import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  loader: {
    width: '100%'
  },
  cards: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '32px'
    },
  }
}));

export default useStyles;