import { tournamentStatus } from '../enum/Enum';
import goldenCup from '../../assets/icons/cups/goldenCup.svg';
import silverCup from '../../assets/icons/cups/silverCup.svg';
import bronzeCup from '../../assets/icons/cups/bronzeCup.svg';
import localization from '../../components/localization/languages';

export const adminRole = 'Administrator';

export const organizerRole = 'Organizer';

export const anglerRole = 'Angler';

export const USER_STORAGE_KEY = 'user';

export const LANGUAGE = 'language';

export const defaultLanguage = 'Ru';

export const languageNames = Object.getOwnPropertyNames(localization);

export const limitOfImagesForNewArticle = 8;

export const errorStyleBackgroundColor = { backgroundColor: 'rgba(240, 128, 128, 0.6)' };

export const placeNumbersPassInPlayoff = 8;

export const countPhotosInGallery = 8;

export const numberOfFinishedTournaments = 3;

export const numberOfSkippedNewsOnHomePage = 0;

export const numberOfTakenNewsOnHomePage = 4;

export const limitCharsFullNameSearch = 30;

export const dateAndTimeFormat = 'yyyy-MM-DDTHH:mm';

export const optionsPerPage = 20;

export const statusIdx: { [index: number]: string } = {
  [tournamentStatus.Close]: 'close',
  [tournamentStatus.Open]: 'open',
  [tournamentStatus.Completed]: 'completed',
  [tournamentStatus.Canceled]: 'canceled',
  [tournamentStatus.Planning]: 'planned'
};

export const cups: { [index: number]: string } = {
  1: goldenCup,
  2: silverCup,
  3: bronzeCup
};

export const defaultMapCoords = [53.902287, 27.561824];

export const articles = {
  review: 2,
  announcement: 0,
  article: 1,
  all: 3,
};

export const phoneRegex = new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/);