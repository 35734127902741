import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const border = `1px solid ${colors.primary}`;

const useStyles = makeStyles((theme) => createStyles({
  content: {
    margin: 10,
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 10px',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '100%',
    textAlign: 'center'
  },
  modalWindowHeader: {
    flexWrap: 'nowrap',
  },
  teamContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 26,
    margin: '20px auto',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  submitContainer: {
    margin: 'auto',
    marginTop: 45
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    textAlign: 'center'
  },
  border: {
    border,
    borderRadius: 8
  },
}));

export default useStyles;