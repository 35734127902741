import {
  remove, get, post, patch, processError
} from '../Http';
import Error from '../../models/service/Error';
import { getTranslation } from '../../components/localization/customHooks/Translation';
import Article from '../../models/components/article/Article';

const url = '/api/Articles';
const l10n = getTranslation();

const generateError = (error: Error) => {
  if (error.response.status === 400) {
    return processError(error, l10n.components.error.UNEXPECTED_ERROR);
  }
  processError(error);
  return null;
};

const ArticlesService = {
  async getAll() {
    return get(url);
  },

  async get(id: string) {
    return get(`${url}/${id}`);
  },

  async delete(id: string) {
    return remove(`${url}/${id}`);
  },

  async post(article: Article) {
    return post(url, article);
  },

  async update(article: Article) {
    const { id } = article;
    return patch(`${url}/${id}`, article, generateError);
  },

  async updateImgs(payload: { articleId: string, imageIds: number[] }) {
    return patch(`${url}/${payload.articleId}/images`, payload, generateError);
  },
};

export default ArticlesService;