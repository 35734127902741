import React from 'react';
import useStyles from '../Authorization.styles';
import AreatroutModal from '../../modal/Modal';
import AuthorizationModalWrapper from './wrapper/Wrapper';

function ModalWindow(
  props: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    component: JSX.Element
  }
) {
  const classes = useStyles();
  const {
    component, open, setOpen
  } = props;
  return (
    <AreatroutModal
      header=""
      open={open}
      setOpen={setOpen}
      modalClassName={classes.SignFormModal}
      className={classes.modalWide}
      onClose={() => setOpen(false)}
    >
      <AuthorizationModalWrapper component={component} />
    </AreatroutModal>
  );
}

export default ModalWindow;