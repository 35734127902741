import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  participantsList: {
    maxHeight: 'calc(100vh - 200px)',
    padding: '0px 5px 0px 5px',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 450,
    },
    [theme.breakpoints.down('md')]: {
      width: '85vw',
      '& .MuiTableCell-root': {
        padding: '5px 0px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '85vw',
      '& .MuiTableCell-root': {
        padding: '5px 0px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
      '& .MuiTableCell-root': {
        padding: 2,
      },
    },
  },
  columnNumber: {
    width: 'calc(100% - 30px)',
  },
  textStyle: {
    '& a': {
      color: '#000',
    },
    cursor: 'pointer',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem',
    padding: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  nameNumber: {
    width: 200,
    textAlign: 'left',
  },
  tick: {
    fontSize: '1.57rem',
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  table: {
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  smallTick: {
    margin: 1,
    fontSize: 18,
  },
}));

export default useStyles;