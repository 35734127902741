import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyles from './Article.styles';
import ArticleTable from './table/Article';
import DeleteArticleModal from './modals/DeleteArticleModal';
import AddOrEditArticleModal from './modals/AddOrEditArticleModal';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import { useStores } from '../../../../stores/Main';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import Modal from '../../../../components/modal/Modal';
import { articles } from '../../../../utils/enum/Enum';
import ATButton from '../../../../components/button/Button';

const Articles = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(articles.all);

  const { ArticlesStore } = useStores();

  const getAllArticles = () => {
    ArticlesStore.refreshArticles();
  };

  const handleClick = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const changeStatus = (currentStatus: number) => {
    setStatus(currentStatus);
  };

  useEffect(() => {
    getAllArticles();
  }, []);

  if (!ArticlesStore.articles) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      <div>
        <button
          onClick={() => changeStatus(articles.all)}
          className={clsx(classes.tableSwitcher, { [classes.active]: status === articles.all })}
          type="button"
        >
          {l10n.shared.ALL}
        </button>
        <button
          onClick={() => changeStatus(articles.announcement)}
          className={clsx(classes.tableSwitcher, { [classes.active]: status === articles.announcement })}
          type="button"
        >
          {l10n.components.articles.ANNOUNCMENTS}
        </button>
        <button
          onClick={() => changeStatus(articles.article)}
          className={clsx(classes.tableSwitcher, { [classes.active]: status === articles.article })}
          type="button"
        >
          {l10n.components.articles.ARTICLES}
        </button>
      </div>
      <div>
        <div className={classes.mediaWrapper}>
          <ATButton
            className={classes.addBtn}
            variant="primary"
            fill="solid"
            size="md"
            onClick={handleClick}
          >
            {l10n.shared.ADD}
          </ATButton>
        </div>
        {ArticlesStore.articles && (
        <ArticleTable
          selectedSubcategory={status}
          data={ArticlesStore.articles}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
        )}
      </div>
      <Modal
        headerClassName={classes.modalHeader}
        header={l10n.components.articles.EDIT_ARTICLE_ADMIN}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onClose={() => getAllArticles()}
      >
        <AddOrEditArticleModal isEditModalOpen={isEditModalOpen} />
      </Modal>
      <Modal
        headerClassName={classes.modalHeader}
        header={l10n.components.articles.CREATE_NEW_ARTICLE_ADMIN}
        open={isAddModalOpen}
        setOpen={setIsAddModalOpen}
        onClose={() => getAllArticles()}
      >
        <AddOrEditArticleModal isEditModalOpen={isEditModalOpen} />
      </Modal>
      <Modal
        header={l10n.components.articles.ARTICLE_MODAL_DELETE}
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onClose={() => getAllArticles()}
      >
        <DeleteArticleModal />
      </Modal>
    </>
  );
});

export default Articles;