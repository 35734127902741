import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import PlaceCell from '../tableTemplates/components/cell/place/Place';
import useStyles from './ScrollableTableCell.styles';
import Angler from '../angler/Angler';

import { ParticipantResult } from '../../models/pages/tournament/playoff/publication/PublishedTournament';

const defaultProps = {
  mainContent: <></>,
  scrolledContent: <></>,
  showIcons: false,
  isTeam: false,
};

const ScrollableTableCell = (props: {
  showIcons?: boolean,
  isTeam?: boolean,
  mainContent?: JSX.Element,
  scrolledContent?: JSX.Element | JSX.Element[],
  participant: ParticipantResult,
}) => {
  const {
    participant, scrolledContent, isTeam, mainContent, showIcons
  } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container alignItems="center">
          <div className={clsx(classes.wrapperPlace, { [classes.hideIcons]: showIcons })}>
            <PlaceCell place={participant.place} imageName={participant.icon} isHaveImageAnyPlaces />
          </div>
          <Angler anglerData={participant.angler} isTeam={isTeam} />
        </Grid>
        {mainContent}
        <div className={classes.scrollableWrapper}>
          {scrolledContent}
        </div>
      </div>
    </>
  );
};

export default ScrollableTableCell;

ScrollableTableCell.defaultProps = defaultProps;