import React from 'react';
import { getImageUrl } from '../../../utils/Utils';
import LocationData from '../../../models/components/dictionaries/location/LocationData';
import useStyles from './styles';

const LocationLogoImg = (props: { location: LocationData }) => {
  const { location } = props;
  const classes = useStyles();
  return (
    <div className={classes.pictureWrapper}>
      {!!location.logo && <img src={getImageUrl(location.logo.url)} className={classes.previewImg} alt="locationLogo" />}
    </div>
  );
};

export default LocationLogoImg;