import React from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../../Playoff.styles';
import PlayoffRounds from '../../../../../../../models/pages/tournament/playoff/PlayoffRounds';
import { pairs, stages } from '../../../../../../../utils/enum/Enum';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import { modifyRound, setRoundsOrder } from '../../Playoff.utils';
import SetFinalStage from '../../components/SetFinalStage';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

function QuarterFinals(props: {
  quarterFinalRounds: PlayoffRounds[]
  getPlayoffRounds: EmptyVoidFunction
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    quarterFinalRounds, getPlayoffRounds, setIsLoaded, isEditMode
  } = props;

  return (
    <div className={classes.quarterFinals}>
      {!isMobile
        && <div className={clsx(classes.header, classes.quarterHeader)}>{l10n.components.playoffSchedule.QUATERFINAL}</div>}
      <div className={clsx(classes.sectionParticipants, classes.flexColumn)}>
        {isMobile
              && (
              <>
                <div className={clsx(classes.header, classes.firstHeader)}>{l10n.components.playoffSchedule.QUATERFINAL}</div>
              </>
              )}
        {setRoundsOrder(pairs.quarterFinals, quarterFinalRounds).map((round) => (
          <SetFinalStage
            setIsLoaded={setIsLoaded}
            round={round ? modifyRound(round) : null}
            key={round?.id || uuidv4()}
            getPlayoffRounds={getPlayoffRounds}
            stage={stages.quarterFinals}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    </div>
  );
}

export default QuarterFinals;