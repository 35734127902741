/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, TextField, Grid, FormHelperText
} from '@material-ui/core';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import Sponsor from '../../../../../../models/components/dictionaries/sponsor/Sponsor';
import ImageData from '../../../../../../models/components/image/ImageData';
import schema from '../helpers/ValidationSchema';
import useStyles from '../Sponsors.style';
import Toasters from '../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';
import UploadImage from '../../../../../../components/uploadImages/UploadImage';
import SponsorPicture from './sponsorPicture/SponsorPicture';

const AddSponsorModal = observer((props: {
  handleClose?: EmptyVoidFunction,
  isEditModalOpen?: any,
}) => {
  const { handleClose, isEditModalOpen } = props;

  const { SponsorsStore } = useStores();

  const [sponsorPictureId, setSponsorPictureId] = useState<ImageData | null>(null);
  const [isImageUploadFailed, setIsImageUploadFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useStyles();
  const l10n = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Sponsor>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditModalOpen) {
      setSponsorPictureId(SponsorsStore.sponsor!.logo);
    }
  }, []);

  useEffect(() => {
    if (sponsorPictureId) setIsImageUploadFailed(false);
  }, [sponsorPictureId]);

  const AddOrEditSponsor = async (data: Sponsor) => {
    setIsLoading(true);
    if (sponsorPictureId) {
      const result = {
        ...data,
        logoId: sponsorPictureId!.id,
      };
      const response = isEditModalOpen ? await SponsorsStore.editSponsor(result) : await SponsorsStore.addSponsor(result);
      if (response) {
        if (!isEditModalOpen) Toasters.success(l10n.components.sponsors.ADD_SPONSOR_SUCCESS);
        if (isEditModalOpen) Toasters.success(l10n.components.sponsors.EDIT_SPONSOR_SUCCESS);
      }
      if (handleClose) {
        handleClose();
      }
    } else {
      setIsImageUploadFailed(true);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit(AddOrEditSponsor)} className={classes.contentWrapper}>
      <div className={classes.imgWrapper}>
        {sponsorPictureId
          ? <SponsorPicture sponsorPictureId={sponsorPictureId} setSponsorImage={setSponsorPictureId} />
          : <UploadImage addImage={setSponsorPictureId} />}
        <FormHelperText data-testid="nameError" className={clsx(classes.invalid, classes.imageError)}>
          {isImageUploadFailed && l10n.components.sponsors.IMAGE_ERROR}
        </FormHelperText>
      </div>
      <Controller
        render={({ field }) => (
          <>
            <TextField
              {...field}
              label={l10n.components.sponsors.SPONSOR_NAME}
              variant="outlined"
              error={!!errors.name}
              autoFocus
              inputProps={{ 'data-testid': 'name' }}
            />
            <FormHelperText data-testid="nameError" className={classes.invalid}>
              {!!errors?.name && errors?.name?.message}
            </FormHelperText>
          </>
        )}
        name="name"
        control={control}
        defaultValue={isEditModalOpen ? SponsorsStore.sponsor?.name : ''}
      />
      <Controller
        render={({ field }) => (
          <>
            <TextField
              {...field}
              className={classes.contentInput}
              label={l10n.components.sponsors.SPONSOR_URL}
              error={!!errors.url}
              variant="outlined"
              inputProps={{ 'data-testid': 'url' }}
            />
            <FormHelperText data-testid="urlError" className={classes.invalid}>
              {!!errors?.url && errors?.url?.message}
            </FormHelperText>
          </>
        )}
        name="url"
        control={control}
        defaultValue={isEditModalOpen ? SponsorsStore.sponsor?.url : ''}
      />
      <Controller
        render={({ field }) => (
          <>
            <TextField
              {...field}
              label={l10n.components.sponsors.SPONSOR_COMMENT}
              variant="outlined"
              className={classes.contentInput}
              error={!!errors.comment}
              multiline
              rows={4}
              inputProps={{ 'data-testid': 'comment' }}
            />
            <FormHelperText data-testid="nameError" className={classes.invalid}>
              {!!errors?.comment && errors?.comment?.message}
            </FormHelperText>
          </>
        )}
        name="comment"
        control={control}
        defaultValue={isEditModalOpen ? SponsorsStore.sponsor?.comment : ''}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={clsx(classes.saveButton, classes.contentInput)}
        data-testid="saveBtn"
      >
        {l10n.shared.SAVE}
      </Button>
    </form>
  );
});

export default AddSponsorModal;