import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useStores } from '../../../../../../../stores/Main';
import useStyles from '../../Template.style';
import TournamentTemplate from '../../../../../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';

function EditDelete(props: {
    template: TournamentTemplate
}) {
  const {
    template,
  } = props;
  const classes = useStyles();
  const { TournamentTemplatesStore } = useStores();

  const openDelModal = (currentTemplate: number): void => {
    TournamentTemplatesStore.setId(currentTemplate);
    TournamentTemplatesStore.toggleDeleteTemplateModalState();
  };

  const openEditModal = async (currentTemplate: number) => {
    TournamentTemplatesStore.setId(currentTemplate);
    TournamentTemplatesStore.toggleEditTemplateModalState();
  };

  return (
    <>
      {template && (
      <ListItem className={classes.buttonCell}>
        <IconButton
          edge="start"
          aria-label="edit"
          onClick={() => openEditModal(template.id)}
        >
          <Edit />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => openDelModal(template.id)}
        >
          <Delete />
        </IconButton>
      </ListItem>
      )}
    </>
  );
}

export default EditDelete;