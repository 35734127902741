import React, { useState } from 'react';
import { Button, FormHelperText } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import Modal from '../../../../../../components/modal/Modal';
import EditTournamentStatusModal from './modal/Modal';
import useStyles from './Status.style';
import { tournamentStatus } from '../../../../../../utils/enum/Enum';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const EditTournamentStatus = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { TournamentStore } = useStores();
  const { tournament } = TournamentStore;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const openModal = () => {
    if (tournament) {
      // eslint-disable-next-line no-unused-expressions
      tournament.status === tournamentStatus.Completed ? setError(true) : setOpen(true);
    }
  };

  return (
    <>
      <Modal
        header={l10n.components.headerLabel.CHANGE_STATUS}
        open={open}
        setOpen={setOpen}
        onClose={() => { }}
        className={classes.modalWindow}
      >
        <EditTournamentStatusModal />
      </Modal>
      <div className={classes.statusText}>
        {`${l10n.pages.TOURNAMENT_STATUS}:  `}
        <strong>{tournament?.statusText}</strong>
      </div>
      <FormHelperText data-testid="nameError" className={classes.invalid}>
        {error && l10n.components.error.CHANGE_TOURNAMENT_STATUS}
      </FormHelperText>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonPadding}
        onClick={openModal}
      >
        {l10n.components.button.CHANGE_TOURNAMENT_STATUS}
      </Button>
    </>
  );
});

export default EditTournamentStatus;