
import { object, string, date } from 'yup';
import { instagramLinkDomens, facebookLinkDomens } from './Constants';
import { checkDomain, getFormattedDate } from '../EditProfile.utils';
import { getTranslation } from '../../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  firstName: string().required(l10n.components.editProfile.VALIDATION_REQUIRED),
  lastName: string().required(l10n.components.editProfile.VALIDATION_REQUIRED),
  city: string().required(l10n.components.editProfile.VALIDATION_REQUIRED),
  instagramLink: string().trim().test('checkUrl', l10n.components.editProfile.VALIDATION_INVALID, (value) => checkDomain(value, instagramLinkDomens)),
  facebookLink: string().trim().test('checkUrl', l10n.components.editProfile.VALIDATION_INVALID, (value) => checkDomain(value, facebookLinkDomens)),
  birthday: date().nullable().transform((curr, orig) => (orig === '' ? null : curr)).max(getFormattedDate()),
});

export default schema;