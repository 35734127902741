import React from 'react';
import clsx from 'clsx';
import useStyles from '../../News.styles';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import { articles } from '../../../../utils/enum/Enum';
import { useStores } from '../../../../stores/Main';

const NewsFilterBar = () => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { NewsStore } = useStores();

  return (
    <div>
      <button
        onClick={() => NewsStore.setFilter(articles.all)}
        className={clsx(classes.tableSwitcher, { [classes.active]: NewsStore.filter === articles.all })}
        type="button"
      >
        {l10n.shared.ALL}
      </button>
      <button
        onClick={() => NewsStore.setFilter(articles.announcement)}
        className={clsx(classes.tableSwitcher, { [classes.active]: NewsStore.filter === articles.announcement })}
        type="button"
      >
        {l10n.components.articles.ANNOUNCMENTS}
      </button>
      <button
        onClick={() => NewsStore.setFilter(articles.article)}
        className={clsx(classes.tableSwitcher, { [classes.active]: NewsStore.filter === articles.article })}
        type="button"
      >
        {l10n.components.articles.ARTICLES}
      </button>
      <button
        onClick={() => NewsStore.setFilter(articles.review)}
        className={clsx(classes.tableSwitcher, { [classes.active]: NewsStore.filter === articles.review })}
        type="button"
      >
        {l10n.components.articles.REPORTS}
      </button>
    </div>
  );
};

export default NewsFilterBar;