import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ColumnMetadata from '../../../models/components/table/ColumnMetadata';
import { useStyles } from '../Table.styles';

const MobileTable = observer((props: {
  columns: ColumnMetadata[],
  rows: any[]
  mobileColumnHeader?: (index: number) => string;// eslint-disable-line
  mobileClassName?: string
}) => {
  const classes = useStyles();
  const {
    columns, rows, mobileColumnHeader, mobileClassName
  } = props;
  const columnFields: { [key: string]: string | JSX.Element } = {};

  const columnKeys = columns.map((column) => {
    const field = column.render || ((row: any) => (
      <>
        <span className={classes.columnName}>{`${column.displayName}: `}</span>
        <span>{`${row[column.fieldName]}`}</span>
      </>
    ));
    columnFields[field] = column.displayName;
    return field;
  });

  return (
    <div>
      {rows.map((row, idx) => (
        <div
          className={clsx(classes.mobileContainer, mobileClassName)}
          key={uuidv4()}
        >
          {mobileColumnHeader && (
            <div className={classes.mobileTableRow}>
              <span className={classes.columnName}>{mobileColumnHeader(idx)}</span>
            </div>
          )}
          {columnKeys.map((key) => (
            <div
              className={classes.mobileTableRow}
              key={uuidv4()}
            >
              {key(row)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});

export default MobileTable;