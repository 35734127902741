import {
  remove, get, post,
} from '../Http';
import Report, { VisibilityStatus } from '../../models/components/report/Report';

const url = '/api/Reports/';

const ReportsService = {
  async getAll() {
    return get(url);
  },

  async getByReportId(id: string) {
    return get(`${url}${id}`);
  },

  async getAllReportsByUserId(id: string) {
    return get(`${url}users/${id}`);
  },

  async getAllByTournamentId(id: string) {
    return get(`${url}tournaments/${id}`);
  },

  async post(report: Report) {
    return post(url, report);
  },

  async postSetPublishStatus(statusInfo: VisibilityStatus) {
    return post(`${url}${statusInfo.reportId}/publish`, statusInfo);
  },

  async getAvailableTournamentsForReport() {
    return get(`${url}tournaments/available`);
  },

  async delete(id: string) {
    return remove(`${url}${id}`);
  },

  async setReportStatusOfPublication(reportId: string, reportIsPublished: boolean) {
    return post(`${url}${reportId}/publish`, { reportId, reportIsPublished });
  }
};

export default ReportsService;