import React from 'react';
import {
  Popper,
  Paper,
  MenuList,
  ClickAwayListener,
  Grow,
} from '@material-ui/core';
import useStyles from '../UserProfile.styles';

function DropDown(props: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  anchorRef: React.MutableRefObject<HTMLButtonElement | null>
  children: JSX.Element | Array<JSX.Element>;
}) {
  const {
    open, setOpen, anchorRef, children
  } = props;

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Popper
      open={open}
      transition
      disablePortal
      placement="top-end"
      className={classes.modal}
      anchorEl={anchorRef.current}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                {React.Children.map(children, (child) => React.cloneElement(child, { handleClose }))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default DropDown;