import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('lg')]: {
      margin: '0 15px',
    },
  },
  wrapper: {
    borderBottom: '1px solid #E2E4F0',
    borderRadius: 2,
    '& .MuiTabs-indicator': {
      backgroundColor: colors.primary,
    },
  },
  tabsTextContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },

  start: {
    justifyContent: 'flex-start',
  },
  tab: {
    color: colors.secondary,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'none',

    '&.Mui-selected': {
      color: colors.primary,
    },
  },
  tabContent: {
    paddingTop: 30,
    boxSizing: 'border-box',
    scrollbarWidth: 'none',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1360,
      margin: 'auto',
      minHeight: '20vh',
    },
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
      '& > .MuiGrid-spacing-xs-2': {
        margin: 0,
        width: '100%',
      },
    },
  },
  tabScroll: {
    '& > .MuiTabs-scrollable': {
      scrollbarWidth: 'none',
    },
    '& .MuiTabScrollButton-root': {
      width: 'auto',
    },
  },
}));

export default useStyles;