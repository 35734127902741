import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 280,
  },
  tableWidth: {
    maxWidth: 1200,
    margin: '0 10px'
  },
  input: {
    width: '100%'
  },
  buttonsWrapper: {
    padding: 10
  },
  value: {
    maxWidth: 100,
    '& p.MuiFormHelperText-contained': {
      textAlign: 'center',
      margin: 0
    }
  },
  field: {
    marginTop: 20,
    '& .MuiFormControl-root': {
      width: '100%'
    },
  },
  invalid: {
    color: 'red',
  },
  buttonAdd: {
    marginBottom: 10,
    marginLeft: 20
  },
  select: {
    '& > div > div > .MuiInputBase-root': {
      width: '100%'
    },
    '& > div > p': {
      marginLeft: 0
    }
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: 0
    }
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    }
  },
  helperText: {
    fontSize: 16
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%'
    }
  },
  description: {
    width: 600
  }
}));

export default useStyles;