import React from 'react';
import clsx from 'clsx';
import useStyles from '../Playoff.styles';
import fish from '../../../../../../assets/icons/fish.svg';

function QualificationCatches(props: {
  catches: number | null | undefined
}) {
  const classes = useStyles();
  const { catches } = props;

  return (
    <div className={clsx(classes.flexRow, classes.qualificationInfo)}>
      <img src={fish} alt="fish" />
      <div>{catches}</div>
    </div>
  );
}

export default QualificationCatches;