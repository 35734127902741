import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import useStyles from './TeamCards.styles';
import registrationStatus from './helpers/Constants';
import MemberAvatar from '../member/avatar/Avatar';
import TeamParticipant from './components/teamParticipant/TeamParticipant';
import Tooltip from '../tooltip/Tooltip';
import TeamData from '../../models/pages/team/TeamData';
import Team from '../../models/pages/team/Team';
import { teamRegistarationStatus } from '../../utils/enum/Enum';
import { EmptyVoidFunction } from '../../utils/types/Types';
import PartnerFields from '../../models/pages/team/PartnerFields';
import EditTeamButton from './components/editTeamButton/EditTeamButton';
import DeleteTeamButton from './components/deleteTeamButton/DeleteTeamButton';
import useTranslation from '../localization/customHooks/Translation';

function TeamCards(props: {
  userEmail: string
  setEditTeamData: React.Dispatch<React.SetStateAction<TeamData>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  teams: Team[]
  refresh: EmptyVoidFunction
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    userEmail, setEditTeamData, setOpen, teams, refresh
  } = props;

  return (
    <>
      {teams.length ? teams.map((team: Team) => (
        <div className={classes.teamContainer} key={team.id}>
          <div className={classes.teamDataContainer}>
            <MemberAvatar countryFlag={team.country?.flag} profilePicture={team?.picture} avatarStyle="medium" />
            <div className={classes.teamName}>{team.name}</div>
            <Tooltip
              className={classes.border}
              index={uuidv4()}
              content={(
                <div className={clsx(classes.popover, classes.text)}>
                  {registrationStatus[team.status].description}
                </div>
              )}
              container={(
                <div className={classes.teamStatus}>
                  <img src={registrationStatus[team.status].icon} alt="status" />
                </div>
              )}
            />
          </div>
          <div className={clsx(classes.participantsContainer, classes.text)}>
            {
              [team.captain, team.partner]
                .sort((member: PartnerFields) => (member.email === userEmail ? -1 : 1))
                .map((member: PartnerFields) => (
                  <TeamParticipant
                    key={member.id}
                    userEmail={userEmail}
                    teamParticipantData={member}
                    className={clsx(classes.partner, { [classes.user]: member.email === userEmail })}
                  />
                ))
            }
          </div>
          <div className={classes.buttonContainer}>
            {team.status === teamRegistarationStatus.approved
              && userEmail === team.captain.email
              && (
                <EditTeamButton setOpen={setOpen} setEditTeamData={setEditTeamData} team={team} />
              )}
            <DeleteTeamButton team={team} refresh={refresh} />
          </div>
        </div>
      ))
        : <p className={classes.basicText}>{l10n.components.teamCard.WITHOUT_TEAM}</p>}
    </>
  );
}

export default TeamCards;