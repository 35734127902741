import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  collapseContainer: {
    boxShadow: '0px 4px 12px rgba(20, 20, 41, 0.25)',
    borderRadius: 8,
  },
  root: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: 'auto',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    padding: '10px 10px 32px',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 200,
      borderRadius: 0,
      boxShadow: 'none',
      padding: 0,
      overflowY: 'auto'
    },
  },
  mobileViewDisabled: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      width: '100%',
      height: '100%',
    }
  },
  header: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'relative',
      paddingTop: 44
    },
  },
  hideHeader: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  title: {
    width: 'fit-content',
    margin: '0 auto',
    colors: colors.secondary,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '36px'
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 16,
    padding: 0
  },
  sectionOpen: {
    [theme.breakpoints.down('xs')]: {
      overflowY: 'hidden'
    },
  }
}));

export default useStyles;