import React from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from '../../Playoff.styles';
import { pairs, stages } from '../../../../../../../utils/enum/Enum';
import { modifyRound, setRoundsOrder } from '../../Playoff.utils';
import PlayoffRounds from '../../../../../../../models/pages/tournament/playoff/PlayoffRounds';
import SetFinalStage from '../../components/SetFinalStage';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

function FinalRounds(props: {
  finalRound: PlayoffRounds[]
  thirdPlaceRound: PlayoffRounds[]
  getPlayoffRounds: EmptyVoidFunction
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    finalRound, thirdPlaceRound, getPlayoffRounds, setIsLoaded, isEditMode
  } = props;

  return (
    <div className={classes.final}>
      {!isMobile && <div className={clsx(classes.header, classes.finalHeader)}>{l10n.components.playoffSchedule.FINAL}</div>}
      <div className={clsx(classes.flexColumn, { [classes.outerFinal]: !isMobile, [classes.sectionParticipants]: isMobile })}>
        {isMobile && <div className={classes.header}>{l10n.components.playoffSchedule.FINAL}</div>}
        {setRoundsOrder(pairs.firstPlace, finalRound).map((round) => (
          <SetFinalStage
            setIsLoaded={setIsLoaded}
            round={round ? modifyRound(round) : null}
            key={round?.id || uuidv4()}
            getPlayoffRounds={getPlayoffRounds}
            stage={stages.firstPlace}
            isEditMode={isEditMode}
          />
        ))}
        {setRoundsOrder(pairs.thirdPlace, thirdPlaceRound).map((round) => (
          <SetFinalStage
            setIsLoaded={setIsLoaded}
            round={round ? modifyRound(round) : null}
            key={round?.id || uuidv4()}
            getPlayoffRounds={getPlayoffRounds}
            stage={stages.thirdPlace}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    </div>
  );
}

export default FinalRounds;