import { createStyles, makeStyles } from '@material-ui/core/styles';
import mainLogo from '../../assets/logo/mainLogo.png';
import mainLogoMobile from '../../assets/logo/mobileMainLogo.png';
import colors, { stylesMenuItem, focusVisibleNavMenu } from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-root': {
      minWidth: 24
    },
  },
  root: {
    maxWidth: 1360,
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
    height: 92,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    '& a': {
      whiteSpace: 'nowrap',
    }
  },
  logo: {
    backgroundImage: `url(${mainLogo})`,
    backgroundRepeat: 'no-repeat',
    maxWidth: 155,
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 25,
      maxWidth: 100,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 155,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${mainLogoMobile})`,
      width: 62,
    },
  },
  invisibleLink: {
    display: 'block',
    height: '100%',
    width: '100%',
    '&:focus-visible': {
      ...focusVisibleNavMenu
    }
  },
  burgerMenuIcon: {
    display: 'none',
    width: 24,
    height: 24,
    marginTop: -5,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  closeBurgerMenuIcon: {
    position: 'absolute',
    right: 12,
    top: 12,
    zIndex: 101
  },
  burgerMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 74,
    ...stylesMenuItem,
    '& li a': {
      width: '100%',
    },
    padding: '0 3rem',
    maxHeight: 250,
    width: '90%'
  },
  burgerMenuWrapper: {
    position: 'fixed',
    zIndex: 100,
    overflowX: 'hidden',
    transition: '0.5s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: 0,
    left: 0,
    height: '100%',
    background: '#FFFFFF',
  },
  burgerMenuOpenWrapper: {
    width: '100%',
  },
  burgerMenuCloseWrapper: {
    width: 0
  },
  menu: {
    display: 'flex',
    marginRight: 30,
    maxWidth: 800,
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    ...stylesMenuItem,
  },
  mainLogoBlur: {
    maxWidth: 143,
    objectFit: 'contain',
    paddingBottom: 26
  },
  active: {
    borderBottom: `2px solid ${colors.primaryDark} !important`,
    '& a': {
      color: colors.primary,
    }
  },
  burgerMenuLanguageSelect: {
    marginLeft: 5,
    marginTop: 10,
  },
  userProfile: {
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
  }
}));

export default useStyles;