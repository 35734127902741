import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  reportModal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 800,
      width: '100%',
      maxHeight: 800,
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        height: '100%',
        maxWidth: '100%',
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%'
      },
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      gap: 20,
      marginBottom: 20
    },
  },
}));

export default useStyles;