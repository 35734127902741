import Location from '../../../models/components/dictionaries/location/Location';
import {
  remove, get, post, put
} from '../../Http';

const url = '/api/Locations/';

const LocationService = {
  async get() {
    return get(url);
  },

  async getLocationTournaments(id: number) {
    return get(`${url}${id}/tournaments`);
  },

  async getById(id: number) {
    return get(url + id);
  },

  async post(location: Location) {
    return post(url, location);
  },

  async put(location: Location) {
    const { id } = location;
    return put(url + id, location);
  },

  async delete(id: number) {
    return remove(url + id);
  },
};

export default LocationService;