import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { AsyncPaginate } from 'react-select-async-paginate';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import Tags from '../../../../../../../components/tags/Tags';
import Member from '../../../../../../../components/member/Member';
import Toasters from '../../../../../../../components/popUp/PopUp';
import AnglerInfo from '../../../../../../../models/pages/user/AnglerInfo';
import AdminTournamentDetails from '../../../../../../../models/pages/tournament/AdminTournamentDetails';
import { optionsPerPage } from '../../../../../../../utils/constants/Constants';
import RegistrationService from '../../../../../../../services/main/Registration.service';
import TournamentService from '../../../../../../../services/main/Tournament.service';
import useStyles, { selectStyle } from './AddParticipants.styles';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const AddParticipants = (props: {
  tournament: AdminTournamentDetails | null;
  fetchAllParticipants: EmptyVoidFunction;
}) => {
  const { tournament, fetchAllParticipants } = props;

  const classes = useStyles();
  const l10n = useTranslation();
  const [user, setUser] = useState<string>('');

  const AddAnglerToTournament = async () => {
    const res = await RegistrationService.registerUserByAdmin(tournament!.id, user);
    if (res) {
      fetchAllParticipants();
      Toasters.success(l10n.components.toasterMessage.PARTICIPANT_HAS_BEEN_ADDED);
    }
  };

  const onChangeSelectedOption = async (event: any) => {
    setUser(event.value);
  };

  const getAvalibale = async (searchQuery: string, skip: number) => {
    let total = 0;
    let options = [];
    const query = {
      searchQuery,
      skip,
      take: optionsPerPage,
    };
    const response = await TournamentService.getAvailableRegistrations(tournament!.id, query);

    if (response) {
      options = response.items.map((angler: AnglerInfo) => ({
        label: (
          <Grid className={classes.member}>
            <Member
              countryFlag={angler.country.flag}
              fullName={angler.fullName}
              profilePicture={angler.profilePicture}
            />
            {angler.isRegistered
              && (
              <Tags
                variant="event-open"
              >
                {l10n.pages.ALREADY_REGISTERED}
              </Tags>
              )}
          </Grid>
        ),
        value: String(angler.userId),
        isDisabled: angler.isRegistered,
      }));
      total = response.total;
    }
    return { total, options };
  };

  const loadOptions = async (search: string, prevOptions: any) => {
    const { total, options } = await getAvalibale(search, prevOptions.length);
    const hasMore = prevOptions.length < total;

    return {
      options,
      hasMore
    };
  };

  return (
    <Grid>
      <Typography className={classes.header}>{l10n.components.button.ADD_NEW_PARTICIPANT}</Typography>
      <div className={classes.contenPosition}>
        <AsyncPaginate
          maxMenuHeight={220}
          loadingMessage={() => l10n.pages.LOADING_MESSAGE}
          noOptionsMessage={() => l10n.pages.NO_OPTIONS_MESSAGE}
          loadOptions={loadOptions}
          styles={selectStyle}
          className={classes.select}
          placeholder={l10n.components.extendedSelect.PLACEHOLDER}
          onChange={onChangeSelectedOption}
          isOptionDisabled={(option: any) => option.isDisabled}
        />
        {!tournament?.ranking?.isTeamRanking
        && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => AddAnglerToTournament()}
          className={classes.button}
        >
          {l10n.components.button.ADD_TO_MAIN_LIST}
        </Button>
        )}
      </div>
    </Grid>
  );
};

export default AddParticipants;