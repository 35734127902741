import moment from 'moment';

export const checkDomain = (value: any, typeOfMedia: string[]) => {
  let url;

  if (value === '') return true;

  try {
    url = new URL(value!);
  } catch {
    return false;
  }

  const domain = typeOfMedia.indexOf(url.hostname);

  return ['http:', 'https:'].includes(url.protocol) && domain > 0;
};

export const getFormattedDate = (dat: any = new Date()) => (dat ? moment(dat).format('YYYY-MM-DD') : null);