import React, { Ref } from 'react';
import { Controller } from 'react-hook-form';
import EditableTextField from '../../../inputs/textField/Editable';
import useTranslation from '../../../localization/customHooks/Translation';

const AdditionalInfoControl = React.forwardRef((props: {
    description: string,
    control: any,
    setValue: any,
    errors: any,
    autoSaveFunction: () => void,
    formControl: {
      controlName: string,
      label: string,
    }
    }, headerFooterRef: Ref<HTMLDivElement>) => {
  const {
    control, setValue, errors, description, formControl, autoSaveFunction
  } = props;
  const l10n = useTranslation();

  return (
    <Controller
      name={formControl.controlName}
      control={control}
      defaultValue={description || ''}
      render={({ field }) => (
        <EditableTextField
          {...field}
          ref={headerFooterRef}
          errors={errors[formControl.controlName]}
          convertedContent={description || ''}
          onBlur={(text: string) => {
            setValue(formControl.controlName, text);
            autoSaveFunction();
          }}
          label={formControl.label}
          placeholder={l10n.components.addLocation.PLACEHOLDER_DESCRIPTION}
        />
      )}
    />
  );
});

export default AdditionalInfoControl;