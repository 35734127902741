import React from 'react';
import { OutlinedInput } from '@material-ui/core';
import useStyles from '../Playoff.styles';
import ModifiedPlayoffPlayer from '../../../../../../models/pages/tournament/playoff/ModifiedPlayoffPlayer';
import RoundResult from '../../../../../../models/pages/tournament/playoff/RoundResult';
import fish from '../../../../../../assets/icons/fish.svg';
import Angler from '../../../../../../components/angler/Angler';
import { onInputHandleChange } from '../../../../../../utils/Utils';

function PlayoffParticipant(props: {
  player: ModifiedPlayoffPlayer | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean
}) {
  const classes = useStyles();
  const {
    player, setParticipant, winnerId, isEditMode
  } = props;

  const onBlurHandleChange = async (data: HTMLInputElement) => {
    const { value } = data;
    setParticipant({
      participantId: player?.id,
      participantCatches: value !== '' ? Number(value) : null,
    });
  };

  return (
    <>
      <div className={classes.anglerInfoWrapper}>
        {player && (
          <Angler anglerData={{ ...player, id: player?.anglerId }} avatarStyle="medium" isTeam={player.isTeam} />
        )}
        {isEditMode ? (
          <OutlinedInput
            disabled={!!winnerId}
            defaultValue={player?.catches}
            className={classes.input}
            type="number"
            name="points"
            inputComponent="input"
            inputProps={{
              min: '0',
            }}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
            onBlur={(event: any) => onBlurHandleChange(event.target)}
          />
        ) : (
          <>
            <div className={classes.anglerCatches}>
              <img src={fish} alt="fish" />
              {player?.catches}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PlayoffParticipant;