import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStyles from '../../Formats.styles';
import TournamentFormat from '../../../../../../../models/pages/tournament/TournamentFormat';
import formatService from '../../../../../../../services/main/dictionaries/Format.service';
import schema from '../../helpers/ValidaionSchema';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => {},
};

function AddEditFormatForm(props: {
    isEdited: boolean;
    format: TournamentFormat;
    handleClose?: EmptyVoidFunction;
}) {
  const { isEdited, format, handleClose } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TournamentFormat>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: undefined,
    },
  });

  const classes = useStyles();
  const l10n = useTranslation();

  const setFormats = async (data: TournamentFormat) => {
    setIsLoaded(true);
    const response = await formatService.post(data);
    if (response) {
      Toasters.success(l10n.components.tournamentFormat.FORMAT_ADD_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };

  const editFormats = async (editedFormat: TournamentFormat) => {
    const result = { ...editedFormat };
    result.id = format.id;
    setIsLoaded(true);
    const response = await formatService.put(result);
    if (response) {
      Toasters.success(l10n.components.tournamentFormat.FORMAT_EDIT_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };

  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form
      autoComplete="off"
      onSubmit={isEdited ? handleSubmit(editFormats) : handleSubmit(setFormats)}
      className={classes.inputContainer}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.tournamentFormat.FORMAT_NAME_ADD}
            variant="outlined"
            error={!!errors.name}
            helperText={errors?.name?.message}
            multiline
            autoFocus
            inputProps={{ 'data-testid': 'name' }}
          />
        )}
        name="name"
        control={control}
        defaultValue={isEdited ? format.name : ''}
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.tournamentFormat.FORMAT_DESCRIPTION}
            variant="outlined"
            multiline
            rows={4}
            inputProps={{ 'data-testid': 'description' }}
          />
        )}
        name="description"
        control={control}
        defaultValue={isEdited ? format.description : ''}
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.tournamentFormat.FORMAT_RULES}
            multiline
            variant="outlined"
            rows={4}
            inputProps={{ 'data-testid': 'rules' }}
          />
        )}
        name="rules"
        control={control}
        defaultValue={isEdited ? format.rules : ''}
      />
      <Button
        className={classes.formButton}
        variant="contained"
        color="primary"
        type="submit"
        data-testid="saveBtn"
      >
        {l10n.components.tournamentFormat.SAVE_BUTTON}
      </Button>
    </form>
  );
}

export default AddEditFormatForm;

AddEditFormatForm.defaultProps = defaultProps;