import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  closeButton: {
    marginLeft: 'auto',
    top: theme.spacing(2.5),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    height: 30,
    width: 30,
    '&:hover': {
      backgroundColor: '#e7f6f7',
    },
    '&:active': {
      backgroundColor: '#b7e5e8',
    },
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      width: 600,
    },
  },
}));

export default useStyles;