import React from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import useStyles from '../Countries.style';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const DeleteCountry = observer((props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const { CountriesStore } = useStores();

  const sendDeleteRequest = async () => {
    const response = await CountriesStore.deleteCountry();
    if (response) {
      Toasters.success(l10n.components.countries.DELETE_COUNTRY_SUCCESS);
      CountriesStore.refreshCountries();
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (CountriesStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.countries.DELETE_QUESTION}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-testid="cancelBtn"
        >
          {l10n.components.countries.BUTTON_CANCEL}
        </Button>
        <Button
          onClick={sendDeleteRequest}
          color="primary"
          variant="contained"
          autoFocus
          data-testid="deleteBtn"
        >
          {l10n.components.countries.DELETE_HEADER}
        </Button>
      </DialogActions>
    </>
  );
});

export default DeleteCountry;