import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  carouselStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& .carousel-slider:hover .control-arrow': {
      background: '#babac0',
    },
    '& .thumbs': {
      '& li': {
        height: '120px',
        [theme.breakpoints.down('xs')]: {
          height: '80px',
        },
      },
      '& .thumb img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    }
  },
}));

export default useStyles;