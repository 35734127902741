import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../../stores/Main';
import { tournamentStatus } from '../../../../../../../utils/enum/Enum';
import TournamentService from '../../../../../../../services/main/Tournament.service';
import Loader from '../../../../../../../components/loader/Loader';
import useStyles from './ToggleStatus.styles';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const ToggleStatus = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const { TournamentStore } = useStores();
  const { tournament } = TournamentStore;
  const [isLoading, setIsLoading] = useState(false);
  const disabledButtonStatuses = [
    tournamentStatus.Canceled,
    tournamentStatus.Completed,
  ];

  const updateTournamentStatus = async () => {
    if (tournament) {
      const { status } = tournament;

      const isStatusPlanningOrClose = [tournamentStatus.Planning, tournamentStatus.Close].includes(status)
        ? tournamentStatus.Open : status;

      const newStatus = status === tournamentStatus.Open
        ? tournamentStatus.Close
        : isStatusPlanningOrClose;

      if (status !== newStatus) {
        setIsLoading(true);
        await TournamentService.updateStatus({ tournamentId: tournament.id, status: newStatus });
        TournamentStore.getTournament(id);
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [tournament?.status]);

  const updateButtonName = () => {
    if (tournament) {
      return tournament.status === tournamentStatus.Open ? l10n.pages.CLOSE_REGISTARTION : l10n.pages.OPEN_REGISTARTION;
    }
    return null;
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      disabled={tournament ? disabledButtonStatuses.includes(tournament.status) : true}
      onClick={() => updateTournamentStatus()}
      className={classes.button}
    >
      {isLoading ? <Loader className={classes.loader} /> : updateButtonName()}
    </Button>
  );
});

export default ToggleStatus;