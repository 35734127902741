import FormatService from '../../../../../services/main/dictionaries/Format.service';
import CountryService from '../../../../../services/main/dictionaries/Country.service';
import RankingService from '../../../../../services/main/dictionaries/Ranking.service';
import SeasonsService from '../../../../../services/main/dictionaries/Season.service';
import RatingService from '../../../../../services/main/dictionaries/Rating.service';
import TournamentService from '../../../../../services/main/Tournament.service';
import FilterField from '../../../../../models/pages/availiableTournaments/FilterField';
import { selectType } from '../../../../../utils/enum/Enum';
import { getTranslation } from '../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const FilterFields: FilterField[] = [
  {
    filterName: 'format',
    placeHolder: l10n.components.placeholder.ALL_FORMATS,
    label: l10n.pages.FORMAT,
    getAvailableItems: FormatService.get,
    type: selectType.multiple
  },
  {
    filterName: 'season',
    placeHolder: l10n.components.placeholder.ALL_SEASONS,
    label: l10n.pages.SEASON,
    getAvailableItems: SeasonsService.getAll,
    type: selectType.multiple
  },
  {
    filterName: 'ranking',
    placeHolder: l10n.components.placeholder.ALL_RANKINGS,
    label: l10n.pages.RANKING,
    getAvailableItems: RankingService.getAll,
    type: selectType.multiple
  },
  {
    filterName: 'ratingsList',
    placeHolder: l10n.components.placeholder.ALL_RATINGS,
    label: l10n.pages.RATING,
    getAvailableItems: RatingService.get,
    type: selectType.multiple
  },
  {
    filterName: 'country',
    placeHolder: l10n.components.placeholder.ALL_COUNTRIES,
    label: l10n.pages.COUNTRY,
    getAvailableItems: CountryService.get,
    type: selectType.multiple
  },
  {
    filterName: 'status',
    placeHolder: l10n.components.placeholder.ALL_STATUSES,
    label: l10n.pages.STATUS,
    getAvailableItems: TournamentService.getStatusesAsSelectItems,
    type: selectType.multiple
  },
];

export default FilterFields;