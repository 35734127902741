import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  signUpForm: {
    width: '82%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 544,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 328
    },
  },
  form: {
    width: '100%',
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '38px',
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
  },
  basicText: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '135%',
    color: 'rgba(133, 134, 151, 1)'
  },
  label: {
    color: '#858697'
  },
  forgottenPasswordLink: {
    display: 'block',
    textAlign: 'right',
    margin: '-5px 0px 30px 0px',
    color: 'rgba(13, 169, 178, 1)'
  },
  signUpBtn: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  signInLink: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  link: {
    color: 'rgba(13, 169, 178, 1)',
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer'
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  inputHeight: {
    '& .MuiFormControl-fullWidth': {
      height: 70,
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginTop: -5
    }
  },
  selectWrapper: {
    marginTop: 8,
    height: 86
  },
  select: {
    '& fieldset': {
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px'
    },
  },
  imageUploader: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& .MuiButton-outlinedSizeSmall': {
      padding: '3px 0px'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  wrapper: {
    height: 'calc(100vh - 300px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 400,
    height: 400,
    width: 400,
    background: '#E1ECFD',
  },
  message: {
    fontSize: 20,
    textAlign: 'center',
  },
  invalid: {
    color: colors.primaryError,
    margin: '-3px 0px',
    fontSize: 15
  },
  errorContainer: {
    textAlign: 'center'
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  },
  addImage: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default useStyles;