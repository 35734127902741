import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  info: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      '&:nth-of-type(n+2)': {
        marginTop: 8
      },
      '& img': {
        marginRight: 8
      }
    }
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: '#0033B7',
    cursor: 'pointer',
  },
  ranking: {
    marginLeft: 16,
    lineHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  rankingIcon: {
    opacity: 0.24
  },
  rankingMobile: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
}));

export default useStyles;