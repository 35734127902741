import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sponsor: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'baseline',
    },
  },
  buttonPadding: {
    padding: 10,
    margin: '15px 0px',
  },
  sponsorContainerInner: {
    position: 'relative'
  },
  sponsorContainerOuter: {
    marginTop: 15,
    maxWidth: '50%',
  },
  inputUpload: {
    display: 'none',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderRadius: 8
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    opacity: 0.5,
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
      transform: 'scale(0.75, 0.75)',
    },
  },
}));

export default useStyles;