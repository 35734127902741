import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  button: {
    marginTop: 24
  },
  tournament: {
    margin: '10px 0'
  },
  section: {
    height: 200
  }
}));

export default useStyles;