import React from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from '../../Playoff.styles';
import PlayoffRounds from '../../../../../../../models/pages/tournament/playoff/PlayoffRounds';
import { pairs, stages } from '../../../../../../../utils/enum/Enum';
import { modifyRound, setRoundsOrder } from '../../Playoff.utils';
import SetFinalStage from '../../components/SetFinalStage';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

function SemiFinals(props: {
  semiFinalRounds: PlayoffRounds[]
  getPlayoffRounds: EmptyVoidFunction
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    semiFinalRounds, getPlayoffRounds, setIsLoaded, isEditMode
  } = props;

  return (
    <div className={classes.semiFinals}>
      {!isMobile && <div className={clsx(classes.header, classes.quarterHeader, classes.headerSemiFinal)}>{l10n.components.playoffSchedule.SEMIFINAL}</div>}
      <div className={clsx(classes.flexColumn, { [classes.semiParticipants]: !isMobile, [classes.sectionParticipants]: isMobile })}>
        {isMobile && <div className={classes.header}>{l10n.components.playoffSchedule.SEMIFINAL}</div>}
        {setRoundsOrder(pairs.semiFinals, semiFinalRounds).map((round) => (
          <SetFinalStage
            setIsLoaded={setIsLoaded}
            round={round ? modifyRound(round) : null}
            key={round?.id || uuidv4()}
            getPlayoffRounds={getPlayoffRounds}
            stage={stages.semiFinals}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    </div>
  );
}

export default SemiFinals;