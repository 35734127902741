import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  button: {
    fontFamily: "'Source Sans Pro', sans-serif",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
    border: '1px solid',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    color: '#FFFFFF',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all 0.4s ease-in-out',
    '&:focus-visible': {
      boxShadow: '0px 0px 0px 2px #A0D3D7'
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
      opacity: 0.5
    },
    '&.primary': {
      '&.solid': {
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        '&:hover': {
          borderColor: colors.primaryLight,
          backgroundColor: colors.primaryLight,
        },
        '&:active': {
          borderColor: colors.primaryDark,
          backgroundColor: colors.primaryDark,
        },
      },
      '&.outline': {
        color: colors.primary,
        borderColor: colors.primary,
        '&:hover': {
          color: colors.primaryLight,
          borderColor: colors.primaryLight,
        },
        '&:active': {
          color: colors.primaryDark,
          borderColor: colors.primaryDark,
        },
      },
    },
    '&.secondary': {
      '&.solid': {
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
        '&:hover': {
          backgroundColor: '#474752',
          borderColor: '#474752',
        },
        '&:active': {
          backgroundColor: colors.secondaryDark,
          borderColor: colors.secondaryDark,
        },
      },
      '&.outline': {
        color: colors.secondary,
        borderColor: colors.secondary,
        '&:hover': {
          color: '#474752',
          borderColor: '#474752',
        },
        '&:active': {
          color: colors.secondaryDark,
          borderColor: colors.secondaryDark,
        },
      },
    },
    '&.sm': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',
      padding: '4px 9px'
    },
    '&.md': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '22px',
      padding: '9px 19px'
    },
    '&.ml': {
      width: '100%',
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '28px',
      padding: '10px 19px'
    },
    '&.lg': {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
      padding: '11px 31px'
    },
    '& img.icon, svg.icon': {
      marginRight: 10,
      verticalAlign: 'middle'
    }
  },
  socialMediaButton: {
    padding: 0,
    '& > .MuiIconButton-label > .MuiSvgIcon-root': {
      width: 50,
      height: 50,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;