import React from 'react';
import { Grid, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../Playoff.styles';
import ModifiedPlayoffRound from '../../../../../../models/pages/tournament/playoff/ModifiedPlayoffRound';
import EmptyCard from './Empty';
import CardParticipant from './Participant';
import fish from '../../../../../../assets/icons/fish.svg';
import { stages } from '../../../../../../utils/enum/Enum';
import RoundResult from '../../../../../../models/pages/tournament/playoff/RoundResult';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';
import { onInputHandleChange } from '../../../../../../utils/Utils';

const defaultProps = {
  round: null,
  stage: stages.quarterFinals,
};

function InlineRound(props: {
  round?: ModifiedPlayoffRound | null,
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  stage?: stages
  winnerId: number | null | undefined,
  isEditMode: boolean,
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    round, setParticipant, winnerId, stage, isEditMode
  } = props;

  const onBlurHandleChange = async ({ value, id }: {value: string, id: number | undefined}) => {
    setParticipant({
      participantId: id,
      participantCatches: value !== '' ? Number(value) : null,
    });
  };

  return (
    <>
      {stage === stages.firstPlace && <span className={classes.resultWinnerText}>{l10n.components.playoffSchedule.FIRST_PLACE}</span>}
      {stage === stages.thirdPlace && <span className={classes.resultWinnerText}>{l10n.components.playoffSchedule.THIRD_PLACE}</span>}
      <div className={classes.flexRow}>
        {round
          ? (
            <>
              <CardParticipant
                player={round.firstParticipant}
                stage={stage}
                winnerId={winnerId}
              />
              <Grid container alignItems="center" justify="center" direction="column" wrap="nowrap">
                <img src={fish} alt="fish" />
                {isEditMode && (
                <div className={classes.flexRow}>
                  <OutlinedInput
                    disabled={!!winnerId}
                    defaultValue={round?.firstParticipant?.catches}
                    className={clsx(classes.input, { [classes.winnerInput]: round.firstParticipant?.id === winnerId })}
                    type="number"
                    name="firstParticipant"
                    inputComponent="input"
                    inputProps={{
                      min: '0',
                    }}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
                    onBlur={(event: any) => onBlurHandleChange({ value: event.target.value, id: round.firstParticipant?.id })}
                  />
                  <div className={clsx(classes.resultPairText, classes.dash)}>-</div>
                  <OutlinedInput
                    disabled={!!winnerId}
                    defaultValue={round?.secondParticipant?.catches}
                    className={clsx(classes.input, { [classes.winnerInput]: round.secondParticipant?.id === winnerId })}
                    type="number"
                    name="secondParticipant"
                    inputComponent="input"
                    inputProps={{
                      min: '0',
                    }}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
                    onBlur={(event: any) => onBlurHandleChange({ value: event.target.value, id: round.secondParticipant?.id })}
                  />
                </div>
                )}
                {!isEditMode
                && (
                <div className={classes.resultPairText}>
                  <span className={clsx({ [classes.subWinner]: (round?.firstParticipant?.id === winnerId) })}>{round?.firstParticipant?.catches}</span>
                  <span>-</span>
                  <span className={clsx({ [classes.subWinner]: (round?.secondParticipant?.id === winnerId) })}>{round?.secondParticipant?.catches}</span>
                </div>
                )}
              </Grid>
              <CardParticipant
                player={round.secondParticipant}
                stage={stage}
                winnerId={winnerId}
              />
            </>
          )
          : (
            <>
              <EmptyCard />
              <EmptyCard />
            </>
          )}
      </div>
    </>
  );
}

InlineRound.defaultProps = defaultProps;

export default InlineRound;