import React from 'react';

const RatingValue = (props: {
  points: number | undefined | null
}) => {
  const { points } = props;

  return (
    <>{Number.isFinite(points) ? points : '-'}</>
  );
};

export default RatingValue;