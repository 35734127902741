import React, { SetStateAction } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Report from '../../../../../../../../models/components/report/Report';
import useStyles from '../../ReportCard.styles';
import useTranslation from '../../../../../../../../components/localization/customHooks/Translation';
import { toLocalDate, getImageUrl } from '../../../../../../../../utils/Utils';
import RTEtext from '../../../../../../../../components/RTEtext/RTEtext';
import ReportPreviewImg from '../previewImgReport/ReportPreviewImg';
import Routes from '../../../../../../../../routing/Routes';
import EditDeleteCell from '../editDeleteCell/EditDeleteCell';
import AdminUser from '../../../../../../../../models/pages/user/AdminUser';
import getPreviewContent from '../../ReportCard.utils';

const MobileCard = (props: {
    anglerId: string,
    userInfo: AdminUser,
    report: Report,
    setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>,
    setIsDeleteModalOpened: React.Dispatch<SetStateAction<boolean>>,
}) => {
  const {
    anglerId,
    userInfo,
    report,
    setIsEditModalOpened,
    setIsDeleteModalOpened
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerMobile}>
        <div className={classes.tournamentInfo}>
          <div>{toLocalDate(report.postedAt)}</div>
          <div>
            <Link className={classes.link} to={Routes.TournamentCard.path.replace(':id', String(report.tournament.id))}>
              {report.tournament.name}
            </Link>
          </div>
        </div>
        <div className={classes.locationWrapper}>
          {report.tournament.location.country
          && report.tournament.location.country.flag
          && <img className={classes.flag} src={getImageUrl(report.tournament.location.country.flag.url)} alt="country flag" />}
          <Link className={classes.link} to={Routes.Location.path.replace(':id', String(report.tournament.location.id))}>
            {report.tournament.location.name}
          </Link>
        </div>
      </div>
      <ReportPreviewImg report={report} />
      <div className={classes.contentWrapper}>
        <Typography variant="h2">{ report.name }</Typography>
        <div>
          <RTEtext text={getPreviewContent(report) || ''} />
        </div>
        <div>
          <Link className={clsx(classes.readMore, classes.link)} to={Routes.TournamentReport.path.replace(':id', report.id)}>
            {l10n.shared.READ_MORE}
          </Link>
        </div>
      </div>
      {userInfo && userInfo.id === anglerId ? (
        <div className={classes.control}>
          <EditDeleteCell
            report={report}
            setIsEditModalOpened={setIsEditModalOpened}
            setIsDeleteModalOpened={setIsDeleteModalOpened}
          />
        </div>
      ) : null }
    </div>
  );
};

export default MobileCard;