import { remove, get, post } from '../Http';

const url = '/api/Images/';

const ImagesService = {
  async get(id: number) {
    return get(url + id);
  },
  async post(image: any) {
    return post(url, image);
  },
  async delete(id: number) {
    return remove(url + id);
  },
};

export default ImagesService;