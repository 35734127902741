import { ComponentType } from 'react';
import About from '../pages/about';
import Home from '../pages/home/Home';
import AnglersRating from '../pages/anglersRating/AnglersRating';
import Demo from '../pages/demo/Demo';
import Admin from '../pages/admin';
import EditLocationPage from '../pages/editLocation';
import Tournaments from '../pages/admin/tournaments/Tournaments';
import AdminUsers from '../pages/admin/users/Users';
import AdminPublications from '../pages/admin/news/News';
import AvailableTournaments from '../pages/availableTournaments/AvailableTournaments';
import Publication from '../pages/publication/Publication';
import { adminRole, organizerRole } from '../utils/constants/Constants';
import Dictionaries from '../pages/admin/dictionaries/Dictionaries';
import TeamsTable from '../pages/admin/teams/Teams';
import Edit from '../pages/admin/tournaments/edit/Edit';
import Locations from '../pages/locations/Locations';
import News from '../pages/news/News';
import Teams from '../pages/teams/Teams';
import UserCard from '../pages/user/UserCard';
import TournamentCard from '../pages/availableTournaments/components/tournamentCard/TournamentCard';
import TournamentReport from '../pages/tournamentReport/TournamentReport';
import LocationCard from '../components/locationCard/LocationCard';
import { getTranslation } from '../components/localization/customHooks/Translation';
import { hasRolesIntersection, StorageService } from '../utils/Utils';

const l10n = getTranslation();

interface RouteValue {
  path: string;
  name: string;
  component: ComponentType;
  rolesAllowed?: string[];
  canActivateFunc?: () => boolean;
}

const isAccessAllowedByRoleFunc = (roles: string[]) => () => hasRolesIntersection(roles, StorageService.getUserData());

const Routes: { [key: string]: RouteValue } = {
  Home: { path: '/', name: l10n.components.routes.AREATROUT_COMPETITIONS, component: Home },
  Rating: { path: '/rating', name: l10n.pages.SPORTSMAN_RATINGS, component: AnglersRating },
  Locations: { path: '/locations', name: l10n.components.routes.LOCATIONS_LIST, component: Locations },
  News: { path: '/news', name: l10n.pages.NEWS, component: News },
  About: { path: '/about', name: l10n.pages.ABOUT_US, component: About },
  Demo: { path: '/demo', name: l10n.components.routes.DEMO, component: Demo },
  Admin: {
    path: '/admin',
    name: l10n.components.routes.ADMINISTARTION,
    component: Admin,
    rolesAllowed: [adminRole, organizerRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole, organizerRole]),
  },
  AdminUser: {
    path: '/admin/users',
    name: l10n.components.routes.USER_MANAGEMENT,
    component: AdminUsers,
    rolesAllowed: [adminRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole]),
  },
  EditLocation: {
    path: '/admin/location/:id',
    name: l10n.components.routes.LOCATION_EDITING,
    component: EditLocationPage,
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole]),
  },
  AdminTournaments: {
    path: '/admin/tournaments',
    name: l10n.components.routes.TOURNAMENT_ADMINISTARTION,
    component: Tournaments,
    rolesAllowed: [adminRole, organizerRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole, organizerRole]),
  },
  Publications: {
    path: '/admin/publications',
    name: l10n.components.routes.ARTICLES_CONTROL,
    component: AdminPublications,
    rolesAllowed: [adminRole, organizerRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole, organizerRole]),
  },
  EditTournaments: {
    path: '/admin/tournaments/edit/:id',
    name: l10n.components.routes.TOURNAMENT_EDITING,
    component: Edit,
    rolesAllowed: [adminRole, organizerRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole, organizerRole]),
  },
  AdminDictionaries: {
    path: '/admin/dictionaries',
    name: l10n.components.routes.DICTIONARIES,
    component: Dictionaries,
    rolesAllowed: [adminRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole]),
  },
  AdminTeams: {
    path: '/admin/teams',
    name: l10n.components.routes.TEAMS,
    component: TeamsTable,
    rolesAllowed: [adminRole],
    canActivateFunc: isAccessAllowedByRoleFunc([adminRole]),
  },
  AvailableTournaments: {
    path: '/tournaments',
    name: l10n.components.routes.TOURNAMENTS_LIST,
    component: AvailableTournaments,
  },
  Teams: {
    path: '/teams',
    name: l10n.components.routes.TEAMS,
    component: Teams,
  },
  TournamentCard: {
    path: '/tournaments/:id',
    name: l10n.components.routes.TOURNAMENT_CARD,
    component: TournamentCard,
  },
  User: {
    path: '/rating/user/:id',
    name: l10n.components.routes.USER_DATA,
    component: UserCard
  },
  Location: {
    path: '/locations/:id',
    name: l10n.components.routes.LOCATION_CARD,
    component: LocationCard
  },
  TournamentReport: {
    path: '/reports/:id',
    name: l10n.components.routes.REPORT_CARD,
    component: TournamentReport
  },
  Publication: {
    path: '/articles/:id',
    name: l10n.components.routes.PUBLICATION_CARD,
    component: Publication,
  },
  TournamentInfo: {
    path: '/tournaments/:id/info',
    name: l10n.components.routes.TOURNAMENT_INFO,
    component: TournamentCard,
  }
};

export default Routes;