import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  active: {
    backgroundColor: 'rgba(13, 169, 178, 0.3)'
  },
  addButton: {
    width: 75,
    height: 50,
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      width: 65,
      height: 40,
    },
  },
  addIcon: {
    transform: 'scale(1.3)'
  },
  addImage: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addTeamModal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 660,
      width: '100%',
      maxHeight: 600,
      height: '100%',
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        maxWidth: '100%',
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      [theme.breakpoints.down('md')]: {
        maxHeight: '100%'
      },
    }
  },
  addTeamContainer: {
    width: '82%',
    height: '100%',
    margin: 'auto'
  },
  button: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
  },
  teams: {
    display: 'block',
    maxWidth: 1360,
    margin: 'auto',
    padding: '50px 0',
    [theme.breakpoints.down('md')]: {
      margin: 'auto 10px',
    },
  },
  teamsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 50,
    gap: 80,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      alignItems: 'center'
    },
  },
  teamFormContainer: {
    marginTop: 50,
  },
  invitationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      gap: 10
    },
  },
  emptyGap: {
    gap: 0,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 48,
    lineHeight: '60px',
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  },
  basicText: {
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: '30px'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: 0
    }
  },
  nameTeamColumn: {
    height: 104,
    [theme.breakpoints.down('xs')]: {
      height: 90,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80%',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      maxHeight: 600,
      justifyContent: 'center',
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
    }
  },
  teamsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    marginBottom: 115,
    maxWidth: 1360,
    width: '100%'
  },
  invalid: {
    color: colors.primaryError,
    width: '100%',
    height: 22,
    margin: 0
  },
  label: {
    color: '#858697'
  },
  listItem: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    },
    '&:active, &:focus': {
      backgroundColor: 'rgba(13, 169, 179, 0.3)',
    },
    '& > label': {
      width: '100%',
    }
  },
  menuItemContainer: {
    height: 40,
    paddingLeft: 8,
    [theme.breakpoints.down('xs')]: {
      height: 32,
      minHeight: 0,
      paddingLeft: 24
    },
  },
  smallText: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px'
  },
  errorText: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px'
  },
  modalHeader: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '38px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
      lineHeight: '30px',
      marginBottom: 15
    },
  },
  teamData: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 0
    },
  },
  select: {
    '& > div > div > div': {
      minWidth: 'auto',
      width: '100%'
    },
    '& > div > p': {
      marginLeft: 0
    },
  },
  singleItem: {
    paddingLeft: 24,
  },
  singlelist: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(13, 169, 178, 0.3)',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    }
  },
  partnerContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: '4px 10px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      gap: 5
    },
  },
  deleteItems: {
    width: 24,
    height: 24,
    zIndex: 100,
    border: 'none',
    borderRadius: '50%',
    background: 'inherit',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    },
    '&:active': {
      backgroundColor: 'rgba(13, 169, 178, 0.3)'
    }
  },
  partnerData: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  partnerError: {
    display: 'block',
    marginRight: 'auto'
  },
  teamError: {
    textAlign: 'center',
    marginTop: 10
  },
  buttonSubmit: {
    marginTop: 30
  },
  sendEmailButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    border: 'none',
    backgroundColor: 'transparent',
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  findPartnerBtn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  submit: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 80
    },
  },
  hideBtn: {
    display: 'none'
  },
  disabledInput: {
    opacity: 0.6,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 260
    },
  },
  buttonContainer: {
    width: '100%',
    maxWidth: 210,
  },
  smallContainer: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 40,
      alignSelf: 'end',
      marginBottom: 4,
    },
  },
  hideContainer: {
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  wideContainer: {
    maxWidth: 240
  },
  emptyTeamList: {
    textAlign: 'center',
    paddingTop: 25,
  },
}));

export default useStyles;