import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStyles from './FinishedTournamentCard.styles';
import FinishedTournament from '../../../models/pages/tournament/FinishedTournament';
import Tags from '../../tags/Tags';
import video from '../../../assets/icons/video.svg';
import Date from '../../date/Date';
import FinishedTournamentInfo from './info/Info';
import Winners from './winners/Winners';
import Routes from '../../../routing/Routes';
import useTranslation from '../../localization/customHooks/Translation';

const FinishedTournamentCard = observer((props: {
  tournamentData: FinishedTournament | undefined,
  className?: string
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { tournamentData, className } = props;

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.weatherWrapper}>
        <div className={classes.content}>
          <div className={classes.title}>
            <Date date={tournamentData?.tournamentDate} />
            <div className={classes.titleInfo}>
              <Link to={Routes.TournamentCard.path.replace(':id', String(tournamentData?.tournamentId))}>
                <h3 className={classes.titleText}>{tournamentData?.tournamentName}</h3>
              </Link>
              <div className={classes.titleTags}>
                <Tags variant="event-completed" className={classes.titleTag}>{l10n.components.finishedTournament.FINISHED}</Tags>
                <Tags variant="event-icon"><img src={video} alt="video" /></Tags>
              </div>
            </div>
          </div>
          <FinishedTournamentInfo tournament={tournamentData} />
        </div>
      </div>
      <Winners winners={tournamentData?.topThreeParticipants} />
    </div>
  );
});

export default FinishedTournamentCard;