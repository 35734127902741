import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    margin: 0,
    marginTop: '5px',

    '& span': {
      whiteSpace: 'nowrap'
    }
  },
}));

export default useStyles;