/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import colors from '../../../../../../../styles/Variables';

const StyledCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.lightGrey,
    color: colors.darkBlue,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCell = (props: any) => <StyledCell align={props.align}>{props.children}</StyledCell>;

export default StyledTableCell;