import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import useStyles from './ChangePassword.styles';
import schema from './helpers/ValidationSchema';
import ATButton from '../../button/Button';
import contentComponents from '../helpers/Constants';
import AuthorizationService from '../../../services/main/Authorization.service';
import PasswordInput from '../../inputs/password/Password';
import Loader from '../../loader/Loader';
import Toasters from '../../popUp/PopUp';
import { useQuery } from '../../../utils/Utils';
import AuthorizationResponse from '../../../models/components/authorization/AuthorizationResponse';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  setModalComponent: null
};

interface ChangePasswordModel {
  password: string,
  confirmPassword: string
}

const ChangePassword = (props: {
    setModalComponent?: React.Dispatch<React.SetStateAction<JSX.Element>>
}) => {
  const { setModalComponent } = props;
  const styles = useStyles();
  const l10n = useTranslation();

  const query = useQuery();
  const id = query.get('userId');
  const token = query.get('token');

  const { control, handleSubmit, formState: { errors } } = useForm<ChangePasswordModel>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const changePasswordHandler = async (data: { password: string }) => {
    setIsLoading(true);
    if (id && token) {
      const response: AuthorizationResponse = await AuthorizationService.changePassword(id, data.password, token);

      if (!response && setModalComponent) {
        setModalComponent(contentComponents.ChangePasswordResult);
      }
      if (response && response.message) {
        Toasters.error('Произошла ошибка при сбросе пароля, пожалуйста, попробуй повторить операцию.');
      }
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Typography className={styles.title}>{l10n.components.authorization.TITLE_CHANGE_PASSWORD}</Typography>
        <form
          className={styles.form}
          onSubmit={handleSubmit(changePasswordHandler)}
        >
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div className={styles.flexColumn}>
                <PasswordInput
                  label={l10n.components.authorization.LABEL_NEW_PASSWORD}
                  name="password"
                  errors={errors.password}
                  field={{ ...field }}
                />
              </div>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <div className={styles.flexColumn}>
                <PasswordInput
                  label={l10n.components.authorization.CONFIRM_NEW_PASSWORD}
                  name="confirmPassword"
                  errors={errors.confirmPassword}
                  field={{ ...field }}
                />
              </div>
            )}
          />
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            isSubmit
            className={styles.button}
          >
            {l10n.components.authorization.BUTTON_APPLY_NEW_PASSWORD}
          </ATButton>
        </form>
      </div>
    </div>
  );
};

ChangePassword.defaultProps = defaultProps;

export default ChangePassword;