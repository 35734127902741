import React, { useState } from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import useStyles from './DeleteReportModal.styles';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const DeleteReportModal = observer((props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const { ReportsStore } = useStores();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendDeleteRequest = async () => {
    setIsLoading(true);
    const response = await ReportsStore.deleteReport();
    if (response) {
      Toasters.success(l10n.components.tournamentReports.DELETE_REPORT_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.tournamentReports.DELETE_REPORT_QUESTION}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-testid="cancelBtn"
        >
          {l10n.shared.CANCEL}
        </Button>
        <Button
          onClick={sendDeleteRequest}
          color="primary"
          variant="contained"
          autoFocus
          data-testid="deleteBtn"
        >
          {l10n.shared.DELETE}
        </Button>
      </DialogActions>
    </>
  );
});

export default DeleteReportModal;