import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import IncomingInvitationData from '../../../../../models/pages/team/IncomingInvitationData';
import useStyles from './Card.styles';
import MemberAvatar from '../../../../../components/member/avatar/Avatar';
import accept from '../../../../../assets/icons/accept.svg';
import reject from '../../../../../assets/icons/decline.svg';
import useConfirmationDialog from '../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import checkMarkAccept from '../../../../../assets/icons/checkMarkAccept.svg';
import checkMarkFailed from '../../../../../assets/icons/checkMarkFailed.svg';
import TeamsService from '../../../../../services/main/Team.service';
import { EmptyVoidFunction } from '../../../../../utils/types/Types';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const InvitationCard = (props: {
  data: IncomingInvitationData
  getInvitations: EmptyVoidFunction
  refresh: EmptyVoidFunction
}) => {
  const { data, getInvitations, refresh } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const { Dialog: AcceptDialog, onOpen: onAcceptOpen, onClose: onAcceptClose } = useConfirmationDialog({
    className: classes.modal,
    headerText: '',
    bodyText: (
      <div>
        <div className={classes.title}>
          <img src={checkMarkAccept} alt="check-mark-accept" />
          <span>{l10n.pages.ACCEPT_INVITATION}</span>
        </div>
        <div className={classes.text}>{l10n.components.confirmDialogsMessage.ACCEPT_INVITATION}</div>
      </div>
    ),
    cancelButtonText: l10n.components.button.CANCEL,
    confirmationButtonText: l10n.components.button.CONTINUE,
    onConfirmClick: async () => {
      await TeamsService.acceptInvitation(data.invitationId);
      onAcceptClose();
      getInvitations();
      refresh();
    },
    onCancelClick: () => {
      onAcceptClose();
    }
  });

  const { Dialog: RejectDialog, onOpen: onRejectOpen, onClose: onRejectClose } = useConfirmationDialog({
    className: classes.modal,
    headerText: '',
    bodyText: (
      <div>
        <div className={classes.title}>
          <img src={checkMarkFailed} alt="check-mark-failed" />
          <span>{l10n.pages.DECLINE_INVITATION}</span>
        </div>
        <div className={classes.text}>{l10n.components.confirmDialogsMessage.DECLINE_INVITATION}</div>
      </div>
    ),
    cancelButtonText: l10n.components.button.CANCEL,
    confirmationButtonText: l10n.components.button.CONTINUE,
    onConfirmClick: async () => {
      await TeamsService.rejectInvitation(data.invitationId);
      refresh();
      onRejectClose();
      getInvitations();
    },
    onCancelClick: () => {
      onRejectClose();
    }
  });

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container alignItems="center" className={classes.teamWrapper}>
          <MemberAvatar
            countryFlag={data.teamModel.country.flag}
            profilePicture={data.teamModel.picture}
            avatarStyle="medium"
          />
          <div className={classes.name}>{data.teamModel.name}</div>
        </Grid>
        <div className={classes.person}>
          <div className={classes.personText}>{`${l10n.pages.INVITATION_FROM}:  `}</div>
          <div className={classes.personInfo}>
            <MemberAvatar countryFlag={data.teamModel.captain.country?.flag} profilePicture={data.teamModel.captain.profilePicture} />
            <span className={classes.personName}>{data.teamModel.captain.name}</span>
          </div>
        </div>
        <Grid container wrap="nowrap">
          <div className={classes.button}>
            <IconButton
              size="small"
              onClick={() => {
                onAcceptOpen();
              }}
            >
              <img src={accept} alt="accept-icon" />
            </IconButton>
          </div>
          <div className={classes.button}>
            <IconButton
              size="small"
              onClick={() => onRejectOpen()}
            >
              <img src={reject} alt="decline-icon" />
            </IconButton>
          </div>
        </Grid>
      </div>
      <AcceptDialog />
      <RejectDialog />
    </>
  );
};

export default InvitationCard;