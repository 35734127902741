import React, { useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import useConfirmationDialog from '../../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import useStyles from './DownloadPdf.style';
import Participant from '../../../../../../models/pages/tournament/Participant';
import noAvatar from '../../../../../../assets/noAvatar.png';
import { dimensions, fonts } from './helpers/Constants';
import { getDocumentContent, getDocumentStyles, createBase64Url } from './DownloadPdf.utils';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = fonts;

const DownloadPdf = (props: {
  participants: Participant[]
  tournamentName: string
  tournamentDate: string
  roundsQuantity: number
  id: number
}) => {
  const classes = useStyles();
  const {
    participants, tournamentName, tournamentDate, id, roundsQuantity
  } = props;
  const l10n = useTranslation();

  const [isLoading, setIsloading] = useState(false);
  const roundsHeight = roundsQuantity * dimensions.roundCellHeight;

  const marginBottom = (dimensions.a4Height % (dimensions.headerHeight + roundsHeight + dimensions.gap)) - dimensions.marginTop;
  let imagesToLoad = 0;
  const makePdf = () => {
    const docDefinition = {
      pageMargins: [dimensions.horizontalMargin, dimensions.marginTop, dimensions.horizontalMargin, marginBottom] as [number, number, number, number],
      content: getDocumentContent(participants, tournamentName, tournamentDate, id),
      styles: getDocumentStyles(),
      images: {
        noAvatar,
      }
    };
    pdfMake.createPdf(docDefinition).download('ParticipantCards.pdf');
  };

  const participantsWithImages = participants.filter((participant) => (participant.angler.profilePicture?.thumbnailFilename));
  const preloadImages = () => participantsWithImages.forEach((participant) => {
    const myImage = new Image(100, 100);
    myImage.crossOrigin = 'anonymous';
    myImage.src = `${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${participant.angler.profilePicture?.url}?v=${new Date()}`;
    myImage.onload = () => {
      imagesToLoad++;
      participant.dataURL = createBase64Url(myImage);
      if (imagesToLoad === participantsWithImages.length) {
        makePdf();
      }
    };
  });

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: l10n.components.headerLabel.DOWNLOAD_PDF_CARDS,
    bodyText: l10n.components.confirmDialogsMessage.DOWNLOAD_PDF_CARDS,
    confirmationButtonText: l10n.components.button.CONTINUE,
    onConfirmClick: () => {
      onClose();
      setIsloading(true);
      preloadImages();
      setIsloading(false);
    },
  });

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => onOpen()}>
        {l10n.components.button.DOWNLOAD_PDF_CARDS}
      </Button>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog />
    </>
  );
};

export default DownloadPdf;