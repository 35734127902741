import defaultSelectValues from '../../helpers/Constants';
import Country from '../../../models/components/dictionaries/country/Country';
import SelectItem from '../../../models/components/selects/SelectItem';
import {
  remove,
  get,
  patch,
  post,
  put,
} from '../../Http';

const url = '/api/Countries/';

const CountryService = {
  async getAsSelectItems(): Promise<SelectItem[]> {
    const countries = (await CountryService.get()) || [];
    return countries.map((country: SelectItem) => ({
      ...country,
      isDefault: country.name === defaultSelectValues.country,
    }));
  },

  async get() {
    return get(url);
  },

  async getById(id: string) {
    return get(`${url}${id}`);
  },

  async post(country: Country) {
    return post(url, country);
  },

  async put(country: Country) {
    const { id } = country;
    return put(url + id, country);
  },

  async delete(id: number) {
    return remove(url + id);
  },

  async postFlag(id: number, pictureId: number) {
    return patch(`${url}${id}/flag`, {
      imageId: pictureId,
      countryId: id,
    });
  },
};

export default CountryService;