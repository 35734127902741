import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { observer } from 'mobx-react';
import MetaDescription from '../../components/metaDescription/MetaDescription';
import useStyles from './Publication.styles';
import useTranslation from '../../components/localization/customHooks/Translation';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import { toLocalDate, getImageUrl } from '../../utils/Utils';
import Routes from '../../routing/Routes';
import { useStores } from '../../stores/Main';
import RTEtext from '../../components/RTEtext/RTEtext';
import ShareLinks from '../../components/shareLinks/ShareLinks';
import AnglerLink from '../../components/angler/anglerLink/AnglerLink';

const Publication = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();

  const { id } = useParams<{ id: string }>();

  const [carouselImages, setImages] = useState<ReactImageGalleryItem[]>([]);

  const { ArticlesStore } = useStores();

  const getImgForCarousel = (images: any) => images.map((image: any) => (
    { original: getImageUrl(image.image.url), originalClass: classes.imgCarousel }));

  const getArticle = async (articleId: string) => {
    await ArticlesStore.getArticleById(articleId);
    if (ArticlesStore.article) {
      const currentImages = getImgForCarousel(ArticlesStore.article.images || []);
      setImages(currentImages);
    }
  };

  useEffect(() => {
    getArticle(id);
  }, []);

  const urlNames = [
    { displayName: l10n.components.locationCard.MAIN_PAGE, url: Routes.Home.path },
    { displayName: l10n.components.routes.NEWS, url: '/news' },
    { displayName: `${ArticlesStore.article?.statusDescription}`, url: '/news' },
    { displayName: `${ArticlesStore.article?.name}`, url: '' }
  ];

  return (
    <>
      {ArticlesStore.article
        ? (
          <div className={classes.main}>
            <MetaDescription title={ArticlesStore.article.name} />
            <Breadcrumbs urls={urlNames} className={classes.breadCrumbs} />
            <div className={clsx(classes.newsTitle, classes.newsDescription)}>
              <Typography component="h2" className={clsx(classes.title, classes.breakText)}>{ArticlesStore.article.name}</Typography>
              <div className={classes.infoAboutReport}>
                <div className={classes.flexContainer}>
                  <AnglerLink
                    anglerData={{ ...ArticlesStore.article.author, id: ArticlesStore.article.authorId }}
                  />
                  <h4 className={classes.date}>{toLocalDate(ArticlesStore.article.postedAt)}</h4>
                </div>
              </div>
            </div>
            {!!carouselImages.length && (
              <div className={classes.photoGallery}>
                <ImageGallery
                  showPlayButton={false}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  autoPlay
                  slideInterval={5000}
                  items={carouselImages}
                />
              </div>
            )}
            <div className={classes.contentWrapper}>
              <div className={classes.reportText}>
                <div className={clsx(classes.reportHeader, classes.breakText)}>
                  <RTEtext text={ArticlesStore.article.description} />
                </div>
              </div>
              <ShareLinks />
            </div>
          </div>
        )
        : <LoaderFullScreen />}
    </>
  );
});

export default Publication;