import Rating from '../../models/components/dictionaries/rating/Rating';
import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';

const transformTournament = (tournaments: UpcomingTournamentEntry[]) => tournaments.map((tournament: UpcomingTournamentEntry) => {
  tournament.ratingsList = tournament.ratings.map((rating: Rating) => rating.name);
  tournament.country = tournament?.location?.country?.name;
  tournament.locationName = tournament?.location?.name;
  return tournament;
});

export default transformTournament;