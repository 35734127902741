import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  activationButtonConfirmed: {
    background: '#B6D7A8',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  activationButtonNotConfirmed: {
    background: colors.lightRedDarker,
  },
  modalWide: {
    position: 'absolute',
    top: -5,
    right: 0,
    fontSize: 20,
  },
  messageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    fontSize: 20,
    margin: 40,
  },
  messageBoxText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    margin: 'auto',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
  },
  ActivateFormModal: {
    '& .MuiDialog-paperWidthSm': {
      position: 'relative',
      width: '85%',
      height: '85%',
      maxWidth: 500,
      maxHeight: 300,
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      overflowY: 'hidden',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto',
      },
    }
  }
}));

export default useStyles;