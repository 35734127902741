import { createMuiTheme, makeStyles } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Source Sans Pro'
    ].join(','),
  },
});

theme.typography.h2 = {
  fontSize: 32,
  lineHeight: '38px',
  fontWeight: 700,
  [theme.breakpoints.down('xs')]: {
    fontSize: 28,
  },
};

const useStyles = makeStyles({
  '@global': {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#babac0 #fff',
    '::-webkit-scrollbar': {
      backgroundColor: '#fff',
      width: '1rem',
      height: '1rem'
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#f4f4f4'
      }
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: 16,
      border: '0.35rem solid #fff',
      '&:hover': {
        backgroundColor: '#a0a0a5',
        border: '0.3rem solid #f4f4f4',
      }
    },
    '::-webkit-scrollbar-button': {
      display: 'none'
    },
    html: {
      scrollbarWidth: 'thin',
      scrollbarColor: '#babac0 #fff',
      '& .MuiPaper-root, .MuiTable-root, .MuiBox-root, .MuiGrid-root': {
        scrollbarWidth: 'thin',
        scrollbarColor: '#babac0 #fff',
        '& *': {
          scrollbarWidth: 'thin',
          scrollbarColor: '#babac0 #fff',
        }
      }
    },
    body: {
      fontFamily: 'Source Sans Pro',
      overflowY: 'scroll',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    a: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '150%',
      color: '#141429',
      textDecoration: 'none'
    }
  },
  mainWrapper: {
    minHeight: 'calc(100vh - 356px)',
  }
});

export { useStyles, theme };