import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import TournamentService from '../../../../services/main/Tournament.service';
import useStyles from './TornamentCard.styles';
import TournamentCardData from '../../../../models/pages/tournament/TournamentCardData';
import TournamentCardInfo from './info/Info';
import TournamentCardSponsors from './sponsors/Sponsors';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import Results from './results/Results';
import { getImgUrlsList } from '../../../../utils/Utils';
import { tournamentStatus } from '../../../../utils/enum/Enum';
import modifyTournamentsData from './TournamentCard.utils';
import CarouselImage from '../../../../components/carouselImage/CarouselImage';
import PhotosGalleryState from '../../../../models/components/photoGallery/PhotosGalleryState';
import MetaDescription from '../../../../components/metaDescription/MetaDescription';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const TournamentCard = () => {
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tournament, setTournament] = useState<TournamentCardData | null>();
  const [photosData, setPhotosData] = useState<PhotosGalleryState>({
    isChangedPhotosOutside: false,
    photos: [],
  });

  const getTournament = async () => {
    setIsLoading(true);
    const tournamentById = await TournamentService.getTournamentInfo(id);
    if (tournamentById) {
      setTournament(modifyTournamentsData(tournamentById));
      setPhotosData((prev) => ({ ...prev, photos: [...tournamentById.photos] }));
    }
    setIsLoading(false);
  };

  const urlName = [
    { displayName: l10n.pages.MAIN_PAGE, url: '/' },
    { displayName: l10n.pages.TOURNAMENTS, url: '/tournaments' },
    { displayName: `${tournament?.name}`, url: '' }
  ];

  useEffect(() => {
    getTournament();
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <div className={classes.container}>
      {tournament
        && (
          <>
            <MetaDescription title={tournament.name} />
            <Breadcrumbs urls={urlName} className={classes.breadCrumbs} />
            <TournamentCardInfo tournament={tournament} getTournament={getTournament} />
            <TournamentCardSponsors selectedTournamentSponsors={tournament.sponsors} selectedMainSponsor={tournament.generalSponsor} />
            {tournament.statusId === tournamentStatus.Completed && tournament.isPublished && (
              <>
                <Results className={classes.results} roundsQuantity={tournament.template?.roundsCount} />
                <div className={classes.photoGallery}>
                  <Typography variant="h2">{l10n.pages.TOURNAMENT_IMAGES}</Typography>
                  { photosData.photos && photosData.photos.length ? (
                    <CarouselImage
                      urlList={getImgUrlsList(photosData.photos)}
                      imgStyles={classes.imgCarousel}
                      autoPlay
                      showThumbs
                    />
                  ) : (
                    <Typography variant="h6" gutterBottom align="center">
                      {l10n.components.photoGallery.EMPTY_PHOTO_LIST}
                    </Typography>
                  )}
                </div>
              </>
            )}
          </>
        )}
    </div>
  );
};

export default TournamentCard;