import React from 'react';
import clsx from 'clsx';
import useStyles from '../../../Playoff.styles';
import TrophyPlace from '../../../components/TrophyPlace';
import PlayoffParticipant from '../../../components/PlayoffParticipant';
import ModifiedPlayoffPlayer from '../../../../../../../../models/pages/tournament/playoff/ModifiedPlayoffPlayer';
import RoundResult from '../../../../../../../../models/pages/tournament/playoff/RoundResult';
import { getWinnerIcon } from '../../../Playoff.utils';

const defaultProps = {
  firstPlaceRound: false,
};

function FinalCard(props: {
  firstPlaceRound?: boolean
  player: ModifiedPlayoffPlayer | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean,
}) {
  const classes = useStyles();
  const {
    player, setParticipant, winnerId, firstPlaceRound, isEditMode
  } = props;

  return (
    <div className={clsx(
      classes.flexRow,
      classes.anglerContainer,
      classes.anglerFinalWrapper,
      classes.wideContainer,
      { [classes.winner]: player?.id === winnerId }
    )}
    >
      <PlayoffParticipant
        winnerId={winnerId}
        player={player}
        setParticipant={setParticipant}
        isEditMode={isEditMode}
      />
      <TrophyPlace icon={getWinnerIcon(firstPlaceRound, player?.id, winnerId)} />
    </div>
  );
}

export default FinalCard;

FinalCard.defaultProps = defaultProps;