import React, { useState, useEffect, ChangeEvent } from 'react';
import clsx from 'clsx';
import { Button, Grid, OutlinedInput } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../../../stores/Main';
import ButtonCreateCards from './buttonCreateCards/ButtonCreateCards';
import AreatroutTable from '../../../../../components/table';
import useStyles from './Drawing.style';
import Header from '../components/Header';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import TournamentService from '../../../../../services/main/Tournament.service';
import DrawedParticipant from '../../../../../models/pages/tournament/DrawedParticipant';
import useConfirmationDialog from '../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import { errorStyleBackgroundColor } from '../../../../../utils/constants/Constants';
import AreatroutModal from '../../../../../components/modal/Modal';
import ATButton from '../../../../../components/button/Button';
import ParticipantNumber from '../../../../../models/pages/tournament/ParticipantNumber';
import { onInputHandleChange } from '../../../../../utils/Utils';

const Drawing = observer(() => {
  const classes = useStyles();
  const { TournamentStore } = useStores();
  const { tournament } = TournamentStore;
  const l10n = useTranslation();
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const startIndex = 1;

  const [isLoading, setIsLoader] = useState(true);
  const [errorMessageIsOpen, setErrorMessageIsOpen] = useState(false);

  const updateHighlightForParticipant = (participant: DrawedParticipant) => {
    participant.rowStyles = participant.hasError ? errorStyleBackgroundColor : {};
  };

  const validateNumbersOrder = () => {
    const { activeParticipants } = TournamentStore;
    if (activeParticipants) {
      const sortedParticipants = activeParticipants
        .slice()
        .sort((left, right) => left.anglerNumber - right.anglerNumber);

      sortedParticipants.forEach((participant, index) => {
        participant.hasError = (participant.anglerNumber !== index + 1)
          || (sortedParticipants[index + 1]?.anglerNumber === participant.anglerNumber)
          || (sortedParticipants[index - 1]?.anglerNumber === participant.anglerNumber);
        updateHighlightForParticipant(participant);
      });

      return !activeParticipants.some(({ hasError }) => hasError);
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      await TournamentStore.getActiveParticipants(id);
      validateNumbersOrder();
      setIsLoader(false);
    };

    fetchData();
  }, [TournamentStore.isDrawing]);

  const onBlurHandleChange = async ({ value, anglerId }: { value: string, anglerId: string }) => {
    if (TournamentStore.activeParticipants) {
      const drawedParticipant = TournamentStore.activeParticipants.find((p) => p.anglerId === anglerId?.toString());
      if (drawedParticipant) {
        drawedParticipant.anglerNumber = Number(value);
      }
      validateNumbersOrder();

      TournamentStore.setActiveParticipants([...TournamentStore.activeParticipants]);
    }
  };

  const saveNumbers = async () => {
    if (!validateNumbersOrder()) {
      setErrorMessageIsOpen(true);
      return false;
    }

    if (TournamentStore.activeParticipants) {
      await TournamentService.updateNumbers(id, TournamentStore.activeParticipants as ParticipantNumber[]);
    }
    return true;
  };

  const columns = [
    {
      displayName: l10n.components.tableColumns.NUMBER,
      fieldName: 'anglerNumber',
      render: ({ anglerNumber, anglerId }: DrawedParticipant) => (
        <div className={clsx(classes.textStyle)}>
          <OutlinedInput
            value={anglerNumber}
            className={classes.input}
            type="number"
            name="anglerNumber"
            inputComponent="input"
            inputProps={{
              min: startIndex,
            }}
            onInput={(event: ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
            onChange={(event) => onBlurHandleChange({ value: event.target.value, anglerId })}
            disabled={TournamentStore.isDrawing}
          />
        </div>
      ),
    },
    {
      displayName: l10n.components.tableColumns.COUNTRY,
      fieldName: 'country',
      render: ({ angler }: DrawedParticipant) => (
        <>{angler?.country && angler?.country?.name}</>
      ),
    },
    {
      displayName: l10n.components.tableColumns.SPORTSMAN,
      fieldName: 'angler.fullName',
      render: ({ angler }: DrawedParticipant) => (
        <div className={clsx(classes.anglerName, classes.textStyle)}>{angler?.fullName}</div>
      ),
    },
  ];

  const assignRandomNumbers = async () => {
    setIsLoader(true);
    const response = await TournamentService.assignRandomNumbers(id);
    TournamentStore.setActiveParticipants(response);
    setIsLoader(false);
  };

  const resetRandomNumbers = async () => {
    setIsLoader(true);
    const response = await TournamentService.resetRandomNumbers(id);
    if (response) {
      TournamentStore.setIsDrawing(false);
    }
    setIsLoader(false);
  };

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: l10n.components.headerLabel.CANCEL_DRAWING,
    bodyText: (
      <div>
        <span>{l10n.components.confirmDialogsMessage.CANCEL_DRAWING}</span>
      </div>
    ),
    confirmationButtonText: l10n.components.button.CONFIRM,
    cancelButtonText: l10n.components.button.REFUSE,
    onCancelClick: () => onClose(),
    onConfirmClick: async () => {
      await resetRandomNumbers();
      await TournamentStore.getActiveParticipants(id);
      validateNumbersOrder();
      onClose();
    },
  });

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  if (TournamentStore.activeParticipants) {
    return (
      <>
        <Header text={`${l10n.components.headerLabel.DRAWING} "${tournament && tournament.name}"`} />
        <Grid container direction="row" justify="center">
          <Grid className={classes.tableWrapper}>
            <div className={classes.titleParticipants}>
              {`${l10n.pages.PARTICIPANTS_NUMBER}:  `}
              <span className={classes.titleParticipantsNumber}>
                {TournamentStore.activeParticipants?.length}
              </span>
            </div>
            <AreatroutTable
              columns={columns}
              rows={TournamentStore.activeParticipants}
              className={classes.table}
              isPaging={false}
            />
          </Grid>
          <Grid className={classes.contentWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={assignRandomNumbers}
              disabled={TournamentStore.isDrawing}
            >
              {l10n.pages.DEFINE_NUMBERS}
            </Button>
            <ButtonCreateCards saveNumbers={saveNumbers} />
            <Button
              variant="contained"
              color="primary"
              onClick={onOpen}
              disabled={!TournamentStore.isDrawing}
            >
              {l10n.pages.CANCEL_DRAWING}
            </Button>
            <Dialog />
          </Grid>
        </Grid>
        <AreatroutModal
          header={l10n.components.confirmDialogsMessage.HEADER_INVALIDNUMBERS}
          open={errorMessageIsOpen}
          setOpen={setErrorMessageIsOpen}
          className={classes.modal}
        >
          <div className={classes.buttonWrapper}>
            {l10n.components.confirmDialogsMessage.HEADER_INVALIDNUMBERS_MESSAGE}
            <ATButton
              variant="primary"
              fill="solid"
              size="md"
              onClick={() => setErrorMessageIsOpen(false)}
              className={classes.continueButton}
            >
              {l10n.components.button.CONTINUE}
            </ATButton>
          </div>
        </AreatroutModal>
      </>
    );
  }
  return (
    <Grid container direction="row" justify="center">
      <h3>{l10n.pages.NO_DATA}</h3>
    </Grid>
  );
});

export default Drawing;