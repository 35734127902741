import React, { useEffect, useState } from 'react';
import {
  Paper, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Table from '../../../../../components/table';
import ScrollableTableCell from '../../../../../components/scrollableTableCell/ScrollableTableCell';
import RoundCell from './components/cells/RoundCell';
import PlaceCell from '../../../../../components/tableTemplates/components/cell/place/Place';
import ColumnMetadata from '../../../../../models/components/table/ColumnMetadata';
import Round from '../../../../../models/pages/tournament/Round';
import { ParticipantInfo } from '../../../../../models/pages/tournament/Participant';
import useStyles from './Qualifiers.styles';
import TournamentService from '../../../../../services/main/Tournament.service';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import star from '../../../../../assets/icons/star.svg';
import { placeNumbersPassInPlayoff } from '../../../../../utils/constants/Constants';
import Angler from '../../../../../components/angler/Angler';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Qualifiers = observer(() => {
  const theme = useTheme();
  const l10n = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const url = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState<ParticipantInfo[]>([]);
  const [roundsNumber, setRoundsNumber] = useState<number>(0);
  const classes = useStyles();

  const getParticipants = async () => {
    setIsLoading(true);
    const response = await TournamentService.getTableData(Number(url.id));
    if (response) {
      const transformParticipants: ParticipantInfo[] = response;

      transformParticipants.sort((prev, next) => prev.participant.qualifyingPlace - next.participant.qualifyingPlace);
      setParticipants(transformParticipants);
      setRoundsNumber(transformParticipants[0].participant.rounds.length);
    }
    setIsLoading(false);
  };

  const getRound = ((rounds: Round[], i: number) => rounds.find((round) => round.roundNumber === i));

  const getRoundColumns = (column: ColumnMetadata[]) => {
    for (let i = 1; i <= roundsNumber; i++) {
      column.splice(i + 1, 0,
        {
          displayName: `${l10n.components.tableColumns.ROUND} ${i}`,
          fieldName: `Round ${i}`,
          allowSearch: false,
          allowSortring: false,
          render: ({ participant }: ParticipantInfo) => <RoundCell round={getRound(participant.rounds, i)} />
        });
    }
  };

  const getColumns = () => {
    const column = [
      {
        displayName: l10n.components.tableColumns.PLACE,
        fieldName: 'participant.qualifyingPlace',
        render: ({ participant: { qualifyingPlace } }: ParticipantInfo) => {
          const isPlayoffPlace: boolean = qualifyingPlace <= placeNumbersPassInPlayoff;
          return (
            <div className={clsx(classes.wrapperPlace, { [classes.wrapperPlayoffPlace]: isPlayoffPlace })}>
              <PlaceCell place={qualifyingPlace} isHaveImageAnyPlaces imageName={star} />
            </div>
          );
        }
      },
      {
        displayName: l10n.components.tableColumns.SPORTSMAN,
        fieldName: 'participant.angler.fullName',
        render: ({ participant: { angler }, isTeam }: ParticipantInfo) => <Angler anglerData={angler} isTeam={isTeam} />

      },
      {
        displayName: l10n.components.tableColumns.POINT,
        fieldName: 'participant.scoresSum',
        render: ({ participant: { scoresSum } }: ParticipantInfo) => scoresSum
      },
      {
        displayName: l10n.components.tableColumns.CATCHES,
        fieldName: 'participant.catchesSum',
        render: ({ participant: { catchesSum } }: ParticipantInfo) => catchesSum
      },
    ];
    getRoundColumns(column);

    return column;
  };

  useEffect(() => {
    getParticipants();
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      <Typography variant="h2" className={classes.h2}>{l10n.pages.QUALIFACATION_RESULTS}</Typography>
      {isMobile
        ? (
          <>
            {participants.map(({ participant, teamMembers }: ParticipantInfo) => {
              const isPlayoffPlace: boolean = participant.qualifyingPlace <= placeNumbersPassInPlayoff;
              const getRoundsList = Array.from({ length: roundsNumber }).map((_, ind) => (
                <RoundCell round={getRound(participant.rounds, ind + 1)} key={uuidv4()} />
              ));
              const participantResult = { icon: star, place: participant.finalPlace, angler: participant.angler };
              return (
                <ScrollableTableCell
                  showIcons={!isPlayoffPlace}
                  key={participant.anglerId}
                  participant={participantResult}
                  teamMembers={teamMembers}
                  scrolledContent={getRoundsList}
                />
              );
            })}
          </>
        )
        : (
          <Paper className={classes.wrapper} elevation={0}>
            <Table
              columns={getColumns()}
              rows={participants}
              isPaging={false}
              className={classes.table}
              isStickyHeader
            />
          </Paper>
        )}
    </>
  );
});

export default Qualifiers;