import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Loader from '../../loader/Loader';
import useStyles from '../RegistrationButton.styles';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import ATButton from '../../button/Button';
import TeamSelect from '../../selects/team/Team';
import TeamsService from '../../../services/main/Team.service';
import TeamSelectItem from '../../../models/components/selects/TeamSelectItem';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const TeamRegistration = (props: {
  tournamentId: number | undefined;
  handleClose?: EmptyVoidFunction;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    tournamentId, handleClose, setIsRegistered
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [menuItems, setMenuItems] = useState<Array<TeamSelectItem>>([]);
  const [teamId, setTeamId] = useState<number>(0);

  const classes = useStyles();
  const l10n = useTranslation();

  const getTeams = async () => {
    setIsLoading(true);
    if (tournamentId) {
      const response = await TeamsService.getAvailableTeams(tournamentId);
      if (response) {
        setMenuItems(response);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTeams();
  }, []);

  const registerTeam = async (id: number) => {
    if (id) {
      if (tournamentId) {
        const res = await TeamsService.register(id, tournamentId);
        if (res) {
          setIsRegistered(true);
        }
      }
      if (handleClose) {
        handleClose();
      }
    }
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <div className={classes.teamContainer}>
        <Typography className={classes.boldText}>{l10n.components.teamRegistrationModal.CHOOSE_TEAM}</Typography>
        <div className={classes.form}>
          {menuItems.length
            ? (
              <>
                <TeamSelect
                  menuItems={menuItems}
                  placeholder={l10n.components.teamRegistrationModal.CHOOSE_TEAM}
                  label={l10n.components.teamRegistrationModal.LABEL_TEAM}
                  setTeamId={setTeamId}
                />
              </>
            )
            : <div>{l10n.components.teamRegistrationModal.EMPTY_TEAM_LIST}</div>}
          <div className={classes.submitContainer}>
            <ATButton
              variant="primary"
              fill="solid"
              size="md"
              onClick={() => registerTeam(teamId)}
            >
              {l10n.components.teamRegistrationModal.SAVE_BUTTON}
            </ATButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamRegistration;

TeamRegistration.defaultProps = defaultProps;