import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import Routes from '../../routing/Routes';
import useStyles from './Menu.style';
import mainLogoBlur from '../../assets/logo/mainLogoBlur.png';
import burgerMenu from '../../assets/burgerMenu.png';
import closeBurgerMenu from '../../assets/icons/close.svg';
import UserProfile from '../profile/UserProfile';
import { hasRolesIntersection, StorageService } from '../../utils/Utils';
import { adminRole, organizerRole } from '../../utils/constants/Constants';
import MetaDescription from '../metaDescription/MetaDescription';
import LanguageSelect from '../selects/language/Language';
import useTranslation from '../localization/customHooks/Translation';

interface Route {
  name: string;
  path: string;
}

export default function MenuAppBar() {
  const styles = useStyles();
  const l10n = useTranslation();
  const navigationRoutes: Route[] = [Routes.AvailableTournaments, Routes.Rating, Routes.Locations, Routes.News, Routes.About];
  const adminNavigationRoutes: Route[] = [Routes.AdminUser, Routes.AdminTournaments, Routes.Publications, Routes.AdminDictionaries, Routes.AdminTeams];
  const organizerNavigationRoutes: Route[] = [Routes.AdminTournaments];
  const RoutesKeys = Object.keys(Routes);

  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [renderingPathes, setRenderingPathes] = useState(navigationRoutes);
  const [metaData, setMetaData] = useState('');
  const location = useLocation();
  const currentUser = StorageService.getUserData();

  const checkRoles = () => {
    if (location.pathname.match(Routes.Admin.path)) {
      if (hasRolesIntersection([adminRole], currentUser)) {
        setRenderingPathes(adminNavigationRoutes);
      } else if (hasRolesIntersection([organizerRole], currentUser)) {
        setRenderingPathes(organizerNavigationRoutes);
      }
    } else setRenderingPathes(navigationRoutes);
  };

  useEffect(() => {
    checkRoles();
    const currentRoute = RoutesKeys.find((key) => Routes[key].path === location.pathname);
    if (currentRoute) {
      setMetaData(Routes[currentRoute].name);
    }
  }, [location.pathname]);

  const menuLinks = renderingPathes.map((routePath) => (
    <li key={routePath.path} className={clsx({ [styles.active]: location.pathname.match(routePath.path) })}>
      <NavLink
        onClick={() => setBurgerMenuOpen(false)}
        to={routePath.path}
      >
        {routePath.name}
      </NavLink>
    </li>
  ));

  return (
    <div className={styles.wrapper}>
      <MetaDescription title={metaData} />
      <div className={styles.root}>
        <Button
          disableRipple
          onClick={() => setBurgerMenuOpen(true)}
          className={styles.burgerMenuIcon}
        >
          <img src={burgerMenu} alt="menu" className={styles.burgerMenuIcon} />
        </Button>
        <div className={styles.logo}>
          <NavLink
            to={Routes.Home.path}
            className={styles.invisibleLink}
          />
        </div>
        <ul className={styles.menu}>
          {menuLinks}
        </ul>
        <div className={clsx(styles.burgerMenuWrapper, {
          [styles.burgerMenuOpenWrapper]: isBurgerMenuOpen,
          [styles.burgerMenuCloseWrapper]: !isBurgerMenuOpen,
        })}
        >
          <ul className={styles.burgerMenu}>
            {menuLinks}
            <div className={styles.burgerMenuLanguageSelect}>
              <LanguageSelect />
            </div>
          </ul>
          <img src={mainLogoBlur} alt={l10n.components.menu.MENU_DESCRIPTION} className={styles.mainLogoBlur} />

          <Button
            disableRipple
            onClick={() => setBurgerMenuOpen(false)}
            className={styles.closeBurgerMenuIcon}
          >
            <img src={closeBurgerMenu} alt="menu" />
          </Button>
        </div>
        <div className={styles.userProfile}>
          <UserProfile />
        </div>
      </div>
    </div>
  );
}