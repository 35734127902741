import * as React from 'react';
// import { observer } from 'mobx-react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import StyledTableRow from '../cells/StyledTableRow';
import StyledTableCell from '../cells/StyledTable';
import useStyles from './ConfigurationTable.styles';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const ConfigurationTable = (props: any) => {
  const {
    control, errors, isEditable, currentTemplate
  }: any = props;

  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <TableContainer component={Paper} className={classes.wrapper}>
      <Table className={classes.configureTable} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{l10n.components.configureTable.RESULT_OF_TOUR}</StyledTableCell>
            <StyledTableCell align="center">{l10n.components.configureTable.CONFIGURE_DESCRIPTION}</StyledTableCell>
            <StyledTableCell align="center">{l10n.components.configureTable.POINTS}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell align="center">
              <p className={classes.boldFont}>{l10n.components.configureTable.WIN}</p>
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
          <StyledTableRow />
          <StyledTableRow key="victory">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.WIN}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              {l10n.components.configureTable.WIN_DESCRIPTION}
            </StyledTableCell>
            <StyledTableCell align="left">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      error={!!errors.pointsForWin}
                      variant="standard"
                      label={l10n.components.configureTable.POINT_LABEL}
                      inputProps={{ 'data-testid': 'pointsForWin' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                name="pointsForWin"
                control={control}
                defaultValue={currentTemplate?.pointsForWin ?? null}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell align="center">
              <p className={classes.boldFont}>{l10n.components.configureTable.DRAW}</p>
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
          <StyledTableRow key="draw">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.DRAW_WITH_RESULT}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p>{l10n.components.configureTable.DRAW_RESULT_DESCRIPTION_FIRST}</p>
              <p>{l10n.components.configureTable.DRAW_RESULT_DESCRIPTION_SECOND}</p>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="standard"
                      error={!!errors.pointsForDrawWithCatches}
                      label={l10n.components.configureTable.POINT_LABEL}
                      inputProps={{ 'data-testid': 'pointsForDrawWithCatches' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                defaultValue={currentTemplate?.pointsForDrawWithCatches ?? null}
                name="pointsForDrawWithCatches"
                control={control}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="drawWithoutResult">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.DRAW_NULL}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p>{l10n.components.configureTable.DRAW_NULL_DESCRIPTION_FIRST}</p>
              <p>{l10n.components.configureTable.DRAW_NULL_DESCRIPTION_SECOND}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="standard"
                      label={l10n.components.configureTable.POINT_LABEL}
                      error={!!errors.pointsForDrawWithoutCatches}
                      inputProps={{ 'data-testid': 'pointsForDrawWithoutCatches' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                name="pointsForDrawWithoutCatches"
                control={control}
                defaultValue={currentTemplate?.pointsForDrawWithoutCatches ?? null}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell align="center">
              <p className={classes.boldFont}>{l10n.components.configureTable.DEFEAT}</p>
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
          <StyledTableRow key="lose">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.DEFEAT_RESULT}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p>{l10n.components.configureTable.DEFEAT_RESULT_DESCRIPTION_FIRST}</p>
              <p>{l10n.components.configureTable.DEFEAT_RESULT_DESCRIPTION_SECOND}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="standard"
                      label={l10n.components.configureTable.POINT_LABEL}
                      error={!!errors.pointsForLoseWithDomination}
                      inputProps={{ 'data-testid': 'pointsForLoseWithDomination' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                name="pointsForLoseWithDomination"
                defaultValue={currentTemplate?.pointsForLoseWithDomination ?? null}
                control={control}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="loseTwiceSmaller">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.DEFEAT_LESS_TWO}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p>{l10n.components.configureTable.DEFEAT_LESS_TWO_DESCRIPTION_FIRST}</p>
              <p>{l10n.components.configureTable.DEFEAT_LESS_TWO_DESCRIPTION_SECOND}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="standard"
                      error={!!errors.pointsForLoseWithoutDomination}
                      label={l10n.components.configureTable.POINT_LABEL}
                      inputProps={{ 'data-testid': 'pointsForLoseWithoutDomination' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                defaultValue={currentTemplate?.pointsForLoseWithoutDomination ?? null}
                name="pointsForLoseWithoutDomination"
                control={control}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key="loseWithoutResult">
            <StyledTableCell component="th" scope="row">
              <p className={classes.boldFont}>{l10n.components.configureTable.DEFEAT_NULL}</p>
            </StyledTableCell>
            <StyledTableCell align="left">
              <p>{l10n.components.configureTable.DEFEAT_NULL_DESCRIPTION_FIRST}</p>
              <p>{l10n.components.configureTable.DEFEAT_NULL_DESCRIPTION_SECOND}</p>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Controller
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="standard"
                      error={!!errors.pointsForLoseWithoutCatches}
                      label={l10n.components.configureTable.POINT_LABEL}
                      inputProps={{ 'data-testid': 'pointsForLoseWithoutCatches' }}
                      disabled={isEditable}
                    />
                  </>
                )}
                name="pointsForLoseWithoutCatches"
                control={control}
                defaultValue={currentTemplate?.pointsForLoseWithoutCatches ?? null}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConfigurationTable;