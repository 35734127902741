import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  basicText: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '135%',
  },
  input: {
    '& fieldset': {
      borderRadius: 8,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: 15
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px 12px'
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '135%',
    },
    '&:focus-visible': {
      outline: 'none',
      transition: 'all .5s ease in'
    },
    maxWidth: '100%'
  },
  label: {
    color: '#858697'
  },
  toggleTextSecurity: {
    position: 'absolute',
    right: 0,
    height: 29,
    margin: '10px 5px',
    border: 'none',
    background: 'inherit',
    cursor: 'pointer'
  },
  passwordWrapper: {
    position: 'relative'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
  invitationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 10
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80%',
    marginTop: 30
  },
  button: {
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
  },
  helperText: {
    fontSize: 16
  },
  invalid: {
    color: 'red'
  },
}));

export default useStyles;