import { createContext, useContext } from 'react';
import LocationStore from './APIstores/Location';
import CountriesStore from './APIstores/Countries';
import TournamentTemplatesStore from './APIstores/TournamentTemplates';
import TournamentStore from './APIstores/Tournament';
import SponsorsStore from './APIstores/Sponsors';
import TacklesStore from './APIstores/Tackles';
import UserStore from './APIstores/User';
import ArticlesStore from './APIstores/Articles';
import ReportsStore from './APIstores/Reports';
import NewsStore from './APIstores/News';

export default class MainStore {
  LocationStore: LocationStore;

  TournamentTemplatesStore: TournamentTemplatesStore;

  TournamentStore: TournamentStore;

  UserStore: UserStore;

  CountriesStore: CountriesStore;

  SponsorsStore: SponsorsStore;

  TacklesStore: TacklesStore;

  ArticlesStore: ArticlesStore;

  ReportsStore: ReportsStore;

  NewsStore: NewsStore;

  constructor() {
    this.LocationStore = new LocationStore(this);
    this.CountriesStore = new CountriesStore(this);
    this.TournamentTemplatesStore = new TournamentTemplatesStore(this);
    this.TournamentStore = new TournamentStore(this);
    this.SponsorsStore = new SponsorsStore(this);
    this.UserStore = new UserStore(this);
    this.TacklesStore = new TacklesStore(this);
    this.ArticlesStore = new ArticlesStore(this);
    this.ReportsStore = new ReportsStore(this);
    this.NewsStore = new NewsStore(this);
  }
}

const StoresContext = createContext(new MainStore());

export const useStores = () => useContext(StoresContext);