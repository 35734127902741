import React, { SetStateAction, useEffect, useState } from 'react';
import AreatroutTable from '../../../../../components/table';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import Article from '../../../../../models/components/article/Article';
import EditDeleteCell from './EditDeleteCell';
import { articles } from '../../../../../utils/enum/Enum';
import UrlCell from './url/Url';
import DateCell from '../../../../../components/tableTemplates/components/cell/data/Date';
import Routes from '../../../../../routing/Routes';

const ArticleTable = (props: {
  data: Article[],
  setIsDeleteModalOpen: React.Dispatch<SetStateAction<boolean>>,
  selectedSubcategory: number,
  setIsEditModalOpen: React.Dispatch<SetStateAction<boolean>>,
}) => {
  const l10n = useTranslation();

  const [publications, setPublications] = useState<Article[] | []>([]);

  const {
    data, setIsDeleteModalOpen, selectedSubcategory, setIsEditModalOpen
  } = props;

  const prepareData = (arrayOfArticles: Article[]) => arrayOfArticles
    .filter((article: Article) => (selectedSubcategory === articles.all ? true : article.status === selectedSubcategory))
    .map((article: Article, ind: number) => {
      article.index = ind + 1;
      return article;
    });

  useEffect(() => {
    setPublications(prepareData(data));
  }, [selectedSubcategory, data]);

  const columns = [
    {
      displayName: '№',
      fieldName: 'index',
    },
    {
      displayName: l10n.components.tableColumns.DATE,
      fieldName: 'postedAt',
      render: ({ postedAt }: Article) => (
        <DateCell format="L LT" value={postedAt} />
      )
    },
    {
      displayName: l10n.components.articles.SUBCATEGORY,
      fieldName: 'statusDescription',
    },
    {
      displayName: l10n.components.tableColumns.NAME,
      allowSearch: true,
      fieldName: 'name',
      render: ({ name, id }: Article) => (
        <UrlCell
          name={name}
          url={Routes.Publication.path.replace(':id', String(id))}
        />
      )
    },
    {
      displayName: l10n.components.tableColumns.CONTROL,
      fieldName: 'action',
      allowSearch: false,
      allowSortring: false,
      render: (article: Article) => (
        <EditDeleteCell
          article={article}
          setIsEditModalOpen={setIsEditModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ),
    },
  ];

  return (
    <>
      {data && <AreatroutTable isSearchVisible columns={columns} rows={publications} />}
    </>
  );
};

export default ArticleTable;