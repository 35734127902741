import React from 'react';
import { observer } from 'mobx-react';
import LocationData from '../../../../../../models/components/dictionaries/location/LocationData';
import AreatroutTable from '../../../../../../components/table';
import EditDeleteCell from './cell/EditDelete';
import CoordinateCell from './cell/Coordinate';
import CountryCell from './cell/Country';
import useStyles from './LocationsTable.styles';
import { useStores } from '../../../../../../stores/Main';
import RTEtext from '../../../../../../components/RTEtext/RTEtext';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const LocationsTable = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { LocationStore } = useStores();

  const columns = [
    {
      displayName: l10n.components.locationTable.NAME_LOCATION,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.locationTable.LOCATION_DESCRIPTION,
      fieldName: 'description',
      allowSearch: false,
      allowSortring: false,
      render: ({ description }: LocationData) => (
        <div className={classes.decription}>
          <RTEtext text={description} />
        </div>
      ),
    },
    {
      displayName: l10n.components.locationTable.LOCATION_COUNTRY,
      fieldName: 'countryId',
      render: (data: LocationData) => <CountryCell data={data} />,
    },
    {
      displayName: l10n.components.locationTable.LOCATION_COORDINATES,
      fieldName: 'longitude',
      allowSearch: false,
      allowSortring: false,
      render: (data: LocationData) => <CoordinateCell data={data} />,
    },
    {
      displayName: l10n.components.locationTable.CONTROL_LOCATION,
      fieldName: 'action',
      allowSearch: false,
      allowSortring: false,
      render: ({ id }: LocationData) => (
        <EditDeleteCell id={id} />
      ),
    },
  ];

  return (
    <>
      {LocationStore.locations?.length ? (
        <AreatroutTable columns={columns} rows={LocationStore.locations} />
      ) : (
        <p>{l10n.components.locationTable.LOCATIONS_LIST_EMPTY}</p>
      )}
    </>
  );
});

export default LocationsTable;