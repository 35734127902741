import React from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import Loader from '../../../../../../../components/loader/Loader';
import useStyles from '../TemplatesStyles';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import { useStores } from '../../../../../../../stores/Main';
import Toasters from '../../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const DeleteTemplateConfirm = observer((props: {
    handleClose?: EmptyVoidFunction;
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { handleClose } = props;
  const { TournamentTemplatesStore } = useStores();
  const deleteTemplate = async () => {
    const response = await TournamentTemplatesStore.deleteTemplate(TournamentTemplatesStore.id);
    if (response) {
      Toasters.success(l10n.components.tournamentsTemplate.TEMPLATE_DELETE_SUCCESS);
      TournamentTemplatesStore.refreshTemplates();
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (TournamentTemplatesStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.tournamentsTemplate.TEMPLATE_DELETE_CONFIRM}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          {l10n.components.tournamentsTemplate.CANCEL_BUTTON}
        </Button>
        <Button
          onClick={deleteTemplate}
          color="primary"
          variant="contained"
          autoFocus
        >
          {l10n.components.tournamentsTemplate.DELETE}
        </Button>
      </DialogActions>
    </>
  );
});

export default DeleteTemplateConfirm;