/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  FormHelperText, Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import useStyles from '../Playoff.styles';
import QuarterFinals from '../stages/quarterFinals/QuarterFinals';
import SemiFinals from '../stages/semiFinals/SemiFinals';
import FinalRounds from '../stages/finals/FinalRounds';
import TournamentService from '../../../../../../services/main/Tournament.service';
import { stages, tournamentStatus } from '../../../../../../utils/enum/Enum';
import { getRoundsArray } from '../Playoff.utils';
import PlayoffRounds from '../../../../../../models/pages/tournament/playoff/PlayoffRounds';
import LoaderFullScreen from '../../../../../../components/loader/fullScreen/FullScreen';
import ATButton from '../../../../../../components/button/Button';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const Schedule = observer((props: {
  isEditMode: boolean,
}) => {
  const url = useParams<{ id: string }>();
  const classes = useStyles();
  const l10n = useTranslation();
  const { isEditMode } = props;
  const [rounds, setRounds] = useState<PlayoffRounds[]>([]);
  const [status, setStatus] = useState(tournamentStatus.Planning);
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPlayoffRounds = async () => {
    setIsLoaded(true);
    const response = await TournamentService.getPlayoffRounds(Number(url.id));
    if (response) {
      setStatus(response.status);
      setRounds(response);
    }
    setIsLoaded(false);
  };

  const finishTournament = async () => {
    if (rounds.every((round: PlayoffRounds) => round.winnerParticipantId !== null)) {
      setIsLoaded(true);
      const result = await TournamentService.updateStatus({ tournamentId: Number(url.id), status: tournamentStatus.Completed });
      if (result) {
        setStatus(tournamentStatus.Completed);
        Toasters.success(l10n.components.playoffSchedule.STATUS_CHANGED);
        setIsLoaded(false);
        setHasError(false);
      } else {
        setHasError(true);
      }
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    getPlayoffRounds();
  }, []);

  if (isLoaded) {
    return (<LoaderFullScreen />);
  }

  return (
    <>
      {isEditMode
        && (
          <div className={classes.headerWrapper}>
            <div><Typography variant="h2" className={classes.h2}>{l10n.components.playoffSchedule.PLAYOFF_RESULTS}</Typography></div>
            <div>
              <ATButton
                variant="primary"
                fill="solid"
                size="md"
                onClick={finishTournament}
                disabled={status === tournamentStatus.Completed}
              >
                {status === tournamentStatus.Completed ? l10n.components.playoffSchedule.TOURNAMENT_FINISHED : l10n.components.playoffSchedule.FINISH_TOURNAMENT}
              </ATButton>
              <FormHelperText data-testid="nameError" className={classes.invalid}>
                {hasError && l10n.components.playoffSchedule.RESULTS_ERROR}
              </FormHelperText>
            </div>
          </div>
        )}
      {!isEditMode && <div><Typography variant="h2" className={classes.h2}>{l10n.components.playoffSchedule.PLAYOFF_RESULTS}</Typography></div>}
      <div className={clsx(classes.flexRow, classes.mobileWrapper)}>
        {rounds?.length ? (
          <>
            <QuarterFinals
              quarterFinalRounds={getRoundsArray(rounds, stages.quarterFinals)}
              getPlayoffRounds={getPlayoffRounds}
              setIsLoaded={setIsLoaded}
              isEditMode={isEditMode}
            />
            <SemiFinals
              semiFinalRounds={getRoundsArray(rounds, stages.semiFinals)}
              getPlayoffRounds={getPlayoffRounds}
              setIsLoaded={setIsLoaded}
              isEditMode={isEditMode}
            />
            <FinalRounds
              finalRound={getRoundsArray(rounds, stages.firstPlace)}
              thirdPlaceRound={getRoundsArray(rounds, stages.thirdPlace)}
              getPlayoffRounds={getPlayoffRounds}
              setIsLoaded={setIsLoaded}
              isEditMode={isEditMode}
            />
          </>
        ) : ' '}
      </div>
    </>
  );
});

export default Schedule;