import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  root: {
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
  },
  participant: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  tableCell: {
    '& .MuiTableCell-root': {
      padding: 7
    }
  },
  switchWrapper: {
    marginLeft: 20,
    marginBottom: 20,
    '& .MuiFormControlLabel-label': {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px'
    }
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: colors.primary,
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(13, 169, 179, 0.5)'
      },
      '&:hover': {
        backgroundColor: 'rgba(13, 169, 179, 0.1)'
      }
    },
  },
  name: {
    padding: '18px 0',
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    lineHeight: '22px',
    color: '#0033B7',
  },
  wrapper: {
    padding: 8,
    color: colors.secondary,
  },
  divider: {
    backgroundColor: '#E2E4F0',
    margin: '6px 0'
  },
  member: {
    marginTop: 6
  },
  nameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
  },
  linkToReport: {
    display: 'flex',
  }
}));

export default useStyles;