import React, { useState } from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import SeasonsService from '../../../../../../services/main/dictionaries/Season.service';
import useStyles from '../Seasons.styles';
import Toasters from '../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const DeleteSeason = (props: {
  seasonId: number,
  handleClose?: EmptyVoidFunction
}) => {
  const { seasonId, handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const deleteSeason = async () => {
    setIsLoaded(true);
    const response = await SeasonsService.delete(seasonId);
    if (response) {
      Toasters.success(l10n.components.seasons.DELETE_SEASON_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };

  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.seasons.DELETE_SEASON_CONFIRM}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          data-testid="cancelBtn"
        >
          {l10n.components.seasons.CANCEL_DELETE}
        </Button>
        <Button
          onClick={deleteSeason}
          variant="contained"
          color="primary"
          autoFocus
          data-testid="deleteBtn"
        >
          {l10n.components.seasons.DELETE}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteSeason;

DeleteSeason.defaultProps = defaultProps;