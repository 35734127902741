import React, { useEffect, useState } from 'react';
import MobileTabsMenu from '../menu/Menu';
import MobileTab from '../../MobileTab';
import MobileTabData from '../../../../../models/components/tabs/MobileTabData';

const defaultProps = {
  MobileLayoutStyle: '',
};
const defaultComponent = { component: null, hash: '', name: '' };

const Container = (props: {
  tabs: MobileTabData[],
  MobileLayoutStyle?: string
}) => {
  const {
    tabs, MobileLayoutStyle
  } = props;

  const [selectedTab, setSelectedTab] = useState<MobileTabData>(defaultComponent);

  const selectedTabHash = window.location.hash;

  const selectComponent = (hash: string) => {
    const selectedComponent = tabs.find((tab) => tab.hash === hash) || defaultComponent;
    setSelectedTab(selectedComponent);
    return selectedComponent;
  };

  const setHash = (hash: string) => {
    const component = selectComponent(hash);
    window.history.pushState({}, '', `${window.location.pathname}${component.hash}`);
  };

  const returnTab = () => {
    window.history.back();
  };

  useEffect(() => {
    selectComponent(selectedTabHash);
  }, [selectedTabHash]);

  return (
    <>
      {selectedTab.component
        ? (
          <MobileTab
            selectedTab={selectedTab}
            onReturn={returnTab}
          />
        )
        : (
          <MobileTabsMenu
            onSelectTab={setHash}
            tabs={tabs}
            MobileLayoutStyle={MobileLayoutStyle}
          />
        )}
    </>
  );
};

export default Container;

Container.defaultProps = defaultProps;