import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import schema from './helpers/ValidationSchema';
import TextInput from '../../inputs/textField/TextInput';
import useStyles from './RecoveryPasswod.styles';
import contentComponents from '../helpers/Constants';
import ATButton from '../../button/Button';
import AuthorizationService from '../../../services/main/Authorization.service';
import Toasters from '../../popUp/PopUp';
import AuthorizationResponse from '../../../models/components/authorization/AuthorizationResponse';
import Loader from '../../loader/Loader';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  setModalComponent: null
};

const RecoveryPassword = (props: {
  setModalComponent?: React.Dispatch<React.SetStateAction<JSX.Element>>
}) => {
  const { setModalComponent } = props;
  const styles = useStyles();
  const l10n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const handlerRecoveryPassword = async (data: {email: string}) => {
    setIsLoading(true);
    const response: AuthorizationResponse = await AuthorizationService.resetEmail(data.email);

    if (!response.error && setModalComponent) {
      setModalComponent(contentComponents.RecoveryLinkSend);
    }

    if (response.error && response.message) {
      Toasters.error(response.message);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className={styles.centeredWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <form
          onSubmit={handleSubmit(handlerRecoveryPassword)}
        >
          <Typography className={styles.title}>{l10n.components.recoveryPassword.TITLE_RECOVERY}</Typography>
          <Typography className={styles.text}>
            {l10n.components.recoveryPassword.TEXT_TYPE_EMAIL}
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                label="E-mail"
                name="email"
                errors={errors.email}
                field={{ ...field }}
              />
            )}
          />
          <div className={styles.button}>
            <ATButton
              variant="primary"
              fill="solid"
              size="lg"
              isSubmit
            >
              {l10n.components.recoveryPassword.RECOVERY_PASSWORD}
            </ATButton>
          </div>
        </form>
        <Grid container className={styles.signUpLink}>
          {l10n.components.recoveryPassword.WITHOUT_ACCOUNT}
          <button
            onClick={() => (setModalComponent ? setModalComponent(contentComponents.SignUp) : null)}
            className={clsx(styles.link)}
            type="button"
          >
            {l10n.components.recoveryPassword.REGISTRATION}
          </button>
        </Grid>
        <br />
        <Grid container className={styles.signUpLink}>
          <button
            onClick={() => (setModalComponent ? setModalComponent(contentComponents.SignIn) : null)}
            className={styles.link}
            type="button"
          >
            {l10n.components.recoveryPassword.BUTTON_SIGN_IN}
          </button>
        </Grid>
      </div>
    </div>
  );
};

RecoveryPassword.defaultProps = defaultProps;

export default RecoveryPassword;