import { statusUserRegistration } from '../../utils/enum/Enum';
import { getTranslation } from '../localization/customHooks/Translation';

const l10n = getTranslation();

export const getCurrentParticipantRegisterStateMessage = (tournamentRegistrationStatus: number | undefined, isWaitList: boolean) => {
  let currentRegisterStateMessage = {
    buttonMessage: l10n.components.registrationButton.REGISTER_IN_TOURNAMENT,
    modalMessage: l10n.components.registrationButton.REGISTER_IN_TOURNAMENT_CONFIRM,
  };

  if (tournamentRegistrationStatus === statusUserRegistration.Canceled && isWaitList) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.WAITING_LIST,
      modalMessage: l10n.components.registrationButton.WAITING_LIST_CONFIRM
    };
  }
  if (tournamentRegistrationStatus === statusUserRegistration.isInWaitingList) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.LEAVE_WATING_LIST,
      modalMessage: l10n.components.registrationButton.LEAVE_WATING_LIST_CONFIRM
    };
  }
  if (tournamentRegistrationStatus === statusUserRegistration.Registered) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.ABORT_REGISTRATION,
      modalMessage: l10n.components.registrationButton.ABORT_REGISTRATION_CONFIRM
    };
  }

  return currentRegisterStateMessage;
};

export const getCurrentTeamRegisterStateMessage = (tournamentRegistrationStatus: number | undefined, isWaitList: boolean) => {
  let currentRegisterStateMessage = {
    buttonMessage: l10n.components.registrationButton.REGISTER_TEAM,
    modalMessage: l10n.components.registrationButton.REGISTER_TEAM_CONFIRM
  };

  if (tournamentRegistrationStatus === statusUserRegistration.Canceled && isWaitList) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.WAITING_LIST_TEAM,
      modalMessage: l10n.components.registrationButton.WAITING_LIST_CONFIRM_TEAM,
    };
  }
  if (tournamentRegistrationStatus === statusUserRegistration.isInWaitingList) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.LEAVE_WATING_LIST_TEAM,
      modalMessage: l10n.components.registrationButton.LEAVE_WATING_LIST_CONFIRM_TEAM
    };
  }
  if (tournamentRegistrationStatus === statusUserRegistration.Registered) {
    currentRegisterStateMessage = {
      buttonMessage: l10n.components.registrationButton.ABORT_REGISTRATION_TEAM,
      modalMessage: l10n.components.registrationButton.ABORT_REGISTRATION_CONFIRM_TEAM
    };
  }

  return currentRegisterStateMessage;
};