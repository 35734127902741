import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tempMediaSize } from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    margin: '0 auto',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
    '& .MuiTable-root': {
      width: '99.8%',
      '& .MuiTableCell-root': {
        border: '1px solid #fff',
        padding: 0
      },
      '& .MuiTableCell-head': {
        padding: 8,
        textIndent: 0,
        textAlign: 'center',
        border: 'none',
      },
      '& .MuiTableHead-root th:nth-child(2)': {
        textAlign: 'center',
      },
    }
  },
  title: {
    marginBottom: 32,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    gap: 15,

    [theme.breakpoints.down(tempMediaSize)]: {
      display: 'block',
    }
  },
  boldText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '150%',
    color: '#141429',
  },
  basicText: {
    fontSize: 22,
    lineHeight: '150%',
    color: '#141429',
  },
  tableFooter: {
    fontSize: '1rem',
  },
  summaryCell: {
    backgroundColor: '#f1f2f8',
    color: 'rgba(0, 0, 0, 0.87)',
    height: 38,
  },
  totalOfAll: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: 'black',
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    '& > .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded * .MuiTableHead-root': {
      backgroundColor: '#f1f2f8',
      '& th:first-child': {
        borderLeft: '8px 0 0 0'
      },
      '& th:last-child': {
        borderRadius: '0 8px 0 0'
      }
    },
  },
  cell: {
    backgroundColor: '#f1f2f8',
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default useStyles;