import React from 'react';
import { FormHelperText } from '@material-ui/core';
import JoditEditor from 'jodit-react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import useStyles from '../styles';
import useTranslation, { getTranslation } from '../../localization/customHooks/Translation';

const l10n = getTranslation();

const defaultProps = {
  placeholder: l10n.components.editableTextField.PLACEHOLDER,
  maxLength: 0,
};

const Editable = React.forwardRef((props: {
  convertedContent: string
  errors: FieldError | undefined
  // eslint-disable-next-line
  onBlur: (text: string) => void
  label: string,
  maxLength?: number,
  placeholder?: string
}, ref) => {
  const {
    errors, convertedContent, onBlur, label, placeholder, maxLength
  } = props;
  const classes = useStyles();
  const language = useTranslation().getLanguage();
  const RTEconfig = {
    readonly: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_as_html',
    language: language || 'ru',
    uploader: {
      insertImageAsBase64URI: true
    },
    showCharsCounter: false,
    limitChars: maxLength || false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    placeholder,
  };

  return (
    <div>
      <FormHelperText className={classes.helperText}>{label}</FormHelperText>
      <JoditEditor
        value={convertedContent}
        ref={ref as React.LegacyRef<JoditEditor>}
        config={RTEconfig}
        onBlur={onBlur}
      />
      <FormHelperText className={clsx(classes.invalid, classes.helperText)}>
        {errors?.message}
      </FormHelperText>
    </div>
  );
});

export default Editable;

Editable.defaultProps = defaultProps;