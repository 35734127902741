import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tempMediaSize } from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  tooltip: {
    transition: '.3 cubic-bezier(.4,0,.6,1)',
  },
  wrapperContentTooltip: {
    padding: '4px 16px',
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      lineHeight: '24px',
    }
  },
  wrapperRounds: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: 8,
    backgroundColor: (props: any) => props.backgroundColor
  },
  wrapperAbsolute: {
    position: 'absolute',
    left: '20%',
    [theme.breakpoints.down(tempMediaSize)]: {
      left: '15%'
    }
  }
}));

export default useStyles;