import React, { useEffect, useState } from 'react';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import RegistrationModal from './modal/Registration';
import { EmptyVoidFunction } from '../../utils/types/Types';
import { tournamentStatus, statusUserRegistration } from '../../utils/enum/Enum';
import { getCurrentParticipantRegisterStateMessage, getCurrentTeamRegisterStateMessage } from './RegistrationButton.utils';
import AuthorizationModal from '../authorization/modalWindow/ModalWindow';
import contentComponents from '../authorization/helpers/Constants';
import useStyles from './RegistrationButton.styles';
import Tags from '../tags/Tags';
import { StorageService } from '../../utils/Utils';
import { statusIdx } from '../../utils/constants/Constants';
import UserListData from '../../models/pages/user/UserList';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';
import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';
import TournamentService from '../../services/main/Tournament.service';
import useTranslation from '../localization/customHooks/Translation';
import CustomCountDown from '../countdown/Countdown';

const defaultProps = {
  className: '',
  isShowCountDown: true,
};

const RegistrationButton = (props: {
  tournament: UserListData | ClosestTournamentCardData | UpcomingTournamentEntry;
  getTournaments: EmptyVoidFunction | undefined;
  isShowCountDown?: boolean;
  className?: string;
}) => {
  const {
    tournament, getTournaments, className, isShowCountDown
  } = props;

  const [open, setOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isWaitingList, setIsWaitingList] = useState(false);
  const [isParticipantInWaitingList, setIsParticipantInWaitingList] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [tournamentStatusId, setTournamentStatusId] = useState(tournament?.statusId);
  const [tournamentStatusText, setTournamentStatusText] = useState(tournament?.status);

  const classes = useStyles();
  const l10n = useTranslation();

  const setAuthorizationComponent = () => {
    setComponent(contentComponents.SignIn);
    setIsModalOpened(true);
  };

  const onCompleteRegistration = () => {
    const checkStatus = async () => {
      const response = await TournamentService.getById(tournament.id);
      if (response) {
        setTournamentStatusId(response.status);
        setTournamentStatusText(response.statusText);
      }
    };
    setTimeout(checkStatus, 3000);
  };

  const applyButtonProps = {
    text: () => {
      let buttonMessage = l10n.components.registrationButton.SIGN_IN_FOR_JOINING;
      if (currentUser) {
        if (tournament?.ranking?.isTeamRanking) {
          buttonMessage = getCurrentTeamRegisterStateMessage(tournament?.registrationStatus, isWaitingList).buttonMessage;
        } else {
          buttonMessage = getCurrentParticipantRegisterStateMessage(tournament?.registrationStatus, isWaitingList).buttonMessage;
        }
      }
      return buttonMessage;
    },
    onClick: currentUser ? () => setOpen(true) : setAuthorizationComponent
  };

  useEffect(() => {
    const isUserRegisterOnTournament = () => {
      if (tournament) {
        setIsRegistered(
          tournament.registrationStatus === statusUserRegistration.Registered
          || tournament.registrationStatus === statusUserRegistration.isInWaitingList,
        );
        setIsParticipantInWaitingList(
          tournament.registrationStatus === statusUserRegistration.isInWaitingList,
        );
        setIsWaitingList(!(tournament.participantsCount < tournament.participantsLimit));
      }
    };
    if (tournamentStatus.Open === tournamentStatusId && currentUser) {
      isUserRegisterOnTournament();
    }
  }, [currentUser, isRegistered, tournament]);

  useEffect(() => {
    setCurrentUser(StorageService.getUserData());
  }, []);

  return (
    <>
      {(tournamentStatus.Open === tournamentStatusId) ? (
        <Button
          className={className}
          variant="primary"
          fill={(isParticipantInWaitingList || isRegistered) ? 'outline' : 'solid'}
          size="sm"
          onClick={applyButtonProps.onClick}
        >
          {applyButtonProps.text()}
        </Button>
      ) : (
        tournamentStatusText
        && <Tags variant={`event-${statusIdx[tournamentStatusId]}`}>{tournamentStatusText}</Tags>
      )}
      <Modal
        header={tournament.ranking?.isTeamRanking
          ? ''
          : getCurrentParticipantRegisterStateMessage(tournament?.registrationStatus, isWaitingList).buttonMessage}
        open={open}
        setOpen={setOpen}
        onClose={getTournaments}
        className={classes.modalWindowHeader}
      >
        <RegistrationModal
          isTeamRanking={tournament.ranking?.isTeamRanking}
          modalMessage={tournament.ranking?.isTeamRanking
            ? getCurrentTeamRegisterStateMessage(tournament?.registrationStatus, isWaitingList).modalMessage
            : getCurrentParticipantRegisterStateMessage(tournament?.registrationStatus, isWaitingList).modalMessage}
          tournamentId={tournament?.id}
          isRegistered={isRegistered}
          isParticipantInWaitingList={isParticipantInWaitingList}
          setIsRegistered={setIsRegistered}
        />
      </Modal>
      {component
        && (
          <AuthorizationModal
            open={isModalOpened}
            setOpen={setIsModalOpened}
            component={component}
          />
        )}
      {isShowCountDown && tournament.endRegistrationDate && tournament.statusId === tournamentStatus.Open
        && (
          <CustomCountDown date={tournament.endRegistrationDate} onComplete={onCompleteRegistration} />
        )}
    </>
  );
};

export default RegistrationButton;

RegistrationButton.defaultProps = defaultProps;