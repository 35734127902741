import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Routes from '../../routing/Routes';
import useStyles from './Home.styles';
import FinishedTournamentCard from '../../components/tournamentsCards/finishedTournamentCard/FinishedTournamentCard';
import FinishedTournament from '../../models/pages/tournament/FinishedTournament';
import NewsComponent from './components/newsComponent/NewsComponent';
import TournamentService from '../../services/main/Tournament.service';
import { numberOfFinishedTournaments } from '../../utils/constants/Constants';
import ATButton from '../../components/button/Button';
import LoaderMainPage from '../../components/loader/mainPage/MainPage';
import ClosestTournamentCard from '../../components/tournamentsCards/closestTournamentCard/ClosestTournamentCard';
import transformClosestTournamentData
  from '../../components/tournamentsCards/closestTournamentCard/ClosestTournamentCard.utils';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';
import useTranslation from '../../components/localization/customHooks/Translation';

export default function Home() {
  const classes = useStyles();
  const l10n = useTranslation();
  const [latestFinishedTournaments, setLatestFinishedTournaments] = useState<FinishedTournament[]>([]);
  const [closestTournaments, setClosestTournaments] = useState<ClosestTournamentCardData[]>([]);
  const [isLoadingFinishedTournaments, setIsLoadingFinishedTournaments] = useState(true);
  const [isLoadingClosestTournaments, setIsLoadingClosestTournaments] = useState(true);

  const getLatestFinishedTournaments = async () => {
    const res = await TournamentService.getLatestFinishedTournaments(numberOfFinishedTournaments);
    if (res) {
      setLatestFinishedTournaments(res);
    }
    setIsLoadingFinishedTournaments(false);
  };

  const getClosestTournaments = async () => {
    const res = await TournamentService.getClosestTournaments();
    if (res) {
      setClosestTournaments(transformClosestTournamentData(res));
    }
    setIsLoadingClosestTournaments(false);
  };

  const getClosestTournamentsView = () => {
    if (!closestTournaments.length) {
      return l10n.pages.NO_CLOSEST_TOURNAMENTS;
    }
    return closestTournaments.map((tournament: ClosestTournamentCardData) => (
      <ClosestTournamentCard
        key={tournament.id}
        tournament={tournament}
        getTournaments={getClosestTournaments}
      />
    ));
  };

  useEffect(() => {
    getLatestFinishedTournaments();
    getClosestTournaments();
  }, []);

  const loaders = (
    <>
      <LoaderMainPage />
      <LoaderMainPage />
      <LoaderMainPage />
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.mainFotoWrapper}>
        <div className={classes.mainTextWrapper}>
          <h1 className={classes.title}>{l10n.pages.AREATROUT}</h1>
          <Typography className={clsx(classes.text, classes.title)}>{l10n.pages.AREATROUT_INVITATION}</Typography>
        </div>
      </div>
      <div className={classes.blockWrapper}>
        <Typography className={classes.blockTitle}>{l10n.pages.CLOSEST_TOURNAMENTS}</Typography>
        <div className={classes.tournamentsCards}>
          {isLoadingClosestTournaments
            ? loaders
            : getClosestTournamentsView()}
        </div>
      </div>
      <div className={classes.blockWrapper}>
        <Typography className={classes.blockTitle}>{l10n.pages.FINISHED_TOURNAMENTS}</Typography>
        <div className={classes.tournamentsCards}>
          {isLoadingFinishedTournaments
            ? loaders
            : latestFinishedTournaments.map((topThreeParticipants: FinishedTournament) => (
              <FinishedTournamentCard
                key={topThreeParticipants.tournamentId}
                tournamentData={topThreeParticipants}
              />
            ))}
        </div>
        <div className={classes.center}>
          <Link to={Routes.AvailableTournaments.path}>
            <ATButton
              className={classes.button}
              variant="secondary"
              fill="outline"
              size="lg"
            >
              {l10n.components.button.ALL_TOURNAMENTS}
            </ATButton>
          </Link>
        </div>
      </div>
      <div className={classes.newsWrapper}>
        <NewsComponent />
      </div>
    </div>
  );
}