import React from 'react';
import { TextField } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker as Picker, LocalizationProvider } from '@mui/x-date-pickers';
import { EmptyVoidFunction } from '../../utils/types/Types';

const defaultProps = {
  error: false,
  disabled: false,
  label: '',
  className: '',
  helperText: '',
  inputProps: {},
  placeholder: 'MM/DD/YYYY HH:MM',
};

const DateTimePicker = (props: {
    value: string;
    onChange: EmptyVoidFunction;
    error?: any;
    disabled?: boolean;
    label?: string;
    className?: string;
    helperText?: string;
    inputProps?: any;
    placeholder?: string;
}) => {
  const {
    value, onChange, error, disabled, label, className, helperText, inputProps, placeholder
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Picker
        ampm={false}
        value={value}
        disablePast
        onChange={onChange}
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            {...params}
            className={className}
            error={error}
            label={label}
            fullWidth
            variant="outlined"
            onChange={() => {}}
            helperText={helperText}
            InputLabelProps={{ shrink: true }}
            inputProps={{ ...params.inputProps, ...inputProps, placeholder }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;

DateTimePicker.defaultProps = defaultProps;