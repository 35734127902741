import React from 'react';
import { Grid } from '@material-ui/core';
import { toLocalDateTime } from '../../../../../utils/Utils';

const defaultProps = {
  className: ''
};

function DateTime(props: {
  date: string;
  className?: string;
}) {
  const { date, className } = props;
  return (
    <Grid className={className}>
      {toLocalDateTime(date)}
    </Grid>
  );
}

export default DateTime;

DateTime.defaultProps = defaultProps;