import React from 'react';
import AreatroutTable from '../../../../../../../components/table';
import RegisteredParticipant from '../../../../../../../models/pages/tournament/RegisteredParticipant';
import DateTimeCell from '../../../../../../../components/tableTemplates/components/cell/dateTime/DateTime';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  ToggleRegistrationButton: () => {},
};

const ParticipantsTable = (props: {
  participants: RegisteredParticipant[] | null;
  fetchAllParticipants: EmptyVoidFunction;
  ToggleRegistrationButton?: any;
}) => {
  const l10n = useTranslation();
  const { ToggleRegistrationButton, participants, fetchAllParticipants } = props;
  const columns = [
    {
      displayName: l10n.components.tableColumns.REGISTRATION_DATE,
      fieldName: 'creationDate',
      allowSearch: false,
      render: ({ creationDate }: RegisteredParticipant) => (
        <DateTimeCell date={creationDate} />
      ),
    },
    {
      displayName: l10n.components.tableColumns.COUNTRY,
      fieldName: 'country',
      render: ({ country }: RegisteredParticipant) => (
        <>{country?.name}</>
      ),
    },
    {
      displayName: l10n.components.tableColumns.SPORTSMAN,
      fieldName: 'fullName',
    },
    {
      displayName: l10n.components.tableColumns.PARTICIPATION,
      fieldName: 'participation',
      allowSortring: false,
      allowSearch: false,
      render: ({ registrationId }: RegisteredParticipant) => (
        <ToggleRegistrationButton
          fetchAllParticipants={fetchAllParticipants}
          participantId={registrationId}
        />
      ),
    },
  ];

  return <AreatroutTable columns={columns} rows={participants} />;
};

export default ParticipantsTable;

ParticipantsTable.defaultProps = defaultProps;