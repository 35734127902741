import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import AreatroutTable from '../Table';
import MobileTable from '../mobileTable/MobileTable';
import GridProperties from '../../../models/components/table/GridProperties';

const defaultProps = {
  onFilterChanged: () => { },
  areActionsAvailable: false,
  desktopClassName: '',
  MobileClassName: '',
  toggleSortOrder: () => { },
  isThirdSortClickAllowed: true,
  isPaging: true,
  toolbarElement: null,
  isSearchVisible: false,
  header: null,
  maxBodyHeight: '',
  isStickyHeader: false,
  summaryFooter: <></>
};

const AdaptiveTable = ({
  columns, rows, onFilterChanged, toggleSortOrder, isThirdSortClickAllowed, desktopClassName, mobileClassName,
  isPaging, toolbarElement, isSearchVisible, header, maxBodyHeight, isStickyHeader, summaryFooter, allHeadersStyle, parentChildData, mobileColumnHeader
}: GridProperties) => {
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      {isMobileLayout
        ? (
          <MobileTable
            columns={columns}
            rows={rows}
            mobileColumnHeader={mobileColumnHeader}
            mobileClassName={mobileClassName}

          />
        )
        : (
          <AreatroutTable
            columns={columns}
            rows={rows}
            className={desktopClassName}
            onFilterChanged={onFilterChanged}
            toggleSortOrder={toggleSortOrder}
            isThirdSortClickAllowed={isThirdSortClickAllowed}
            isPaging={isPaging}
            toolbarElement={toolbarElement}
            isSearchVisible={isSearchVisible}
            header={header}
            maxBodyHeight={maxBodyHeight}
            isStickyHeader={isStickyHeader}
            summaryFooter={summaryFooter}
            allHeadersStyle={allHeadersStyle}
            parentChildData={parentChildData}
          />
        )}
    </div>
  );
};

export default AdaptiveTable;

AdaptiveTable.defaultProps = defaultProps;