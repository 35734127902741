import React, { useEffect, useState, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import CarouselImage from '../../components/carouselImage/CarouselImage';
import MetaDescription from '../../components/metaDescription/MetaDescription';
import useStyles from './TournamentReport.styles';
import useTranslation from '../../components/localization/customHooks/Translation';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import AnglerLink from '../../components/angler/anglerLink/AnglerLink';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import { toLocalDate, getImageUrl, sortReportRounds } from '../../utils/Utils';
import Routes from '../../routing/Routes';
import { useStores } from '../../stores/Main';
import RTEtext from '../../components/RTEtext/RTEtext';
import ImageData from '../../models/components/image/ImageData';
import { RoundReport } from '../../models/components/report/Report';
import Flag from '../../components/flag/Flag';
import ShareLinks from '../../components/shareLinks/ShareLinks';
import { mergeSingleRound } from '../../components/controlReport/ReportControl.utils';

const TournamentReport = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();

  const reportHash = '#reports-list';

  const [imagesUrl, setImagesUrl] = useState<{'original': string}[] | null>(null);
  const [sortedReport, setSortedReport] = useState<any>(null);

  const { id } = useParams<{id: string}>();

  const { ReportsStore } = useStores();

  const sortRounds = () => {
    if (ReportsStore.report) {
      const current = {
        ...ReportsStore.report,
        roundReports: sortReportRounds(ReportsStore.report.roundReports)
          .filter((round: RoundReport) => round.description)
          .map((round: RoundReport) => mergeSingleRound(round))
      };
      setSortedReport(current);
    }
  };
  const getImgUrlsForCarousel = (images: any) => images.map((image: ImageData) => (
    { original: getImageUrl(image.url), originalClass: classes.imgCarousel }));

  useEffect(() => {
    ReportsStore.getOneReportById(id);
  }, []);

  useEffect(() => {
    sortRounds();
  }, [ReportsStore.report]);

  useEffect(() => {
    if (ReportsStore.report && ReportsStore.report.images.length) {
      const currentImagesUrl = getImgUrlsForCarousel(ReportsStore.report.images);
      setImagesUrl(currentImagesUrl);
    }
  }, [ReportsStore.report]);

  const urlNames = [
    { displayName: l10n.components.locationCard.MAIN_PAGE, url: Routes.Home.path },
    { displayName: l10n.components.tableColumns.TOURNAMENTS, url: Routes.AvailableTournaments.path },
    {
      displayName: ReportsStore.report?.tournament.name,
      url: Routes.TournamentCard.path.replace(':id', ReportsStore.report ? String(ReportsStore.report!.tournament.id) : ''),
    },
    {
      displayName: l10n.components.reports.REPORTS,
      url: `${Routes.TournamentCard.path.replace(':id', String(ReportsStore.report ? ReportsStore.report!.tournament.id : ''))}${reportHash}`
    },
    { displayName: `${ReportsStore.report?.name}`, url: '' }
  ];

  const urlList = useMemo(() => imagesUrl && imagesUrl.length && imagesUrl.map((el: {'original': string}) => el.original), [imagesUrl]);

  return (
    <>
      {sortedReport
        ? (
          <div className={classes.main}>
            <MetaDescription title={sortedReport.name} />
            <Breadcrumbs urls={urlNames} className={classes.breadCrumbs} />
            <div className={classes.reportContainer}>
              <div className={classes.reportDescription}>
                <Typography component="h2" className={clsx(classes.title, classes.breakText)}>{sortedReport.name}</Typography>
                <div className={classes.infoAboutReport}>
                  <div className={classes.flexContainer}>
                    <div className={classes.authorInfo}>
                      <AnglerLink
                        anglerData={{ ...sortedReport.author, id: sortedReport.authorId }}
                      />
                      {sortedReport.team && (
                        <span className={classes.teamName}>{sortedReport.team.teamUser.fullName}</span>
                      )}
                    </div>
                    <h4 className={classes.date}>{toLocalDate(sortedReport.postedAt)}</h4>
                  </div>
                  <div className={clsx(classes.flexContainer, classes.additionalWrapper)}>
                    <Link
                      to={Routes.TournamentCard.path.replace(':id', String(sortedReport.tournament.id))}
                    >
                      {sortedReport.tournament.name}
                    </Link>
                    <Link
                      to={Routes.Location.path.replace(':id', String(sortedReport.tournament.location.id))}
                      className={classes.flagWrapper}
                    >
                      {sortedReport && <Flag data={sortedReport.tournament.location.country.flag} className={classes.flag} />}
                      <span className={clsx(classes.link, classes.location)}>{sortedReport.tournament.location.name}</span>
                    </Link>
                  </div>
                </div>
                <div className={classes.reportMain}>
                  <div />
                </div>
              </div>
            </div>
            {urlList && !!urlList.length && (
              <div className={classes.photoGallery}>
                <CarouselImage
                  urlList={urlList}
                  imgStyles={classes.imgCarousel}
                  autoPlay
                />
              </div>
            )}
            <div className={classes.reportText}>
              {sortedReport.header && (
                <div className={clsx(classes.reportHeader, classes.breakText)}>
                  <RTEtext text={sortedReport.header} />
                </div>
              )}
              {sortedReport.roundReports && (
                sortedReport.roundReports.map((round: RoundReport) => (round.description && (
                  <div key={uuidv4()} className={classes.roundText}>
                    <div className={classes.roundInfo}>
                      <Typography key={round.id} variant="h2">
                        {round.roundDescription}
                      </Typography>
                      <div className={classes.score}>
                        {`${round.anglerCatches} - ${round.opponentCatches}`}
                      </div>
                      <p className={clsx(classes.nullBottomMargin, classes.opponentName)}>
                        <AnglerLink
                          anglerData={round.opponent}
                        />
                      </p>
                    </div>
                    <div>
                      <RTEtext text={round.description} />
                    </div>
                  </div>
                )
                ))
              )}
              {sortedReport.footer && (
                <div className={classes.reportFooter}>
                  <RTEtext text={sortedReport.footer} />
                </div>
              )}
            </div>
            <ShareLinks />
          </div>
        )
        : <LoaderFullScreen />}
    </>
  );
});

export default TournamentReport;