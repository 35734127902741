import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab, Box,
} from '@material-ui/core';

import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import TabPanel from './components/TabPanel';
import useStyles from './TabsContainer.styles';
import TabData from '../../models/components/tabs/TabData';

const defaultProps = {
  className: '',
  isCentered: true
};

const accessibilityProps = (index: any) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const TabsContainer = (props: {
  data: TabData[],
  className?: string
  isCentered?: boolean
}) => {
  const classes = useStyles();
  const { data, className, isCentered } = props;

  const selectedTabHash = window.location.hash;
  const selectedTab = data.findIndex((tab) => tab.hash === selectedTabHash);
  const tabIndex = selectedTab && selectedTab >= 0 ? selectedTab : 0;

  const location = useLocation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const setHash = (hash: string) => window.history.pushState({}, '', `${location.pathname}${hash}`);

  useEffect(() => {
    if (window.location.hash !== data[tabIndex].hash) setHash(data[tabIndex].hash);
  }, []);

  useEffect(() => {
    if (selectedTab > -1) setSelectedTabIndex(tabIndex);
  }, [selectedTabHash]);

  return (
    <div className={clsx(classes.root, className)}>
      <Box position="static" color="default" className={classes.wrapper}>
        <Tabs
          classes={{
            flexContainer: clsx(classes.tabsTextContainer, { [classes.start]: !isCentered }),
            root: classes.tabScroll
          }}
          value={selectedTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {data.map((item, i) => (
            <Tab
              onClick={() => setHash(data[i].hash)}
              key={item.name}
              label={item.name}
              disabled={item.disabled || false}
              className={classes.tab}
              disableRipple
              {...accessibilityProps(i)}
            />
          ))}
        </Tabs>
      </Box>
      {data.map((item, i) => (
        <TabPanel
          className={classes.tabContent}
          key={item.name}
          value={selectedTabIndex}
          index={i}
        >
          {item.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default TabsContainer;

TabsContainer.defaultProps = defaultProps;