import React from 'react';
import AreatroutTable from '../../../../../../components/table/Table';
import Sponsor from '../../../../../../models/components/dictionaries/sponsor/Sponsor';
import EditDeleteCell from './cell/EditDelete';
import UrlCell from '../../../../../../components/table/cells/urlCell/UrlCell';
import useStyles from './SponsorsTableStyles';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

function TemplatesTable(props: {
    sponsorsList: Sponsor[] | null,
    setIsEditModalOpen: any,
    setIsDeleteModalOpen: any,
}) {
  const {
    sponsorsList, setIsEditModalOpen, setIsDeleteModalOpen
  } = props;

  const classes = useStyles();
  const l10n = useTranslation();

  const columns = [
    {
      displayName: l10n.components.sponsors.SPONSORS_LOGO,
      fieldName: 'logo',
      render: ({ logo }: Sponsor) => (
        <img src={`${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${logo!.url}`} alt="sponsor logo" className={classes.logo} />
      )
    },
    {
      displayName: l10n.components.sponsors.SPONSOR_NAME,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.sponsors.SPONSOR_URL,
      fieldName: 'url',
      render: ({ url }: Sponsor) => (
        <UrlCell url={url} />
      )
    },
    {
      displayName: l10n.components.sponsors.SPONSOR_COMMENTS,
      fieldName: 'comment',
    },
    {
      displayName: l10n.components.sponsors.SPONSOR_CONTROL,
      fieldName: 'action',
      allowSearch: false,
      allowSortring: false,
      render: (sponsor: Sponsor) => (
        <EditDeleteCell
          sponsor={sponsor}
          setIsEditModalOpen={setIsEditModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ),
    },
  ];

  return (
    <>
      {sponsorsList && sponsorsList.length
        ? <AreatroutTable columns={columns} rows={sponsorsList} className={classes.fullWidth} />
        : <p>{l10n.components.sponsors.SPONSORS_LIST_IS_EMPTY}</p>}
    </>
  );
}

export default TemplatesTable;