import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    maxWidth: 800,
    margin: '0 10px'
  },
  editButton: {
    marginRight: 10,
  },
  addButton: {
    alignSelf: 'flex-start',
    marginBottom: 20,
    marginLeft: 10
  },
}));

export default useStyles;