import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './Place.styles';

const defaultProps = {
  imageName: '',
  isHaveImageAnyPlaces: false,
  className: ''
};

const Place = (props: {
  place: number,
  imageName?: string,
  isHaveImageAnyPlaces?: boolean,
  className?: string
}) => {
  const {
    place, imageName, isHaveImageAnyPlaces, className
  } = props;
  const classes = useStyles();

  return (
    <Grid container justify="center" wrap="nowrap" className={className}>
      {isHaveImageAnyPlaces
        ? (
          <>
            <Grid item container justify="center" className={classes.icon}>
              {imageName && <img src={imageName} alt="icon" />}
            </Grid>
            <Grid item className={classes.place}>
              <span>{place}</span>
            </Grid>
          </>
        )
        : (
          <Grid item className={classes.place}>
            <span>{place}</span>
          </Grid>
        )}
    </Grid>
  );
};

Place.defaultProps = defaultProps;

export default Place;