import React from 'react';
import { Button } from '@material-ui/core';
import useConfirmationDialog from '../../../../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import RegistrationService from '../../../../../../../../services/main/Registration.service';
import { EmptyVoidFunction } from '../../../../../../../../utils/types/Types';
import Toasters from '../../../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../../../components/localization/customHooks/Translation';

const TransferToActiveParticipants = (props: {
  participantId: number | null;
  fetchAllParticipants: EmptyVoidFunction;
}) => {
  const { participantId, fetchAllParticipants } = props;
  const l10n = useTranslation();
  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: l10n.components.headerLabel.ADD_TO_MAIN_LIST,
    bodyText: l10n.components.confirmDialogsMessage.ADD_USERS_TO_MAIN_LIST,
    confirmationButtonText: l10n.components.button.CONTINUE,
    onConfirmClick: async () => {
      if (participantId) {
        const res = await RegistrationService.transferToActiveParticipants(participantId);
        if (res) {
          Toasters.success(l10n.components.toasterMessage.PARTICIPANT_HAS_BEEN_ADDED);
        }
      }
      onClose();
      fetchAllParticipants();
    },
  });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onOpen()}
      >
        {l10n.components.button.ADD_TO_MAIN_LIST}
      </Button>
      <Dialog />
    </>
  );
};

export default TransferToActiveParticipants;