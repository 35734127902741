import AdminUser from '../../models/pages/user/AdminUser';
import EditUserRole from '../../models/pages/user/EditUserRole';
import BlockUser from '../../models/pages/user/BlockUser';
import {
  remove, get, post, processError, put
} from '../Http';
import Error from '../../models/service/Error';
import { getTranslation } from '../../components/localization/customHooks/Translation';

const url = '/api/Users';

const l10n = getTranslation();

const options = {
  headers: { 'Content-Type': 'application/json' },
};

const generateError = (e: Error) => {
  processError(e, l10n.components.error.USER_WASNT_FOUND);
  return null;
};

async function editRole(data: EditUserRole, editRoleUrl: string) {
  return post(`${url}/${data.id}/roles/${editRoleUrl}`, `"${data.role}"`, options);
}

const UserService = {
  async get() {
    return get(url);
  },

  async getOpponents(id: string) {
    return get(`${url}/${id}/opponents`);
  },

  async getRatings(id: string) {
    return get(`${url}/${id}/ratings`);
  },

  async getUser(id: string) {
    return get(`${url}/${id}`);
  },

  async getUserParticipationInfo(id: string) {
    return get(`${url}/${id}/tournaments`);
  },

  async getUserOpponentMeetingInfo(idUser: string, idOpponent: string) {
    return get(`${url}/${idUser}/games/${idOpponent}`);
  },

  async post(user: AdminUser) {
    return post(url, user);
  },

  async validateEmail(email: string) {
    return post(`${url}/validate`, { userEmail: email }, undefined, generateError);
  },

  async put(user: AdminUser) {
    return put(`${url}/${user.id}`, user);
  },

  async delete(id: string) {
    return remove(`${url}/${id}`);
  },

  addToRole(data: EditUserRole) {
    return editRole(data, 'add');
  },
  removeFromRole(data: EditUserRole) {
    return editRole(data, 'remove');
  },

  async blockUser(user: BlockUser) {
    return post(`${url}/block`, user);
  },

  async unBlockUser(user: BlockUser) {
    return post(`${url}/unblock`, `"${user.userId}"`, options);
  },

  async getCurrentUser() {
    return get(`${url}/info`);
  },

  async getUserByEmail(email: string) {
    return get(`${url}/email/${email}`, generateError);
  }
};

export default UserService;