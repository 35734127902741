import React from 'react';
import AreatroutModal from '../../../../../components/modal/Modal';
import useStyles from '../../../UserCard.styles';

function MeetingsModal(
  props: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    component: JSX.Element,
  }
) {
  const classes = useStyles();
  const {
    component, open, setOpen
  } = props;
  return (
    <AreatroutModal
      modalClassName={classes.modal}
      header=""
      open={open}
      setOpen={setOpen}
      onClose={() => setOpen(false)}
    >
      {component}
    </AreatroutModal>
  );
}

export default MeetingsModal;