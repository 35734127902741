import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    maxWidth: 447,
    width: '100%',
    outline: 'none',
    borderRadius: 8,
    '&:focus-visible': {
      boxShadow: '0px 0px 0px 2px #E2E4F0'
    },
    '& label.Mui-focused': {
      color: '#141429',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#141429',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#C1C1CA',
        borderRadius: 8,

      },
      '&:hover fieldset': {
        borderColor: '#858697',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#141429',
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-input': {
        padding: '13px 16px 13px 0'
      },
      '&.MuiOutlinedInput-adornedStart': {
        paddingLeft: 16
      },
      '& .MuiInputBase-input': {
        color: colors.secondary,
        height: 'auto',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSize: 16,
        lineHeight: '22px'
      },
      '& input::placeholder': {
        color: '#C1C1CA'
      },
      '&.Mui-error:hover fieldset': {
        borderColor: '#FD4A32'
      },
      '&.Mui-error.Mui-focused fieldset': {
        borderColor: '#FD4A32'
      },
    }
  }
}));

export default useStyles;