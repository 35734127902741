import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 35,
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    padding: '0px 5px 0px 5px',
  },
  contentWrapper: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 300,
    height: 120,
  },
  titleParticipants: {
    fontSize: 18,
    marginBottom: 10,
  },
  titleParticipantsNumber: {
    marginLeft: 5,
    fontWeight: 600,
    fontSize: 24,
  },
  textStyle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem',
    padding: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  anglerName: {
    width: 200,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      width: 170,
    },
  },
  input: {
    width: 48,
    height: 24,
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#000000',
      padding: '1px 1px 1px 3px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '35%',
      minHeight: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%'
    }
  },
  buttonWrapper: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: colors.secondary,
    padding: '12px 36px 26px',
    lineHeight: '24px',
  },
  continueButton: {
    alignSelf: 'flex-end',
  },
}));

export default useStyles;