import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  FormHelperText,
  Select,
  FormControl,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './helpers/ValidationSchema';
import AuthorizationService from '../../../services/main/Authorization.service';
import Toasters from '../../popUp/PopUp';
import useStyles from './SignUp.styles';
import AuthorizationResponse from '../../../models/components/authorization/AuthorizationResponse';
import useIsMounted from '../../../utils/hooks/UseIsMounted';
import Country from '../../../models/components/dictionaries/country/Country';
import CountriesService from '../../../services/main/dictionaries/Country.service';
import UserSignUp from '../../../models/components/authorization/UserSignUp';
import ATButton from '../../button/Button';
import buttonStyles from '../../button/Button.styles';
import UserImage from '../../userImage/UserImage';
import contentComponents from '../helpers/Constants';
import TextInput from '../../inputs/textField/TextInput';
import PasswordInput from '../../inputs/password/Password';
import Loader from '../../loader/Loader';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  setModalComponent: null
};

interface FormData extends UserSignUp {
  confirmPassword?: string
}

function SignUp(props: {
  setModalComponent?: React.Dispatch<React.SetStateAction<JSX.Element>>
}) {
  const { setModalComponent } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const buttonStyle = buttonStyles();
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      countryId: '',
      city: '',
    },
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [profilePictureId, setProfilePictureId] = useState<number | null>(null);
  const [countries, setCountries] = useState<Array<Country> | null>([]);
  const [isLoading, setisLoading] = useState(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    const getCountries = async () => {
      const res = await CountriesService.get();
      if (isMounted()) {
        setCountries(res);
      }
    };
    getCountries();
  }, []);

  const handlerSignUp = async (data: FormData) => {
    setisLoading(true);
    const result = { ...data };

    if (profilePictureId) {
      result.profilePictureId = profilePictureId;
    }
    delete result.confirmPassword;
    const res: AuthorizationResponse = await AuthorizationService.signUp(result);
    if (!res.error && setModalComponent) {
      setModalComponent(contentComponents.ConfirmationEmail);
    }
    if (res.error && res.message) {
      Toasters.error(res.message);
      setErrorMessage(res.message);
    }
    setisLoading(false);
  };
  if (isLoading) {
    return (
      <div className={classes.centeredWrapper}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className={classes.signUpForm}>
        <Typography
          component="h1"
          variant="h5"
          className={classes.title}
        >
          {l10n.components.signUpForm.REGISTRATION}
        </Typography>
        <div className={classes.addImage}>
          <UserImage
            setPictureId={setProfilePictureId}
            variant="large"
          />
        </div>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handlerSignUp)}
        >
          <div className={clsx(classes.inputRow, classes.inputHeight)}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.flexColumn}>
                  <TextInput
                    label={l10n.components.signUpForm.LABEL_NAME}
                    name="firstName"
                    errors={errors.firstName}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.flexColumn}>
                  <TextInput
                    label={l10n.components.signUpForm.LABEL_LASTNAME}
                    name="lastName"
                    errors={errors.lastName}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
          </div>
          <div className={classes.inputRow}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.selectWrapper}
            >
              <Controller
                name="countryId"
                control={control}
                render={({ field }) => (
                  <div className={clsx(classes.flexColumn)}>
                    <FormHelperText className={clsx(classes.label, classes.basicText)}>{l10n.components.signUpForm.LABEL_COUNTRY}</FormHelperText>
                    <Select
                      {...field}
                      inputProps={{ 'data-testid': 'country' }}
                      variant="outlined"
                      placeholder={l10n.components.signUpForm.PLACEHOLDER_COUNTRY}
                      value={field.value}
                      onChange={field.onChange}
                      native
                      error={!!errors.countryId}
                      className={clsx(classes.select, classes.basicText)}
                    >
                      <option aria-label="None" value="" disabled>{l10n.components.signUpForm.OPTION_COUNTRY}</option>
                      {countries && countries.map((item: Country) => (
                        <option
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
              />
              <FormHelperText
                data-testid="countryError"
                className={clsx(classes.invalid, classes.basicText)}
              >
                {!!errors?.countryId && errors?.countryId?.message}
              </FormHelperText>
            </FormControl>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={clsx(classes.flexColumn, classes.inputHeight)}>
                  <TextInput
                    label={l10n.components.signUpForm.LABEL_CITY}
                    name="city"
                    errors={errors.city}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
          </div>
          <div className={clsx(classes.inputRow, classes.inputHeight)}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.flexColumn}>
                  <PasswordInput
                    label={l10n.components.signUpForm.LABEL_PASSWORD}
                    name="password"
                    errors={errors.password}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.flexColumn}>
                  <PasswordInput
                    label={l10n.components.signUpForm.LABEL_REPEAT_PASSWORD}
                    name="confirmPassword"
                    errors={errors.confirmPassword}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
          </div>
          <div className={clsx(classes.inputRow, classes.inputHeight)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={classes.flexColumn}>
                  <TextInput
                    label="E-mail"
                    name="email"
                    errors={errors.email}
                    field={{ ...field }}
                  />
                </div>
              )}
            />
          </div>
          <div className={clsx(classes.invalid, classes.errorContainer)}>
            {errorMessage}
          </div>
          <div className={classes.signUpBtn}>
            <ATButton
              isSubmit
              variant="primary"
              fill="solid"
              size="lg"
              className={buttonStyle.button}
            >
              {l10n.components.signUpForm.BUTTON_REGISTER}
            </ATButton>
          </div>
        </form>
        <Grid container className={classes.signInLink}>
          <Grid item>
            <div>
              {l10n.components.signUpForm.ALREADY_REGISTER}
              <button
                onClick={() => (setModalComponent ? setModalComponent(contentComponents.SignIn) : null)}
                className={clsx(classes.link, classes.basicText)}
                type="button"
              >
                {l10n.components.signUpForm.BUTTON_SIGN_UP}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SignUp;

SignUp.defaultProps = defaultProps;