import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  scroll: {
    maxHeight: 'calc(100vh - 230px)',
    overflow: 'auto',
    padding: '0px 5px 0px 5px',
    scrollBehavior: 'smooth',
    marginTop: 10,
    '& div:first-child': {
      margin: 0,
    }
  },
  card: {
    boxSizing: 'border-box',
    width: '55vw',
    marginLeft: 'auto',
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      width: 690,
    },
    [theme.breakpoints.down('md')]: {
      width: '85vw',
    },
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: 10,
    overflow: 'hidden',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '1px solid #ccc',
    borderBottom: 'none',
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        fontSize: 27,
        fontWeight: 600,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& h4': {
        fontSize: 20,
      },
    },
  },
  tournamentInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& p': {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        fontSize: 9,
      },
    },
  },
  id: {
    margin: '0 15px',
  },
  logo: {
    objectFit: 'cover',
    maxWidth: '100%',
  },
}));

export default useStyles;