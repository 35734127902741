import React, { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ModifiedPlayoffRound from '../../../../../../models/pages/tournament/playoff/ModifiedPlayoffRound';
import { postResult } from '../Playoff.utils';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import InlineRound from './InlineRound';
import { stages } from '../../../../../../utils/enum/Enum';
import RoundResult from '../../../../../../models/pages/tournament/playoff/RoundResult';
import PlayoffResult from '../../../../../../models/pages/tournament/playoff/PlayoffResult';
import FirstPlaceRound from '../stages/finals/components/FirstPlaceRound';
import ThirdPlaceRound from '../stages/finals/components/ThirdPlaceRound';
import SemiFinalRounds from '../stages/semiFinals/components/SemiFinalRounds';
import QuarterFinalRounds from '../stages/quarterFinals/components/QuarterFinalRounds';

const defaultProps = {
  round: null
};

function SetFinalStage(props: {
  round?: ModifiedPlayoffRound | null,
  getPlayoffRounds: EmptyVoidFunction
  stage: number
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
}) {
  const {
    round, getPlayoffRounds, stage, setIsLoaded, isEditMode
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [winnerId, setWinnerId] = useState<number | null | undefined>(round?.winnerParticipantId);
  const [roundResult, setRoundResult] = useState<PlayoffResult>({
    tournamentId: null,
    playOffGameId: null,
    firstParticipantCatches: null,
    secondParticipantCatches: null,
  });
  const [participant, setParticipant] = useState<RoundResult>({
    participantId: null,
    participantCatches: null
  });
  useEffect(() => {
    if (round) {
      setRoundResult((prev) => ({
        tournamentId: round?.firstParticipant?.tournamentId,
        playOffGameId: round.id,
        firstParticipantCatches: round?.firstParticipant?.id === participant.participantId
          ? participant.participantCatches
          : prev.firstParticipantCatches,
        secondParticipantCatches: round?.secondParticipant?.id === participant.participantId
          ? participant.participantCatches
          : prev.secondParticipantCatches,
      }));
    }
  }, [participant]);
  useEffect(() => {
    if (roundResult.firstParticipantCatches !== null && roundResult.secondParticipantCatches !== null) {
      setIsLoaded(true);
      postResult(roundResult, setWinnerId, getPlayoffRounds, setIsLoaded);
    }
  }, [roundResult]);

  if (isMobile) {
    return (
      <InlineRound
        round={round}
        winnerId={winnerId}
        stage={stage}
        isEditMode={isEditMode}
        setParticipant={setParticipant}
      />
    );
  }

  return (
    <>
      {stage === stages.firstPlace ? (
        <FirstPlaceRound
          round={round}
          winnerId={winnerId}
          setParticipant={setParticipant}
          isEditMode={isEditMode}
        />
      ) : null}
      {stage === stages.thirdPlace ? (
        <ThirdPlaceRound
          round={round}
          winnerId={winnerId}
          setParticipant={setParticipant}
          isEditMode={isEditMode}
        />
      ) : null}
      {stage === stages.semiFinals ? (
        <SemiFinalRounds
          round={round}
          winnerId={winnerId}
          setParticipant={setParticipant}
          isEditMode={isEditMode}
        />
      ) : null}
      {stage === stages.quarterFinals ? (
        <QuarterFinalRounds
          round={round}
          winnerId={winnerId}
          setParticipant={setParticipant}
          isEditMode={isEditMode}
        />
      ) : null}
    </>
  );
}

SetFinalStage.defaultProps = defaultProps;

export default SetFinalStage;