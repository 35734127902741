import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  mainContainer: {
    maxWidth: 1360,
    margin: 'auto',
    paddingLeft: 16,
    boxSizing: 'border-box'
  },
  participantCard: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  participantContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 15px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    },
  },
  title: {
    fontSize: 40,
    lineHeight: '50px',
    fontWeight: 'bold',
    margin: '5px 0px 0px',
    color: colors.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
      lineHeight: '40px'
    },
  },
  basicText: {
    fontSize: 18,
    lineHeight: '20px',
  },
  participantData: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    '& p': {
      margin: '10px 0'
    }
  },
  img: {
    height: 200,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 140,
      maxWidth: 140,
      display: 'block',
      margin: 'auto'
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  breadcrumbs: {
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  link: {
    fontSize: 16,
    lineHeight: '135%',
    color: '#0E3AAA',
  },
  meetingTable: {
    '& .MuiTableCell-root': {
      padding: '11px 0px',
      textIndent: 0,
      minHeight: 45,
      boxSizing: 'border-box',
      paddingRight: 10,
    },
    '& .MuiTableHead-root': {
      '& th': {
        paddingLeft: 30,
      },
    },
  },
  meetingTableHeader: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
  },
  photo: {
    margin: 'auto',
    width: '25%',
    borderRadius: '50%',
  },
  name: {
    textAlign: 'center',
  },
  active: {
    width: '100%',
    padding: 5,
  },
  line: {
    textAlign: 'center',
    '& img': {
      width: '25%',
    },
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      [theme.breakpoints.down('xs')]: {
        minWidth: 0,
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        margin: 0,
      },
      minWidth: 600,
    },
  },
  modalLoading: {
    height: 325,
  },
  tabsContainer: {
    margin: 0
  },
  desktopContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  editButtonDesktop: {
    paddingTop: 5,
    paddingLeft: 10,
    cursor: 'pointer',
  },
  mobileContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  mobileEditButton: {
    position: 'absolute',
    cursor: 'pointer',
    left: '75%',
  }
}));

export default useStyles;