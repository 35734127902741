import React from 'react';
import { Button } from '@material-ui/core';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';
import AuthorizationService from '../../../../../../services/main/Authorization.service';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useStyles from '../ActivationState.style';

const defaultProps = {
  handleClose: () => { },
};

export default function ActivationStateModal(props: {
  userId: string;
  handleClose?: EmptyVoidFunction;
}) {
  const {
    userId, handleClose,
  } = props;
  const l10n = useTranslation();
  const classes = useStyles();

  const activateUser = async () => {
    await AuthorizationService.activateUser(userId);
    Toasters.success(l10n.components.toasterMessage.USER_HAS_BEEN_ACTIVATED);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className={classes.messageContainer}>
      <div className={classes.messageBoxText}>
        {l10n.components.confirmDialogsMessage.ACCEPT_USERACTIVATION}
      </div>
      <Button
        data-testid="block"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => {
          activateUser();
        }}
      >
        {l10n.components.button.ACTIVATE}
      </Button>
    </div>
  );
}

ActivationStateModal.defaultProps = defaultProps;