import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import AddForm from './AddForm';
import Modal from '../../../../components/modal/Modal';
import useStyles from './Add.styles';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../../components/localization/customHooks/Translation';

function Add(props: {
  getAllTournaments: EmptyVoidFunction,
}) {
  const { getAllTournaments } = props;
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <>
      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={() => {
            setOpenAdd(true);
          }}
        >
          {l10n.components.button.ADD}
        </Button>
      </div>
      <Modal
        header={l10n.components.headerLabel.ADD_TOURNAMENT}
        open={openAdd}
        setOpen={setOpenAdd}
        modalClassName={classes.modal}
      >
        <AddForm getAllTournaments={getAllTournaments} />
      </Modal>
    </>
  );
}

export default Add;