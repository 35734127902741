/* eslint-disable max-len */
import {
  object, string, ref,
} from 'yup';

import { getTranslation } from '../../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  password: string().required(l10n.components.authorization.VALIDATION_PASSWORD_REQUIRED).min(8, l10n.components.authorization.VALIDATION_PASSWORD_MIN),
  confirmPassword: string().required(l10n.components.authorization.VALIDATION_PASSWORD_REQUIRED).oneOf([ref('password'), null], l10n.components.authorization.VALIDATION_PASSWORD_MATCH),
});

export default schema;