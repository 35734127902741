import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  fullWidth: {
    width: 1200,
  },
  logo: {
    width: 100,
  },
  link: {
    color: '#4833b9',
  },
}));

export default useStyles;