import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './ConfirmationEmail.style';
import ATButton from '../../button/Button';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const ConfirmationEmail = (props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const styles = useStyles();
  const l10n = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Typography className={styles.title}>{l10n.components.authorization.EMAIL_TITLE_CONFIRM}</Typography>
        <Typography className={styles.text}>
          {l10n.components.authorization.EMAIL_TEXT_CONFIRM}
        </Typography>
        <ATButton
          variant="primary"
          fill="solid"
          size="lg"
          onClick={handleClose}
        >
          {l10n.components.authorization.BUTTON_EMAIL_CONFIRM}
        </ATButton>
      </div>
    </div>
  );
};

ConfirmationEmail.defaultProps = defaultProps;

export default ConfirmationEmail;