import React from 'react';
import AreatroutTable from '../../../../../../components/table/Table';
import Season from '../../../../../../models/components/dictionaries/season/Season';
import EditDeleteCell from './cell/EditDeleteCell';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

function SeasonsTable(props: {
    seasonsList: Season[] | null,
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setSeasonInfo: React.Dispatch<React.SetStateAction<Season>>
}) {
  const {
    seasonsList, setOpenDelete, setSeasonInfo, setOpenEdit,
  } = props;

  const l10n = useTranslation();

  const columns = [
    {
      displayName: l10n.components.seasons.NAME_OF_SEASON,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.seasons.LABEL_DESCRIPTION,
      fieldName: 'description',
    },
    {
      displayName: l10n.components.seasons.CONTROL,
      fieldName: 'action',
      allowSearch: false,
      allowSortring: false,
      render: (data: Season) => (
        <EditDeleteCell
          setOpenDelete={setOpenDelete}
          setOpenEdit={setOpenEdit}
          data={data}
          setSeasonInfo={setSeasonInfo}
        />
      ),
    },
  ];

  return (
    <>
      {seasonsList && seasonsList.length
        ? <AreatroutTable columns={columns} rows={seasonsList} />
        : <p>{l10n.components.seasons.SEASONS_LIST_IS_EMPTY}</p>}
    </>
  );
}

export default SeasonsTable;