import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './NewsComponent.styles';
import ATButton from '../../../../components/button/Button';
import NewsService from '../../../../services/main/News.service';
import NewsItem from '../../../../models/pages/news/NewsItem';
import News from '../../../../models/pages/news/News';
import Routes from '../../../../routing/Routes';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import { numberOfSkippedNewsOnHomePage, numberOfTakenNewsOnHomePage } from '../../../../utils/constants/Constants';
import ArticlesLinks from '../../../../components/article/links/Links';

function NewsComponent() {
  const classes = useStyles();
  const [news, setNews] = useState<News>();
  const l10n = useTranslation();
  const getNews = async () => {
    const response = await NewsService.get(numberOfSkippedNewsOnHomePage, numberOfTakenNewsOnHomePage);
    setNews(response);
  };
  useEffect(() => {
    getNews();
  }, []);
  return (
    <div className={classes.newsWrapper}>
      <Typography className={classes.header}>{l10n.pages.NEWS_ARTICLES}</Typography>
      <div className={classes.articles}>
        {news?.items.map((article: NewsItem) => (
          <ArticlesLinks article={article} key={uuidv4()} />
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <Link to={Routes.News.path}>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="outline"
            size="lg"
          >
            {l10n.components.button.ALL_NEWS}
          </ATButton>
        </Link>
      </div>
    </div>
  );
}

export default NewsComponent;