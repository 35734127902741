import {
  makeAutoObservable,
  runInAction
} from 'mobx';
import ImageData from '../../models/components/image/ImageData';
import Sponsor from '../../models/components/dictionaries/sponsor/Sponsor';
import SponsorsService from '../../services/main/dictionaries/Sponsor.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class SponsorsStore {
    sponsors: Sponsor[] | null = null;

    sponsor: Sponsor | null = {
      url: '',
      logoId: null,
      name: '',
      comment: '',
      id: 0,
      logo: {
        id: 0,
        filename: '',
        originalFilename: '',
        thumbnailFilename: '',
        miniatureFilename: '',
        miniatureImageUrl: '',
        thumbnailImageUrl: '',
        createdAt: '',
        url: '',
      },
      isRemoved: false,
    };

    mainStore: any;

    constructor(mainStore: any) {
      this.mainStore = mainStore.mainStore;

      makeAutoObservable(this);
    }

    getSponsorsById = async (sponsorsId: number) => {
      this.sponsor!.id = sponsorsId;
      const response = await SponsorsService.get(sponsorsId);
      if (response) {
        this.setSponsor(response);
      }
      return this.sponsor;
    };

    getSponsorByName = (selectedMainSponsor: string) => this.sponsors!.find((el: Sponsor) => el.name === selectedMainSponsor);

    refreshSponsors: EmptyVoidFunction = async () => {
      const response = await SponsorsService.getAll();
      if (response) {
        runInAction(() => { this.sponsors = response; });
      }
    };

    setLogo = async (logo: ImageData) => {
      this.sponsor!.logo = logo;
    };

    deleteSponsor = async () => {
      const response = await SponsorsService.delete(this.sponsor!.id);
      return response;
    };

    addSponsor = async (data: Sponsor) => {
      const response = await SponsorsService.post(data);
      return response;
    };

    editSponsor = async (data: Sponsor) => {
      const result = { ...data };
      result.id = this.sponsor!.id;
      const response = await SponsorsService.update(result);
      return response;
    };

    setId = (sponsorId: number) => {
      this.sponsor!.id = sponsorId;
    }

    setSponsor = (sponsor: Sponsor | null) => {
      this.sponsor = sponsor;
    }

    setSponsors = (sponsors: Sponsor[]) => {
      this.sponsors = sponsors;
    }
}