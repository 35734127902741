import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  modalWide: {
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 20,
    marginLeft: 10
  },
  formatsWrapper: {
    maxWidth: 800,
    margin: '0 10px'
  },
  editWrapper: {
    justifyContent: 'center',
  },
  tableCell: {
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  table: {
    boxSizing: 'border-box',
    padding: 20,
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
    boxSizing: 'border-box',
    padding: 20,
  },
  formButton: {
    margin: '0 auto',
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
  },
  buttonsWrapper: {
    padding: 10
  },
}));

export default useStyles;