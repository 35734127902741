import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  title: {
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  titleText: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 'bold',
    color: colors.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  info: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8
    },
  }
}));

export default useStyles;