import { observer } from 'mobx-react';
import React from 'react';
import ColumnMetadata from '../../../../models/components/table/ColumnMetadata';
import Tackle from '../../../../models/components/tackles/Tackle';
import { useStores } from '../../../../stores/Main';
import AdaptiveTable from '../../../table/adaptiveTable/AdaptiveTable';
import useTranslation from '../../../localization/customHooks/Translation';
import LoaderFullScreen from '../../../loader/fullScreen/FullScreen';
import EditDeleteCell from '../cell/EditDelete';
import useStyles from '../../Tackles.styles';

const TacklesTable = observer((props: {
  isUsersPage: boolean,
}) => {
  const { isUsersPage } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const { TacklesStore } = useStores();
  const heightForAllRows = 50;

  const columns: ColumnMetadata[] = [
    {
      displayName: l10n.components.tackles.SPINNING,
      fieldName: 'spinning',
      allowSearch: false,
      cellStyle: {
        height: heightForAllRows,
      }
    },
    {
      displayName: l10n.components.tackles.REEL,
      fieldName: 'reel',
      allowSearch: false,
    },
    {
      displayName: l10n.components.tackles.WIRE,
      fieldName: 'wire',
      allowSearch: false,
    },
  ];

  if (isUsersPage) {
    columns.push({
      displayName: l10n.components.countries.CONTROL_NAME,
      fieldName: 'action',
      allowSortring: false,
      allowSearch: false,
      render: (data: Tackle) => (<EditDeleteCell data={data} />)
    });
  }

  if (!TacklesStore.tackles) {
    return <LoaderFullScreen />;
  }

  return (
    <AdaptiveTable
      desktopClassName={classes.tacklesTable}
      columns={columns}
      rows={TacklesStore.tackles}
      mobileColumnHeader={(index: number) => `${l10n.components.tackles.TACKLE} №  ${index + 1} `}
      isPaging={false}
    />
  );
});

export default TacklesTable;