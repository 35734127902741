/* eslint-disable react/destructuring-assignment */
import React from 'react';
import RegisteredParticipant from '../../../models/pages/tournament/RegisteredParticipant';
import useStyles from './MemberList.styles';
import { sortingType } from '../../../utils/enum/Enum';
import DateTimeCell from '../../tableTemplates/components/cell/dateTime/DateTime';
import AreatroutTable from '../../table/Table';
import Angler from '../../angler/Angler';
import useTranslation from '../../localization/customHooks/Translation';

const MemberList = (props: {
  participants: RegisteredParticipant[]
}) => {
  const { participants } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const columns = [
    {
      displayName: '',
      allowSortring: false,
      fieldName: 'number',
      render: ({ number }: RegisteredParticipant) => <p>{number}</p>,
      customColumnStyle: { width: '2%' },
      readonly: true,
    },
    {
      displayName: l10n.components.memberListTable.FULLNAME,
      fieldName: 'fullName',
      render: (participant: RegisteredParticipant) => (
        <Angler
          anglerData={{ ...participant, id: participant.userId }}
          isTeam={participant.isTeam}
          className={classes.mobileContainer}
        />
      ),
      customColumnStyle: { width: '40%' },
    },
    {
      displayName: l10n.components.memberListTable.DATE,
      fieldName: 'creationDate',
      defaultSort: sortingType.desc,
      render: ({ creationDate }: RegisteredParticipant) => <DateTimeCell date={creationDate} />,
      customColumnStyle: { width: '58%' },
    },
  ];

  return (
    participants && participants.length
      ? (
        <AreatroutTable
          rows={participants}
          columns={columns}
          isPaging={false}
          className={classes.membersList}
          allHeadersStyle={{ backgroundColor: '#ffffff' }}
        />
      )
      : <div className={classes.empty}>{l10n.components.memberListTable.EMPTY_LIST}</div>
  );
};

export default MemberList;