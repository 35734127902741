import {
  autorun,
  makeAutoObservable,
  runInAction
} from 'mobx';
import TournamentTemplate from '../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';
import TemplatesService from '../../services/main/dictionaries/TournamentTemplate.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class TournamentTemplatesStore {
  id: number = 0;

  templates: TournamentTemplate[] | null = null;

  init: EmptyVoidFunction;

  template: TournamentTemplate | null = null;

  isLoading: boolean = false;

  isAddModalOpen: boolean = false;

  isDeleteModalOpen: boolean = false;

  isEditModalOpen: boolean = false;

  mainStore: any;

  areTemplateChanged: boolean = true;

  constructor(mainStore: any) {
    this.mainStore = mainStore.mainStore;

    makeAutoObservable(this);

    this.init = autorun(() => this.refreshTemplates());

    this.init();
  }

  toggleLoaderState = () => {
    this.isLoading = !this.isLoading;
  }

  toggleAddTemplateModalState = () => {
    this.isAddModalOpen = !this.isAddModalOpen;
  }

  toggleDeleteTemplateModalState = () => {
    this.isDeleteModalOpen = !this.isDeleteModalOpen;
  }

  toggleEditTemplateModalState = () => {
    this.isEditModalOpen = !this.isEditModalOpen;
  }

  toggleDeletetemplateModalState = () => {
    this.isDeleteModalOpen = !this.isDeleteModalOpen;
  }

  getTemplateById = async (templateId: number) => {
    this.toggleLoaderState();
    const response = await TemplatesService.get(templateId);
    if (response) {
      this.setTemplateState(false);
      this.setTemplate(response);
    }
    this.toggleLoaderState();
    return this.template;
  };

  refreshTemplates: EmptyVoidFunction = async () => {
    this.toggleLoaderState();
    const response = await TemplatesService.getAll();
    if (response) {
      runInAction(() => { this.templates = response; });
    }
    this.toggleLoaderState();
  };

  deleteTemplate = async (templateId: number) => {
    this.toggleLoaderState();
    const response = await TemplatesService.delete(templateId);
    this.toggleLoaderState();
    return response;
  };

  addTemplate = async (data: TournamentTemplate) => {
    this.toggleLoaderState();
    const response = await TemplatesService.post(data);
    this.toggleLoaderState();
    return response;
  };

  editTemplate = async (data: TournamentTemplate) => {
    if (this.id) {
      this.toggleLoaderState();
      const result = { ...data };
      result.id = this.id;
      const response = await TemplatesService.update(result);
      this.toggleLoaderState();
      return response;
    }
    return null;
  };

  setId = (templateId: number) => {
    this.id = templateId;
  }

  setTemplate = (template: TournamentTemplate) => {
    this.template = template;
  }

  setTemplates = (templates: TournamentTemplate[]) => {
    this.templates = templates;
  }

  setTemplateState = (value: boolean) => {
    this.areTemplateChanged = value;
  }
}