import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AreatroutTable from '../../../../../components/table';
import DateCell from '../../../../../components/tableTemplates/components/cell/data/Date';
import MeetingTableHeader from './header/Header';
import { sortingType } from '../../../../../utils/enum/Enum';
import UserParticipationHistory from '../../../../../models/pages/tournament/UserParticipationHistory';
import Routes from '../../../../../routing/Routes';
import colors from '../../../../../styles/Variables';
import useStyles from '../../../UserCard.styles';
import UserOpponentMeetingInfo, { Meetings } from '../../../../../models/pages/tournament/UserOpponentMeetingInfo';
import UserService from '../../../../../services/main/User.service';
import Loader from '../../../../../components/loader/Loader';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

export interface MeetingTableProps {
    userId: string,
    opponentId: string
}

function calculateColor(userScore: number, opponentScore: number) {
  if (userScore - opponentScore > 0) {
    return colors.greenWinner;
  } if (userScore - opponentScore < 0) {
    return colors.redLose;
  }
  return colors.orangeDraw;
}

function MeetingTable({ userId, opponentId }: MeetingTableProps) {
  const [data, setData] = useState<UserOpponentMeetingInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const l10n = useTranslation();

  function getCorrectRoundName(playoffRound: number) {
    const stages = [l10n.pages.QUARTER_FINAL, l10n.pages.FINAL, l10n.pages.THIRD_PLACE_FINAL, l10n.pages.SEMI_FINAL];
    return playoffRound! <= 3 ? stages[playoffRound!] : `1/${playoffRound} ${l10n.pages.PART_OF_FINAL}`;
  }

  function createHighlighting(uncoloredRows: UserOpponentMeetingInfo) {
    return {
      ...uncoloredRows,
      meetings: uncoloredRows
        .meetings.map((row: Meetings) => ({ ...row, rowStyles: { backgroundColor: calculateColor(row.userCatches, row.opponentCatches) } }))
    };
  }

  const getMeetingsInfo = async (user: string, opponent: string) => {
    setIsLoading(true);
    const response = await UserService.getUserOpponentMeetingInfo(user, opponent);
    if (response) {
      setData(createHighlighting(response));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMeetingsInfo(userId, opponentId);
  }, []);

  const classes = useStyles();
  const columns = [
    {
      displayName: l10n.components.tableColumns.DATE,
      fieldName: 'tournamentDate',
      defaultSort: sortingType.desc,
      render: ({ tournamentDate }: UserParticipationHistory) => (
        <div className={classes.active}>
          <DateCell value={tournamentDate} />
        </div>
      ),
    },
    {
      displayName: l10n.components.tableColumns.TOURNAMENT,
      fieldName: 'tournamentName',
      render: ({ tournamentName, tournamentId }: UserParticipationHistory) => (
        <Link to={Routes.TournamentCard.path.replace(':id', String(tournamentId))}>
          <span className={classes.link}>{tournamentName}</span>
        </Link>
      )
    },
    {
      displayName: l10n.components.tableColumns.ROUND_NAME,
      fieldName: 'roundNumber',
      render: ({ roundNumber, playOffStage }: any) => (
        <span>{ roundNumber || getCorrectRoundName(playOffStage) }</span>
      )
    },
    {
      displayName: l10n.components.tableColumns.SCORE,
      fieldName: 'userCatches',
      render: ({ userCatches, opponentCatches }: Meetings) => <span>{`${userCatches} - ${opponentCatches}`}</span>
    },
  ];
  if (isLoading) {
    return (
      <div className={classes.modalLoading}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      {data && (
      <div>
        <MeetingTableHeader user={data.user} opponent={data.opponent} />
        <AreatroutTable
          columns={columns}
          rows={data.meetings}
          isPaging={data.meetings.length > 10}
          isStickyHeader={false}
          className={classes.meetingTable}
        />
      </div>
      )}

    </>
  );
}

export default MeetingTable;