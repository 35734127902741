import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  saveButton: {
    display: 'block',
    marginTop: 10,
    marginLeft: 'auto'
  },
}));

export default useStyles;