import { number, object, string } from 'yup';
import { getTranslation } from '../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(l10n.shared.REQUIRED_NAME),
  tournamentId: number().required(l10n.components.tournamentReports.REQUIRED_TOURNAMENT).moreThan(0),
});

export default schema;