import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import schema from './helpers/ValidationSchema';
import Toasters from '../../popUp/PopUp';
import useStyles from './EditProfile.styles';
import buttonStyles from '../../button/Button.styles';
import ATButton from '../../button/Button';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import TextInput from '../../inputs/textField/TextInput';
import Loader from '../../loader/Loader';
import UserService from '../../../services/main/User.service';
import SingleSelect from '../../selects/single/Single';
import CountryService from '../../../services/main/dictionaries/Country.service';
import { selectType } from '../../../utils/enum/Enum';
import UserImage from '../../userImage/UserImage';
import UserData from '../../../models/pages/user/UserData';
import { StorageService } from '../../../utils/Utils';
import { getFormattedDate } from './EditProfile.utils';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

function EditProfile(props: {
  handleClose?: EmptyVoidFunction;
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const buttonStyle = buttonStyles();
  const { handleClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [profilePictureId, setProfilePictureId] = useState<number | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const localStorageData = StorageService.getUserData();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const getUserInfo = async () => {
    setIsLoading(true);
    const response = await UserService.getCurrentUser();
    if (response) {
      setUserData({
        ...response,
        countryId: response.country.id,
      });
    }
    if (response?.profilePicture) {
      setProfilePictureId(response.profilePicture.id);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const onFilterSelected = (_: string, value: string | number | null) => {
    if (value) {
      setValue('countryId', Number(value));
    }
  };
  const handlerEditProfile = async (data: any) => {
    setIsLoading(true);
    const result = {
      profilePictureId,
      ...data,
      birthday: data.birthday ? moment(data.birthday).format('YYYY-MM-DD 12:00:00') : null,
    };
    const response = await UserService.put(result);
    if (response) {
      Toasters.success(l10n.components.editProfile.TOASTER_SUCCESS);
      StorageService.setUserData({
        ...localStorageData,
        profilePicture: response.profilePicture,
        name: response.fullName,
        instagramLink: result.instagramLink,
        facebookLink: result.facebookLink,
        birthday: result.birthday ? moment(result.birthday).format('YYYY-MM-DD') : null,
      });
    }
    setIsLoading(false);
    if (handleClose) {
      handleClose();
    }
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {userData && (
        <div className={classes.editProfileForm}>
          <Typography className={classes.modalHeader}>{l10n.components.editProfile.MODAL_HEADER_EDIT}</Typography>
          <div className={classes.addImage}>
            <UserImage
              pictureId={profilePictureId}
              setPictureId={setProfilePictureId}
              variant="large"
            />
          </div>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handlerEditProfile)}
          >
            <div className={classes.inputRow}>
              <Controller
                render={({ field }) => (
                  <div className={classes.flexColumn}>
                    <TextInput
                      label={l10n.components.editProfile.LABEL_NAME}
                      name="firstName"
                      errors={errors.firstName}
                      field={{ ...field }}
                    />
                  </div>
                )}
                name="firstName"
                control={control}
                defaultValue={userData.firstName}
              />
              <Controller
                render={({ field }) => (
                  <div className={classes.flexColumn}>
                    <TextInput
                      label={l10n.components.editProfile.LABEL_LASTNAME}
                      name="lastName"
                      errors={errors.lastName}
                      field={{ ...field }}
                    />
                  </div>
                )}
                name="lastName"
                control={control}
                defaultValue={userData.lastName}
              />
            </div>
            <div className={classes.inputRow}>
              <FormControl
                variant="outlined"
                size="small"
                className={clsx(classes.flexColumn, { [classes.invalid]: !!errors.countryId })}
              >
                <SingleSelect
                  filterProps={{
                    filterName: 'countryId',
                    getAvailableItems: CountryService.get,
                    label: l10n.components.editProfile.LABEL_COUNTRY,
                    placeHolder: l10n.components.editProfile.PLACEHOLDER_COUNTRY,
                    type: selectType.single
                  }}
                  defaultValue={userData.countryId}
                  onValueSelected={onFilterSelected}
                  className={classes.select}
                />
                <FormHelperText className={classes.invalid} data-testid="countryError">
                  {errors?.countryId?.message}
                </FormHelperText>
              </FormControl>
              <Controller
                render={({ field }) => (
                  <div className={classes.flexColumn}>
                    <TextInput
                      label={l10n.components.editProfile.LABEL_CITY}
                      name="city"
                      errors={errors.city}
                      field={{ ...field }}
                    />
                  </div>
                )}
                name="city"
                control={control}
                defaultValue={userData.city}
              />
            </div>
            <div className={classes.dateController}>
              <Controller
                render={({ field }) => (
                  <FormControl
                    className={clsx([classes.inputRow], { [classes.invalid]: errors?.date, [classes.inputRow]: !errors?.date })}
                  >
                    <div className={classes.flexColumn}>
                      <p className={classes.dateLabel}>{l10n.components.editProfile.BIRTHDAY}</p>
                      <TextField
                        {...field}
                        inputProps={{ 'data-testid': 'date' }}
                        type="date"
                        size="small"
                        variant="outlined"
                        className={classes.dateBirth}
                      />
                      <FormHelperText data-testid="dateError" className={classes.invalid}>
                        {!!errors.birthday && l10n.components.editProfile.BIRTHDAY_ERROR}
                      </FormHelperText>
                    </div>
                  </FormControl>
                )}
                name="birthday"
                defaultValue={getFormattedDate(userData.birthday)}
                control={control}
              />
            </div>
            <div className={classes.inputRow}>
              <Controller
                render={({ field }) => (
                  <div className={classes.flexColumn}>
                    <TextInput
                      label="Facebook"
                      name="facebookLink"
                      errors={errors.facebookLink}
                      field={{ ...field }}
                    />
                  </div>
                )}
                name="facebookLink"
                control={control}
                defaultValue={userData.facebookLink || ''}
              />
              <Controller
                render={({ field }) => (
                  <div className={classes.flexColumn}>
                    <TextInput
                      label="Instagram"
                      name="instagramLink"
                      errors={errors.instagramLink}
                      field={{ ...field }}
                    />
                  </div>
                )}
                name="instagramLink"
                control={control}
                defaultValue={userData.instagramLink || ''}
              />
            </div>
            <div>
              <ATButton
                isSubmit
                variant="primary"
                fill="solid"
                size="lg"
                className={clsx(buttonStyle.button, classes.button)}
              >
                {l10n.components.editProfile.SAVE_CHANGES}
              </ATButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default EditProfile;

EditProfile.defaultProps = defaultProps;