import React, { useEffect, useState } from 'react';
import IncomingInvitationData from '../../../../models/pages/team/IncomingInvitationData';
import InvitationCard from './card/Card';
import useStyles from './IncomingInvitation.styles';
import TeamsService from '../../../../services/main/Team.service';
import Loader from '../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const IncomingInvitations = (props: {
  refresh: EmptyVoidFunction
}) => {
  const { refresh } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<IncomingInvitationData[]>([]);
  const l10n = useTranslation();

  const getInvitations = async () => {
    setIsLoading(true);
    const res = await TeamsService.getAllActiveInvitations();
    if (res) {
      setInvitations(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInvitations();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (invitations.length) {
    return (
      <div className={classes.wrapper}>
        {invitations.map((invitation) => (
          <InvitationCard
            refresh={refresh}
            key={invitation.invitationId}
            data={invitation}
            getInvitations={getInvitations}
          />
        ))}
      </div>
    );
  }

  return (
    <p className={classes.basicText}>{l10n.pages.NO_INVITATIONS}</p>
  );
};

export default IncomingInvitations;