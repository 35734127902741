import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useStores } from '../../stores/Main';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import LoaderFullScreen from '../loader/fullScreen/FullScreen';
import MetaDescription from '../metaDescription/MetaDescription';
import useStyles from './LocationCard.styles';
import Map from '../map/Map';
import CarouselImage from '../carouselImage/CarouselImage';
import { getImgUrlsList } from '../../utils/Utils';
import IdQueryParam from '../../models/components/dictionaries/location/IdQueryParam';
import useTranslation from '../localization/customHooks/Translation';
import InfoPanel from './Components/InfoPanel';
import RTEtext from '../RTEtext/RTEtext';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';
import ClosestTournamentCard from '../tournamentsCards/closestTournamentCard/ClosestTournamentCard';
import LocationService from '../../services/main/dictionaries/Location.service';
import FinishedTournament from '../../models/pages/tournament/FinishedTournament';
import FinishedTournamentCard from '../tournamentsCards/finishedTournamentCard/FinishedTournamentCard';
import transformClosestTournamentData from '../tournamentsCards/closestTournamentCard/ClosestTournamentCard.utils';

const LocationCard = observer(() => {
  const MaxTournaments = 3;
  const url = useParams<IdQueryParam>();
  const id = Number(url.id);
  const classes = useStyles();
  const l10n = useTranslation();
  const { LocationStore } = useStores();
  const { location, isLoading } = LocationStore;
  const [UpcomingTournaments, setUpcomingTournaments] = useState<ClosestTournamentCardData[]>([]);
  const [FinishedTournaments, setFinishedTournaments] = useState<FinishedTournament[]>([]);
  const [UpcomingTournamentsCollapsed, setUpcomingTournamentsCollapsed] = useState<boolean>(true);
  const [FinishedTournamentsCollapsed, setFinishedTournamentsCollapsed] = useState<boolean>(true);

  const getUpcomingTournaments = async () => {
    const res = await LocationService.getLocationTournaments(Number(id));
    setFinishedTournaments(res.finishedTournaments || []);
    setUpcomingTournaments(transformClosestTournamentData(res.upcomingTournaments) || []);
  };

  useEffect(() => {
    const getLocationById = async () => LocationStore.getLocation(Number(id));
    const setId = () => LocationStore.setId(Number(id));
    getLocationById();
    getUpcomingTournaments();
    setId();
  }, [id]);

  const urlName = [
    { displayName: l10n.components.locationCard.MAIN_PAGE, url: '/' },
    { displayName: l10n.components.locationCard.LOCATIONS_PAGE, url: '/locations' },
    { displayName: `${location?.name}`, url: '' }
  ];

  const getCoordinates = (latitude: number, longitude: number) => `https://yandex.com/maps/?ll=
  ${longitude},${latitude}&mode=whatshere&whatshere[point]=${longitude},${latitude}&whatshere[zoom]=11.8&z=13`;

  function filterTournaments(index: number, collapsed: boolean) {
    return collapsed ? index < MaxTournaments : true;
  }

  return (
    <>
      {!isLoading && location
        ? (
          <div className={classes.main}>
            <MetaDescription title={location?.name} />
            <Breadcrumbs urls={urlName} className={classes.breadCrumbs} />
            <div className={classes.locationContainer}>
              <div className={classes.locationDescription}>
                <InfoPanel location={location} />
                <RTEtext text={location.description || ''} />
              </div>
            </div>
            <div className={classes.table}>
              {!!UpcomingTournaments.length && (
                <div>
                  <Typography className={classes.columnTitle} variant="h2">{l10n.components.locationCard.UPCOMING_TOURNAMENTS}</Typography>
                  <div className={classes.upcomingCards}>
                    {UpcomingTournaments
                      .filter((_, index) => filterTournaments(index, UpcomingTournamentsCollapsed))
                      .map((tournament: ClosestTournamentCardData) => (
                        <ClosestTournamentCard
                          key={tournament.id}
                          tournament={tournament}
                          getTournaments={getUpcomingTournaments}
                        />
                      ))}
                    {(UpcomingTournaments.length > MaxTournaments) && (
                      <Button
                        className={clsx(classes.allTournamentsButton)}
                        onClick={() => {
                          setUpcomingTournamentsCollapsed(!UpcomingTournamentsCollapsed);
                        }}
                      >
                        {l10n.shared.ALL_UPCOMING_TOURNAMENTS}
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {!!FinishedTournaments.length && (
                <div>
                  <Typography className={classes.columnTitle} variant="h2">{l10n.components.locationCard.FINISHED_TOURNAMENTS}</Typography>
                  <div className={classes.finishedCards}>
                    {FinishedTournaments
                      .filter((_, index) => filterTournaments(index, FinishedTournamentsCollapsed))
                      .map((tournament: FinishedTournament) => (
                        <FinishedTournamentCard
                          key={tournament.tournamentId}
                          tournamentData={tournament}
                        />
                      ))}
                    {(FinishedTournaments.length > MaxTournaments) && (
                      <Button
                        className={clsx(classes.allTournamentsButton)}
                        onClick={() => {
                          setFinishedTournamentsCollapsed(!FinishedTournamentsCollapsed);
                        }}
                      >
                        {l10n.shared.ALL_FINISHED_TOURNAMENTS}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={classes.map}>
              <Typography className={classes.mapTitle} variant="h2">{l10n.components.locationCard.LOCATION_MAP}</Typography>
              <div className={classes.link}>
                <a
                  href={getCoordinates(location.latitude, location.longitude)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {l10n.components.locationCard.HOW_TO_GET_TO}
                </a>
              </div>
              <Map />
            </div>
            <div className={classes.photoGallery}>
              <Typography variant="h2">{l10n.components.locationCard.GALLERY}</Typography>
              { location.images && location.images.length ? (
                <CarouselImage
                  urlList={getImgUrlsList(location.images)}
                  imgStyles={classes.imgCarousel}
                  showThumbs
                />
              ) : (
                <Typography variant="h6" gutterBottom align="center">
                  {l10n.components.photoGallery.EMPTY_PHOTO_LIST}
                </Typography>
              )}
            </div>
          </div>
        )
        : <LoaderFullScreen />}
    </>
  );
});

export default LocationCard;