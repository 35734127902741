import React from 'react';
import ContentLoader from 'react-content-loader';
import useStyles from '../Loader.style';

function MainPage() {
  const styles = useStyles();

  return (
    <ContentLoader
      className={styles.width}
      speed={2}
      height={375}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="400" height="375" />
    </ContentLoader>
  );
}

export default MainPage;