import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Modal from '../../../../components/modal/Modal';
import DeleteTournamentModal from './modal/Modal';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../../components/localization/customHooks/Translation';

export default function DeleteTournament(props: { getTournament: EmptyVoidFunction, id: number }) {
  const { getTournament, id } = props;
  const [open, setOpen] = useState(false);
  const l10n = useTranslation();

  return (
    <>
      <Modal
        header={l10n.components.headerLabel.DELETE}
        open={open}
        setOpen={setOpen}
        onClose={getTournament}
      >
        <DeleteTournamentModal tournamentId={id} />
      </Modal>

      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => setOpen(true)}
      >
        <Delete />
      </IconButton>
    </>
  );
}