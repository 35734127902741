/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import colors from '../../../../../../../styles/Variables';

const StyledRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:first-child td, &:first-child th': {
    backgroundColor: colors.lightGreen,
  },
  '&:nth-child(4) td, &:nth-child(4) th': {
    backgroundColor: colors.lightOrange,
  },
  '&:nth-child(7) td, &:nth-child(7) th': {
    backgroundColor: colors.lightRed,
  },
}));

const StyledTableRow = (props: any) => <StyledRow>{props.children}</StyledRow>;

export default StyledTableRow;