import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import schema from '../../helpers/ValidationSchema';
import useStyles from '../../Tackles.styles';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import { useStores } from '../../../../stores/Main';
import Toasters from '../../../popUp/PopUp';
import useTranslation from '../../../localization/customHooks/Translation';
import Tackle from '../../../../models/components/tackles/Tackle';
import TextInput from '../../../inputs/textField/TextInput';
import ATButton from '../../../button/Button';
import Loader from '../../../loader/Loader';

const AddLocationForm = observer((props: {
  handleClose?: EmptyVoidFunction;
  isAddModalOpened: boolean;
}) => {
  const { TacklesStore } = useStores();
  const { handleClose, isAddModalOpened } = props;
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Tackle>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: isAddModalOpened ? TacklesStore.emptyTackleData : TacklesStore.userTackle,
  });
  const classes = useStyles();
  const l10n = useTranslation();
  const editTackle = async (data: Tackle) => {
    setIsLoading(true);
    const response = await TacklesStore.editTackle(data);
    if (response?.id) {
      Toasters.success(l10n.components.toasterMessage.TACKLES_WERE_EDITED);
    }
    setIsLoading(false);
    if (handleClose) {
      handleClose();
    }
  };

  const addTackle = async (data: Tackle) => {
    setIsLoading(true);
    const response = await TacklesStore.addTackle(data);
    if (response?.id) {
      Toasters.success(l10n.components.toasterMessage.TACKLES_WERE_ADDED);
    }
    setIsLoading(false);
    if (handleClose) {
      handleClose();
    }
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(isAddModalOpened ? addTackle : editTackle)}
      className={classes.inputContainer}
    >
      <Controller
        render={({ field }) => (
          <div className={classes.input}>
            <TextInput
              field={{ ...field }}
              label={l10n.components.tackles.SPINNING}
              errors={errors.spinning}
              name="spinning"
            />
          </div>
        )}
        name="spinning"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <div className={classes.input}>
            <TextInput
              field={{ ...field }}
              name="reel"
              label={l10n.components.tackles.REEL}
              errors={errors.reel}
            />
          </div>
        )}
        name="reel"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <div className={classes.input}>
            <TextInput
              name="wire"
              field={{ ...field }}
              label={l10n.components.tackles.WIRE}
              errors={errors.wire}
            />
          </div>
        )}
        name="wire"
        control={control}
      />
      <div className={classes.buttonContainer}>
        <ATButton
          testId="save-tackles"
          fill="solid"
          size="md"
          isSubmit
          variant="primary"
          className={clsx(classes.button, classes.submit, classes.buttonSize)}
        >
          <>{l10n.components.button.SAVE}</>
        </ATButton>
        <ATButton
          testId="cancel"
          fill="outline"
          size="md"
          variant="primary"
          onClick={() => {
            if (handleClose) { handleClose(); }
          }}
          className={clsx(classes.button, classes.buttonSize)}
        >
          <>{l10n.components.button.CANCEL}</>
        </ATButton>
      </div>
    </form>
  );
});

export default AddLocationForm;