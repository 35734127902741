import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField, Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import SeasonsService from '../../../../../../services/main/dictionaries/Season.service';
import Season from '../../../../../../models/components/dictionaries/season/Season';
import schema from '../helpers/ValidationSchema';
import useStyles from '../Seasons.styles';
import Toasters from '../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const EditSeason = (props: {
  seasonInfo: Season,
  handleClose?: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Season>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const { seasonInfo, handleClose } = props;
  const editNewSeason = async (data: Season) => {
    const result = { ...data };
    result.id = seasonInfo.id;
    setIsLoaded(true);
    const response = await SeasonsService.update(result);
    if (response) {
      Toasters.success(l10n.components.seasons.EDIT_SEASON_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };

  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form onSubmit={handleSubmit(editNewSeason)} className={classes.contentWrapper}>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.seasons.LABEL_NAME}
            variant="outlined"
            error={!!errors.name}
            helperText={errors?.name?.message}
            className={classes.contentInput}
            autoFocus
            inputProps={{ 'data-testid': 'name' }}
          />
        )}
        name="name"
        control={control}
        defaultValue={seasonInfo.name}
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.seasons.LABEL_DESCRIPTION}
            variant="outlined"
            className={classes.contentInput}
            multiline
            rows={4}
            inputProps={{ 'data-testid': 'description' }}
          />
        )}
        name="description"
        control={control}
        defaultValue={seasonInfo.description}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.saveButton}
        data-testid="saveBtn"
      >
        {l10n.components.seasons.SAVE_BUTTON}
      </Button>
    </form>
  );
};

export default EditSeason;

EditSeason.defaultProps = defaultProps;