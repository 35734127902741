import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  empty: {
    width: 'fit-content',
    margin: '20px auto',
    opacity: 0.7
  },
  membersList: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: '0px 15px',
      [theme.breakpoints.down(480)]: {
        padding: '0px 5px',
      },
    },
  },
  mobileContainer: {
    [theme.breakpoints.down('xs')]: {
      '& div:last-child': {
        whiteSpace: 'normal',
        textAlign: 'left',
        marginLeft: 5
      },
      '& div > span > span': {
        bottom: '25%'
      },
    }
  }
}));

export default useStyles;