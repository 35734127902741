import React, { ChangeEventHandler } from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import useStyles from '../styles';

const defaultProps = {
  placeHolder: '',
  field: { name: '', value: '' },
  onBlur: () => { },
};

function TextInput(props: {
  name: string
  errors: FieldError | undefined
  field?: { name: string, value: string | undefined }
  label: string
  placeHolder?: string
  onBlur?: ChangeEventHandler
}) {
  const classes = useStyles();
  const {
    name, errors, field, label, placeHolder, onBlur
  } = props;
  return (
    <>
      <FormHelperText className={clsx(classes.label, classes.basicText)}>{label}</FormHelperText>
      <TextField
        {...field}
        className={clsx(classes.input, classes.basicText)}
        type="text"
        variant="outlined"
        placeholder={placeHolder}
        fullWidth
        id={name}
        name={name}
        error={errors && !!errors}
        helperText={errors?.message}
        onBlur={onBlur}
      />
    </>
  );
}

TextInput.defaultProps = defaultProps;

export default TextInput;