import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import SeasonsService from '../../../../../services/main/dictionaries/Season.service';
import Season from '../../../../../models/components/dictionaries/season/Season';
import SeasonTable from './table/SeasonsTable';
import Modal from '../../../../../components/modal/Modal';
import AddSeasonModal from './modals/AddSeason';
import DeleteSeasonModal from './modals/DeleteSeason';
import EditSeasonModal from './modals/EditSeason';
import useStyles from './Seasons.styles';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Seasons = () => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(true);
  const [seasons, setSeasons] = useState<Season[] | null>([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [seasonInfo, setSeasonInfo] = useState<Season>({
    id: 0,
    name: '',
    description: '',
  });

  const l10n = useTranslation();

  const getAllSeasons = async () => {
    const res = await SeasonsService.getAll();
    setSeasons(res);
    setIsLoaded(false);
  };

  useEffect(() => {
    getAllSeasons();
  }, []);

  if (isLoaded) {
    return (<LoaderFullScreen />);
  }
  return (
    <div className={classes.tableWrapper}>
      <Button
        data-testid="add-button"
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddCircleOutline />}
        onClick={() => setOpenAdd(true)}
      >
        {l10n.components.seasons.ADD_SEASON_BUTTON}
      </Button>
      <Modal
        header={l10n.components.seasons.ADD_SEASON_MODAL}
        open={openAdd}
        setOpen={setOpenAdd}
        onClose={() => getAllSeasons()}
      >
        <AddSeasonModal />
      </Modal>
      <Modal
        header={l10n.components.seasons.DELETE_SEASON_MODAL}
        open={openDelete}
        setOpen={setOpenDelete}
        onClose={() => getAllSeasons()}
      >
        <DeleteSeasonModal seasonId={seasonInfo.id} />
      </Modal>
      <Modal
        header={l10n.components.seasons.EDIT_SEASON_MODAL}
        open={openEdit}
        setOpen={setOpenEdit}
        onClose={() => getAllSeasons()}
      >
        <EditSeasonModal seasonInfo={seasonInfo} />
      </Modal>
      <SeasonTable
        seasonsList={seasons}
        setOpenDelete={setOpenDelete}
        setOpenEdit={setOpenEdit}
        setSeasonInfo={setSeasonInfo}
      />
    </div>
  );
};

export default Seasons;