import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import AreatroutTable from '../table/Table';
import Participant from '../../models/pages/tournament/Participant';
import ParticipantData from '../../models/pages/tournament/ParticipantData';
import useStyles from './ParticipantsList.styles';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  onFilterChanged: () => { },
  toggleSortOrder: () => { },
};

function ParticipantsList(props: {
  participants: Participant[],
  onFilterChanged?: (data: Participant[], text: string) => void, // eslint-disable-line
  toggleSortOrder?: any
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    participants, onFilterChanged, toggleSortOrder
  } = props;
  const [participantsData, setParticipantsData] = useState<Participant[]>(participants);

  useEffect(() => {
    setParticipantsData(participants);
  }, [participants]);

  const columns = [
    {
      displayName: '№',
      fieldName: 'anglerNumber',
      render: ({ anglerNumber }: ParticipantData) => <div className={clsx(classes.columnNumber, classes.textStyle)}>{anglerNumber}</div>,
    },
    {
      displayName: l10n.components.participantsList.ANGLER,
      fieldName: 'angler.fullName',
      render: ({ anglerId, angler }: ParticipantData) => (
        <div className={clsx(classes.nameNumber, classes.textStyle)}>
          <a href={`#${anglerId}`}>{angler.fullName}</a>
        </div>
      ),
    },
    {
      displayName: <p className={classes.tick}>&#10004;</p>,
      fieldName: 'isFull',
      render: ({ isCompleted }: ParticipantData) => isCompleted && <p className={clsx(classes.columnNumber, classes.smallTick)}>&#10004;</p>,
    },
  ];
  return (
    <div className={classes.participantsList}>
      <AreatroutTable
        columns={columns}
        rows={participantsData}
        onFilterChanged={onFilterChanged}
        areActionsAvailable
        className={classes.table}
        toggleSortOrder={toggleSortOrder}
        isThirdSortClickAllowed={false}
        isPaging={false}
      />
    </div>
  );
}

export default ParticipantsList;

ParticipantsList.defaultProps = defaultProps;