import React from 'react';
import {
  Link,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useStyles from './Info.styles';
import ClosestTournamentInfo
  from '../../../../../components/tournamentsCards/closestTournamentCard/info/Info';
import { modifyClosestTournamentData, modifyFinishedTournamentData } from '../../cards/Cards.utils';
import TournamentCardData from '../../../../../models/pages/tournament/TournamentCardData';
import { EmptyVoidFunction } from '../../../../../utils/types/Types';
import FinishedTournamentInfo
  from '../../../../../components/tournamentsCards/finishedTournamentCard/info/Info';
import { tournamentStatus } from '../../../../../utils/enum/Enum';
import tournamentLogo from '../../../../../assets/tournament-logo.png';
import { getImageUrl, toDate } from '../../../../../utils/Utils';
import Tags from '../../../../../components/tags/Tags';
import ATButton from '../../../../../components/button/Button';
import Description from './description/Description';
import regulationButton from '../../../../../assets/icons/regulation.svg';
import { statusIdx } from '../../../../../utils/constants/Constants';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import Weather from '../../../../../components/tournamentsCards/components/Weather';

const TournamentCardInfo = (props: {
  tournament: TournamentCardData
  getTournament: EmptyVoidFunction
}) => {
  const { tournament, getTournament } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.tournamentImage}>
        {tournament?.tournamentPictureId
          ? <img src={getImageUrl(tournament.tournamentPicture?.thumbnailImageUrl)} alt={`${l10n.pages.AREATROUT_LOGO} - ${tournament.name}`} />
          : <img src={tournamentLogo} alt={l10n.pages.AREATROUT_LOGO} />}
      </div>
      <Grid container justify="space-between" className={classes.content}>
        <div className={classes.info}>
          <div className={classes.date}>{toDate(tournament.date, 'LL')}</div>
          <Typography component="h1" className={classes.title}>{tournament.name}</Typography>
          <Tags
            className={classes.tag}
            variant={`event-${tournament ? statusIdx[tournament.statusId] : statusIdx[tournamentStatus.Open]}`}
          >
            {tournament && tournament?.status}
          </Tags>
          {tournament.statusId === tournamentStatus.Completed
            ? (
              <div className={classes.tournamentContainer}>
                <FinishedTournamentInfo tournament={modifyFinishedTournamentData(tournament)} />
                <div className={classes.weatherMobile}>
                  {tournament?.weather && <Weather weatherData={tournament?.weather} />}
                </div>
              </div>
            )
            : (
              <div className={classes.tournamentContainer}>
                <ClosestTournamentInfo
                  closestTournament={modifyClosestTournamentData(tournament)}
                  getTournaments={getTournament}
                />
                <div className={classes.weatherMobile}>
                  {tournament?.weather && <Weather weatherData={tournament?.weather} />}
                </div>
              </div>
            )}
        </div>
        <Description
          label={l10n.pages.TOURNAMENT_DESCRIPTION}
          className={classes.description}
          text={tournament.description}
          isOpen={tournament.statusId !== tournamentStatus.Completed}
        />
        <div>
          <ATButton
            disabled={!tournament.regulationsLink}
            variant="secondary"
            fill="outline"
            size="md"
            className={classes.button}
          >
            <Link
              href={tournament.regulationsLink}
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener"
            >
              <img src={regulationButton} alt="regulation-icon" className="icon" />
              <span>{l10n.pages.TOURNAMENT_REGULATIONS}</span>
            </Link>
          </ATButton>
          <div className={classes.weatherDesktop}>
            {tournament?.weather && <Weather weatherData={tournament?.weather} />}
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default TournamentCardInfo;