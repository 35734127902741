import React from 'react';
import 'moment/locale/ru';
import useStyles from './Date.styles';
import { toLocaleDate } from './Date.utils';

const defaultProps = {
  className: '',
};

const Date = (props: {
  date: string | undefined,
  className?: string

}) => {
  const classes = useStyles();
  const { date, className } = props;
  const day = toLocaleDate(date).format('D');
  const month = toLocaleDate(date).format('MMM').slice(0, -1);

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.month}>{month}</div>
      <div className={classes.day}>{day}</div>
    </div>
  );
};

export default Date;

Date.defaultProps = defaultProps;