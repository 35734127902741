import React from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../../../../stores/Main';
import useConfirmationDialog from '../../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import TournamentService from '../../../../../../services/main/Tournament.service';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

function ButtonCreateCards(props: {
  saveNumbers: () => Promise<boolean>;
}) {
  const { TournamentStore } = useStores();
  const l10n = useTranslation();
  const {
    saveNumbers
  } = props;

  const url = useParams<{ id: string }>();
  const id = Number(url.id);

  const generateDrawingCouples = async () => {
    const response = await TournamentService.generateDrawingCouples(id);
    if (response) {
      TournamentStore.getTournament(id);
      Toasters.success(l10n.components.toasterMessage.DRAWING_WAS_HELD);
    }
  };

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: l10n.components.headerLabel.DRAW,
    bodyText: l10n.components.confirmDialogsMessage.HOLD_TOURNAMENT,
    confirmationButtonText: l10n.components.button.CONTINUE,
    onConfirmClick: () => {
      onClose();
      generateDrawingCouples();
    },
  });
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={TournamentStore.isDrawing}
        onClick={async () => {
          if (await saveNumbers()) {
            onOpen();
          }
        }}
      >
        {l10n.components.button.CREATE_CARDS}
      </Button>
      <Dialog />
    </>
  );
}

export default ButtonCreateCards;