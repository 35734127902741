import {
  number, object, string
} from 'yup';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const validatePoints = () => number()
  .test('digitsAfterPoint', l10n.components.tournamentsTemplate.DIGITS_AFTER_POINT,
    (value: number | undefined) => Number.isInteger(value! * 10)).moreThan(-1)
  .required(l10n.components.tournamentsTemplate.REQUIRED_NUMBER);

const validateString = () => string().required(l10n.components.tournamentsTemplate.REQUIRED_FIELD);

const schema = object().shape({
  name: validateString(),
  roundsCount: number().min(1).max(20).positive(l10n.components.tournamentsTemplate.TYPE_NUMBER_MORE_THAN_ZERO)
    .integer(l10n.components.tournamentsTemplate.TYPE_INTEGER_NUMBER)
    .typeError(l10n.components.tournamentsTemplate.REQUIRED_NUMBER)
    .required(l10n.components.tournamentsTemplate.REQUIRED_NUMBER),
  description: validateString(),
  pointsForDrawWithCatches: validatePoints(),
  pointsForDrawWithoutCatches: validatePoints(),
  pointsForLoseWithDomination: validatePoints(),
  pointsForLoseWithoutDomination: validatePoints(),
  pointsForLoseWithoutCatches: validatePoints(),
  pointsForWin: validatePoints(),
});

export default schema;