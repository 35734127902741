import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  fullWidth: {
    maxWidth: 1400,
    width: '100%',
  },
  logo: {
    width: 100,
  },
  link: {
    color: '#4833b9',
  },
  sponsorImg: {
    maxWidth: 250,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
    },
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperSelectedSponsors: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    justifyContent: 'space-evenly',
    gap: 50,
  },
  wrapper: {
    maxWidth: 750,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    textAlign: 'center',
    alignItems: 'center',
  },
  generalWrapper: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },
  resetButton: {
    marginBottom: 20,
    alignSelf: 'flex-end',
  },
  saveButton: {
    marginTop: 20,
    marginBottom: 20,
    float: 'right',
  },
  radioBtn: {
    '& .MuiRadio-colorPrimary': {
      color: 'blue',
    }
  },
}));

export default useStyles;