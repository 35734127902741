import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  main: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
  },
  breakText: {
    wordBreak: 'break-word',
  },
  breadCrumbs: {
    marginTop: 30,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  newsTitle: {
    margin: '0px 16px 16px 16px',
  },
  newsDescription: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: '48px',
    fontWeight: 'bold',
    color: colors.secondary,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px'
    },
  },
  description: {
    width: '100%',
    marginTop: 10,
    maxWidth: 896,
    order: 3,
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  link: {
    color: colors.link,
    '& a': {
      color: colors.link,
      fontSize: 16
    }
  },
  photoGallery: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    maxWidth: 1360,
    width: '90vw',
    height: '90vh',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
      height: 350,
      margin: 'auto',
    },
  },
  imgCarousel: {
    maxWidth: '90vw',
    maxHeight: '90vh',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      maxHeight: 350,
    },
  },
  country: {
    fontSize: 20
  },
  buttonStatus: {
    margin: 'auto'
  },
  table: {
    margin: 16,
  },
  infoAboutReport: {
    display: 'flex',
    gap: 30,
    [theme.breakpoints.down('xs')]: {
      gap: 5,
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  flag: {
    width: 20,
    height: 20,
    marginRight: 4
  },
  location: {
    whiteSpace: 'nowrap'
  },
  flagWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  reportMain: {
  },

  reportText: {
    marginLeft: 15,
  },
  reportFooter: {
    marginTop: 50,
  },
  reportHeader: {
    marginTop: 10,
    marginBottom: 50,
  },
  socialButtons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  shareButton: {
    width: 50,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
      gap: 5,
    },
  },
  date: {
    margin: 0,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  }
}));

export default useStyles;