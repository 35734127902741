import React, { useState } from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import RankingService from '../../../../../../../services/main/dictionaries/Ranking.service';
import useStyles from './DeleteRanking.styles';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const DeleteRanking = (props: {
  rankingId: number,
  handleClose?: EmptyVoidFunction
}) => {
  const { rankingId, handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteRanking = async () => {
    setIsLoading(true);
    const res = await RankingService.delete(rankingId);

    if (res) {
      Toasters.success(l10n.components.deleteRanking.DELETE_RANKING_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }

    setIsLoading(false);
  };
  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.deleteRanking.CONFIRM_DELETE}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-testid="cancelBtn"
        >
          {l10n.components.deleteRanking.CANCEL_DELETE}
        </Button>
        <Button
          onClick={deleteRanking}
          color="primary"
          variant="contained"
          autoFocus
          data-testid="deleteBtn"
        >
          {l10n.components.deleteRanking.DELETE}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteRanking;

DeleteRanking.defaultProps = defaultProps;