import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  invalid: {
    color: 'red',
  },
  saveButton: {
    margin: '0 auto',
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
  },
  map: {
    height: 200
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
    boxSizing: 'border-box',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      maxWidth: '100vw',
      marginTop: 50,
    },
  },
  imgContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: 15,
  },
  imgSizes: {
    width: 250,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  resultWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  roundName: {
    float: 'left',
    marginBottom: 0,
  },
  itemInList: {
    whiteSpace: 'break-spaces',
  },
  enemyWrapper: {
    display: 'flex',
    float: 'right',
    position: 'relative',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      gap: 15,
    },
  },
  win: {
    backgroundColor: colors.greenWinner,
  },
  draw: {
    backgroundColor: colors.orangeDraw,
  },
  lose: {
    backgroundColor: colors.redLose,
  },
  opponentName: {
    fontWeight: 900,
  },
  nullBottomMargin: {
    marginBottom: 0,
    height: 20,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      height: 40,
    },
  },
  score: {
    position: 'absolute',
    right: '50%',
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      right: 0,
    },
  },
}));

export default useStyles;