import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, TextField, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import Article from '../../../../../models/components/article/Article';
import schema from './helpers/ValidationSchema';
import useStyles from './ArticleModal.styles';
import Toasters from '../../../../../components/popUp/PopUp';
import { EmptyVoidFunction } from '../../../../../utils/types/Types';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import { limitOfImagesForNewArticle } from '../../../../../utils/constants/Constants';
import { articles } from '../../../../../utils/enum/Enum';
import EditableTextField from '../../../../../components/inputs/textField/Editable';
import UploadImage from '../../../../../components/uploadImages/UploadImage';
import UserPicture from '../../../../../components/userPicture/UserPicture';
import ImageData from '../../../../../models/components/image/ImageData';

const AddOrEditArticleModal = observer((props: {
  handleClose?: EmptyVoidFunction,
  isEditModalOpen?: boolean,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Article>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const classes = useStyles();
  const l10n = useTranslation();
  const { handleClose, isEditModalOpen } = props;
  const { ArticlesStore } = useStores();
  const editor = useRef(null);

  const [images, setImages] = useState<ImageData[] | [] | null>([]);

  const setLocationDescription = (newComponent: string) => setValue('description', newComponent, { shouldDirty: true, shouldValidate: true });

  const getIdsOfImg = () => images!.map((el: ImageData) => el.id);

  useEffect(() => {
    if (isEditModalOpen && ArticlesStore.article) {
      const imgs = ArticlesStore.article.images.map((el: any) => el.image);
      setImages(imgs);
    }
  }, [ArticlesStore.article]);

  const saveOrUpdateArticle = async (data: Article) => {
    if (images!.length > limitOfImagesForNewArticle) {
      Toasters.error(l10n.components.error.IMG_COUNT_ERROR);
    } else {
      if (isEditModalOpen) await ArticlesStore.updatePictures(getIdsOfImg());
      data.imagesIds = images!.length ? getIdsOfImg() : [];

      const response = isEditModalOpen ? await ArticlesStore.editArticle(data) : await ArticlesStore.addArticle(data);

      if (response) {
        Toasters
          .success(isEditModalOpen ? l10n.components.articles.EDIT_ARTICLE_SUCCESS : l10n.components.articles.ADD_ARTICLE_SUCCESS);
        ArticlesStore.refreshArticles();
      }
      if (handleClose) {
        handleClose();
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(saveOrUpdateArticle)}
      autoComplete="off"
      className={classes.inputContainer}
    >
      <FormControl variant="outlined">
        <InputLabel>{l10n.components.articles.SUBCATEGORY_OF_PUBLICATION}</InputLabel>
        <Controller
          name="status"
          control={control}
          defaultValue={isEditModalOpen && ArticlesStore.article ? ArticlesStore.article.status : ''}
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label={l10n.components.articles.SUBCATEGORY_OF_PUBLICATION}
              value={field.value}
              onChange={field.onChange}
              error={!!errors.status}
            >
              <MenuItem value={articles.announcement}>{l10n.components.articles.ANNOUNCMENT}</MenuItem>
              <MenuItem value={articles.article}>{l10n.components.articles.ARTICLE}</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.shared.NAME}
            variant="outlined"
            error={!!errors.name}
            helperText={errors?.name?.message}
          />
        )}
        name="name"
        control={control}
        defaultValue={isEditModalOpen && ArticlesStore.article ? ArticlesStore.article.name : ''}
      />
      <Controller
        render={({ field }) => (
          <EditableTextField
            {...field}
            ref={editor}
            errors={errors.description}
            convertedContent={isEditModalOpen && ArticlesStore.article?.description ? ArticlesStore.article?.description : ''}
            onBlur={setLocationDescription}
            label=""
            placeholder={l10n.shared.DESCRIPTION}
          />
        )}
        name="description"
        control={control}
        defaultValue={isEditModalOpen && ArticlesStore.article ? ArticlesStore.article.description : ''}
      />
      <div>
        <div className={classes.imgContainer}>
          {!!images?.length && images!
            .map((img: ImageData) => <div className={classes.imgSizes} key={img.id}><UserPicture pictureInfo={img} setImage={setImages} /></div>)}
        </div>
        <UploadImage
          addImage={(img: ImageData) => { setImages((prev: any) => [...prev, img]); }} // TODO
        />
      </div>
      <Button
        data-testid="save"
        type="submit"
        variant="contained"
        color="primary"
        className={classes.saveButton}
      >
        {l10n.shared.SAVE}
      </Button>
    </form>
  );
});

export default AddOrEditArticleModal;