import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  main: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
  },
  breadCrumbs: {
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    gap: 20,
    margin: 16,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 20,
    },
  },
  imgCarousel: {
    objectFit: 'contain',
    maxWidth: '85vw',
    maxHeight: '85vh',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      maxHeight: 350,
    },
  },
  map: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 300,
    borderRadius: 8,
    marginLeft: 15,
    overflow: 'hidden',
    '& a': {
      maxWidth: 400,
      maxHeight: 400,
      fontWeight: 400,
      fontSize: 10
    },
    [theme.breakpoints.down('xs')]: {
      order: 2,
      maxWidth: '92%',
      width: '100vw',
      height: '80vw',
    },
  },
  locationDescription: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
    },
  },
  upcomingCards: {
    maxWidth: 1360,
    marginBottom: 25,
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '90%',
    },
  },
  finishedCards: {
    maxWidth: 1360,
    marginBottom: 25,
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 0,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: 48,
    fontWeight: 'bold',
    color: colors.secondary,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px'
    },
  },
  mapTitle: {
    marginBottom: 20,
  },
  columnTitle: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  description: {
    width: '100%',
    marginTop: 10,
    maxWidth: 896,
    order: 3,
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  link: {
    color: colors.link,
    '& a': {
      color: colors.link,
      fontSize: 16
    }
  },
  allTournamentsButton: {
    width: '100%',
    color: colors.link,
    fontSize: 16,
  },
  photoGallery: {
    margin: 16,
    '& h2': {
      marginTop: 10,
      marginBottom: 32
    },
  },
  country: {
    fontSize: 20
  },
  buttonStatus: {
    margin: 'auto'
  },
  table: {
    marginLeft: 15,
  },
}));

export default useStyles;