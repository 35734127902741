import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  avatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32,
    },
  },
  buttons: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  userProfile: {
    display: 'flex',
    marginRight: 12,
    '& .arrow': {
      marginLeft: 11,
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '&.down': { borderTop: '5px solid #C1C1CA' },
      '&.up': { borderBottom: '5px solid #C1C1CA' }
    },
    '& .MuiButton-root': {
      minWidth: 10,
      '&:hover': {
        backgroundColor: 'transparent'
      },
    },
    '& .MuiButton-text': {
      padding: 0,
    },
    '& .Mui-focusVisible': {
      color: 'transparent'
    }
  },
  mobileSignInIcon: {
    display: 'none',
    width: 24,
    height: 24,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  profileName: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 10,
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  modal: {
    zIndex: 100,
    '& .MuiList-padding': {
      padding: '4px 0px',
    },
    '& ul > li': {
      boxSizing: 'border-box',
      height: 38,
      padding: '8px 12px 8px 21px'
    },
    '& ul > li:hover': {
      background: 'rgba(13, 169, 178, 0.15)'
    },
    '& ul > li:active': {
      background: 'rgba(13, 169, 178, 0.3)',
    },
  },
  signInButton: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '150%',
    color: '#141429',
    marginRight: 24,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'inherit',
    cursor: 'pointer'
  },
  label: {
    '& span': {
      fontFamily: 'Source Sans Pro',
      fontSize: 16,
      lineHeight: '22px',
      textAlign: 'right'
    },
  }
}));

export default useStyles;