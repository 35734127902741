import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import ATButton from '../../../../../../../components/button/Button';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';
import { useStores } from '../../../../../../../stores/Main';
import Report from '../../../../../../../models/components/report/Report';
import { StorageService } from '../../../../../../../utils/Utils';
import ReportCard from './card/Card';
import Modal from '../../../../../../../components/modal/Modal';
import ReportControl from '../../../../../../../components/controlReport/ReportControl';
import useStyles from './Reports.styles';

const TournamentReports = observer((props: {tournamentId: string}) => {
  const { tournamentId } = props;
  const l10n = useTranslation();
  const currentUser = StorageService.getUserData();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [reportIdForEdit, setReportIdForEdit] = useState<string>('');
  const classes = useStyles();

  const { ReportsStore } = useStores();

  const getReports = async () => {
    await ReportsStore.getAllByTournamentId(tournamentId);
    if (ReportsStore.reports && currentUser) {
      const findAnglerReport = ReportsStore.reports.find((report: Report) => report.author.id === currentUser.id);
      if (findAnglerReport) {
        setReportIdForEdit(findAnglerReport.id);
      }
    }
  };

  const handleClick = () => {
    if (reportIdForEdit) {
      ReportsStore.getOneReportById(reportIdForEdit);
    }
    setIsModalOpened(true);
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <div className="mainWrapper">
      <div className={classes.button}>
        <Typography variant="h2">{l10n.components.reports.REPORTS}</Typography>
        {currentUser && (
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            onClick={handleClick}
          >
            {reportIdForEdit ? l10n.components.tournamentReports.EDIT_REPORT : l10n.components.tournamentReports.WRITE_REPORT}
          </ATButton>
        )}
      </div>
      <div>
        {ReportsStore.reports && ReportsStore.reports
          .map((report: Report) => (
            <ReportCard
              key={report.id}
              report={report}
            />
          ))}
      </div>
      <Modal
        modalClassName={classes.reportModal}
        header={reportIdForEdit ? l10n.components.tournamentReports.EDIT_REPORT : l10n.components.tournamentReports.WRITE_REPORT}
        open={isModalOpened}
        setOpen={setIsModalOpened}
        onClose={() => { ReportsStore.getAllByTournamentId(tournamentId); }}
      >
        <ReportControl tournamentId={tournamentId} isAddModalOpened={!reportIdForEdit} isEditModalOpened={!!reportIdForEdit} />
      </Modal>
    </div>
  );
});

export default TournamentReports;