import React from 'react';
import clsx from 'clsx';
import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import useStyles from './Checkbox.styles';

const defaultProps = {
  inputRef: undefined,
  disabled: false,
  className: '',
};

const Checkbox = (props: {
  inputRef?: React.Ref<any> | undefined,
  className?: string,
  disabled?: boolean
  checked: boolean,
  label: string | JSX.Element,
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const classes = useStyles();
  const {
    label, inputRef, className, checked, onChange, disabled
  } = props;

  return (
    <FormControlLabel
      className={clsx(classes.wrapper, className)}
      disabled={disabled}
      inputRef={inputRef}
      control={(
        <MUICheckbox
          onChange={onChange}
          checked={checked}
          className={classes.root}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          inputProps={{ 'aria-label': 'custom-checkbox' }}
        />
    )}
      label={label}
    />
  );
};

export default Checkbox;

Checkbox.defaultProps = defaultProps;