import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import useStyles from './CarouselImage.styles';

const defaultProps = {
  autoPlay: false,
  infiniteLoop: true,
  interval: 3000,
  imgStyles: '',
  style: '',
  showArrows: true,
  showStatus: false,
  showIndicators: false,
  showThumbs: false,
  dynamicHeight: false,
  width: '100%',
  altForImg: 'img',
};

const CarouselImage = (props: {
  width?: number | string;
  urlList: string[];
  imgStyles?: string;
  altForImg?: string;
  style?: string;
  infiniteLoop?: boolean;
  interval?: number;
  showArrows?: boolean;
  showStatus?: boolean;
  showIndicators?: boolean;
  showThumbs?: boolean;
  autoPlay?: boolean;
  dynamicHeight?: boolean;
}) => {
  const {
    width,
    urlList,
    imgStyles,
    altForImg,
    autoPlay,
    interval,
    infiniteLoop,
    showArrows,
    showStatus,
    showIndicators,
    showThumbs,
    dynamicHeight,
    style,
  } = props;
  const classes = useStyles();

  const carouselRef = React.useRef<any>(null);

  const imgList = useMemo(() => urlList!.map((el: string) => (<div key={el}><img className={imgStyles} src={el} alt={altForImg} /></div>)), [urlList]);

  useEffect(() => {
    if (carouselRef.current && urlList.length) {
      carouselRef.current.moveTo(0);
    }
  }, [urlList]);

  return (
    <Carousel
      ref={carouselRef}
      className={clsx(classes.carouselStyle, style)}
      autoPlay={autoPlay}
      infiniteLoop={infiniteLoop}
      interval={interval}
      showArrows={showArrows}
      showStatus={showStatus}
      showIndicators={showIndicators}
      showThumbs={showThumbs}
      dynamicHeight={dynamicHeight}
      width={width}
    >
      {imgList}
    </Carousel>
  );
};

CarouselImage.defaultProps = defaultProps;

export default CarouselImage;