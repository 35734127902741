import {
  autorun,
  makeAutoObservable,
} from 'mobx';
import Country from '../../models/components/dictionaries/country/Country';
import CountryService from '../../services/main/dictionaries/Country.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class CountriesStore {
  id: number = 0;

  countries: Country[] | null = null;

  init: EmptyVoidFunction;

  country: Country = {
    name: '',
    id: 0,
    flagPictureId: null,
    flag: null
  };

  isLoading: boolean = false;

  isAddModalOpen: boolean = false;

  isDeleteModalOpen: boolean = false;

  isEditModalOpen: boolean = false;

  mainStore: any;

  constructor(mainStore: any) {
    this.mainStore = mainStore.mainStore;

    makeAutoObservable(this);

    this.init = autorun(() => this.refreshCountries());

    this.init();
  }

  refreshCountries: EmptyVoidFunction = async () => {
    this.toggleLoaderState();
    const response = await CountryService.get();
    if (response) {
      this.countries = response;
    }
    this.toggleLoaderState();
  };

  toggleLoaderState = () => {
    this.isLoading = !this.isLoading;
  }

  toggleAddCountryModalState = () => {
    this.isAddModalOpen = !this.isAddModalOpen;
  }

  toggleEditCountryModalState = () => {
    this.isEditModalOpen = !this.isEditModalOpen;
  }

  toggleDeleteCountryModalState = () => {
    this.isDeleteModalOpen = !this.isDeleteModalOpen;
  }

  openAddCountryModal = () => {
    this.toggleAddCountryModalState();
    this.country = {
      id: 0, name: '', flagPictureId: null, flag: null
    };
  };

  setCountryFlag = (pictureId: number | null) => {
    if (pictureId) {
      this.country.flagPictureId = pictureId;
    }
  };

  deleteCountry = async () => {
    this.toggleLoaderState();
    const response = await CountryService.delete(this.country.id);
    this.toggleLoaderState();
    return response;
  };

  addCountry = async (data: Country) => {
    this.toggleLoaderState();
    const response = await CountryService.post(data);
    this.toggleLoaderState();
    return response;
  };

  editCountry = async (data: Country) => {
    this.toggleLoaderState();
    const result = { ...data };
    result.id = this.country.id;
    if (this.country.flagPictureId) {
      await CountryService.postFlag(this.country.id, this.country.flagPictureId);
    }
    const response = await CountryService.put(result);
    this.toggleLoaderState();
    return response;
  };

  setId = (countryId: number) => {
    this.id = countryId;
  }

  setCountry = (country: Country) => {
    this.country = country;
  }
}