import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../../../components/loader/Loader';
import { tournamentStatus } from '../../../../utils/enum/Enum';
import FinishedTournamentCard
  from '../../../../components/tournamentsCards/finishedTournamentCard/FinishedTournamentCard';
import ClosestTournamentCard from '../../../../components/tournamentsCards/closestTournamentCard/ClosestTournamentCard';
import UpcomingTournamentEntry from '../../../../models/pages/tournament/UpcomingTournamentEntry';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useStyles from './Cards.styles';
import { modifyClosestTournamentData, modifyFinishedTournamentData } from './Cards.utils';

const AvailableTournamentsCards = (props: {
  tournaments: Array<UpcomingTournamentEntry> | null,
  getTournaments: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const { tournaments, getTournaments } = props;

  const perPage = 10;
  const [cards, setCards] = useState<Array<UpcomingTournamentEntry> | null>([]);
  const [lastPosition, setLastPosition] = useState(perPage);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (tournaments) {
      setCards(tournaments.slice(0, perPage));
    }
  }, [tournaments]);

  const loadCards = () => {
    if (cards && cards.length === tournaments?.length) {
      setHasMore(false);
      return;
    }

    if (tournaments) {
      setCards((prev) => (
        prev && [...prev, ...tournaments.slice(lastPosition, lastPosition + perPage)]
      ));
    }

    setLastPosition(lastPosition + perPage);
  };

  return (
    <>
      {cards && (
        <InfiniteScroll
          hasMore={hasMore}
          next={loadCards}
          loader={<div className={classes.loader}><Loader /></div>}
          dataLength={cards.length}
          className={classes.cards}
        >
          {cards.map((tournament) => (tournament.statusId === tournamentStatus.Completed
            ? (
              <FinishedTournamentCard
                key={tournament.id}
                tournamentData={modifyFinishedTournamentData(tournament)}
              />
            )
            : (
              <ClosestTournamentCard
                key={tournament.id}
                tournament={modifyClosestTournamentData(tournament)}
                getTournaments={getTournaments}
              />
            )
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default AvailableTournamentsCards;