import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  heading: {
    margin: 0,
    fontSize: 40,
    marginLeft: 16,
  },
  mainWrapper: {
    maxWidth: 1360,
    margin: 'auto',
  },
  filterBar: {
    marginTop: 15,
    marginBottom: 15,
  },
  breadCrumbs: {
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  newsCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  previewContainer: {
    position: 'relative',
    marginBottom: 20,
  },
  textContainer: {
    width: '90%',
    '& div': {
      maxHeight: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& p': {
      maxHeight: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  publication: {
    marginBottom: 25,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  previewImg: {
    width: '90%',
  },
  gridContainer: {
    marginLeft: 20,
    rowGap: 30,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      justifyContent: 'center',
      overflow: 'hidden',
      marginLeft: 0,
    },
  },
  link: {
    color: colors.link,
  },
  tableSwitcher: {
    background: 'none',
    color: '#51b3b4',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    margin: '0px 15px 15px 15px',
    textDecoration: '#51b3b4 underline',
  },
  active: {
    color: '#141429',
    textDecoration: 'none',
  },
  element: {
    minWidth: 300,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
      maxWidth: 300,
    },
  },
  typeOfPublication: {
    top: 10,
    left: 10,
    position: 'absolute',
    width: 70,
    textAlign: 'center',
    borderRadius: 10,
  },
  labelWithImg: {
    top: 10,
    left: 10,
  },
  announcment: {
    backgroundColor: '#0a909a',
    color: 'white',
  },
  article: {
    color: 'white',
  },
  report: {
    backgroundColor: '#009e0f',
    color: 'white',
  },
  continueRead: {
    float: 'right',
  },
  infinityScroll: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  }
}));

export default useStyles;