import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import { logError } from './utils/Utils';
import LanguageContextProvider from './components/localization/contexts/LanguageContext';

const processError = (
  message: string | Event,
  url: string | undefined,
  lineNo: number | undefined,
  columnNo: number | undefined,
  error: Error | undefined
) => logError(message, error);

window.onerror = processError;

ReactDOM.render(
  <LanguageContextProvider>
    <App />
  </LanguageContextProvider>,
  document.getElementById('root'),
);