import React, { useEffect, useState } from 'react';
import {
  Box, TableCell, TableFooter, TableRow, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import AreatroutTable from '../table';
import PlaceCell from '../tableTemplates/components/cell/place/Place';
import useStyles from './HeatCaptureTable.styles';
import TournamentService from '../../services/main/Tournament.service';
import LoaderFullScreen from '../loader/fullScreen/FullScreen';
import HeatMapData from '../../models/pages/tournament/HeatMapData';
import HeatMapCell from './HeatMapCell/HeatMapCell';
import SingleSelect from '../selects/single/Single';
import SelectItem from '../../models/components/selects/SelectItem';
import {
  getRoundNumber, getTotalRow, getSectorsData, getSummaryData, getFullNames
} from './HeatCaptureTable.utils';
import useTranslation from '../localization/customHooks/Translation';

const HeatCaptureTable = (props: {
  id: number
  roundsQuantity: number
}) => {
  const { id, roundsQuantity } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [roundsNumber, setRoundsNumber] = useState<number>(0);
  const [sectorsData, setSectorsData] = useState<{ rounds: HeatMapData[] }[]>([]);
  const [names, setNames] = useState<SelectItem[]>([]);
  const [selectedAngler, setSelectedAngler] = useState({ id: 0, name: '' });
  const [totalRow, setTotalRow] = useState<number[]>([]);
  const classes = useStyles();
  const l10n = useTranslation();

  const onFilterSelected = (_: string, value: string | number | null | undefined) => {
    if (value || value === 0) {
      const current = names.find((angler) => angler.id === value);
      setSelectedAngler(current!);
    }
  };

  const catchesAmplitude = {
    minCatches: 99999,
    maxCatches: 0,
  };

  sectorsData.forEach((row) => (Array.from({ length: roundsQuantity }, (_, index) => index).forEach((index) => {
    catchesAmplitude.minCatches = Math.min(catchesAmplitude.minCatches, Number(row.rounds[index].angler.catches) + Number(row.rounds[index].opponent.catches));
    catchesAmplitude.maxCatches = Math.max(catchesAmplitude.maxCatches, Number(row.rounds[index].angler.catches) + Number(row.rounds[index].opponent.catches));
  })));

  const getParticipants = async () => {
    setIsLoading(true);
    const response = await TournamentService.getTableData(id);
    if (response) {
      getFullNames(response, setNames);
      setRoundsNumber(getRoundNumber(response));
      setSectorsData(getSectorsData(response));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (sectorsData.length) {
      setTotalRow(getTotalRow(sectorsData, roundsNumber));
    }
  }, [sectorsData]);

  const setAnglerHighlight = (roundData: any, stateAngler: Partial<SelectItem>) => roundData.angler.name === stateAngler.name
    || roundData.opponent.name === stateAngler.name;

  const totalOfAllCatches = (arrOfTotal: number[]) => arrOfTotal.reduce((acc, curr) => acc + curr, 0);

  const getRound = ((rounds: HeatMapData[], i: number) => rounds.find(({ tour }) => tour === i));

  const getRoundColumns = () => (Array.from({ length: roundsNumber }, (_, index) => index + 1).map((roundNumber: number) => ({
    displayName: `${l10n.components.heatCaptureTable.TOUR} ${roundNumber}`,
    fieldName: `Round ${roundNumber}`,
    allowSearch: false,
    allowSortring: false,
    render: ({ rounds }: any) => {
      const round: any = getRound(rounds, roundNumber);
      return (
        <HeatMapCell
          isAnglerHighlighted={setAnglerHighlight(round, selectedAngler)}
          sector={round}
          minCatches={catchesAmplitude.minCatches}
          maxCatches={catchesAmplitude.maxCatches}
        />
      );
    }
  }))
  );

  const getSummaryColumn = () => ({
    displayName: l10n.components.heatCaptureTable.TOTAL,
    fieldName: 'summary',
    allowSearch: false,
    allowSortring: false,
    render: ({ rounds }: { rounds: HeatMapData[] }) => {
      const summary = getSummaryData(rounds);
      return <div className={clsx(classes.summaryCell, classes.flexColumn)}>{summary}</div>;
    }
  }
  );

  const getSectorColumn = () => {
    const column = [
      {
        displayName: l10n.components.heatCaptureTable.SECTOR,
        fieldName: 'sectorNumber',
        allowSearch: false,
        allowSortring: false,
        render: ({ sector }: any) => <PlaceCell place={sector} className={classes.cell} />
      },
      ...getRoundColumns(),
      getSummaryColumn()
    ];

    return column;
  };

  useEffect(() => {
    getParticipants();
  }, []);

  const getSummaryFooter = () => {
    if (totalRow.length) {
      return (
        <TableFooter>
          <TableRow>
            <TableCell className={clsx(classes.boldText, classes.summaryCell)}>
              {' '}
              {l10n.components.heatCaptureTable.TOTAL}
            </TableCell>
            {totalRow?.map((cell) => (
              <TableCell
                key={uuidv4()}
                classes={{ footer: classes.tableFooter }}
                className={classes.summaryCell}
              >
                {cell}
              </TableCell>
            ))}
            <TableCell className={clsx(classes.summaryCell, classes.totalOfAll)} key={uuidv4()}>
              {totalOfAllCatches(totalRow)}
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }
    return <></>;
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h2" className={classes.title}>
        {l10n.components.heatCaptureTable.CAPTURE_MAP}
      </Typography>
      <Box className={classes.container}>
        <div>
          {l10n.components.heatCaptureTable.CHOOSE_ANGLER}
        </div>
        <SingleSelect
          filterProps={{
            filterName: 'personId',
            label: '',
            placeHolder: l10n.components.heatCaptureTable.CHOOSE_ANGLER,
            type: '',
            items: names
          }}
          onValueSelected={onFilterSelected}
          defaultValue={0}
        />
      </Box>
      <AreatroutTable
        className={classes.table}
        columns={getSectorColumn()}
        rows={sectorsData}
        isPaging={false}
        summaryFooter={getSummaryFooter()}
      />
    </Box>
  );
};

export default HeatCaptureTable;