import React from 'react';
import PlayoffRounds from '../../../../../models/pages/tournament/playoff/PlayoffRounds';
import PlayoffResult from '../../../../../models/pages/tournament/playoff/PlayoffResult';
import TournamentService from '../../../../../services/main/Tournament.service';
import { playoffIcons } from '../../../../../utils/enum/Enum';

export const getRoundsArray = (rounds: PlayoffRounds[], stage: number) => rounds.filter((round: PlayoffRounds) => round.stage === stage);

export async function postResult(
  data: PlayoffResult,
  setWinnerId: React.Dispatch<React.SetStateAction<number | null | undefined>>,
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  getPlayoffRounds: any
) {
  const response = await TournamentService.postRoundResults(data);
  if (response) {
    setWinnerId(Number(response));
    getPlayoffRounds();
  }
  setIsLoaded(false);
}

export const setRoundsOrder = (numberOfRounds: number, rounds: PlayoffRounds[]) => {
  const playoffPairs = Array(numberOfRounds).fill(null);
  rounds.forEach((round: PlayoffRounds) => {
    if (round?.roundNumber) {
      playoffPairs[round.roundNumber - 1] = round;
    }
  });
  return playoffPairs;
};

export const modifyRound = (round: PlayoffRounds) => ({
  id: round.id,
  winnerParticipantId: round.winnerParticipantId,
  firstParticipant: {
    anglerId: round.firstParticipant.angler.id,
    id: round.firstParticipantId,
    catches: round.firstParticipantCatches,
    qualifyingPlace: round.firstParticipant.qualifyingPlace,
    tournamentId: round.firstParticipant.tournamentId,
    fullName: round.firstParticipant.angler.fullName,
    country: round.firstParticipant.angler.country,
    profilePictureId: round.firstParticipant.angler.profilePictureId,
    profilePicture: round.firstParticipant.angler.profilePicture,
    qualifyingCatchesSum: round.firstParticipant.catchesSum,
    isTeam: round.isTeam,
  },
  secondParticipant: {
    anglerId: round.secondParticipant.angler.id,
    id: round.secondParticipantId,
    catches: round.secondParticipantCatches,
    place: round.secondParticipant.qualifyingPlace,
    tournamentId: round.secondParticipant.tournamentId,
    fullName: round.secondParticipant.angler.fullName,
    country: round.secondParticipant.angler.country,
    profilePictureId: round.secondParticipant.angler.profilePictureId,
    profilePicture: round.secondParticipant.angler.profilePicture,
    qualifyingPlace: round.secondParticipant.qualifyingPlace,
    qualifyingCatchesSum: round.secondParticipant.catchesSum,
    isTeam: round.isTeam
  }
});

export const getWinnerIcon = (isRoundForFirstPlace: boolean | undefined, playerId: playoffIcons | undefined, winnerId: playoffIcons | null | undefined) => {
  if (typeof winnerId === 'number') {
    if (isRoundForFirstPlace) {
      const icon = playerId === winnerId ? playoffIcons.goldCup : playoffIcons.silverCup;
      return icon;
    }
    const icon = playerId === winnerId ? playoffIcons.bronzeCup : playoffIcons.star;
    return icon;
  }
  return null;
};