import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import formatService from '../../../../../../../services/main/dictionaries/Format.service';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import useStyles from '../../Formats.styles';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => {},
};

function DeleteFormatConfirm(props: {
    handleClose?: EmptyVoidFunction;
    id: number;
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const { handleClose, id } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const deleteFormat = async () => {
    setIsLoaded(true);
    const response = await formatService.delete(id);
    if (response) {
      Toasters.success(l10n.components.tournamentFormat.FORMAT_DELETE_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };
  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.tournamentFormat.DELETE_FORMAT_CONFIRM}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          data-testid="cancelBtn"
        >
          {l10n.components.tournamentFormat.CANCEL_BUTTON}
        </Button>
        <Button
          onClick={deleteFormat}
          variant="contained"
          color="primary"
          autoFocus
          data-testid="deleteBtn"
        >
          {l10n.components.tournamentFormat.DELETE}
        </Button>
      </DialogActions>
    </>
  );
}

export default DeleteFormatConfirm;

DeleteFormatConfirm.defaultProps = defaultProps;