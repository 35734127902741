import { createStyles, makeStyles } from '@material-ui/core/styles';
import mainFoto from '../../assets/mainFoto.png';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      overflowX: 'visible',
    },
    '& p': {
      fontFamily: 'Source Sans Pro',
    }
  },
  mainFotoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${mainFoto}) center top/auto 100%, rgba(0, 0, 0, 0.49)`,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'no-repeat',
    maxWidth: 1920,
    width: '100%',
    backgroundSize: 'cover',
    height: 428,
    [theme.breakpoints.down('xs')]: {
      height: 353,
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 72,
    lineHeight: '91px',
    color: '#FFFFFF',
    margin: '0 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
      lineHeight: '65px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 44,
      lineHeight: '50px'
    },
  },
  text: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    margin: '0 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '28px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '24px'
    },
  },
  mainTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 1396,
    width: '100%',
    height: 147,
    [theme.breakpoints.down('sm')]: {
      height: 196,
    },
  },
  blockWrapper: {
    maxWidth: 1360,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& p:not(:first-child)': {
      marginTop: 8,
    },
    marginTop: 112,
    [theme.breakpoints.down('md')]: {
      marginTop: 48
    },
  },
  tournamentsCards: {
    maxWidth: 1360,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: 32,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  blockTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 48,
    lineHeight: '60px',
    color: '#141429',
    margin: '0 16px 48px 16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 44,
      lineHeight: '50px',
      textAlign: 'center'
    },
  },
  button: {
    marginTop: 56,
    [theme.breakpoints.down('md')]: {
      marginTop: 40,
    },
  },
  center: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  newsWrapper: {
    margin: '120px 16px 260px 16px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 190,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
}));

export default useStyles;