import {
  autorun,
  makeAutoObservable,
  runInAction
} from 'mobx';
import Location from '../../models/components/dictionaries/location/Location';
import LocationService from '../../services/main/dictionaries/Location.service';
import { EmptyVoidFunction } from '../../utils/types/Types';
import { defaultMapCoords as coordinate } from '../../utils/constants/Constants';
import LocationData from '../../models/components/dictionaries/location/LocationData';
import LocationTournaments from '../../models/components/dictionaries/location/LocationTournaments';

export default class LocationStore {
  id: number = 0;

  locations: Location[] | null = null;

  tournaments: LocationTournaments | null = null;

  init: EmptyVoidFunction;

  location: LocationData | null = null;

  isLoading: boolean = false;

  isAddModalOpen: boolean = false;

  isDeleteModalOpen: boolean = false;

  mainStore: any;

  coordinates: number[] | null = coordinate;

  areCoordinatesChanged: boolean = true;

  constructor(mainStore: any) {
    this.mainStore = mainStore.mainStore;

    makeAutoObservable(this);

    this.init = autorun(() => this.refreshLocations());

    this.init();
  }

  toggleLoaderState = () => {
    runInAction(() => {
      this.isLoading = !this.isLoading;
    });
  }

  toggleAddLocationModalState = () => {
    this.isAddModalOpen = !this.isAddModalOpen;
  }

  toggleDeleteLocationModalState = () => {
    this.isDeleteModalOpen = !this.isDeleteModalOpen;
  }

  getLocation = async (locationId: number) => {
    this.toggleLoaderState();
    const response = await LocationService.getById(locationId);
    if (response?.latitude && response?.longitude) {
      runInAction(() => {
        this.setCoordinates([response.latitude, response.longitude]);
        this.setCoordinatesState(false);
        this.setLocation(response);
      });
    }
    this.toggleLoaderState();
    return this.location;
  };

  getLocationTournaments = async (locationId: number) => {
    this.toggleLoaderState();
    const response = await LocationService.getLocationTournaments(locationId);
    if (response) {
      runInAction(() => {
        this.tournaments = response;
      });
    }
    this.toggleLoaderState();
    return this.tournaments;
  };

  refreshLocations: EmptyVoidFunction = async () => {
    this.toggleLoaderState();
    const response = await LocationService.get();
    if (response) {
      runInAction(() => {
        this.locations = response;
      });
    }
    this.toggleLoaderState();
  };

  deleteLocation = async (locationId: number) => {
    this.toggleLoaderState();
    const response = await LocationService.delete(locationId);
    this.toggleLoaderState();
    return response;
  };

  addLocation = async (data: Location) => {
    this.toggleLoaderState();
    const response = await LocationService.post(data);
    this.toggleLoaderState();
    return response;
  };

  editLocation = async (data: Location) => {
    if (this.location) {
      this.toggleLoaderState();
      const result = { ...data };
      result.id = this.location.id;
      const response = await LocationService.put(result);
      this.toggleLoaderState();
      return response;
    }
    return null;
  };

  setId = (locationId: number) => {
    this.id = locationId;
  }

  setLocation = (location: LocationData) => {
    this.location = location;
  }

  setCoordinates = (coordinates: number[] | null) => {
    this.coordinates = coordinates;
  }

  setCoordinatesState = (value: boolean) => {
    this.areCoordinatesChanged = value;
  }

  resetLocation = () => {
    this.location = null;
  }
}