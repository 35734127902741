import React from 'react';
import edit from '../../../../assets/icons/teamCards/edit.svg';
import TeamData from '../../../../models/pages/team/TeamData';
import useStyles from '../../TeamCards.styles';
import Team from '../../../../models/pages/team/Team';

function EditTeamButton(props: {
  setEditTeamData: React.Dispatch<React.SetStateAction<TeamData>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  team: Team
}) {
  const {
    team, setEditTeamData, setOpen
  } = props;
  const classes = useStyles();

  const editData = (currentTeam: Team) => {
    setOpen(true);
    setEditTeamData({
      id: currentTeam.id,
      name: currentTeam.name,
      countryId: currentTeam.country.id.toString(),
      pictureId: currentTeam.picture?.id.toString() || '',
      partnerId: currentTeam.partner.id,
      email: currentTeam.partner.email
    });
  };

  return (

    <button
      type="button"
      className={classes.roundedButton}
      onClick={() => editData(team)}
    >
      <img src={edit} alt="edit" />
    </button>
  );
}

export default EditTeamButton;