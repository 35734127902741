import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useTranslation from '../../../components/localization/customHooks/Translation';
import Tabs from '../../../components/tabs/Tabs';
import Articles from './article/Articles';
import AdminReports from './reports/AdminReports';
import useStyles from './article/Article.styles';

const AdminPublications = () => {
  const classes = useStyles();
  const l10n = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const adminTabs = [
    {
      name: l10n.components.articles.REPORTS,
      component: <AdminReports />,
      hash: '#Reports',
    },
    {
      name: l10n.components.articles.ADMIN_PUBLICATIONS,
      component: <Articles />,
      hash: '#Articles'
    },
  ];

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.articlesTabMenu}>
        <Tabs
          tabData={adminTabs}
          isCentered={false}
          DesktopLayoutStyle={classes.tabsContainer}
          isMobileLayout={isMobile}
        />
      </div>
    </div>
  );
};

export default AdminPublications;