import React, {
  Grid
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './ConfirmationDialog.style';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import ATButton from '../../button/Button';

const defaultProps = {
  onCancelClick: () => { },
  cancelButtonText: '',
  dialogClassName: '',
};

function ConfirmationDialog(props: {
  bodyText: string | JSX.Element;
  onConfirmClick: EmptyVoidFunction;
  confirmationButtonText: string;
  cancelButtonText?: string;
  dialogClassName?: string;
  onCancelClick?: EmptyVoidFunction;
}) {
  const {
    bodyText, onConfirmClick, confirmationButtonText, cancelButtonText, onCancelClick, dialogClassName
  } = props;
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.contentWrapper, dialogClassName)}>
      <div className={classes.contentTitle}>{bodyText}</div>
      <Grid container justify="flex-end" className={classes.contentButtons}>
        {cancelButtonText
          && (
            <ATButton
              className={classes.cancelButton}
              variant="secondary"
              fill="outline"
              size="md"
              onClick={onCancelClick}
            >
              {cancelButtonText}
            </ATButton>
          )}
        <ATButton
          variant="primary"
          fill="solid"
          size="md"
          onClick={onConfirmClick}
        >
          {confirmationButtonText}
        </ATButton>

      </Grid>
    </Grid>
  );
}

ConfirmationDialog.defaultProps = defaultProps;

export default ConfirmationDialog;