import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 263,
    minHeight: 155,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px 20px',
  },
  contentInput: {
    marginBottom: 20,
  },
  wrapperInput: {
    marginBottom: 20,
    marginTop: 20,
  },
  button: {
    margin: '0 auto'
  },
  field: {
    marginBottom: 20,
  },
  invalid: {
    color: 'red',
  },
}));

export default useStyles;