import React from 'react';
import useStyles from '../Playoff.styles';
import QualificationPlace from './QualificationPlace';
import TrophyPlace from './TrophyPlace';
import ModifiedPlayoffPlayer from '../../../../../../models/pages/tournament/playoff/ModifiedPlayoffPlayer';
import { stages } from '../../../../../../utils/enum/Enum';
import { getWinnerIcon } from '../Playoff.utils';
import QualificationCatches from './QualificationCatches';
import Angler from '../../../../../../components/angler/Angler';

const defaultProps = {
  stage: stages.quarterFinals,
  winnerId: null
};

function Participant(props: {
  player: ModifiedPlayoffPlayer | null
  winnerId?: number | null
  stage?: stages
}) {
  const classes = useStyles();
  const { player, stage, winnerId } = props;
  const isRoundForFirstPlace: boolean = stage === stages.firstPlace;

  return (
    <div className={classes.anglerContainerMobile}>
      {player && (
        <Angler
          anglerData={{ ...player, id: player?.anglerId }}
          isTeam={player.isTeam}
          avatarStyle="medium"
          className={classes.mobileCard}
          userNameStyle={classes.userNameContainer}
        />
      )}
      {stage === stages.quarterFinals
        && (
          <div className={classes.qualificationInfoWrapper}>
            <QualificationPlace place={player?.qualifyingPlace} />
            <QualificationCatches catches={player?.qualifyingCatchesSum} />
          </div>
        )}
      {stage === stages.thirdPlace && <TrophyPlace icon={getWinnerIcon(isRoundForFirstPlace, player?.id, winnerId)} />}
      {stage === stages.firstPlace && <TrophyPlace icon={getWinnerIcon(isRoundForFirstPlace, player?.id, winnerId)} />}
    </div>
  );
}

Participant.defaultProps = defaultProps;

export default Participant;