import Team from '../../models/pages/team/Team';

const transformTeams = (team: Team) => ({
  id: team.id,
  creationDate: team.creationDate,
  picture: team.picture,
  country: team.country,
  name: team.name,
  captain: {
    country: team.captain.country,
    id: team.captain.id,
    name: team.captain.name,
    email: team.captain.email,
    profilePicture: team.captain.profilePicture,
    isCaptain: true
  },
  partner: {
    country: team.partner.country,
    id: team.partner.id,
    name: team.partner.name,
    email: team.partner.email,
    profilePicture: team.partner.profilePicture,
    isCaptain: false
  },
  email: team.email,
  status: team.status,
  statusText: team.statusText
});

export default transformTeams;