import React, { useEffect, useState } from 'react';
import useStyles from '../team/Team.styles';
import DesktopMultipleSelect from './components/Desktop';
import MobileMultipleSelect from './components/Mobile';
import Loader from '../../loader/Loader';
import SelectItem from '../../../models/components/selects/SelectItem';
import FilterField from '../../../models/pages/availiableTournaments/FilterField';
import AppliedTournamentsFiltersModel from '../../../models/pages/availiableTournaments/AppliedTournamentsFiltersModel';

const defaultProps = {
  disabled: false,
  className: ''
};

function MultipleSelect(props: {
  filterProps: FilterField
  selectedFilters: AppliedTournamentsFiltersModel
  setSelectedFilters: React.Dispatch<React.SetStateAction<AppliedTournamentsFiltersModel>>
  className?: string
  disabled?: boolean
}) {
  const {
    disabled, setSelectedFilters, className, selectedFilters, filterProps
  } = props;

  const classes = useStyles({});

  const [menuItems, setMenuItems] = useState<SelectItem[] | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const fetchMenuItems = async () => {
    setIsLoaded(true);
    let response: SelectItem[] = filterProps.items || [];
    if (filterProps.getAvailableItems) {
      response = await filterProps.getAvailableItems() || [];
    }

    if (response) {
      setMenuItems(response);
    }
    setIsLoaded(false);
  };
  useEffect(() => {
    fetchMenuItems();
  }, []);

  if (isLoaded) {
    return <div className={classes.loader}><Loader /></div>;
  }
  return (
    <div className={className}>
      <DesktopMultipleSelect
        setSelectedFilters={setSelectedFilters}
        menuItems={menuItems}
        disabled={disabled}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
      />
      <MobileMultipleSelect
        setSelectedFilters={setSelectedFilters}
        menuItems={menuItems}
        disabled={disabled}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
      />
    </div>
  );
}

MultipleSelect.defaultProps = defaultProps;

export default MultipleSelect;