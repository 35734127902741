import AdminTournamentDetails from '../../../../models/pages/tournament/AdminTournamentDetails';

const modifyTournamentsData = (tournament: AdminTournamentDetails | null) => (tournament === null || Array.isArray(tournament) ? null : {
  ...tournament,
  ratingsList: tournament.ratings.map((rating) => rating.name),
  ranking: tournament?.ranking!,
  season: tournament?.season?.name!,
  format: tournament.format.name,
  status: tournament.statusText,
  statusId: tournament.status,
  locationName: tournament?.location?.name,
});

export default modifyTournamentsData;