import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  avatarGroup: {
    marginRight: 8
  },
  avatar: {
    boxSizing: 'border-box',
    width: 33,
    height: 33,
    border: '1px solid #fff'
  },
  ratio: {
    fontFamily: "'Source Sans Pro'",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  count: {
    color: '#0033B7'
  },
  limit: {
    color: '#d79c65',
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100vh',
        margin: 0,
        height: '100vh',
        maxHeight: '100vh'
      },
    },

  }
}));

export default useStyles;