import { makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: `1px solid ${colors.primary}`,
    marginRight: 15,
    '& button:last-child': {
      borderBottom: 'none',
    }
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderBottom: `1px solid ${colors.primary}`,
    background: 'inherit',
    textAlign: 'left',
    outline: 'none',
    padding: '5px 10px',
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    '&>.MuiSvgIcon-root': {
      fill: colors.primary
    }
  },
  backBtn: {
    width: 51,
    height: 51,
    borderRadius: '50%',
    border: `1px solid ${colors.primary}`,
    background: 'inherit',
    outline: 'none',
    '&>.MuiSvgIcon-root': {
      marginTop: 3
    }
  },
  tabNavigation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  tabsTitle: {
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    fontSize: 26,
    fontWeight: 700
  },
  componentContainer: {
    overflowY: 'auto'
  }
}));

export default useStyles;