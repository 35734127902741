import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ATButton from '../../../../components/button/Button';
import ReportCard from './components/ReportCard/ReportCard';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import ReportControl from '../../../../components/controlReport/ReportControl';
import DeleteReportModal from './components/DeleteReportModal/DeleteReportModal';
import AreatroutModal from '../../../../components/modal/Modal';
import { useStores } from '../../../../stores/Main';
import Report from '../../../../models/components/report/Report';
import useStyles from './TournamentReports.styles';
import { StorageService } from '../../../../utils/Utils';

const TournamentReports = observer((props: {anglerId: string}) => {
  const { anglerId } = props;
  const l10n = useTranslation();
  const classes = useStyles();
  const currentUser = StorageService.getUserData();
  const [isAddModalOpened, setIsAddModalOpened] = useState<boolean>(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);

  const { ReportsStore } = useStores();
  const getReports = async () => {
    await ReportsStore.getReportsByUserId(anglerId);
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <div className="mainWrapper">
      <div className={classes.button}>
        {currentUser && (
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            onClick={() => { setIsAddModalOpened(true); }}
          >
            {l10n.components.tournamentReports.WRITE_REPORT}
          </ATButton>
        )}
      </div>
      <div>
        {ReportsStore.reports && !!ReportsStore.reports.length
          && ReportsStore.reports
            .map((el: Report) => (
              <ReportCard
                key={el.id}
                anglerId={anglerId}
                report={el}
                setIsEditModalOpened={setIsEditModalOpened}
                setIsDeleteModalOpened={setIsDeleteModalOpened}
              />
            ))}
      </div>
      <AreatroutModal
        modalClassName={classes.reportModal}
        header={l10n.components.tournamentReports.WRITE_REPORT}
        open={isAddModalOpened}
        setOpen={setIsAddModalOpened}
        onClose={() => { ReportsStore.getReportsByUserId(anglerId); }}
      >
        <ReportControl isAddModalOpened={isAddModalOpened} isEditModalOpened={isEditModalOpened} />
      </AreatroutModal>
      <AreatroutModal
        modalClassName={classes.reportModal}
        header={l10n.components.tournamentReports.EDIT_REPORT}
        open={isEditModalOpened}
        setOpen={setIsEditModalOpened}
        onClose={() => { ReportsStore.getReportsByUserId(anglerId); }}
      >
        <ReportControl isAddModalOpened={isAddModalOpened} isEditModalOpened={isEditModalOpened} />
      </AreatroutModal>
      <AreatroutModal
        header={l10n.components.tournamentReports.DELETE_REPORT}
        open={isDeleteModalOpened}
        setOpen={setIsDeleteModalOpened}
        onClose={() => { ReportsStore.getReportsByUserId(anglerId); }}
      >
        <DeleteReportModal />
      </AreatroutModal>
    </div>
  );
});

export default TournamentReports;