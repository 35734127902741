import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '38px',
    textAlign: 'center',
    color: '#141429',
    marginTop: 16
  },
  text: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '24px',
    color: '#141429',
    marginTop: 16
  },
  button: {
    marginTop: 32
  },
  checkmarkBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 48,
    height: 48,
    width: 48,
    margin: '0 auto',
    background: 'rgba(13, 169, 179, 0.2)',
  },
  checkmarkSuccess: {
    color: '#0DA9B3',
    fontSize: 24,
    lineHeight: '24px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
}));

export default useStyles;