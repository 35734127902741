import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableRow, OutlinedInput
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './CardTemplate.styles';
import TableHeader from './header/TableHeader';
import Round from '../../../models/pages/tournament/Round';
import TournamentService from '../../../services/main/Tournament.service';
import Participant from '../../../models/pages/tournament/Participant';
import PairsCaptures from '../../../models/pages/tournament/captures/PairsCaptures';
import IncorrectCatchesRound from '../../../models/pages/tournament/captures/IncorrectCatchesRound';
import useTranslation from '../../localization/customHooks/Translation';
import { onInputHandleChange } from '../../../utils/Utils';

function CardTemplate(props: {
  participant: Participant,
  updateOpponent: Function,
  isCardsCompleted: Function;
}) {
  const { participant, updateOpponent, isCardsCompleted } = props;
  const roundsData = participant.rounds;
  const classes = useStyles();
  const l10n = useTranslation();
  const [rounds, setRounds] = useState<Round[] | undefined>();

  const postPairsCapturesData = async (round: Round) => {
    if ((round.anglerCatches !== null && round.opponentCatches !== null)
      || (round.anglerCatches === null && round.opponentCatches === null)) {
      const pairsCapturesData: PairsCaptures = {
        tournamentId: participant.tournamentId,
        anglerId: participant.angler.id,
        opponentId: round.opponentId,
        anglerCatches: round.anglerCatches,
        opponentCatches: round.opponentCatches,
      };
      const anglerScore = await TournamentService.postPairsCaptures(pairsCapturesData);

      if (anglerScore) {
        round.anglerScore = anglerScore.score;
        round.hasError = anglerScore.error;

        const incorrectRound: IncorrectCatchesRound = {
          hasError: round.hasError,
          opponentId: round.opponentId,
          roundNumber: round.roundNumber,
          anglerId: participant.anglerId,
        };

        updateOpponent(incorrectRound);
        isCardsCompleted(participant.anglerId);
      }
    }
  };

  const onBlurHandleChange = async (round: Round, data: HTMLInputElement) => {
    const { value, name } = data;
    const roundData = rounds?.find((el: Round) => el.id === round.id);

    if (roundData) {
      roundData[name] = value === '' ? null : Number(value);
      await postPairsCapturesData(roundData);
    }
  };

  useEffect(() => {
    setRounds(roundsData);
  }, []);

  return (
    <TableContainer>
      <Table aria-label="customized table" className={classes.cardTemplate}>
        <TableHeader />
        <TableBody>
          {rounds && rounds.length
            ? rounds.map((round: Round) => (
              <TableRow
                key={round.id}
                className={clsx([classes.tableRow], { [classes.tableRowError]: round.hasError })}
              >
                <TableCell align="center">{round.roundNumber}</TableCell>
                <TableCell align="center">{round.sectorNumber}</TableCell>
                <TableCell align="center">
                  <OutlinedInput
                    defaultValue={round.anglerCatches !== null ? round.anglerCatches : ''}
                    className={classes.input}
                    type="number"
                    name="anglerCatches"
                    inputComponent="input"
                    inputProps={{
                      min: '0',
                    }}
                    onInput={(event: ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
                    onBlur={(event: any) => onBlurHandleChange(round, event.target)}
                  />
                </TableCell>
                <TableCell align="center">
                  <OutlinedInput
                    defaultValue={round.opponentCatches !== null ? round.opponentCatches : ''}
                    className={classes.input}
                    type="number"
                    name="opponentCatches"
                    inputComponent="input"
                    inputProps={{
                      min: '0',
                    }}
                    onInput={(event: ChangeEvent<HTMLInputElement>) => onInputHandleChange(event)}
                    onBlur={(event: any) => onBlurHandleChange(round, event.target)}
                  />
                </TableCell>
                <TableCell align="center" defaultValue={round.anglerScore !== null ? round.anglerScore : ''}>
                  {round.anglerScore}
                </TableCell>
                <TableCell align="center">{round.opponentNum}</TableCell>
                <TableCell className={classes.fullName}>
                  <a href={`#${round.opponentId}`} tabIndex={-1}>{round.opponent.fullName}</a>
                </TableCell>
              </TableRow>
            ))
            : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {l10n.components.tableTemplates.NO_DATA_AVAILABLE}
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CardTemplate;