import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const flexMobile = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  flexDirection: 'column',
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
    padding: '0 16px'
  },
  search: {
    marginBottom: 40
  },
  title: {
    color: colors.secondary,
    fontSize: 48,
    lineHeight: '60px',
    fontWeight: 'bold',
    margin: '10px 0 32px 0',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 44,
      lineHeight: '50px',
      marginTop: 20
    },
  },
  fullName: {
    width: 250,
    textAlign: 'start'
  },
  text: {
    marginLeft: 8
  },
  boldText: {
    fontWeight: 'bold'
  },
  table: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone': {
        display: 'none'
      }
    },
    '& .MuiTableCell-root': {
      padding: 0,
      textIndent: 0,
    },
    '& .MuiTableHead-root': {
      '& th': {
        textIndent: 0,
      },
      '& .tableHead': {
        '& th': {
          textIndent: 0,
          '&:first-child,&:last-child': {
            borderRadius: 0,
          },

        },
      }
    }
  },
  selectContainer: {
    display: 'flex',
    gap: 15,
    flexWrap: 'wrap',
    marginBottom: 12,
    '& div': {
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      ...flexMobile,
    },
  },
  select: {
    display: 'inline-flex',
    margin: 12,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 0,
    },
  },
  filled: {
    backgroundColor: colors.lightGrey
  },
  cell: {
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableBorder: {
    borderLeft: `1px solid ${colors.greyBorder}`,
  },
  breadcrumbs: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  wrapper: {
    color: colors.secondary,
    padding: '12px 36px 36px',
    lineHeight: '24px',
  },
  descriptionTitle: {
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    marginBottom: 20
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '35%',
      minHeight: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%'
    }
  },
  descriptionText: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: colors.primary,
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    padding: 0,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      margin: '5px 0px',
      display: 'block',
      marginLeft: 'auto'
    },
  },
  angler: {
    '& > div:last-child': {
      margin: '0px 8px 0px 6px'
    },
  }
}));

export default useStyles;