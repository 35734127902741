import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  partnerData: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

export default useStyles;