import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  contentInput: {
    marginBottom: 20,
    marginRight: 10,
  },
  contentWidth: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
  },
  direction: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '10px 10px 5px 10px',
  },
  contentMap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    cursor: 'pointer'
  },
  previewImage: {
    position: 'relative',
    display: 'inline-block'
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    opacity: 0.5,
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
      transform: 'scale(0.75, 0.75)',
    },
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '70vh',
  },
  countrySelect: {
    margin: '0 10px 20px 0'
  },
  description: {
    boxSizing: 'border-box',
    paddingRight: 10,
  },
  container: {
    maxWidth: 1360,
    display: 'block',
    margin: 'auto'
  },
  imagesContainer: {
    maxWidth: 1360,
    gap: 10,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    margin: '0px 18px'
  },
  buttonsContainer: {
    margin: '0px 10px'
  },
  logoPicture: {
    marginTop: 15,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

export default useStyles;