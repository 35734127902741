import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import useStyles from './Location.styles';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { useStores } from '../../stores/Main';
import LocationPreviewCard from './components/previewCard/PreviewCard';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import useTranslation from '../../components/localization/customHooks/Translation';
import Location from '../../models/components/dictionaries/location/Location';
import LocationsMap from './components/map/Map';

const Locations = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const urlName = [
    { displayName: l10n.pages.MAIN_PAGE, url: '/' },
    { displayName: l10n.components.locationCard.LOCATIONS_PAGE, url: '/locations' },
  ];
  const { LocationStore } = useStores();

  return (
    <div className={classes.mainContainer}>
      <Breadcrumbs urls={urlName} className={classes.breadcrumbs} />
      <h2 className={classes.heading}>{l10n.components.locationCard.LOCATIONS_PAGE}</h2>
      <Grid spacing={2} container className={classes.container}>
        <>
          {LocationStore.locations?.length ? (
            LocationStore.locations.map((el: Location) => (
              <Grid item key={el.id} lg={4} md={6} xs={11}>
                <LocationPreviewCard
                  locationId={el.id}
                  description={el.description}
                  name={el.name}
                  image={el.logo}
                />
              </Grid>
            ))) : (
              <LoaderFullScreen />
          )}
        </>
        <LocationsMap />
      </Grid>

    </div>
  );
});

export default Locations;