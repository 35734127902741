import React from 'react';
import clsx from 'clsx';
import useStyles from './Sponsors.style';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import { getImgUrlsList } from '../../../../../utils/Utils';
import CarouselImage from '../../../../../components/carouselImage/CarouselImage';

const TournamentCardSponsors = (props: { selectedTournamentSponsors: any, selectedMainSponsor: any }) => {
  const { selectedTournamentSponsors, selectedMainSponsor } = props;

  const classes = useStyles();
  const l10n = useTranslation();

  const srcSponsor = (source: any) => `${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${source?.logo!.thumbnailImageUrl}`;

  const listOfTournamentSponsors = selectedTournamentSponsors.map((el: any) => el.logo);

  return (
    <div>
      <div className={classes.wrapperSelectedSponsors}>
        {selectedMainSponsor && (
          <div className={clsx(classes.wrapper, classes.generalWrapper)}>
            <h3>{l10n.components.sponsors.GENERAL_SPONSOR}</h3>
            {selectedMainSponsor
            && <img className={classes.sponsorImg} alt={l10n.components.sponsors.GENERAL_SPONSOR} src={srcSponsor(selectedMainSponsor)} />}
          </div>
        )}
        {!!selectedTournamentSponsors.length && (
          <div className={classes.wrapper}>
            <h3>{l10n.components.sponsors.SPONSORS}</h3>
            <CarouselImage
              urlList={getImgUrlsList(listOfTournamentSponsors)}
              imgStyles={classes.sponsorImg}
              showThumbs
              infiniteLoop={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TournamentCardSponsors;