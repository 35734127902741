import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Instagram, Facebook } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import { runInAction } from 'mobx';
import contentComponents from '../../components/authorization/helpers/Constants';
import { getImageUrl, StorageService, toLocalDate } from '../../utils/Utils';
import UserCardTabMenu from './components/tabMenu/UserCardTabMenu';
import noAvatar from '../../assets/icons/no-avatar.svg';
import useStyles from './UserCard.styles';
import UserInfo from '../../models/pages/user/UserInfo';
import UserParticipationHistory from '../../models/pages/tournament/UserParticipationHistory';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import UserService from '../../services/main/User.service';
import SocialMediaButton from '../../components/button/socialMedia/SocialMedia';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import MetaDescription from '../../components/metaDescription/MetaDescription';
import useTranslation from '../../components/localization/customHooks/Translation';
import AuthorizationModal from '../../components/authorization/modalWindow/ModalWindow';
import { useStores } from '../../stores/Main';

function UserCard() {
  const classes = useStyles();
  const l10n = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const url = useParams<{ id: string }>();

  const getAgeEndingWord = (age: number) => {
    const ageArray = [
      l10n.components.yearType.YEARS,
      l10n.components.yearType.YEAR,
      l10n.components.yearType.OF_THE_YEAR,
      l10n.components.yearType.OF_THE_YEAR,
      l10n.components.yearType.OF_THE_YEAR,
      l10n.components.yearType.YEARS,
      l10n.components.yearType.YEARS,
      l10n.components.yearType.YEARS,
      l10n.components.yearType.YEARS,
      l10n.components.yearType.YEARS
    ];
    if (age <= 10) {
      return ageArray[age];
    } if (age > 10 && age <= 20) {
      return l10n.components.yearType.YEARS;
    } if (age > 20) {
      return ageArray[age % 10];
    }
    return '';
  };
  const { id } = url;

  const [user, setUser] = useState<UserInfo | null>(null);
  const [participation, setParticipation] = useState<UserParticipationHistory[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const { UserStore } = useStores();
  runInAction(() => UserStore.setId(id));

  const getAge = (date: string) => `${toLocalDate(date)}
  (${moment().diff(date, 'years', false)} ${getAgeEndingWord(moment().diff(date, 'years', false))})`;

  const getLivingPlace = (participant: UserInfo) => (participant.city
    ? `${participant.city}, ${participant.country.name}`
    : `${participant.country.name}`);

  const urlName = [
    { displayName: l10n.pages.MAIN_PAGE, url: '/' },
    { displayName: l10n.pages.SPORTSMAN_RATINGS, url: '/rating' },
    { displayName: `${user?.fullName}`, url: `/users/${id}` },
  ];

  const getUser = async (userId: string) => {
    setIsLoading(true);
    const response = await UserService.getUser(userId);
    if (response) {
      setUser(response);
    }
    setIsLoading(false);
  };

  const checkIsItCurrentUser = () => user?.fullName === StorageService.getUserData()?.name;

  const openEditModal = () => {
    setIsModalOpened(true);
  };

  const getUserParticipationInfo = async (userId: string) => {
    const response = await UserService.getUserParticipationInfo(userId);
    if (response) {
      setParticipation(response);
    }
  };

  useEffect(() => {
    getUserParticipationInfo(id);
    getUser(id);
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      {user
        && (
          <>
            <MetaDescription title={user.fullName} />
            <div className={classes.mainContainer}>
              <Breadcrumbs urls={urlName} className={classes.breadcrumbs} />
              <div className={classes.participantCard}>
                <div className={classes.mobileContainer}>
                  {user.profilePicture
                    ? <img src={getImageUrl(user.profilePicture?.url)} alt={l10n.pages.USERS_IMAGE} className={classes.img} />
                    : <img src={noAvatar} alt={l10n.pages.USERS_IMAGE} className={classes.img} />}
                  {isMobile && checkIsItCurrentUser() && <EditIcon className={classes.mobileEditButton} onClick={openEditModal} />}
                </div>
                <div className={classes.participantContainer}>
                  <div className={classes.desktopContainer}>
                    <p className={classes.title}>
                      {user.fullName}
                    </p>
                    {!isMobile && checkIsItCurrentUser() && <EditIcon className={classes.editButtonDesktop} onClick={openEditModal} />}
                  </div>
                  <div className={classes.participantData}>
                    {user.birthday && <p className={classes.basicText}>{getAge(user.birthday)}</p>}
                    <p className={classes.basicText}>{getLivingPlace(user)}</p>
                  </div>
                  <div className={classes.buttonContainer}>
                    {user.instagramLink && (
                      <SocialMediaButton link={user.instagramLink}>
                        <Instagram fontSize="large" />
                      </SocialMediaButton>
                    )}
                    {user.facebookLink && (
                      <SocialMediaButton link={user.facebookLink}>
                        <Facebook fontSize="large" />
                      </SocialMediaButton>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {participation && <UserCardTabMenu data={participation} id={id} isMobile={isMobile} />}
              </div>
            </div>
          </>
        )}
      {user
        && (
          <AuthorizationModal
            open={isModalOpened}
            setOpen={setIsModalOpened}
            component={contentComponents.EditProfile}
          />
        )}
    </>
  );
}

export default UserCard;