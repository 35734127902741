import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import useStyles from './Sponsors.style';
import SponsorsTable from './table/SponsorsTable';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import Modal from '../../../../../components/modal/Modal';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import AddOrEditModal from './modals/AddOrEditSponsorsModal';
import DeleteSponsorModal from './modals/DeleteSponsorModal';

const Sponsors = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { SponsorsStore } = useStores();

  const getAllSponsors = async () => {
    await SponsorsStore.refreshSponsors();
  };

  useEffect(() => {
    getAllSponsors();
  }, []);

  return (
    <div className={classes.tableWrapper}>
      <Button
        data-testid="add-button"
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddCircleOutline />}
        onClick={() => setIsAddModalOpen(true)}
      >
        {l10n.shared.ADD}
      </Button>
      <Modal
        header={l10n.components.sponsors.SPONSOR_MODAL_ADD}
        open={isAddModalOpen}
        setOpen={setIsAddModalOpen}
        onClose={() => getAllSponsors()}
      >
        <AddOrEditModal />
      </Modal>
      <Modal
        header={l10n.components.sponsors.SPONSOR_MODAL_EDIT}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onClose={() => getAllSponsors()}
      >
        <AddOrEditModal isEditModalOpen={isEditModalOpen} />
      </Modal>
      <Modal
        header={l10n.components.sponsors.SPONSOR_MODAL_DELETE}
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onClose={() => getAllSponsors()}
      >
        <DeleteSponsorModal />
      </Modal>
      {SponsorsStore.sponsors ? (
        <SponsorsTable sponsorsList={SponsorsStore.sponsors} setIsEditModalOpen={setIsEditModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} />
      ) : (
        <LoaderFullScreen />
      )}
    </div>
  );
});

export default Sponsors;