import AnglerRatingResult from '../../models/pages/anglerRating/AnglerRatingResult';
import AnglerTournamentResults from '../../models/pages/anglerRating/AnglerTournamentResults';

const anglersSortByRatingPoints = (anglersRatings: AnglerRatingResult[]) => (
  anglersRatings.sort((prev, next) => (next.finalRatingPoints - prev.finalRatingPoints || prev.sumPlaces - next.sumPlaces)));

const addAnglerPlace = (anglersRatings: AnglerRatingResult[]) => {
  anglersRatings.forEach((angler, index: number) => { angler.place = index + 1; });
  return anglersRatings;
};

const getSumPlaces = (anglersRatings: AnglerRatingResult[]) => {
  anglersRatings.forEach((angler) => {
    const sum = angler.tournamentResults.reduce((acc: number, result: AnglerTournamentResults) => acc + result.place, 0);
    angler.sumPlaces = sum;
  });

  return anglersRatings;
};

const mapToRatingsTableView = (anglersRatings: AnglerRatingResult[]) => (
  addAnglerPlace(anglersSortByRatingPoints(getSumPlaces(anglersRatings))));

export const getBestTournaments = (anglersRatings: AnglerRatingResult[], bestTournamentsCount: number | null) => {
  if (bestTournamentsCount) {
    anglersRatings.map((rating) => [...rating.tournamentResults]
      .sort((a, b) => b.points - a.points)
      .slice(0, bestTournamentsCount)
      .forEach((tournament) => {
        tournament.hasBestResult = true;
      }));
  }
  return mapToRatingsTableView(anglersRatings);
};

export default mapToRatingsTableView;