import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ImageData from '../../../../../../../models/components/image/ImageData';
import useStyles from './SponsorPicture.styles';
import { getImageUrl } from '../../../../../../../utils/Utils';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const SponsorPicture = (props: {sponsorPictureId: ImageData, setSponsorImage: React.Dispatch<React.SetStateAction<ImageData | null>>}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { sponsorPictureId, setSponsorImage } = props;
  return (
    <div className={classes.sponsorContainerOuter}>
      <div className={classes.sponsorContainerInner}>
        <img
          src={getImageUrl(sponsorPictureId.url)}
          alt={l10n.pages.PREVIEW}
          className={classes.imageWrapper}
        />
        <IconButton
          className={classes.closeButton}
          onClick={() => setSponsorImage(null)}
          size="small"
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

export default SponsorPicture;