import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from '../ActivationErrors.styles';
import ATButton from '../../../button/Button';
import checkMarkFailed from '../../../../assets/icons/checkMarkFailed.svg';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const ActivationFailed = (props: {
  handleClose?: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { handleClose } = props;

  const closeModal = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.wrapper}>
      <div className={classes.checkMark}>
        <img src={checkMarkFailed} alt="check-mark-failed" />
      </div>
      <Typography className={classes.title}>
        {l10n.components.authorization.TITLE_FAILED}
      </Typography>
      <Typography className={classes.description}>
        {l10n.components.authorization.DESCRIPTION_FAILED}
      </Typography>
      <ATButton
        variant="primary"
        fill="solid"
        size="lg"
        onClick={closeModal}
      >
        {l10n.components.authorization.BUTTON_DESCRIPTION_FAILED}
      </ATButton>
    </Grid>
  );
};

export default ActivationFailed;

ActivationFailed.defaultProps = defaultProps;