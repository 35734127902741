import React from 'react';
import { AnglerData } from '../../models/pages/tournament/playoff/publication/PublishedTournament';
import ParticipantsTooltip from '../tooltip/participant/Participants';
import AnglerLink from './anglerLink/AnglerLink';

const defaultProps = {
  avatarStyle: '',
  className: '',
  userNameStyle: '',
  children: null,
  isTeam: false
};

const Angler = (props: {
  anglerData: AnglerData;
  isTeam?: boolean
  avatarStyle?: string
  className?: string
  userNameStyle?: string
  children?: JSX.Element | JSX.Element[] | null,
}) => {
  const {
    anglerData,
    avatarStyle,
    className,
    userNameStyle,
    children,
    isTeam
  } = props;

  return (
    <>
      {isTeam
        ? (
          <ParticipantsTooltip teamId={anglerData.id}>
            <AnglerLink
              anglerData={anglerData}
              className={className}
              avatarStyle={avatarStyle}
              userNameStyle={userNameStyle}
              isTeam
            >
              {children}
            </AnglerLink>
          </ParticipantsTooltip>
        )
        : (
          <AnglerLink
            anglerData={anglerData}
            className={className}
            avatarStyle={avatarStyle}
            userNameStyle={userNameStyle}
          >
            {children}
          </AnglerLink>
        )}
    </>
  );
};

Angler.defaultProps = defaultProps;

export default React.memo(Angler);