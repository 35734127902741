import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import ParticipantsCardTemplate from '../../../../../components/participantsCardTemplate/ParticipantsCardTemplate';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import {
  setOpponentErrorRound, sortByNumberField, sortByStringField, transformParticipantData, isCardCompleted, getRound, updateHighlightForParticipant
} from './ParticipantsCards.utils';
import ParticipantsList from '../../../../../components/participantsList/ParticipantsList';
import TournamentService from '../../../../../services/main/Tournament.service';
import Header from '../components/Header';
import Participant from '../../../../../models/pages/tournament/Participant';
import DownloadPdf from './downloadPdf/DownloadPdf';
import useStyles from './ParticipantsCards.styles';
import ErrorRound from '../../../../../models/pages/tournament/captures/ErrorRound';
import useConfirmationDialog from '../../../../../components/useConfirmationDialog/UseConfirmationDialog';
import Toasters from '../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const ParticipantsCards = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [errorCards, setErrorCards] = useState<ErrorRound[] | undefined>();
  const [isShowingErrorCards, setIsShowingErrorCards] = useState(false);
  const [allCardsCompleted, setAreAllCardsCompleted] = useState(false);
  const [participantsIds, setParticipantsIds] = useState<number[]>([]);
  const [dataSort, setDataSort] = useState<{ columnId: number, direction: string }>({
    columnId: 0,
    direction: 'asc'
  });

  const { TournamentStore } = useStores();
  const { tournament } = TournamentStore;

  const checkAllCardsCompleted = () => {
    setAreAllCardsCompleted(participants.every((participant) => participant.isCompleted));
  };

  const isCardsCompleted = (anglerId: string) => {
    const newParticipants = [...participants];
    const opponentCard = newParticipants.find((participant) => participant.anglerId === anglerId);

    if (opponentCard) {
      opponentCard.isCompleted = isCardCompleted(opponentCard?.rounds);
      setParticipants(newParticipants);
    }
  };

  const onFilterChanged = (tableData: Participant[]) => {
    setParticipantsIds(tableData.map((participant) => participant.id));
  };

  const getParticipants = async (participantId: number) => {
    const response = await TournamentService.getTableData(participantId);

    if (response) {
      const transformParticipants = sortByNumberField(transformParticipantData(response));

      setParticipants(transformParticipants);
      onFilterChanged(transformParticipants);
    }
  };
  const toggleSortOrder = (columnId: number, direction: string) => {
    setDataSort({ columnId, direction });
  };

  const findErrorCards = () => {
    const cards = participants.filter((participant) => (
      participant.rounds.some((round) => round.hasError)
    ));

    setFilteredParticipants(cards);
  };

  const showAllCards = () => {
    setIsShowingErrorCards(!isShowingErrorCards);
  };

  const findErrorRounds = () => {
    errorCards?.forEach((error) => {
      const errorRound = getRound(filteredParticipants, error.userId, error.roundNumber);

      if (errorRound) {
        errorRound.hasError = true;
        setOpponentErrorRound(filteredParticipants, errorRound);
      }
    });
    participants.forEach((participant: Participant) => {
      updateHighlightForParticipant(participant);
    });
  };

  const getErrorRounds = async (tournamentId: number) => {
    setIsLoading(true);

    const response = await TournamentService.getErrorRounds(tournamentId);

    if (response) {
      setErrorCards(response);
    }

    setIsLoading(false);
  };

  const fetchData = async (participantId: number) => {
    setIsLoading(true);
    await getParticipants(participantId);
    await getErrorRounds(participantId);
    setIsLoading(false);
  };

  const getErrorsCount = () => {
    const errorsCount = participants.reduce((acc: number, participant: Participant) => (
      acc + participant.rounds.filter((round) => round.hasError).length), 0);

    setErrorCount(errorsCount / 2);
    if (!errorsCount && isShowingErrorCards) {
      showAllCards();
    }
  };

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: l10n.components.headerLabel.COMPUTE_QUALIFIERS,
    bodyText: l10n.components.confirmDialogsMessage.RECOMPUTE_QUALIFIERS,
    confirmationButtonText: l10n.components.button.CONFIRM,
    cancelButtonText: l10n.components.button.CANCEL,
    onCancelClick: () => {
      onClose();
    },
    onConfirmClick: async () => {
      const res = await TournamentService.calculateQualificationResults(id);

      onClose();
      if (res) {
        TournamentStore.getTournament(id);
        Toasters.success(l10n.components.toasterMessage.QUALIFIERS_HAVE_BEEN_FORMED);
      }
    },
  });

  const calculateQualificationResults = async () => {
    if (allCardsCompleted && !errorCount) {
      if (TournamentStore.areQualifiersCompleted) {
        onOpen();
      } else {
        const res = await TournamentService.calculateQualificationResults(id);
        if (res) {
          TournamentStore.getTournament(id);
          Toasters.success(l10n.components.toasterMessage.QUALIFIERS_HAVE_BEEN_FORMED);
          TournamentStore.setAreQualifiersCompleted(true);
        }
      }
    } else {
      Toasters.error(l10n.components.toasterMessage.COMPUTE_QUALIFIERS_ERROR);
    }
  };

  const renderButton = () => {
    let resolveButton: {
      text: string, action: () => void | Promise<void>
    } = {
      text: l10n.components.button.COMPUTE_QUALIFIERS,
      action: () => calculateQualificationResults()
    };

    if (isShowingErrorCards) {
      resolveButton = {
        text: l10n.components.button.SHOW_ALL,
        action: () => {
          showAllCards();
        }
      };
    } else if (errorCount) {
      resolveButton = {
        text: l10n.components.button.SHOW_ERRORS(errorCount),
        action: () => {
          findErrorCards();
          setIsShowingErrorCards(!isShowingErrorCards);
        }
      };
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={resolveButton.action}
      >
        {resolveButton.text}
      </Button>
    );
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  useEffect(() => {
    findErrorRounds();
    getErrorsCount();
  }, [errorCards]);

  useEffect(() => {
    checkAllCardsCompleted();
  }, [participants]);

  useEffect(() => {
    if (isShowingErrorCards) {
      findErrorCards();
    } else {
      const filterParticipants = participants.filter((participant: Participant) => participantsIds.includes(participant.id));
      const sortFunction = dataSort.columnId === 0 ? sortByNumberField : sortByStringField;

      setFilteredParticipants(sortFunction(filterParticipants, dataSort.direction));
    }
  }, [participants, isShowingErrorCards, participantsIds, dataSort]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <>
      <Header className={classes.header} text={`${l10n.components.headerLabel.FILLING_PARTICIPANTS_CARDS} "${tournament && tournament.name}"`} />
      <Box className={classes.cardsContainer}>
        <ParticipantsList participants={participants} onFilterChanged={onFilterChanged} toggleSortOrder={toggleSortOrder} />
        <Box justifyContent="space-between" flexWrap="wrap" className={classes.cardBorder}>
          <Box className={classes.root}>
            {tournament?.name && tournament?.date && tournament?.template?.roundsCount && (
              <DownloadPdf
                participants={participants}
                tournamentName={tournament?.name}
                tournamentDate={tournament?.date}
                id={tournament?.id}
                roundsQuantity={tournament?.template?.roundsCount}
              />
            )}
            <Box className={classes.errorButton}>
              {renderButton()}
            </Box>
          </Box>
          <ParticipantsCardTemplate
            filteredParticipantsData={filteredParticipants}
            participantsData={participants}
            isCardsCompleted={isCardsCompleted}
            getErrorsCount={getErrorsCount}
          />
        </Box>
        <Dialog />
      </Box>
    </>
  );
});

export default ParticipantsCards;