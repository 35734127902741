import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AreatroutTable from '../../../components/table';
import AdminTournamentGridItem from '../../../models/pages/tournament/AdminTournamentGridItem';
import ParticipantRatio from '../../../components/tableTemplates/components/ParticipantsRatio';
import TournamentService from '../../../services/main/Tournament.service';
import ManagementCell from './cells/Management';
import AddTournament from './add/Add';
import LoaderFullScreen from '../../../components/loader/fullScreen/FullScreen';
import { sortingType } from '../../../utils/enum/Enum';
import { toDate, StorageService } from '../../../utils/Utils';
import DateCell from '../../../components/tableTemplates/components/cell/data/Date';
import { adminRole } from '../../../utils/constants/Constants';
import useStyles from './Tournaments.styles';
import useTranslation from '../../../components/localization/customHooks/Translation';
import Routes from '../../../routing/Routes';

const Tournaments = () => {
  const [tournaments, setTournaments] = useState<AdminTournamentGridItem[] | null>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowedCreatorFullName, setIsAllowedCreatorFullName] = useState(false);
  const classes = useStyles();
  const l10n = useTranslation();

  const getAllTournaments = async () => {
    setIsLoading(true);
    const res = await TournamentService.get();

    if (res) {
      res.map((tournament: AdminTournamentGridItem) => {
        tournament.transformCreationDate = toDate(tournament.creationDate, 'X');
        tournament.transformDate = toDate(tournament.date, 'X');
        return tournament;
      });
      setTournaments(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTournaments();
    setIsAllowedCreatorFullName(StorageService.getUserData()?.roles.some((role: string) => role === adminRole));
  }, []);

  const columns = [
    {
      displayName: l10n.components.tableColumns.NAME,
      fieldName: 'name',
      customSort: (a: any, b: any) => a.name.localeCompare(b.name),
      render: ({ name, id }: AdminTournamentGridItem) => <Link className={classes.link} to={Routes.TournamentCard.path.replace(':id', String(id))}>{name}</Link>
    },
    {
      displayName: l10n.components.tableColumns.CREATION_DATE,
      fieldName: 'transformCreationDate',
      render: ({ creationDate }: AdminTournamentGridItem) => <DateCell value={creationDate} />,
    },
    {
      displayName: l10n.components.tableColumns.CARRY_OUT_DATE,
      fieldName: 'transformDate',
      defaultSort: sortingType.desc,
      render: ({ date }: AdminTournamentGridItem) => <DateCell value={date} />,
    },
    {
      displayName: l10n.components.tableColumns.COUNTRY,
      fieldName: 'country',
    },
    {
      displayName: l10n.components.tableColumns.LOCATION,
      fieldName: 'location',
      render: ({ location, locationId }: AdminTournamentGridItem) => (
        <Link className={classes.link} to={Routes.Location.path.replace(':id', String(locationId))}>{location}</Link>
      )
    },
    {
      displayName: l10n.components.tableColumns.PARTICIPANTS,
      fieldName: 'participantsCount',
      allowSortring: false,
      allowSearch: false,
      render: (tournament: AdminTournamentGridItem) => <ParticipantRatio tournament={tournament} />
    },
    {
      displayName: l10n.components.tableColumns.FORMAT,
      fieldName: 'format',
      allowSearch: false,
    },
    {
      displayName: l10n.components.tableColumns.STATUS,
      fieldName: 'status',
      allowSearch: false,
    },
    {
      displayName: l10n.components.tableColumns.CONTROL,
      fieldName: 'management',
      allowSortring: false,
      allowSearch: false,
      render: ({ id }: AdminTournamentGridItem) => (
        <ManagementCell
          getTournament={getAllTournaments}
          tournamentId={id}
        />
      ),
    },
  ];

  if (isAllowedCreatorFullName) {
    columns.unshift({
      displayName: l10n.components.tableColumns.CREATOR,
      fieldName: 'creatorFullName',
    });
  }

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <div className={classes.root}>
      <AddTournament getAllTournaments={getAllTournaments} />
      <AreatroutTable columns={columns} rows={tournaments} />
    </div>
  );
};

export default Tournaments;