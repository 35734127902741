import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  select: {
    display: 'inline-flex',
    margin: 12,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 0,
    },
  },
  selectContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 74,
    },
  },
  button: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer'
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  active: {
    color: 'rgba(13, 169, 178, 1)'
  },
  buttonReset: {
    display: 'inline-flex',
    verticalAlign: 'bottom',
    paddingBottom: 24,
    marginLeft: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  filterButton: {
    display: 'block',
  },
  filterIcon: {
    gap: 6
  },
  largeButton: {
    marginTop: 24
  },
  mobileButtonsContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    width: '100%',
    height: 74,
    boxShadow: '0px 4px 16px rgb(139 147 176 / 25%)',
    background: 'white',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  tagsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 1360,
    margin: '20px 0'
  },
  reset: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: 'auto',
    },
  },
  section: {
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  }
}));

export default useStyles;