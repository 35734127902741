import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  signInForm: {
    width: '60%',
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '87%',
      maxWidth: 360
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: 150
    },
  },
  form: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 25,
      boxSizing: 'border-box'
    },
    '& .MuiFormControl-fullWidth': {
      height: '78px',
      '& .MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
  },
  basicText: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '135%',
    color: colors.primary
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '38px',
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
  },
  signInBtn: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(4)}px auto ${theme.spacing(4)}px`
  },
  signUpLink: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  recoveryPasswordButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -theme.spacing(1.5),
    position: 'relative',
    zIndex: theme.zIndex.tooltip + 1,
  },
  link: {
    color: colors.primary,
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer'
  },
  toggleTextSecurity: {
    position: 'absolute',
    right: 0,
    height: 29,
    margin: '10px 5px',
    border: 'none',
    cursor: 'pointer'
  },
  passwordWrapper: {
    position: 'relative'
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  },
  invalid: {
    textAlign: 'center',
    color: colors.primaryError,
    '& .MuiFormHelperText-contained': {
      margin: '3px 0 0'
    }
  },
}));

export default useStyles;