import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  uploadWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
  },
  preview: {
    backgroundColor: '#fff',
  },
  uploadForm: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  saveImageButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '10%',
  },
  imageName: {
    marginTop: 5,
    color: '#4791db',
    textAlign: 'center',
  },
  button: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: '#0DA9B3',
    border: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'start',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    },
  },
  previewPicture: {
    position: 'relative',
    '& img': {
      maxWidth: 48,
      width: '100%',
    },
    '&.large > img': {
      maxWidth: 96,
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '-5%',
    right: '-5%',
    background: 'transparent',
    '& span .large': {
      width: 25,
    }
  },
}));

export default useStyles;