import React, { useState, useEffect } from 'react';
import AreatroutTable from '../../../../components/table';
import UserService from '../../../../services/main/User.service';
import UserRating from '../../../../models/pages/user/UserRating';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import getUserResultsByRatings from './RatingTable.utils';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import useStyles from './RatingTable.styles';

interface RatingTableProps {
    anglerId: string;
}

function RatingTable({ anglerId }: RatingTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<UserRating[] | null>(null);

  const classes = useStyles();
  const l10n = useTranslation();

  const getRatings = async (id: string) => {
    setIsLoading(true);
    const response = await UserService.getRatings(id);
    if (response) {
      setTableData(getUserResultsByRatings(response));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRatings(anglerId);
  }, []);

  const columns = [
    { displayName: l10n.components.tableColumns.RATINGS, fieldName: 'rating' },
    { displayName: l10n.components.tableColumns.SEASONS, fieldName: 'season' },
    {
      displayName: l10n.components.tableColumns.TOURNAMENTS,
      fieldName: 'tournamentsAmount',
      render: ({ tournamentsAmount, tournamentsInSeason }: UserRating) => (
        <div>
          {(tournamentsAmount || tournamentsInSeason) && `${tournamentsAmount} / ${tournamentsInSeason}`}
        </div>
      )
    },
    { displayName: l10n.components.tableColumns.PLACE, fieldName: 'place' },
    { displayName: l10n.components.tableColumns.POINTS, fieldName: 'score' },
    { displayName: l10n.components.tableColumns.CATCHES, fieldName: 'catches' },
  ];

  const setCollapse = (row: any, rows: any) => rows.find((a: any) => a.id === row.parentId);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      {tableData?.length && tableData
        ? (<AreatroutTable columns={columns} rows={tableData} parentChildData={setCollapse} />)
        : <h3 className={classes.emptyRatings}>{l10n.components.tableColumns.EMPTY_RATINGS}</h3> }
    </>
  );
}

export default RatingTable;