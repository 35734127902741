import React from 'react';
import useStyles from './HeatMapCell.styles';
import Tooltip from '../../tooltip/Tooltip';
import HeatMapData from '../../../models/pages/tournament/HeatMapData';
import star from '../../../assets/icons/black-star.svg';

const HeatMapCell = (props: {
  sector: HeatMapData
  minCatches: number
  maxCatches: number
  isAnglerHighlighted: boolean
}) => {
  const {
    sector, minCatches, maxCatches, isAnglerHighlighted
  } = props;

  const getCatchesColor = () => {
    const catches = Number(sector?.angler.catches) + Number(sector?.opponent.catches);
    const bR = 255;
    const bG = 255;
    const heat = catches > 0 ? (catches - minCatches) / (maxCatches - minCatches) : 0;
    const red = heat <= 0.5 ? bR : bR * (1 - heat) * 2;
    const green = heat >= 0.5 ? bG : bG * 2 * heat;
    const blue = 0;
    return `rgba(${red},${green},${blue}, 0.45)`;
  };

  const backgroundColor = getCatchesColor();

  const classes = useStyles({ backgroundColor });
  const sumCatches: number | null | undefined = Number(sector?.angler.catches) + Number(sector?.opponent.catches);

  return (
    <Tooltip
      index={sector?.tour.toString() || ''}
      className={classes.tooltip}
      content={(
        <div className={classes.wrapperContentTooltip}>
          <span>
            {`${sector?.angler.name}: ${sector?.angler.catches}`}
          </span>
          <span>
            {`${sector?.opponent.name}: ${sector?.opponent.catches}`}
          </span>
        </div>
        )}
      container={(
        <div className={classes.wrapperRounds}>
          {isAnglerHighlighted && <div className={classes.wrapperAbsolute}><img src={star} alt="g" width={10} /></div>}
          {sumCatches}
        </div>
        )}
    />
  );
};

export default HeatMapCell;