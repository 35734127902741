import {
  makeAutoObservable,
  runInAction
} from 'mobx';
import { articles } from '../../utils/enum/Enum';
import { NewsCards, PublicationCard } from '../../models/pages/news/News';
import NewsService from '../../services/main/News.service';

export default class NewsStore {
  news: NewsCards = { total: 0, items: [] };

  filter: articles = articles.all;

  mainStore: any;

  readonly defaultCountOfNews = 12;

  constructor(mainStore: any) {
    this.mainStore = mainStore.mainStore;
    makeAutoObservable(this);
  }

  get filteredNews(): PublicationCard[] {
    return this.news.items.filter((item: PublicationCard) => item.type === this.filter || this.filter === articles.all);
  }

  setFilter = (filter: articles) => {
    this.filter = filter;
  }

  refreshNews = async () => {
    const response = await NewsService.get(0, this.defaultCountOfNews);
    if (response) {
      runInAction(() => { this.news = response; });
    }
  };

  initialize = async () => {
    this.getNews(0);
  };

  getNews = async (page: number) => {
    const skip = page * this.defaultCountOfNews;
    const response = await NewsService.get(skip, this.defaultCountOfNews);
    if (response) {
      this.news = {
        total: response.total,
        items: [
          ...this.news.items,
          ...response.items.filter((article: PublicationCard) => !this.news.items.some((item: PublicationCard) => item.id === article.id
          && item.type === article.type))
        ]
      };
    }
    return response;
  }
}