import React from 'react';
import useStyles from './UrlCell.styles';

const defaultProps = {
  displayName: '',
};

const UrlCell = (props: {url: string, displayName?: string }) => {
  const { url, displayName } = props;
  const classes = useStyles();
  return (
    <a className={classes.link} href={url}>{displayName || url}</a>
  );
};

export default UrlCell;

UrlCell.defaultProps = defaultProps;