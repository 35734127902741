import {
  remove, get, post, put
} from '../../Http';
import TornamentFormat from '../../../models/pages/tournament/TournamentFormat';

const url = '/api/Formats/';

const FormatService = {
  async get() {
    return get(url);
  },

  async post(formats: TornamentFormat) {
    return post(url, formats);
  },

  async delete(id: number) {
    return remove(url + id);
  },

  async put(formats: TornamentFormat) {
    const { id } = formats;
    return put(url + id, formats);
  },
};

export default FormatService;