import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import SelectItem from '../../../../models/components/selects/SelectItem';
import useStyles from '../../team/Team.styles';

const defaultProps = {
  disabled: false,
  defaultValue: '',
  menuItemStyle: ''
};

function Mobile(props: {
  menuItems: SelectItem[],
  disabled?: boolean,
  label: string
  menuItemStyle?: string
  bindValue: keyof Omit<SelectItem, 'isDefault'>,
  // eslint-disable-next-line no-unused-vars
  onItemSelected: (value: string | number | null) => void,
  defaultValue?: string | number
}) {
  const {
    menuItems, disabled, onItemSelected, bindValue, label, defaultValue, menuItemStyle
  } = props;

  const classes = useStyles();
  const getInitialValue = () => (menuItems.length ? (menuItems.find((item) => item.isDefault) || menuItems[0])[bindValue] : '');

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | number>(getInitialValue());

  useEffect(() => {
    const initialValue = getInitialValue();
    setSelectedItem(defaultValue || initialValue);
    onItemSelected(defaultValue || initialValue);
  }, [menuItems]);

  const clickHandler = (item: string) => {
    setSelectedItem(item);
    onItemSelected(item);
  };

  return (
    <div className={classes.accordion}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Accordion
          expanded={open}
          disabled={disabled}
          square
          onChange={() => setOpen((prev) => !prev)}
        >
          <AccordionSummary
            disableRipple
            expandIcon={<ExpandMoreIcon />}
            className={clsx(classes.accordionSummary, classes.basicText)}
          >
            <FormHelperText className={classes.label}>{label}</FormHelperText>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionItemsContainer}>
            {menuItems?.map((item: SelectItem) => (
              <MenuItem
                classes={{
                  root: clsx(
                    classes.listItem,
                    classes.basicText,
                    classes.singleItem,
                    { [classes.active]: selectedItem === item.name }
                  ),
                  selected: classes.active
                }}
                disableGutters
                disableRipple
                key={item.id || uuidv4()}
                value={item.name}
                className={menuItemStyle || classes.menuItemContainer}
                onClick={() => clickHandler(item.name)}
              >
                {item.name}
              </MenuItem>
            ))}
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
    </div>
  );
}

Mobile.defaultProps = defaultProps;

export default Mobile;