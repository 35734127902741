import React, { useEffect, useState } from 'react';
import {
  Grid, IconButton, Box, Toolbar
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Routes from '../../routing/Routes';
import useStyles from './Footer.styles';
import insta from '../../assets/icons/communication/insta.svg';
import logo from '../../assets/logo/logoTuna.png';
import useTranslation from '../localization/customHooks/Translation';
import VersionService from '../../services/main/Version.service';
import LanguageSelect from '../selects/language/Language';

export default function Footer() {
  const classes = useStyles();
  const l10n = useTranslation();
  const currentYear = new Date().getFullYear();

  const [backendVersion, setbackendVersion] = useState<string>();

  const getbackendVersion = async () => {
    const res = await VersionService.get();
    if (res) {
      setbackendVersion(res);
    }
  };

  useEffect(() => {
    getbackendVersion();
  }, []);

  const frontendVersion = process.env.REACT_APP_VERSION;

  return (
    <Grid className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid className={classes.logo}><img src={logo} alt="logo" /></Grid>
        <Grid className={classes.menuContainer}>
          <Grid className={classes.menu}>
            <Box className={classes.links}>
              <Link to={Routes.AvailableTournaments.path}>
                {Routes.AvailableTournaments.name}
              </Link>
              <Link to={Routes.Rating.path}>
                {Routes.Rating.name}
              </Link>
              <Link to={Routes.Locations.path}>
                {Routes.Locations.name}
              </Link>
              <a href="/">{l10n.components.footer.LINK_TO_RULES}</a>
            </Box>
            <Box className={classes.links}>
              <a href="/">{l10n.components.footer.LINK_CONTACTS}</a>
              <a href="/">{l10n.components.footer.LINK_USER_AGREEMENT}</a>
              <a href="/">{l10n.components.footer.LINK_ORGANIZERS}</a>
              <Link to={Routes.About.path}>
                {Routes.About.name}
              </Link>
            </Box>
          </Grid>
          <div className={classes.desktopLanguageSelect}>
            <LanguageSelect />
          </div>
        </Grid>
        <Grid className={classes.block}>
          <IconButton
            className={classes.instaLogo}
            component="a"
            href="#"
            target="blank"
            rel="noreferrer noopener"
          >
            <img src={insta} alt="instagramLink" />
          </IconButton>
          <Box>
            <p className={classes.title}>
              Areaby ©
              {' '}
              {currentYear}
            </p>
            <p className={classes.title}>{l10n.components.footer.TITLE_RIGHTS}</p>
            <p className={classes.title}>{`${l10n.components.footer.VERSION} ${frontendVersion} / ${backendVersion}`}</p>
          </Box>
        </Grid>
      </Toolbar>
    </Grid>
  );
}