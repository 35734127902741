import React, { useState } from 'react';
import useStyles from '../../Authorization.styles';
import image from '../../../../assets/signForm/signFormLogo.png';
import { EmptyVoidFunction } from '../../../../utils/types/Types';

const defaultProps = {
  handleClose: () => { },
};

function Wrapper(props: {
  handleClose?: EmptyVoidFunction;
  component: JSX.Element
}) {
  const classes = useStyles();
  const { component, handleClose } = props;
  const [modalComponent, setModalComponent] = useState<JSX.Element>(component);
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.image}>
        <img src={image} alt="Sign-In Logo" />
      </div>
      <div className={classes.formsWrapper}>
        {modalComponent && React.cloneElement(modalComponent, {
          handleClose,
          setModalComponent
        })}
      </div>
    </div>
  );
}

export default Wrapper;

Wrapper.defaultProps = defaultProps;