import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TableHead, TableCell, TableRow } from '@material-ui/core';
import TournamentRating from '../../../../models/pages/tournament/TournamentRating';
import useStyles from './TableHeader.styles';
import Routes from '../../../../routing/Routes';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const TableHeader = (props: {
  tournaments: TournamentRating[]
}) => {
  const { tournaments } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell rowSpan={2}>{l10n.components.tableColumns.PLACE}</TableCell>
        <TableCell rowSpan={2}>{l10n.components.tableColumns.PARTICIPANT}</TableCell>
        {tournaments.map((tournament) => (
          <TableCell key={tournament.tournamentId} colSpan={2} align="center">
            <Link to={Routes.TournamentCard.path.replace(':id', String(tournament.tournamentId))} className={classes.textLink}>
              <span>{tournament.tournamentName}</span>
            </Link>
          </TableCell>
        ))}
        <TableCell rowSpan={2} align="center">
          {l10n.components.tableColumns.PLACES_SUM}
        </TableCell>
        <TableCell rowSpan={2} align="center">
          {l10n.components.tableColumns.POINTS_SUM}
        </TableCell>
      </TableRow>
      <TableRow className="tableHead">
        {tournaments.map((tournament) => (
          <Fragment key={tournament.tournamentId}>
            <TableCell align="center">{l10n.components.tableColumns.PLACE}</TableCell>
            <TableCell align="center">{l10n.components.tableColumns.POINT}</TableCell>
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;