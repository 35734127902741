import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  cardBorder: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
    },
  },
  errorButton: {
    marginLeft: 35,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 20
    },
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 35,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  errors: {
    fontSize: 18,
  },
  errorsCount: {
    marginLeft: 5,
    fontWeight: 600,
    fontSize: 24,
  },
}));

export default useStyles;