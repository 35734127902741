import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  main: {
    boxSizing: 'border-box',
    maxWidth: 1100,
    width: '100%',
    margin: '0 auto',
  },
  breadCrumbs: {
    marginTop: 30,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    margin: 16,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  reportDescription: {
    width: '70vw',
    maxWidth: 900,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: '48px',
    fontWeight: 'bold',
    color: colors.secondary,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px'
    },
  },
  description: {
    width: '100%',
    marginTop: 10,
    maxWidth: 896,
    order: 3,
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  link: {
    color: colors.link,
    '& a': {
      color: colors.link,
      fontSize: 16
    }
  },
  photoGallery: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    maxWidth: 1360,
    height: '85vh',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
      height: 350,
      margin: 'auto',
    },
  },
  imgCarousel: {
    objectFit: 'contain',
    maxWidth: '85vw',
    maxHeight: '85vh',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      maxHeight: 350,
    },
  },
  country: {
    fontSize: 20
  },
  buttonStatus: {
    margin: 'auto'
  },
  table: {
    margin: 16,
  },
  infoAboutReport: {
    display: 'flex',
    gap: 30,
    [theme.breakpoints.down('xs')]: {
      gap: 5,
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  flag: {
    width: 20,
    height: 20,
    marginRight: 4
  },
  location: {
    whiteSpace: 'nowrap'
  },
  breakText: {
    wordBreak: 'break-word',
  },
  flagWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  reportMain: {
  },

  reportText: {
    marginLeft: 15,
    marginRight: 15,
  },
  reportFooter: {
    marginTop: 50,
  },
  reportHeader: {
    marginTop: 50,
    marginBottom: 50,
  },
  socialButtons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  shareButton: {
    width: 50,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
      gap: 5,
    },
  },
  date: {
    margin: 0,
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  teamName: {
    width: 150,
    textAlign: 'center',
  },
  score: {
    right: 0,
    marginTop: '20px',
    fontWeight: 800,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: '50%',
      marginTop: '0',
    },
  },
  opponentName: {
    fontWeight: 800,
  },
  nullBottomMargin: {
    marginBottom: 0,
    height: 20,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      height: 40,
    },
  },
  roundInfo: {
    marginTop: '1em',
    flexBasis: 200,
    flexShrink: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  roundText: {
    display: 'flex',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  additionalWrapper: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
      gap: 5,
    },
  },
}));

export default useStyles;