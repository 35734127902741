import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  cell: {
    borderRadius: 100,
    cursor: 'default',
    width: 58,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  win: {
    border: `1px solid ${colors.green}`,
    color: colors.green,
  },
  loss: {
    border: `1px solid  ${colors.red}`,
    color: colors.red
  },
  draw: {
    border: `1px solid  ${colors.orange}`,
    color: colors.orange,
  },
  wrapperContentTooltip: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      lineHeight: '32px'
    }
  },
  numberRound: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: 4
  },
  wrapperRounds: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: 46,
    justifyContent: 'center',
  },
  resultStatus: {
    padding: '5px 10px',
    textAlign: 'center'
  },
  tooltip: {
    padding: 0
  },
  winColor: {
    background: colors.green,
  },
  defeatColor: {
    background: colors.red,
  },
  drawColor: {
    background: colors.orange,
  },
  roundInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15
  },
  userContainer: {
    padding: '10px 20px',
    boxSizing: 'border-box'
  }
}));

export default useStyles;