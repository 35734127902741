import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const border = `1px solid ${colors.primary}`;

const useStyles = makeStyles((theme) => createStyles({
  teamContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 70,
    borderRadius: 8,
    border,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  teamDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '40%',
    height: '100%',
    borderRight: border,
    boxSizing: 'border-box',
    padding: '0px 60px 0px 25px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 15px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
      padding: 15,
    },
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '135%',
  },
  participantsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderTop: border
    },
  },
  buttonContainer: {
    height: '100%',
    borderLeft: border,
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      justifyContent: 'space-evenly',
      width: '100%',
      padding: '8px 0'
    },
  },
  teamStatus: {
    cursor: 'pointer'
  },
  teamName: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '135%',
  },
  user: {
    borderBottom: border,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  partner: {
    width: '100%'
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 10,
      borderBottom: border
    },
  },
  avatar: {
    height: 24,
    width: 24
  },
  name: {
    marginLeft: 20,
  },
  smallMargin: {
    margin: '0px 5px'
  },
  largeMargin: {
    margin: '0px 20px'
  },
  roundedButton: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: colors.primaryLight
    },
    '&:hover': {
      backgroundColor: colors.lightBlue
    },
  },
  popover: {
    padding: 20,
    borderRadius: 8,
    color: colors.primaryLight
  },
  border: {
    border,
    borderRadius: 8
  },
  basicText: {
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: '30px'
  },
  middleSizeText: {
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '30px'
  },
  avatarLarge: {
    width: 50,
    height: 50
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: 20,
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '30px',
      color: colors.secondary
    }
  },
}));

export default useStyles;