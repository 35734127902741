/* eslint-disable max-len */
import {
  boolean, mixed, number, object, string
} from 'yup';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(l10n.components.rating.REQUIRED_FIELD),
  description: string().required(l10n.components.rating.REQUIRED_FIELD),
  isCountingTournamentsChecked: boolean(),
  countingTournamentsNumber: mixed()
    .when('isCountingTournamentsChecked', {
      is: true,
      then: number().typeError(l10n.components.rating.TYPE_NUMBER).positive(l10n.components.rating.NUMBER_MORE_THAN_ZERO).integer(l10n.components.rating.TYPE_INTEGER_NUMBER),
      otherwise: string()
    }),
  isNationalRatingChecked: boolean(),
  countryId: string().required(l10n.components.rating.REQUIRED_COUNTRY),
});

export default schema;