import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './RegisterAnglerModal.styles';
import ParticipantData from '../../../../../../../components/participantData/ParticipantData';

function AnglerData(props: {
  anglerData: any
  setAnglerData: React.Dispatch<React.SetStateAction<any>>
  setEmail: React.Dispatch<React.SetStateAction<any>>
  disabled: boolean
}) {
  const {
    anglerData, setAnglerData, setEmail, disabled
  } = props;
  const classes = useStyles();
  const resetUserData = () => {
    setAnglerData(null);
    setEmail('');
  };
  return (
    <>
      {
        anglerData.id && (
          <div className={clsx(classes.flexColumn, { [classes.disabledInput]: disabled })}>
            <div className={clsx(classes.flexRow, classes.partnerContainer)}>
              <ParticipantData
                url={anglerData!.profilePicture ? anglerData!.profilePicture!.url : ''}
                name={anglerData?.fullName}
              />
              {!disabled && (
                <button
                  className={classes.deleteItems}
                  onClick={resetUserData}
                  type="button"
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          </div>
        )
      }
    </>

  );
}

export default AnglerData;