import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from '../ActivationErrors.styles';
import ATButton from '../../../button/Button';
import checkMarkFailed from '../../../../assets/icons/checkMarkFailed.svg';
import AuthorizationService from '../../../../services/main/Authorization.service';
import AuthorizationComponents from '../../helpers/Constants';
import Loader from '../../../loader/Loader';
import Toasters from '../../../popUp/PopUp';
import AuthorizationResponse from '../../../../models/components/authorization/AuthorizationResponse';
import useTranslation from '../../../localization/customHooks/Translation';

const defaultProps = {
  setModalComponent: null,
};

const ActivationExpired = (props: {
  email: string,
  setModalComponent?: React.Dispatch<React.SetStateAction<JSX.Element>>
}) => {
  const l10n = useTranslation();
  const { email, setModalComponent } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const sendConfirmationLink = async () => {
    setIsLoading(true);
    const res: AuthorizationResponse = await AuthorizationService.sendConfirmationLink(email);

    if (!res.error && setModalComponent) {
      setModalComponent(AuthorizationComponents.ConfirmationEmail);
    }

    if (res.error && res.message) {
      Toasters.error(res.message);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className={classes.centeredWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.wrapper}>
      <div className={classes.checkMark}>
        <img src={checkMarkFailed} alt="check-mark-failed" />
      </div>
      <Typography className={classes.title}>
        {l10n.components.authorization.TITLE_EXPIRED}
      </Typography>
      <Typography className={classes.description}>
        {l10n.components.authorization.DESCRIPTION_EXPIRED}
      </Typography>
      <ATButton
        variant="primary"
        fill="solid"
        size="lg"
        onClick={sendConfirmationLink}
      >
        {l10n.components.authorization.BUTTON_DESCRIPTION_EXPIRED}
      </ATButton>
    </Grid>
  );
};

export default ActivationExpired;

ActivationExpired.defaultProps = defaultProps;