import React, { useState } from 'react';
import {
  Box,
  Grid, IconButton, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import useStyles from './PhotoGallery.styles';
import ImageThumbnail from '../imageThumbnail/ImageThumbnail';
import { getImageUrl } from '../../utils/Utils';
import PhotosGalleryState from '../../models/components/photoGallery/PhotosGalleryState';
import ImageData from '../../models/components/image/ImageData';
import UploadImage from '../uploadImages/UploadImage';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  countPhotosInGallery: null
};

function PhotoGallery(props: {
  photosData: PhotosGalleryState,
  setPhotosData: React.Dispatch<React.SetStateAction<PhotosGalleryState>>
  isReadMode: boolean,
  countPhotosInGallery?: number | null,
}) {
  const {
    photosData: { photos }, setPhotosData, isReadMode, countPhotosInGallery
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const l10n = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const deleteImage = async (imageId: number) => {
    setPhotosData((prev: PhotosGalleryState) => (
      {
        isChangedPhotosOutside: true,
        photos: prev.photos?.filter((item: any) => item.id !== imageId)
      }
    ));
  };

  const clickImageHandler = (link: string) => {
    setImageUrl(link);
    setOpen(true);
  };

  const addImage = (image: ImageData) => {
    if (image) {
      setPhotosData((prev) => ({ isChangedPhotosOutside: true, photos: [...prev.photos, image] }));
    }
  };

  const getImgUrlsList = (currPhotos: ImageData[]) => currPhotos.map(({ url }: ImageData) => getImageUrl(url));

  const visiblePhotos = photos.slice(0, countPhotosInGallery ? Math.min(countPhotosInGallery, photos.length) : photos.length).map((photo, index) => (
    <Grid
      item
      xs={12}
      md={3}
      sm={6}
      key={photo.createdAt}
    >
      { countPhotosInGallery && photos.length > countPhotosInGallery && countPhotosInGallery - 1 === index ? (
        <Box className={classes.wrapperCloseIcon}>
          {/* TODO: need to implementing correct link on gallery or photosCarusel */}
          <Box className={classes.wrapperLink} onClick={() => {}}>
            <img
              src={getImageUrl(photo.url)}
              alt={photo.originalFilename}
              className={classes.image}
            />
            <span className={classes.link}>
              {`+${photos?.length - Number(countPhotosInGallery)} ${l10n.components.photoGallery.PHOTO}`}
            </span>
          </Box>
        </Box>
      )
        : (
          <Box className={classes.wrapperCloseIcon}>
            <Box onClick={() => clickImageHandler(photo.url)}>
              <img
                src={getImageUrl(photo.url)}
                alt={photo.originalFilename}
                className={classes.image}
              />
            </Box>
            { !isReadMode && (
            <IconButton
              onClick={() => deleteImage(photo.id)}
              className={classes.closeButton}
              size="small"
            >
              <Close />
            </IconButton>
            )}
          </Box>
        )}
    </Grid>
  ));

  if (!photos.length) {
    return (
      <>
        <Typography variant="h6" gutterBottom align="center">
          {l10n.components.photoGallery.EMPTY_PHOTO_LIST}
        </Typography>
        { !isReadMode && <UploadImage addImage={addImage} />}
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={isMobile ? 2 : 4}>
        {visiblePhotos}
      </Grid>
      <ImageThumbnail
        listOfImgsUrl={getImgUrlsList(photos)}
        onClose={() => setOpen(false)}
        open={open}
        setOpen={setOpen}
        src={getImageUrl(imageUrl)}
      />
      { !isReadMode && <UploadImage addImage={addImage} />}
    </div>
  );
}

PhotoGallery.defaultProps = defaultProps;

export default PhotoGallery;