import React, { useState, useRef, useEffect } from 'react';

import {
  Button,
  Avatar,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { hasRolesIntersection, StorageService, useQuery } from '../../utils/Utils';
import Routes from '../../routing/Routes';
import useStyles from './UserProfile.styles';
import ATButton from '../button/Button';
import noAvatar from '../../assets/icons/no-avatar.svg';
import mobileSignInIcon from '../../assets/icons/user.svg';
import DropDown from './dropDown/DropDown';
import DropDownItem from './dropDown/item/DropDownItem';
import contentComponents from '../authorization/helpers/Constants';
import AuthorizationModal from '../authorization/modalWindow/ModalWindow';
import { emailType } from '../../utils/enum/Enum';
import useTranslation from '../localization/customHooks/Translation';

export default function UserProfile() {
  const classes = useStyles();
  const l10n = useTranslation();
  const [open, setOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const navigate = useNavigate();
  const anchorRef = useRef<null | HTMLButtonElement>(null);
  const userData = StorageService.getUserData();
  const query = useQuery();
  const token = query.get('token');
  const type = query.get('type');

  useEffect(() => {
    if (token) {
      if (type === emailType.password) {
        setComponent(contentComponents.ChangePassword);
      }
      if (type === emailType.email) {
        setComponent(contentComponents.ConfirmationResult);
      }
      setIsModalOpened(true);
    }
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const signOut = () => {
    window.location.replace(Routes.Home.path);
    StorageService.deleteUserData();
    handleToggle();
  };

  const directToUserPage = () => {
    window.location.replace(Routes.User.path.replace(':id', StorageService.getUserData().id));
  };

  return (
    <div className={classes.userProfile}>
      {userData
        ? (
          <>
            <Button
              ref={anchorRef}
              aria-controls={clsx({ 'menu-list-grow': open })}
              aria-haspopup="true"
              onClick={handleToggle}
              disableRipple
            >
              <Grid
                className={classes.userProfileContainer}
              >
                {userData && userData.profilePicture
                  ? (
                    <Avatar
                      className={classes.avatar}
                      src={`${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${userData.profilePicture.url}`}
                      alt={userData.profilePicture.originalFilename}
                    />
                  )
                  : <Avatar variant="circle" src={noAvatar} alt="avatar" />}
                <i className={clsx('arrow', { up: open, down: !open })} />
              </Grid>
            </Button>
            <DropDown open={open} setOpen={setOpen} anchorRef={anchorRef}>
              <DropDownItem clickHandler={directToUserPage} label={l10n.components.userProfile.MY_PROFILE} />
              {(hasRolesIntersection(Routes.Admin.rolesAllowed, userData))
                ? <DropDownItem clickHandler={() => navigate(Routes.AdminTournaments.path)} label={l10n.components.userProfile.ADMIN} />
                : <></>}
              <DropDownItem clickHandler={() => navigate(Routes.Teams.path)} label={l10n.components.userProfile.TEAMS} />
              <DropDownItem clickHandler={signOut} label={l10n.components.userProfile.SIGN_OUT} />
            </DropDown>
          </>
        )
        : (
          <>
            <div className={classes.buttons}>
              <button
                type="button"
                className={classes.signInButton}
                onClick={() => {
                  setIsModalOpened(true);
                  setComponent(contentComponents.SignIn);
                }}
              >
                {l10n.components.userProfile.SIGN_IN}
              </button>
              <ATButton
                variant="primary"
                fill="solid"
                size="md"
                onClick={() => {
                  setIsModalOpened(true);
                  setComponent(contentComponents.SignUp);
                }}
              >
                {l10n.components.userProfile.SIGN_UP}
              </ATButton>
            </div>
            <Avatar
              variant="circle"
              src={mobileSignInIcon}
              alt="avatar"
              className={classes.mobileSignInIcon}
              onClick={() => {
                setIsModalOpened(true);
                setComponent(contentComponents.SignIn);
              }}
            />
          </>
        )}
      {component
        && (
          <AuthorizationModal
            open={isModalOpened}
            setOpen={setIsModalOpened}
            component={component}
          />
        )}
    </div>
  );
}