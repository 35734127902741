import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import Season from '../../../../../../../models/components/dictionaries/season/Season';
import useStyles from '../../Seasons.styles';

function EditDeleteCell(props: {
    data: Season
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setSeasonInfo: React.Dispatch<React.SetStateAction<Season>>
}) {
  const classes = useStyles();
  const {
    data, setOpenDelete, setSeasonInfo, setOpenEdit,
  } = props;
  const openDelModal = (dataSeason: Season): void => {
    setOpenDelete(true);
    setSeasonInfo(dataSeason);
  };
  const openEditModal = (dataSeason: Season): void => {
    setOpenEdit(true);
    setSeasonInfo(dataSeason);
  };

  return (
    <ListItem className={classes.buttonCell}>
      <IconButton
        edge="start"
        aria-label="edit"
        onClick={() => openEditModal(data)}
      >
        <Edit />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => openDelModal(data)}
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
}

export default EditDeleteCell;