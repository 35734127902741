import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import formatService from '../../../../../services/main/dictionaries/Format.service';
import TournamentFormat from '../../../../../models/pages/tournament/TournamentFormat';
import useStyles from './Formats.styles';
import FormatsTable from './components/table/FormatsTable';
import AddEditFormatForm from './components/modals/AddEditFormatForm';
import Modal from '../../../../../components/modal/Modal';
import DeleteFormatConfirm from './components/modals/DeleteFormatConfirm';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

function Formats() {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [formatsList, setFormatsList] = useState<TournamentFormat[] | null>([]);
  const [format, setFormat] = useState<TournamentFormat>({
    id: 0,
    name: '',
    description: '',
    rules: '',
  });

  const l10n = useTranslation();

  const getFormats = async () => {
    const formats = await formatService.get();
    setFormatsList(formats);
    setIsLoaded(false);
  };

  useEffect(() => {
    getFormats();
  }, []);

  const addFormClose = (): void => {
    getFormats();
    setOpenAdd(false);
  };

  const editFormClose = (): void => {
    getFormats();
    setOpenEdit(false);
  };

  const deleteFormClose = (): void => {
    getFormats();
    setOpenDelete(false);
  };

  if (isLoaded) {
    return (<LoaderFullScreen />);
  }
  return (
    <div className={classes.formatsWrapper}>
      <div className={classes.addButton}>
        <Button
          data-testid="add-button"
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={() => { setOpenAdd(true); }}
        >
          {l10n.components.tournamentFormat.ADD_FORMAT_BUTTON}
        </Button>
      </div>
      <Modal
        header={l10n.components.tournamentFormat.ADD_FORMAT_MODAL}
        open={openAdd}
        setOpen={setOpenAdd}
        className={classes.modalWide}
        onClose={addFormClose}
      >
        <AddEditFormatForm
          isEdited={openEdit}
          format={format}
        />
      </Modal>
      <Modal
        header={l10n.components.tournamentFormat.EDIT_FORMAT_MODAL}
        open={openEdit}
        setOpen={setOpenEdit}
        className={classes.modalWide}
        onClose={editFormClose}
      >
        <AddEditFormatForm
          isEdited={openEdit}
          format={format}
        />
      </Modal>
      <Modal
        header={l10n.components.tournamentFormat.DELETE_FORMAT_MODAL}
        open={openDelete}
        setOpen={setOpenDelete}
        className={classes.modalWide}
        onClose={deleteFormClose}
      >
        <DeleteFormatConfirm
          id={format.id}
        />
      </Modal>
      <FormatsTable
        formatsList={formatsList}
        setOpenEdit={setOpenEdit}
        setOpenDelete={setOpenDelete}
        setFormat={setFormat}
      />
    </div>
  );
}

export default Formats;