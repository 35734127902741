import { tableStyles } from './helpers/Constants';
import getTableHeader from './tableElements/Header';
import getTableRounds from './tableElements/Rounds';
import Participant from '../../../../../../models/pages/tournament/Participant';

import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

export const getDocumentContent = (
  participants: Participant[],
  tournamentName: string,
  tournamentDate: string,
  id: number,
) => {
  const halfNumber = (participants.length + (participants.length % 2)) / 2;
  const getSectorSide = (anglerNumber: number, halfSectorNumber: number) => (anglerNumber <= halfSectorNumber ? l10n.pages.RIGHT : l10n.pages.LEFT);

  return [participants.map((participant: Participant) => [{
    table: {
      widths: [25, 30, 40, 50, 40, 25, 200, 50],
      headerRows: 4,
      body: [
        ...getTableHeader(participant, tournamentName, tournamentDate, id),
        ...getTableRounds(participant.rounds, getSectorSide(participant.anglerNumber, halfNumber)),
      ]
    }
  },
  '\n'])];
};

export const getDocumentStyles = () => tableStyles;

export const createBase64Url = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');
  ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);
  const dataURL = canvas.toDataURL('image/png');
  return dataURL;
};