import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { defaultMapCoords } from '../../utils/constants/Constants';
import { useStores } from '../../stores/Main';

const AreatroutMap = observer(() => {
  const { LocationStore } = useStores();
  const mapData = {
    center: LocationStore.coordinates || defaultMapCoords,
    zoom: 12,
  };

  const onMapClick = (event: any) => {
    LocationStore.setCoordinates(event.get('coords'));
    runInAction(() => {
      LocationStore.areCoordinatesChanged = true;
    });
  };

  return (
    <YMaps enterprise query={{ apikey: process.env.REACT_APP_YANDEX_MAP_KEY }}>
      <Map defaultState={mapData} width="100%" height="100%" onClick={onMapClick}>
        {LocationStore.coordinates && <Placemark geometry={LocationStore.coordinates} />}
      </Map>
    </YMaps>
  );
});

export default AreatroutMap;