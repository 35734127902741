import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  table: {
    '& td.MuiTableCell-root': {
      padding: 0,
      '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone': {
        display: 'none'
      }
    }
  },
  wrapper: {
    margin: '0 auto',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0 0 16px',
      overflow: 'hidden',
    }
  },
  wrapperPlace: {
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img': {
      opacity: 0
    },
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
    }
  },
  wrapperPlayoffPlace: {
    '& img': {
      opacity: 1
    },
  },
  h2: {
    marginBottom: 32,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24
    }
  }
}));

export default useStyles;