import { getTranslation } from '../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const defaultSelectValues = {
  season: l10n.components.defaultSeasons.AUTUMN_21_SPRING_22,
  country: l10n.components.defaultCountries.BELARUS,
  rating: l10n.components.defaultRatings.ATLAS
};

export default defaultSelectValues;

export const userErrors: { [key: number]: string } = {
  0: l10n.components.error.UNKNOWN_ERROR,
  1: l10n.components.error.USER_IS_BLOCKED,
  2: l10n.components.error.LOGIN_PASSWORD_ERROR,
  3: l10n.components.error.NON_EXISTING_LOGIN_DATA_ERROR,
  4: l10n.components.error.EXISTING_LOGIN_DATA_ERROR,
  13: l10n.components.error.EDITING_LOGIN_DATA_ERROR,
  17: l10n.components.error.USER_EMAIL_ERROR,
  18: l10n.components.error.TOKEN_ERROR,
  19: l10n.components.error.RESETPASSWORD_ERROR
};

export const userIsBlockedErrorNumber = 1;