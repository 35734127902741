import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../../../stores/Main';
import TabsContainer from '../../../../components/tabsContainer/TabsContainer';
import EditTournamentData from './tournamentData/TournamentData';
import EditSponsors from './sponsors/Sponsors';
import { tournamentStatus } from '../../../../utils/enum/Enum';
import Registration from './registration/Registration';
import Drawing from './drawing/Drawing';
import ParticipantsCards from './participantsCards/ParticipantsCards';
import PlayoffSchedule from './playoff/components/Schedule';
import Qualifiers from './qualifiers/Qualifiers';
import TournamentImages from './tournamentImages/TournamentImages';
import Publication from './publication/Publication';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const Edit = observer(() => {
  const { TournamentStore } = useStores();
  const url = useParams<{ id: string }>();
  const l10n = useTranslation();
  const { tournament } = TournamentStore;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    TournamentStore.getTournament(Number(url.id));
  }, []);

  useEffect(() => {
    if (tournament) {
      setIsLoading(false);
    }
  }, [tournament]);

  const disabledDrawingStatuses = [
    tournamentStatus.Canceled,
    tournamentStatus.Open,
    tournamentStatus.Planning,
  ];
  const disabledChangingStatuses = [
    tournamentStatus.Close,
    tournamentStatus.Completed,
  ];

  const EditTournamentContent = [
    {
      name: l10n.pages.DATA,
      component: <EditTournamentData
        isInputDisabled={!!(tournament && disabledChangingStatuses.includes(tournament.status))}
      />,
      hash: '#tournament-data'
    },
    {
      name: l10n.pages.SPONSORS,
      component: <EditSponsors />,
      hash: '#edit-sponsors',
    },
    {
      name: l10n.pages.REGISTRATION,
      component: <Registration />,
      hash: '#registartion'
    },
    {
      name: l10n.pages.DRAWING,
      component: <Drawing />,
      disabled:
        tournament && disabledDrawingStatuses.includes(tournament.status),
      hash: '#drawing'
    },
    {
      name: l10n.pages.PARTICIPANT_CARDS,
      component: <ParticipantsCards />,
      disabled: !TournamentStore.isDrawing,
      hash: '#participant-cards'
    },
    {
      name: l10n.pages.QUALIFIERS,
      component: <Qualifiers />,
      disabled: !TournamentStore.areQualifiersCompleted,
      hash: '#qualifiers'
    },
    {
      name: l10n.pages.PLAYOFF,
      component: <PlayoffSchedule isEditMode />,
      disabled: !TournamentStore.areQualifiersCompleted,
      hash: '#playoff'
    },
    {
      name: l10n.pages.PUBLICATION,
      component: <Publication />,
      disabled: !TournamentStore.isPlayOffFinished,
      hash: '#publication'
    },
    {
      name: l10n.pages.TOURNAMENT_IMAGES,
      component: <TournamentImages />,
      hash: '#tournament-images'
    },
  ];

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return <TabsContainer data={EditTournamentContent} />;
});

export default Edit;