/* eslint-disable react/destructuring-assignment */

import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { observer } from 'mobx-react';
import AreatroutTable from '../../../../../components/table';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import Report from '../../../../../models/components/report/Report';
import UrlCell from '../../../../../components/table/cells/urlCell/UrlCell';
import DateCell from '../../../../../components/tableTemplates/components/cell/data/Date';
import Routes from '../../../../../routing/Routes';
import { useStores } from '../../../../../stores/Main';
import Toasters from '../../../../../components/popUp/PopUp';
import { reportStatus } from '../../../../../utils/enum/Enum';

const AdminReportsTable = observer((props: {
  data: Report[],
}) => {
  const { data } = props;
  const { ReportsStore } = useStores();

  const l10n = useTranslation();
  const [adminReports, setAdminReports] = useState<Report[] | []>([]);

  const switchDisplayOfReport = async (reportId: string, reportIsPublished: boolean) => {
    const payload = { reportId, reportIsPublished };
    const response = await ReportsStore.updateVisibilityOfReport(payload);
    if (response) {
      Toasters.success(reportIsPublished ? l10n.components.reports.DISPLAY_REPORT_SUCCESS : l10n.components.reports.HIDE_REPORT_SUCCESS);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, report: Report) => {
    if (report) {
      switchDisplayOfReport(report.id, event.target.checked);
    }

    const updatedReports = [...adminReports];
    updatedReports[adminReports.findIndex((existingReport: Report) => existingReport.id === report.id)] = { ...report, isShown: event.target.checked };

    setAdminReports(updatedReports);
  };

  const fillReportData = (arrayOfReports: Report[]) => arrayOfReports
    .map((report: Report, index: number) => {
      report.index = index + 1;
      report.isShown = report.status === reportStatus.Published;
      return report;
    });

  useEffect(() => {
    const reports = fillReportData(data);
    setAdminReports(reports);
  }, [ReportsStore.reports]);

  const columns = [
    {
      displayName: '№',
      fieldName: 'index',
    },
    {
      displayName: l10n.components.tableColumns.DATE,
      fieldName: 'postedAt',
      render: ({ postedAt }: Report) => (
        <DateCell value={postedAt} />
      )
    },
    {
      displayName: l10n.components.tableColumns.AUTHOR,
      fieldName: 'name',
      allowSearch: true,
      render: ({ author, authorId }: Report) => (
        <UrlCell displayName={author.fullName} url={Routes.User.path.replace(':id', authorId)} />
      )
    },
    {
      displayName: l10n.components.tableColumns.TITLE,
      fieldName: 'author',
      render: ({ name, id }: Report) => (
        <UrlCell displayName={name} url={Routes.TournamentReport.path.replace(':id', id)} />
      ),
    },
    {
      displayName: l10n.components.tableColumns.TOURNAMENT,
      fieldName: 'tournamentName',
      render: ({ tournament, tournamentId }: Report) => (
        <UrlCell displayName={tournament.name} url={Routes.TournamentCard.path.replace(':id', String(tournamentId))} />
      )
    },
    {
      displayName: l10n.components.tableColumns.SHOW_OR_HIDE,
      fieldName: 'isPublished',
      allowSearch: false,
      allowSortring: false,
      render: (report: Report) => (
        <Switch
          checked={report.isShown}
          name={String(report.id)}
          onChange={(event) => handleChange(event, report)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
  ];

  return (
    <AreatroutTable
      columns={columns}
      rows={adminReports}
      isSearchVisible
      searchPlaceholder={l10n.components.areatroutTable.SEARCH_PLACEHOLDER_NAME}
    />
  );
});

export default AdminReportsTable;