import React from 'react';
import { Link } from 'react-router-dom';
import AreatroutTable from '../../../../components/table';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import DateCell from '../../../../components/tableTemplates/components/cell/data/Date';
import PlaceCell from '../../../../components/tableTemplates/components/cell/place/Place';
import { cups } from '../../../../utils/constants/Constants';
import { sortingType } from '../../../../utils/enum/Enum';
import UserParticipationHistory from '../../../../models/pages/tournament/UserParticipationHistory';
import Routes from '../../../../routing/Routes';
import useStyles from '../../UserCard.styles';

export interface ParticipationProps {
  data: UserParticipationHistory[]
}

function ParticipationHistory({ data }: ParticipationProps) {
  const classes = useStyles();
  const l10n = useTranslation();
  const columns = [
    {
      displayName: l10n.components.tableColumns.DATE,
      fieldName: 'tournamentDate',
      defaultSort: sortingType.desc,
      render: ({ tournamentDate }: UserParticipationHistory) => <DateCell value={tournamentDate} />,
    },
    {
      displayName: l10n.components.tableColumns.TOURNAMENT_NAME,
      fieldName: 'tournamentName',
      render: ({ tournamentName, tournamentId }: UserParticipationHistory) => (
        <Link to={Routes.TournamentCard.path.replace(':id', String(tournamentId))}>
          <span className={classes.link}>{tournamentName}</span>
        </Link>
      )
    },
    {
      displayName: l10n.components.tableColumns.RANKING,
      fieldName: 'isTeamTournament',
      render: ({ isTeamTournament }: UserParticipationHistory) => (
        <span>{isTeamTournament ? l10n.pages.TEAM_TOURNAMENT : l10n.pages.INDIVIDUAL_TOURNAMENT}</span>
      )
    },
    {
      displayName: l10n.components.tableColumns.CATCHES,
      fieldName: 'catchesSum',
    },
    {
      displayName: l10n.components.tableColumns.PLACE,
      fieldName: 'finalPlace',
      defaultSort: sortingType.asc,
      render: ({ finalPlace }: UserParticipationHistory) => <PlaceCell place={finalPlace} imageName={cups[finalPlace]} isHaveImageAnyPlaces />
    },
  ];
  return (
    <AreatroutTable columns={columns} rows={data} isPaging={false} className={classes.meetingTable} />
  );
}

export default ParticipationHistory;