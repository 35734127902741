import React from 'react';
import LocationData from '../../../models/components/dictionaries/location/LocationData';
import useStyles from './styles';
import FacebookIcon from '../../../assets/icons/communication/facebook.svg';
import InstagramIcon from '../../../assets/icons/communication/instagram.svg';
import WebIcon from '../../../assets/icons/communication/webIcon.svg';
import TephoneIcon from '../../../assets/icons/communication/telephone.svg';

const InfoPanel = (props: { location: LocationData }) => {
  const { location } = props;
  const classes = useStyles();
  return (
    <div className={classes.linksWrapper}>
      {location.instagramLink && (
      <a href={location.instagramLink}>
        <img className={classes.linkButton} src={InstagramIcon} alt="InstagramLink" />
      </a>
      )}
      {location.facebookLink && (
      <a href={location.facebookLink}>
        <img className={classes.linkButton} src={FacebookIcon} alt="FacebookLink" />
      </a>
      )}
      {location.websiteLink && (
      <a href={location.websiteLink}>
        <img className={classes.linkButton} src={WebIcon} alt="WebsiteLink" />
      </a>
      )}
      {location.phoneNumber && (
      <a href={`tel:${location.phoneNumber}`}>
        <img className={classes.linkButton} src={TephoneIcon} alt="PhoneLink" />
      </a>
      )}
    </div>
  );
};

export default InfoPanel;