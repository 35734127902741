import {
  remove, get, patch, post, processError, put
} from '../Http';
import { TournamentQuery } from '../../models/pages/tournament/TournamentQuery';
import Status from '../../models/pages/tournament/Status';
import TournamentStatus from '../../models/pages/tournament/TournamentStatus';
import AdminTournamentUpdateRequest from '../../models/pages/tournament/AdminTournamentUpdateRequest';
import AddTournament from '../../models/pages/tournament/AddTournament';
import PairsCaptures from '../../models/pages/tournament/captures/PairsCaptures';
import PlayoffResult from '../../models/pages/tournament/playoff/PlayoffResult';
import PublishTournament from '../../models/pages/tournament/PublishTournament';
import Error from '../../models/service/Error';
import ParticipantNumber from '../../models/pages/tournament/ParticipantNumber';

const url = '/api/Tournaments/';
const options = {
  headers: { 'Content-Type': 'application/json' },
};

const generateError = (error: Error) => {
  if (error.response.status !== 404) {
    return processError(error);
  }
  return null;
};

const TournamentService = {
  async get() {
    return get(url);
  },

  async getParticipants(id: number) {
    return get(`${url}${id}/participants`);
  },

  async getAllExistingRegistrations(id: number) {
    return get(`${url}${id}/registrations`);
  },

  async getAwaitingParticipants(id: number) {
    return get(`${url}${id}/registrations/waitList`);
  },

  async getById(id: number) {
    return get(`${url}${id}`);
  },

  async getStatuses() {
    return get(`${url}statuses`);
  },

  async getStatusesAsSelectItems() {
    const response = await TournamentService.getStatuses();
    if (!response) {
      return [];
    }
    return response.map((item: Status) => ({
      id: item.status,
      name: item.description,
    }));
  },

  async getAllTournaments() {
    return get(`${url}all`);
  },

  async getPdfCards(id: number) {
    return get(`${url + id}/cards/pdf`);
  },

  async getTableData(id: number) {
    return get(`${url + id}/table`);
  },

  async getRegistratrionStatus(id: number) {
    return get(`${url + id}/registration`, generateError);
  },

  async getPlayoffRounds(id: number) {
    return get(`${url + id}/table/play-off`, generateError);
  },

  async getErrorRounds(id: number) {
    return get(`${url + id}/table/errors`);
  },

  async getFinalTournamentResult(id: number) {
    return get(`${url + id}/table/final`);
  },

  async getLatestFinishedTournaments(count: number) {
    return get(`${url}latest-finished/${count}`);
  },

  async getClosestTournaments() {
    return get(`${url}closests`);
  },

  async getTournamentInfo(id: number) {
    return get(`${url + id}/info`);
  },

  async post(tournament: AddTournament) {
    return post(url, tournament);
  },

  async registerUserOnTournament(id: number) {
    return post(`${url + id}/registration`);
  },

  async setTournamentSponsors(data: {
    tournamentId: number,
    sponsorsIds: number[] | [],
    generalSponsorId: number | null,
  }) {
    return post(`${url + data.tournamentId}/sponsors`, data);
  },

  async generateDrawingCouples(id: number) {
    return post(`${url + id}/drawing/generate`);
  },

  async assignRandomNumbers(id: number) {
    return post(`${url + id}/participants/numbers/randomize`);
  },

  async postPairsCaptures(data: PairsCaptures) {
    const id = data.tournamentId;
    return post(`${url + id}/table/captures`, data);
  },

  async postQuarterFinals(id: number) {
    return post(`${url + id}/table/play-off/quarterfinals`);
  },

  async postRoundResults(data: PlayoffResult) {
    const id = data.tournamentId;
    return post(`${url + id}/table/play-off/captures`, data);
  },

  async calculateQualificationResults(id: number) {
    return post(`${url + id}/qualification/results`);
  },

  async setIsPublish(data: PublishTournament) {
    return post(`${url + data.tournamentId}/publish`, data);
  },

  async patchImages(tournamentImages: any) {
    return patch(`${url}${tournamentImages.tournamentId}/images`, tournamentImages);
  },

  async update(tournament: AdminTournamentUpdateRequest) {
    const { id } = tournament;
    return put(`${url + id}`, tournament);
  },

  async updateStatus(status: TournamentStatus) {
    return put(`${url}${status.tournamentId}/status`, JSON.stringify(status.status), options);
  },

  async resetRandomNumbers(id: number) {
    return put(`${url + id}/rollback`);
  },

  async updateNumbers(tournamentId: number, participantNumbers: ParticipantNumber[]) {
    return put(`${url + tournamentId}/participants/numbers`, participantNumbers, options);
  },

  async delete(id: number) {
    return remove(`${url + id}`);
  },

  async cancelRegistration(id: number) {
    return remove(`${url + id}/registration/cancel`);
  },

  async getAvailableRegistrations(id: number, params: TournamentQuery) {
    return get(`${url + id}/registrations/available`, undefined, { params });
  },
};

export default TournamentService;