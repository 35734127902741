import React from 'react';
import { List, Button, Grid } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import Country from '../../../../../models/components/dictionaries/country/Country';
import AreatroutModal from '../../../../../components/modal/Modal';
import AddOrEditCountryModal from './modals/AddOrEditCountry';
import DeleteCountryModal from './modals/DeleteCountry';
import AreatroutTable from '../../../../../components/table';
import EditDeleteCell from './editDeleteCell/EditDeleteCell';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import useStyles from './Countries.style';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Countries = observer(() => {
  const { CountriesStore } = useStores();
  const classes = useStyles();
  const l10n = useTranslation();

  const columns = [
    {
      displayName: l10n.components.countries.COUNTRY_NAME,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.countries.CONTROL_NAME,
      fieldName: 'action',
      allowSortring: false,
      allowSearch: false,
      render: (data: Country) => (
        <EditDeleteCell data={data} />
      ),
    },
  ];

  if (CountriesStore.isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <>
      <AreatroutModal
        header={l10n.components.countries.ADD_COUNTRY_HEADER}
        open={CountriesStore.isAddModalOpen}
        setOpen={() => { }}
        onClose={CountriesStore.toggleAddCountryModalState}
      >
        <AddOrEditCountryModal />
      </AreatroutModal>
      <AreatroutModal
        header={l10n.components.countries.DELETE_HEADER}
        open={CountriesStore.isDeleteModalOpen}
        setOpen={() => { }}
        onClose={CountriesStore.toggleDeleteCountryModalState}
      >
        <DeleteCountryModal />
      </AreatroutModal>
      <AreatroutModal
        header={l10n.components.countries.EDIT_HEADER}
        open={CountriesStore.isEditModalOpen}
        setOpen={() => { }}
        onClose={CountriesStore.toggleEditCountryModalState}
      >
        <AddOrEditCountryModal />
      </AreatroutModal>
      <Button
        data-testid="add-button"
        variant="contained"
        color="primary"
        className={classes.buttonAdd}
        startIcon={<AddCircleOutline />}
        onClick={CountriesStore.openAddCountryModal}
      >
        {l10n.components.countries.ADD_BUTTON}
      </Button>
      <Grid className={classes.tableWidth}>
        <List>
          {CountriesStore.countries?.length ? (
            <AreatroutTable columns={columns} rows={CountriesStore.countries} />
          ) : (
            <p>{l10n.components.countries.EMPTY_COUNTRY_LIST}</p>
          )}
        </List>
      </Grid>
    </>
  );
});

export default Countries;