import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  accordion: {
    borderBottom: '1px solid rgba(226, 228, 240, 1)',
    borderTop: 'none',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    '& > div > .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '& > div > .MuiAccordionSummary-root': {
      minHeight: 0,
      padding: '0px 18px'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  accordionItemsContainer: {
    flexDirection: 'column',
    padding: 0,
    marginBottom: 8
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      fontWeight: 600,
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      padding: 0,
      margin: '8px 0'
    },
    '& > div > p': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit'
    }
  },
  active: {
    backgroundColor: 'rgba(13, 169, 178, 0.3)'
  },
  ATCheckbox: {
    width: '100%'
  },
  langButton: {
    border: 0,
    background: 'transparent',
    color: 'white',
    fontSize: 14,
    cursor: 'pointer',
  },
  basicText: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '135%',
  },
  deleteItems: {
    position: 'absolute',
    right: 40,
    top: 12,
    width: 24,
    height: 24,
    zIndex: 100,
    border: 'none',
    borderRadius: '50%',
    background: 'inherit',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    },
    '&:active': {
      backgroundColor: 'rgba(13, 169, 178, 0.3)'
    }
  },
  label: {
    width: '100%'
  },
  basicSelectLabel: {
    width: '100%'
  },
  disabledListItem: {
    cursor: 'auto',
    opacity: 0.5,
    width: '100%',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:active, &:focus': {
      backgroundColor: 'inherit',
    },
    '& > label': {
      width: '100%',
    }
  },
  listItem: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    },
    '&:active, &:focus': {
      backgroundColor: 'rgba(13, 169, 179, 0.3)',
    },
    '& > label': {
      width: '100%',
    }
  },
  list: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'inherit',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    }
  },
  menuItemChecked: {
    '& .MuiSvgIcon-root > path': {
      height: 18,
      width: 18,
      borderRadius: 4,
      color: 'rgba(13, 169, 178, 1)'
    }
  },
  menuItemContainer: {
    height: 40,
    paddingLeft: 8,
    [theme.breakpoints.down('xs')]: {
      height: 32,
      minHeight: 0,
      paddingLeft: 24
    },
  },
  customItem: {
    height: 40,
    paddingLeft: 8,
  },
  menuItem: {
    '& .MuiSvgIcon-root': {
      boxSizing: 'border-box',
      height: 18,
      width: 18,
      borderRadius: 4,
      border: '1px solid grey'
    },
    '& .MuiSvgIcon-root > path': {
      display: 'none'
    }
  },
  selectContainerWrapper: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '135%',
    },
    '& .MuiOutlinedInput-input': {
      padding: '13px 72px 13px 16px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      '&.mobileSelect': {
        display: 'block'
      }
    },
  },
  select: {
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      width: 320,
    },
    '& .MuiSelect-iconOutlined': {
      right: 13
    },
    li: {
      paddingRight: 10
    }
  },
  basicSelect: {
    width: '100%',
    '& .MuiSelect-iconOutlined': {
      right: 13
    }
  },
  languageSelect: {
    width: 80,
    '& > .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '2px 40px 2px 10px'
    },
  },
  languageMenuItem: {
    height: 25
  },
  selectContainer: {
    borderRadius: 8,
    '& > .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& > .MuiOutlinedInput-input': {
      padding: '14px 14px'
    }
  },
  customSelect: {
    borderRadius: 8,
    position: 'relative',
    '& > .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& > .MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 14px',
      height: 35,
    }
  },
  singleItem: {
    paddingLeft: 24,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12
    },
  },
  singleTeamItem: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    },
  },
  disabledList: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'inherit',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'inherit',
    }
  },
  singlelist: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(13, 169, 178, 0.3)',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    }
  },
  text: {
    '& > span': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '135%',
    }
  },
  loader: {
    width: '100vw'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  langItemContainer: {
    margin: 0,
    marginLeft: 10,
    display: 'inline-block',
  },
  placeholder: {
    margin: 0,
    marginLeft: 15,
    color: colors.grey
  },
  itemData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarContainer: {
    margin: '0px 40px 0px 10px'
  },
  border: {
    border: `1px solid ${colors.grey}`,
    borderRadius: 8,
  },
  jsxComponent: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
    zIndex: 9999
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.8,
    width: '100%',
    zIndex: 0
  },
  popover: {
    padding: 20,
    borderRadius: 8,
    color: colors.primaryLight
  }
}));

export default useStyles;