import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  fullWidth: {
    maxWidth: 1400,
    width: '100%',
  },
  logo: {
    width: 100,
  },
  link: {
    color: '#4833b9',
  },
  sponsorImg: {
    maxWidth: 200,
    maxHeight: 200,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  carousel: {
    width: '100%',
    '& .rec.rec-arrow': {
      [theme.breakpoints.down('sm')]: {
        lineHeight: 1,
        minWidth: 30,
        width: 30,
        minHeight: 30,
        height: 30,
      },
    },
  },
  wrapperSelectedSponsors: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
    justifyContent: 'space-evenly',
    gap: 50,
  },
  wrapper: {
    width: 750,
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    textAlign: 'center',
    alignItems: 'center',
  },
  generalWrapper: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },
  resetButton: {
    marginBottom: 20,
    alignSelf: 'flex-end',
  },
  saveButton: {
    marginTop: 20,
    marginBottom: 20,
    float: 'right',
  },
  radioBtn: {
    '& .MuiRadio-colorPrimary': {
      color: 'blue',
    }
  }
}));

export default useStyles;