import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  header: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '60px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 44,
      lineHeight: '50px',
      marginBottom: 15
    },
  },
  articles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 20,
    marginBottom: 25,
    marginTop: 48,
    [theme.breakpoints.down('sm')]: {
      gridTemplate: 'repeat(2, 1fr)/repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 330,
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateRows: 'repeat(3, 1fr)',
    },
  },
  subHeader: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    color: 'black',
    textDecoration: 'none',
    '&:active': {
      textDecoration: 'underline',
      color: 'rgba(0, 51, 183, 1)',
    },
  },
  subHeaderWrapper: {
    maxHeight: 60,
  },
  headerPadding: {
    display: 'flex',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 33,
    },
  },
  button: {
    marginTop: 25,
    [theme.breakpoints.down('md')]: {
      margin: '8px 0px 38px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  newsWrapper: {
    maxWidth: 1360,
    width: '100%',
  },
  articleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& img': {
      objectFit: 'cover'
    }
  },
  typeOfPublication: {
    position: 'absolute',
    width: 70,
    textAlign: 'center',
    borderRadius: 10,
  },
  articleSize: {
    height: 450,
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: 328,
      marginTop: 26,
    },
  },
  imageComponentTag: {
    position: 'absolute',
    top: 7,
    left: 10
  },
  withoutImageComponentTag: {
    top: 7,
    left: 10,
    position: 'absolute',
    textAlign: 'center',
    borderRadius: 10,
  },
  image: {
    maxWidth: '100%',
    maxHeight: 200,
    borderRadius: 8,
    display: 'block',
  },
  textWrapperSmall: {
    WebkitLineClamp: 3,
  },
  textWrapperLarge: {
    WebkitLineClamp: 10,
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  userName: {
    color: 'rgba(0, 51, 183, 1)',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginRight: 10,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      color: 'black',
    },
  },
  date: {
  },
  footerWrapper: {
    marginTop: 10,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  basicTextStyle: {
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    lineHeight: '24px',
  },
  hideContent: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    wordWrap: 'break-word',
    marginBlockStart: 0,
    marginBlockEnd: 0,
  }
}));

export default useStyles;