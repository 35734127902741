import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  closeButton: {
    position: 'absolute',
    marginLeft: 'auto',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    height: 50,
  },
  backArrow: {
    color: 'black',
    position: 'absolute',
    height: 50,
    top: 'calc(50% - 3vh)',
  },
  forwardArrow: {
    color: 'black',
    position: 'absolute',
    height: 50,
    top: 'calc(50% - 3vh)',
    left: 'calc(100% - 50px)',
    [theme.breakpoints.down('xs')]: {
      left: 'calc(85% - 1vw)',
    },
  },
  imageControl: {
    backgroundColor: 'rgba(244,245,247, 0.4)',
    '&:hover': {
      backgroundColor: 'rgba(244,245,247, 0.9)',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '90vh',
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 1000
    },
  }
}));

export default useStyles;