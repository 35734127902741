import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, TextField, Grid, FormHelperText
} from '@material-ui/core';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../../stores/Main';
import ConfigurationTable from '../../table/configuration/ConfigurationTable';
import TournamentTemplate from '../../../../../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';
import schema from '../../helpers/ValidationSchema';
import useStyles from '../../Template.style';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const AddTemplateModal = observer((props: {
  handleClose?: EmptyVoidFunction
}) => {
  const { handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TournamentTemplate>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { TournamentTemplatesStore } = useStores();
  const addNewTemplate = async (data: TournamentTemplate) => {
    const response = await TournamentTemplatesStore.addTemplate(data);
    if (response) {
      Toasters.success(l10n.components.tournamentsTemplate.ADD_TEMPLATE_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (TournamentTemplatesStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <div className={classes.modalWrapper}>
      <form onSubmit={handleSubmit(addNewTemplate)} className={classes.contentWrapper}>
        <Controller
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label={l10n.components.tournamentsTemplate.TEMPLATE_NAME}
                variant="outlined"
                error={!!errors.name}
                autoFocus
                inputProps={{ 'data-testid': 'name' }}
              />
              <FormHelperText data-testid="nameError" className={classes.invalid}>
                {!!errors?.name && errors?.name?.message}
              </FormHelperText>
            </>
          )}
          name="name"
          control={control}
          defaultValue=""
        />
        <Controller
          render={({ field }) => (
            <>
              <TextField
                {...field}
                className={classes.contentInput}
                label={l10n.components.tournamentsTemplate.ROUNDS_COUNT}
                error={!!errors.roundsCount}
                variant="outlined"
                inputProps={{ 'data-testid': 'roundsCount' }}
              />
              <FormHelperText data-testid="nameError" className={classes.invalid}>
                {!!errors?.roundsCount && errors?.roundsCount?.message}
              </FormHelperText>
            </>
          )}
          name="roundsCount"
          control={control}
        />
        <ConfigurationTable control={control} errors={errors} isEditable={false} />
        <Controller
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label={l10n.components.tournamentsTemplate.TEMPLATE_DESCRIPTION}
                variant="outlined"
                className={classes.contentInput}
                error={!!errors.description}
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'description' }}
              />
              <FormHelperText data-testid="nameError" className={classes.invalid}>
                {!!errors?.description && errors?.description?.message}
              </FormHelperText>
            </>
          )}
          name="description"
          control={control}
          defaultValue=""
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={clsx(classes.saveButton, classes.contentInput)}
          data-testid="saveBtn"
        >
          {l10n.components.tournamentsTemplate.SAVE_BUTTON}
        </Button>
      </form>
    </div>
  );
});

export default AddTemplateModal;