import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    marginTop: 15,
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pictureWrapper: {
    width: 250,
    margin: 'auto',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0px',
    },
  },
  linksWrapper: {
    marginTop: 15,
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: '48px',
    fontWeight: 'bold',
    color: colors.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px'
    },
  },
  country: {
    marginBottom: 15,
    fontSize: 20
  },
  linkButton: {
    width: 50,
  },
  previewImg: {
    width: 250,
  },
}));

export default useStyles;