import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  title: {
    width: 'fit-content',
    margin: '0 auto',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '38px',
    color: colors.secondary,
    marginBottom: 17
  },
  text: {
    width: '100%',
    fontSize: 20,
    lineHeight: '26px',
    margin: '20px 0 4px'
  },
  tables: {
    padding: '0 48px',
    overflowY: 'auto',
    minHeight: '50vh',
    maxHeight: 'calc(100vh - 400px)',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
      maxHeight: 'calc(100vh - 185px)'
    },
  },
  button: {
    minHeight: 94,
    padding: '20px 0 32px'
  }
}));

export default useStyles;