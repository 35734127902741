import React, { useState } from 'react';
import clsx from 'clsx';
import Modal from '../../../../../../../components/modal/Modal';
import useStyles from './PointCalculationInfo.styles';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';
import RTEtext from '../../../../../../../components/RTEtext/RTEtext';

const defaultProps = {
  className: '',
};

const PointsCalculationInfo = (props: {
  className?: string,
}) => {
  const { className } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const l10n = useTranslation();
  return (
    <>
      <button
        className={clsx(classes.button, className)}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {l10n.components.button.SCORE_COUNT_DESCRIPTION}
      </button>
      <Modal header="" open={isOpen} setOpen={setIsOpen} modalClassName={classes.modal}>
        <div className={classes.wrapper}>
          <RTEtext text={l10n.pages.SCORE_COUNT_DESCRIPTION} />
        </div>
      </Modal>
    </>
  );
};

export default PointsCalculationInfo;

PointsCalculationInfo.defaultProps = defaultProps;