import React from 'react';
import useStyles from './Button.styles';
import { EmptyVoidFunction } from '../../utils/types/Types';

const defaultProps = {
  onClick: () => { },
  disabled: false,
  className: '',
  isSubmit: false,
  testId: ''
};

const Button = (props: {
  children: any,
  variant: string,
  fill: string,
  size: string
  disabled?: boolean,
  onClick?: EmptyVoidFunction,
  className?: string,
  isSubmit?: boolean,
  testId?: string
}) => {
  const classes = useStyles();
  const {
    children, variant, fill, size, disabled, onClick, className, isSubmit, testId
  } = props;

  return (
    <button
      className={`${classes.button} ${variant} ${fill} ${size} ${className}`}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </button>
  );
};

export default Button;

Button.defaultProps = defaultProps;