import React, { useState, useCallback } from 'react';
import ConfirmationDialog from './confirmationDialog/ConfirmationDialog';
import { EmptyVoidFunction } from '../../utils/types/Types';
import Modal from '../modal/Modal';

const defaultProps = {
  onCancelClick: () => {},
  cancelButtonText: '',
  headerText: '',
  className: '',
  dialogClassName: '',
};

export default function useConfirmationDialog(props: {
  headerText: string;
  bodyText: string | JSX.Element;
  confirmationButtonText: string;
  onConfirmClick: EmptyVoidFunction;
  cancelButtonText?: string;
  onCancelClick?: EmptyVoidFunction;
  className?: string
  dialogClassName?: string
}) {
  const {
    headerText, bodyText, confirmationButtonText, onConfirmClick, onCancelClick, cancelButtonText, className, dialogClassName
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const Dialog = useCallback(
    () => (
      <Modal header={headerText} open={isOpen} setOpen={setIsOpen} modalClassName={className}>
        <ConfirmationDialog
          bodyText={bodyText}
          onConfirmClick={onConfirmClick}
          confirmationButtonText={confirmationButtonText}
          cancelButtonText={cancelButtonText}
          onCancelClick={onCancelClick}
          dialogClassName={dialogClassName}
        />
      </Modal>
    ),
    [isOpen],
  );

  return {
    Dialog,
    onOpen,
    onClose,
  };
}

useConfirmationDialog.defaultProps = defaultProps;