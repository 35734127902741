import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  winners: {
    minHeight: 86,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    listStyleType: 'none',
    margin: 0,
    padding: '20px 50px 10px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px 10px',
      justifyContent: 'space-around',
    },
    boxSizing: 'border-box',
    background: 'rgba(226, 228, 240, 0.35)',
    borderRadius: '0px 0px 8px 8px',
  },
  winner: {
    '&.winner-1': {
      order: 2,
      '& .MuiAvatar-root': {
        width: 48,
        height: 48
      }
    },
    '&.winner-2': {
      order: 1,
      '& .MuiAvatar-root': {
        width: 42,
        height: 42
      }
    },
    '&.winner-3': {
      order: 3,
      '& .MuiAvatar-root': {
        width: 33,
        height: 33
      }
    }
  },
  winnerLogo: {
    marginRight: 7,
  },
  winnerPlace: {
    marginLeft: 4,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '26px'
  },
  winnerName: {
    margin: '0 auto',
    maxWidth: 84,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    paddingBottom: 6,
  },
  wrapper: {
    padding: 8,
    color: colors.secondary,
  },
  divider: {
    backgroundColor: '#E2E4F0',
    margin: '6px 0'
  },
  userInfo: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': {
      whiteSpace: 'normal',
      margin: '0 auto',
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      fontWeight: 400
    },
    '& > div:last-child': {
      height: 32
    },
  },
  userNameContainer: {
    whiteSpace: 'normal',
  },
  place: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

}));

export default useStyles;