import React from 'react';
import clsx from 'clsx';
import useStyles from '../../../Playoff.styles';
import SemiFinalCard from './SemiFinalCard';
import EmptyCard from '../../../components/Empty';
import ModifiedPlayoffRound from '../../../../../../../../models/pages/tournament/playoff/ModifiedPlayoffRound';
import RoundResult from '../../../../../../../../models/pages/tournament/playoff/RoundResult';

const defaultProps = {
  round: null
};

function SemiFinalRounds(props: {
  round?: ModifiedPlayoffRound | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean
}) {
  const classes = useStyles();
  const {
    round, setParticipant, winnerId, isEditMode
  } = props;
  return (
    <>
      <div className={clsx(classes.coupleWrapper, classes.flexRow)}>
        <div className={clsx(classes.flexColumn, classes.innerWrapper)}>
          <div className={classes.flexRow}>
            <svg className={clsx(classes.semiFinalsLine, classes.semiFinalsUpperLine)}>
              <line x1="0" y1="0" x2="160" y2="62" className={classes.line} />
            </svg>
            {round
              ? (
                <SemiFinalCard
                  player={round.firstParticipant}
                  setParticipant={setParticipant}
                  winnerId={winnerId}
                  isEditMode={isEditMode}
                />
              )
              : <EmptyCard />}
          </div>
          <div className={classes.flexRow}>
            <svg className={clsx(classes.semiFinalsLine, classes.semiFinalsLowerLine)}>
              <line x1="0" y1="80" x2="160" y2="13" className={classes.line} />
            </svg>
            {round
              ? (
                <SemiFinalCard
                  player={round.secondParticipant}
                  setParticipant={setParticipant}
                  winnerId={winnerId}
                  isEditMode={isEditMode}
                />
              )
              : <EmptyCard />}
          </div>
        </div>
      </div>
    </>
  );
}

SemiFinalRounds.defaultProps = defaultProps;

export default SemiFinalRounds;