import React from 'react';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AddCircleOutline } from '@material-ui/icons';
import useStyles from './Locations.styles';
import LocationsTable from './table/LocationsTable';
import AddLocation from './modals/addLocation/AddLocation';
import Modal from '../../../../../components/modal/Modal';
import DeleteLocationConfirm from './modals/deleteLocation/DeleteLocation';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import { useStores } from '../../../../../stores/Main';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Locations = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { LocationStore } = useStores();

  const addFormClose = () => {
    LocationStore.toggleAddLocationModalState();
  };

  const deleteFormClose = () => {
    LocationStore.toggleDeleteLocationModalState();
  };

  if (LocationStore.isLoading) {
    return (<LoaderFullScreen />);
  }
  return (
    <div className={classes.locationsWrapper}>
      <div className={classes.addButton}>
        <Button
          data-testid="add-button"
          color="primary"
          startIcon={<AddCircleOutline />}
          variant="contained"
          onClick={LocationStore.toggleAddLocationModalState}
        >
          {l10n.components.locations.LOCATION_ADD_BUTTON}
        </Button>
      </div>
      <Modal
        header={l10n.components.locations.LOCATION_ADD_MODAL}
        open={LocationStore.isAddModalOpen}
        setOpen={() => { }}
        className={classes.modalWide}
        modalClassName={classes.modal}
        onClose={addFormClose}
      >
        <AddLocation />
      </Modal>
      <Modal
        header={l10n.components.locations.LOCATION_DELETE_MODAL}
        open={LocationStore.isDeleteModalOpen}
        setOpen={() => { }}
        className={classes.modalWide}
        onClose={deleteFormClose}
      >
        <DeleteLocationConfirm />
      </Modal>
      <LocationsTable />
    </div>
  );
});

export default Locations;