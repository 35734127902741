import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStylesMember = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 3
  },
  userName: {
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    textAlign: 'start',
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 6,
      width: 'min-content',
      alignItems: 'center',
      fontFamily: "'Source Sans Pro', sans-serif",
      lineHeight: '22px',
      textAlign: 'center'
    }
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5
  }
}));

const useStylesAvatar = makeStyles(() => createStyles({
  avatar: {
    width: 32,
    height: 32,
    '&.medium': {
      width: 48,
      height: 48
    },
  },
  flag: {
    width: 16,
    height: 16,
    border: '1px solid #FFF',
    borderRadius: '50%'
  }
}));

export { useStylesMember, useStylesAvatar };