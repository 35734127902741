import React from 'react';
import { IconButton, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../stores/Main';
import DeleteTournament from '../delete/Delete';
import Routes from '../../../../routing/Routes';
import { EmptyVoidFunction } from '../../../../utils/types/Types';

const Management = observer((props: {
  tournamentId: number,
  getTournament: EmptyVoidFunction
}) => {
  const { tournamentId, getTournament } = props;
  const { TournamentStore } = useStores();

  const defaultHashAtTournamentPage = '#tournament-data';

  return (
    <Grid container justify="space-around">
      <Link to={{
        pathname: Routes.EditTournaments.path.replace(':id', String(tournamentId)),
        hash: defaultHashAtTournamentPage,
      }}
      >
        <IconButton
          edge="start"
          aria-label="edit"
          onClick={TournamentStore.resetTournament}
        >
          <Edit />
        </IconButton>
      </Link>
      <DeleteTournament getTournament={getTournament} id={tournamentId} />
    </Grid>
  );
});

export default Management;