import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';

interface ProtectedRouteProps {
  isAccessAllowedFunc?: () => boolean
}

function ProtectedRoute({ isAccessAllowedFunc }: ProtectedRouteProps) {
  if (isAccessAllowedFunc && !isAccessAllowedFunc()) {
    return (<Navigate to="/" />);
  }
  return (<Outlet />);
}

ProtectedRoute.defaultProps = {
  isAccessAllowedFunc: () => true
};

export default ProtectedRoute;