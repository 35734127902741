import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Checkbox,
  ListItemText,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import schema from './helpers/ValidationSchema';
import TournamentService from '../../../../services/main/Tournament.service';
import RatingService from '../../../../services/main/dictionaries/Rating.service';
import SeasonsService from '../../../../services/main/dictionaries/Season.service';
import FormatService from '../../../../services/main/dictionaries/Format.service';
import RankingService from '../../../../services/main/dictionaries/Ranking.service';
import useStyles from './Add.styles';
import Season from '../../../../models/components/dictionaries/season/Season';
import Rating from '../../../../models/components/dictionaries/rating/Rating';
import Ranking from '../../../../models/components/dictionaries/ranking/Ranking';
import TournamentFormat from '../../../../models/pages/tournament/TournamentFormat';
import AddTournament from '../../../../models/pages/tournament/AddTournament';
import DateTimePicker from '../../../../components/dateTimePicker/DateTimePicker';
import Toasters from '../../../../components/popUp/PopUp';
import Loader from '../../../../components/loader/Loader';
import { getRatingsIds, toISODate } from '../../../../utils/Utils';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import { useStores } from '../../../../stores/Main';
import TournamentTemplate from '../../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

function AddForm(props: {
  handleClose?: EmptyVoidFunction;
  getAllTournaments: EmptyVoidFunction;
}) {
  const { handleClose, getAllTournaments } = props;
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      date: moment().format('YYYY-MM-DD'),
      ratingIds: [],
      locationId: undefined,
      rankingId: undefined,
      seasonId: undefined,
      formatId: undefined,
      regulationsLink: '',
      participantsLimit: 0,
      rulesLink: '',
      templateId: undefined,
      endRegistrationDate: moment().format('YYYY-MM-DD HH:mm'),
      shouldRegistrationCloseAutomatically: false,
    },
  });

  const classes = useStyles();
  const l10n = useTranslation();
  const { TournamentTemplatesStore, LocationStore } = useStores();
  const [ratings, setRatings] = useState<Rating[] | null>([]);
  const [ranking, setRanking] = useState<Ranking[] | null>([]);
  const [formats, setFormats] = useState<TournamentFormat[] | null>([]);
  const [seasons, setSeasons] = useState<Season[] | null>([]);
  const [ratingsList, setRatingsList] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    setRatingsList(event.target.value);
  };

  const setData = async (data: AddTournament) => {
    setIsLoaded(true);

    const response = await TournamentService.post(data);
    if (response) {
      Toasters.success(l10n.components.toasterMessage.TOURNAMENT_HAS_BEEN_ADDED);
    }
    getAllTournaments();
    setIsLoaded(false);
    if (handleClose) {
      handleClose();
    }
  };

  const transformData = (data: any) => {
    const result = { ...data };
    result.date = toISODate(data.date);
    result.ratingIds = getRatingsIds(ratings, data.ratingIds);
    setData(result);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      setSeasons(await SeasonsService.getAll());
      setFormats(await FormatService.get());
      setRanking(await RankingService.getAll());
      setRatings(await RatingService.get());
      setIsLoaded(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setValue('ratingIds', ratingsList);
  }, [ratingsList]);

  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form
      className={classes.editWrapper}
      autoComplete="off"
      onSubmit={handleSubmit(transformData)}
      data-testid="newTournament"
    >
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], { [classes.invalid]: errors?.name })}
          >
            <p className={classes.label}>{l10n.pages.NOMINATION}</p>
            <div>
              <TextField
                {...field}
                autoFocus
                className={classes.inputField}
                error={!!errors.name}
                type="string"
                size="small"
                variant="outlined"
                multiline
                inputProps={{ 'data-testid': 'name' }}
              />
              <FormHelperText data-testid="nameError" className={clsx(classes.invalid, classes.error)}>
                {!!errors?.name && errors?.name.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="name"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.locationId,
            })}
            variant="outlined"
            size="small"
          >
            <p className={classes.label}>{l10n.pages.LOCATION}</p>
            <div>
              <Select
                {...field}
                className={classes.inputField}
                value={field.value}
                onChange={field.onChange}
                native
                multiline
                inputProps={{ 'data-testid': 'location' }}
              >
                <option aria-label="None" value="" />
                {LocationStore.locations?.length && LocationStore.locations.map((data: any) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Select>
              <FormHelperText className={clsx(classes.invalid, classes.error)} data-testid="locationError">
                {!!errors?.locationId && errors?.locationId.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="locationId"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.formatId,
            })}
            variant="outlined"
            size="small"
          >
            <p className={classes.label}>{l10n.pages.FORMAT}</p>
            <div>
              <Select
                {...field}
                className={classes.inputField}
                value={field.value}
                onChange={field.onChange}
                native
                inputProps={{ 'data-testid': 'format' }}
              >
                <option aria-label="None" value="" />
                {formats && formats.length && formats.map((data: any) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Select>
              <FormHelperText className={clsx(classes.invalid, classes.error)} data-testid="formatError">
                {!!errors?.formatId && errors?.formatId.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="formatId"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.rankingId,
            })}
            variant="outlined"
            size="small"
          >
            <p className={classes.label}>{l10n.pages.RANKING}</p>
            <div>
              <Select
                {...field}
                className={classes.inputField}
                value={field.value}
                onChange={field.onChange}
                native
                inputProps={{ 'data-testid': 'ranking' }}
              >
                <option aria-label="None" value="" />
                {ranking && ranking.length && ranking.map((data: any) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Select>
              <FormHelperText className={clsx(classes.invalid, classes.error)} data-testid="rankingError">
                {!!errors?.rankingId && errors?.rankingId.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="rankingId"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.seasonId,
            })}
            variant="outlined"
            size="small"
          >
            <p className={classes.label}>{l10n.pages.SEASON}</p>
            <div>
              <Select
                {...field}
                className={classes.inputField}
                value={field.value}
                onChange={field.onChange}
                native
                inputProps={{ 'data-testid': 'season' }}
              >
                <option value=""> </option>
                {seasons && seasons.length && seasons.map((data: any) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Select>
              <FormHelperText data-testid="seasonError" className={clsx(classes.invalid, classes.error)}>
                {!!errors?.seasonId && errors?.seasonId.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="seasonId"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.participantsLimit,
            })}
          >
            <p className={classes.label}>{l10n.pages.PARTICIPANTS_LIMIT}</p>
            <div>
              <TextField
                {...field}
                className={classes.inputField}
                type="number"
                variant="outlined"
                size="small"
                inputProps={{ 'data-testid': 'quote' }}
              />
              <FormHelperText data-testid="quoteError" className={classes.invalid}>
                {!!errors.participantsLimit && errors?.participantsLimit.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="participantsLimit"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], { [classes.invalid]: errors?.date })}
          >
            <p className={classes.label}>{l10n.pages.DATE}</p>
            <div>
              <TextField
                {...field}
                className={classes.inputField}
                inputProps={{ 'data-testid': 'date' }}
                type="date"
                size="small"
                variant="outlined"
              />
              <FormHelperText data-testid="dateError" className={clsx(classes.invalid, classes.error)}>
                {!!errors.date && errors?.date.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="date"
        control={control}
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <FormControl
            className={clsx([classes.inputRow], { [classes.invalid]: errors?.endRegistrationDate })}
          >
            <p className={classes.label}>{l10n.pages.END_REGISTRATION_DATE}</p>
            <div>
              <DateTimePicker
                value={value}
                onChange={onChange}
                className={clsx(classes.inputField, classes.picker)}
                inputProps={{ 'data-testid': 'endRegistrationDate' }}
              />
              <FormHelperText data-testid="dateError" className={clsx(classes.invalid, classes.error)}>
                {!!errors.endRegistrationDate && errors?.endRegistrationDate.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="endRegistrationDate"
        control={control}
      />
      <div className={classes.inputRow}>
        <p className={classes.checkboxLabel}>{l10n.pages.AUTOMATICALLY_CLOSE_REGISTARTION}</p>
        <Controller
          name="shouldRegistrationCloseAutomatically"
          control={control}
          render={({
            field: { value, onChange }
          }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <Controller
        render={
          ({ field }) => (
            <FormControl
              variant="outlined"
              size="small"
              className={clsx(classes.inputRow, classes.rating)}
            >
              <p className={classes.label}>{l10n.pages.RATING}</p>
              <div>
                <Select
                  {...field}
                  className={classes.inputField}
                  value={ratingsList}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  onChange={handleChange}
                  multiple
                  inputProps={{ 'data-testid': 'ratings' }}
                >
                  {ratings?.length && ratings.map((data: Rating) => (
                    <MenuItem key={data.id} value={data.name}>
                      <Checkbox checked={ratingsList.indexOf(data.name) > -1} />
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </FormControl>
          )
        }
        control={control}
        name="ratingIds"
      />
      <Controller
        render={({ field }) => (
          <FormControl
            className={clsx([classes.inputRow], {
              [classes.invalid]: errors?.templateId,
            })}
            variant="outlined"
            size="small"
          >
            <p className={classes.label}>{l10n.pages.TEMPLATE}</p>
            <div>
              <Select
                {...field}
                className={classes.inputField}
                value={field.value}
                onChange={field.onChange}
                native
                multiline
                inputProps={{ 'data-testid': 'template' }}
              >
                <option aria-label="None" value="" />
                {TournamentTemplatesStore.templates?.length && TournamentTemplatesStore.templates.map((data: TournamentTemplate) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Select>
              <FormHelperText className={clsx(classes.invalid, classes.error)} data-testid="templateError">
                {!!errors?.templateId && errors?.templateId.message}
              </FormHelperText>
            </div>
          </FormControl>
        )}
        name="templateId"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl className={classes.inputRow}>
            <p className={classes.label}>{l10n.pages.REGULATIONS}</p>
            <TextField
              {...field}
              className={classes.inputField}
              type="string"
              size="small"
              variant="outlined"
              multiline
              error={!!errors.regulationsLink}
              helperText={errors.regulationsLink?.message}
              inputProps={{ 'data-testid': 'regulationsLink' }}
            />
          </FormControl>
        )}
        name="regulationsLink"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl className={classes.inputRow}>
            <p className={classes.label}>{l10n.pages.RULES}</p>
            <TextField
              {...field}
              margin="dense"
              className={classes.inputField}
              type="string"
              size="small"
              variant="outlined"
              multiline
              error={!!errors.rulesLink}
              helperText={errors.rulesLink?.message}
              inputProps={{ 'data-testid': 'rulesLink' }}
            />
          </FormControl>
        )}
        name="rulesLink"
        control={control}
      />
      <Controller
        render={({ field }) => (
          <FormControl className={classes.description}>
            <p>{l10n.pages.TOURNAMENT_DESCRIPTION}</p>
            <TextField
              {...field}
              rows={6}
              type="string"
              size="small"
              variant="outlined"
              multiline
              inputProps={{ 'data-testid': 'description' }}
            />
          </FormControl>
        )}
        name="description"
        control={control}
      />
      <Button
        className={classes.saveButton}
        type="submit"
        variant="contained"
        color="primary"
        data-testid="saveButton"
      >
        {l10n.components.button.SAVE}
      </Button>
    </form>
  );
}

export default AddForm;

AddForm.defaultProps = defaultProps;