import React, { useEffect, useState } from 'react';
import {
  IconButton, Dialog, Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import clsx from 'clsx';
import { EmptyVoidFunction } from '../../utils/types/Types';
import useStyles from './ImageThumbnail.style';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  onClose: () => { },
};

export default function ImageThumbnail(props: {
  src: string;
  onClose?: EmptyVoidFunction;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listOfImgsUrl: string[];
}) {
  const {
    onClose, open, setOpen, src, listOfImgsUrl
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const [selectedImg, setSelectedImg] = useState<number>(0);

  const handleClose = () => {
    setOpen(false);
    setSelectedImg(0);
    if (onClose) {
      onClose();
    }
  };

  const findIndexOfSelectedImg = () => listOfImgsUrl.indexOf(src);

  const prevImg = () => {
    setSelectedImg((prev) => (prev ? prev - 1 : prev));
  };
  const nextImg = () => {
    setSelectedImg((prev) => (prev !== listOfImgsUrl.length - 1 ? prev + 1 : prev));
  };

  useEffect(() => {
    setSelectedImg(findIndexOfSelectedImg());
  }, [listOfImgsUrl, open]);

  return (listOfImgsUrl
    ? (
      <Dialog open={open} onClose={handleClose} className={classes.modal}>
        <Grid container direction="row">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            disabled={selectedImg === 0}
            aria-label="backArrow"
            className={clsx(classes.backArrow, classes.imageControl)}
            onClick={prevImg}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            disabled={selectedImg === listOfImgsUrl.length - 1}
            aria-label="forwardArrow"
            className={clsx(classes.forwardArrow, classes.imageControl)}
            onClick={nextImg}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <img
            className={classes.image}
            src={listOfImgsUrl[selectedImg]}
            alt={l10n.components.imageThumbnail.PREVIEW_IMG}
          />
        </Grid>
      </Dialog>
    ) : null
  );
}

ImageThumbnail.defaultProps = defaultProps;