import React, {
  ChangeEvent, useEffect, useState
} from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ListItemText,
  Checkbox,
  TextField,
  Link,
  IconButton,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Edit, Check } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import {
  getRatingsIds, toISODate, toDate, toLocalDate
} from '../../../../../utils/Utils';
import { dateAndTimeFormat } from '../../../../../utils/constants/Constants';
import SeasonsService from '../../../../../services/main/dictionaries/Season.service';
import schema from './helpers/ValidationSchema';
import Header from '../components/Header';
import TournamentFormat from '../../../../../models/pages/tournament/TournamentFormat';
import AdminTournamentUpdateRequest from '../../../../../models/pages/tournament/AdminTournamentUpdateRequest';
import Season from '../../../../../models/components/dictionaries/season/Season';
import Rating from '../../../../../models/components/dictionaries/rating/Rating';
import Ranking from '../../../../../models/components/dictionaries/ranking/Ranking';
import RankingService from '../../../../../services/main/dictionaries/Ranking.service';
import TournamentService from '../../../../../services/main/Tournament.service';
import FormatService from '../../../../../services/main/dictionaries/Format.service';
import RatingService from '../../../../../services/main/dictionaries/Rating.service';
import useStyles from './TournamentData.styles';
import Routes from '../../../../../routing/Routes';
import EditTournamentStatus from './status/Status';
import DateTimePicker from '../../../../../components/dateTimePicker/DateTimePicker';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import ATCheckbox from '../../../../../components/checkbox/Checkbox';
import Toasters from '../../../../../components/popUp/PopUp';
import TournamentPoster from './poster/SingleImageUpload';
import ImageData from '../../../../../models/components/image/ImageData';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const EditTournamentData = observer((props: {
  isInputDisabled: boolean
}) => {
  const { TournamentStore, TournamentTemplatesStore, LocationStore } = useStores();
  const { tournament } = TournamentStore;
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const l10n = useTranslation();
  const { isInputDisabled } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [seasons, setSeasons] = useState<Season[] | null>();
  const [formats, setFormats] = useState<TournamentFormat[] | null>();
  const [rankings, setRankings] = useState<Ranking[] | null>();
  const [ratings, setRatings] = useState<Rating[] | null>(null);
  const [editRulesLink, setEditRulesLink] = useState(false);
  const [editRegulationsLink, setEditRegulationsLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [poster, setPoster] = useState<ImageData | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (tournament) {
      TournamentStore.setTournament({
        ...tournament,
        ratings: event.target.value as string[],
      });
    }
  };
  const handleEdit = async (data: AdminTournamentUpdateRequest) => {
    const ratingsListId = getRatingsIds(ratings, tournament?.ratings);
    const result = { ...data };
    result.id = tournament?.id as number;
    result.ratingIds = ratingsListId;
    result.photos = [];
    result.tournamentPictureId = poster?.id || null;
    result.date = toISODate(data.date);
    setIsLoading(true);
    const res = await TournamentService.update(result);
    if (res) {
      Toasters.success(l10n.components.toasterMessage.TOURNAMENT_HAS_BEEN_CHANGED);
      TournamentStore.getTournament(id);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (tournament) {
      setPoster(tournament?.tournamentPicture);
    }
  }, [tournament]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      setSeasons(await SeasonsService.getAll());
      setFormats(await FormatService.get());
      setRankings(await RankingService.getAll());
      setRatings(await RatingService.get());
      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    setValue('ratingIds', tournament?.ratings);
  }, [tournament?.ratings]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <div className={classes.editComponent}>
      {tournament
        && (
          <>
            <Header className={classes.header} text={`${l10n.components.routes.TOURNAMENT_EDITING} "${tournament.name}"`} />
            <Grid container spacing={2} direction="row" className={classes.editContainer}>
              <Grid container item className={classes.rightSideContainer}>
                <EditTournamentStatus />
                <TournamentPoster
                  setPoster={setPoster}
                  poster={poster}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <form onSubmit={handleSubmit(handleEdit)}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={tournament.name}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isInputDisabled}
                            type="text"
                            variant="outlined"
                            fullWidth
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            label={l10n.pages.NOMINATION}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="date"
                        control={control}
                        defaultValue={toDate(tournament.date, 'YYYY-MM-DD')}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isInputDisabled}
                            type="date"
                            variant="outlined"
                            fullWidth
                            label={l10n.pages.DATE}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.date}
                            helperText={errors.date?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="endRegistrationDate"
                        control={control}
                        defaultValue={tournament.endRegistrationDate ? toLocalDate(tournament?.endRegistrationDate, dateAndTimeFormat) : ''}
                        render={({ field: { value, onChange } }) => (
                          <DateTimePicker
                            value={value}
                            onChange={onChange}
                            error={!!errors.endRegistrationDate}
                            helperText={errors.endRegistrationDate?.message}
                            disabled={isInputDisabled}
                            label={l10n.pages.END_REGISTRATION_DATE}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="shouldRegistrationCloseAutomatically"
                        control={control}
                        defaultValue={tournament.shouldRegistrationCloseAutomatically}
                        render={({
                          field: { value, onChange }
                        }) => (
                          <ATCheckbox
                            checked={value}
                            label={l10n.pages.AUTOMATICALLY_CLOSE_REGISTARTION}
                            onChange={onChange}
                            disabled={isInputDisabled}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="participantsLimit"
                        control={control}
                        defaultValue={tournament.participantsLimit}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isInputDisabled}
                            type="number"
                            variant="outlined"
                            fullWidth
                            label={l10n.pages.PARTICIPANTS_LIMIT}
                            inputProps={{
                              min: '0',
                            }}
                            onInput={(event: ChangeEvent<HTMLInputElement>) => {
                              const { target } = event;
                              if (!target.validity.valid) {
                                target.value = '';
                              }
                              return target;
                            }}
                            error={!!errors.participantsLimit}
                            helperText={errors.participantsLimit?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{l10n.pages.SEASON}</InputLabel>
                        {seasons && (
                          <Controller
                            name="seasonId"
                            control={control}
                            defaultValue={tournament.seasonId}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isInputDisabled}
                                variant="outlined"
                                label={l10n.pages.SEASON}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {seasons
                                  && seasons.map((season) => (
                                    <MenuItem key={season.id} value={season.id}>
                                      {season.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{l10n.pages.FORMAT}</InputLabel>
                        {formats && (
                          <Controller
                            name="formatId"
                            control={control}
                            defaultValue={tournament.formatId}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isInputDisabled}
                                variant="outlined"
                                label={l10n.pages.FORMAT}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {formats
                                  && formats.map((format) => (
                                    <MenuItem key={format.id} value={format.id}>
                                      {format.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{l10n.pages.RANKING}</InputLabel>
                        {rankings && (
                          <Controller
                            name="rankingId"
                            control={control}
                            defaultValue={tournament.rankingId}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isInputDisabled}
                                variant="outlined"
                                label={l10n.pages.RANKING}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {rankings
                                  && rankings.map((ranking) => (
                                    <MenuItem key={ranking.id} value={ranking.id}>
                                      {ranking.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{l10n.pages.LOCATION}</InputLabel>
                        {LocationStore.locations && (
                          <Controller
                            name="locationId"
                            control={control}
                            defaultValue={tournament.locationId}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isInputDisabled}
                                variant="outlined"
                                label={l10n.pages.LOCATION}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {LocationStore.locations?.map((location) => (
                                  <MenuItem
                                    key={location.id}
                                    value={location.id}
                                  >
                                    {location.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                      >
                        <InputLabel>{l10n.pages.RATINGS}</InputLabel>
                        <Controller
                          name="ratingIds"
                          control={control}
                          defaultValue={tournament.ratings}
                          render={({ field }) => (
                            <Select
                              {...field}
                              disabled={isInputDisabled}
                              multiple
                              variant="outlined"
                              label={l10n.pages.RATINGS}
                              value={tournament?.ratings}
                              onChange={handleChange}
                              renderValue={(selected) => (selected as string[]).join(', ')}
                            >
                              {ratings
                                && ratings.map((rating: Rating) => (
                                  <MenuItem key={rating.id} value={rating.name}>
                                    <Checkbox
                                      checked={
                                        tournament.ratings
                                        && tournament.ratings.includes(rating.name)
                                      }
                                    />
                                    <ListItemText primary={rating.name} />
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{l10n.pages.TEMPLATE}</InputLabel>
                        {TournamentTemplatesStore.templates && (
                          <Controller
                            name="templateId"
                            control={control}
                            defaultValue={tournament.templateId || tournament.template?.id}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isInputDisabled}
                                variant="outlined"
                                label={l10n.pages.TEMPLATE}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {TournamentTemplatesStore.templates?.map((template) => (
                                  <MenuItem
                                    key={template.id}
                                    value={template.id}
                                  >
                                    {template.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="regulationsLink"
                        control={control}
                        defaultValue={tournament.regulationsLink}
                        render={({ field }) => (
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            className={classes.editWrapper}
                          >
                            <Grid item sm={9}>
                              {editRegulationsLink ? (
                                <TextField
                                  {...field}
                                  disabled={isInputDisabled}
                                  type="text"
                                  variant="outlined"
                                  fullWidth
                                  label={l10n.pages.REGULATIONS}
                                  value={field.value || ''}
                                  error={!!errors.regulationsLink}
                                  helperText={errors.regulationsLink?.message}
                                />
                              ) : (
                                <Typography className={classes.link}>
                                  {field.value ? (
                                    <Link
                                      href={field.value}
                                      target="_blank"
                                      rel="noopener"
                                    >
                                      {l10n.pages.REGULATIONS}
                                    </Link>
                                  ) : (
                                    l10n.pages.REGULATIONS_LINK_WASNT_ADDED
                                  )}
                                </Typography>
                              )}
                            </Grid>
                            <IconButton
                              className={classes.linkButton}
                              color="primary"
                              edge="start"
                              aria-label="edit"
                              disabled={!!errors.regulationsLink || isInputDisabled}
                              onClick={() => {
                                setEditRegulationsLink((s) => !s);
                              }}
                            >
                              {editRegulationsLink ? (
                                <Check aria-disabled={!isDirty || !isValid} />
                              ) : (
                                <Edit />
                              )}
                            </IconButton>
                          </Grid>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="rulesLink"
                        control={control}
                        defaultValue={tournament.rulesLink}
                        render={({ field }) => (
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            className={classes.editWrapper}
                          >
                            <Grid item sm={9}>
                              {editRulesLink ? (
                                <TextField
                                  {...field}
                                  disabled={isInputDisabled}
                                  type="text"
                                  variant="outlined"
                                  fullWidth
                                  label={l10n.pages.RULES}
                                  value={field.value || ''}
                                  error={!!errors.rulesLink}
                                  helperText={errors.rulesLink?.message}
                                />
                              ) : (
                                <Typography className={classes.link}>
                                  {field.value ? (
                                    <Link
                                      href={field.value}
                                      target="_blank"
                                      rel="noopener"
                                    >
                                      {l10n.pages.RULES}
                                    </Link>
                                  ) : (
                                    l10n.pages.RULES_LINK_WASNT_ADDED
                                  )}
                                </Typography>
                              )}
                            </Grid>
                            <IconButton
                              className={classes.linkButton}
                              color="primary"
                              edge="start"
                              aria-label="edit"
                              disabled={!!errors.regulationsLink || isInputDisabled}
                              onClick={() => {
                                setEditRulesLink((s) => !s);
                              }}
                            >
                              {editRulesLink ? <Check /> : <Edit />}
                            </IconButton>
                          </Grid>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={tournament.description}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isInputDisabled}
                            multiline
                            rows={5}
                            type="text"
                            variant="outlined"
                            fullWidth
                            label={l10n.pages.TOURNAMENT_DESCRIPTION}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          className={classes.button}
                          name="button"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isDirty && !isValid}
                        >
                          {l10n.components.button.SAVE}
                        </Button>
                        <Button
                          className={classes.button}
                          name="button"
                          type="submit"
                          variant="contained"
                          onClick={() => navigate(Routes.AdminTournaments.path)}
                        >
                          {l10n.components.button.CANCEL}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </>
        )}
    </div>
  );
});

export default EditTournamentData;