import {
  makeAutoObservable,
  runInAction
} from 'mobx';
import AvailableTournamentsForReport from '../../models/pages/availiableTournaments/AvailableTournamentsForReport';
import Report, { VisibilityStatus } from '../../models/components/report/Report';
import ReportsService from '../../services/main/Report.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class ReportsStore {
      reports: Report[] | null = null;

      report: Report | null = null;

      availableForReportTournamentsInfo: AvailableTournamentsForReport[] | [] | null = null;

      mainStore: any;

      constructor(mainStore: any) {
        this.mainStore = mainStore.mainStore;

        makeAutoObservable(this);
      }

      getReportsByUserId = async (userId: string) => {
        this.clearReports();
        const response = await ReportsService.getAllReportsByUserId(userId);
        if (response) {
          this.setReports(response);
        }
        return this.reports;
      };

      getAllByTournamentId = async (tournamentId: string) => {
        this.clearReports();
        const response = await ReportsService.getAllByTournamentId(tournamentId);
        if (response) {
          this.setReports(response);
        }
        return this.reports;
      };

      getOneReportById = async (id?: string) => {
        const response = await ReportsService.getByReportId(id || this.report!.id);
        if (response) {
          this.setReport(response);
        }
        return response;
      }

      updateVisibilityOfReport = async (statusInfo: VisibilityStatus) => {
        const response = await ReportsService.postSetPublishStatus(statusInfo);
        return response;
      }

      getAvailableTournamentsForReport = async () => {
        const response = await ReportsService.getAvailableTournamentsForReport();
        this.setTournamentsInfo(response);
        return response;
      }

      refreshReports: EmptyVoidFunction = async () => {
        const response = await ReportsService.getAll();
        if (response) {
          runInAction(() => { this.reports = response; });
        }
      };

      publishReport = async (reportId: string, reportIsPublished: boolean) => {
        const response = await ReportsService.setReportStatusOfPublication(reportId, reportIsPublished);
        return response;
      }

      deleteReport = async () => {
        const response = await ReportsService.delete(this.report!.id);
        return response;
      };

      addReport = async (data: Report) => {
        const response = await ReportsService.post(data);
        return response;
      };

      setId = (reportId: string) => {
        this.report!.id = reportId;
      }

      setReport = (report: Report | null) => {
        this.report = report;
      }

      setReports = (reports: Report[]) => {
        this.reports = reports;
      }

      setTournamentsInfo = (tournaments: AvailableTournamentsForReport[]) => {
        this.availableForReportTournamentsInfo = tournaments;
      }

      clearReports = () => {
        this.reports = null;
      }
}