import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  table: {
    boxSizing: 'border-box',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone': {
        display: 'none'
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 0px',
      textIndent: 0,
      boxSizing: 'border-box'
    },
    '& .MuiTableCell-body': {
      padding: '7px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 16,
      '& .MuiTableHead-root > tr > th > span > svg': {
        margin: 0
      },
      '& .MuiTableHead-root > tr > th > span': {
        fontSize: 14
      },
    },
  },
  fullName: {
    textAlign: 'left',
    marginLeft: 10,
  },
  basicCell: {
    boxSizing: 'border-box',
    paddingRight: 25,
  },
  rateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      gap: 5
    },
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 16,
    lineHeight: '135%',
    color: '#0E3AAA',
  },
  mobileContainer: {
    [theme.breakpoints.down('xs')]: {
      '& div:last-child': {
        whiteSpace: 'normal',
        textAlign: 'left',
        marginLeft: 5
      },
      '& div > span > span': {
        bottom: '25%'
      },
    }
  },
  search: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '60%'
    },
  },
}));

export default useStyles;