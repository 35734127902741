import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: 1360,
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: '0 16px',
  },
  participantsTable: {
    boxSizing: 'border-box',
    padding: 25,
    width: 600,
    [theme.breakpoints.only('sm')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
    },
  },
  participantsNumber: {
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
      background: '#eee',
    },
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
  },
  emptyList: {
    boxSizing: 'border-box',
    padding: 50,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  tournaments: {
    display: 'block',
    margin: 'auto',
    maxWidth: 1360
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& .MuiTableHead-root': {
      '& th:nth-child(5)': {
        paddingRight: 20,
      },
      '& th:nth-child(6)': {
        paddingRight: 20,
      },
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 48,
    lineHeight: '60px',
    color: '#141429',
    margin: '10px 0 32px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 44,
      lineHeight: '50px',
      marginTop: 20,
      textAlign: 'center'
    },
  },
  link: {
    fontSize: 16,
    lineHeight: '135%',
    color: '#0E3AAA',
  },
  usersListWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  usersList: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '135%',
  },
  flagWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  flag: {
    width: 20,
    height: 20,
    marginRight: 4
  },
  location: {
    whiteSpace: 'nowrap'
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '60%'
    },
  },
  buttonStatus: {
    margin: 'auto'
  },
  breadcrumbs: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  }
}));

export default useStyles;