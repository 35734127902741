import React from 'react';
import { toDate } from '../../../../../utils/Utils';
import useStyles from './Date.styles';

const defaultProps = {
  format: 'L',
};

const Date = (props: { value: string, format?: string }) => {
  const { value, format } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {toDate(value, format)}
    </span>
  );
};

export default Date;

Date.defaultProps = defaultProps;