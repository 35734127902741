import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStores } from '../../../../stores/Main';
import AdminReportsTable from './table/AdminReportsTable';

const AdminReports = observer(() => {
  const { ReportsStore } = useStores();

  useEffect(() => {
    ReportsStore.refreshReports();

    return () => ReportsStore.setReports([]);
  }, []);

  return (
    ReportsStore.reports && (
    <div>
      <AdminReportsTable
        data={ReportsStore.reports}
      />
    </div>
    )
  );
});

export default AdminReports;