import React, {
  useState, useContext, createContext, useEffect
} from 'react';
import ContextProps from '../../../models/components/localization/ContextProps';
import { StorageService } from '../../../utils/Utils';

const LanguageContext = createContext<Partial<ContextProps>>({});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageContextProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const currentLanguage = StorageService.getLanguage();
  const [language, changeLanguage] = useState(currentLanguage || navigator.language);
  useEffect(() => {
    StorageService.setLanguage(language);
  }, [language]);
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;