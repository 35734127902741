import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import Country from '../../../../../../models/components/dictionaries/country/Country';
import useStyles from '../Countries.style';

const EditDeleteCell = observer((props: { data: Country }) => {
  const classes = useStyles();
  const { CountriesStore } = useStores();
  const { data } = props;

  const editCountry = (country: Country) => {
    CountriesStore.setCountry(country);
    CountriesStore.toggleEditCountryModalState();
  };

  const openDelModal = (country: Country) => {
    CountriesStore.setCountry(country);
    CountriesStore.toggleDeleteCountryModalState();
  };

  return (
    <ListItem className={classes.buttonCell}>
      <IconButton
        className={classes.button}
        onClick={() => editCountry(data)}
        edge="start"
        aria-label="edit"
      >
        <Edit />
      </IconButton>
      <IconButton
        className={classes.button}
        edge="end"
        aria-label="delete"
        onClick={() => openDelModal(data)}
        data-testid="delete-button"
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
});

export default EditDeleteCell;