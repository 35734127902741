import React, {
  useEffect, useRef, useState
} from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField, Select, MenuItem, FormControl, FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import schema from './helpers/ValidationSchema';
import useStyles from './ReportControl.styles';
import { useStores } from '../../stores/Main';
import useTranslation from '../localization/customHooks/Translation';

import { EmptyVoidFunction } from '../../utils/types/Types';
import LoaderFullScreen from '../loader/fullScreen/FullScreen';
import Report from '../../models/components/report/Report';
import ATButton from '../button/Button';
import UploadImage from '../uploadImages/UploadImage';
import UserPicture from '../userPicture/UserPicture';
import ImageData from '../../models/components/image/ImageData';
import RoundControl from './componens/round/RoundControl';
import AdditionalInfoControl from './componens/footerHeader/AdditionalInfoControl';
import Toasters from '../popUp/PopUp';
import { getNameWithDateAndLocation } from '../../utils/Utils';
import AvailableTournamentsForReport from '../../models/pages/availiableTournaments/AvailableTournamentsForReport';
import Round from '../../models/pages/tournament/Round';
import { mergeRoundsInEditModal, mergeRounds } from './ReportControl.utils';

const ReportControl = observer((props: {
    handleClose?: EmptyVoidFunction;
    isAddModalOpened: boolean;
    isEditModalOpened: boolean;
    tournamentId?: string;
  }) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const { ReportsStore } = useStores();

  const {
    handleClose, isAddModalOpened, isEditModalOpened, tournamentId
  } = props;
  const headerRef = useRef<HTMLHeadingElement>(null);
  const roundsRef = useRef<HTMLHeadingElement[]>([]);
  const footerRef = useRef<HTMLHeadingElement>(null);

  const classes = useStyles();
  const l10n = useTranslation();
  const [availableForReportTournamentsInfo, setAvailableForReportTournamentsInfo] = useState<AvailableTournamentsForReport[] | [] | null>(null);
  const [stateOfSelectList, setStateOfSelectList] = useState<number>(0);
  const [selectedTournamentInfo, setSelectedTournamentInfo] = useState<any>(null);
  const [images, setImages] = useState<ImageData[] | [] | null>([]);

  const findTournamentById = (id: number) => availableForReportTournamentsInfo!.find((el) => el.id === id);

  const getIdsOfImg = () => images!.map((el: ImageData) => el.id);

  const getAvailableTournaments = async () => {
    ReportsStore.setReport(null);
    const response = await ReportsStore.getAvailableTournamentsForReport();
    if (response) {
      setAvailableForReportTournamentsInfo(response);
    }
  };

  const resetAnyData = () => {
    reset();
    setSelectedTournamentInfo(null);
    setImages([]);
    ReportsStore.setReport(null);
  };

  const getRoundObjects = (data: Report) => Object.values(data).filter((el: any) => el.playOffGameId || el.tournamentRoundId);

  const getReportById = async (id: string) => {
    await ReportsStore.getOneReportById(id);
  };

  const validateContent = ({ roundReports, header, footer }: Report) => {
    const isAnyEmptyRound = roundReports.some((RoundReport) => RoundReport.description);
    return !!(header || footer || isAnyEmptyRound);
  };

  const preparingDataForReport = (data: Report) => ({
    ...data,
    imageIds: getIdsOfImg(),
    roundReports: getRoundObjects(data),
  });

  const saveDraft = async (data: Report) => {
    const dataForSave = preparingDataForReport(data);
    const isValid = validateContent(dataForSave);

    if (isValid) {
      const response = await ReportsStore.addReport(dataForSave);
      return response;
    }
    return null;
  };

  const saveReport = async (data: Report) => {
    const result = await saveDraft(data);

    if (result) {
      const isPublicated = await ReportsStore.publishReport(result.id, true);
      if (isPublicated) {
        Toasters.success(isEditModalOpened
          ? l10n.components.tournamentReports.EDIT_REPORT_SUCCESS
          : l10n.components.tournamentReports.ADD_REPORT_SUCCESS);
      }
    }
    if (handleClose) {
      ReportsStore.setReport(null);
      handleClose();
    }
    if (!result) {
      Toasters.error(l10n.components.error.AT_LEAST_ONE_FIELS);
    }
  };

  const triggerFocus = () => {
    const reportDraft = getValues();
    if (reportDraft.name && !isEditModalOpened) {
      saveDraft(reportDraft);
    }
  };

  useEffect(() => {
    if (isAddModalOpened) {
      getAvailableTournaments();
    }
  }, []);

  useEffect(() => {
    if (tournamentId) {
      setValue('tournamentId', tournamentId);
      setStateOfSelectList(Number(tournamentId));
    }
  }, []);

  useEffect(() => {
    if (availableForReportTournamentsInfo && isAddModalOpened && stateOfSelectList) {
      const selectedTournament = findTournamentById(stateOfSelectList);
      if (selectedTournament && selectedTournament.reportDraftId && !selectedTournamentInfo) {
        getReportById(selectedTournament.reportDraftId!);
        if (ReportsStore.report) {
          const transformedReport = mergeRoundsInEditModal(ReportsStore.report);
          setValue('name', transformedReport.name);
          setSelectedTournamentInfo(transformedReport);
          setImages(ReportsStore.report.images);
        }
      }
      if (selectedTournament && !selectedTournament.reportDraftId) {
        const glued = mergeRounds(selectedTournament);
        setSelectedTournamentInfo(glued);
      }
    }

    if (ReportsStore.report && isEditModalOpened) {
      const transformedReport = mergeRoundsInEditModal(ReportsStore.report);
      setValue('name', transformedReport.name);
      setSelectedTournamentInfo(transformedReport);
      setStateOfSelectList(ReportsStore.report!.tournamentId);
      setImages(ReportsStore.report.images);
    }
  }, [stateOfSelectList, ReportsStore.report, availableForReportTournamentsInfo]);

  if (!availableForReportTournamentsInfo && !isEditModalOpened) {
    return <LoaderFullScreen />;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(saveReport)}
      className={classes.inputContainer}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.tournamentReports.NAME_OF_REPORT}
            variant="outlined"
            error={!!errors.name}
            autoFocus
            helperText={errors?.name?.message}
          />
        )}
        name="name"
        control={control}
        defaultValue={isEditModalOpened && ReportsStore.report ? ReportsStore.report!.name : ''}
      />
      <FormControl
        variant="outlined"
        fullWidth
        className="formControl"
      >
        <Controller
          name="tournamentId"
          defaultValue={isEditModalOpened && ReportsStore.report ? ReportsStore.report!.tournamentId : 0}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              onChange={(evt) => {
                resetAnyData();
                field.onChange(evt);
                setStateOfSelectList(Number(evt.target.value));
              }}
              value={tournamentId || field.value}
              disabled={!!tournamentId || isEditModalOpened}
              error={!!errors.tournamentId}
              className={clsx({ [classes.invalid]: errors?.tournamentId })}
            >
              {isEditModalOpened && ReportsStore.report ? (
                <MenuItem
                  key={ReportsStore.report!.tournamentId}
                  value={ReportsStore.report!.tournamentId}
                >
                  {getNameWithDateAndLocation(ReportsStore.report!.tournament)}
                </MenuItem>
              ) : (
                <MenuItem
                  key={0}
                  value={0}
                >
                  {l10n.components.tournamentReports.CHOOSE_TOURNAMENT}
                </MenuItem>
              )}
              {isAddModalOpened && availableForReportTournamentsInfo && availableForReportTournamentsInfo!
                .filter((tournament: AvailableTournamentsForReport) => (tournamentId ? String(tournament.id) === tournamentId : true))
                .map((tournament: AvailableTournamentsForReport) => (
                  <MenuItem key={tournament.id} value={tournament.id} className={classes.itemInList}>
                    {getNameWithDateAndLocation(tournament)}
                  </MenuItem>
                ))}
            </Select>
          )}
        />
        <FormHelperText className={classes.invalid}>
          {!!errors.tournamentId && l10n.components.tournamentReports.REQUIRED_TOURNAMENT}
        </FormHelperText>
      </FormControl>
      {selectedTournamentInfo && !!stateOfSelectList && (
      <AdditionalInfoControl
        autoSaveFunction={triggerFocus}
        description={selectedTournamentInfo.header}
        ref={headerRef}
        control={control}
        setValue={setValue}
        errors={errors}
        formControl={{
          controlName: 'header',
          label: l10n.components.tournamentReports.INTRO_OF_REPORT,
        }}
      />
      )}
      {selectedTournamentInfo && !!stateOfSelectList && selectedTournamentInfo.roundReports && selectedTournamentInfo.roundReports
        .map((round: Round, index: number) => (
          <RoundControl
            autoSaveFunction={triggerFocus}
            currentIdAngler={selectedTournamentInfo.authorId}
            index={index}
            round={round}
            ref={roundsRef}
            control={control}
            setValue={setValue}
            errors={errors}
          />
        ))}
      {selectedTournamentInfo && !!stateOfSelectList && (
      <AdditionalInfoControl
        autoSaveFunction={triggerFocus}
        ref={footerRef}
        description={selectedTournamentInfo.footer}
        control={control}
        setValue={setValue}
        errors={errors}
        formControl={{
          controlName: 'footer',
          label: l10n.components.tournamentReports.SUMMARY,
        }}
      />
      )}
      <div>
        <div className={classes.imgContainer}>
          {!!images?.length && images!
            .map((img: ImageData) => <div className={classes.imgSizes} key={img.id}><UserPicture pictureInfo={img} setImage={setImages} /></div>)}
        </div>
        <UploadImage
          addImage={(img: ImageData) => { setImages((prev: ImageData[] | [] | null) => [...prev!, img]); }}
        />
      </div>
      <ATButton
        variant="primary"
        fill="solid"
        size="lg"
        isSubmit
      >
        {l10n.shared.ADD}
      </ATButton>
    </form>
  );
});

export default ReportControl;