const colors = {
  mainBackground: '#282c34',
  primary: '#0DA9B2',
  primaryLight: '#21CAD4',
  primaryDark: '#0A9099',
  secondary: '#141429',
  secondaryDark: '#0F0F1F',
  primaryFocus: '#A0D3D7',
  primaryError: '#f44336',
  grey: '#bbb',
  link: '#0033B7',
  lightBlue: '#dbf2f4',
  grayDark: '#474752',
  lightGrey: '#f7f8fb',
  greyBorder: '#ddd',
  greenWinner: '#d2e3cb',
  orangeDraw: '#fff2cc',
  redLose: '#ecc4c4',
  green: '#8BC78B',
  orange: '#F7C377',
  red: '#F56964',
  darkBlue: '#172b56',
  lightGreen: '#abf5d1',
  lightOrange: '#fff0b3',
  lightRed: '#ffbdad',
  lightRedDarker: '#ea9999',
  white: '#FFFFFF'
};

const navMenuBorderTransition: string = 'opacity .3s ease';

const focusVisibleNavMenu = {
  outline: 'none',
  border: `2px solid ${colors.primaryFocus}`,
  borderRadius: '4px',
};

const stylesMenuItem = {
  '& li': {
    listStyle: 'none',
    borderBottom: '2px solid transparent',
    transition: navMenuBorderTransition,
    '&:hover': {
      borderBottom: `2px solid ${colors.secondary}`,
      cursor: 'pointer',
    },
    '& a': {
      display: 'inline-block',
      lineHeight: '2.5rem',
      padding: '0px 4px 2px',
      border: '2px solid transparent',
      '&:focus-visible': {
        ...focusVisibleNavMenu
      }
    }
  }
};

export const tempMediaSize = 450;

export default colors;

export {
  navMenuBorderTransition, focusVisibleNavMenu, stylesMenuItem
};