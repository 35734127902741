import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  content: {
    marginBottom: 50,
  },
  title: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 20px 0px',
      boxSizing: 'border-box',
      margin: 0
    },
  },
  titleName: {
    marginRight: 20,
  },
  titleParticipants: {
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    letterSpacing: -0.1,
  },
  titleParticipantsNumber: {
    marginLeft: 5,
    fontWeight: 600,
    fontSize: 19,
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
  status: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      order: 0,
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
  button: {
    minWidth: 192,
    minHeight: 50,
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      minHeight: 50,
    },
  },
  contenPosition: {
    display: 'flex',
    marginBottom: 10,
    width: '100%',
    flexWrap: 'wrap',
  },
}));

export default useStyles;