import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useStyles from './Info.styles';
import mapPin from '../../../../assets/icons/map-pin.svg';
import star from '../../../../assets/icons/star.svg';
import format from '../../../../assets/icons/primanka.svg';
import user from '../../../../assets/icons/user.svg';
import ParticipantsCount from '../../../participantsCount/ParticipantsCount';
import FinishedTournament from '../../../../models/pages/tournament/FinishedTournament';
import Routes from '../../../../routing/Routes';
import ranking from '../../../../assets/icons/ranking.svg';

const defaultProps = {
  className: '',
};

const FinishedTournamentInfo = (props: {
  tournament: FinishedTournament | undefined
  className?: string
}) => {
  const classes = useStyles();
  const { tournament, className } = props;

  return (
    <>
      <ul className={clsx(className, classes.info)}>
        <li className={classes.list}>
          <img src={mapPin} alt="map-pin" />
          <Link to={Routes.Location.path.replace(':id', String(tournament?.locationId))}>
            <button type="button" className={classes.button}>{tournament?.locationName}</button>
          </Link>
        </li>
        <li className={classes.list}>
          <img src={star} alt="star-icon" />
          <span>{tournament?.ratingsNames.map((rating: string) => rating).join(', ')}</span>
        </li>
        <li className={classes.list}>
          <img src={format} alt="format-icon" />
          <span>{tournament?.formatName}</span>
        </li>
        <li className={classes.rankingMobile}>
          <img src={ranking} alt="format-icon" className={classes.rankingIcon} />
          <span>{tournament?.rankingName}</span>
        </li>
        <li className={classes.list}>
          <img src={user} alt="user-icon" />
          <ParticipantsCount number={tournament?.participantsCount} />
          <span className={classes.ranking}>{tournament?.rankingName}</span>
        </li>
      </ul>
    </>
  );
};

export default FinishedTournamentInfo;

FinishedTournamentInfo.defaultProps = defaultProps;