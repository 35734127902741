import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  win: {
    border: `1px solid ${colors.green}`,
    color: colors.green
  },
  draw: {
    border: `1px solid ${colors.orange}`,
    color: colors.orange
  },
  defeat: {
    border: `1px solid ${colors.red}`,
    color: colors.red
  },
  coloredCell: {
    width: 40,
    height: 25,
    borderRadius: 14,
    [theme.breakpoints.down('xs')]: {
      width: 30,
    },
  },
  wrapperContentTooltip: {
    padding: '4px 16px',
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      lineHeight: '24px',
    }
  },
  tooltip: {
    transition: '.3 cubic-bezier(.4,0,.6,1)',
  },
}));

export default useStyles;