import { createStyles, makeStyles } from '@material-ui/core/styles';
import check from '../../assets/icons/check.svg';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    margin: 0,
    '& .MuiFormControlLabel-label': {
      margin: '0px 4px',
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: 16,
      lineHeight: '24px',
      color: colors.secondary
    }
  },
  root: {
    padding: 7,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    position: 'relative',
    borderRadius: 4,
    width: 16,
    height: 16,
    border: '1px solid',
    borderColor: '#BCC1CB',
    'input:hover ~ &, input:active ~ &': {
      borderColor: '#858697'
    },
    'input:hover ~ &::before, input:active ~ &::before, input:focus-visible ~ &::before': {
      position: 'absolute',
      top: -8,
      left: -8,
      content: '""',
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: colors.primary,
      opacity: 0.1,
    },
    'input:active ~ &::before': {
      opacity: 0.2,
    },
    'input:focus-visible ~ &::before': {
      opacity: 0.3
    },
    'input:hover ~ &': {
      borderColor: '#858697',
    },
    'input:disabled ~ &': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
  checkedIcon: {
    width: 16,
    height: 16,
    background:
      `url(${check}) center no-repeat`,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    'input:hover ~ &': {
      backgroundColor: colors.primary,
      borderColor: colors.primary
    },
    'input:disabled': {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      background:
        `url(${check}) center no-repeat`,
    },
  },
}));

export default useStyles;