import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 280,
    background: '#141429',
    marginTop: 30,
    '& .MuiToolbar-root': {
      alignItem: 'top',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        '& span': {
          height: '90%',
          width: '90%',
        }
      },

    },
    '& .MuiIconButton-label': {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      height: 490,

    }
  },
  toolbar: {
    maxWidth: 1360,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 50,
    paddingBottom: 50,
    fontFamily: 'Source Sans Pro',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '100%',
      maxHeight: 430,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      marginRight: 50,
      [theme.breakpoints.down('sm')]: {
        width: '85%',
        marginRight: 0,
      },
    },
  },
  instaLogo: {
    [theme.breakpoints.down('sm')]: {
      '& img': {
        height: '150%',
      }
    },
  },
  block: {
    display: 'flex',
    maxWidth: 150,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'right',
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      maxHeight: 100,
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    textAlign: 'right',
    color: '#C1C1CA',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  menuContainer: {
    display: 'flex',
    maxWidth: 600,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 330,
      maxHeight: 160,
      marginTop: -10,
    },
  },
  menu: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& a': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: '#FFFFFF',
      textDecoration: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    }
  },
  desktopLanguageSelect: {
    display: 'flex',
    color: '#FFFFFF',
    justifyContent: 'start',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

export default useStyles;