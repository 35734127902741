import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  mainWrapper: {},
  articlesTabMenu: {},
  tabsContainer: {
    margin: 0
  },
  tableSwitcher: {
    background: 'none',
    color: '#51b3b4',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    margin: '0px 15px 15px 15px',
    textDecoration: '#51b3b4 underline',
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    color: '#141429',
    textDecoration: 'none',
  },
  addBtn: {
    marginLeft: 15,
    marginBottom: 15,
  },
  modalHeader: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: 200,
    }
  },
  mediaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  }
}));

export default useStyles;