import React, {
  useRef, forwardRef
} from 'react';
import clsx from 'clsx';
import {
  AddBox,
  ArrowDropDown,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  LastPage,
  Remove,
  FirstPage,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import MaterialTable, { MTableToolbar, MTableHeader, MTableBody } from '@material-table/core';
import ColumnMetadata from '../../models/components/table/ColumnMetadata';
import GridProperties from '../../models/components/table/GridProperties';
import { useStyles, search } from './Table.styles';
import useTranslation from '../localization/customHooks/Translation';

const tableIcons = {
  Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <SaveAlt {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ArrowDropDown {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

const transformColumns = (array: ColumnMetadata[]) => array.map((item: ColumnMetadata) => {
  const column = {
    field: item.fieldName,
    title: item.displayName,
    sorting: item.allowSortring != null ? item.allowSortring : true,
    filtering: item.allowSearch != null ? item.allowSearch : true,
    render: item.render,
    defaultSort: item.defaultSort,
    customSort: item.customSort,
    headerStyle: item.headerStyle,
    cellStyle: item.cellStyle,
    ...item.customColumnStyle,
  };
  return column;
});

const defaultProps = {
  onFilterChanged: () => { },
  areActionsAvailable: false,
  className: '',
  toggleSortOrder: () => { },
  isThirdSortClickAllowed: true,
  isPaging: true,
  toolbarElement: null,
  isSearchVisible: false,
  header: null,
  maxBodyHeight: '',
  isStickyHeader: false,
  summaryFooter: <></>,
  searchPlaceholder: '',
};

export default function AreatroutTable({
  columns, rows, className, onFilterChanged, toggleSortOrder, isThirdSortClickAllowed,
  isPaging, toolbarElement, isSearchVisible, header, maxBodyHeight, isStickyHeader, summaryFooter, allHeadersStyle, parentChildData, searchPlaceholder
}: GridProperties) {
  const newColumns = transformColumns(columns);
  const classes = useStyles();
  const tableRef = useRef<any>();
  const l10n = useTranslation();

  return (
    <div className={clsx(className, classes.root, { [classes.stickyHeader]: isStickyHeader })}>
      <MaterialTable
        onOrderChange={(columnId: number, value: string) => toggleSortOrder && toggleSortOrder(columnId, value)}
        onSearchChange={() => onFilterChanged && onFilterChanged(tableRef.current?.state.data, tableRef.current?.state.searchText)}
        tableRef={tableRef}
        icons={tableIcons}
        columns={newColumns}
        data={rows}
        parentChildData={parentChildData}
        options={{
          filtering: false,
          search: isSearchVisible,
          sorting: true,
          showTitle: false,
          thirdSortClick: isThirdSortClickAllowed,
          pageSize: 10,
          paginationType: 'stepped',
          paging: isPaging,
          draggable: false,
          pageSizeOptions: [],
          rowStyle: (rowData) => (rowData.rowStyles),
          headerStyle: allHeadersStyle,
          searchFieldStyle: search,
          searchFieldAlignment: 'left',
          maxBodyHeight,
        }}
        localization={{
          toolbar: {
            exportTitle: l10n.components.areatroutTable.EXPORT_TITLE,
            exportAriaLabel: l10n.components.areatroutTable.EXPORT_ARIA_LABEL,
            searchTooltip: l10n.components.areatroutTable.SEARCH_TOOLTIP,
            searchPlaceholder: searchPlaceholder || l10n.components.areatroutTable.SEARCH_PLACEHOLDER,
          },
          body: {
            emptyDataSourceMessage: l10n.components.areatroutTable.EMPTY_DATA_SOURCE_MESSAGE,
          },
          pagination: {
            labelDisplayedRows: l10n.components.areatroutTable.LABEL_DISPLAYED_ROWS,
            labelRowsSelect: l10n.components.areatroutTable.LABEL_ROWS_SELECT,
            labelRowsPerPage: l10n.components.areatroutTable.LABEL_ROWS_PER_PAGE,
            firstAriaLabel: l10n.components.areatroutTable.FIRST_ARIA_LABEL,
            firstTooltip: l10n.components.areatroutTable.FIRST_TOOLTIP,
            previousAriaLabel: l10n.components.areatroutTable.PREVIOUS_ARIA_LABEL,
            previousTooltip: l10n.components.areatroutTable.PREVIOUS_TOOLTIP,
            nextAriaLabel: l10n.components.areatroutTable.NEXT_ARIA_LABEL,
            nextTooltip: l10n.components.areatroutTable.NEXT_TOOLTIP,
            lastAriaLabel: l10n.components.areatroutTable.LAST_ARIA_LABEL,
            lastTooltip: l10n.components.areatroutTable.LAST_TOOLTIP,
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className={clsx(classes.tollbar, { [classes.search]: !isSearchVisible })}>
              <MTableToolbar {...props} />
              {toolbarElement}
            </div>
          ),
          Header: (props) => (
            header || (
              <MTableHeader {...props} />
            )
          ),
          Body: (props) => (
            <>
              <MTableBody {...props} />
              {summaryFooter}
            </>
          )
        }}
      />
    </div>
  );
}

AreatroutTable.defaultProps = defaultProps;