import React, {
  Dispatch, SetStateAction, useRef, useState
} from 'react';
import { Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './SingleImageUpload.styles';
import ImageService from '../../../../../../services/main/Image.service';
import LoaderFullScreen from '../../../../../../components/loader/fullScreen/FullScreen';
import ImageData from '../../../../../../models/components/image/ImageData';
import { getImageUrl } from '../../../../../../utils/Utils';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  imageStyles: '',
  altButtonDescription: '',
};

const SingleImageUpload = (props: {
  setPoster: Dispatch<SetStateAction<ImageData | null>>;
  poster: ImageData | null;
  imageStyles?: string;
  altButtonDescription?: string;
}) => {
  const {
    poster, setPoster, imageStyles, altButtonDescription
  } = props;
  const classes = useStyles();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const l10n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const uploadImages = async (posterData: File) => {
    if (posterData) {
      const formData = new FormData();
      formData.append('image', posterData);
      setIsLoading(true);
      const response = await ImageService.post(formData);
      if (response) {
        setPoster(response);
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <div className={classes.poster}>
      <input
        type="file"
        id="image_uploads"
        name="image_uploads"
        accept="image/*"
        ref={fileInput}
        onChange={(event: any) => uploadImages(event.target.files[0])}
        className={classes.inputUpload}
      />
      {poster ? (
        <div className={imageStyles || classes.posterContainerOuter}>
          <div className={classes.posterContainerInner}>
            <img
              src={getImageUrl(poster.url)}
              alt={l10n.pages.PREVIEW}
              className={classes.imageWrapper}
            />
            <IconButton
              className={classes.closeButton}
              onClick={() => setPoster(null)}
              size="small"
            >
              <Close />
            </IconButton>
          </div>
        </div>
      )
        : (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => fileInput.current?.click()}
            className={classes.buttonPadding}
          >
            {altButtonDescription || l10n.components.button.LOAD_IMAGE}
          </Button>
        )}
    </div>
  );
};

export default SingleImageUpload;

SingleImageUpload.defaultProps = defaultProps;