import React from 'react';
import clsx from 'clsx';
import useStyles from '../Playoff.styles';

const defaultProps = {
  isFinal: false
};

function Empty(props: {
  isFinal?: boolean
}) {
  const { isFinal } = props;
  const classes = useStyles();
  return (
    <div className={clsx(
      classes.flexRow,
      classes.anglerContainer,
      classes.shortContainer,
      {
        [classes.anglerFinalWrapper]: isFinal
      }
    )}
    />
  );
}

Empty.defaultProps = defaultProps;

export default Empty;