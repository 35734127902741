import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    width: 35,
    boxSizing: 'border-box',
    color: colors.secondary,
  },
  month: {
    textTransform: 'uppercase',
  },
  day: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 'bold'
  }
}));

export default useStyles;