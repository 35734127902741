import { object, string } from 'yup';
import { getTranslation } from '../../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  email: string().required(l10n.components.signInForm.REQUIRED_EMAIL).email(l10n.components.signInForm.INVALID_EMAIL),
  password: string().required(l10n.components.signInForm.PASSWORD_REQUIRED),
});

export default schema;