import {
  makeAutoObservable,
  reaction,
} from 'mobx';
import DrawedParticipant from '../../models/pages/tournament/DrawedParticipant';
import AdminTournamentDetails from '../../models/pages/tournament/AdminTournamentDetails';
import Rating from '../../models/components/dictionaries/rating/Rating';
import TournamentService from '../../services/main/Tournament.service';

export default class TournamentStore {
  areQualifiersCompleted: boolean = false;

  isPlayOffFinished: boolean = false;

  tournament: AdminTournamentDetails | null = null;

  isDrawing: boolean = false;

  isPublished: boolean = false;

  mainStore: any;

  activeParticipants: Array<DrawedParticipant> | null = [];

  constructor(mainStore: any) {
    this.mainStore = mainStore;

    makeAutoObservable(this);

    reaction(() => this.tournament, () => {
      if (this.tournament) {
        this.setPlayoffStatus(this.tournament.isPlayOffFinished);
      }
    });

    reaction(() => this.isDrawing, () => {
      if (!this.isDrawing) {
        this.setAreQualifiersCompleted(false);
      }
    });
  }

  getById = async (id: number) => {
    const response = await TournamentService.getById(id);
    if (response) {
      this.setTournament(response);
    }
  }

  getTournament = async (id: number) => {
    const response = await TournamentService.getById(id);
    if (response) {
      this.setTournament({
        ...response,
        ratings: response.ratings.map((item: Rating) => item.name),
      });
      this.setIsDrawing(response.drawingWasGenerated);
      this.setAreQualifiersCompleted(response.qualificationResultsWereCalculated);
      this.setIsPublished(response.isPublished);
    }
  }

  getActiveParticipants = async (id: number) => {
    const response = await TournamentService.getParticipants(id);
    if (response) {
      this.setActiveParticipants(response);
    }
  }

  setTournamentSponsors = async (data: any) => {
    const response = await TournamentService.setTournamentSponsors(data);
    return !!response;
  }

  setTournament = (tournament: AdminTournamentDetails) => {
    this.tournament = tournament;
  }

  setPlayoffStatus = (isFinished: boolean) => {
    this.isPlayOffFinished = isFinished;
  }

  setActiveParticipants = (participants: DrawedParticipant[]) => {
    this.activeParticipants = participants;
  }

  resetTournament = () => {
    this.tournament = null;
  }

  setAreQualifiersCompleted = (qualificationResultsWereCalculated: boolean) => {
    this.areQualifiersCompleted = qualificationResultsWereCalculated;
  }

  setIsPublished = (isPublished: boolean) => {
    this.isPublished = isPublished;
  }

  setIsDrawing = (isDrawing: boolean) => {
    this.isDrawing = isDrawing;
  }
}