import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../Tournaments.styles';

const defaultProps = {
  className: '',
};

const Header = (props: {
  text: string,
  className?: string,
}) => {
  const { text, className } = props;
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.header, className)}>
      {text}
    </Typography>
  );
};

Header.defaultProps = defaultProps;

export default Header;