import React, {
  useRef,
  useState
} from 'react';
import {
  TextField, Box, Grid, FormControl, FormHelperText
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import RatingService from '../../../../../../services/main/dictionaries/Rating.service';
import Rating from '../../../../../../models/components/dictionaries/rating/Rating';
import Loader from '../../../../../../components/loader/Loader';
import Toasters from '../../../../../../components/popUp/PopUp';
import useStyles from '../Rating.styles';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import schema from '../helpers/ValidationSchema';
import ATCheckbox from '../../../../../../components/checkbox/Checkbox';
import CountryService from '../../../../../../services/main/dictionaries/Country.service';
import RatingForm from '../../../../../../models/components/dictionaries/rating/RatingForm';
import ATButton from '../../../../../../components/button/Button';
import SingleSelect from '../../../../../../components/selects/single/Single';
import { selectType } from '../../../../../../utils/enum/Enum';
import TextInput from '../../../../../../components/inputs/textField/TextInput';
import EditableTextField from '../../../../../../components/inputs/textField/Editable';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

export default function AddOrEditCountry(props: {
  selectedRating: Rating;
  handleClose?: EmptyVoidFunction;
}) {
  const { selectedRating, handleClose } = props;
  const editor = useRef(null);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<RatingForm>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedRating,
      countingTournamentsNumber: selectedRating.countingTournamentsNumber || '',
      isNationalRating: selectedRating.isNationalRating || false,
    }
  });

  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isTournamentValueChecked, setIsTournamentValueChecked] = useState<boolean>(Boolean(selectedRating.countingTournamentsNumber));
  const [isNationalRatingChecked, setIsNationalRatingChecked] = useState<boolean>(Boolean(selectedRating.isNationalRating));

  const onFilterSelected = (_: string, value: string | number | null) => {
    if (value) {
      setValue('countryId', value.toString(), { shouldValidate: true, shouldDirty: true });
    }
  };

  const setRatingDescription = (newComponent: string) => setValue('description', newComponent, { shouldDirty: true });

  const sendPostRequest = async (data: Rating) => {
    setIsLoading(true);
    data.countingTournamentsNumber = isTournamentValueChecked
      ? Number(data.countingTournamentsNumber)
      : null;
    data.countryId = Number(data.countryId);
    data.isNationalRating = isNationalRatingChecked;
    const response = await RatingService.post(data);

    if (response) {
      Toasters.success(l10n.components.rating.RATING_ADD_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(false);
  };

  const sendPutRequest = async (data: Rating) => {
    setIsLoading(true);
    data.countingTournamentsNumber = isTournamentValueChecked
      ? Number(data.countingTournamentsNumber)
      : null;
    data.countryId = Number(data.countryId);
    data.isNationalRating = isNationalRatingChecked;

    const result = { ...data, id: selectedRating.id };
    const response = await RatingService.put(result);
    if (response) {
      Toasters.success(l10n.components.rating.RATING_EDIT_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form
      onSubmit={handleSubmit(
        selectedRating.id ? sendPutRequest : sendPostRequest,
      )}
    >
      <Box m={2} p={1}>
        <Controller
          render={({ field }) => (
            <div className={classes.flexColumn}>
              <TextInput
                placeHolder={l10n.components.rating.PLACEHOLDER_NAME}
                label={l10n.components.rating.LABEL_RATING}
                name="name"
                errors={errors.name}
                field={{ ...field }}
              />
            </div>
          )}
          name="name"
          control={control}
        />
        <div className={classes.field}>
          <EditableTextField
            ref={editor}
            errors={errors.description}
            convertedContent={selectedRating.description}
            onBlur={setRatingDescription}
            label={l10n.components.rating.RATING_DESCRIPTION}
          />
        </div>
        <div className={classes.field}>
          <FormControl
            variant="outlined"
            size="small"
            className={clsx({ [classes.invalid]: !!errors.countryId })}
          >
            <SingleSelect
              filterProps={{
                filterName: 'countryId',
                getAvailableItems: CountryService.get,
                label: l10n.components.rating.LABEL_COUNTRY_RATING,
                placeHolder: l10n.components.rating.PLACEHOLDER_COUNTRY,
                type: selectType.single
              }}
              defaultValue={selectedRating.countryId}
              onValueSelected={onFilterSelected}
              className={classes.select}
            />
            <FormHelperText className={classes.invalid} data-testid="countryError">
              {errors?.countryId?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className={classes.field}>
          <Controller
            name="isNationalRating"
            control={control}
            render={({
              field: { ref }
            }) => (
              <ATCheckbox
                inputRef={ref}
                checked={isNationalRatingChecked}
                label={l10n.components.rating.LABEL_NATIONAL_RATING}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsNationalRatingChecked(e.target.checked);
                }}
              />
            )}
          />
        </div>
        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          className={classes.field}
        >
          <Controller
            name="isCountingTournamentsChecked"
            control={control}
            defaultValue={isTournamentValueChecked}
            render={({
              field: {
                onChange, ref
              }
            }) => (
              <ATCheckbox
                inputRef={ref}
                checked={isTournamentValueChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.checked);
                  setIsTournamentValueChecked(e.target.checked);
                }}
                label={l10n.components.rating.LABEL_MAX_PARTICIPANTS}
              />
            )}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.value}
                disabled={!isTournamentValueChecked}
                type="number"
                size="small"
                variant="outlined"
                error={!!errors.countingTournamentsNumber}
                helperText={isTournamentValueChecked && errors.countingTournamentsNumber?.message}
              />
            )}
            name="countingTournamentsNumber"
            control={control}
          />
        </Grid>
      </Box>
      <Box mb={1} p={1} display="flex" justifyContent="center">
        <ATButton
          isSubmit
          variant="primary"
          fill="solid"
          size="md"
          testId="saveBtn"
        >
          {l10n.components.rating.SAVE_BUTTON}
        </ATButton>
      </Box>
    </form>
  );
}

AddOrEditCountry.defaultProps = defaultProps;