import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, TextField, Grid, FormHelperText
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Season from '../../../../../../models/components/dictionaries/season/Season';
import SeasonsService from '../../../../../../services/main/dictionaries/Season.service';
import schema from '../helpers/ValidationSchema';
import useStyles from '../Seasons.styles';
import Toasters from '../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => {},
};

const AddSeason = (props: {
  handleClose?: EmptyVoidFunction
}) => {
  const { handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Season>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: undefined,
    },
  });
  const addNewSeason = async (data: Season) => {
    setIsLoaded(true);
    const response = await SeasonsService.post(data);
    if (response) {
      Toasters.success(l10n.components.seasons.ADD_SEASON_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoaded(false);
  };

  if (isLoaded) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form onSubmit={handleSubmit(addNewSeason)} className={classes.contentWrapper}>
      <Controller
        render={({ field }) => (
          <>
            <TextField
              {...field}
              error={!!errors.name}
              label={l10n.components.seasons.LABEL_NAME}
              variant="outlined"
              autoFocus
              inputProps={{ 'data-testid': 'name' }}
            />
            <FormHelperText data-testid="nameError" className={classes.invalid}>
              {!!errors?.name && errors?.name?.message}
            </FormHelperText>
          </>
        )}
        name="name"
        control={control}
        defaultValue=""
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.seasons.LABEL_DESCRIPTION}
            variant="outlined"
            className={classes.wrapperInput}
            multiline
            rows={4}
            inputProps={{ 'data-testid': 'description' }}
          />
        )}
        name="description"
        control={control}
        defaultValue=""
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.saveButton}
        data-testid="saveBtn"
      >
        {l10n.components.seasons.SAVE_BUTTON}
      </Button>
    </form>
  );
};

export default AddSeason;

AddSeason.defaultProps = defaultProps;