/* eslint-disable max-len */
const en: any = {
  shared: {
    DELETE: 'Delete',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    ADD: 'Add',
    ALL: 'All',
    REQUIRED_NAME: 'Name cannot be empty',
    REQUIRED_DESCRIPTION: 'Description cannot be empty',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    REQUIRED_FIELD: 'Field cannot be empty',
    READ_MORE: 'Read more',
    ALL_UPCOMING_TOURNAMENTS: 'All upcoming tournaments',
    ALL_FINISHED_TOURNAMENTS: 'All finished tournaments',
    TOURNAMENT: 'Tournament',
    CONTINUE: 'Next',
  },
  components: {
    articles: {
      SUBCATEGORY: 'Subcategory',
      SUBCATEGORY_OF_PUBLICATION: 'Subcategory of publication',
      ADMIN_PUBLICATIONS: 'Admin publications',
      DELETE_QUESTION: 'Do you really want to delete selected article?',
      DELETE_ARTICLE_SUCCESS: 'Article succesfully deleted',
      ADD_ARTICLE_SUCCESS: 'Article added',
      EDIT_ARTICLE_SUCCESS: 'Article changed',
      EDIT: 'Article changed',
      ARTICLE_MODAL_DELETE: 'Delete article',
      ARTICLES: 'Articles',
      ARTICLE: 'Article',
      ANNOUNCMENTS: 'Announcements',
      ANNOUNCMENT: 'Announcement',
      CREATE_NEW_ARTICLE_ADMIN: 'Create new administrator article',
      EDIT_ARTICLE_ADMIN: 'Edit administrators article',
      REPORT: 'Report',
      REPORTS: 'Reports',
    },
    reports: {
      SWITCH_VISIBILITY_CONFIRMATION: 'Do you want to hide the report?',
      PUBLISH_VISIBILITY_CONFIRMATION: 'Do you want to publish report?',
      DISPLAY_REPORT_SUCCESS: 'Report succesfully published',
      HIDE_REPORT_SUCCESS: 'Report hided',
      REPORTS: 'Reports',
    },
    sponsors: {
      GENERAL_SPONSOR: 'General parthner',
      DISPLAY_SPONSORS: 'Partners on the tourm=nament card',
      SPONSORS_ADDED: 'Partners added',
      SPONSORS: 'Partners',
      SPONSORS_LOGO: 'Logo',
      SPONSOR_NAME: 'Partner',
      SPONSOR_URL: 'Website',
      INCORREСT_URL: 'Link incorrect',
      SPONSOR_COMMENTS: 'Comments',
      SPONSOR_CONTROL: 'Control',
      SPONSORS_LIST_IS_EMPTY: 'No partners',
      SPONSOR_MODAL_ADD: 'Add partner',
      SPONSOR_MODAL_EDIT: 'Edit partner',
      SPONSOR_MODAL_DELETE: 'Delete partner',
      ADD_SPONSOR_SUCCESS: 'Partner added',
      EDIT_SPONSOR_SUCCESS: 'Partner changed',
      DELETE_SPONSOR_SUCCESS: 'Partner deleted',
      IMAGE_ERROR: 'Upload image',
      SPONSOR_COMMENT: 'Comments',
      DELETE_QUESTION: 'Delete partner?',
      MAIN_SPONSOR: 'General partner',
      TOURNAMENT_SPONSOR: 'Partner',
      CANCEL_ALL: 'Cancel all',
    },
    error: {
      CHANGE_TOURNAMENT_STATUS: `Changing the status of a finished tournament is not possible.
      To reset the results of the tournament, use the cancellation of the draw on the "Draw" tab.`,
      DATE_ERROR: 'Please select actual date',
      EMPTY_FIELD_ERROR: 'Field cannot be empty',
      ENTER_PARTNER_EMAIL: 'Input partners email',
      EXISTING_LOGIN_DATA_ERROR: 'User already exists',
      EDITING_LOGIN_DATA_ERROR: 'Errod during users data changing',
      FORMAT_ERROR: 'Please choose tournament format',
      IS_NUMBER_POSITIVE: 'Please input a number greater than 0',
      INTEGER_ERROR: 'Please enter an integer',
      LOCACTION_ERROR: 'Please choose location',
      LOGIN_PASSWORD_ERROR: 'Wrong username or password',
      LINK_ERROR: 'Link must be like : http(s)://example.com',
      MAX_PARTICIPANTS_ERROR: 'Specify participants quota',
      MIN_PARTICIPANT_ERROR: 'Sould be at least 1 participant',
      NAME_ERROR: 'Name cannot be empty',
      NON_EXISTING_LOGIN_DATA_ERROR: 'User alreaady exists',
      PARTICIPANTS_LIMIT_ERROR: 'Input number',
      PARTNERS_EMAIL_ERROR: 'Input parnter E-mail',
      REACHED_MAX_PARTICIPANTS_ERROR: 'Maximum participants reached',
      RANKING_ERROR: 'Please specify ranking',
      SEASON_ERROR: 'Please choose season',
      TEMPLATE_ERROR: 'Please choose template',
      TEAM_NAME_ERROR: 'Please enter team name',
      TOKEN_ERROR: 'Email token expired',
      RESETPASSWORD_ERROR: 'Reset password failed',
      UNKNOWN_ERROR: 'Unknown error, please try again',
      USER_EMAIL_ERROR: 'Email not confirmed',
      USER_IS_BLOCKED: 'User blocked',
      USER_WASNT_FOUND: 'Unknown user',
      UNEXPECTED_ERROR: 'Unexpected error, please try again',
      SESSION_IS_EXPIRED: 'Your session expired, please log in again',
      WRONG_PARTNER_DATA: 'User not found',
      FIELD_LENGTH_ERROR: 'The field can contain from 2 to 256  characters, numbers, special characters',
      IS_ALREADY_REGISTERED: 'This participant is already registered for this tournament.',
      IMG_COUNT_ERROR: 'Изображений Images must be less than 8',
      AT_LEAST_ONE_FIELS: 'At least one field must be filled',
    },
    placeholder: {
      ALL_COUNTRIES: 'All countries',
      ALL_SEASONS: 'All seasons',
      ALL_RATINGS: 'All ratings',
      ALL_FORMATS: 'All formats',
      ALL_RANKINGS: 'All rankings',
      ALL_STATUSES: 'All statuses',
    },
    label: {
      EMAIL: 'Email',
      PASSWORD: 'Password',
      PARTNER: 'Partner',
      REASON_OF_BLOCKING: 'Blocking reason',
      REMEMBER_ME: 'Remember me',
      TEAM_NAME: 'Team name',
    },
    tableColumns: {
      EMPTY_RATINGS: 'Rating data does not exist',
      ADMINISTRATOR: 'Administrator',
      CATCHES: 'Catches',
      CREATION_DATE: 'Creation date',
      CARRY_OUT_DATE: 'Date',
      CREATOR: 'Organizer',
      CREATOR_NAME: 'Creator',
      CONTROL: 'Control',
      COUNTRY: 'Country',
      DATE: 'Date',
      AUTHOR: 'Author',
      DESCRIPTION: 'Description',
      EMAIL: 'E-mail',
      ACTIVATION: 'Activation',
      FORMAT: 'Format',
      FULLNAME: 'Name',
      TITLE: 'Title',
      LOCATION: 'Location name',
      LOCATION_PLACE: 'Location',
      SHOW_OR_HIDE: 'Hide/Show',
      MEETINGS: 'Meet',
      NAME: 'Name',
      NUMBER: 'Number',
      PARTICIPATION: 'Participation',
      PARTICIPANT: 'Participant',
      ADD_PARTICIPANT: 'Add participant',
      PARTICIPANTS: 'Participants',
      PERSONAL_MEETINGS: 'Meets',
      PARTNER_NAME: 'Partner name',
      PLACE: 'Place',
      PLACES: 'Places',
      PLACES_SUM: 'Sum of places',
      POINT: 'Point',
      POINTS: 'Points',
      POINTS_SUM: 'Sum of points',
      ROUND: 'Round',
      ROUND_NAME: 'Round',
      RATING: 'Rating',
      RANKING: 'Ranking',
      REGISTRATION_DATE: 'Registration date',
      RESULTS: 'Results, %',
      STATUS: 'Tournament status',
      STATUS_TEXT: 'Status',
      SPORTSMAN: 'Angler',
      SCORE: 'Score',
      TOURNAMENT: 'Tournament',
      TOURNAMENTS: 'Tournaments',
      TOURNAMENT_NAME: 'Tournament name',
      TOURNAMENT_PARTICIPATION: 'Tournament participation',
      WAS_ACTIVE_AT: 'Last activity',
      RATINGS: 'Ratings',
      SEASONS: 'Seasons',
      TACKLES: 'Tackles'
    },
    headerLabel: {
      ALL_COUNTRIES: 'All countries',
      ALL_RATINGS: 'All ratings',
      ADD_TO_MAIN_LIST: 'Add to main list',
      ALL_SEASONS: 'All seasons',
      ADD_TOURNAMENT: 'Create tournament',
      COUNTRY: 'Country',
      CANCEL_REGISTRATION: 'Cancel registration',
      CANCEL_DRAWING: 'Cancel drawing',
      CHANGE_STATUS: 'Change status',
      COMPUTE_QUALIFIERS: 'Qualifiers calculation',
      DELETE: 'Delete',
      DELETE_USER: 'Delete user',
      DRAW: 'Draw',
      DOWNLOAD_PDF_CARDS: 'Download PDF cards',
      RATING: 'Rating',
      SEASON: 'Season',
      TOURNAMENT_HAS_BEEN_PUBLISHED: 'Pournament published',
    },
    toasterMessage: {
      CHANGES_HAVE_BEEN_SAVED: 'Changes saved',
      COMPUTE_QUALIFIERS_ERROR: 'Qualifying results could not be calculated, please fill out all participant cards',
      getBlockingMessage: (status: boolean) => `User ${status ? 'uunblocked' : 'blocked'}`,
      DRAWING_WAS_HELD: 'Drawing completed',
      USER_HAS_BEEN_ACTIVATED: 'User activated',
      PARTICIPANT_HAS_BEEN_ADDED: 'Participant added to list',
      PARTICIPANT_HAS_BEEN_ADDED_TO_WAITLIST: 'Participant mowed to waitlist',
      REGISTRATION_HAS_BEEN_CANCELED: 'Registration cancelled',
      ROLE_HAS_BEEN_DELETED: 'Role changed',
      STATUS_HAS_BEEN_CHANGED: 'Status changed',
      TOURNAMENT_HAS_BEEN_ADDED: 'Tournament added',
      TOURNAMENT_HAS_BEEN_DELETED: 'Tournament deleted',
      TOURNAMENT_HAS_BEEN_CHANGED: 'Tournament changed',
      TEAM_WAS_CREATED: 'Team created',
      TEAM_WAS_EDITED: 'Team edited',
      USER_HAS_BEEN_DELETED: 'ПUser deleted',
      USER_HAS_BEEN_ADDED: 'Used added to tournament',
      QUALIFIERS_HAVE_BEEN_FORMED: 'The results of the qualifying rounds have been calculated, the grid of the final fights has been created',
      WAITING_LIST_HAS_BEEN_LEFT: 'You have successfully left the waiting list',
      YOU_HAVE_BEEN_ADDED_TO_WAITING_LIST: 'You have added to waitlist',
      YOU_HAVE_BEEN_REGISTERED: 'You have successfully registered',
      TACKLES_WERE_ADDED: 'Tackles added',
      TACKLES_WERE_EDITED: 'Tackles edited',
      TACKLES_WERE_DELETED: 'Tackles deleted',
    },
    confirmDialogsMessage: {
      ADD_USERS_TO_MAIN_LIST: 'Do you want to add the member to the main list?',
      ADD_TO_WAITING_LIST: 'Do you want to add the member to the waitlist?',
      ACCEPT_INVITATION: 'Do you want to accept invitation?',
      CANCEL_REGISTRATION: 'Do you want to cancel registration?',
      CANCEL_DRAWING: 'Do you want to cancel drawing? Participants cards and tournament results will be deleted!',
      DOWNLOAD_PDF_CARDS: 'Do you want to download PDF?',
      DELETE_USER: 'Do you want to delete user?',
      DELETE_TOURNAMENT: 'Do you want to delete tournament?',
      DECLINE_INVITATION: 'Do you want to reject invitation?',
      HOLD_TOURNAMENT: 'Do you want to draw participants?',
      RECOMPUTE_QUALIFIERS: 'Recalculating results will update the qualifying round results and reset the playoff results. Proceed?',
      LEAVE_WAITING_LIST: 'Do you want to leave waitlist?',
      REGISTER_ON_TOURNAMENT: 'ВDo you want to ragister on the tournament?',
      DELETE_TACKLE: 'Do you want to delete tackle?',
      HEADER_USERACTIVATION: 'Activate user',
      ACCEPT_USERACTIVATION: 'Do you want to activate this user? \nThis action cannot be undone',
    },
    button: {
      ACTIVATED: 'Activated',
      ACTIVATE: 'Activate',
      ADD: 'Add',
      FIND_ANGLER: 'Find angler',
      ADD_TO_MAIN_LIST: 'Add to main list',
      ADD_NEW_PARTICIPANT: 'Add new participant',
      ADD_TO_WAITING_LIST: 'Add to waitlist',
      ALL_TOURNAMENTS: 'All tournaments',
      APPLY: 'Apply',
      ALL_NEWS: 'All news',
      CREATE_TEAM: 'Create team',
      CHANGE: 'Change',
      CHANGE_TOURNAMENT_STATUS: 'Change status',
      CANCEL: 'Cancel',
      CANCEL_REGISTRATION: 'Cancel registration',
      CONTINUE: 'Proceed',
      CREATE_CARDS: 'Create cards (Draw)',
      CONFIRM: 'Yes',
      COMPUTE_QUALIFIERS: 'Calculate qualifiers',
      DOWNLOAD_PDF_CARDS: 'Download PDF cards',
      DELETE: 'Delete',
      FIND_PARTNER: 'Find partner',
      FORGOT_PASSWORD: 'Forgot password?',
      LOAD_IMAGE: 'Upload image',
      LEAVE_WAITING_LIST: 'leave waitlist',
      RATINGS_DESCRIPTION: 'Rating description',
      REGISTER: 'Register',
      RESET: 'Reset',
      RESET_ALL: 'Reset all',
      REFUSE: 'Cancel',
      SIGN_IN: 'Enter',
      SIGN_UP: 'Sign up',
      SCORE_COUNT_DESCRIPTION: 'How are points calculated?',
      SAVE_CHANGES: 'Save changes',
      SAVE: 'Save',
      SHOW_ERRORS: (errorCount: number) => `Show errors: ${errorCount}`,
      SHOW_ALL: 'Show all',
    },
    yearType: {
      YEARS: 'y.o.',
      YEAR: 'y.o.',
      OF_THE_YEAR: 'y.o.'
    },
    defaultSeasons: {
      AUTUMN_21_SPRING_22: 'Autumn 2021 - Spring 2022'
    },
    defaultCountries: {
      BELARUS: 'Belarus'
    },
    defaultRatings: {
      ATLAS: 'ATLAS'
    },
    routes: {
      AREATROUT_COMPETITIONS: 'Area trout competitions',
      ADMINISTARTION: 'Administration',
      DEMO: 'Demonstration',
      DICTIONARIES: 'Dictionaries',
      LOCATIONS_LIST: 'Locations',
      LOCATION_CARD: 'Location card',
      LOCATION_EDITING: 'Edit location',
      TOURNAMENT_ADMINISTARTION: 'Tournament management',
      ARTICLES_CONTROL: 'News management',
      TOURNAMENT_EDITING: 'Edit tournament',
      TEAMS: 'Teams',
      TOURNAMENTS_LIST: 'Tournaments',
      TOURNAMENT_CARD: 'Tournament card',
      USER_DATA: 'User data',
      USER_MANAGEMENT: 'User management',
      REPORT_CARD: 'Report',
      PUBLICATION_CARD: 'Publication',
      NEWS: 'News',
      TOURNAMENT_INFO: 'Tournament info',
    },
    addImage: {
      IMG_ALT_PREVIEW: 'Preview',
      HELPER_DESCRIPTION_UPLOAD: 'Click to upload image',
      HELPER_PHOTO_UPLOAD: 'Upload image',
      HELPER_LOGO_UPLOAD: 'Upload logo',
      BUTTON_TEXT_UPLOAD: 'Upload',
    },
    appbar: {
      ADMIN_PATH_NAME: 'Administration',
      APPBAR_TITLE: 'Trout area',
    },
    areatroutTable: {
      EXPORT_TITLE: 'Export',
      EXPORT_ARIA_LABEL: 'Export',
      SEARCH_TOOLTIP: 'Search',
      SEARCH_PLACEHOLDER: 'Search name,location',
      SEARCH_PLACEHOLDER_NAME: 'Search name',
      EMPTY_DATA_SOURCE_MESSAGE: 'No data',
      LABEL_ROWS_SELECT: 'rows',
      LABEL_DISPLAYED_ROWS: '{from}-{to} of {count}',
      LABEL_ROWS_PER_PAGE: 'Rows per page:',
      FIRST_ARIA_LABEL: 'First page',
      FIRST_TOOLTIP: 'First page',
      PREVIOUS_ARIA_LABEL: 'Previous page',
      PREVIOUS_TOOLTIP: 'Previous page',
      NEXT_ARIA_LABEL: 'Next page',
      NEXT_TOOLTIP: 'Next page',
      LAST_ARIA_LABEL: 'Last page',
      LAST_TOOLTIP: 'Last page',
      MOCK_DATA: {
        ANGLER: 'Angler',
        TOURNAMENTS: 'Tournament',
        CATCHES: 'Catches',
        GOLD: 'Gold',
        SILVER: 'Silver',
        BRONZE: 'Bronze',
        ATLAS: 'ATLAS',
      }
    },
    authorization: {
      TITLE_EXPIRED: 'Activation link expired',
      DESCRIPTION_EXPIRED: 'We have sent an email with further registration instructions to the e-mail address you provided during registration. Please, check the mail',
      BUTTON_DESCRIPTION_EXPIRED: 'Send again',
      TITLE_FAILED: 'Activation not completed',
      DESCRIPTION_FAILED: 'We have sent an email with further registration instructions to the e-mail address you provided during registration. Please, check the mail',
      TITLE_CHANGE_PASSWORD: 'Change password',
      LABEL_NEW_PASSWORD: 'New password',
      CONFIRM_NEW_PASSWORD: 'Confirm password',
      BUTTON_APPLY_NEW_PASSWORD: 'Apply',
      TITLE_PASSWORD_CHANGED: 'Password changed',
      DESCRIPTION_NEW_PASSWORD: 'Please log in agian with new credentials',
      BUTTON_DESCRIPTION_NEW_PASSWORD: 'Ok',
      EMAIL_TITLE_CONFIRM: 'E-mail confirmation',
      EMAIL_TEXT_CONFIRM: 'An email has been sent to the email address you provided during registration with further registration instructions',
      BUTTON_EMAIL_CONFIRM: 'OK',
      TITLE_EMAIL_CONFIRMED: 'Email confirmed',
      BUTTON_EMAIL_CONFIRMED: 'OK',
      TITLE_FAILED_RESULT: 'Error',
      DESCRIPTION_FAILED_RESULT: 'Failed to complete the email verification process. Please try again or contact your system administrator.',
      VALIDATION_PASSWORD_REQUIRED: 'Please enter password',
      VALIDATION_PASSWORD_MIN: '8 digits minimun',
      VALIDATION_PASSWORD_MATCH: 'Passwords must be the same',
    },
    editProfile: {
      TOASTER_SUCCESS: 'Changes saved',
      MODAL_HEADER_EDIT: 'Profile details',
      LABEL_NAME: 'First name',
      LABEL_LASTNAME: 'Last name',
      LABEL_COUNTRY: 'Country',
      PLACEHOLDER_COUNTRY: 'Choose country',
      LABEL_CITY: 'City',
      BIRTHDAY: 'Birthday',
      BIRTHDAY_ERROR: 'Please select correct date',
      SAVE_CHANGES: 'Save changes',
      VALIDATION_REQUIRED: 'Field cannot be empty',
      VALIDATION_INVALID: 'Invalid data',
    },
    recoveryPassword: {
      TITLE_LINK_SEND: 'Password recovery link sent',
      TEXT_LINK_SEND: 'Password recovery link sent',
      BUTTON_RECOVERY_LINK: 'Ok',
      TITLE_RECOVERY: 'Password recoverw',
      TEXT_TYPE_EMAIL: 'Введите e-mail, который вы использовали при регистрации. Мы вышлем на него пароль',
      RECOVERY_PASSWORD: 'Восстановить пароль',
      WITHOUT_ACCOUNT: 'Dont have an account? ',
      REGISTRATION: 'Register',
      BUTTON_SIGN_IN: 'Log in',
      REQUIRED_EMAIL: 'Enter email',
      INVALID_EMAIL: 'Wrong email'
    },
    signInForm: {
      SIGN_IN: 'Sign in',
      LABEL_PASSWORD: 'Password',
      BUTTON_FORGOT_PASSWORD: 'Forgot password?',
      BUTTON_SIGN_IN: 'Sign in',
      WITHOUT_ACCOUNT: 'Dont have an account? ',
      REGISTRATION: 'Register',
      REQUIRED_EMAIL: 'Enter  email',
      INVALID_EMAIL: 'Wrong email',
      PASSWORD_REQUIRED: 'Enter password',
    },
    signUpForm: {
      REGISTRATION: 'Sign up',
      LABEL_NAME: 'First name',
      LABEL_LASTNAME: 'Last name',
      LABEL_COUNTRY: 'Country',
      PLACEHOLDER_COUNTRY: 'Choose country',
      OPTION_COUNTRY: 'Choose country',
      LABEL_CITY: 'City',
      LABEL_PASSWORD: 'Password',
      LABEL_REPEAT_PASSWORD: 'Re-enter password',
      BUTTON_REGISTER: 'Sign up',
      ALREADY_REGISTER: 'Have an account? ',
      BUTTON_SIGN_UP: 'Log in',
      REQUIRED_FIELD: 'Field cannot be empty',
      REQUIRED_EMAIL: 'Enter email',
      INVALID_EMAIL: 'Wrong email',
      VALIDATION_PASSWORD_REQUIRED: 'Enter password',
      VALIDATION_PASSWORD_MIN: 'Input at least 8 characters',
      VALIDATION_PASSWORD_MATCH: 'Passwords must be the same',
    },
    collapsibleSection: {
      FILTERS: 'Filters',
    },
    countries: {
      COUNTRY_NAME: 'Country',
      CONTROL_NAME: 'Control',
      ADD_COUNTRY_HEADER: 'Add country',
      DELETE_HEADER: 'Delete',
      EDIT_HEADER: 'Edit',
      SAVE_BUTTON: 'Save',
      ADD_BUTTON: 'Add',
      EMPTY_COUNTRY_LIST: 'Countries not added yet',
      ADD_COUNTRY_SUCCESS: 'Country added',
      EDIT_COUNTRY_SUCCESS: 'Country edited',
      DELETE_COUNTRY_SUCCESS: 'Country delited',
      DELETE_QUESTION: 'Deleta country?',
      BUTTON_CANCEL: 'Cancel',
      REQUIRED_FIELD: 'Field cannot be empty',
    },
    footer: {
      LINK_TO_RULES: 'Rournament rules',
      LINK_CONTACTS: 'Contact info',
      LINK_USER_AGREEMENT: 'User agreement',
      LINK_ORGANIZERS: 'Organizers',
      TITLE_RIGHTS: 'All rights recieved',
      VERSION: 'Version',
      TITLE_LANGUAGE: 'The website is also available in different languages:',
    },
    heatCaptureUtils: {
      EMPTY_SELECT: 'Not selected',
    },
    heatCaptureTable: {
      TOUR: 'Round',
      TOTAL: 'Total',
      SECTOR: 'Sector',
      CHOOSE_ANGLER: 'Choose angler',
      CAPTURE_MAP: 'Catches heatmap',
    },
    imageThumbnail: {
      PREVIEW_IMG: 'Image preview',
    },
    editableTextField: {
      PLACEHOLDER: 'Input text',
    },
    extendedSelect: {
      PLACEHOLDER: 'Choose an item or search',
      LOADINGMESSAGE: 'Searching...',
      NOOPTIONSMESSAGE: 'Not found'
    },
    locations: {
      LOCATION_NAME: 'Location',
      LOCATION_DESCRIPTION: 'Description',
      LOCATION_COUNTRY: 'Country',
      LOCATION_COORDINATES: 'Coordinates',
      LOCATION_ADD_MODAL: 'Add location',
      LOCATION_ADD_BUTTON: 'Add',
      LOCATION_DELETE_MODAL: 'Delete',
      REQUIRED_NAME: 'Name cannot be ampty',
      REQUIRED_DESCRIPTION: 'Description cannot be empty',
      REQUIRED_COUNTRY: 'Please choose the country',
    },
    editLocation: {
      LOCATION_EDIT_SUCCESS: 'Changes saved',
      LOCATION_EDIT_TITLE: 'Location editing',
      LOCATION_EDIT_NAME: 'Name',
      LOCATION_EDIT_COUNTRY: 'Country',
      PLACEHOLDER_DESCRIPTION: 'Please add description',
      LONGITUDE: 'Longitude',
      LATTITUDE: 'Latitude',
      TOURNAMENT_PICTURES: 'Tournament galery',
      LOCATION_EDIT_SAVE: 'Save',
      LOCATION_EDIT_CANCEL: 'Cancel',
      PHONE_NUMBER: 'Phone',
      PHONE_NUMBER_VALIDATION: 'Phone in international format',
      LINK_TO_WEBSITE: 'Wrb site',
      LINK_TO_FACEBOOK: 'Facebook link',
      LINK_TO_INSTAGRAM: 'Instagram link',
      UPLOAD_LOGO: 'Upload logo',
    },
    addLocation: {
      LOCATION_ADD_SUCCESS: 'Location added',
      LOCATION_ADD_NAME: 'Name',
      LOCATION_CHOOSE_COUNTRY: 'Please choose country',
      LOCATION_REQUIRED: 'Please choose country',
      PLACEHOLDER_DESCRIPTION: 'Please add description',
      LONGITUDE: 'Longitude',
      LATTITUDE: 'Latitude',
      LOCATION_ADD_SAVE: 'Save',
    },
    deleteLocation: {
      LOCATION_DELETE_SUCCESS: 'Location deleted',
      CONFIRM_DELETE: 'Delete location?',
      CANCEL_DELETE: 'Cancel',
      DELETE: 'Delete',
    },
    countryCell: {
      NOT_AVAILABLE: 'Not available',
    },
    locationTable: {
      NAME_LOCATION: 'Location name',
      LOCATION_DESCRIPTION: 'Description',
      LOCATION_COUNTRY: 'Country',
      LOCATION_COORDINATES: 'Coordinates',
      CONTROL_LOCATION: 'Control',
      LOCATIONS_LIST_EMPTY: 'No locations',
    },
    locationCard: {
      MAIN_PAGE: 'Main page',
      LOCATIONS_PAGE: 'Locations',
      LOCATION: 'Location',
      HOW_TO_GET_TO: 'How to get to?',
      LOCATION_DESCRIPTION: 'Location description',
      GALLERY: 'Galery',
      UPCOMING_TOURNAMENTS: 'Upcoming tournaments',
      FINISHED_TOURNAMENTS: 'Finished tournaments',
      LOCATION_MAP: 'Location',
    },
    locationTournamentsTable: {
      DATE: 'Date',
      TOURNAMENT_NAME: 'Tournament',
      STATUS: 'Status',
    },
    memberList: {
      PARTICIPANTS_LIST: 'List of anglers',
      WAITING_LIST: 'Wait list',
    },
    memberListTable: {
      FULLNAME: 'Angler',
      DATE: 'Date',
      EMPTY_LIST: 'Empty list',
    },
    menu: {
      MENU_DESCRIPTION: 'Area trout competitions',
    },
    metaDescription: {
      META_DESCRIPTION: 'Area trout competitions. Schedule, statistic, results',
    },
    modal: {
      SAVE: 'Save',
      CLOSE: 'Close'
    },
    participantCardTemplate: {
      PHOTO_ALT: 'Photo',
      DATA_IS_EMPTY: 'No data',
    },
    participantsCount: {
      MANY_PARTICIPANTS: 'anglers',
      ONE_PARTICIPANT: 'angler',
      PARENTAL_CASE_PARTICIPANT: 'anglers',
    },
    participantsList: {
      ANGLER: 'Angler',
    },
    photoGallery: {
      PHOTO: 'photo',
      EMPTY_PHOTO_LIST: 'No photo',
    },
    playoffSchedule: {
      STATUS_CHANGED: 'Status changed',
      PLAYOFF_RESULTS: 'Playoff results',
      TOURNAMENT_FINISHED: 'Tournament finished',
      FINISH_TOURNAMENT: 'Finish tournament',
      RESULTS_ERROR: 'No qualifiyers data',
      FIRST_PLACE: 'First place',
      THIRD_PLACE: 'third place',
      FINAL: 'Final',
      QUATERFINAL: 'Quaterfinal',
      SEMIFINAL: 'Semifinal',
    },
    rangkings: {
      NAME_RANKING: 'Name',
      DESCRIPTION_RANKING: 'Description',
      CONTROL_RANKING: 'Control',
      ADD_RANKING_BUTTON: 'Add',
      ADD_RANKING_MODAL: 'Add ranking',
      REQUIRED_FIELD: 'Field cannot be empty',
    },
    addRanking: {
      ADD_RANKING_SUCCESS: 'Ranking added',
      ADD_NAME: 'Name',
      TEAM_RANKING: 'Team ranking',
      ADD_DESCRIPTION: 'Description',
      ADD_SAVE: 'Save',
    },
    deleteRanking: {
      DELETE_MODAL: 'Delete',
      DELETE_RANKING_SUCCESS: 'Rank deleted',
      CONFIRM_DELETE: 'Delete rank?',
      CANCEL_DELETE: 'Cancel',
      DELETE: 'Delete',
    },
    editRanking: {
      EDIT_RANKING: 'Change rank',
      EDIT_RANKING_SUCCESS: 'Rank changed',
      EDIT_NAME: 'Name',
      TEAM_RANKING: 'Team rank',
      EDIT_DESCRIPTION: 'Description',
      EDIT_SAVE: 'Save',
    },
    rating: {
      RATING_NAME: 'Rating name',
      RATING_DESCRIPTION: 'Description',
      RATING_CONTROL: 'Control',
      ADD_RATING_MODAL: 'Add rating',
      DELETE_RATING_MODAL: 'Delete',
      EDIT_RATING_MODAL: 'Edit',
      ADD_RATING_BUTTON: 'Add',
      RATING_LIST_IS_EMPTY: 'Rank has not added yet',
      REQUIRED_FIELD: 'Field cannot be ampty',
      TYPE_NUMBER: 'Inut number',
      NUMBER_MORE_THAN_ZERO: 'Input number greater than 0',
      REQUIRED_COUNTRY: 'Please select the country',
      TYPE_INTEGER_NUMBER: 'Please input integer',
      RATING_ADD_SUCCESS: 'Rating added',
      RATING_EDIT_SUCCESS: 'Rating edited',
      PLACEHOLDER_NAME: 'Input name',
      LABEL_RATING: 'Rating',
      LABEL_COUNTRY_RATING: 'Rating country',
      PLACEHOLDER_COUNTRY: 'Select the country',
      LABEL_NATIONAL_RATING: 'National rating',
      LABEL_MAX_PARTICIPANTS: 'Maximum tournaments in rating',
      SAVE_BUTTON: 'Save',
      RATING_DELETE_SUCCESS: 'Rating deleted',
      CANCEL_DELETE: 'Cancel',
      CONFIRM_DELETE: 'Delete rating?',
    },
    tackles: {
      ADD_TACKLES: 'Add tackles',
      EDIT_TACKLES: 'Edit tackles',
      SPINNING: 'Rod',
      REEL: 'Reel',
      WIRE: 'Lure',
      TACKLE: 'Tackle'
    },
    tournamentReports: {
      REPORTS: 'Reports',
      WRITE_REPORT: 'Create report',
      NAME_OF_REPORT: 'Report name',
      TEXT_OF_REVIEW: 'Report body',
      INTRO_OF_REPORT: 'Intro',
      SUMMARY: 'Final',
      ADD_REPORT_SUCCESS: 'Report added',
      EDIT_REPORT_SUCCESS: 'Report changed',
      DELETE_REPORT_SUCCESS: 'Report deleted',
      EDIT_REPORT: 'Edit report',
      CHOOSE_TOURNAMENT: 'Select tournament',
      REQUIRED_TOURNAMENT: 'Please select tournament',
      PHOTO: 'Image',
      DELETE_REPORT: 'Delete report',
      DELETE_REPORT_QUESTION: 'Delete report?',
    },
    registrationButton: {
      SIGN_IN_FOR_JOINING: 'Authorise to participate',
      REGISTER_IN_TOURNAMENT: 'Participate',
      REGISTER_IN_TOURNAMENT_CONFIRM: 'Are you sure you want to register for the selected tournament?',
      WAITING_LIST: 'Add to the waiting list',
      WAITING_LIST_CONFIRM: 'Are you sure you want to be added to the waiting list?',
      LEAVE_WATING_LIST: 'Leave waiting list',
      LEAVE_WATING_LIST_CONFIRM: 'Are you sure  you want to leave the waiting list?',
      ABORT_REGISTRATION: 'Cancel participation',
      ABORT_REGISTRATION_CONFIRM: 'Are you sure you want to leave the tournament?',
      REGISTER_TEAM: 'Participate team',
      REGISTER_TEAM_CONFIRM: 'Are you sure you want to register a team for the selected tournament?',
      WAITING_LIST_TEAM: 'Add team to the waiting list',
      WAITING_LIST_CONFIRM_TEAM: 'Are you sure you want to add the team to the waiting list?',
      LEAVE_WATING_LIST_TEAM: 'Laeve waiting list',
      LEAVE_WATING_LIST_CONFIRM_TEAM: 'Are you sure you want to leave waiting list?',
      ABORT_REGISTRATION_TEAM: 'Cancel team participation',
      ABORT_REGISTRATION_CONFIRM_TEAM: 'Are you sure you want to cancel team participation?',
    },
    registrationModal: {
      ADDED_TO_THE_WAITING_LIST: 'You have added to the waiting list',
      REGISTERED_SUCCESSFULLY: 'Registered successfully',
      LEAVE_WAITING_LIST: 'Left wait list',
      CANCELLED_REGISTRATION: 'Participation cancelled',
      CONTINUE: 'Continue',
    },
    teamRegistrationModal: {
      CHOOSE_TEAM: 'Please choose the team',
      LABEL_TEAM: 'Team',
      EMPTY_TEAM_LIST: 'No teams available',
      SAVE_BUTTON: 'Done',
    },
    seasons: {
      ADD_SEASON_BUTTON: 'Add',
      ADD_SEASON_MODAL: 'Add season',
      DELETE_SEASON_MODAL: 'Delete season',
      EDIT_SEASON_MODAL: 'Edit season',
      ADD_SEASON_SUCCESS: 'Season added',
      LABEL_NAME: 'Name',
      LABEL_DESCRIPTION: 'Description',
      CONTROL: 'Control',
      SEASONS_LIST_IS_EMPTY: 'No seasons added',
      SAVE_BUTTON: 'Save',
      DELETE_SEASON_SUCCESS: 'Seaon deleted',
      DELETE_SEASON_CONFIRM: 'Are you sure you want to delete season?',
      CANCEL_DELETE: 'Cancel',
      DELETE: 'Delete',
      EDIT_SEASON_SUCCESS: 'Season edited',
      NAME_OF_SEASON: 'Season name',
      REQUIRED_FIELD: 'Field cannot be emptyм',
    },
    tableTemplates: {
      NO_DATA_AVAILABLE: 'No data available',
      TOUR: 'Round',
      ZONE: 'Sector',
      CATCHES: 'Catches',
      POINTS: 'Points',
      ENEMY: 'Opponent',
      YOUR_RESULT: 'Yours',
      NAME: 'Name',
      PARTICIPANTS: 'Participants',
      WIN: 'Wins',
      DRAWS: 'Draws',
      LOSSES: 'Looses',
    },
    teamCard: {
      APPROVED: 'The angler confirmed his participation in this team',
      PENDING: 'The angler has not yet confirmed his participation in this team',
      DECLINED: 'The angler refused to participate in this team',
      DISBANDED: 'Team deleted',
      WITHOUT_TEAM: 'You dont participane any team',
      YOU: 'You',
      DELETE_TEAM: 'Delete team',
      DELETE_TEAM_CONFIRM: 'Are you sure you want to delete team?',
      CONFIRM_BUTTON: 'Ok',
      CANCEL_BUTTON: 'Cancel',
      DELETE_TEAM_SUCCESS: 'Team deleted',
    },
    tournamentFormat: {
      ADD_FORMAT_BUTTON: 'Add',
      ADD_FORMAT_MODAL: 'Add format',
      EDIT_FORMAT_MODAL: 'Edit format',
      DELETE_FORMAT_MODAL: 'Delete format',
      REQUIRED_FORMAT: 'Add format name',
      DELETE_FORMAT_CONFIRM: 'Are you sure you want to delete format?',
      DELETE: 'Delete',
      FORMAT_NAME: 'Format name',
      FORMAT_DESCRIPTION: 'Description',
      FORMAT_ADD_SUCCESS: 'Format added',
      FORMAT_EDIT_SUCCESS: 'Format edited',
      FORMAT_DELETE_SUCCESS: 'Format deleted',
      FORMAT_NAME_ADD: 'Name',
      FORMAT_RULES: 'Rules',
      SAVE_BUTTON: 'Save',
      CANCEL_BUTTON: 'Cancel',
      CONTROL: 'Control',
      FORMATS_ARE_EMPTY: 'No formats',
    },
    tournamentResult: {
      PLACE: 'Place',
      TEAM: 'Team',
      ANGLER: 'Angler',
      CATCHES: 'Catches',
      POINTS: 'Points',
      EMPTY_DATA: 'No data available',
    },
    finishedTournament: {
      FINISHED: 'Finished',
    },
    weather: {
      MORNING: 'Morning:',
      DAY: 'Day:',
      EVENING: 'Evening:',
      WIND_SPEED: 'm/s',
    },
    tournamentsTemplate: {
      TEMPLATE_BUTTON_ADD: 'Add',
      TEMPLATE_MODAL_ADD: 'Add template',
      TEMPLATE_MODAL_EDIT: 'Edit template',
      TEMPLATE_MODAL_DELETE: 'Delete template',
      DIGITS_AFTER_POINT: 'Must be no more than one decimal place',
      REQUIRED_FIELD: 'Field cannot be empty',
      TYPE_INTEGER_NUMBER: 'Input integer',
      TYPE_NUMBER_MORE_THAN_ZERO: 'Input number greater than zero',
      REQUIRED_NUMBER: 'Input number',
      ADD_TEMPLATE_SUCCESS: 'Template added',
      TEMPLATE_NAME: 'Name',
      ROUNDS_COUNT: 'Rounds',
      TEMPLATE_DESCRIPTION: 'Description',
      SAVE_BUTTON: 'Save',
      TEMPLATE_DELETE_SUCCESS: 'Template deleted',
      TEMPLATE_EDIT_SUCCESS: 'Template edited',
      TEMPLATE_DELETE_CONFIRM: 'Are you sure you want to delete template?',
      CANCEL_BUTTON: 'Cancel',
      DELETE: 'Delete',
      TEMPLATE_NAME_COLUMN: 'Template name',
      CONTROL: 'Control',
      TEMPLATES_LIST_IS_EMPTY: 'No templates available',
    },
    userImage: {
      LABEL_CHOOSE_IMG: 'Choose file',
      SAVE_BUTTON: 'Save',
      UPLOAD_PHOTO: 'Upload photo',
      SIZE_ERROR: 'Image size more than 20mb',
    },
    userProfile: {
      EDIT_PROFILE: 'Edit profile',
      MY_PROFILE: 'My profile',
      TEAMS: 'Teams',
      SIGN_OUT: 'Log out',
      ADMIN: 'Admin',
      SIGN_IN: 'Sign in',
      SIGN_UP: 'Sign up',
      EMPTY_TEAMS_LIST: 'No teams available',
    },
    configureTable: {
      RESULT_OF_TOUR: 'Result',
      CONFIGURE_DESCRIPTION: 'Description',
      POINTS: 'Score',
      POINT_LABEL: 'Point',
      WIN: 'Win',
      WIN_DESCRIPTION: 'Own catches > opponent catches',
      DRAW: 'Draw',
      DRAW_WITH_RESULT: 'Fish draw',
      DRAW_RESULT_DESCRIPTION_FIRST: 'а. Количество своих поимок = количеству поимок оппонента',
      DRAW_RESULT_DESCRIPTION_SECOND: 'b. Catches not equal 0',
      DRAW_NULL: 'Zero draw',
      DRAW_NULL_DESCRIPTION_FIRST: 'a. Own catches = 0',
      DRAW_NULL_DESCRIPTION_SECOND: 'b. Opponent catches = 0',
      DEFEAT: 'Defeat',
      DEFEAT_RESULT: 'Defeat score < 2',
      DEFEAT_RESULT_DESCRIPTION_FIRST: 'a. The number of own captures is not equal to zero',
      DEFEAT_RESULT_DESCRIPTION_SECOND: 'b. The ratio of the number of catches of the opponent / the number of own catches < 2',
      DEFEAT_LESS_TWO: 'Defeat score ≥ 2',
      DEFEAT_LESS_TWO_DESCRIPTION_FIRST: 'a. The number of own captures is not equal to zero',
      DEFEAT_LESS_TWO_DESCRIPTION_SECOND: 'b. The ratio of the number of catches of the opponent / the number of own catches ≥ 2',
      DEFEAT_NULL: 'Нулевое поражение',
      DEFEAT_NULL_DESCRIPTION_FIRST: 'a. The number of own captures = 0',
      DEFEAT_NULL_DESCRIPTION_SECOND: 'b. The number of opponent captures > 0',
    }
  },
  pages: {
    ABOUT_US: 'Abuot us',
    ACCEPT_INVITATION: 'Accept invitation',
    ANNOUNCEMENT: 'Announcement',
    AREA: 'Area',
    AREATROUT: 'Areatrout',
    SPONSORS: 'Partners',
    AREATROUT_INVITATION: 'Participate in competitions, share your impressions, and see results. Conduct your own competition',
    AREATROUT_LOGO: 'Areatrout logo',
    AREATROUT_IMAGE: 'Areatrout photo',
    ARTICLE: 'Article',
    BAD_BEHAVIOR: 'Misbehaviour',
    BLOCK_USER: 'Block',
    CATCHES: 'Catches',
    CANCEL_DRAWING: 'Cancel drawings',
    CLOSE_REGISTARTION: 'Close registration',
    CLOSEST_TOURNAMENTS: 'Upcoming tournaments',
    COUNTRY: 'Country',
    COUNTRIES: 'Countries',
    CHOOSE_LANGUAGE: 'Choose langvige',
    CREATE_TEAM: 'Create team',
    DATA: 'Date',
    DATE: 'Date',
    DECLINE_INVITATION: 'Decline invitation',
    DESCRIPTION: 'Description',
    DEFINE_NUMBERS: 'Define numbers',
    DRAWING: 'Drawing',
    DRAW_WITH_POINTS: 'Draw with catches',
    EDIT_TEAM: 'Edit team',
    ENTER_TEAM_NAME: 'Enter team name',
    FORMAT: 'Format',
    FORMATS: 'Formats',
    FILTERS: 'Filters',
    FINAL: 'Final',
    FINISHED_TOURNAMENTS: 'Completed tournaments',
    HEAT_MAP: 'Heat map',
    IMAGES: 'Tournament photos',
    INDIVIDUAL_TOURNAMENT: 'Individual',
    INCOMING_INVITATIONS: 'Incuming invitations',
    INVITATION_FROM: 'Invitation from',
    LOCATION: 'Location',
    LOCATIONS: 'Locations',
    LESS_2X_DEFEAT: ' Defeat with catches &#60; 2 times ',
    LEFT: 'L',
    MAIN_PAGE: 'Main page',
    MAIN_LIST: 'List of participants',
    MORE_2X_DEFEAT: ' Defeat with catches &#8805; 2  times ',
    MY_POINTS: 'My',
    NUM: '№',
    NAME: 'Name',
    NEWS_ARTICLES: 'News',
    NEWS: 'News',
    NOMINATION: 'Name',
    NO_DATA: 'No data',
    NO_REGISTERED_USERS: 'No registered players available',
    NO_AVAILABLE_TOURNAMENTS: 'No tournaments available',
    NO_INVITATIONS: 'No invitations',
    OPEN_REGISTARTION: 'Allow registration',
    REGISTER_ANGLER_TO_TOURNAMENT: 'Register participant',
    ADD_PARTICIPANT: 'Add participant',
    OPPONENT: 'Opponent',
    PART_OF_FINAL: 'final',
    PARTICIPANT_CARDS: 'Participants cards',
    PARTICIPANTS_LIMIT: 'Max participants',
    PARTICIPANTS_NUMBER: 'Number of participants',
    PIECE_OF_NEWS: 'News',
    PREVIEW: 'Preview',
    PROJECT_MANAGEMENT: 'Project mamagement',
    POINTS: 'Points',
    PLAYOFF: 'Playoff',
    PLAYOFF_RESULT: 'Playoff results',
    PUBLICATION: 'Publish',
    QUALIFIERS: 'Qualifiers',
    QUALIFACATION_RESULTS: 'Qualifiers results',
    QUARTER_FINAL: 'Quarter final',
    RANKING: 'Ranking',
    RANKINGS: 'Rank',
    RATING: 'Rating',
    RATINGS: 'Ratings',
    RIGHT: 'R',
    RULES: 'Rules',
    REGULATIONS: 'Regulations',
    REGULATIONS_LINK_WASNT_ADDED: 'Regulation link not added',
    REGISTRATION: 'Registrations',
    REVIEW: 'Report',
    RULES_LINK_WASNT_ADDED: 'Rules link not added',
    ROUND: 'Round',
    SELECT_COUNTRY: 'Choose country',
    SELECT_PARTNER_EMAIL: 'Eneer partners E-mail ',
    SELECT_ANGLER_EMAIL: 'Enter angler E-mail',
    SEASON: 'Season',
    SEASONS: 'Seasons',
    SEARCH_BY_NAME: 'Search',
    SEARCH_BY_ARTICLE_NAME: 'Search',
    SEARCH_BY_FULLNAME: 'Search',
    SECTOR: 'Zone',
    SEMI_FINAL: 'Semifinal',
    SPORTSMAN_RATINGS: 'Ratings',
    SIGN: 'Sign',
    SIGN_INTO_ACCAUNT: 'Sign in',
    STATUS: 'Status',
    TEMPLATES: 'Templates',
    TEAMS: 'Teams',
    TEAM_TOURNAMENT: 'Team competition',
    THIRD_PLACE_FINAL: 'Third place',
    TOURNAMENT_STATUS: 'Tournament status',
    TOURNAMENTS: 'Tournaments',
    TOURNAMENT_IMAGES: 'Galery',
    TOURNAMENT_RESULT: 'Final results',
    TOURNAMENT_DESCRIPTION: 'Tournament description',
    TOURNAMENT_REGULATIONS: 'Tournament regulations',
    TEMPLATE: 'Template',
    UNBLOCK_USER: 'Unblock',
    USERS_IMAGE: 'User image',
    WAITINGS_lIST: 'Waitlist',
    WIN: 'Win',
    ZERO_DRAW: 'Zero draw',
    ZERO_DEFEAT: 'Zero defeat',
    SCORE_COUNT_DESCRIPTION: `
    <h2 style='text-align: center'>Calculation rules</h2>
    <h3>Qualifiers</h3>
    <p>
    In the qualifying rounds, the opponents compete in pairs. Each fish caught and released according to the rules counts.
      <br />
      At the end of the round, athletes compare their captures. The player with the most catches wins.
    </p>
    <p>
    Points are awarded according to the rules.
    </p>`
  }
};

export default en;