import UpcomingTournamentEntry from '../../../../models/pages/tournament/UpcomingTournamentEntry';

export const modifyClosestTournamentData = (tournament: UpcomingTournamentEntry) => ({
  ...tournament,
  ratings: tournament.ratings.map((rating) => rating.name),
  participantPhotos: tournament.threeParticipants.map(({ participant }) => participant.angler.profilePicture)
});

export const modifyFinishedTournamentData = (tournament: UpcomingTournamentEntry) => ({
  rankingName: tournament.ranking.name,
  participantsCount: tournament.participantsCount,
  tournamentId: tournament.id,
  locationName: tournament.location.name!,
  locationId: tournament.location.id,
  formatName: tournament.format,
  tournamentName: tournament.name,
  tournamentDate: tournament.date,
  seasonName: tournament.season,
  ratingsNames: tournament.ratings.map((rating) => rating.name),
  topThreeParticipants: tournament.threeParticipants
});