import PersonalMeetingsData from '../../../../models/pages/user/PersonalMeetingsData';

const calculateRate = (number: number, total: number) => Math.ceil((number / total) * 100) || 0;

const transformMeetingsData = (opponentData: PersonalMeetingsData) => {
  const meetingsCount = opponentData.drawsCount + opponentData.lossesCount + opponentData.winsCount;
  return {
    ...opponentData,
    winRate: calculateRate(opponentData.winsCount, meetingsCount),
    drawRate: calculateRate(opponentData.drawsCount, meetingsCount),
    lossesRate: calculateRate(opponentData.lossesCount, meetingsCount),
    meetingsCount
  };
};

const addRatesToMeetingsData = (data: PersonalMeetingsData[]) => data.map(transformMeetingsData);

export default addRatesToMeetingsData;