import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    paddingTop: 50,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 32,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  basicText: {
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: '30px'
  }
}));

export default useStyles;