import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './helpers/ValidationSchema';
import UserSingIn from '../../../models/components/authorization/UserSingIn';
import AuthorizationService from '../../../services/main/Authorization.service';
import Toasters from '../../popUp/PopUp';
import useStyles from './SingIn.styles';
import buttonStyles from '../../button/Button.styles';
import ATButton from '../../button/Button';
import Routes from '../../../routing/Routes';
import AuthorizationResponse from '../../../models/components/authorization/AuthorizationResponse';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import TextInput from '../../inputs/textField/TextInput';
import PasswordInput from '../../inputs/password/Password';
import AuthorizationComponents from '../helpers/Constants';
import Loader from '../../loader/Loader';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
  setModalComponent: null
};

function SignIn(props: {
  handleClose?: EmptyVoidFunction;
  setModalComponent?: React.Dispatch<React.SetStateAction<JSX.Element>>
}) {
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  const l10n = useTranslation();
  const { handleClose, setModalComponent } = props;
  const {
    control, handleSubmit, formState, reset, formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const handlerSignIn = async (data: UserSingIn) => {
    setIsLoading(true);

    const res: AuthorizationResponse = await AuthorizationService.signIn(data);

    if (res && res.error && setModalComponent) {
      if (res.statusCode === 17) {
        setModalComponent(AuthorizationComponents.ActivationFailed);
      } else if (res.statusCode === 18) {
        setModalComponent(AuthorizationComponents.ActivationExpired(data.email));
      } else {
        Toasters.error(res.message || l10n.components.error.LOGIN_PASSWORD_ERROR);
        setErrorMessage(res.message || l10n.components.error.LOGIN_PASSWORD_ERROR);
      }
    } else {
      window.location.replace(Routes.Home.path);
      if (handleClose) {
        handleClose();
      }
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className={classes.centeredWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={classes.signInForm}>
        <Typography
          component="h1"
          variant="h5"
          className={classes.title}
        >
          {l10n.components.signInForm.SIGN_IN}
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handlerSignIn)}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                label="E-mail"
                name="email"
                errors={errors.email}
                field={{ ...field }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <PasswordInput
                label={l10n.components.signInForm.LABEL_PASSWORD}
                name="password"
                errors={errors.password}
                field={{ ...field }}
              />
            )}
          />
          <div className={classes.recoveryPasswordButton}>
            <button
              onClick={() => (setModalComponent ? setModalComponent(AuthorizationComponents.RecoveryPassword) : null)}
              className={clsx(classes.basicText, classes.link)}
              type="button"
            >
              {l10n.components.signInForm.BUTTON_FORGOT_PASSWORD}
            </button>
          </div>
          <div className={clsx(classes.invalid)}>
            {errorMessage}
          </div>
          <div className={classes.signInBtn}>
            <ATButton
              isSubmit
              variant="primary"
              fill="solid"
              size="lg"
              className={buttonStyle.button}
            >
              {l10n.components.signInForm.BUTTON_SIGN_IN}
            </ATButton>
          </div>
        </form>
        <Grid container className={classes.signUpLink}>
          <Grid item>
            <div>
              {l10n.components.signInForm.WITHOUT_ACCOUNT}
              <button
                onClick={() => (setModalComponent ? setModalComponent(AuthorizationComponents.SignUp) : null)}
                className={clsx(classes.link, classes.basicText)}
                type="button"
              >
                {l10n.components.signInForm.REGISTRATION}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SignIn;

SignIn.defaultProps = defaultProps;