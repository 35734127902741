import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './About.styles';
import useTranslation from '../../components/localization/customHooks/Translation';

function About() {
  const l10n = useTranslation();
  const styles = useStyles();

  return (
    <Grid>
      <h2 className={styles.heading}>
        {l10n.pages.ABOUT_US}
      </h2>
    </Grid>
  );
}

export default About;