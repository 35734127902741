import Rating from '../../../models/components/dictionaries/rating/Rating';
import RatingSelectItem from '../../../models/components/selects/RatingSelectItem';
import {
  remove, get, post, put
} from '../../Http';
import defaultSelectValues from '../../helpers/Constants';

const url = '/api/Ratings/';

const RatingService = {
  async getAsSelectItems(): Promise<RatingSelectItem[]> {
    const ratings = (await this.get()) || [];
    return ratings.map((rating: RatingSelectItem) => ({
      ...rating,
      isDefault: rating.name === defaultSelectValues.rating,
    }));
  },

  async get() {
    return get(url);
  },

  async post(rating: Rating) {
    return post(url, rating);
  },

  async put(rating: Rating) {
    return put(url + rating.id, rating);
  },

  async delete(id: number) {
    return remove(url + id);
  },

  async getAnglersRating<T>(data: { seasonId: T; ratingId: T }, searchString?: string) {
    return get(`${url}results`, undefined, {
      params: { ratingId: data.ratingId, seasonId: data.seasonId, searchQuery: searchString },
    });
  },
};

export default RatingService;