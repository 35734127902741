import { object, string } from 'yup';
import { getTranslation } from '../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  spinning: string()
    .required(l10n.components.error.EMPTY_FIELD_ERROR)
    .min(2, l10n.components.error.FIELD_LENGTH_ERROR)
    .max(256, l10n.components.error.FIELD_LENGTH_ERROR),
  reel: string()
    .required(l10n.components.error.EMPTY_FIELD_ERROR)
    .min(2, l10n.components.error.FIELD_LENGTH_ERROR)
    .max(256, l10n.components.error.FIELD_LENGTH_ERROR),
  wire: string()
    .required(l10n.components.error.EMPTY_FIELD_ERROR)
    .min(2, l10n.components.error.FIELD_LENGTH_ERROR)
    .max(256, l10n.components.error.FIELD_LENGTH_ERROR),
});

export default schema;