import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  FacebookShareButton, FacebookIcon, VKShareButton, VKIcon
} from 'react-share';
import useStyles from './ShareLinks.styles';

const ShareLinks = () => {
  const classes = useStyles();

  const path = useLocation().pathname;

  const shareLink = `${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${path}`;

  return (
    <div className={classes.socialButtons}>
      <FacebookShareButton url={shareLink}>
        <FacebookIcon className={classes.shareButton} />
      </FacebookShareButton>
      <VKShareButton url={shareLink}>
        <VKIcon className={classes.shareButton} />
      </VKShareButton>
    </div>
  );
};

export default ShareLinks;