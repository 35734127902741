import React, {
  Dispatch, SetStateAction, useEffect, useState, FocusEvent
} from 'react';
import {
  Button, TextField, Box, FormHelperText,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import AnglerData from './AnglerData';
import useStyles from './RegisterAnglerModal.styles';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';
import UserService from '../../../../../../../services/main/User.service';
import schema from './helpers/ValidationSchema';
import RegistrationService from '../../../../../../../services/main/Registration.service';
import Toasters from '../../../../../../../components/popUp/PopUp';
import { useStores } from '../../../../../../../stores/Main';
import Participant from '../../../../../../../models/pages/tournament/Participant';

const RegisterAngler = observer((props: {handleClose: Dispatch<SetStateAction<boolean>>}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleClose } = props;
  const { TournamentStore } = useStores();
  const l10n = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [user, setUser] = useState<Participant | null>(null);

  useEffect(() => {
    setUser(null);
  }, [email]);

  const findUser = async (currentEmail: string) => {
    if (email) {
      const response = await UserService.getUserByEmail(currentEmail);
      if (response) {
        setUser(response);
      }
    }
  };

  const registerFoundAngler = async (userId: number, tournamentId: number) => {
    if (user) {
      const response = await RegistrationService.registerUserByAdmin(tournamentId, userId);
      if (response) {
        Toasters.success(l10n.components.toasterMessage.USER_HAS_BEEN_ADDED);
        handleClose(false);
      }
    }
  };

  const submitRegistrtion = () => {
    if (user) {
      registerFoundAngler(user!.id, TournamentStore.tournament!.id);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitRegistrtion)}>
        <Box m={2} p={1} mb={2} className={classes.wrapperSearchBar}>
          <div className={classes.fullWidth}>
            {user ? <AnglerData anglerData={user} setAnglerData={setUser} disabled={false} setEmail={setEmail} />
              : (
                <Controller
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => setEmail(event.target.value.trim())}
                        rows={1}
                        id="filled-multiline-flexible"
                        label={l10n.components.tableColumns.PARTICIPANT}
                        variant="outlined"
                        error={!!errors.email}
                        fullWidth
                        autoFocus
                        placeholder={l10n.pages.SELECT_ANGLER_EMAIL}
                        inputProps={{ 'data-testid': 'email' }}
                      />
                      <FormHelperText data-testid="emailError" className={classes.invalid}>
                        {!!errors?.email && errors?.email?.message}
                      </FormHelperText>
                    </>
                  )}
                  name="email"
                  control={control}
                  defaultValue={email}
                />
              )}
          </div>
          <Button
            variant="contained"
            color="primary"
            data-testid="saveBtn"
            size="small"
            className={classes.searchButton}
            onClick={() => findUser(email)}
          >
            {l10n.components.button.FIND_ANGLER}
          </Button>
        </Box>
        <Box mb={1} mt={3} p={1} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            data-testid="saveBtn"
            type="submit"
          >
            {l10n.components.tableColumns.ADD_PARTICIPANT}
          </Button>
        </Box>
      </form>
    </>
  );
});

export default RegisterAngler;