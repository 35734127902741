import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';
import TournamentService from '../../services/main/Tournament.service';
import RegisteredParticipant from '../../models/pages/tournament/RegisteredParticipant';
import useStyles from './MemberList.styles';
import MemberListTable from './table/MemberList';
import Loader from '../loader/Loader';
import RegistrationButton from '../registrationButton/RegistrationButton';
import UserListData from '../../models/pages/user/UserList';
import { EmptyVoidFunction } from '../../utils/types/Types';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';
import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';
import useTranslation from '../localization/customHooks/Translation';

const MemberList = (props: {
  users: UserListData | ClosestTournamentCardData | UpcomingTournamentEntry,
  getTournaments: EmptyVoidFunction | undefined
}) => {
  const { users, getTournaments } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [activeParticipants, setActiveParticipants] = useState<RegisteredParticipant[]>([]);
  const [awaitingParticipants, setAwaitingParticipants] = useState<RegisteredParticipant[]>([]);

  const addParticipantNumber = (participants: RegisteredParticipant[]) => {
    participants.forEach((participant: RegisteredParticipant, index: number) => { participant.number = index + 1; });

    return participants;
  };

  const fetchAllParticipants = async () => {
    setIsLoading(true);
    const allRegistrations = await TournamentService.getAllExistingRegistrations(users.id);

    if (allRegistrations) {
      setActiveParticipants(addParticipantNumber(allRegistrations.activeRegistrations));
      setAwaitingParticipants(addParticipantNumber(allRegistrations.waitListRegistrations));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllParticipants();
  }, [users]);

  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        className={classes.title}
      >
        {l10n.components.memberList.PARTICIPANTS_LIST}
      </Typography>
      <Grid container alignItems={activeParticipants.length ? 'baseline' : 'center'} className={classes.tables}>
        {isLoading
          ? <Loader />
          : (
            <>
              <MemberListTable participants={activeParticipants} />
              {Boolean(awaitingParticipants.length) && (
                <>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={clsx(classes.title, classes.text)}
                  >
                    {l10n.components.memberList.WAITING_LIST}
                  </Typography>
                  <MemberListTable participants={awaitingParticipants} />
                </>
              )}
            </>
          )}
      </Grid>
      <Grid container justify="center" className={classes.button}>
        <RegistrationButton tournament={users} getTournaments={getTournaments} />
      </Grid>
    </>
  );
};

export default MemberList;