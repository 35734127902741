import React from 'react';
import { Typography } from '@material-ui/core';
import LocationData from '../../../models/components/dictionaries/location/LocationData';
import useStyles from './styles';
import LocationLogoImg from './LocationLogoImg';
import RTEtext from '../../RTEtext/RTEtext';
import LinksPanel from './LinksPanel';

const InfoPanel = (props: { location: LocationData }) => {
  const { location } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {location.logo && (
        <LocationLogoImg location={location} />
      )}
      <div className={classes.contentWrapper}>
        <Typography variant="h2" className={classes.title}>
          { location.name }
        </Typography>
        <div className={classes.country}>
          <RTEtext text={location.country?.name || ''} />
        </div>
        {(location.instagramLink || location.facebookLink || location.websiteLink || location.phoneNumber) && (
        <LinksPanel location={location} />
        )}
      </div>
    </div>
  );
};

export default InfoPanel;