import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import useStyles from './ParticipantsCardTemplate.styles';
import CardTemplate from '../tableTemplates/cardTemplate/CardTemplate';
import Participant from '../../models/pages/tournament/Participant';
import { getImageUrl } from '../../utils/Utils';
import { setOpponentErrorRound } from '../../pages/admin/tournaments/edit/participantsCards/ParticipantsCards.utils';
import IncorrectCatchesRound from '../../models/pages/tournament/captures/IncorrectCatchesRound';
import { EmptyVoidFunction } from '../../utils/types/Types';
import useTranslation from '../localization/customHooks/Translation';

function ParticipantsCardTemplate(props: {
    filteredParticipantsData: Participant[];
    participantsData: Participant[];
    isCardsCompleted: Function;
    getErrorsCount: EmptyVoidFunction
}) {
  const {
    filteredParticipantsData, participantsData, getErrorsCount, isCardsCompleted
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>([]);

  const updateOpponent = (incorrectRound: IncorrectCatchesRound) => {
    setOpponentErrorRound(participants, incorrectRound);
    getErrorsCount();
  };

  useEffect(() => {
    setParticipants(participantsData);
  }, [participantsData]);

  useEffect(() => {
    setFilteredParticipants(filteredParticipantsData);
  }, [filteredParticipantsData]);

  return (
    <Paper className={classes.scroll}>
      {filteredParticipants?.length
        ? filteredParticipants.map((participant: Participant) => (
          <Paper className={classes.card} key={participant.id} id={`${participant.anglerId}`}>
            <Box className={classes.head}>
              <Box className={classes.image}>
                {participant.angler.profilePicture && (
                  <img
                    className={classes.logo}
                    src={getImageUrl(participant.angler.profilePicture.url)}
                    alt={l10n.components.participantCardTemplate.PHOTO_ALT}
                  />
                )}
              </Box>
              <Typography
                className={classes.id}
                variant="h4"
              >
                {participant.anglerNumber}
              </Typography>
              <Typography
                variant="h4"
              >
                {participant.angler.fullName}
              </Typography>
            </Box>
            <CardTemplate participant={participant} updateOpponent={updateOpponent} isCardsCompleted={isCardsCompleted} />
          </Paper>
        ))
        : (
          <Typography variant="h6" align="center">
            {l10n.components.participantCardTemplate.DATA_IS_EMPTY}
          </Typography>
        )}
    </Paper>
  );
}

export default ParticipantsCardTemplate;