import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import useStyles from '../../Location.styles';
import { useStores } from '../../../../stores/Main';
import Routes from '../../../../routing/Routes';
import { defaultMapCoords } from '../../../../utils/constants/Constants';

const LocationsMap = () => {
  const classes = useStyles();
  const { LocationStore } = useStores();
  const mapState = {
    center: defaultMapCoords,
    zoom: 9,
  };

  return (
    <div className={classes.mapContainer}>
      <YMaps enterprise query={{ apikey: process.env.REACT_APP_YANDEX_MAP_KEY }}>
        <Map
          modules={[
            'geoObject.addon.balloon',
          ]}
          width="100%"
          height="100%"
          state={mapState}
        >
          {LocationStore.locations?.map((location) => (
            <Placemark
              geometry={[location.latitude, location.longitude]}
              key={location.id}
              draggable={false}
              properties={{
                balloonContentBody: [
                  `
                    <a class=${classes.placemarkBalloonLink} href="${Routes.Location.path.replace(':id', String(location.id))}" >
                       ${location.name}
                    </a>
                      `,
                ]
              }}
            />
          ))}
        </Map>
      </YMaps>
    </div>
  );
};

export default LocationsMap;