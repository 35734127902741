import React, { useState } from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import useStyles from '../styles';
import hiddenText from '../../../assets/icons/signForm/hiddenText.svg';
import visibleText from '../../../assets/icons/signForm/visibleText.svg';

function Password(props: {
  name: string
  errors: FieldError | undefined
  field: { name: string, value: string }
  label: string
}) {
  const classes = useStyles();
  const {
    name, errors, field, label
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <>
      <FormHelperText
        className={clsx(classes.label, classes.basicText)}
      >
        {label}
      </FormHelperText>
      <div className={classes.passwordWrapper}>
        <TextField
          {...field}
          className={clsx(classes.input, classes.basicText)}
          type={isPasswordVisible ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          id={name}
          name={name}
          error={errors && !!errors}
          helperText={errors?.message}
        />
        <button
          type="button"
          onClick={() => setIsPasswordVisible((prev) => !prev)}
          className={classes.toggleTextSecurity}
        >
          <img src={isPasswordVisible ? visibleText : hiddenText} alt="textVisibility" />
        </button>
      </div>
    </>
  );
}

export default Password;