export const emptyTeamData = {
  name: '',
  email: '',
  countryId: '',
  pictureId: '',
  partnerId: '',
};

export const emptyPartner = {
  id: '',
  name: '',
  email: '',
  profilePicture: null
};