import React from 'react';
import clsx from 'clsx';
import MemberAvatar from './avatar/Avatar';
import ImageData from '../../models/components/image/ImageData';
import { useStylesMember } from './Member.styles';

const defaultProps = {
  countryFlag: null,
  avatarStyle: '',
  className: '',
  userNameStyle: '',
  children: null
};

const Member = (props: {
  profilePicture: ImageData | null | undefined
  fullName: string | undefined
  countryFlag?: ImageData | null;
  avatarStyle?: string,
  className?: string
  userNameStyle?: string
  children?: JSX.Element | JSX.Element[] | null
}) => {
  const {
    profilePicture, fullName, countryFlag, avatarStyle, className, userNameStyle, children
  } = props;
  const classes = useStylesMember();
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.avatarContainer}>
        <MemberAvatar countryFlag={countryFlag} profilePicture={profilePicture} avatarStyle={avatarStyle} />
        {children}
      </div>
      <div className={clsx(classes.userName, userNameStyle)}>
        {fullName}
      </div>
    </div>
  );
};

Member.defaultProps = defaultProps;

export default Member;