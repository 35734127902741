import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    maxWidth: 32,
    maxHeight: 32,
  },
  flag: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'fill'
  }
}));

export default useStyles;