import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { v4 } from 'uuid';
import AppRoutes from '../routing/Routes';
import Menu from '../components/menu/Menu';
import { theme, useStyles } from './App.styles';
import Footer from '../components/footer/Footer';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import ProtectedRoute from '../routing/ProtectedRoute';

function App() {
  const styles = useStyles();
  const publicRoutes = Object.values(AppRoutes).filter(({ canActivateFunc }) => !canActivateFunc);
  const protectedRoutes = Object.values(AppRoutes).filter(({ canActivateFunc }) => canActivateFunc);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <main className={styles['@global']}>
          <Menu />
          <div className={styles.mainWrapper}>
            <Routes>
              {publicRoutes.map(({ path, component }) => (
                <Route key={path} path={path} Component={component} />
              ))}

              {protectedRoutes.map(({ path, component, canActivateFunc }) => (
                <Route key={v4()} element={<ProtectedRoute isAccessAllowedFunc={canActivateFunc} />}>
                  <Route key={path} path={path} Component={component} />
                </Route>
              ))}
            </Routes>
          </div>
          <Footer />
        </main>
      </Router>
    </ThemeProvider>
  );
}

export default App;