/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import AreatroutTable from '../../../../components/table';
import useStyles from './OpponentsTable.styles';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import { sortingType } from '../../../../utils/enum/Enum';
import PersonalMeetingsData from '../../../../models/pages/user/PersonalMeetingsData';
import UserService from '../../../../services/main/User.service';
import Angler from '../../../../components/angler/Angler';
import MeetingTable from '../meeting/table/Table';
import MeetingModal from '../meeting/modal/MeetingsModal';
import addRatesToMeetingsData from './OpponentsTable.utils';
import RateCell from '../../../../components/tableTemplates/components/cell/rate/Rate';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import SearchInput from '../../../../components/searchInput/SearchInput';
import { limitCharsFullNameSearch } from '../../../../utils/constants/Constants';

const OpponentTable = (props: {
  anglerId: string
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { anglerId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<PersonalMeetingsData[] | null>(null);
  const [filteredTableData, setFilteredTableData] = useState<PersonalMeetingsData[] | null>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const getOpponents = async (id: string) => {
    setIsLoading(true);
    const response = await UserService.getOpponents(id);
    if (response) {
      const responseData = addRatesToMeetingsData(response);
      setTableData(responseData);
      setFilteredTableData(responseData);
    }
    setIsLoading(false);
  };

  const setMeetingModal = async (userId: string, opponentId: string) => {
    setComponent(<MeetingTable opponentId={opponentId} userId={anglerId} />);
    setIsModalOpened(true);
  };

  const columns = [
    {
      displayName: l10n.components.tableColumns.SPORTSMAN,
      fieldName: 'opponentFullName',
      render: ({
        opponentFullName, opponentId, opponentCountry, opponentProfilePicture
      }: PersonalMeetingsData) => (
        <div className={classes.fullName}>
          <Angler
            anglerData={{
              fullName: opponentFullName,
              profilePicture: opponentProfilePicture,
              country: opponentCountry,
              id: opponentId,
            }}
            className={classes.mobileContainer}
          />
        </div>
      )
    },
    {
      displayName: l10n.components.tableColumns.MEETINGS,
      fieldName: 'meetingsCount',
      render: ({ meetingsCount, opponentId }: PersonalMeetingsData) => (
        <div onClick={() => setMeetingModal(anglerId, opponentId)} className={`${classes.basicCell} ${classes.link}`}>{meetingsCount}</div>
      )
    },
    {
      displayName: l10n.components.tableColumns.RESULTS,
      fieldName: 'winRate',
      customSort: (a: any, b: any) => {
        if (a.winRate === b.winRate) {
          if (a.drawRate > b.drawRate) {
            return 1;
          }
          if (a.drawRate < b.drawRate) {
            return -1;
          }
          return a.lossesRate > b.lossesRate ? 1 : -1;
        }
        return a.winRate > b.winRate ? 1 : -1;
      },
      defaultSort: sortingType.desc,
      render: ({ winRate, drawRate, lossesRate }: PersonalMeetingsData) => (
        <div className={classes.rateContainer}>
          <RateCell rate={winRate} isWin />
          <RateCell rate={drawRate} isDraw />
          <RateCell rate={lossesRate} isDefeat />
        </div>
      )
    },
  ];

  function searchFunction(opponent: PersonalMeetingsData) {
    return opponent.opponentFullName.toLowerCase().includes(searchText.trim().toLowerCase());
  }

  useEffect(() => {
    if (tableData) {
      const searchResult = tableData.filter(searchFunction);

      if (searchResult) {
        setFilteredTableData(searchResult);
      }
    }
  }, [searchText]);

  useEffect(() => {
    getOpponents(anglerId);
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <>
      <SearchInput
        placeholder={l10n.pages.SEARCH_BY_FULLNAME}
        onChange={setSearchText}
        className={classes.search}
        maxLength={limitCharsFullNameSearch}
      />
      {tableData && (
        <AreatroutTable
          className={classes.table}
          columns={columns}
          rows={filteredTableData}
          isThirdSortClickAllowed={false}
          isPaging={false}
        />
      )}
      {component
        && (
          <MeetingModal
            open={isModalOpened}
            setOpen={setIsModalOpened}
            component={component}
          />
        )}
    </>
  );
};

export default OpponentTable;