import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import NewsItem from '../../../models/pages/news/NewsItem';
import { articles } from '../../../utils/enum/Enum';
import ArticleContent from '../content/Content';
import ArticleFooter from '../footer/Footer';
import Routes from '../../../routing/Routes';

function Links(props: {
  article: NewsItem
}) {
  const { article } = props;

  const getArticleLink = () => {
    if (article.type === articles.review) {
      return Routes.TournamentReport.path.replace(':id', String(article.id));
    }

    return Routes.Publication.path.replace(':id', String(article.id));
  };

  return (
    <div>
      <Link to={getArticleLink()}>
        <ArticleContent article={article} key={uuidv4()} />
      </Link>
      <ArticleFooter article={article} />
    </div>
  );
}

export default Links;