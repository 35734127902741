import React from 'react';
import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import TemplatesTable from './table/TemplateTable';
import useStyles from './Template.style';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import Modal from '../../../../../components/modal/Modal';
import AddTemplateModal from './modals/add/Add';
import EditTemplateModal from './modals/edit/Edit';
import DeleteTemplateConfirm from './modals/delete/Delete';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const TournamentTemplates = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();

  const { TournamentTemplatesStore } = useStores();

  const getAllTemplates = () => {
    TournamentTemplatesStore.refreshTemplates();
  };

  return (
    <div className={classes.tableWrapper}>
      <Button
        data-testid="add-button"
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddCircleOutline />}
        onClick={() => TournamentTemplatesStore.toggleAddTemplateModalState()}
      >
        {l10n.components.tournamentsTemplate.TEMPLATE_BUTTON_ADD}
      </Button>
      <Modal
        header={l10n.components.tournamentsTemplate.TEMPLATE_MODAL_ADD}
        open={TournamentTemplatesStore.isAddModalOpen}
        setOpen={TournamentTemplatesStore.toggleAddTemplateModalState}
        onClose={() => getAllTemplates()}
        modalClassName={classes.modalWrapper}
      >
        <AddTemplateModal />
      </Modal>
      <Modal
        header={l10n.components.tournamentsTemplate.TEMPLATE_MODAL_EDIT}
        open={TournamentTemplatesStore.isEditModalOpen}
        setOpen={TournamentTemplatesStore.toggleEditTemplateModalState}
        onClose={() => getAllTemplates()}
        modalClassName={classes.modalWrapper}
      >
        <EditTemplateModal />
      </Modal>
      <Modal
        header={l10n.components.tournamentsTemplate.TEMPLATE_MODAL_DELETE}
        open={TournamentTemplatesStore.isDeleteModalOpen}
        setOpen={() => {}}
        onClose={TournamentTemplatesStore.toggleDeleteTemplateModalState}
      >
        <DeleteTemplateConfirm />
      </Modal>
      {TournamentTemplatesStore.templates ? (
        <TemplatesTable
          templatesList={TournamentTemplatesStore.templates}
        />
      ) : (
        <LoaderFullScreen />
      )}
    </div>
  );
});

export default TournamentTemplates;