import React from 'react';
import useStyles from './Url.styles';

const UrlCell = (props: {url: string, name: string }) => {
  const { url, name } = props;
  const classes = useStyles();
  return (
    <a className={classes.link} href={url}>{name}</a>
  );
};

export default UrlCell;