import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AreatroutModal from '../../../../../../../../components/modal/Modal';
import DeleteRankingModal from '../DeleteRanking';
import useTranslation from '../../../../../../../../components/localization/customHooks/Translation';

type onFunction = () => void;

const Wrapper = (props: { getRankings: onFunction, id: number }) => {
  const { getRankings, id } = props;
  const [open, setOpen] = useState(false);
  const l10n = useTranslation();

  return (
    <>
      <AreatroutModal
        header={l10n.components.deleteRanking.DELETE_MODAL}
        open={open}
        setOpen={setOpen}
        onClose={getRankings}
      >
        <DeleteRankingModal rankingId={id} />
      </AreatroutModal>

      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => setOpen(true)}
      >
        <Delete />
      </IconButton>
    </>
  );
};

export default Wrapper;