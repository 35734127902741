import React, { useState } from 'react';
import useStyles from './Desxription.styles';
import CollapsibleSection from '../../../../../../components/collapsibleSection/CollapsibleSection';
import RTEtext from '../../../../../../components/RTEtext/RTEtext';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  className: '',
};

const Description = (props: {
  isOpen: boolean;
  text: string | undefined;
  className?: string,
  label: string
}) => {
  const {
    text, className, isOpen, label
  } = props;
  const l10n = useTranslation();
  const classes = useStyles();
  const [openSection, setOpenSection] = useState<boolean>(isOpen);

  return (
    <>
      {text
        && (
          <div className={className}>
            <button
              type="button"
              className={classes.button}
              onClick={() => setOpenSection(((prevState) => !prevState))}
            >
              {label || l10n.pages.DESCRIPTION}
            </button>
            <CollapsibleSection
              isSectionOpen={openSection}
              setIsSectionOpen={setOpenSection}
              isMobileViewDisabled
              className={classes.section}
            >
              <RTEtext text={text} />
            </CollapsibleSection>
          </div>
        )}
    </>
  );
};

export default Description;

Description.defaultProps = defaultProps;