import React from 'react';
import { Grid, List } from '@material-ui/core';
import AreatroutTable from '../../../../../../components/table/Table';
import TournamentTemplate from '../../../../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';
import EditDeleteCell from './cells/EditDelete';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';
import useStyles from '../Template.style';

function TemplatesTable(props: {
    templatesList: TournamentTemplate[] | null,
}) {
  const {
    templatesList,
  } = props;

  const l10n = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      displayName: l10n.components.tournamentsTemplate.TEMPLATE_NAME_COLUMN,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.tournamentsTemplate.TEMPLATE_DESCRIPTION,
      fieldName: 'description',
    },
    {
      displayName: l10n.components.tournamentsTemplate.ROUNDS_COUNT,
      fieldName: 'roundsCount',
    },
    {
      displayName: l10n.components.tournamentsTemplate.CONTROL,
      fieldName: 'action',
      allowSearch: false,
      allowSortring: false,
      render: (template: TournamentTemplate) => (
        <EditDeleteCell
          template={template}
        />
      ),
    },
  ];

  return (
    <>
      <Grid className={classes.tableWidth}>
        <List>
          {templatesList && templatesList.length
            ? <AreatroutTable columns={columns} rows={templatesList} className={classes.templateTable} />
            : <p>{l10n.components.tournamentsTemplate.TEMPLATES_LIST_IS_EMPTY}</p>}
        </List>
      </Grid>

    </>
  );
}

export default TemplatesTable;