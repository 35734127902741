import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 360
    }
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  mobileWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  quarterFinals: {
    maxWidth: 624,
    width: 624,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto'
    }
  },
  semiFinals: {
    maxWidth: 462,
    width: 462,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto'
    }
  },
  final: {
    maxWidth: 724,
    width: 724,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto'
    }
  },
  sectionParticipants: {
    gap: 32,
    [theme.breakpoints.down('xs')]: {
      gap: 24,
      alignSelf: 'center',
      margin: 'auto',
    }
  },
  resultPairText: {
    margin: '4px 4px 0 4px',
    fontWeight: 'bold',
    lineHeight: '32px',
    fontSize: 24,
  },
  dash: {
    color: '#bdbdbd',
  },
  resultWinnerText: {
    fontWeight: 'bold',
    lineHeight: '24px',
    fontSize: 16,
    margin: '-8px, auto',
  },
  quarterHeader: {
    margin: '0px 0px 24px 12px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  innerFinal: {
    gap: 6,
  },
  outerFinal: {
    marginTop: 145,
    gap: 20
  },
  finalHeader: {
    margin: '0px 30px 5px 145px',
  },
  headerSemiFinal: {
    marginLeft: 142
  },
  header: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      margin: '32px 0 0',
      fontWeight: 'bold',
    }
  },
  firstHeader: {
    marginTop: 0
  },
  subHeader: {
    marginLeft: 150,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
  },
  semiParticipants: {
    gap: 200,
    marginTop: 100
  },
  wideContainer: {
    width: 360,
  },
  shortContainer: {
    width: 300,
  },
  coupleWrapper: {
    boxSizing: 'border-box',
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: 25,
      height: 156,
      alignSelf: 'stretch',
      backgroundImage: 'linear-gradient(#E2E4F0, #E2E4F0), linear-gradient(#E2E4F0, #E2E4F0), linear-gradient(#E2E4F0, #E2E4F0)',
      backgroundSize: '100% 2px, 100% 2px, 2px 52%',
      backgroundPosition: '0 24%, 0 77%, 100% 51%',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('xs')]: {
        content: 'none'
      }
    }
  },
  innerWrapper: {
    gap: 6,
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  anglerContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    gap: 10,
    height: 75,
    border: '1px solid #E2E4F0',
    borderRadius: 7,
    padding: 14
  },
  anglerContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 30%',
    '& .MuiAvatar-root': {
      marginBottom: 4,
    },
  },
  qualificationInfoWrapper: {
    display: 'flex',
    marginTop: 8,
    '& div:first-child': {
      marginRight: 8
    }
  },
  line: {
    width: 140,
    height: 100,
    stroke: '#E2E4F0',
    strokeWidth: 2,
  },
  semiFinalsLine: {
    width: 140,
    height: 80,
  },
  semiFinalsUpperLine: {
    marginTop: -15
  },
  semiFinalsLowerLine: {
    marginTop: 14
  },
  crossedLines: {
    position: 'absolute',
    width: 145,
    height: 290,
  },
  finalsLine: {
    position: 'absolute',
    width: 145,
    height: 80,
    marginTop: -5
  },
  finalsLine_2: {
    marginTop: -110,
  },
  finalsLine_3: {
    marginTop: -125,
  },
  finalsLine_4: {
    marginTop: 10,
  },
  anglerFinalWrapper: {
    marginLeft: 145
  },
  qualificationInfo: {
    gap: 3,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    [theme.breakpoints.down('xs')]: {
      '& img': {
        width: 20,
        height: 20
      },
      '& div': {
        fontSize: 16,
        lineHeight: 'normal'
      }
    }
  },
  userName: {
    width: 160,
    alignSelf: 'center',
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 'min-content',
      alignItems: 'center',
      gap: 10,
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      textAlign: 'center'
    }
  },
  anglerCatches: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  anglerInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  h2: {
    marginBottom: 32,
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginBottom: 0,
    }
  },
  input: {
    width: 48,
    height: 46,
    borderRadius: 7,
    borderColor: '#C1C1CA',
    '& .MuiOutlinedInput-input': {
      padding: 0,
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      textAlign: 'center'
    },
    '& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    '& input[type=number]': {
      appearance: 'textfield',
      margin: 0,
    },
  },
  subWinner: {
    boxSizing: 'border-box',
    border: '2px solid #19A801',
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: 4,
      height: 8,
      alignSelf: 'stretch',
      backgroundImage: 'linear-gradient(#19A801, #19A801)',
      backgroundSize: '100% 50%',
      position: 'absolute',
      top: 32,
      right: -4,
      borderRadius: 4
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'rgba(25, 168, 1, 0.05)',
    },
    [theme.breakpoints.down('xs')]: {
      color: '#19A801',
      border: 'none',
      '&::after': {
        content: 'none',
      }
    }
  },
  winner: {
    boxSizing: 'border-box',
    border: '2px solid #19A801',
    backgroundColor: 'rgba(25, 168, 1, 0.05)'
  },
  winnerInput: {
    backgroundColor: 'rgba(25, 168, 1, 0.05)',
    '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #19A801',
    }
  },
  mobileCard: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  userNameContainer: {
    whiteSpace: 'normal',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  invalid: {
    color: 'red',
  },
}));

export default useStyles;