import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 20,
  },
  textLink: {
    textDecoration: 'underline'
  }
}));

export default useStyles;