import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',
    [theme.breakpoints.down('md')]: {
      marginTop: 160,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 90,
    },
  },
  wrapper: {
    width: '100%',
    maxWidth: 410,
    '& .MuiFormControl-fullWidth': {
      height: 74,
      '& .Mui-error': {
        margin: '3px 0 0'
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 328,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '38px',
    textAlign: 'center',
    color: '#141429',
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 32
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#141429',
    marginTop: 24,
    marginBottom: 16
  },
  signUpLink: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: ' #0DA9B3',
    backgroundColor: 'inherit',
    border: 'none',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '135%',
    cursor: 'pointer'
  },
  titleLinkSend: {
    marginTop: 16
  },
  checkmarkBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 48,
    height: 48,
    width: 48,
    margin: '0 auto',
    background: 'rgba(13, 169, 179, 0.2)',
  },
  checkmarkSuccess: {
    color: '#0DA9B3',
    fontSize: 24,
    lineHeight: '24px',
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  }
}));

export default useStyles;