import { sortingType } from '../../../../../utils/enum/Enum';
import Round from '../../../../../models/pages/tournament/Round';
import IncorrectCatchesRound from '../../../../../models/pages/tournament/captures/IncorrectCatchesRound';
import { errorStyleBackgroundColor } from '../../../../../utils/constants/Constants';
import Participant, { ParticipantInfo } from '../../../../../models/pages/tournament/Participant';

const determineLeftAndRightValues = (left: any, right: any, direction: string) => ({
  first: direction === sortingType.asc ? left : right,
  second: direction === sortingType.asc ? right : left
});

export const isCardCompleted = (rounds: Round[]) => rounds.every((round: Round) => round.anglerCatches !== null
  && round.opponentCatches !== null
  && round.anglerCatches !== null);

export const transformParticipantData = (data: ParticipantInfo[]) => data.map(({ participant }: ParticipantInfo) => ({
  ...participant,
  isCompleted: isCardCompleted(participant.rounds),
  rowStyles: {},
  angler: {
    ...participant.angler,
    profilePicture: participant.angler.profilePicture || null,
    profilePictureId: participant.angler.profilePictureId || null,
  },
  rounds: participant.rounds.sort((prev, next) => prev.roundNumber - next.roundNumber) || null,
}));

export const sortByNumberField = (
  array: Participant[], direction: string = sortingType.asc
) => array.sort((left: Participant, right: Participant) => {
  const { first, second } = determineLeftAndRightValues(left.anglerNumber, right.anglerNumber, direction);
  return first - second;
});

export const sortByStringField = (array: Participant[], direction: string) => array.sort((left: Participant, right: Participant) => {
  const { first, second } = determineLeftAndRightValues(left.angler.fullName, right.angler.fullName, direction);
  return first > second ? 1 : -1;
});

export const getRound = (array: Participant[], id: string, roundNumber: number) => {
  const opponentCard = array.find((participant) => participant.anglerId === id);
  return opponentCard?.rounds.find((round) => round.roundNumber === roundNumber);
};

export const updateHighlightForParticipant = (opponentCard: Participant) => {
  if (opponentCard.rounds.some((round: Round) => round.hasError)) {
    opponentCard.rowStyles = errorStyleBackgroundColor;
  } else {
    opponentCard.rowStyles = {};
  }
};

export const setErrorStyleToRow = (array: Participant[], id: string) => {
  const opponentCard = array.find((participant: Participant) => participant.anglerId === id);
  if (opponentCard) {
    updateHighlightForParticipant(opponentCard);
  }
};

export const setOpponentErrorRound = (array: Participant[], incorrectRound: IncorrectCatchesRound | Round) => {
  const opponentRound = getRound(array, incorrectRound.opponentId, incorrectRound.roundNumber);
  if (opponentRound) {
    opponentRound.hasError = incorrectRound?.hasError as boolean;
  }
  setErrorStyleToRow(array, incorrectRound.opponentId);
  setErrorStyleToRow(array, incorrectRound.anglerId);
};