import React, {
  FocusEvent, useEffect, useState
} from 'react';
import clsx from 'clsx';
import {
  FormControl, FormHelperText, Typography
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { schema, emailSchema } from '../../helpers/ValidationSchema';
import UserImage from '../../../../components/userImage/UserImage';
import useStyles from '../../Teams.styles';
import TextInput from '../../../../components/inputs/textField/TextInput';
import CountriesService from '../../../../services/main/dictionaries/Country.service';
import ATButton from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import TeamData from '../../../../models/pages/team/TeamData';
import TeamResponse from '../../../../models/pages/team/TeamResponse';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import UserService from '../../../../services/main/User.service';
import PartnerFields from '../../../../models/pages/team/PartnerFields';
import PartnerData from '../partnerData/PartnerData';
import TeamsService from '../../../../services/main/Team.service';
import sendIcon from '../../../../assets/icons/team/sendIcon.svg';
import { emptyPartner } from '../../helpers/Constants';
import SingleSelect from '../../../../components/selects/single/Single';
import { selectType } from '../../../../utils/enum/Enum';
import Toasters from '../../../../components/popUp/PopUp';
import useTranslation from '../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

function AddOrEditTeamModal(props: {
  handleClose?: EmptyVoidFunction;
  editTeamData: TeamData
  refresh: EmptyVoidFunction
}) {
  const { handleClose, editTeamData, refresh } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: editTeamData
  });

  const [profilePictureId, setProfilePictureId] = useState<number | null>(Number(editTeamData?.pictureId) || null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [partnerData, setPartnerData] = useState<PartnerFields>(emptyPartner);
  const [emailError, setEmailError] = useState<string>('');
  const [teamError, setTeamError] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const onFilterSelected = (_: string, value: string | number | null) => {
    if (value) {
      setValue('countryId', value.toString(), { shouldValidate: true, shouldDirty: true });
    }
  };

  const getPartnerData = async (value: string) => {
    const response = await UserService.validateEmail(value);
    if (response) {
      setPartnerData(response);
      setValue('partnerId', response.id);
    } else {
      setEmailError(l10n.components.error.WRONG_PARTNER_DATA);
    }
  };

  const validateEmail = () => {
    emailSchema.validate(email)
      .then(async () => {
        setEmailError('');
        getPartnerData(email);
      })
      .catch(() => setEmailError(l10n.components.error.ENTER_PARTNER_EMAIL));
  };

  useEffect(() => {
    if (editTeamData.email) {
      getPartnerData(editTeamData.email);
    }
  }, [editTeamData.email]);

  const createTeam = async (data: TeamData) => {
    setIsLoading(true);
    const result = { ...data };
    if (profilePictureId) {
      result.pictureId = profilePictureId.toString();
    }
    const response: TeamResponse = await TeamsService.post(result);
    if (response.errorMessage) {
      setIsLoading(false);
      return setTeamError(response.errorMessage);
    }
    if (response && handleClose) {
      refresh();
      Toasters.success(l10n.components.toasterMessage.TEAM_WAS_CREATED);
      return handleClose();
    }
    return setIsLoading(false);
  };

  const editTeam = async (data: TeamData) => {
    setIsLoading(true);
    const result = { ...data };
    if (profilePictureId) {
      result.pictureId = profilePictureId.toString();
    }
    const response: TeamResponse = await TeamsService.put(result);
    if (response && handleClose) {
      refresh();
      Toasters.success(l10n.components.toasterMessage.TEAM_WAS_EDITED);
      return handleClose();
    }
    if (response.errorMessage) {
      setIsLoading(false);
      return setTeamError(response.errorMessage);
    }
    return setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={classes.addTeamContainer}>
      <form
        onSubmit={editTeamData.name ? handleSubmit(editTeam) : handleSubmit(createTeam)}
        className={classes.form}
      >
        <Typography className={classes.modalHeader}>{editTeamData.name ? l10n.pages.EDIT_TEAM : l10n.pages.CREATE_TEAM}</Typography>
        <div className={classes.addImage}>
          <UserImage
            pictureId={profilePictureId}
            setPictureId={setProfilePictureId}
            variant="large"
          />
        </div>
        <div className={classes.teamData}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <div className={clsx(classes.flexColumn, classes.nameTeamColumn)}>
                <TextInput
                  placeHolder={l10n.pages.ENTER_TEAM_NAME}
                  label={l10n.components.label.TEAM_NAME}
                  name="name"
                  errors={errors.name}
                  field={{ ...field }}
                />
              </div>
            )}
          />
          <FormControl
            variant="outlined"
            fullWidth
            className={clsx(classes.flexColumn, { [classes.invalid]: !!errors.countryId })}
          >
            <SingleSelect
              filterProps={{
                filterName: 'countryId',
                getAvailableItems: CountriesService.get,
                label: l10n.pages.COUNTRY,
                placeHolder: l10n.pages.SELECT_COUNTRY,
                type: selectType.single
              }}
              defaultValue={editTeamData.countryId}
              onValueSelected={onFilterSelected}
              className={classes.select}
            />
            <FormHelperText
              className={classes.invalid}
            >
              {!!errors?.countryId && errors?.countryId?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className={clsx(classes.invitationContainer, { [classes.emptyGap]: Boolean(editTeamData.email) })}>
          <div className={classes.flexColumn}>
            {partnerData.email
              ? <PartnerData partnerData={partnerData} setPartnerData={setPartnerData} disabled={Boolean(editTeamData.email)} />
              : (
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <div className={classes.flexColumn}>
                      <TextInput
                        placeHolder={l10n.pages.SELECT_PARTNER_EMAIL}
                        label={l10n.components.label.PARTNER}
                        name="email"
                        errors={errors.email}
                        field={{ ...field }}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => setEmail(event.target.value.trim())}
                      />
                    </div>
                  )}
                />
              )}
          </div>
          <div className={clsx(classes.buttonContainer, classes.smallContainer, { [classes.hideContainer]: Boolean(editTeamData.name) })}>
            <ATButton
              className={clsx(classes.button, classes.findPartnerBtn, { [classes.hideBtn]: Boolean(editTeamData.name) })}
              variant="primary"
              fill="solid"
              size="ml"
              onClick={() => validateEmail()}
            >
              {l10n.components.button.FIND_PARTNER}
            </ATButton>
            <button
              onClick={() => validateEmail()}
              type="button"
              className={clsx(classes.sendEmailButton, { [classes.hideBtn]: Boolean(editTeamData.name) })}
            >
              <img src={sendIcon} alt="icon" />
            </button>
          </div>
        </div>
        <FormHelperText className={clsx(classes.invalid, classes.errorText, classes.partnerError)}>
          {emailError}
        </FormHelperText>
        <div className={clsx(classes.buttonContainer, classes.buttonSubmit, { [classes.wideContainer]: Boolean(editTeamData.name) })}>
          <ATButton
            isSubmit
            className={classes.button}
            variant="primary"
            fill="solid"
            size="ml"
            disabled={!isDirty || !isValid || !partnerData.email}
          >
            {editTeamData.name ? l10n.components.button.SAVE_CHANGES : l10n.components.button.CREATE_TEAM}
          </ATButton>
        </div>
      </form>
      <FormHelperText className={clsx(classes.invalid, classes.errorText, classes.teamError)}>
        {teamError}
      </FormHelperText>
    </div>
  );
}

AddOrEditTeamModal.defaultProps = defaultProps;

export default AddOrEditTeamModal;