/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import searchIcon from '../../assets/icons/search.svg';
import useStyles from './SearchInput.styles';

const defaultProps = {
  className: '',
  inputRef: undefined,
  name: '',
  error: false,
  helperText: undefined,
  disabled: false,
  defaultValue: '',
  maxLength: 256,
};

const SearchInput = (props: {
  placeholder: string,
  className?: string
  inputRef?: React.Ref<any> | undefined,
  name?: string,
  error?: boolean,
  helperText?: React.ReactNode,
  disabled?: boolean,
  defaultValue?: string,
  maxLength?: number,

  onChange: React.Dispatch<React.SetStateAction<string>>
}) => {
  const classes = useStyles();
  const {
    placeholder, className, inputRef, name, error, helperText, disabled, onChange, defaultValue, maxLength
  } = props;

  const debounced = useDebouncedCallback((inputValue) => {
    onChange(inputValue);
  }, 300);

  return (
    <TextField
      inputRef={inputRef}
      type="text"
      variant="outlined"
      disabled={disabled}
      name={name}
      defaultValue={defaultValue}
      className={clsx(classes.root, className)}
      placeholder={placeholder}
      onChange={(e) => debounced(e.target.value)}
      error={error}
      helperText={helperText}
      inputProps={{
        maxLength,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={searchIcon} alt="search" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;

SearchInput.defaultProps = defaultProps;