import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: colors.primary,
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    padding: 0
  },
  wrapper: {
    color: colors.secondary,
    padding: '12px 36px 36px',
    lineHeight: '24px',
  },
  title: {
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center'
  },
  subtitle: {
    fontWeight: 'bold',
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        '&.MuiDialog-paperScrollPaper': {
          maxHeight: '100%'
        }
      },
    },
  }
}));

export default useStyles;