import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';
import ColumnMetadata from '../../models/components/table/ColumnMetadata';
import AppliedTournamentsFiltersModel from '../../models/pages/availiableTournaments/AppliedTournamentsFiltersModel';

export default class SearchService {
  columns: ColumnMetadata[];

  constructor(columns: ColumnMetadata[]) {
    this.columns = columns;
  }

  search(tournaments: any[], inputValue: string) {
    return tournaments.filter((tournament: UpcomingTournamentEntry) => (
      this.columns.some((column: ColumnMetadata) => {
        if (!column.allowSearch) {
          return tournament[column.fieldName]?.toString().toLowerCase().includes(inputValue.trim().toLowerCase());
        }
        return false;
      })));
  }

  filterTournamentBySelectedFilters = (competitions: UpcomingTournamentEntry[], filter: AppliedTournamentsFiltersModel) => {
    const filterKeys = Object.keys(filter);
    return competitions.filter((competition: UpcomingTournamentEntry) => filterKeys.every((eachKey: string) => {
      const filteredItemsArray = filter[eachKey].map((item: string) => item.toLowerCase());
      if (!filter[eachKey].length) {
        return true;
      }
      if (Array.isArray(competition[eachKey])) {
        return competition[eachKey].some((item: string) => filteredItemsArray.some(((filteredItem) => item.toLowerCase().includes(filteredItem))));
      }
      if (eachKey === 'ranking') {
        const rankingName = competition.ranking.name;
        return filteredItemsArray.some(((filteredItem) => rankingName.toLowerCase().includes(filteredItem)));
      }
      return competition[eachKey]
        && filteredItemsArray.some(((filteredItem) => competition[eachKey].toLowerCase().includes(filteredItem)));
    }));
  }

  getFiltersCount = (filtersParams: AppliedTournamentsFiltersModel) => Object.values(filtersParams).reduce((acc, current) => (
    current.length ? acc + 1 : acc), 0)
}