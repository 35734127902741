import React, { useEffect, useState } from 'react';
import {
  Button, TextField, Box, Grid, FormHelperText,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/Main';
import schema from './helpers/ValidationSchema';
import Country from '../../../../../../models/components/dictionaries/country/Country';
import Loader from '../../../../../../components/loader/Loader';
import useStyles from '../Countries.style';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import UserImage from '../../../../../../components/userImage/UserImage';
import Toasters from '../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const AddOrEditCountry = observer((props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Country>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { handleClose } = props;
  const { CountriesStore } = useStores();
  const classes = useStyles();
  const l10n = useTranslation();
  const [pictureId, setPictureId] = useState<number | null>(CountriesStore.country.flagPictureId);

  const sendPostRequest = async (data: Country) => {
    const response = await CountriesStore.addCountry(data);
    if (response.id) {
      Toasters.success(l10n.components.countries.ADD_COUNTRY_SUCCESS);
      CountriesStore.refreshCountries();
    }
    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => { CountriesStore.setCountryFlag(pictureId); }, [pictureId]);

  const sendPutRequest = async (data: Country) => {
    const response = await CountriesStore.editCountry(data);
    if (response.id) {
      Toasters.success(l10n.components.countries.EDIT_COUNTRY_SUCCESS);
      CountriesStore.refreshCountries();
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (CountriesStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form
      onSubmit={handleSubmit(
        CountriesStore.country.id ? sendPutRequest : sendPostRequest,
      )}
    >
      <Box m={2} p={1} mb={3}>
        <Controller
          render={({ field }) => (
            <>
              <TextField
                {...field}
                id="filled-multiline-flexible"
                label={l10n.components.countries.COUNTRY_NAME}
                rowsMax={4}
                variant="outlined"
                error={!!errors.name}
                multiline
                autoFocus
                className={classes.input}
                inputProps={{ 'data-testid': 'name' }}
              />
              <FormHelperText data-testid="nameError" className={classes.invalid}>
                {!!errors?.name && errors?.name?.message}
              </FormHelperText>
            </>
          )}
          name="name"
          control={control}
          defaultValue={CountriesStore.country.name}
        />
      </Box>
      {Boolean(CountriesStore.country.id) && <UserImage setPictureId={setPictureId} pictureId={pictureId} />}
      <Box mb={1} mt={3} p={1} display="flex" justifyContent="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          data-testid="saveBtn"
        >
          {l10n.components.countries.SAVE_BUTTON}
        </Button>
      </Box>
    </form>
  );
});

export default AddOrEditCountry;