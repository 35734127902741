import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import TournamentService from '../../services/main/Tournament.service';
import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';
import AreatroutTable from '../../components/table';
import RegistrationButton from '../../components/registrationButton/RegistrationButton';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import DateCell from '../../components/tableTemplates/components/cell/data/Date';
import Rating from '../../models/components/dictionaries/rating/Rating';
import { sortingType } from '../../utils/enum/Enum';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import useStyles from './AvaliableTourmanents.styles';
import UserList from '../../components/userList/UserList';
import Flag from '../../components/flag/Flag';
import TournamentFilters from './components/tournamentsFilter/TournamentFilters';
import transformTournament from './AvailableTournaments.utils';
import AvailableTournamentsCards from './components/cards/Cards';
import SearchInput from '../../components/searchInput/SearchInput';
import SearchService from '../../services/main/Search.service';
import Routes from '../../routing/Routes';
import useTranslation from '../../components/localization/customHooks/Translation';
import { useStores } from '../../stores/Main';

export default function AvaliableTournaments() {
  const classes = useStyles();
  const l10n = useTranslation();
  const { LocationStore } = useStores();
  const [loader, setLoader] = useState(true);
  const [tournaments, setTournaments] = useState<Array<UpcomingTournamentEntry> | null>([]);
  const [searchTournaments, setSearchTournaments] = useState<Array<UpcomingTournamentEntry> | null>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredTournaments, setFilteredTournaments] = useState<UpcomingTournamentEntry[] | null>([]);

  const getTournaments = async () => {
    const response = await TournamentService.getAllTournaments();
    if (response) {
      const transformedTournament = transformTournament(response);
      setTournaments(transformedTournament);
      setFilteredTournaments(transformedTournament);
    }
    setLoader(false);
  };

  const columns = [
    {
      displayName: l10n.components.tableColumns.DATE,
      fieldName: 'date',
      defaultSort: sortingType.desc,
      render: ({ date }: UpcomingTournamentEntry) => <DateCell value={date} />,
    },
    {
      displayName: l10n.components.tableColumns.TOURNAMENT_NAME,
      fieldName: 'name',
      render: ({ name, id }: UpcomingTournamentEntry) => (
        <Link to={Routes.TournamentCard.path.replace(':id', String(id))}>
          <span className={classes.link}>{name}</span>
        </Link>
      )
    },
    {
      displayName: l10n.components.tableColumns.LOCATION_PLACE,
      fieldName: 'locationName',
      render: ({ location }: UpcomingTournamentEntry) => (
        <Link
          to={Routes.Location.path.replace(':id', String(location.id))}
          className={classes.flagWrapper}
          onClick={LocationStore.resetLocation}
        >
          {location?.country?.flag?.url && <Flag data={location?.country.flag} className={classes.flag} />}
          <span className={clsx(classes.link, classes.location)}>{location?.name}</span>
        </Link>
      )
    },
    {
      displayName: l10n.components.tableColumns.STATUS_TEXT,
      fieldName: 'status',
      allowSortring: false,
      allowSearch: false,
      cellStyle: { padding: 0 },
      render: (tournament: UpcomingTournamentEntry) => (
        <RegistrationButton
          tournament={tournament}
          getTournaments={getTournaments}
          className={classes.buttonStatus}
        />
      )
    },
    {
      displayName: l10n.components.tableColumns.PARTICIPANTS,
      fieldName: '',
      allowSortring: false,
      allowSearch: false,
      render: (tournament: UpcomingTournamentEntry) => (
        <div className={classes.usersListWrapper}>
          <UserList
            users={tournament}
            getTournaments={getTournaments}
            className={classes.usersList}
          />
        </div>
      )
    },
    {
      displayName: l10n.components.tableColumns.RATING,
      fieldName: 'ratings',
      allowSortring: false,
      render: ({ ratings }: UpcomingTournamentEntry) => <span className={classes.link}>{ratings.map((rating: Rating) => rating.name).join(', ')}</span>
    },
    {
      displayName: l10n.components.tableColumns.RANKING,
      fieldName: 'ranking.name',
    },
    {
      displayName: l10n.components.tableColumns.FORMAT,
      fieldName: 'format',
    },
  ];
  const url = [
    { displayName: l10n.pages.MAIN_PAGE, url: '/' },
    { displayName: l10n.pages.TOURNAMENTS, url: '/tournaments' }
  ];
  const searchService = new SearchService(columns);

  useEffect(() => {
    if (filteredTournaments) {
      const searchResult = searchService.search(filteredTournaments, searchText);

      if (searchResult) {
        setSearchTournaments(searchResult);
      }
    }
  }, [searchText, filteredTournaments]);

  useEffect(() => {
    getTournaments();
  }, []);

  if (loader) {
    return <LoaderFullScreen />;
  }

  return (
    <Grid className={clsx(classes.root, classes.tournaments)}>
      <Breadcrumbs urls={url} className={classes.breadcrumbs} />
      <div className={classes.title}>{l10n.pages.TOURNAMENTS}</div>
      {tournaments ? (
        <>
          <Grid container justify="space-between">
            <SearchInput
              placeholder={l10n.pages.SEARCH_BY_NAME}
              onChange={setSearchText}
              className={classes.search}
            />
            <TournamentFilters
              searchService={searchService}
              setFilteredTournaments={setFilteredTournaments}
              tournaments={tournaments}
            />
          </Grid>
          <AreatroutTable className={classes.table} columns={columns} rows={searchTournaments} isSearchVisible={false} />
          <AvailableTournamentsCards tournaments={searchTournaments} getTournaments={getTournaments} />
        </>
      ) : (
        <Grid container direction="row" justify="center">
          {l10n.pages.NO_AVAILABLE_TOURNAMENTS}
        </Grid>
      )}
    </Grid>
  );
}