import React, { useState } from 'react';
import {
  ListItem, IconButton, Backdrop, CircularProgress
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../stores/Main';
import Tackle from '../../../../models/components/tackles/Tackle';
import useStyles from '../../Tackles.styles';
import useTranslation from '../../../localization/customHooks/Translation';
import useConfirmationDialog from '../../../useConfirmationDialog/UseConfirmationDialog';
import Modal from '../../../modal/Modal';
import AddEditTackle from '../modals/AddEditTackle';
import Toasters from '../../../popUp/PopUp';

const EditDelete = observer((props: { data: Tackle }) => {
  const classes = useStyles();
  const { TacklesStore } = useStores();
  const { data } = props;
  const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState(false);
  const l10n = useTranslation();

  const editTackle = (tackle: Tackle) => {
    TacklesStore.setTackle(tackle);
    setIsEditModalOpened(true);
  };

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: '',
    dialogClassName: classes.dialog,
    bodyText: l10n.components.confirmDialogsMessage.DELETE_TACKLE,
    confirmationButtonText: l10n.components.button.CONFIRM,
    cancelButtonText: l10n.components.button.CANCEL,
    onConfirmClick: async () => {
      onClose();
      setIsloading(true);
      const res = await TacklesStore.deleteTackle(data);
      setIsloading(false);
      if (res) {
        Toasters.success(l10n.components.toasterMessage.TACKLES_WERE_DELETED);
      }
    },
    onCancelClick: () => onClose()
  });

  return (
    <>
      <ListItem className={classes.buttonCell}>
        <IconButton
          className={classes.controlButton}
          onClick={() => editTackle(data)}
          edge="start"
          aria-label="edit"
        >
          <Edit />
        </IconButton>
        <IconButton
          className={classes.controlButton}
          edge="end"
          aria-label="delete"
          data-testid="delete-button"
          onClick={() => onOpen()}
        >
          <Delete />
        </IconButton>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog />
      </ListItem>
      <Modal
        header={l10n.components.tackles.EDIT_TACKLES}
        open={isEditModalOpened}
        setOpen={setIsEditModalOpened}
        className={classes.modalWide}
        modalClassName={classes.modal}
      >
        <AddEditTackle isAddModalOpened={false} />
      </Modal>
    </>
  );
});

export default EditDelete;