import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './Info.styles';
import mapPin from '../../../../assets/icons/map-pin.svg';
import star from '../../../../assets/icons/star.svg';
import format from '../../../../assets/icons/primanka.svg';
import ranking from '../../../../assets/icons/ranking.svg';
import UserList from '../../../userList/UserList';
import { tournamentStatus } from '../../../../utils/enum/Enum';
import RegistrationButton from '../../../registrationButton/RegistrationButton';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import ClosestTournamentCardData from '../../../../models/pages/tournament/ClosestTournamentCardData';
import Routes from '../../../../routing/Routes';

const ClosestTournamentInfo = (props: {
  closestTournament: ClosestTournamentCardData
  getTournaments: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const { closestTournament, getTournaments } = props;

  return (
    <div>
      <ul className={classes.info}>
        <li className={classes.list}>
          <img src={mapPin} alt="map-pin" />
          <Link to={Routes.Location.path.replace(':id', String(closestTournament?.location.id))}>
            <button type="button" className={classes.button}>{closestTournament?.location.name}</button>
          </Link>
        </li>
        <li className={classes.list}>
          <img src={star} alt="star-icon" />
          <span>
            {closestTournament?.ratings.map((rating) => rating).join(', ')}
          </span>
        </li>
        <li className={classes.list}>
          <img src={format} alt="format-icon" />
          <span>
            {closestTournament?.format}
          </span>
        </li>
        <li className={classes.rankingMobile}>
          <img src={ranking} alt="format-icon" className={classes.rankingIcon} />
          <span>{closestTournament?.ranking.name}</span>
        </li>
        <li className={classes.list}>
          <UserList
            users={closestTournament}
            getTournaments={getTournaments}
          />
          <span className={classes.rankingDesktop}>{closestTournament?.ranking.name}</span>
        </li>
      </ul>
      {closestTournament?.statusId === tournamentStatus.Open && (
        <RegistrationButton
          tournament={closestTournament}
          getTournaments={getTournaments}
          className={classes.registrationButton}
        />
      )}
    </div>
  );
};

export default ClosestTournamentInfo;