import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import Rating from '../../../../../../models/components/dictionaries/rating/Rating';
import useStyles from '../Rating.styles';

function EditDelete(props: {
    data: Rating,
    setSelectedRating: React.Dispatch<React.SetStateAction<Rating>>,
    setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  const classes = useStyles();
  const {
    data, setSelectedRating, setOpenEdit, setOpenDelete,
  } = props;

  const editFormat = (format: Rating) => {
    setSelectedRating(format);
    setOpenEdit(true);
  };

  const openDelModal = (format: Rating) => {
    setSelectedRating(format);
    setOpenDelete(true);
  };

  return (
    <ListItem className={classes.buttonCell}>
      <IconButton
        className={classes.button}
        onClick={() => editFormat(data)}
        edge="start"
        aria-label="edit"
      >
        <Edit />
      </IconButton>
      <IconButton
        className={classes.button}
        edge="end"
        aria-label="delete"
        onClick={() => openDelModal(data)}
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
}

export default EditDelete;