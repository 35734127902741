import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  root: {
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    color: '#858697',
  },
  active: {
    color: colors.secondary,
  },
  noActive: {
    display: 'flex',
  },
  slash: {
    margin: '0 8px'
  }
}));

export default useStyles;