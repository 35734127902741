import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  },
  wrapper: {
    maxWidth: 410,
    display: 'flex',
    height: 220,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperError: {
    maxWidth: 430,
    height: 280,
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '38px',
    textAlign: 'center',
    color: '#141429',
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '24px',
    color: '#141429',
  },
  checkmarkBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 48,
    height: 48,
    width: 48,
    margin: '0 auto',
    background: 'rgba(13, 169, 179, 0.2)',
  },
  checkmarkBoxError: {
    background: '#FEEBEB',
  },
  checkmarkSuccess: {
    color: '#0DA9B3',
    fontSize: 24,
    lineHeight: '24px',
  },
  checkmarkError: {
    color: 'red',
  }
}));

export default useStyles;