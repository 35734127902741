import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Ranking from '../../../../../../../../models/components/dictionaries/ranking/Ranking';
import useStyles from '../../../Rankings.styles';
import AreatroutModal from '../../../../../../../../components/modal/Modal';
import EditRankingModal from '../EditRanking';
import useTranslation from '../../../../../../../../components/localization/customHooks/Translation';

type onFunction = () => void;

const Wrapper = (props: { getRankings: onFunction, ranking: Ranking }) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { getRankings, ranking } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <AreatroutModal
        header={l10n.components.editRanking.EDIT_RANKING}
        open={open}
        setOpen={setOpen}
        onClose={getRankings}
      >
        <EditRankingModal rankingInfo={ranking} />
      </AreatroutModal>
      <IconButton
        className={classes.editButton}
        edge="start"
        aria-label="edit"
        onClick={() => setOpen(true)}
      >
        <Edit />
      </IconButton>
    </>
  );
};

export default Wrapper;