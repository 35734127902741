import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  invalid: {
    color: colors.red,
  },
  wrapper: {
    marginTop: 20,
  },
  boldFont: {
    fontWeight: 'bold',
    margin: 0,
  },
  configureTable: {
    minWidth: 500
  },
  headerOfColumn: {
    minWidth: 100,
  },
}));

export default useStyles;