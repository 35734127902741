import { object, string } from 'yup';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(l10n.components.tournamentFormat.REQUIRED_FORMAT),
  description: string(),
});

export default schema;