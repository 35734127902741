import React from 'react';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  className: '',
};

const ParticipantsCount = (props: {
  number: number | undefined,
  className?: string
}) => {
  const { number, className } = props;
  const l10n = useTranslation();

  const getNoun = (num: number | undefined) => {
    let noun = '';

    if (num) {
      let n = Math.abs(num);

      n %= 100;
      if (n >= 5 && n <= 20) {
        noun = l10n.components.participantsCount.MANY_PARTICIPANTS;
      }

      n %= 10;
      if (n === 1) {
        noun = l10n.components.participantsCount.ONE_PARTICIPANT;
      }

      if (n >= 2 && n <= 4) {
        noun = l10n.components.participantsCount.PARENTAL_CASE_PARTICIPANT;
      }

      noun = l10n.components.participantsCount.MANY_PARTICIPANTS;
    }

    return noun;
  };

  return <span className={className}>{`${number} ${getNoun(number)}`}</span>;
};

export default ParticipantsCount;

ParticipantsCount.defaultProps = defaultProps;