import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useStores } from '../../../../../stores/Main';
import useStyles from '../Article.styles';
import Article from '../../../../../models/components/article/Article';

function EditDeleteCell(props: {
  article: Article,
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const {
    article, setIsDeleteModalOpen, setIsEditModalOpen
  } = props;
  const classes = useStyles();
  const { ArticlesStore } = useStores();

  const openDelModal = (currArticle: Article): void => {
    ArticlesStore.setArticle(currArticle);
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (currArticle: Article): void => {
    ArticlesStore.setArticle(currArticle);
    if (ArticlesStore.article) setIsEditModalOpen(true);
  };

  return (
    <>
      {article && (
      <ListItem className={classes.buttonCell}>
        <IconButton
          edge="start"
          aria-label="edit"
          onClick={() => openEditModal(article)}
        >
          <Edit />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => openDelModal(article)}
        >
          <Delete />
        </IconButton>
      </ListItem>
      )}
    </>
  );
}

export default EditDeleteCell;