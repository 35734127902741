import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useStyles from '../Tabs.styles';
import MobileTabData from '../../../models/components/tabs/MobileTabData';
import colors from '../../../styles/Variables';
import { EmptyVoidFunction } from '../../../utils/types/Types';

const MobileTab = (props: {
  onReturn: EmptyVoidFunction
  selectedTab: MobileTabData
}) => {
  const { selectedTab, onReturn } = props;
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tabNavigation}>
        <button
          type="button"
          className={classes.backBtn}
          onClick={onReturn}
        >
          <ArrowBackIcon fontSize="large" htmlColor={colors.primary} />
        </button>
        <div className={classes.tabsTitle}>
          {selectedTab.name}
        </div>
      </div>
      <div className={classes.componentContainer}>
        {selectedTab.component}
      </div>
    </div>
  );
};

export default MobileTab;