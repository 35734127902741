import {
  get
} from '../Http';

const url = '/api/Version/';

const VersionService = {
  async get() {
    return get(url);
  },
};

export default VersionService;