import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CollapsibleSection from '../../../../components/collapsibleSection/CollapsibleSection';
import MultipleSelect from '../../../../components/selects/multiple/Multiple';
import useStyles from './TournamentFilters.styles';
import nofilterImage from '../../../../assets/icons/tournaments/filter/noFilter.svg';
import activeFilterImage from '../../../../assets/icons/tournaments/filter/activeFilter.svg';
import closeIcon from '../../../../assets/icons/select/closeIcon.svg';
import ATButton from '../../../../components/button/Button';
import UpcomingTournamentEntry from '../../../../models/pages/tournament/UpcomingTournamentEntry';
import FilterTag from '../../../../components/tags/filter/FilterTag';
import FilterFields from './helpers/Constants';
import FilterField from '../../../../models/pages/availiableTournaments/FilterField';
import AppliedTournamentsFiltersModel from '../../../../models/pages/availiableTournaments/AppliedTournamentsFiltersModel';
import { selectType } from '../../../../utils/enum/Enum';
import SingleSelect from '../../../../components/selects/single/Single';
import SearchService from '../../../../services/main/Search.service';
import useTranslation from '../../../../components/localization/customHooks/Translation';

function TournamentFilters(props: {
  searchService: SearchService
  setFilteredTournaments: React.Dispatch<React.SetStateAction<UpcomingTournamentEntry[] | null>>
  tournaments: UpcomingTournamentEntry[] | null,
}) {
  const classes = useStyles();

  const {
    setFilteredTournaments, tournaments, searchService
  } = props;

  const EmptyFilters = {
    season: [],
    ranking: [],
    ratingsList: [],
    country: [],
    status: [],
    format: [],
  };
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<AppliedTournamentsFiltersModel>({ ...EmptyFilters });
  const [filtersLength, setFiltersLength] = useState<number>(0);
  const l10n = useTranslation();

  const resetFilter = (key: string) => {
    selectedFilters[key] = [];
    setSelectedFilters({ ...selectedFilters });
  };

  const onFilterSelected = (filterName: string, value: number | string | null) => {
    selectedFilters[filterName] = [(value || '').toString()];
    setSelectedFilters({ ...selectedFilters });
  };

  useEffect(() => {
    if (tournaments) {
      setFilteredTournaments(searchService.filterTournamentBySelectedFilters(tournaments, selectedFilters));
    }
    setFiltersLength(searchService.getFiltersCount(selectedFilters));
  }, [selectedFilters]);

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen((prev) => !prev)}
        className={clsx(classes.button, classes.filterButton)}
      >
        <div className={clsx(classes.innerButton, classes.filterIcon, { [classes.active]: open })}>
          <img src={open ? activeFilterImage : nofilterImage} alt={l10n.pages.FILTERS} />
          {filtersLength ? `${l10n.pages.FILTERS}: ${filtersLength}` : l10n.pages.FILTERS}
        </div>
      </button>
      <CollapsibleSection
        isSectionOpen={open}
        setIsSectionOpen={setOpen}
        onClose={() => setOpen(false)}
        className={classes.section}
      >
        <div className={classes.selectContainer}>
          {FilterFields.map((filter: FilterField) => (
            filter.type === selectType.multiple ? (
              <MultipleSelect
                key={filter.filterName}
                filterProps={filter}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                className={classes.select}
              />
            ) : (
              <SingleSelect
                key={filter.filterName}
                filterProps={filter}
                onValueSelected={onFilterSelected}
                className={classes.select}
                showCustomMobileSelect
              />
            )
          ))}
          <button
            type="button"
            onClick={() => setSelectedFilters({ ...EmptyFilters })}
            className={clsx(classes.button, classes.buttonReset)}
          >
            <div className={clsx(classes.innerButton)}>
              <img src={closeIcon} alt="Filter" />
              {l10n.components.button.RESET_ALL}
            </div>
          </button>
          <div className={classes.mobileButtonsContainer}>
            <ATButton
              variant="secondary"
              fill="outline"
              size="md"
              onClick={() => setSelectedFilters({ ...EmptyFilters })}
            >
              {l10n.components.button.RESET}
            </ATButton>
            <ATButton
              variant="primary"
              fill="solid"
              size="md"
              onClick={() => setOpen(false)}
            >
              {l10n.components.button.APPLY}
            </ATButton>
          </div>
        </div>
      </CollapsibleSection>
      <div className={clsx(classes.tagsContainer)}>
        {Object.keys(selectedFilters).map((key) => Boolean(selectedFilters[key].length) && (
          <FilterTag label={selectedFilters[key].join(', ')} onDelete={() => resetFilter(key)} key={key} />
        ))}
        {Boolean(searchService.getFiltersCount(selectedFilters)) && (
          <button
            type="button"
            onClick={() => setSelectedFilters({ ...EmptyFilters })}
            className={clsx(classes.button, classes.reset)}
          >
            <div className={classes.innerButton}>
              <img src={closeIcon} alt="Filter" />
              {l10n.components.button.RESET_ALL}
            </div>
          </button>
        )}
      </div>
    </>
  );
}

export default TournamentFilters;