import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardTemplate: {
    '& th': {
      fontWeight: 600,
    },
    '& th, & tr, & td': {
      border: '1px solid #ccc',
      [theme.breakpoints.down('xs')]: {
        fontSize: 9,
      },
    },
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& input': {
      width: 40,
      margin: 2,
      [theme.breakpoints.down('xs')]: {
        width: 20,
        padding: 0,
        fontSize: 10,
        borderWidth: 1,
        margin: 3,
        '&[type="number"]::-webkit-inner-spin-button': {
          appearance: 'none',
        },
        '&[type="number"]::-webkit-outer-spin-button': {
          appearance: 'none',
        },
        '&[type="number"]': {
          appearance: 'textfield'
        },
      },
    },
    '& .MuiTableCell-root': {
      padding: 3,
      [theme.breakpoints.down('xs')]: {
        padding: 1,
      },
    },
    '& .fullName': {
      textAlign: 'left',
    },
  },
  tableRowError: {
    backgroundColor: 'rgba(240, 128, 128, 0.6)',
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(240, 128, 128, 0.6)',
    },
  },
  tableCellError: {
    backgroundColor: 'rgba(240, 128, 128, 0.6)',
  },
  narrowCell: {
    padding: '0 5px',
    width: 30,
    [theme.breakpoints.down('xs')]: {
      width: 15,
      padding: '0 2px',
    },
  },
  BasicCell: {
    width: 60,
    [theme.breakpoints.down('xs')]: {
      width: 35,
    },
  },
  fullName: {
    '& a': {
      color: '#000',
    },
    cursor: 'pointer',
    textAlign: 'left',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      padding: '1px 1px 1px 3px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
  },
  resultCell: {
    fontWeight: 600,
    fontSize: 16,
  },
}));

export default useStyles;