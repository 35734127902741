import React from 'react';
import { Avatar as MUIAvatar, Badge } from '@material-ui/core';
import clsx from 'clsx';
import { getImageUrl } from '../../../utils/Utils';
import noAvatar from '../../../assets/icons/no-avatar.svg';
import ImageData from '../../../models/components/image/ImageData';
import Flag from '../../flag/Flag';
import { useStylesAvatar } from '../Member.styles';

export type AvatarSize = 'medium' | 'small';

const defaultProps = {
  countryFlag: null,
  avatarStyle: '',
};

const Avatar = (props: {
  countryFlag?: ImageData | null;
  profilePicture: ImageData | null | undefined;
  avatarStyle?: string,
}) => {
  const { countryFlag, profilePicture, avatarStyle } = props;
  const classes = useStylesAvatar();

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      badgeContent={(
        countryFlag
        && (
          <Flag
            className={classes.flag}
            data={countryFlag}
          />
        )
      )}
    >
      <MUIAvatar
        className={clsx(classes.avatar, avatarStyle)}
        alt={profilePicture?.filename || 'avatar'}
        src={profilePicture?.url ? getImageUrl(profilePicture.miniatureImageUrl) : noAvatar}
      />
    </Badge>
  );
};

Avatar.defaultProps = defaultProps;

export default Avatar;