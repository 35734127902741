import {
  remove,
  get,
  patch,
  post,
  put,
} from '../../Http';
import Ranking from '../../../models/components/dictionaries/ranking/Ranking';

const url = '/api/Rankings/';

const RankingService = {
  async getAll() {
    return get(url);
  },

  async get(id: number) {
    return get(url + id);
  },

  async delete(id: number) {
    return remove(url + id);
  },

  async post(ranking: Ranking) {
    return post(url, ranking);
  },

  async update(ranking: Ranking) {
    const { id } = ranking;
    return put(url + id, ranking);
  },

  async updatePart(ranking: Ranking) {
    const { id } = ranking;
    return patch(url + id, ranking);
  },
};

export default RankingService;