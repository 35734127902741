import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  fullScreen: {
    '& .MuiCircularProgress-colorPrimary': {
      color: '#0DA9B3'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 100px)',
    width: '100%',
  },
  smallLoader: {
    '& .MuiCircularProgress-colorPrimary': {
      color: '#0DA9B3',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: 20
  },
  width: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: 320,
    },
  }
}));

export default useStyles;