import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, TextField, Grid, FormHelperText
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import RankingService from '../../../../../../../services/main/dictionaries/Ranking.service';
import Ranking from '../../../../../../../models/components/dictionaries/ranking/Ranking';
import schema from '../../helpers/ValidationSchema';
import useStyles from './EditRanking.styles';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import ATCheckbox from '../../../../../../../components/checkbox/Checkbox';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => {},
};

const EditRankingModal = (props: {
  rankingInfo: Ranking,
  handleClose?: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { rankingInfo, handleClose } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Ranking>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      ...rankingInfo,
      description: rankingInfo.description || '',
      isTeamRanking: rankingInfo.isTeamRanking || false,
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isTeamRanking, setIsTeamRanking] = useState<boolean>(Boolean(rankingInfo?.isTeamRanking));

  const editNewRanking = async (data: Ranking) => {
    const result = { ...data };
    result.id = rankingInfo.id;
    result.isTeamRanking = isTeamRanking;
    setIsLoading(true);
    const res = await RankingService.update(result);

    if (res) {
      Toasters.success(l10n.components.editRanking.EDIT_RANKING_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }

    setIsLoading(false);
  };

  return (
    <Grid className={classes.modalContent}>
      {isLoading
        ? <Loader />
        : (
          <form onSubmit={handleSubmit(editNewRanking)} className={classes.contentWrapper}>
            <Controller
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    label={l10n.components.editRanking.EDIT_NAME}
                    variant="outlined"
                    className={classes.contentInput}
                    autoFocus
                    inputProps={{ 'data-testid': 'name' }}
                  />
                  <FormHelperText data-testid="nameError" className={classes.invalid}>
                    {!!errors?.name && errors?.name?.message}
                  </FormHelperText>
                </>
              )}
              name="name"
              control={control}
              defaultValue={rankingInfo.name}
            />
            <div className={classes.field}>
              <Controller
                name="isTeamRanking"
                control={control}
                render={({
                  field: { ref }
                }) => (
                  <ATCheckbox
                    inputRef={ref}
                    checked={isTeamRanking}
                    label={l10n.components.editRanking.TEAM_RANKING}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsTeamRanking(e.target.checked);
                    }}
                  />
                )}
              />
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={l10n.components.editRanking.EDIT_DESCRIPTION}
                  variant="outlined"
                  className={classes.contentInput}
                  multiline
                  rows={4}
                  inputProps={{ 'data-testid': 'description' }}
                />
              )}
              name="description"
              control={control}
              defaultValue={rankingInfo.description}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              data-testid="saveBtn"
            >
              {l10n.components.editRanking.EDIT_SAVE}
            </Button>
          </form>
        )}
    </Grid>
  );
};

export default EditRankingModal;

EditRankingModal.defaultProps = defaultProps;