import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './Tooltip.style';
import { EmptyVoidFunction } from '../../utils/types/Types';

const defaultProps = {
  className: '',
  onShow: () => {}
};

export default function Tooltip(props: {
  container: JSX.Element | string,
  content: JSX.Element,
  index: string,
  className?: string,
  onShow?: EmptyVoidFunction,
 }) {
  const {
    container, content, index, className, onShow
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    if (onShow) {
      onShow();
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);

  return (
    <>
      <div
        aria-owns={isOpened ? index : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {container}
      </div>
      <Popover
        disableScrollLock
        id={index}
        className={classes.popover}
        classes={{
          paper: clsx(classes.paper, className)
        }}
        open={isOpened}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </>
  );
}

Tooltip.defaultProps = defaultProps;