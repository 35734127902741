import {
  remove, get, post, put
} from '../../Http';
import Season from '../../../models/components/dictionaries/season/Season';
import defaultSelectValues from '../../helpers/Constants';

const url = '/api/seasons/';

const SeasonsService = {
  async getAsSelectItems() {
    const seasons = (await SeasonsService.getAll()) || [];
    return seasons.map((season: Season) => ({
      ...season,
      isDefault: season.name === defaultSelectValues.season,
    }));
  },

  async getAll() {
    return get(url);
  },

  async get(id: number) {
    return get(url + id);
  },

  async delete(id: number) {
    return remove(`${url + id}`);
  },

  async post(season: Season) {
    return post(url, season);
  },

  async update(season: Season) {
    const { id } = season;
    return put(url + id, season);
  },
};

export default SeasonsService;