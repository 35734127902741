import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { StorageService } from '../../utils/Utils';
import useStyles from './Tackles.styles';
import useTranslation from '../localization/customHooks/Translation';
import ATButton from '../button/Button';
import Modal from '../modal/Modal';
import AddEditTackle from './components/modals/AddEditTackle';
import { useStores } from '../../stores/Main';
import TacklesTable from './components/table/TacklesTable';
import Loader from '../loader/Loader';

const Tackles = observer(() => {
  const [isAddModalOpened, setAddIsModalOpened] = useState<boolean>(false);
  const classes = useStyles();
  const l10n = useTranslation();
  const { TacklesStore, UserStore } = useStores();

  const auth = StorageService.getUserData();

  const isUsersPage = auth?.id === UserStore.userId;

  const openModalClickHandler = () => {
    setAddIsModalOpened(true);
  };

  return (
    <>
      {TacklesStore.tackles
        ? (
          <div>
            {isUsersPage && (
              <div className={classes.AddBtnContainer}>
                <ATButton
                  testId="add-button"
                  fill="solid"
                  size="md"
                  variant="primary"
                  onClick={openModalClickHandler}
                  className={classes.button}
                >
                  <>{l10n.components.button.ADD}</>
                </ATButton>
              </div>
            )}
            <TacklesTable isUsersPage={isUsersPage} />
            <Modal
              header={l10n.components.tackles.ADD_TACKLES}
              open={isAddModalOpened}
              setOpen={setAddIsModalOpened}
              className={classes.modalWide}
              modalClassName={classes.modal}
            >
              <AddEditTackle isAddModalOpened={isAddModalOpened} />
            </Modal>
          </div>
        )
        : (<Loader />)}
    </>
  );
});

export default Tackles;