import React from 'react';
import clsx from 'clsx';
import useStyles from './Flag.styles';
import ImageData from '../../models/components/image/ImageData';
import { getImageUrl } from '../../utils/Utils';

const defaultProps = {
  className: '',
};

const Flag = (props: {data: ImageData, className?: string}) => {
  const classes = useStyles();
  const { data, className } = props;

  return (
    <div
      className={clsx(className, classes.wrapper)}
    >
      <img
        className={classes.flag}
        src={getImageUrl(data.url)}
        alt="flag"
      />
    </div>
  );
};

export default Flag;

Flag.defaultProps = defaultProps;