import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import useStyles from '../CardTemplate.styles';
import useTranslation from '../../../localization/customHooks/Translation';

function TableHeader() {
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" rowSpan={2} padding="none" className={classes.narrowCell}>{l10n.components.tableTemplates.TOUR}</TableCell>
        <TableCell align="center" rowSpan={2} padding="none" className={classes.narrowCell}>{l10n.components.tableTemplates.ZONE}</TableCell>
        <TableCell align="center" rowSpan={1} colSpan={2} padding="none">{l10n.components.tableTemplates.CATCHES}</TableCell>
        <TableCell align="center" rowSpan={2} padding="none" className={classes.BasicCell}>{l10n.components.tableTemplates.POINTS}</TableCell>
        <TableCell align="center" rowSpan={1} colSpan={2} padding="none" className={classes.BasicCell}>{l10n.components.tableTemplates.ENEMY}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center" className={classes.BasicCell} padding="none">{l10n.components.tableTemplates.YOUR_RESULT}</TableCell>
        <TableCell align="center" className={classes.narrowCell} padding="none">{l10n.components.tableTemplates.ENEMY}</TableCell>
        <TableCell align="center" className={classes.narrowCell}>№</TableCell>
        <TableCell align="center" padding="none">{l10n.components.tableTemplates.NAME}</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;