import {
  get
} from '../Http';

const url = '/api/News';

const NewsService = {
  async get(skip: number, take: number) {
    return get(url, undefined, { params: { skip, take } });
  },
};

export default NewsService;