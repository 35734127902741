import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button, Grid, TextField
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Ranking from '../../../../../../../models/components/dictionaries/ranking/Ranking';
import RankingService from '../../../../../../../services/main/dictionaries/Ranking.service';
import schema from '../../helpers/ValidationSchema';
import Toasters from '../../../../../../../components/popUp/PopUp';
import useStyles from './AddRanking.styles';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import ATCheckbox from '../../../../../../../components/checkbox/Checkbox';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const AddRanking = (props: {
  handleClose?: EmptyVoidFunction
}) => {
  const { handleClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const l10n = useTranslation();
  const [isTeamRanking, setIsTeamRanking] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Ranking>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      isTeamRanking: false,
    },
  });

  const addNewRanking = async (data: Ranking) => {
    setIsLoading(true);
    const res = await RankingService.post(data);
    if (res) {
      Toasters.success(l10n.components.addRanking.ADD_RANKING_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }

    setIsLoading(false);
  };

  return (
    <Grid className={classes.modalContent}>
      {isLoading
        ? <Loader />
        : (
          <form onSubmit={handleSubmit(addNewRanking)} className={classes.contentWrapper}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={l10n.components.addRanking.ADD_NAME}
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  autoFocus
                  inputProps={{ 'data-testid': 'name' }}
                />
              )}
              name="name"
              control={control}
              defaultValue=""
            />
            <div className={classes.wrapperInput}>
              <Controller
                name="isTeamRanking"
                control={control}
                render={({
                  field: { ref }
                }) => (
                  <ATCheckbox
                    inputRef={ref}
                    checked={isTeamRanking}
                    label={l10n.components.addRanking.TEAM_RANKING}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsTeamRanking(e.target.checked);
                    }}
                  />
                )}
              />
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={l10n.components.addRanking.ADD_DESCRIPTION}
                  variant="outlined"
                  className={classes.contentInput}
                  multiline
                  rows={4}
                  inputProps={{ 'data-testid': 'description' }}
                />
              )}
              name="description"
              control={control}
              defaultValue=""
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              data-testid="saveBtn"
            >
              {l10n.components.addRanking.ADD_SAVE}
            </Button>
          </form>
        )}
    </Grid>
  );
};

export default AddRanking;

AddRanking.defaultProps = defaultProps;