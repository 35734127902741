import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  modalWide: {
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 20,
    marginLeft: 10
  },
  head: {
    textAlign: 'center',
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
  },
  buttonsWrapper: {
    padding: 10,
    margin: 'auto',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
    boxSizing: 'border-box',
    padding: 20,
  },
  imgContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: 15,
  },
  imgSizes: {
    width: 250,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  saveButton: {
    width: '30%',
    margin: 'auto',
  },
  invalid: {
    color: colors.red,
  }
}));

export default useStyles;