import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import TournamentResult from '../../../../../../components/tournamentResult/TournamentResult';
import useStyles from './FinalResult.styles';
import PointsCalculationInfo from './pointsCalculationInfo/PointsCalculationInfo';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const FinalResults = (props: {
  id: number
}) => {
  const { id } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <>
      <Grid container justify="space-between" alignItems="center" className={classes.title}>
        <Typography component="h2" className={classes.titleText}>{l10n.pages.TOURNAMENT_RESULT}</Typography>
        <PointsCalculationInfo className={classes.info} />
      </Grid>
      <TournamentResult id={id} isSearchVisible={false} />
    </>
  );
};

export default FinalResults;