import deleted from '../../../assets/icons/teamCards/deleted.svg';
import approved from '../../../assets/icons/teamCards/approved.svg';
import pending from '../../../assets/icons/teamCards/pending.svg';
import TeamRegistrationStatus from '../../../models/pages/team/TeamRegistrationStatus';
import { teamRegistarationStatus } from '../../../utils/enum/Enum';
import { getTranslation } from '../../localization/customHooks/Translation';

const l10n = getTranslation();

const registrationStatus: TeamRegistrationStatus = {
  [teamRegistarationStatus.approved]: { icon: approved, description: l10n.components.teamCard.APPROVED },
  [teamRegistarationStatus.pending]: { icon: pending, description: l10n.components.teamCard.PENDING },
  [teamRegistarationStatus.invitationRejected]: { icon: deleted, description: l10n.components.teamCard.DECLINED },
  [teamRegistarationStatus.disbanded]: { icon: deleted, description: l10n.components.teamCard.DISBANDED },
};

export default registrationStatus;