import React, { useEffect, useState } from 'react';
import { List, Button, Grid } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import Rating from '../../../../../models/components/dictionaries/rating/Rating';
import RatingService from '../../../../../services/main/dictionaries/Rating.service';
import AreatroutModal from '../../../../../components/modal/Modal';
import AddOrEditRatingModal from './modals/AddOrEditRating';
import EditDeleteCell from './cell/EditDelete';
import DeleteRatingModal from './modals/DeleteRating';
import AreatroutTable from '../../../../../components/table';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import useStyles from './Rating.styles';
import RTEtext from '../../../../../components/RTEtext/RTEtext';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

function Ratings() {
  const [allRating, setRatings] = useState<Array<Rating> | null>([]);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRating, setSelectedRating] = useState<Rating>({
    name: '',
    id: 0,
    countingTournamentsNumber: null,
    countryId: '',
    description: ''
  });
  const classes = useStyles();
  const l10n = useTranslation();

  const columns = [
    {
      displayName: l10n.components.rating.RATING_NAME,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.rating.RATING_DESCRIPTION,
      fieldName: 'description',
      allowSearch: false,
      allowSortring: false,
      render: ({ description }: Rating) => (
        <div className={classes.description}>
          <RTEtext text={description} />
        </div>

      ),
    },
    {
      displayName: l10n.components.rating.RATING_CONTROL,
      fieldName: 'action',
      allowSortring: false,
      allowSearch: false,
      render: (data: Rating) => (
        <EditDeleteCell
          setSelectedRating={setSelectedRating}
          setOpenEdit={setOpenEdit}
          setOpenDelete={setOpenDelete}
          data={data}
        />
      ),
    },
  ];

  const getRatings = async () => {
    const response = await RatingService.get();
    setRatings(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getRatings();
  }, []);

  const handleAdd = () => {
    setOpenAdd(true);
    setSelectedRating({
      id: 0,
      name: '',
      countingTournamentsNumber: null,
      countryId: '',
      description: ''
    });
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <>
      <AreatroutModal
        header={l10n.components.rating.ADD_RATING_MODAL}
        open={openAdd}
        setOpen={setOpenAdd}
        onClose={getRatings}
        modalClassName={classes.modal}
        disableEnforceFocus
      >
        <AddOrEditRatingModal selectedRating={selectedRating} />
      </AreatroutModal>
      <AreatroutModal
        header={l10n.components.rating.DELETE_RATING_MODAL}
        open={openDelete}
        setOpen={setOpenDelete}
        onClose={getRatings}
        modalClassName={classes.modal}
      >
        <DeleteRatingModal ratingId={selectedRating.id} />
      </AreatroutModal>
      <AreatroutModal
        header={l10n.components.rating.EDIT_RATING_MODAL}
        open={openEdit}
        setOpen={setOpenEdit}
        onClose={getRatings}
        modalClassName={classes.modal}
        disableEnforceFocus
      >
        <AddOrEditRatingModal selectedRating={selectedRating} />
      </AreatroutModal>
      <Button
        data-testid="add-button"
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={handleAdd}
        className={classes.buttonAdd}
      >
        {l10n.components.rating.ADD_RATING_BUTTON}
      </Button>
      <Grid className={classes.tableWidth}>
        <List>
          {allRating && allRating.length ? (
            <AreatroutTable columns={columns} rows={allRating} />
          ) : (
            l10n.components.rating.RATING_LIST_IS_EMPTY
          )}
        </List>
      </Grid>
    </>
  );
}

export default Ratings;