import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../stores/Main';
import Header from '../components/Header';
import RegisteredParticipant from '../../../../../models/pages/tournament/RegisteredParticipant';
import RegisterAngler from './components/registerAnglerModal/RegisterAngler';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import ParticipantsTable from './components/patricipantsTable/ParticipantsTable';
import TournamentService from '../../../../../services/main/Tournament.service';
import AddParticipants from './components/addParticipant/AddParticipants';
import useStyles from './Registration.styles';
import ToggleStatus from './components/toggleStatus/ToggleStatus';
import TransferToAwaitingParticipants from './components/buttons/transferToAwaitingParticipants/TransferToAwaitingParticipants';
import TransferToActiveParticipants from './components/buttons/transferToActiveParticipants/TransferToActiveParticipants';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import Modal from '../../../../../components/modal/Modal';

const Registration = observer(() => {
  const { TournamentStore } = useStores();
  const { tournament } = TournamentStore;
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeParticipants, setActiveParticipants] = useState<RegisteredParticipant[] | null>([]);
  const [awaitingParticipants, setAwaitingParticipants] = useState<RegisteredParticipant[] | null>([]);

  const fetchAllParticipants = async () => {
    setIsLoading(true);
    if (tournament) {
      const allRegistrations = await TournamentService.getAllExistingRegistrations(tournament.id);
      if (allRegistrations) {
        setActiveParticipants(allRegistrations.activeRegistrations);
        setAwaitingParticipants(allRegistrations.waitListRegistrations);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllParticipants();
  }, [isModalOpened, tournament]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return (
    <>
      <Header className={classes.header} text={`${l10n.components.headerLabel.REGISTEDER_PARTICIPANT} "${tournament && tournament.name}"`} />
      <Grid container spacing={2}>
        <Grid container item sm={12} md={3} direction="column" alignItems="center" className={classes.status}>
          <div className={classes.titleParticipants}>
            {`${l10n.pages.PARTICIPANTS_NUMBER}:`}
            <span className={classes.titleParticipantsNumber}>
              {activeParticipants?.length}
              /
              {tournament?.participantsLimit}
            </span>
          </div>
          <ToggleStatus />
        </Grid>
        <Grid container item xs={12} sm={12} md={9}>
          <Grid item sm={12} className={classes.content}>
            <AddParticipants tournament={tournament} fetchAllParticipants={fetchAllParticipants} />
            <ParticipantsTable
              ToggleRegistrationButton={TransferToAwaitingParticipants}
              fetchAllParticipants={fetchAllParticipants}
              participants={activeParticipants}
            />
          </Grid>
          <Grid item sm={12}>
            <Grid container justify="space-between" alignItems="baseline" className={classes.title}>
              <Typography variant="h5" component="h3" className={classes.titleName}>{l10n.pages.WAITINGS_lIST}</Typography>
              <div className={classes.titleParticipants}>
                {`${l10n.pages.PARTICIPANTS_NUMBER}:`}
                <span className={classes.titleParticipantsNumber}>
                  {awaitingParticipants?.length}
                </span>
              </div>
            </Grid>
            <ParticipantsTable
              ToggleRegistrationButton={TransferToActiveParticipants}
              fetchAllParticipants={fetchAllParticipants}
              participants={awaitingParticipants}
            />
          </Grid>
        </Grid>
        <Modal
          open={isModalOpened}
          header={l10n.pages.ADD_PARTICIPANT}
          setOpen={setIsModalOpened}
        >
          <RegisterAngler handleClose={setIsModalOpened} />
        </Modal>
      </Grid>
    </>
  );
});

export default Registration;