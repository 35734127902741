import React from 'react';
import clsx from 'clsx';
import useStyles from '../../../Playoff.styles';
import FinalCard from './FinalCard';
import EmptyCard from '../../../components/Empty';
import ModifiedPlayoffRound from '../../../../../../../../models/pages/tournament/playoff/ModifiedPlayoffRound';
import RoundResult from '../../../../../../../../models/pages/tournament/playoff/RoundResult';
import useTranslation from '../../../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  round: null
};

function ThirstPlaceRound(props: {
  round?: ModifiedPlayoffRound | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    round, setParticipant, winnerId, isEditMode
  } = props;
  return (
    <div className={clsx(classes.flexColumn, classes.innerFinal)}>
      <div className={classes.subHeader}>{l10n.components.playoffSchedule.THIRD_PLACE}</div>
      <div className={classes.flexRow}>
        <svg className={clsx(classes.crossedLines, classes.finalsLine_3)}>
          <line x1="0" y1="290" x2="145" y2="30" className={classes.line} />
        </svg>
        {round
          ? (
            <FinalCard
              player={round.firstParticipant}
              setParticipant={setParticipant}
              winnerId={winnerId}
              isEditMode={isEditMode}
            />
          )
          : <EmptyCard isFinal />}
      </div>
      <div className={classes.flexRow}>
        <svg className={clsx(classes.finalsLine, classes.finalsLine_4)}>
          <line x1="0" y1="75" x2="150" y2="25" className={classes.line} />
        </svg>
        {round
          ? (
            <FinalCard
              player={round.secondParticipant}
              setParticipant={setParticipant}
              winnerId={winnerId}
              isEditMode={isEditMode}
            />
          )
          : <EmptyCard isFinal />}
      </div>
    </div>
  );
}

ThirstPlaceRound.defaultProps = defaultProps;

export default ThirstPlaceRound;