import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../components/table';
import LoaderFullScreen from '../../../components/loader/fullScreen/FullScreen';
import DateCell from '../../../components/tableTemplates/components/cell/data/Date';
import Modal from '../../../components/modal/Modal';
import AddOrEditTeamModal from '../../teams/components/addOrEditTeamModal/AddOrEditTeamModal';
import TeamData from '../../../models/pages/team/TeamData';
import { emptyTeamData } from '../../teams/helpers/Constants';
import TeamsService from '../../../services/main/Team.service';
import transformTeams from '../../teams/Teams.utils';
import Team from '../../../models/pages/team/Team';
import useStyles from './Teams.styles';
import DeleteTeamButton from '../../../components/teamCard/components/deleteTeamButton/DeleteTeamButton';
import EditTeamButton from '../../../components/teamCard/components/editTeamButton/EditTeamButton';
import { sortingType } from '../../../utils/enum/Enum';
import useTranslation from '../../../components/localization/customHooks/Translation';
import Routes from '../../../routing/Routes';

function TeamsTable() {
  const classes = useStyles();
  const l10n = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [editTeamData, setEditTeamData] = useState<TeamData>(emptyTeamData);
  const [teams, setTeams] = useState<Team[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getTeams = async () => {
    setIsLoading(true);
    const response = await TeamsService.getAllTeams();
    if (response) {
      setTeams(response.map((team: Team) => transformTeams(team)));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTeams();
    setEditTeamData(emptyTeamData);
  }, []);

  const closeModalHandler = () => {
    setOpen(false);
    getTeams();
  };

  const columns = [
    {
      displayName: l10n.components.tableColumns.CREATION_DATE,
      fieldName: 'creationDate',
      defaultSort: sortingType.desc,
      render: ({ creationDate }: Team) => <DateCell value={creationDate} />,
    },
    {
      displayName: l10n.components.tableColumns.NAME,
      fieldName: 'name',
      render: ({ name }: Team) => name,
    },
    {
      displayName: l10n.components.tableColumns.COUNTRY,
      fieldName: 'country.name',
      customSort: (a: any, b: any) => a.country.name.localeCompare(b.country.name),
      render: ({ country }: Team) => country.name,
    },
    {
      displayName: l10n.components.tableColumns.CREATOR_NAME,
      fieldName: 'captain.name',
      customSort: (a: any, b: any) => a.captain.name.localeCompare(b.captain.name),
      render: ({ captain }: Team) => <Link className={classes.link} to={Routes.User.path.replace(':id', String(captain.id))}>{captain.name}</Link>,
    },
    {
      displayName: l10n.components.tableColumns.PARTNER_NAME,
      fieldName: 'partner.name',
      customSort: (a: any, b: any) => a.partner.name.localeCompare(b.partner.name),
      render: ({ partner }: Team) => <Link className={classes.link} to={Routes.User.path.replace(':id', String(partner.id))}>{partner.name}</Link>,
    },
    {
      displayName: l10n.components.tableColumns.STATUS_TEXT,
      fieldName: 'statusText',
      render: ({ statusText }: Team) => statusText
    },
    {
      displayName: l10n.components.tableColumns.CONTROL,
      fieldName: 'management',
      allowSortring: false,
      allowSearch: false,
      render: (team: Team) => (
        <>
          <EditTeamButton setOpen={setOpen} setEditTeamData={setEditTeamData} team={team} />
          <DeleteTeamButton team={team} refresh={getTeams} />
        </>
      ),
    },
  ];

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      <div className={classes.root}>
        <Modal
          header=""
          open={open}
          setOpen={setOpen}
          modalClassName={classes.addTeamModal}
          onClose={closeModalHandler}
        >
          <AddOrEditTeamModal editTeamData={editTeamData} refresh={getTeams} />
        </Modal>
        <Table
          columns={columns}
          rows={teams}
          isSearchVisible
        />
      </div>
    </>
  );
}

export default TeamsTable;