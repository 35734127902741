import {
  makeAutoObservable,
  runInAction
} from 'mobx';
import Article from '../../models/components/article/Article';
import ArticlesService from '../../services/main/Article.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class ArticlesStore {
    articles: Article[] | null = null;

    article: Article | null = null

    mainStore: any;

    constructor(mainStore: any) {
      this.mainStore = mainStore.mainStore;

      makeAutoObservable(this);
    }

    getArticleById = async (articleId?: string) => {
      const response = await ArticlesService.get(articleId || this.article!.id);
      if (response) {
        this.setArticle(response);
      }
      return this.article;
    };

    updatePictures = async (imgIds: number[]) => {
      const payload = {
        articleId: this.article!.id,
        imageIds: imgIds,
      };
      const response = await ArticlesService.updateImgs(payload);
      return response;
    }

    getArticleByName = (selectedMainArticle: string) => this.articles!.find((el: Article) => el.name === selectedMainArticle);

    refreshArticles: EmptyVoidFunction = async () => {
      const response = await ArticlesService.getAll();
      if (response) {
        runInAction(() => { this.articles = response; });
      }
    };

    deleteArticle = async () => {
      const response = await ArticlesService.delete(this.article!.id);
      return response;
    };

    addArticle = async (data: Article) => {
      this.setArticle(data);
      const response = await ArticlesService.post(data);
      return response;
    };

    editArticle = async (data: Article) => {
      const result = { ...data };
      result.id = this.article!.id;
      const response = await ArticlesService.update(result);
      return response;
    };

    setId = (articleId: string) => {
      this.article!.id = articleId;
    }

    setArticle = (article: Article | null) => {
      this.article = article;
    }

    setArticles = (articles: Article[]) => {
      this.articles = articles;
    }
}