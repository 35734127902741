import React from 'react';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import Tackles from '../../../../components/tackles/Tackles';
import Tabs from '../../../../components/tabs/Tabs';
import UserParticipationHistory from '../../../../models/pages/tournament/UserParticipationHistory';
import { StorageService } from '../../../../utils/Utils';
import OpponentTable from '../opponentTable/OpponentTable';
import UserPaticipationHistory from '../participationHistory/ParticipationHistory';
import RatingTable from '../ratingTable/RatingTable';
import TournamentReports from '../tournamentReports/TournamentReports';
import useStyles from '../../UserCard.styles';

const UserCardTabMenu = (props: {
  data: UserParticipationHistory[]
  id: string
  isMobile: boolean
}) => {
  const { data, id, isMobile } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const auth = StorageService.getUserData();

  const resultsContentGuest = [
    {
      name: l10n.components.tableColumns.TOURNAMENT_PARTICIPATION,
      component: <UserPaticipationHistory data={data} />,
      hash: '#user-paticipation-history'
    },
    {
      name: l10n.components.tableColumns.PERSONAL_MEETINGS,
      component: <OpponentTable anglerId={id} />,
      hash: '#opponent-table'
    },
    {
      name: l10n.components.tableColumns.TACKLES,
      component: <Tackles />,
      hash: '#meetings-table'
    },
    {
      name: l10n.components.tournamentReports.REPORTS,
      component: <TournamentReports anglerId={id} />,
      hash: '#reports-list',
    }
  ];

  const resultsContentUser = [
    ...resultsContentGuest,
    {
      name: l10n.components.tableColumns.RATINGS,
      component: <RatingTable anglerId={id} />,
      hash: '#rating-table'
    }
  ];

  return (
    <Tabs
      tabData={auth ? resultsContentUser : resultsContentGuest}
      isCentered={false}
      DesktopLayoutStyle={classes.tabsContainer}
      isMobileLayout={isMobile}
    />
  );
};

export default UserCardTabMenu;