import { post } from '../Http';
import UserSingIn from '../../models/components/authorization/UserSingIn';
import UserSignUp from '../../models/components/authorization/UserSignUp';
import { StorageService } from '../../utils/Utils';
import AuthorizationUser from '../../models/service/AuthorizationUser';
import Error from '../../models/service/Error';
import { getTranslation } from '../../components/localization/customHooks/Translation';
import { userErrors, userIsBlockedErrorNumber } from '../helpers/Constants';

const url = '/api/Users/';
const l10n = getTranslation();
const setUser = (data: AuthorizationUser) => StorageService.setUserData(data);

const getErrorMessage = (error: Error) => {
  const errorData = error.response.data;
  if (!userErrors[errorData.Code]) {
    return l10n.components.error.UNKNOWN_ERROR;
  }
  if (errorData.Code === userIsBlockedErrorNumber) {
    return `${userErrors[errorData.Code]}. ${l10n.components.label.REASON_OF_BLOCKING}: "${errorData.Message}"`;
  }
  return userErrors[errorData.Code];
};

const generateError = (error: Error) => {
  if (error.response) {
    return {
      error: true,
      statusCode: error.response.data.Code,
      message: getErrorMessage(error),
    };
  }
  return null;
};
// eslint-disable-next-line
const checkAuthorization = (response: any, setUserData?: ((user: AuthorizationUser) => void)) => {
  if (response) {
    if (response.error) {
      return response;
    }
    if (setUserData) {
      setUserData(response);
    }
    return {
      error: false,
      data: response,
    };
  }
};

export default class AuthorizationService {
  static signUp = async (data: UserSignUp) => {
    const response = await post(url, data, { skipAuthRefresh: true }, generateError);
    return checkAuthorization(response);
  }

  static signIn = async (data: UserSingIn) => {
    const response = await post(`${url}token`, data, { skipAuthRefresh: true }, generateError);
    return checkAuthorization(response, setUser);
  };

  static confirmationEmail = async (userId: string, token: string) => post(`${url}${userId}/email/confirm`, { userId, token }, { skipAuthRefresh: true });

  static activateUser = async (userId: string) => post(`${url}${userId}/email/activate`, { userId });

  static sendConfirmationLink = async (userEmail: string) => {
    const response = await post(`${url}email/confirmation/send`, { userEmail }, { skipAuthRefresh: true }, generateError);
    return checkAuthorization(response);
  };

  static resetEmail = async (userEmail: string) => {
    const response = await post(`${url}password/reset/send`, { userEmail }, undefined, generateError);
    return checkAuthorization(response);
  };

  static changePassword = async (userId: string, newPassword: string, token: string) => {
    const response = await post(`${url}${userId}/password/reset`, { userId, newPassword, token }, undefined, generateError);
    return response;
  };
}