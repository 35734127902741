import React, { ChangeEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import {
  FormHelperText, MenuItem, Select
} from '@material-ui/core';
import Tooltip from '../../tooltip/Tooltip';
import useStyles from './Team.styles';
import TeamSelectItem from '../../../models/components/selects/TeamSelectItem';
import MemberAvatar from '../../member/avatar/Avatar';
import questionIcon from '../../../assets/icons/select/question.svg';

const defaultProps = {
  placeholder: '',
};

function TeamSelect(props: {
  menuItems: TeamSelectItem[] | null
  placeholder?: string
  label: string
  setTeamId: React.Dispatch<React.SetStateAction<number>>;
}) {
  const classes = useStyles();

  const {
    placeholder, setTeamId, label, menuItems
  } = props;

  const [selectedMenuItem, setSelectedMenuItem] = useState<TeamSelectItem>({ id: 0, name: '', blockReason: null });
  const [open, setOpen] = useState<boolean>(false);

  const getTooltip = (message: string) => (
    <Tooltip
      className={classes.border}
      index={uuidv4()}
      content={(
        <div className={clsx(classes.popover)}>
          {message}
        </div>
      )}
      container={(
        <div>
          <img src={questionIcon} alt="question" />
        </div>
      )}
    />
  );

  // TODO: Insert the country flag when it will be available from the server
  return (
    <>
      {Boolean(menuItems?.length) && (
        <div className={clsx(classes.flexColumn)}>
          <FormHelperText className={clsx(classes.basicSelectLabel, classes.basicText)}>{label}</FormHelperText>
          <Select
            open={open}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              classes: { list: classes.singlelist },
              autoFocus: false,
              getContentAnchorEl: null,
              PopoverClasses: { paper: classes.border }
            }}
            variant="outlined"
            value={selectedMenuItem.id}
            displayEmpty
            className={clsx(classes.basicSelect, classes.basicText, classes.customSelect)}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
            onChange={(event: ChangeEvent<{ value: any }>) => {
              // TODO: Implement better solution to displaying Tooltips at disabled menuItems
              const id = Number(event.target.value);
              const teamData = menuItems?.find((item) => item.id === id);
              if (teamData?.isAvailable) {
                setSelectedMenuItem(teamData);
                setTeamId(teamData.id);
                return setOpen(true);
              }
              return setOpen(false);
            }}
          >
            <MenuItem disabled value={0}>
              <p className={classes.placeholder}>{placeholder}</p>
            </MenuItem>
            {menuItems?.map((item: TeamSelectItem) => (
              <MenuItem
                classes={{
                  root: clsx(
                    classes.basicText,
                    classes.singleItem,
                    classes.singleTeamItem,
                    { [classes.active]: selectedMenuItem?.name === item.name && item.isAvailable },
                    { [classes.disabledListItem]: !item.isAvailable },
                    { [classes.listItem]: item.isAvailable }
                  ),
                  selected: clsx({ [classes.active]: !item.isAvailable }),
                }}
                key={item.id}
                value={item.id}
                disableGutters
                disableRipple
                className={classes.customItem}
              >
                <div className={classes.itemData}>
                  {item
                    && (
                      <div className={classes.avatarContainer}>
                        <MemberAvatar countryFlag={null} profilePicture={item.picture} />
                      </div>
                    )}
                  {item.name}
                </div>
                <div className={classes.jsxComponent}>
                  {item.blockReason && getTooltip(item.blockReason)}
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </>
  );
}

TeamSelect.defaultProps = defaultProps;

export default TeamSelect;