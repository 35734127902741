import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: 16,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  tournamentImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 0,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      marginBottom: 30,
      marginRight: 0,
    },
    '& img': {
      maxWidth: '60vw',
      maxHeight: 325,
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        maxWidth: '90vw'
      },
    }
  },
  date: {
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: colors.secondary
  },
  title: {
    fontSize: 48,
    lineHeight: '60px',
    fontWeight: 'bold',
    color: colors.secondary,
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: 44,
      lineHeight: '50px'
    },
  },
  tag: {
    margin: '16px 0 21px'
  },
  description: {
    width: '100%',
    marginTop: 32,
    maxWidth: 896,
    order: 3,
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  descriptionText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  info: {
    width: '60%',
    maxWidth: 625,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  winners: {
    marginTop: 20,
    padding: 0,
    maxWidth: 330,
    backgroundColor: '#fff',
  },
  button: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      marginTop: 24
    },
  },
  hidden: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  weatherDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  tournamentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weatherMobile: {
    marginTop: -15,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  }
}));

export default useStyles;