export const fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf'
  },
};

export const dimensions = {
  roundCellHeight: 19,
  headerHeight: 100,
  gap: 20,
  a4Height: 841,
  horizontalMargin: 30,
  marginTop: 17,
};

export const tableStyles = {
  tableHeader: {
    bold: true,
    fontSize: 25,
    color: 'black',
  },
  subHeader: {
    bold: false,
    fontSize: 11,
    color: 'black',
  },
  smallText: {
    bold: false,
    fontSize: 10,
    color: 'black',
  },
};