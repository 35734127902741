import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import SelectItem from '../../../../models/components/selects/SelectItem';
import useStyles from '../../team/Team.styles';
import ATCheckbox from '../../../checkbox/Checkbox';
import FilterField from '../../../../models/pages/availiableTournaments/FilterField';
import AppliedTournamentsFiltersModel from '../../../../models/pages/availiableTournaments/AppliedTournamentsFiltersModel';

const defaultProps = {
  disabled: false,
  menuItems: null
};

const Mobile = (props: {
  menuItems?: SelectItem[] | null
  filterProps: FilterField
  selectedFilters: AppliedTournamentsFiltersModel
  setSelectedFilters: React.Dispatch<React.SetStateAction<AppliedTournamentsFiltersModel>>
  disabled?: boolean
}) => {
  const {
    menuItems, disabled, setSelectedFilters, selectedFilters, filterProps
  } = props;
  const classes = useStyles({});
  const [open, setOpen] = useState(false);

  const handleAccordionChange = (item: string) => {
    const index = selectedFilters[filterProps.filterName]?.indexOf(item);
    if (index >= 0) {
      selectedFilters[filterProps.filterName].splice(index, 1);
    } else {
      selectedFilters[filterProps.filterName].push(item);
    }
    setSelectedFilters({ ...selectedFilters });
  };

  return (
    <div className={classes.accordion}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Accordion
          expanded={open}
          disabled={disabled}
          square
          onChange={() => setOpen((prev) => !prev)}
        >
          <AccordionSummary
            disableRipple
            expandIcon={<ExpandMoreIcon />}
            className={clsx(classes.accordionSummary, classes.basicText)}
          >
            <FormHelperText className={classes.label}>{filterProps.label}</FormHelperText>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionItemsContainer}>
            {menuItems?.map((item: SelectItem) => (
              <MenuItem
                classes={{ root: classes.listItem }}
                disableGutters
                disableRipple
                key={item.id || uuidv4()}
                value={item.name}
                className={classes.menuItemContainer}
                onChange={() => handleAccordionChange(item.name)}
              >
                <ATCheckbox
                  checked={selectedFilters[filterProps.filterName]?.includes(item.name)}
                  label={item.name}
                  onChange={() => { }}
                />
              </MenuItem>
            ))}
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
    </div>
  );
};

Mobile.defaultProps = defaultProps;

export default Mobile;