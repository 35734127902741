import React from 'react';
import {
  Button, Grid, DialogActions, DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import Loader from '../../../../../../../components/loader/Loader';
import useStyles from '../../Locations.styles';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import { useStores } from '../../../../../../../stores/Main';
import Toasters from '../../../../../../../components/popUp/PopUp';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const DeleteLocation = observer((props: {
    handleClose?: EmptyVoidFunction;
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { handleClose } = props;
  const { LocationStore } = useStores();
  const deleteLocation = async () => {
    const response = await LocationStore.deleteLocation(LocationStore.id);
    if (response) {
      Toasters.success(l10n.components.deleteLocation.LOCATION_DELETE_SUCCESS);
      LocationStore.refreshLocations();
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (LocationStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.deleteLocation.CONFIRM_DELETE}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-testid="cancel-delete"
        >
          {l10n.components.deleteLocation.CANCEL_DELETE}
        </Button>
        <Button
          onClick={deleteLocation}
          color="primary"
          variant="contained"
          data-testid="confirm-delete"
          autoFocus
        >
          {l10n.components.deleteLocation.DELETE}
        </Button>
      </DialogActions>
    </>
  );
});

export default DeleteLocation;