import { v4 as uuidv4 } from 'uuid';
import UserRating from '../../../../models/pages/user/UserRating';
import { groupBy } from '../../../../utils/Utils';

const getUserResultsByRatings = (ratings: UserRating[]): UserRating[] => {
  const getEmptyRatingRow = (rating: string): UserRating => ({
    rating,
    season: '',
    tournamentsAmount: null,
    tournamentsInSeason: null,
    score: null,
    catches: null,
    place: null,
    id: uuidv4(),
  });

  const groupBySeason = ratings.reduce((acc: any, curr: any) => {
    if (acc[curr.rating + curr.season]) {
      acc[curr.rating + curr.season].score += curr.score;
      acc[curr.rating + curr.season].catches += curr.catches;
    } else {
      acc[curr.rating + curr.season] = { ...curr };
    }
    return acc;
  }, {});

  const groupedResults: { [key: string]: UserRating[] } = groupBy(Object.values(groupBySeason), 'rating');

  return Object.keys(groupedResults)
    .sort()
    .reduce((seed: UserRating[], rating: any) => {
      const emptyRatingRow = getEmptyRatingRow(rating);
      return [
        ...seed,
        emptyRatingRow,
        ...groupedResults[rating]
          .map((result) => {
            result.parentId = emptyRatingRow.id;
            result.id = uuidv4();
            return result;
          })
      ];
    }, []);
};

export default getUserResultsByRatings;