import React from 'react';
import { getImageUrl } from '../../../../../../../../utils/Utils';
import tournamentLogo from '../../../../../../../../assets/tournament-logo.png';
import Report from '../../../../../../../../models/components/report/Report';
import useStyles from '../../ReportCard.styles';

const ReportPreviewImg = (props: { report: Report }) => {
  const { report } = props;
  const classes = useStyles();
  return (
    <div className={classes.pictureWrapper}>
      {!!report.images.length && <img src={getImageUrl(report.images[0].url)} className={classes.previewImg} alt="reportLogo" />}
      {!report.images.length && report.tournament.tournamentPicture
        && <img src={getImageUrl(report.tournament.tournamentPicture.url)} className={classes.previewImg} alt="tournamentLogo" />}
      {!report.images.length && !report.tournament.tournamentPicture
        && <img src={tournamentLogo} className={classes.previewImg} alt="tournamentLogo" /> }
    </div>
  );
};

export default ReportPreviewImg;