import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import TournamentService from '../../../../../services/main/Tournament.service';
import Toasters from '../../../../../components/popUp/PopUp';
import useStyles from './TournamentsImages.styles';
import PhotoGallery from '../../../../../components/photoGallery/PhotoGallery';
import PhotosGalleryState from '../../../../../models/components/photoGallery/PhotosGalleryState';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const TournamentImages = observer(() => {
  const url = useParams<{ id: string }>();
  const id = Number(url.id);
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [photosData, setPhotosData] = useState<PhotosGalleryState>({
    isChangedPhotosOutside: false,
    photos: []
  });
  const l10n = useTranslation();
  const getTournament = async () => {
    const response = await TournamentService.getById(id);
    if (response) {
      setPhotosData((prev) => ({ ...prev, photos: [...response.photos] }));
    }
  };

  useEffect(() => {
    getTournament();
  }, []);

  const postImages = async () => {
    const imageIds = photosData.photos?.map((item: any) => item.id);
    const tournamentPhotos = {
      tournamentId: id,
      imageIds
    };
    if (tournamentPhotos.imageIds) {
      setIsLoaded(true);
      const response = await TournamentService.patchImages(tournamentPhotos);
      if (response) {
        Toasters.success(l10n.components.toasterMessage.CHANGES_HAVE_BEEN_SAVED);
        getTournament();
        setPhotosData((prev) => ({ ...prev, isChangedPhotosOutside: false }));
      }
      setIsLoaded(false);
    }
  };

  if (isLoaded) {
    return (<LoaderFullScreen />);
  }
  return (
    <div>
      <Typography variant="h6" gutterBottom align="center">
        {photosData.photos.length ? l10n.pages.IMAGES : ''}
      </Typography>
      <PhotoGallery photosData={photosData} setPhotosData={setPhotosData} isReadMode={false} />
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={postImages}
        disabled={!photosData.isChangedPhotosOutside}
        className={classes.saveButton}
      >
        {l10n.components.button.SAVE}
      </Button>
    </div>
  );
});

export default TournamentImages;