import Participant from '../../../../../../../models/pages/tournament/Participant';
import { toLocalDate } from '../../../../../../../utils/Utils';
import { getTranslation } from '../../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const getTableHeader = (participant: Participant, name: string, date: string, id: number) => (
  [
    [
      {
        image: participant.dataURL ? participant.dataURL : 'noAvatar',
        alignment: 'center',
        fit: [50, 50],
        width: 50,
        height: 50,
        margin: [0, 4],
        rowSpan: 2,
        colSpan: 2,
        border: [true, true, false, true]
      },
      {},
      {
        text: `${name} - ${toLocalDate(date)}`,
        style: 'smallText',
        colSpan: 5,
        alignment: 'center',
        border: [false, true, false, false],
      },
      {},
      {},
      {},
      {},
      {
        qr: `${process.env.REACT_APP_DOMAIN_ADRESS_KEY}/tournaments/${id}`,
        fit: '60',
        rowSpan: 2,
        alignment: 'right',
        border: [false, true, true, true]
      },
    ],
    [
      {},
      {},
      {
        text: `${participant.anglerNumber}  ${participant.angler.fullName}`,
        style: 'tableHeader',
        colSpan: 5,
        alignment: 'left',
        border: [false, false, false, true]
      },
      {},
      {},
      {},
      {},
      {}
    ],
    [
      {
        text: l10n.pages.ROUND, style: 'sub', rowSpan: 2, margin: [0, 9], alignment: 'center'
      },
      {
        text: l10n.pages.AREA, style: 'subHeader', margin: [0, 9], rowSpan: 2, alignment: 'center'
      },
      {
        text: l10n.pages.CATCHES, style: 'subHeader', margin: [0, 1], colSpan: 2, alignment: 'center'
      },
      {},
      {
        text: l10n.pages.POINTS, style: 'subHeader', margin: [0, 9], rowSpan: 2, alignment: 'center'
      },
      {
        text: l10n.pages.OPPONENT, style: 'subHeader', margin: [0, 1], colSpan: 3, alignment: 'center'
      },
      {},
      {},
    ],
    [
      {},
      {},
      {
        text: l10n.pages.MY_POINTS, style: 'subHeader', margin: [0, 1], alignment: 'center'
      },
      {
        text: l10n.pages.OPPONENT, style: 'smallText', margin: [0, 1], alignment: 'center'
      },
      {},
      {
        text: l10n.pages.NUM, style: 'subHeader', margin: [0, 1], alignment: 'center'
      },
      {
        text: l10n.pages.NAME, style: 'subHeader', margin: [0, 1], alignment: 'center'
      },
      {
        text: l10n.pages.SIGN, style: 'subHeader', margin: [0, 1], alignment: 'center'
      }
    ]
  ]
);

export default getTableHeader;