import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import Routes from '../../../../routing/Routes';
import AuthorizationService from '../../../../services/main/Authorization.service';
import useStyles from '../ConfirmationEmail.style';
import ATButton from '../../../button/Button';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import Loader from '../../../loader/Loader';
import { useQuery } from '../../../../utils/Utils';
import useTranslation from '../../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const ConfirmationContent = (props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const styles = useStyles();
  const l10n = useTranslation();
  const query = useQuery();
  const userId = query.get('userId');
  const token = query.get('token');

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);

  const confirmationEmail = async () => {
    if (userId && token) {
      const response = await AuthorizationService.confirmationEmail(userId, token);
      if (response) {
        setIsSuccess(true);
      }
    }
    setIsLoading(false);
  };

  const redirectToHome = () => {
    if (handleClose) {
      handleClose();
    }
    window.location.replace(Routes.Home.path);
  };

  useEffect(() => {
    confirmationEmail();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.checkmarkBox}>
            <b className={styles.checkmarkSuccess}>✓</b>
          </div>
          <Typography className={styles.title}>{l10n.components.authorization.TITLE_EMAIL_CONFIRMED}</Typography>
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            onClick={redirectToHome}
          >
            {l10n.components.authorization.BUTTON_EMAIL_CONFIRMED}
          </ATButton>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={clsx(styles.wrapperError, styles.wrapper)}>
        <div className={clsx(styles.checkmarkBoxError, styles.checkmarkBox)}>
          <b className={clsx(styles.checkmarkError, styles.checkmarkSuccess)}>✘</b>
        </div>
        <>
          <Typography className={styles.title}>{l10n.components.authorization.TITLE_FAILED_RESULT}</Typography>
          <Typography className={styles.text}>
            {l10n.components.authorization.DESCRIPTION_FAILED_RESULT}
          </Typography>
        </>
        <ATButton
          variant="primary"
          fill="solid"
          size="lg"
          onClick={redirectToHome}
        >
          {l10n.components.authorization.BUTTON_DESCRIPTION_FAILED}
        </ATButton>
      </div>
    </div>
  );
};

ConfirmationContent.defaultProps = defaultProps;

export default ConfirmationContent;