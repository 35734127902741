import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import useStyles from './UserList.styles';
import noAvatar from '../../assets/icons/no-avatar.svg';
import UserListData from '../../models/pages/user/UserList';
import Modal from '../modal/Modal';
import MemberList from '../memberList/MemberList';
import { EmptyVoidFunction } from '../../utils/types/Types';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';
import UpcomingTournamentEntry from '../../models/pages/tournament/UpcomingTournamentEntry';
import { getImageUrl } from '../../utils/Utils';

const defaultProps = {
  className: '',
  getTournaments: () => {}
};

const UserList = (props: {
  getTournaments?: EmptyVoidFunction,
  users: UserListData | ClosestTournamentCardData | UpcomingTournamentEntry,
  className?: string,
}) => {
  const classes = useStyles();
  const {
    className, users, getTournaments
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const participantsCount = users?.participantsCount + users?.waitListParticipantsCount;

  return (
    <Grid>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className={`${classes.wrapper} ${className}`}
      >
        {!!users?.participantPhotos?.length
        && (
          <AvatarGroup spacing="medium" className={classes.avatarGroup}>
            {users?.participantPhotos?.map((user: any) => (
              <Avatar
                key={user?.id || uuidv4()}
                className={classes.avatar}
                alt={user?.fileName || 'avatar'}
                src={user?.url ? getImageUrl(user?.miniatureImageUrl) : noAvatar}
              />
            ))}
          </AvatarGroup>
        )}
        <div className={classes.ratio}>
          <span className={clsx(classes.count, { [classes.limit]: participantsCount > users?.participantsLimit })}>{participantsCount}</span>
          /
          {users?.participantsLimit}
        </div>
      </button>
      <Modal
        header=""
        modalClassName={classes.modal}
        open={open}
        setOpen={setOpen}
      >
        <MemberList users={users} getTournaments={getTournaments} />
      </Modal>
    </Grid>

  );
};

export default UserList;

UserList.defaultProps = defaultProps;