import {
  makeAutoObservable,
} from 'mobx';

export default class UserStore {
  userId: string = '';

  mainStore: any;

  constructor(mainStore: any) {
    this.mainStore = mainStore;

    makeAutoObservable(this);
  }

  setId = (tackleId: string) => {
    this.userId = tackleId;
  }
}