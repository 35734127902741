import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: '0 10px'
  },
  link: {
    color: colors.link
  },
  header: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    margin: '0 16px 20px 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '28px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 19,
      lineHeight: '24px',
    },
  },
}));

export default useStyles;