import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  tags: {
    '&.MuiChip-root': {
      height: 22
    },
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 4,
    lineHeight: '22px',
    '&.event-close': {
      background: 'rgba(253, 90, 67, 0.15)',
      color: '#FD4A32',
    },
    '&.event-planned': {
      background: 'rgba(106, 13, 178, 0.15)',
      color: '#6A0DB2',
    },
    '&.event-open': {
      background: 'rgba(13, 169, 179, 0.15)',
      color: '#0DA9B3',
    },
    '&.event-canceled': {
      background: 'rgba(145, 145, 149, 0.15)',
      color: '#858697',
    },
    '&.event-now': {
      background: 'rgba(25, 168, 1, 0.15)',
      color: '#19A801',
    },
    '&.event-completed': {
      background: 'rgba(0, 51, 183, 0.15)',
      color: '#0033B7',
    },
    '&.event-icon': {
      backgroundColor: 'rgba(20, 20, 41, 0.1)',
      padding: '0 12px',
      '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '&.news': {
      background: 'rgba(255, 255, 255, 0.64)',
      backdropFilter: 'blur(4px)',
      color: '#3C3C3C',
    },
    '&.news-article': {
      background: '#0E3AAA',
      backdropFilter: 'blur(4px)',
      color: '#FFFFFF',
    },
    '&.news-announcement': {
      background: '#0DA9B3',
      backdropFilter: 'blur(4px)',
      color: '#FFFFFF',
    },
    '&.news-review': {
      background: '#009e0f',
      backdropFilter: 'blur(4px)',
      color: '#FFFFFF',
    },
  },
  filterTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    background: 'rgba(13, 169, 179, 0.2)',
    boxSizing: 'border-box',
    paddingLeft: 10,
    margin: '5px 10px',
    textAlign: 'left',
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      margin: '6px 8px',
      width: '44%',
    },
  }
}));

export default useStyles;