import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  modalWide: {
    position: 'absolute',
    top: -5,
    right: 0
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('lg')]: {
      height: 700,
    },
  },
  formsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'unset',
      marginTop: 43
    },
  },
  image: {
    width: '50%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  SignFormModal: {
    '& .MuiDialog-paperWidthSm': {
      position: 'relative',
      width: '85%',
      maxWidth: 1322,
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
      overflowY: 'hidden',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto',
      },
    }
  }
}));

export default useStyles;