import React, { useState, DragEvent } from 'react';
import { Fab } from '@material-ui/core';
import Avatar from 'react-avatar-edit';
import { SaveAlt } from '@material-ui/icons';
import useStyles from './UploadImage.styles';
import ImageService from '../../../services/main/Image.service';
import { b64toBlob } from '../../../utils/Utils';
import useTranslation from '../../localization/customHooks/Translation';
import Toasters from '../../popUp/PopUp';

interface ResImageData {
  id: number;
  filename: string;
  originalFilename: string;
  createdAt: string;
  url: string
}

type onCloseFunction = () => void;

const UploadImage = (props: {
  setPictureId: React.Dispatch<React.SetStateAction<number | null>>,
  handleClose: onCloseFunction,
  setImagePreview: React.Dispatch<React.SetStateAction<string | undefined>>,
  imagePreview: undefined | string;
}) => {
  const {
    setPictureId, handleClose, setImagePreview, imagePreview,
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [imageURL, setImageURL] = useState('');
  const [image, setImage] = useState<any>();
  const stopPerformance = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFileLoad = (file: any) => {
    if (file) {
      setImage(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const postImages = async (event: any) => {
    stopPerformance(event);
    if (imagePreview) {
      const file = b64toBlob(imagePreview);
      const formData = new FormData();
      formData.append('image', file, 'image.jpg');
      const res: ResImageData = await ImageService.post(formData);
      setPictureId(res.id);
      handleClose();
    }
  };

  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files[0].size > 20 * 1024 * 1024) {
      Toasters.error(l10n.components.userImage.SIZE_ERROR);
      elem.target.value = '';
    }
  };

  const onClose = () => {
    setImage(null);
    setImagePreview(undefined);
  };

  const onCrop = (newPreview: string) => {
    setImagePreview(newPreview);
  };

  return (
    <div className={classes.uploadWrapper}>
      <form className={classes.uploadForm} onSubmit={postImages}>
        <div className={classes.preview}>
          <Avatar
            label={l10n.components.userImage.LABEL_CHOOSE_IMG}
            width={390}
            height={295}
            onCrop={onCrop}
            onClose={onClose}
            onFileLoad={onFileLoad}
            onBeforeFileLoad={onBeforeFileLoad}
            src={imageURL}
          />
        </div>
        <div className={classes.saveImageButton}>
          <Fab size="small" variant="extended" type="submit" disabled={!image}>
            <SaveAlt className={classes.extendedIcon} />
            {l10n.components.userImage.SAVE_BUTTON}
          </Fab>
        </div>
      </form>
    </div>
  );
};

export default UploadImage;