import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme: Theme) => createStyles({
  popover: {
    pointerEvents: 'none',
    borderRadius: '8px',
  },
  paper: {
    padding: theme.spacing(1),
    boxShadow: 'none',
    filter: 'drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.15))',
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      marginTop: 18
    }
  },
  wrapper: {
    padding: 8,
    color: colors.secondary,
  },
  divider: {
    backgroundColor: '#E2E4F0',
    margin: '6px 0'
  },
  member: {
    marginTop: 6
  }
}));

export default useStyles;