import React from 'react';
import Locations from './components/location/Locations';
import Ratings from './components/rating/Rating';
import Countries from './components/countries/Countries';
import Rankings from './components/rankings/Rankings';
import Formats from './components/format/Formats';
import Seasons from './components/seasons/Seasons';
import TournamentTemplates from './components/templates/Templates';
import TabsContainer from '../../../components/tabsContainer/TabsContainer';
import useTranslation from '../../../components/localization/customHooks/Translation';
import Sponsors from './components/sponsors/Sponsors';

const Dictionaries = () => {
  const l10n = useTranslation();
  const dictionariesData = [
    {
      name: l10n.pages.LOCATIONS,
      component: <Locations />,
      hash: '#locations'
    },
    {
      name: l10n.pages.RATINGS,
      component: <Ratings />,
      hash: '#ratings'
    },
    {
      name: l10n.pages.COUNTRIES,
      component: <Countries />,
      hash: '#countries'
    },
    {
      name: l10n.pages.RANKINGS,
      component: <Rankings />,
      hash: '#rankings'
    },
    {
      name: l10n.pages.FORMATS,
      component: <Formats />,
      hash: '#formats'
    },
    {
      name: l10n.pages.SEASONS,
      component: <Seasons />,
      hash: '#seasons'
    },
    {
      name: l10n.pages.TEMPLATES,
      component: <TournamentTemplates />,
      hash: '#templates'
    },
    {
      name: l10n.pages.SPONSORS,
      component: <Sponsors />,
      hash: '#sponsors'
    }
  ];

  return (
    <TabsContainer data={dictionariesData} />
  );
};

export default Dictionaries;