import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    maxWidth: 1360,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        marginTop: 40,
        marginBottom: 24
      },
    },
  },
  wrapperImage: {
    '&.small': {
      flex: '1 1 auto',
    },
  },
  image: {
    width: '100%',
    height: 200,
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  wrapperLink: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    transition: '.3s ease-out',
    '& img': {
      filter: 'brightness(30%)'
    },
    '&:hover': {
      '& img': {
        filter: 'brightness(38%)'
      },
    }
  },
  button: {
    display: 'contents',
    padding: 0,
    margin: 0,
    position: 'relative',
  },
  link: {
    position: 'absolute',
  },
  wrapperCloseIcon: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    opacity: 0.5,
    background: 'white',
  },
}));

export default useStyles;