import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  modalWindow: {
    minWidth: 200,
  },
  buttonPadding: {
    padding: 10,
  },
  statusText: {
    fontSize: 16,
    marginBottom: 10,
  },
  invalid: {
    color: colors.red,
    width: 200,
    marginBottom: 15,
  },
}));

export default useStyles;