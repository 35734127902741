import DOMPurify from 'dompurify';
import React from 'react';

function RTEtext(props: {
  text: string,
  className?: string
}) {
  const { text, className } = props;
  const createMarkup = (html: any) => ({ __html: DOMPurify.sanitize(html) });
  return (
    <>
      {// eslint-disable-next-line
        <div className={className} dangerouslySetInnerHTML={createMarkup(text)} />
      }
    </>
  );
}

RTEtext.defaultProps = {
  className: ''
};

export default RTEtext;