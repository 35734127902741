import SelectField from '../../../models/pages/availiableTournaments/FilterField';
import { selectType } from '../../../utils/enum/Enum';
import SeasonsService from '../../../services/main/dictionaries/Season.service';
import { getTranslation } from '../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const AvailbleRatingFilters: SelectField[] = [
  {
    filterName: 'countryId',
    placeHolder: l10n.components.placeholder.ALL_COUNTRIES,
    label: l10n.pages.COUNTRY,
    type: selectType.single,
    items: []
  },
  {
    filterName: 'seasonId',
    placeHolder: l10n.components.placeholder.ALL_SEASONS,
    label: l10n.pages.SEASON,
    getAvailableItems: SeasonsService.getAsSelectItems,
    type: selectType.single
  },
  {
    filterName: 'ratingId',
    placeHolder: l10n.components.placeholder.ALL_RATINGS,
    label: l10n.pages.RATING,
    type: selectType.single,
    items: []
  },
];

export default AvailbleRatingFilters;