import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapperSearchBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    gap: '3%',
  },
  searchButton: {
    width: '40%',
    height: 56,
  },
  fullWidth: {
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: 0
    }
  },
  invalid: {
    color: colors.primaryError,
    width: '100%',
    height: 22,
    margin: 0
  },
  smallText: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px'
  },
  partnerContainer: {
    width: '100%',
    height: 56,
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: '4px 10px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      gap: 5
    },
  },
  deleteItems: {
    width: 24,
    height: 24,
    zIndex: 100,
    border: 'none',
    borderRadius: '50%',
    background: 'inherit',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(13, 169, 179, 0.15)',
    },
    '&:active': {
      backgroundColor: 'rgba(13, 169, 178, 0.3)'
    }
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  disabledInput: {
    opacity: 0.6,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 260
    },
  },
}));

export default useStyles;