import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from '../../../../../stores/Main';
import EditSponsorsTable from './components/edit/EditSponsorsTable';
import Button from '../../../../../components/button/Button';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import useStyles from './Sponsors.style';
import Toasters from '../../../../../components/popUp/PopUp';
import CarouselImage from '../../../../../components/carouselImage/CarouselImage';
import {
  setDefaultStateCheckbox, areAllCheckboxesEmpty
} from './Sponsors.utils';
import { getImageUrl } from '../../../../../utils/Utils';
import { SelectedSponsor } from '../../../../../models/components/dictionaries/sponsor/Sponsor';
import ImageData from '../../../../../models/components/image/ImageData';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';

const EditSponsors = observer(() => {
  const { SponsorsStore, TournamentStore } = useStores();

  const classes = useStyles();
  const l10n = useTranslation();
  const url = useParams<{ id: string }>();
  const id = Number(url.id);

  const [selectedMainSponsor, setSelectedMainSponsor] = useState('');
  const [selectedTournamentSponsors, setSelectedTournamentSponsors] = useState<SelectedSponsor[]>([]);
  const [selectedTournamentsImages, setSelectedTournamentsImages] = useState<ImageData[]>([]);

  useEffect(() => {
    SponsorsStore.refreshSponsors();
  }, []);

  useEffect(() => {
    if (SponsorsStore.sponsors && TournamentStore.tournament) {
      if (TournamentStore.tournament!.generalSponsor) setSelectedMainSponsor(TournamentStore.tournament!.generalSponsor.name);
      if (TournamentStore.tournament!.sponsors && TournamentStore.tournament!.sponsors.length) {
        const start = [...setDefaultStateCheckbox(true, TournamentStore.tournament!.sponsors)];
        setSelectedTournamentSponsors(start);
      } else {
        setSelectedTournamentSponsors(setDefaultStateCheckbox(false, SponsorsStore.sponsors!));
      }
    }
  }, [SponsorsStore.sponsors, TournamentStore.tournament]);

  const getImgTournamentSponsors = () => {
    const sponsorsImgUrlList = selectedTournamentSponsors
      .filter((curr: SelectedSponsor) => curr.isSelected === true)
      .map((curr: SelectedSponsor) => curr.logo);
    setSelectedTournamentsImages(sponsorsImgUrlList);
  };

  const clearAllFields = () => {
    setSelectedMainSponsor('');
    setSelectedTournamentSponsors(setDefaultStateCheckbox(false, SponsorsStore.sponsors!));
    setSelectedTournamentsImages([]);
  };

  const getMainSponsorImage = () => {
    const generalSponsor = TournamentStore.tournament?.generalSponsor;
    const sponsorUrl = SponsorsStore.getSponsorByName(selectedMainSponsor)?.logo?.thumbnailImageUrl
      || generalSponsor?.logo.thumbnailImageUrl || generalSponsor?.logo.url || '';
    return getImageUrl(sponsorUrl);
  };

  const getIdOfTournamentSponsors = (selectedSponsors: SelectedSponsor[]) => selectedSponsors
    .filter((curr: SelectedSponsor) => curr.isSelected === true)
    .map((curr: SelectedSponsor) => curr.id);

  const saveSponsors = async () => {
    const payload = {
      tournamentId: id,
      sponsorsIds: selectedTournamentSponsors ? getIdOfTournamentSponsors(selectedTournamentSponsors) : [],
      generalSponsorId: selectedMainSponsor ? SponsorsStore.getSponsorByName(selectedMainSponsor)!.id : null,
    };
    const res = await TournamentStore.setTournamentSponsors(payload);
    if (res) {
      TournamentStore.getTournament(id);
      Toasters.success(l10n.components.sponsors.SPONSORS_ADDED);
    }
  };

  const urlList = useMemo(() => selectedTournamentsImages.map((el: ImageData) => getImageUrl(el.thumbnailImageUrl)), [selectedTournamentsImages]);

  useEffect(() => {
    if (selectedTournamentSponsors) getImgTournamentSponsors();
  }, [selectedTournamentSponsors]);

  if (!SponsorsStore!.sponsors!) {
    return <LoaderFullScreen />;
  }

  return (
    <div className={classes.mainWrapper}>
      <Button
        variant="primary"
        fill="solid"
        size="md"
        className={classes.resetButton}
        onClick={clearAllFields}
      >
        {l10n.components.sponsors.CANCEL_ALL}
      </Button>
      <div>
        <EditSponsorsTable
          sponsorsList={SponsorsStore.sponsors}
          selectedMainSponsor={selectedMainSponsor}
          setSelectedMainSponsor={setSelectedMainSponsor}
          selectedTournamentSponsors={selectedTournamentSponsors}
          setSelectedTournamentSponsors={setSelectedTournamentSponsors}
        />
        <div className={classes.wrapperSelectedSponsors}>
          {selectedMainSponsor && (
          <div className={clsx(classes.wrapper, classes.generalWrapper)}>
            <h3>{l10n.components.sponsors.GENERAL_SPONSOR}</h3>
            {selectedMainSponsor && (
            <img
              className={classes.sponsorImg}
              alt={l10n.components.sponsors.GENERAL_SPONSOR}
              src={getMainSponsorImage()}
            />
            )}
          </div>
          )}
          {!areAllCheckboxesEmpty(selectedTournamentSponsors) && (
          <div className={classes.wrapper}>
            <h3>{l10n.components.sponsors.SPONSORS}</h3>
            <CarouselImage
              urlList={urlList}
              width={300}
              imgStyles={classes.sponsorImg}
              showThumbs
            />
          </div>
          )}
        </div>
        <Button
          variant="primary"
          fill="solid"
          size="lg"
          className={classes.saveButton}
          onClick={saveSponsors}
        >
          {l10n.components.button.SAVE}
        </Button>
      </div>
    </div>
  );
});

export default EditSponsors;