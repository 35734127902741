import LocalizedStrings from 'react-localization';
import localization from '../languages';
import { useLanguageContext } from '../contexts/LanguageContext';
import { defaultLanguage, languageNames } from '../../../utils/constants/Constants';

const translation = new LocalizedStrings(localization);

export default function useTranslation() {
  const localizationContext = useLanguageContext();
  if (localizationContext?.language) {
    translation.setLanguage(languageNames.includes(localizationContext?.language) ? localizationContext?.language : defaultLanguage);
  }
  return translation;
}

export const getTranslation = () => translation;