import React, { useState, useEffect } from 'react';
import { Modal, Button, IconButton } from '@material-ui/core';
import closeIcon from '../../assets/icons/closeImg.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import useStyles from './upload/UploadImage.styles';
import UploadImage from './upload/UploadImage';
import ImagesService from '../../services/main/Image.service';
import { getImageUrl } from '../../utils/Utils';
import Loader from '../loader/Loader';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  pictureId: null,
  variant: '',
};

export default function UserImage(props: {
  setPictureId: React.Dispatch<React.SetStateAction<number | null>>;
  pictureId?: number | null;
  variant?: string;
}) {
  const {
    setPictureId, pictureId, variant
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>('');

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const onClose = () => {
    setPictureId(null);
    setImagePreview(undefined);
  };

  const getImage = async () => {
    setIsLoading(true);
    if (pictureId) {
      const response = await ImagesService.get(pictureId);
      if (response) {
        setImagePreview(getImageUrl(response.thumbnailImageUrl));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getImage();
  }, [pictureId]);

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <div className={classes.wrapper}>
      {imagePreview
        ? (
          <div className={`${classes.previewPicture} ${variant}`}>
            <img src={imagePreview} alt="Preview" />
            <IconButton
              disableRipple
              onClick={onClose}
              className={classes.closeButton}
              size="small"
            >
              <img src={closeIcon} alt="close" className={`${variant}`} />
            </IconButton>
          </div>
        )
        : (
          <>
            <Button
              disableRipple
              size="small"
              variant="outlined"
              onClick={handleOpen}
              className={classes.button}
            >
              <img src={uploadIcon} alt="upload icon" className={classes.extendedIcon} />
              {l10n.components.userImage.UPLOAD_PHOTO}
            </Button>
          </>
        )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div>
          <UploadImage
            handleClose={handleClose}
            setPictureId={setPictureId}
            setImagePreview={setImagePreview}
            imagePreview={imagePreview}
          />
        </div>
      </Modal>
    </div>
  );
}

UserImage.defaultProps = defaultProps;