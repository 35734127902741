import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useLanguageContext } from '../../localization/contexts/LanguageContext';
import useStyles from '../team/Team.styles';
import useTranslation from '../../localization/customHooks/Translation';
import { languageNames } from '../../../utils/constants/Constants';

export default function LanguageSelect() {
  const classes = useStyles();
  const l10n = useTranslation();
  const { changeLanguage } = useLanguageContext();

  const onLangClick = (lang: string) => {
    if (lang) {
      const selectedLanguage = languageNames.find((availableLanguage) => availableLanguage === lang);
      if (changeLanguage && selectedLanguage) {
        changeLanguage(selectedLanguage);
      }
      window.location.reload();
    }
  };

  return (
    <Grid className={classes.languageContainer}>
      {l10n.components.footer.TITLE_LANGUAGE}
      <Grid>
        {languageNames
          .map((lang: string) => (
            <div key={lang} className={classes.langItemContainer}>
              <button
                type="button"
                className={classes.langButton}
                onClick={
                  () => onLangClick(lang)
                }
              >
                {lang}
              </button>
            </div>
          ))}
      </Grid>
    </Grid>
  );
}