import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import DeleteUserConfirm from './modal/Modal';
import Modal from '../../../../../components/modal/Modal';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

type onFunction = () => void;

function DeleteUser(props: {
  id: string;
  getUsers: onFunction;
  isDisabled: boolean;
}) {
  const { id, getUsers, isDisabled } = props;
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const l10n = useTranslation();

  return (
    <>
      <IconButton
        data-testid="delete-user"
        edge="end"
        aria-label="delete"
        disabled={isDisabled}
        onClick={() => setOpenDelete(true)}
      >
        <Delete />
      </IconButton>
      <Modal
        header={l10n.components.headerLabel.DELETE_USER}
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <DeleteUserConfirm getUsers={getUsers} id={id} />
      </Modal>
    </>
  );
}

export default DeleteUser;