import React from 'react';
import clsx from 'clsx';
import useStyles from '../Article.style';
import { toLocalDate } from '../../../utils/Utils';
import NewsItem from '../../../models/pages/news/NewsItem';
import AnglerLink from '../../angler/anglerLink/AnglerLink';

function Footer(props: {
  article: NewsItem
}) {
  const classes = useStyles();
  const { article } = props;
  return (
    <div className={clsx(classes.footer, classes.footerWrapper, classes.basicTextStyle)}>
      <div className={classes.userWrapper}>
        <AnglerLink anglerData={article.author} avatarStyle="medium" />
      </div>
      <div className={classes.date}>{toLocalDate(article.postedAt)}</div>
    </div>
  );
}

export default Footer;