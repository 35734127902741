import {
  object, string,
} from 'yup';
import { checkUrl } from '../../../../../../utils/Utils';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const validateString = () => string().trim().required(l10n.components.tournamentsTemplate.REQUIRED_FIELD);

const schema = object().shape({
  name: validateString(),
  url: validateString().test('checkUrl', l10n.components.editProfile.VALIDATION_INVALID, (value) => checkUrl(value)),
});

export default schema;