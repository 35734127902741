import React from 'react';
import { Chip } from '@material-ui/core';
import useStyles from './Tags.styles';
import { EmptyVoidFunction } from '../../utils/types/Types';

const defaultProps = {
  onClick: () => {},
  className: '',
};

export default function Tags(props: {
  children: string | JSX.Element,
  variant: string,
  onClick?: EmptyVoidFunction,
  className?: string
}) {
  const {
    children, variant, onClick, className
  } = props;
  const styles = useStyles();

  return (
    <Chip className={`${styles.tags} ${variant} ${className}`} label={children} onClick={onClick} />
  );
}

Tags.defaultProps = defaultProps;