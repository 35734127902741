import React, { } from 'react';
import {
  useMediaQuery, useTheme
} from '@material-ui/core';
import Report from '../../../../../../../../models/components/report/Report';
import MobileCard from './desktop/Desktop';
import DesktopCard from './mobile/Mobile';

const ReportCard = (props: {
  report: Report,
  }) => {
  const {
    report
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (!isMobile ? (
    <MobileCard
      report={report}
    />
  ) : (
    <DesktopCard
      report={report}
    />
  )
  );
};

export default ReportCard;