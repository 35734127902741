import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Toasters from '../components/popUp/PopUp';
import { logError, StorageService } from '../utils/Utils';
import AuthorizationUser from '../models/service/AuthorizationUser';
import Error from '../models/service/Error';
import { getTranslation } from '../components/localization/customHooks/Translation';
import { defaultLanguage } from '../utils/constants/Constants';

const client = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_ADRESS_KEY,
});

function getAccessToken() {
  const accessToken: AuthorizationUser = StorageService.getUserData().tokens.jwtToken;
  return accessToken;
}

const processError = (error: Error, message?: string) => {
  const l10n = getTranslation();
  if (message) {
    return Toasters.error(message);
  }
  return Toasters.error(
    error.response?.data.Message || error.response?.data.StatusText || l10n.components.error.UNEXPECTED_ERROR
  );
};

client.interceptors.request.use(
  (request) => (
    new Promise((resolve) => {
      if (localStorage.user) {
        request.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      resolve(request);
    }))
);

const refreshAuthLogic = async (failedRequest: { response: { config: { headers: { [x: string]: string; }; }; }; }) => {
  const user = StorageService.getUserData();
  const refreshToken = { refreshToken: user.tokens.refreshToken };
  const { data } = await client.post('/api/Users/token/refresh', refreshToken);
  StorageService.setUserData({ ...user, tokens: data });
  failedRequest.response.config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return Promise.resolve();
};

createAuthRefreshInterceptor(client, refreshAuthLogic, {
  retryInstance: client
});

client.interceptors.response.use((res) => res, async (error) => {
  const l10n = getTranslation();
  const { response: { status } } = error;
  if (status === 403) {
    error.response.data.StatusText = l10n.components.error.SESSION_IS_EXPIRED;
    processError(error, l10n.components.error.SESSION_IS_EXPIRED);
    setTimeout(() => {
      window.location.replace('/');
      StorageService.deleteUserData();
    }, 5000);
  }
  return Promise.reject(error);
});

client.interceptors.request.use(
  (request) => (
    new Promise((resolve) => {
      request.headers['Accept-Language'] = localStorage.language || defaultLanguage;
      resolve(request);
    }))
);

const transformError = (error: Error) => {
  const errorMessage = error.response.data.Message.replace(
    'Validation failed: ',
    ''
  )
    .replace(' -- Model.Name:', '')
    .replace(' -- Model.PartnerId:', '');
  return {
    status: false,
    errorMessage,
  };
};

// eslint-disable-next-line
const put = async<T> (urlLink: string, data?: T, optionsData?: any, errorHandler?: (e: Error) => any) => {
  try {
    const response = await client.put<T>(urlLink, data, optionsData);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 500) {
      logError(e.response?.data.Message, e);
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    processError(e);
    return null;
  }
};

// eslint-disable-next-line
const post = async<T> (urlLink: string, data?: T, optionsData?: any, errorHandler?: (e: Error) => any) => {
  try {
    const response = await client.post<T>(urlLink, data, optionsData);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 500) {
      logError(e.response?.data.Message, e);
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    processError(e);
    return null;
  }
};

// eslint-disable-next-line
const patch = async<T>(urlLink: string, data: T, errorHandler?: (e: Error) => any) => {
  try {
    const response = await client.patch<T>(urlLink, data);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 500) {
      logError(e.response?.data.Message, e);
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    processError(e);
    return null;
  }
};

// eslint-disable-next-line
const remove = async (urlLink: string, errorHandler?: (e: Error) => any) => {
  try {
    const response = await client.delete(urlLink);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 500) {
      logError(e.response?.data.Message, e);
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    processError(e);
    return null;
  }
};

// eslint-disable-next-line
const get = async<T> (urlLink: string, errorHandler?: (e: Error) => any, params?: T) => {
  try {
    const response = await client.get<T>(urlLink, params);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 500) {
      logError(e.response?.data.Message, e);
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    processError(e);
    return null;
  }
};

export {
  client,
  processError,
  transformError,
  get,
  put,
  post,
  remove,
  patch,
};