import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  heading: {
    color: colors.secondary,
    fontSize: 48,
    lineHeight: '60px',
    fontWeight: 'bold',
    margin: '10px 0 32px 0',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 44,
      lineHeight: '50px',
      marginTop: 20
    },
  },
  breadcrumbs: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  container: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
    padding: '0 16px'
  },
  mapContainer: {
    width: '100%',
    height: '500px',
  },
  link: {
    color: colors.link
  },
  placemarkBalloonLink: {
    color: colors.link,
    textDecoration: 'underline'
  },
  mainContainer: {
    maxWidth: 1360,
    margin: 'auto',
    paddingLeft: 16,
  },
  cardStyle: {
    display: 'block',
    transitionDuration: '0.3s',
  },
  continueRead: {
    float: 'right',
  },
  textContainer: {
    width: '90%',
    marginblockend: 0,
    '& div': {
      maxHeight: '13em',
      whiteSpace: 'wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& p': {
      maxHeight: '13em',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      textOverflow: 'ellipsis',
    }
  },
  newsCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '& img': {
      objectFit: 'cover'
    }
  },
  previewContainer: {
    position: 'relative',
    marginBottom: 20,
  },
  previewImg: {
    maxWidth: '90%',
    maxHeight: 200,
    display: 'block',
    borderRadius: 8,
  },
  headerText: {
    width: 400
  },
  basicTextStyle: {
    wordBreak: 'break-word',
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    lineHeight: '24px',
  },
  hideContent: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    marginBlockStart: 0,
    marginBlockEnd: 0,
  }
}));

export default useStyles;