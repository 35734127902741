import { getTranslation } from '../../components/localization/customHooks/Translation';
import Error from '../../models/service/Error';
import { post, processError } from '../Http';

const url = '/api/Registrations/';
const urlRegistration = '/api/Tournaments/registration/';

const l10n = getTranslation();

const generateError = (error: Error) => {
  if (error.response.status === 400) {
    return processError(error, l10n.components.error.REACHED_MAX_PARTICIPANTS_ERROR);
  }
  processError(error);
  return null;
};

const generateErrorRegister = (e: Error) => {
  processError(e, l10n.components.error.IS_ALREADY_REGISTERED);
  return null;
};

const RegistrationService = {
  async transferToAwaitingParticipants(id: number) {
    return post(`${url}${id}/transfer/waitList`);
  },

  async transferToActiveParticipants(id: number) {
    return post(`${url}${id}/transfer/activeList`, undefined, undefined, generateError);
  },

  async registerUserByAdmin(tournamentId: number, userId: number | string) {
    return post(`${urlRegistration}manual`, { tournamentId, userId }, generateErrorRegister);
  },
};

export default RegistrationService;