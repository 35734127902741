import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import {
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import SelectItem from '../../../../models/components/selects/SelectItem';
import useStyles from '../../team/Team.styles';
import closeIcon from '../../../../assets/icons/select/closeIcon.svg';
import ATCheckbox from '../../../checkbox/Checkbox';
import FilterField from '../../../../models/pages/availiableTournaments/FilterField';
import AppliedTournamentsFiltersModel from '../../../../models/pages/availiableTournaments/AppliedTournamentsFiltersModel';

const defaultProps = {
  disabled: false,
  menuItems: null
};

const Desktop = (props: {
  menuItems?: SelectItem[] | null
  filterProps: FilterField
  selectedFilters: AppliedTournamentsFiltersModel
  setSelectedFilters: React.Dispatch<React.SetStateAction<AppliedTournamentsFiltersModel>>
  disabled?: boolean,
}) => {
  const {
    menuItems, disabled, setSelectedFilters, selectedFilters, filterProps
  } = props;

  const classes = useStyles();

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    event.preventDefault();
    selectedFilters[filterProps.filterName] = event.target.value as string[];
    setSelectedFilters({ ...selectedFilters });
  };

  const resetFilter = (key: string) => {
    selectedFilters[key] = [];
    setSelectedFilters({ ...selectedFilters });
  };

  return (
    <div className={clsx(classes.selectContainerWrapper, classes.basicText)}>
      <FormHelperText className={clsx(classes.label, classes.basicText)}>{filterProps.label}</FormHelperText>
      <div className={classes.selectContainer}>
        {Boolean(selectedFilters[filterProps.filterName].length)
          && (
            <button
              className={classes.deleteItems}
              onClick={() => resetFilter(filterProps.filterName)}
              type="button"
            >
              <img src={closeIcon} alt="closeIcon" />
            </button>
          )}
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            classes: { list: classes.list },
            autoFocus: false
          }}
          disabled={disabled}
          multiple
          displayEmpty
          variant="outlined"
          value={selectedFilters[filterProps.filterName]}
          onChange={handleSelectChange}
          renderValue={(selected) => {
            const items = selected as string[];
            if (!items.length) {
              return filterProps.placeHolder;
            }
            return items.join(', ');
          }}
          className={classes.select}
        >
          {menuItems?.map((item: SelectItem) => (
            <MenuItem
              classes={{ root: classes.listItem }}
              disableGutters
              disableRipple
              key={item.id || uuidv4()}
              value={item.name}
              className={classes.menuItemContainer}
            >
              <ATCheckbox
                checked={selectedFilters[filterProps.filterName]?.includes(item.name)}
                label={item.name}
                onChange={() => { }}
              />
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

Desktop.defaultProps = defaultProps;

export default Desktop;