import {
  remove, get, post, put
} from '../../Http';
import TournamentTemplate from '../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';

const url = '/api/templates/';

const TemplatesService = {
  async getAll() {
    return get(url);
  },

  async get(id: number) {
    return get(url + id);
  },

  async delete(id: number) {
    return remove(`${url + id}`);
  },

  async post(template: TournamentTemplate) {
    return post(url, template);
  },

  async update(template: TournamentTemplate) {
    const { id } = template;
    return put(url + id, template);
  },
};

export default TemplatesService;