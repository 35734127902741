import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  editProfileForm: {
    width: '82%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 544,
    marginTop: 48,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 328
    },
  },
  form: {
    width: '100%',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    '& .MuiFormControl-fullWidth': {
      height: '74px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  dateLabel: {
    margin: 0,
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    lineHeight: '135%',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  dateController: {
    marginBottom: 25,
  },
  dateBirth: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingBottom: 15,
      paddingTop: 15,
    }
  },
  label: {
    boxSizing: 'border-box',
    width: '40%',
    marginRight: 'auto',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '38px',
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      marginTop: 24,
      fontSize: 28,
    },
  },
  invalid: {
    color: colors.primaryError,
    '& .MuiFormHelperText-contained': {
      margin: '3px 0 0'
    }
  },
  select: {
    '& > div > div > div': {
      minWidth: 'auto',
      width: '100%'
    },
    '& > div > p': {
      marginLeft: 0
    }
  },
  button: {
    display: 'block',
    margin: '40px auto'
  },
  addImage: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalHeader: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '38px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
      lineHeight: '30px',
      marginBottom: 15
    },
  },
}));

export default useStyles;