import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ImageData from '../../models/components/image/ImageData';
import useStyles from './UserPicture.styles';
import { getImageUrl } from '../../utils/Utils';
import useTranslation from '../localization/customHooks/Translation';

const UserPicture = (props: {pictureInfo: ImageData,
  setImage: React.Dispatch<React.SetStateAction<any | null>>}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { pictureInfo, setImage } = props;

  const handleClick = () => {
    setImage((prev: ImageData[]) => prev!.filter((el) => el.id !== pictureInfo.id));
  };

  return (pictureInfo ? (
    <div className={classes.imgContainerOuter}>
      <div className={classes.imgContainerInner}>
        <img
          src={getImageUrl(pictureInfo.url)}
          alt={l10n.pages.PREVIEW}
          className={classes.imageWrapper}
        />
        <IconButton
          className={classes.closeButton}
          onClick={handleClick}
          size="small"
        >
          <Close />
        </IconButton>
      </div>
    </div>
  ) : null);
};

export default UserPicture;