import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    margin: '0 auto',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0 0 16px',
      overflow: 'hidden',
    }
  },
  wrapperPlace: {
    width: 36,
    marginRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
    }
  },
  hideIcons: {
    '& img': {
      opacity: 0
    },
  },
  scrollableWrapper: {
    display: 'flex',
    gap: 12,
    paddingTop: 12,
    paddingBottom: 16,
    borderBottom: '2px solid #E2E4F0',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
}));

export default useStyles;