/* eslint-disable max-len */
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ChangeEvent } from 'react';
import AuthorizationUser from '../models/service/AuthorizationUser';
import { USER_STORAGE_KEY, LANGUAGE } from './constants/Constants';
import Rating from '../models/components/dictionaries/rating/Rating';
import 'moment/locale/ru';
import ErrorService from '../services/main/Error.service';
import { getTranslation } from '../components/localization/customHooks/Translation';
import ImageData from '../models/components/image/ImageData';
import { RoundReport } from '../models/components/report/Report';
import { stages } from './enum/Enum';
import AvailableTournamentsForReport from '../models/pages/availiableTournaments/AvailableTournamentsForReport';

export const precision = 7;

const getPrettyCoords = (coords: Array<number> | null, index: number) => (coords ? Number(coords[index].toFixed(precision)) : null);

export const getPrettyLatitudeFromCoords = (coords: Array<number> | null) => getPrettyCoords(coords, 0);

export const getPrettyLongitudeFromCoords = (coords: Array<number> | null) => getPrettyCoords(coords, 1);

export const getPrettyGeoValue = (geoValue: number) => getPrettyCoords([geoValue], 0);

export const intersect = (arr1: Array<string>, arr2: Array<string>) => arr1.filter((entry) => arr2.indexOf(entry) !== -1);

export const hasRolesIntersection = (
  arr: Array<string> | undefined,
  currentUser: AuthorizationUser | undefined,
) => {
  if (arr?.length) {
    if (currentUser?.roles) {
      return intersect(arr, currentUser.roles).length > 0;
    }
    return false;
  }
  return true;
};

export const StorageService = {
  getUserData() {
    const storedItem = localStorage.getItem(USER_STORAGE_KEY);
    return storedItem ? JSON.parse(storedItem) : null;
  },

  setUserData(userData: AuthorizationUser) {
    const storedItem = JSON.stringify(userData);
    localStorage.setItem(USER_STORAGE_KEY, storedItem);
  },

  deleteUserData() {
    localStorage.removeItem(USER_STORAGE_KEY);
  },

  setLanguage(language: string) {
    localStorage.setItem(LANGUAGE, language);
  },

  getLanguage() {
    const storedItem = localStorage.getItem(LANGUAGE);
    return storedItem || null;
  },
};

export const toLocalDate = (date: any, format: string = 'L') => {
  const transformedDate = moment.utc(date);
  return transformedDate.local().locale('ru').format(format);
};

export const toLocalDateTime = (date: string) => {
  const transformedDate = moment.utc(date);
  return transformedDate.local().locale('ru').format('L LTS');
};

export const toDate = (date: any, format: string = 'L') => {
  const transformedDate = moment(date);
  return transformedDate.local().format(format);
};

export const toDateEndOfDay = (date: any, format: string = 'L') => {
  const transformedDate = moment(date);
  return transformedDate.endOf('day').local().format(format);
};

export const toISODate = (date: any) => `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`;

export const getNameWithDateAndLocation = (tournament: AvailableTournamentsForReport) => (`${toLocalDate(tournament.date, 'LL')} ${tournament.name} - ${tournament.location.name}`);

export const getRatingsIds = (
  allRatings: Rating[] | null,
  tournamentRatings: any[] | undefined,
) => allRatings?.reduce((acc: number[], item: Rating) => {
  if (
    tournamentRatings?.some(
      (el) => el.toUpperCase() === item.name.toUpperCase(),
    )
  ) {
    acc.push(item.id);
  }
  return acc;
}, []);

export const getImageUrl = (url: string) => `${process.env.REACT_APP_DOMAIN_ADRESS_KEY}${url}`;

export function b64toBlob(dataURI: string) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export async function logError(message: string | Event, error: Error | undefined) {
  await ErrorService.post({
    message,
    page: window.location.href,
    trace: error?.stack ? error.stack : 'stack trace is not available'
  });
}

export const groupBy = (array: any[], fieldName: string) => array.reduce((group, current) => {
  (group[current[fieldName]] = group[current[fieldName]] || []).push(current);
  return group;
}, {});

const decodeStringToHtml = (html: string) => {
  const txt = document.createElement('span');
  txt.innerHTML = html;
  return txt.innerText;
};

export const calculateResult = (anglerCatches: number, opponentCatches: number) => {
  const l10n = getTranslation();
  if (anglerCatches > opponentCatches) { return decodeStringToHtml(l10n.pages.WIN); }
  if (anglerCatches === opponentCatches && anglerCatches !== 0) { return decodeStringToHtml(l10n.pages.DRAW_WITH_POINTS); }
  if (anglerCatches < opponentCatches && anglerCatches === 0) { return decodeStringToHtml(l10n.pages.ZERO_DEFEAT); }
  if (anglerCatches < opponentCatches && anglerCatches <= opponentCatches / 2) { return decodeStringToHtml(l10n.pages.MORE_2X_DEFEAT); }
  if (anglerCatches < opponentCatches && anglerCatches > opponentCatches / 2) { return decodeStringToHtml(l10n.pages.LESS_2X_DEFEAT); }
  return decodeStringToHtml(l10n.pages.ZERO_DRAW);
};

const getStage: any = {
  [stages.quarterFinals]: 0,
  [stages.semiFinals]: 1,
  [stages.thirdPlace]: 2,
  [stages.firstPlace]: 3,
};

export const sortReportRounds = (rounds: RoundReport[]) => ([
  ...rounds.filter((r: RoundReport) => r.tournamentRound).sort((a, b) => a.tournamentRound.roundNumber - b.tournamentRound.roundNumber),
  ...rounds.filter((r: RoundReport) => r.playOffGame).sort((a, b) => getStage[a.playOffGame.stage] - getStage[b.playOffGame.stage]),
]);

export const checkUrl = (value?: string) => {
  let url: URL;

  if (value === '') return true;

  try {
    url = new URL(value!);
  } catch {
    return false;
  }

  return ['http:', 'https:'].includes(url.protocol);
};

export const onInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
  const { target } = event;

  if (!target.validity.valid) {
    target.value = '';
  }

  return target;
};

export const getImgUrlsList = (currPhotos: ImageData[]) => currPhotos.map(({ url }: ImageData) => getImageUrl(url));