import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import useStyles from './Breadcrumbs.styles';

const defaultProps = {
  className: '',
};

interface Breadcrumb {
  displayName: string;
  url: string;
  className?: string;
}

const Breadcrumbs = (props: {
  urls: Breadcrumb[]
  className?: string
}) => {
  const classes = useStyles();
  const { urls, className } = props;

  const transformUrls = urls.map((el: Breadcrumb, i: number) => (
    <div key={v4()}>
      {(i === urls.length - 1)
        ? (
          <span className={clsx(classes.root, classes.active)}>
            {el.displayName}
          </span>
        )
        : (
          <div className={classes.noActive}>
            <Link to={el.url}>
              <span className={classes.root}>
                {el.displayName}
              </span>
            </Link>
            <span className={classes.slash}>/</span>
          </div>
        )}
    </div>
  ));

  return (
    <div className={clsx(classes.root, className)}>
      {transformUrls}
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.defaultProps = defaultProps;