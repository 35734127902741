import React from 'react';
import TournamentFormat from '../../../../../../../models/pages/tournament/TournamentFormat';
import AreatroutTable from '../../../../../../../components/table';
import EditDeleteCell from './EditDeleteCell';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

function FormatsTable(props: {
  formatsList: TournamentFormat[] | null,
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setFormat: React.Dispatch<React.SetStateAction<TournamentFormat>>
}) {
  const {
    formatsList, setOpenEdit, setOpenDelete, setFormat,
  } = props;
  const l10n = useTranslation();
  const columns = [
    {
      displayName: l10n.components.tournamentFormat.FORMAT_NAME,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.tournamentFormat.FORMAT_DESCRIPTION,
      fieldName: 'description',
      allowSortring: false,
    },
    {
      displayName: l10n.components.tournamentFormat.CONTROL,
      fieldName: 'action',
      allowSortring: false,
      allowSearch: false,
      render: (data: TournamentFormat) => (
        <EditDeleteCell
          setFormat={setFormat}
          setOpenEdit={setOpenEdit}
          setOpenDelete={setOpenDelete}
          data={data}
        />
      ),
    },
  ];

  return (
    <>
      {formatsList && formatsList.length
        ? (
          <AreatroutTable
            data-testid="table"
            columns={columns}
            rows={formatsList}
          />
        )
        : <p>{l10n.components.tournamentFormat.FORMATS_ARE_EMPTY}</p>}
    </>
  );
}

export default FormatsTable;