import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../RecoveryPasswod.styles';
import ATButton from '../../../button/Button';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const RecoveryLinkSend = (props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const styles = useStyles();
  const l10n = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.checkmarkBox}>
          <b className={styles.checkmarkSuccess}>✓</b>
        </div>
        <Typography className={clsx(styles.titleLinkSend, styles.title)}>{l10n.components.recoveryPassword.TITLE_LINK_SEND}</Typography>
        <Typography className={styles.text}>
          {l10n.components.recoveryPassword.TEXT_LINK_SEND}
        </Typography>
        <div className={styles.button}>
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            onClick={handleClose && handleClose}
          >
            {l10n.components.recoveryPassword.BUTTON_RECOVERY_LINK}
          </ATButton>
        </div>
      </div>
    </div>
  );
};

RecoveryLinkSend.defaultProps = defaultProps;

export default RecoveryLinkSend;