import React from 'react';
import {
  Button, Grid, DialogTitle, DialogActions
} from '@material-ui/core';
import RatingService from '../../../../../../services/main/dictionaries/Rating.service';
import Loader from '../../../../../../components/loader/Loader';
import Toasters from '../../../../../../components/popUp/PopUp';
import useStyles from '../Rating.styles';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

export default function DeleteRating(props: {
  ratingId: number;
  handleClose?: EmptyVoidFunction;
}) {
  const { handleClose, ratingId } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const sendDeleteRequest = async () => {
    setIsLoading(true);
    if (ratingId) {
      const response = await RatingService.delete(ratingId);
      if (response) {
        Toasters.success(l10n.components.rating.RATING_DELETE_SUCCESS);
      }
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(true);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>{l10n.components.rating.CONFIRM_DELETE}</DialogTitle>
      <DialogActions className={classes.buttonsWrapper}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-testid="cancelBtn"
        >
          {l10n.components.rating.CANCEL_DELETE}
        </Button>
        <Button
          data-testid="deleteBtn"
          onClick={sendDeleteRequest}
          color="primary"
          variant="contained"
          autoFocus
        >
          {l10n.components.rating.DELETE_RATING_MODAL}
        </Button>
      </DialogActions>
    </>
  );
}

DeleteRating.defaultProps = defaultProps;