import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  editComponent: {
    display: 'block',
    margin: 'auto',
    maxWidth: 1360,
  },
  title: {
    marginBottom: 40,
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
  editWrapper: {
    flexWrap: 'nowrap',
  },
  link: {
    paddingLeft: 14,
  },
  linkButton: {
    alignSelf: 'baseline',
  },
  button: {
    margin: 10,
  },
  rightSideContainer: {
    order: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      order: 0,
      alignItems: 'flex-start',
      marginBottom: 10,
      width: '100%',
    },
  },
  label: {
    boxSizing: 'border-box',
    width: '40%',
    marginRight: 'auto',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  editContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0
    },
  },
  checkboxLabel: {
    boxSizing: 'border-box',
    marginRight: '35px',
    width: '40%',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 'auto',
    },
  },
}));

export default useStyles;