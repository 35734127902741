/* eslint-disable max-len */
const ru: any = {
  shared: {
    DELETE: 'Удалить',
    SAVE: 'Сохранить',
    CANCEL: 'Отменить',
    ADD: 'Добавить',
    ALL: 'Все',
    REQUIRED_NAME: 'Название не должно быть пустым',
    REQUIRED_DESCRIPTION: 'Описание не должно быть пустым',
    NAME: 'Имя',
    DESCRIPTION: 'Описание',
    REQUIRED_FIELD: 'Поле не должно быть пустым',
    READ_MORE: 'Читать далее',
    ALL_UPCOMING_TOURNAMENTS: 'Все предстоящие турниры',
    ALL_FINISHED_TOURNAMENTS: 'Все завершенные турниры',
    TOURNAMENT: 'Турнир',
    CONTINUE: 'далее',
  },
  components: {
    articles: {
      SUBCATEGORY: 'Подкатегория',
      SUBCATEGORY_OF_PUBLICATION: 'Подкатегория публикации',
      ADMIN_PUBLICATIONS: 'Публикации администратора',
      DELETE_QUESTION: 'Вы действительно хотите удалить выбранную статью?',
      DELETE_ARTICLE_SUCCESS: 'Статья успешно удалена',
      ADD_ARTICLE_SUCCESS: 'Новая статья успешно добавлена',
      EDIT_ARTICLE_SUCCESS: 'Статья успешно изменена',
      EDIT: 'Cтатья успешно изменена',
      ARTICLE_MODAL_DELETE: 'Удалить статью',
      ARTICLES: 'Cтатьи',
      ARTICLE: 'Cтатья',
      ANNOUNCMENTS: 'Анонсы',
      ANNOUNCMENT: 'Анонс',
      CREATE_NEW_ARTICLE_ADMIN: 'Создание новой публикации администратора',
      EDIT_ARTICLE_ADMIN: 'Редактирование публикации администратора',
      REPORT: 'Отчёт',
      REPORTS: 'Отчёты',
    },
    reports: {
      SWITCH_VISIBILITY_CONFIRMATION: 'Вы действительно хотите скрыть отчёт?',
      PUBLISH_VISIBILITY_CONFIRMATION: 'Вы действительно хотите опубликовать отчёт?',
      DISPLAY_REPORT_SUCCESS: 'Отчёт успешно опубликован',
      HIDE_REPORT_SUCCESS: 'Отчёт успешно скрыт',
      REPORTS: 'Отчёты',
    },
    sponsors: {
      GENERAL_SPONSOR: 'Генеральный партнер',
      DISPLAY_SPONSORS: 'Отображение партнеров на карточке турнира',
      SPONSORS_ADDED: 'Партнеры успешно добавлены',
      SPONSORS: 'Партнеры',
      SPONSORS_LOGO: 'Логотип',
      SPONSOR_NAME: 'Партнер',
      SPONSOR_URL: 'Сайт',
      INCORREСT_URL: 'Неверная ссылка на веб-сайт',
      SPONSOR_COMMENTS: 'Комментарии',
      SPONSOR_CONTROL: 'Управление',
      SPONSORS_LIST_IS_EMPTY: 'Партнеры отсутствуют',
      SPONSOR_MODAL_ADD: 'Добавить партнера',
      SPONSOR_MODAL_EDIT: 'Редактировать партнера',
      SPONSOR_MODAL_DELETE: 'Удалить партнера',
      ADD_SPONSOR_SUCCESS: 'Партнер успешно добавлен',
      EDIT_SPONSOR_SUCCESS: 'Партнер успешно изменён',
      DELETE_SPONSOR_SUCCESS: 'Партнер успешно удалён',
      IMAGE_ERROR: 'Загрузите изображение',
      SPONSOR_COMMENT: 'Комментарии',
      DELETE_QUESTION: 'Вы действительно хотите удалить выбранного партнера?',
      MAIN_SPONSOR: 'Генеральный партнер',
      TOURNAMENT_SPONSOR: 'Партнер турнира',
      CANCEL_ALL: 'Отменить всех',
    },
    error: {
      CHANGE_TOURNAMENT_STATUS: `Изменение статуса завершенного турнира невозможно. 
      Для обнуления результатов турнира воспользуйтесь отменой жеребьевки на вкладке "Жеребьевка".`,
      DATE_ERROR: 'Необходимо выбрать актуальную дату',
      EMPTY_FIELD_ERROR: 'Поле не должно быть пустым',
      ENTER_PARTNER_EMAIL: 'Введите E-mail партнера',
      EXISTING_LOGIN_DATA_ERROR: 'Пользователь с такими данными уже зарегистрирован',
      EDITING_LOGIN_DATA_ERROR: 'Ошибка при изменении данных пользователя',
      FORMAT_ERROR: 'Необходимо выбрать формат турнира',
      IS_NUMBER_POSITIVE: 'Укажите число больше нуля',
      INTEGER_ERROR: 'Необходимо указать целое число',
      LOCACTION_ERROR: 'Необходимо выбрать локацию',
      LOGIN_PASSWORD_ERROR: 'Неверное имя пользователя или пароль',
      LINK_ERROR: 'Ссылка должна быть вида: http(s)://example.com',
      MAX_PARTICIPANTS_ERROR: 'Укажите количество участников',
      MIN_PARTICIPANT_ERROR: 'Должен быть минимум 1 участник',
      NAME_ERROR: 'Наименование не должно быть пустым',
      NON_EXISTING_LOGIN_DATA_ERROR: 'Пользователь с такими данными не существует',
      PARTICIPANTS_LIMIT_ERROR: 'Укажите число',
      PARTNERS_EMAIL_ERROR: 'Введите E-mail партнера',
      REACHED_MAX_PARTICIPANTS_ERROR: 'Достигнуто максимальное количество участников в основном списке',
      RANKING_ERROR: 'Необходимо выбрать тип зачета',
      SEASON_ERROR: 'Необходимо выбрать сезон',
      TEMPLATE_ERROR: 'Необходимо выбрать шаблон',
      TEAM_NAME_ERROR: 'Введите название команды',
      TOKEN_ERROR: 'Токен подтверждения email\'a истек',
      RESETPASSWORD_ERROR: 'Не удалось сбросить пароль',
      UNKNOWN_ERROR: 'Неизвестная ошибка, пожалуйста, попробуйте ещё раз',
      USER_EMAIL_ERROR: 'Email пользователя не подтверждён',
      USER_IS_BLOCKED: 'Пользователь заблокирован',
      USER_WASNT_FOUND: 'Пользователь не найден',
      UNEXPECTED_ERROR: 'Произошла непредвиденная ошибка, пожалуйста, попробуйте ещё раз',
      SESSION_IS_EXPIRED: 'Ваша сессия истекла, пожалуйста, перелогиньтесь',
      WRONG_PARTNER_DATA: 'Пользователь не найден',
      FIELD_LENGTH_ERROR: 'Поле может содержать от 2 до 256 символов кириллического и латинского алфавита, цифр, специальных символов',
      IS_ALREADY_REGISTERED: 'Данный участник уже зарегистрирован на этот турнир.',
      IMG_COUNT_ERROR: 'Изображений должно быть менее 8',
      AT_LEAST_ONE_FIELS: 'Должно быть заполнено хотя бы одно поле',
      INVALID_DATE: 'Некорректная дата',
      DATE_END_REGISTARTION_IS_LATER: 'Дата окончания регистрации не может быть позже даты проведения турнира',
    },
    placeholder: {
      ALL_COUNTRIES: 'Все страны',
      ALL_SEASONS: 'Все сезоны',
      ALL_RATINGS: 'Все рейтинги',
      ALL_FORMATS: 'Все форматы',
      ALL_RANKINGS: 'Все зачеты',
      ALL_STATUSES: 'Все статусы',
    },
    label: {
      EMAIL: 'Адрес электронной почты',
      PASSWORD: 'Пароль',
      PARTNER: 'Партнер',
      REASON_OF_BLOCKING: 'Причина блокировки',
      REMEMBER_ME: 'Запомнить меня',
      TEAM_NAME: 'Название команды',
    },
    tableColumns: {
      EMPTY_RATINGS: 'Данные о рейтингах отсутствуют',
      ADMINISTRATOR: 'Администратор',
      CATCHES: 'Поимки',
      CREATION_DATE: 'Дата создания',
      CARRY_OUT_DATE: 'Дата проведения',
      CREATOR: 'Организатор',
      CREATOR_NAME: 'Создатель',
      CONTROL: 'Управление',
      COUNTRY: 'Страна',
      DATE: 'Дата',
      AUTHOR: 'Автор',
      DESCRIPTION: 'Описание',
      EMAIL: 'E-mail',
      ACTIVATION: 'Активация',
      FORMAT: 'Формат',
      FULLNAME: 'Имя',
      TITLE: 'Название',
      LOCATION: 'Название локации',
      LOCATION_PLACE: 'Место проведения',
      SHOW_OR_HIDE: 'Скрыт / отображается',
      MEETINGS: 'Встречи',
      NAME: 'Наименование',
      NUMBER: 'Номер',
      PARTICIPATION: 'Участие',
      PARTICIPANT: 'Участник',
      ADD_PARTICIPANT: 'Добавить участника',
      PARTICIPANTS: 'Участники',
      PERSONAL_MEETINGS: 'Личные встречи',
      PARTNER_NAME: 'Второй игрок',
      PLACE: 'Место',
      PLACES: 'Места',
      PLACES_SUM: 'Сумма мест',
      POINT: 'Балл',
      POINTS: 'Баллы',
      POINTS_SUM: 'Сумма баллов',
      ROUND: 'Тур',
      ROUND_NAME: 'Раунд',
      RATING: 'Рейтинг',
      RANKING: 'Зачет',
      REGISTRATION_DATE: 'Дата регистрации',
      RESULTS: 'Результаты, %',
      STATUS: 'Статус турнира',
      STATUS_TEXT: 'Статус',
      SPORTSMAN: 'Спортсмен',
      SCORE: 'Счёт',
      TOURNAMENT: 'Турнир',
      TOURNAMENTS: 'Турниры',
      TOURNAMENT_NAME: 'Название турнира',
      TOURNAMENT_PARTICIPATION: 'Участие в турнирах',
      WAS_ACTIVE_AT: 'Последняя активность',
      RATINGS: 'Рейтинги',
      SEASONS: 'Сезоны',
      TACKLES: 'Комплекты снастей'
    },
    headerLabel: {
      ALL_COUNTRIES: 'Все страны',
      ALL_RATINGS: 'Все рейтинги',
      ADD_TO_MAIN_LIST: 'Добавить в основной список',
      ALL_SEASONS: 'Все сезоны',
      ADD_TOURNAMENT: 'Создать новый турнир',
      COUNTRY: 'Страна рейтинга',
      CANCEL_REGISTRATION: 'Отменить регистрацию',
      CANCEL_DRAWING: 'Отменить жеребьевку',
      CHANGE_STATUS: 'Изменить статус',
      COMPUTE_QUALIFIERS: 'Рассчет результатов отборочного тура',
      DELETE: 'Удалить',
      DELETE_USER: 'Удалить пользователя',
      DRAW: 'Жеребьевать',
      DOWNLOAD_PDF_CARDS: 'Скачать карточки в PDF',
      RATING: 'Рейтинг',
      SEASON: 'Сезон',
      TOURNAMENT_HAS_BEEN_PUBLISHED: 'Турнир опубликован',
      REGISTEDER_PARTICIPANT: 'Зарегистрированные спортсмены на турнир',
      DRAWING: 'Присвоение номеров спортсменам на турнир',
      FILLING_PARTICIPANTS_CARDS: 'Заполнение карточек спортсменов на турнир'
    },
    toasterMessage: {
      CHANGES_HAVE_BEEN_SAVED: 'Изменения сохранены',
      COMPUTE_QUALIFIERS_ERROR: 'Результаты отборочных не могут быть рассчитаны, пожалуйста заполните все карточки участников',
      getBlockingMessage: (status: boolean) => `Пользователь успешно ${status ? 'разблокирован' : 'заблокирован'}`,
      DRAWING_WAS_HELD: 'Жеребьевка успешно проведена',
      USER_HAS_BEEN_ACTIVATED: 'Пользователь успешно активирован',
      PARTICIPANT_HAS_BEEN_ADDED: 'Участник успешно добавлен в основной список',
      PARTICIPANT_HAS_BEEN_ADDED_TO_WAITLIST: 'Участник успешно переведен в список ожидания',
      REGISTRATION_HAS_BEEN_CANCELED: 'Вы успешно отменили регистрацию',
      ROLE_HAS_BEEN_DELETED: 'Роль успешно изменена',
      STATUS_HAS_BEEN_CHANGED: 'Статус изменен',
      TOURNAMENT_HAS_BEEN_ADDED: 'Турнир успешно добавлен',
      TOURNAMENT_HAS_BEEN_DELETED: 'Турнир успешно удален',
      TOURNAMENT_HAS_BEEN_CHANGED: 'Турнир успешно изменен',
      TEAM_WAS_CREATED: 'Команда успешно создана',
      TEAM_WAS_EDITED: 'Команда успешно отредактирована',
      USER_HAS_BEEN_DELETED: 'Пользователь успешно удален',
      USER_HAS_BEEN_ADDED: 'Пользователь успешно добавлен на турнир',
      QUALIFIERS_HAVE_BEEN_FORMED: 'Результаты отборочных туров рассчитаны, сетка финальных поединков сформирована',
      WAITING_LIST_HAS_BEEN_LEFT: 'Вы успешно покинули лист ожидания',
      YOU_HAVE_BEEN_ADDED_TO_WAITING_LIST: 'Вы добавлены в лист ожидания',
      YOU_HAVE_BEEN_REGISTERED: 'Вы успешно зарегистрированы',
      TACKLES_WERE_ADDED: 'Данные успешно добавлены',
      TACKLES_WERE_EDITED: 'Данные успешно обновлены',
      TACKLES_WERE_DELETED: 'Данные успешно удалены',
    },
    confirmDialogsMessage: {
      ADD_USERS_TO_MAIN_LIST: 'Вы точно хотите добавить участника в основной список?',
      ADD_TO_WAITING_LIST: 'Вы действительно хотите добавиться в лист ожидания?',
      ACCEPT_INVITATION: 'Вы действительно хотите принять приглашение?',
      CANCEL_REGISTRATION: 'Вы точно хотите отменить регистацию?',
      CANCEL_DRAWING: 'Вы действительно хотите отменить жеребьевку? Турнирные карточки и результаты турнира будут удалены!',
      DOWNLOAD_PDF_CARDS: 'Вы точно хотите скачать карточки в PDF?',
      DELETE_USER: 'Вы действительно хотите удалить пользователя?',
      DELETE_TOURNAMENT: 'Вы точно хотите удалить турнир?',
      DECLINE_INVITATION: 'Вы действительно хотите отклонить приглашение?',
      HOLD_TOURNAMENT: 'Вы точно хотите провести жеребьёвку участников?',
      RECOMPUTE_QUALIFIERS: 'Повторный рассчет результатов приведет к обновлению результатов отборочного тура и сбросу результатов плэйофф. Продолжить?',
      LEAVE_WAITING_LIST: 'Вы действительно хотите покинуть лист ожидания?',
      REGISTER_ON_TOURNAMENT: 'Вы действительно хотите зарегистрироваться на выбранный турнир?',
      DELETE_TACKLE: 'Вы действительно хотите удалить комплект снастей?',
      HEADER_USERACTIVATION: 'Активировать пользователя',
      ACCEPT_USERACTIVATION: 'Вы уверены, что хотите активировать данного пользователя? \nЭто действие нельзя отменить',
      HEADER_INVALIDNUMBERS: 'Ошибка',
      HEADER_INVALIDNUMBERS_MESSAGE: 'Номера присвоены некорректно',
    },
    button: {
      ACTIVATED: 'Активирован',
      ACTIVATE: 'Активировать',
      ADD: 'Добавить',
      FIND_ANGLER: 'Найти участника',
      ADD_TO_MAIN_LIST: 'Добавить в основной список',
      ADD_NEW_PARTICIPANT: 'Добавить нового участника',
      ADD_TO_WAITING_LIST: 'Добавиться в лист ожидания',
      ALL_TOURNAMENTS: 'Все турниры',
      APPLY: 'Применить',
      ALL_NEWS: 'Все новости',
      CREATE_TEAM: 'Создать команду',
      CHANGE: 'Изменить',
      CHANGE_TOURNAMENT_STATUS: 'Изменить статус',
      CANCEL: 'Отмена',
      CANCEL_REGISTRATION: 'Отменить регистрацию',
      CONTINUE: 'Продолжить',
      CREATE_CARDS: 'Создать карточки (жеребьевать)',
      CONFIRM: 'Да',
      COMPUTE_QUALIFIERS: 'Рассчитать результаты отборочных',
      DOWNLOAD_PDF_CARDS: 'Скачать карточки в PDF',
      DELETE: 'Удалить',
      FIND_PARTNER: 'Найти партнера',
      FORGOT_PASSWORD: 'Забыли пароль?',
      LOAD_IMAGE: 'Загрузить изображение',
      LEAVE_WAITING_LIST: 'Покинуть лист ожидания',
      RATINGS_DESCRIPTION: 'Описание рейтинга',
      REGISTER: 'Зарегистрироваться',
      RESET: 'Сбросить',
      RESET_ALL: 'Сбросить всё',
      REFUSE: 'Нет',
      SIGN_IN: 'Войти',
      SIGN_UP: 'Зарегистрироваться',
      SCORE_COUNT_DESCRIPTION: 'Как происходит просчет баллов?',
      SAVE_CHANGES: 'Сохранить изменения',
      SAVE: 'Сохранить',
      SHOW_ERRORS: (errorCount: number) => `Показать ошибки: ${errorCount}`,
      SHOW_ALL: 'Показать все',
    },
    yearType: {
      YEARS: 'лет',
      YEAR: 'год',
      OF_THE_YEAR: 'года'
    },
    defaultSeasons: {
      AUTUMN_21_SPRING_22: 'Осень 2021 - Весна 2022'
    },
    defaultCountries: {
      BELARUS: 'Беларусь'
    },
    defaultRatings: {
      ATLAS: 'Atlas'
    },
    routes: {
      AREATROUT_COMPETITIONS: 'Соревнования по спортивной ловле форели',
      ADMINISTARTION: 'Администрирование',
      DEMO: 'Демонстрация',
      DICTIONARIES: 'Cправочники',
      LOCATIONS_LIST: 'Водоёмы',
      LOCATION_CARD: 'Карточка водоёма',
      LOCATION_EDITING: 'Редактирование локации',
      TOURNAMENT_ADMINISTARTION: 'Администрирование турниров',
      ARTICLES_CONTROL: 'Управление новостями',
      TOURNAMENT_EDITING: 'Редактирование турнира',
      TEAMS: 'Команды',
      TOURNAMENTS_LIST: 'Турниры',
      TOURNAMENT_CARD: 'Карточка турнира',
      USER_DATA: 'Данные пользователя',
      USER_MANAGEMENT: 'Управление пользователями',
      REPORT_CARD: 'Карточка отчёта',
      PUBLICATION_CARD: 'Карточка публикации',
      NEWS: 'Новости',
      TOURNAMENT_INFO: 'Информация о турнире',
    },
    addImage: {
      IMG_ALT_PREVIEW: 'Предпросмотр изображения',
      HELPER_DESCRIPTION_UPLOAD: 'Нажмите на это поле, чтобы загрузить картинку',
      HELPER_PHOTO_UPLOAD: 'Загрузите фотографию',
      HELPER_LOGO_UPLOAD: 'Загрузите логотип',
      BUTTON_TEXT_UPLOAD: 'Загрузить',
    },
    appbar: {
      ADMIN_PATH_NAME: 'Администрирование',
      APPBAR_TITLE: 'Спортивная ловля форели',
    },
    areatroutTable: {
      EXPORT_TITLE: 'Выгрузить',
      EXPORT_ARIA_LABEL: 'Выгрузить',
      SEARCH_TOOLTIP: 'Поиск',
      SEARCH_PLACEHOLDER: 'Поиск по названию, месту',
      SEARCH_PLACEHOLDER_NAME: 'Поиск по названию',
      EMPTY_DATA_SOURCE_MESSAGE: 'Нет данных',
      LABEL_ROWS_SELECT: 'строк',
      LABEL_DISPLAYED_ROWS: '{from}-{to} из {count}',
      LABEL_ROWS_PER_PAGE: 'Количество на странице:',
      FIRST_ARIA_LABEL: 'Первая страница',
      FIRST_TOOLTIP: 'Первая страница',
      PREVIOUS_ARIA_LABEL: 'Предыдущая страница',
      PREVIOUS_TOOLTIP: 'Предыдущая страница',
      NEXT_ARIA_LABEL: 'Следующая страница',
      NEXT_TOOLTIP: 'Следующая страница',
      LAST_ARIA_LABEL: 'Последняя страница',
      LAST_TOOLTIP: 'Последняя страница',
      MOCK_DATA: {
        ANGLER: 'Спортсмен',
        TOURNAMENTS: 'Турниров',
        CATCHES: 'Поимок',
        GOLD: 'Золото',
        SILVER: 'Серебро',
        BRONZE: 'Бронза',
        ATLAS: 'Atlas',
      }
    },
    authorization: {
      TITLE_EXPIRED: 'Ссылка на активацию больше не действительна',
      DESCRIPTION_EXPIRED: 'На указанный вами при регистрации e-mail мы выслали письмо с дальнейшими инструкциями по регистрации. Пожалуйста проверьте почту',
      BUTTON_DESCRIPTION_EXPIRED: 'Выслать повторно',
      TITLE_FAILED: 'Активация не пройдена',
      DESCRIPTION_FAILED: 'На указанный вами при регистрации e-mail мы выслали письмо с дальнейшими инструкциями по регистрации. Пожалуйста проверьте почту',
      BUTTON_DESCRIPTION_FAILED: 'Хорошо',
      TITLE_CHANGE_PASSWORD: 'Смена пароля',
      LABEL_NEW_PASSWORD: 'Новый пароль',
      CONFIRM_NEW_PASSWORD: 'Подтверждение пароля',
      BUTTON_APPLY_NEW_PASSWORD: 'Применить',
      TITLE_PASSWORD_CHANGED: 'Ваш пароль успешно изменен',
      DESCRIPTION_NEW_PASSWORD: 'Пожалуйста, авторизируйтесь с новыми  данными.',
      BUTTON_DESCRIPTION_NEW_PASSWORD: 'Хорошо',
      EMAIL_TITLE_CONFIRM: 'Подтверждение e-mail',
      EMAIL_TEXT_CONFIRM: 'На указанный вами при регистрации адрес электронной почты выслано письмо с дальнейшими инструкциями по регистрации',
      BUTTON_EMAIL_CONFIRM: 'Хорошо',
      TITLE_EMAIL_CONFIRMED: 'Ваш e-mail успешно подтверждён',
      BUTTON_EMAIL_CONFIRMED: 'Хорошо',
      TITLE_FAILED_RESULT: 'Ошибка',
      DESCRIPTION_FAILED_RESULT: 'Не удалось завершить процесс верификации электронной почты. Пожалуйста, попробуйте ещё раз или обратитесь к администратору системы.',
      VALIDATION_PASSWORD_REQUIRED: 'Введите ваш пароль',
      VALIDATION_PASSWORD_MIN: 'Минимум 8 символов',
      VALIDATION_PASSWORD_MATCH: 'Пароли должны совпадать',
    },
    editProfile: {
      TOASTER_SUCCESS: 'Изменения сохранены',
      MODAL_HEADER_EDIT: 'Редактирование профиля',
      LABEL_NAME: 'Имя',
      LABEL_LASTNAME: 'Фамилия',
      LABEL_COUNTRY: 'Страна',
      PLACEHOLDER_COUNTRY: 'Выберите страну',
      LABEL_CITY: 'Город',
      BIRTHDAY: 'День рождения',
      BIRTHDAY_ERROR: 'Необходимо выбрать актуальную дату',
      SAVE_CHANGES: 'Сохранить изменения',
      VALIDATION_REQUIRED: 'Поле не должно быть пустым',
      VALIDATION_INVALID: 'Неверное значение',
    },
    recoveryPassword: {
      TITLE_LINK_SEND: 'Ссылка на восстановление пароля отправлена',
      TEXT_LINK_SEND: 'Мы отправили на ваш e-mail ссылку на восстановление пароля',
      BUTTON_RECOVERY_LINK: 'Хорошо',
      TITLE_RECOVERY: 'Восстановление пароля',
      TEXT_TYPE_EMAIL: 'Введите e-mail, который вы использовали при регистрации. Мы вышлем на него пароль',
      RECOVERY_PASSWORD: 'Восстановить пароль',
      WITHOUT_ACCOUNT: 'Нет аккаунта? ',
      REGISTRATION: 'Зарегистрируйтесь',
      BUTTON_SIGN_IN: 'Войти',
      REQUIRED_EMAIL: 'Введите ваш e-mail',
      INVALID_EMAIL: 'Неверный e-mail'
    },
    signInForm: {
      SIGN_IN: 'Вход',
      LABEL_PASSWORD: 'Пароль',
      BUTTON_FORGOT_PASSWORD: 'Забыли пароль?',
      BUTTON_SIGN_IN: 'Войти',
      WITHOUT_ACCOUNT: 'Нет аккаунта? ',
      REGISTRATION: 'Зарегистрируйтесь',
      REQUIRED_EMAIL: 'Введите ваш e-mail',
      INVALID_EMAIL: 'Неверный e-mail',
      PASSWORD_REQUIRED: 'Введите ваш пароль',
    },
    signUpForm: {
      REGISTRATION: 'Регистрация',
      LABEL_NAME: 'Имя',
      LABEL_LASTNAME: 'Фамилия',
      LABEL_COUNTRY: 'Страна',
      PLACEHOLDER_COUNTRY: 'Выберите страну',
      OPTION_COUNTRY: 'Выберите страну',
      LABEL_CITY: 'Город',
      LABEL_PASSWORD: 'Пароль',
      LABEL_REPEAT_PASSWORD: 'Повторите пароль',
      BUTTON_REGISTER: 'Зарегистрироваться',
      ALREADY_REGISTER: 'Уже есть аккаунт? ',
      BUTTON_SIGN_UP: 'Войти',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
      REQUIRED_EMAIL: 'Введите ваш e-mail',
      INVALID_EMAIL: 'Неверный e-mail',
      VALIDATION_PASSWORD_REQUIRED: 'Введите ваш пароль',
      VALIDATION_PASSWORD_MIN: 'Минимум 8 символов',
      VALIDATION_PASSWORD_MATCH: 'Пароли должны совпадать',
    },
    collapsibleSection: {
      FILTERS: 'Фильтры',
    },
    countries: {
      COUNTRY_NAME: 'Название страны',
      CONTROL_NAME: 'Управление',
      ADD_COUNTRY_HEADER: 'Добавить страну',
      DELETE_HEADER: 'Удалить',
      EDIT_HEADER: 'Редактировать',
      SAVE_BUTTON: 'Сохранить',
      ADD_BUTTON: 'Добавить',
      EMPTY_COUNTRY_LIST: 'Страны еще не добавлены',
      ADD_COUNTRY_SUCCESS: 'Страна успешно добавлена',
      EDIT_COUNTRY_SUCCESS: 'Страна успешно изменена',
      DELETE_COUNTRY_SUCCESS: 'Страна успешно удалена',
      DELETE_QUESTION: 'Вы действительно хотите удалить выбранную страну?',
      BUTTON_CANCEL: 'Oтмена',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
    },
    footer: {
      LINK_TO_RULES: 'Правила проведения турниров',
      LINK_CONTACTS: 'Контактная информация',
      LINK_USER_AGREEMENT: 'Пользовательское соглашение',
      LINK_ORGANIZERS: 'Организаторам',
      TITLE_RIGHTS: 'Все права защищены',
      VERSION: 'Версия',
      TITLE_LANGUAGE: 'Веб-сайт также доступен на других языках:',
    },
    heatCaptureUtils: {
      EMPTY_SELECT: 'Не выбран',
    },
    heatCaptureTable: {
      TOUR: 'Тур',
      TOTAL: 'Итого',
      SECTOR: 'Сектор',
      CHOOSE_ANGLER: 'Выберите спортсмена',
      CAPTURE_MAP: 'Карта поимок',
    },
    imageThumbnail: {
      PREVIEW_IMG: 'Предпросмотр изображения',
    },
    editableTextField: {
      PLACEHOLDER: 'Введите текст',
    },
    extendedSelect: {
      PLACEHOLDER: 'Поиск участника',
    },
    locations: {
      LOCATION_NAME: 'Название локации',
      LOCATION_DESCRIPTION: 'Описание',
      LOCATION_COUNTRY: 'Страна',
      LOCATION_COORDINATES: 'Координаты',
      LOCATION_ADD_MODAL: 'Добавить локацию',
      LOCATION_ADD_BUTTON: 'Добавить',
      LOCATION_DELETE_MODAL: 'Удалить',
      REQUIRED_NAME: 'Название не должно быть пустым',
      REQUIRED_DESCRIPTION: 'Описание не должно быть пустым',
      REQUIRED_COUNTRY: 'Необходимо выбрать страну',
    },
    editLocation: {
      LOCATION_EDIT_SUCCESS: 'Изменения сохранены',
      LOCATION_EDIT_TITLE: 'Редактирование локации',
      LOCATION_EDIT_NAME: 'Название',
      LOCATION_EDIT_COUNTRY: 'Страна',
      PLACEHOLDER_DESCRIPTION: 'Добавьте описание',
      LONGITUDE: 'Долгота',
      LATTITUDE: 'Широта',
      TOURNAMENT_PICTURES: 'Фотографии турнира',
      LOCATION_EDIT_SAVE: 'Сохранить',
      LOCATION_EDIT_CANCEL: 'Отменить',
      PHONE_NUMBER: 'Номер телефона',
      PHONE_NUMBER_VALIDATION: 'Введите номер в международном формате: +<код страны><код города или сети><номер телефона>',
      LINK_TO_WEBSITE: 'Ссылка на сайт',
      LINK_TO_FACEBOOK: 'Ссылка на страницу FACEBOOK',
      LINK_TO_INSTAGRAM: 'Ссылка на страницу INSTAGRAM',
      UPLOAD_LOGO: 'Загрузите логотип',
    },
    addLocation: {
      LOCATION_ADD_SUCCESS: 'Новая локация успешно добавлена',
      LOCATION_ADD_NAME: 'Название',
      LOCATION_CHOOSE_COUNTRY: 'Выбрать страну',
      LOCATION_REQUIRED: 'Необходимо выбрать страну',
      PLACEHOLDER_DESCRIPTION: 'Добавьте описание',
      LONGITUDE: 'Долгота',
      LATTITUDE: 'Широта',
      LOCATION_ADD_SAVE: 'Сохранить',
    },
    deleteLocation: {
      LOCATION_DELETE_SUCCESS: 'Локация успешно удалена',
      CONFIRM_DELETE: 'Вы действительно хотите удалить данную локацию?',
      CANCEL_DELETE: 'Отменить',
      DELETE: 'Удалить',
    },
    countryCell: {
      NOT_AVAILABLE: 'Не доступно',
    },
    locationTable: {
      NAME_LOCATION: 'Название локации',
      LOCATION_DESCRIPTION: 'Описание',
      LOCATION_COUNTRY: 'Страна',
      LOCATION_COORDINATES: 'Координаты',
      CONTROL_LOCATION: 'Управление',
      LOCATIONS_LIST_EMPTY: 'Локации отсутствуют',
    },
    locationCard: {
      MAIN_PAGE: 'Главная',
      LOCATIONS_PAGE: 'Водоёмы',
      LOCATION: 'Водоём',
      HOW_TO_GET_TO: 'Как проехать?',
      LOCATION_DESCRIPTION: 'Описание локации',
      GALLERY: 'Галерея',
      UPCOMING_TOURNAMENTS: 'Предстоящие турниры',
      FINISHED_TOURNAMENTS: 'Завершенные турниры',
      LOCATION_MAP: 'Местоположение',
    },
    locationTournamentsTable: {
      DATE: 'Дата',
      TOURNAMENT_NAME: 'Название турнира',
      STATUS: 'Статус',
    },
    memberList: {
      PARTICIPANTS_LIST: 'Список участников',
      WAITING_LIST: 'В листе ожидания',
    },
    memberListTable: {
      FULLNAME: 'Участник',
      DATE: 'Дата',
      EMPTY_LIST: 'Список пуст',
    },
    menu: {
      MENU_DESCRIPTION: 'Соревнования по спортивной ловле форели',
    },
    metaDescription: {
      META_DESCRIPTION: 'Соревнования по спортивной ловле форели. Регистрация на турниры, просмотр результатов, статистика игроков, фото и отчеты с турниров',
    },
    modal: {
      SAVE: 'Сохранить',
      CLOSE: 'Закрыть'
    },
    participantCardTemplate: {
      PHOTO_ALT: 'Фото Участника',
      DATA_IS_EMPTY: 'Данные отсутствуют',
    },
    participantsCount: {
      MANY_PARTICIPANTS: 'участников',
      ONE_PARTICIPANT: 'участник',
      PARENTAL_CASE_PARTICIPANT: 'участника',
    },
    participantsList: {
      ANGLER: 'Спортсмен',
    },
    photoGallery: {
      PHOTO: 'фото',
      EMPTY_PHOTO_LIST: 'Фотографий нет',
    },
    playoffSchedule: {
      STATUS_CHANGED: 'Статус изменён',
      PLAYOFF_RESULTS: 'Результат плэйофф',
      TOURNAMENT_FINISHED: 'Турнир завершён',
      FINISH_TOURNAMENT: 'Завершить турнир',
      RESULTS_ERROR: 'Результаты плэйофф не внесены',
      FIRST_PLACE: 'За 1 место',
      THIRD_PLACE: 'За 3 место',
      FINAL: 'Финал',
      QUATERFINAL: '1/4 финала',
      SEMIFINAL: 'Полуфинал',
    },
    rangkings: {
      NAME_RANKING: 'Наименование',
      DESCRIPTION_RANKING: 'Описание',
      CONTROL_RANKING: 'Управление',
      ADD_RANKING_BUTTON: 'Добавить',
      ADD_RANKING_MODAL: 'Добавить зачет',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
    },
    addRanking: {
      ADD_RANKING_SUCCESS: 'Зачет успешно добавлен',
      ADD_NAME: 'Название',
      TEAM_RANKING: 'Командный зачет',
      ADD_DESCRIPTION: 'Описание',
      ADD_SAVE: 'Сохранить',
    },
    deleteRanking: {
      DELETE_MODAL: 'Удалить',
      DELETE_RANKING_SUCCESS: 'Зачёт успешно удален',
      CONFIRM_DELETE: 'Вы точно хотите удалить выбранный зачет?',
      CANCEL_DELETE: 'Oтмена',
      DELETE: 'Удалить',
    },
    editRanking: {
      EDIT_RANKING: 'Изменить зачет',
      EDIT_RANKING_SUCCESS: 'Зачёт успешно изменен',
      EDIT_NAME: 'Название',
      TEAM_RANKING: 'Командный зачет',
      EDIT_DESCRIPTION: 'Описание',
      EDIT_SAVE: 'Сохранить',
    },
    rating: {
      RATING_NAME: 'Наименование рейтинга',
      RATING_DESCRIPTION: 'Описание',
      RATING_CONTROL: 'Управление',
      ADD_RATING_MODAL: 'Добавить рейтинг',
      DELETE_RATING_MODAL: 'Удалить',
      EDIT_RATING_MODAL: 'Редактировать',
      ADD_RATING_BUTTON: 'Добавить',
      RATING_LIST_IS_EMPTY: 'Рейтинг еще не добавлен',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
      TYPE_NUMBER: 'Введите число',
      NUMBER_MORE_THAN_ZERO: 'Укажите число больше нуля',
      REQUIRED_COUNTRY: 'Пожалуйста выберите страну из списка',
      TYPE_INTEGER_NUMBER: 'Укажите целое число',
      RATING_ADD_SUCCESS: 'Рейтинг успешно добавлен',
      RATING_EDIT_SUCCESS: 'Рейтинг успешно изменен',
      PLACEHOLDER_NAME: 'Введите название',
      LABEL_RATING: 'Рейтинг',
      LABEL_COUNTRY_RATING: 'Страна рейтинга',
      PLACEHOLDER_COUNTRY: 'Выберите страну',
      LABEL_NATIONAL_RATING: 'Национальный рейтинг',
      LABEL_MAX_PARTICIPANTS: 'Максимальное количество турниров в расчете',
      SAVE_BUTTON: 'Сохранить',
      RATING_DELETE_SUCCESS: 'Рейтинг успешно удален',
      CANCEL_DELETE: 'Oтмена',
      CONFIRM_DELETE: 'Вы действительно хотите удалить выбранный рейтинг?',
    },
    tackles: {
      ADD_TACKLES: 'Добавить комплект снастей',
      EDIT_TACKLES: 'Редактировать комплект снастей',
      SPINNING: 'Спиннинг',
      REEL: 'Катушка',
      WIRE: 'Леска/шнур',
      TACKLE: 'Комплект'
    },
    tournamentReports: {
      REPORTS: 'Отчёты',
      WRITE_REPORT: 'Написать отчёт',
      NAME_OF_REPORT: 'Название отчета',
      TEXT_OF_REVIEW: 'Текст отчета',
      INTRO_OF_REPORT: 'Вводная часть текста отчета',
      SUMMARY: 'Резюмирующая часть текста отчета',
      ADD_REPORT_SUCCESS: 'Отчёт успешно добавлен',
      EDIT_REPORT_SUCCESS: 'Отчёт успешно изменен',
      DELETE_REPORT_SUCCESS: 'Отчёт успешно удалён',
      EDIT_REPORT: 'Редактировать отчёт',
      CHOOSE_TOURNAMENT: 'Выберите турнир',
      REQUIRED_TOURNAMENT: 'Необходимо выбрать турнир',
      PHOTO: 'Фото',
      DELETE_REPORT: 'Удалить отчёт',
      DELETE_REPORT_QUESTION: 'Вы действительно хотите удалить выбранный отчёт?',
    },
    registrationButton: {
      SIGN_IN_FOR_JOINING: 'Авторизируйтесь для участия в турнире',
      REGISTER_IN_TOURNAMENT: 'Зарегистрироваться',
      REGISTER_IN_TOURNAMENT_CONFIRM: 'Вы действительно хотите зарегистрироваться на выбранный турнир?',
      WAITING_LIST: 'В лист ожидания',
      WAITING_LIST_CONFIRM: 'Вы действительно хотите добавиться в лист ожидания?',
      LEAVE_WATING_LIST: 'Покинуть лист ожидания',
      LEAVE_WATING_LIST_CONFIRM: 'Вы действительно хотите покинуть лист ожидания?',
      ABORT_REGISTRATION: 'Отменить регистрацию',
      ABORT_REGISTRATION_CONFIRM: 'Вы действительно хотите отменить регистрацию?',
      REGISTER_TEAM: 'Зарегистрировать команду',
      REGISTER_TEAM_CONFIRM: 'Вы действительно хотите зарегистрировать команду на выбранный турнир?',
      WAITING_LIST_TEAM: 'Добавить команду в лист ожидания',
      WAITING_LIST_CONFIRM_TEAM: 'Вы действительно хотите добавить команду в лист ожидания?',
      LEAVE_WATING_LIST_TEAM: 'Покинуть лист ожидания',
      LEAVE_WATING_LIST_CONFIRM_TEAM: 'Вы действительно хотите покинуть лист ожидания?',
      ABORT_REGISTRATION_TEAM: 'Отменить регистрацию команды',
      ABORT_REGISTRATION_CONFIRM_TEAM: 'Вы действительно хотите отменить регистрацию команды на турнир?',
    },
    registrationModal: {
      ADDED_TO_THE_WAITING_LIST: 'Вы добавлены в лист ожидания',
      REGISTERED_SUCCESSFULLY: 'Вы успешно зарегистрированы',
      LEAVE_WAITING_LIST: 'Вы успешно покинули лист ожидания',
      CANCELLED_REGISTRATION: 'Вы успешно отменили регистрацию',
      CONTINUE: 'Продолжить',
    },
    teamRegistrationModal: {
      CHOOSE_TEAM: 'Выберите команду',
      LABEL_TEAM: 'Команда',
      EMPTY_TEAM_LIST: 'Нет доступных команд',
      SAVE_BUTTON: 'Готово',
    },
    seasons: {
      ADD_SEASON_BUTTON: 'Добавить',
      ADD_SEASON_MODAL: 'Добавить сезон',
      DELETE_SEASON_MODAL: 'Удалить сезон',
      EDIT_SEASON_MODAL: 'Редактировать сезон',
      ADD_SEASON_SUCCESS: 'Сезон успешно добавлен',
      LABEL_NAME: 'Название',
      LABEL_DESCRIPTION: 'Описание',
      CONTROL: 'Управление',
      SEASONS_LIST_IS_EMPTY: 'Сезоны отсутствуют',
      SAVE_BUTTON: 'Сохранить',
      DELETE_SEASON_SUCCESS: 'Сезон успешно удален',
      DELETE_SEASON_CONFIRM: 'Вы уверены, что хотите удалить данный сезон?',
      CANCEL_DELETE: 'Oтмена',
      DELETE: 'Удалить',
      EDIT_SEASON_SUCCESS: 'Сезон успешно изменен',
      NAME_OF_SEASON: 'Название сезона',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
    },
    tableTemplates: {
      NO_DATA_AVAILABLE: 'Данные отсутствуют',
      TOUR: 'Тур',
      ZONE: 'Зона',
      CATCHES: 'Поимки',
      POINTS: 'Баллов',
      ENEMY: 'Соперник',
      YOUR_RESULT: 'Свои',
      NAME: 'Имя',
      PARTICIPANTS: 'Участники турнира',
      WIN: 'Побед',
      DRAWS: 'Ничьих',
      LOSSES: 'Поражений',
    },
    teamCard: {
      APPROVED: 'Спортсмен подтвердил свое участие в этой команде',
      PENDING: 'Спортсмен ещё не подтвердил свое участие в этой команде',
      DECLINED: 'Спортсмен отказался от участия в этой команде',
      DISBANDED: 'Команда расформирована',
      WITHOUT_TEAM: 'Вы не состоите ни в одной из команд',
      YOU: 'вы',
      DELETE_TEAM: 'Удалить команду',
      DELETE_TEAM_CONFIRM: 'Вы действительно хотите удалить команду?',
      CONFIRM_BUTTON: 'Да',
      CANCEL_BUTTON: 'Отмена',
      DELETE_TEAM_SUCCESS: 'Команда успешно удалена',
    },
    tournamentFormat: {
      ADD_FORMAT_BUTTON: 'Добавить',
      ADD_FORMAT_MODAL: 'Добавить формат турнира',
      EDIT_FORMAT_MODAL: 'Редактировать формат турнира',
      DELETE_FORMAT_MODAL: 'Удалить формат',
      REQUIRED_FORMAT: 'Введите название формата',
      DELETE_FORMAT_CONFIRM: 'Вы уверены, что хотите удалить данный формат?',
      DELETE: 'Удалить',
      FORMAT_NAME: 'Название формата',
      FORMAT_DESCRIPTION: 'Описание',
      FORMAT_ADD_SUCCESS: 'Формат успешно добавлен',
      FORMAT_EDIT_SUCCESS: 'Формат успешно изменен',
      FORMAT_DELETE_SUCCESS: 'Формат успешно удален',
      FORMAT_NAME_ADD: 'Наименование',
      FORMAT_RULES: 'Правила',
      SAVE_BUTTON: 'Сохранить',
      CANCEL_BUTTON: 'Отмена',
      CONTROL: 'Управление',
      FORMATS_ARE_EMPTY: 'Форматы турнира отсутствуют',
    },
    tournamentResult: {
      PLACE: 'Место',
      TEAM: 'Команда',
      ANGLER: 'Спортсмен',
      CATCHES: 'Поимки',
      POINTS: 'Балл',
      EMPTY_DATA: 'Данных нет',
    },
    finishedTournament: {
      FINISHED: 'Завершенный',
    },
    weather: {
      MORNING: 'Утро:',
      DAY: 'День:',
      EVENING: 'Вечер:',
      WIND_SPEED: 'м/с',
    },
    tournamentsTemplate: {
      TEMPLATE_BUTTON_ADD: 'Добавить',
      TEMPLATE_MODAL_ADD: 'Добавить шаблон',
      TEMPLATE_MODAL_EDIT: 'Изменить шаблон',
      TEMPLATE_MODAL_DELETE: 'Удалить шаблон',
      DIGITS_AFTER_POINT: 'Должно быть не более одного знака после запятой',
      REQUIRED_FIELD: 'Поле не должно быть пустым',
      TYPE_INTEGER_NUMBER: 'Укажите целое число',
      TYPE_NUMBER_MORE_THAN_ZERO: 'Укажите число больше нуля',
      REQUIRED_NUMBER: 'Введите число',
      ADD_TEMPLATE_SUCCESS: 'Шаблон успешно добавлен',
      TEMPLATE_NAME: 'Название',
      ROUNDS_COUNT: 'Количество раундов',
      TEMPLATE_DESCRIPTION: 'Описание',
      SAVE_BUTTON: 'Сохранить',
      TEMPLATE_DELETE_SUCCESS: 'Шаблон успешно удален',
      TEMPLATE_EDIT_SUCCESS: 'Шаблон успешно изменён',
      TEMPLATE_DELETE_CONFIRM: 'Вы действительно хотите удалить данный шаблон?',
      CANCEL_BUTTON: 'Отмена',
      DELETE: 'Удалить',
      TEMPLATE_NAME_COLUMN: 'Название шаблона',
      CONTROL: 'Управление',
      TEMPLATES_LIST_IS_EMPTY: 'Шаблоны отсутствуют',
    },
    userImage: {
      LABEL_CHOOSE_IMG: 'Выберите файл',
      SAVE_BUTTON: 'Сохранить',
      UPLOAD_PHOTO: 'Загрузить фото',
      SIZE_ERROR: 'Размер изображения превышает 20mb',
    },
    userProfile: {
      EDIT_PROFILE: 'Редактировать профиль',
      MY_PROFILE: 'Личный кабинет',
      TEAMS: 'Команды',
      SIGN_OUT: 'Выход',
      ADMIN: 'Администрирование',
      SIGN_IN: 'Войти',
      SIGN_UP: 'Зарегистрироваться',
      EMPTY_TEAMS_LIST: 'Команды отсутствуют',
    },
    configureTable: {
      RESULT_OF_TOUR: 'Результат тура',
      CONFIGURE_DESCRIPTION: 'Описание',
      POINTS: 'Начисляемый балл',
      POINT_LABEL: 'балл',
      WIN: 'Победа',
      WIN_DESCRIPTION: 'Kоличество своих поимок в туре > количества поимок оппонента',
      DRAW: 'Ничья',
      DRAW_WITH_RESULT: 'Результативная ничья',
      DRAW_RESULT_DESCRIPTION_FIRST: 'а. Количество своих поимок = количеству поимок оппонента',
      DRAW_RESULT_DESCRIPTION_SECOND: 'b. Количество поимок не равно 0',
      DRAW_NULL: 'Нулевая ничья',
      DRAW_NULL_DESCRIPTION_FIRST: 'a. Количество своих поимок = 0',
      DRAW_NULL_DESCRIPTION_SECOND: 'b. Количество поимок оппонента = 0',
      DEFEAT: 'Поражение',
      DEFEAT_RESULT: 'Результативное поражение < 2 раза',
      DEFEAT_RESULT_DESCRIPTION_FIRST: 'a. Количество своих поимок не равно нулю',
      DEFEAT_RESULT_DESCRIPTION_SECOND: 'b. Соотношение количества поимок оппонента / количества своих поимок < 2',
      DEFEAT_LESS_TWO: 'Результативное поражение ≥ 2',
      DEFEAT_LESS_TWO_DESCRIPTION_FIRST: 'a. Количество своих поимок не равно нулю',
      DEFEAT_LESS_TWO_DESCRIPTION_SECOND: 'b. Соотношение количества поимок оппонента / количества своих поимок ≥ 2',
      DEFEAT_NULL: 'Нулевое поражение',
      DEFEAT_NULL_DESCRIPTION_FIRST: 'a. Количество своих поимок в туре = 0',
      DEFEAT_NULL_DESCRIPTION_SECOND: 'b. Количество поимок оппонента > 0',
    }
  },
  pages: {
    ABOUT_US: 'О нас',
    ACCEPT_INVITATION: 'Принять приглашение',
    ANNOUNCEMENT: 'Анонс',
    AREA: 'Зона',
    END_REGISTRATION_DATE: 'Дата и время закрытия регистрации',
    AUTOMATICALLY_CLOSE_REGISTARTION: 'Автоматическое закрытие регистрации',
    AREATROUT: 'Спортивная ловля форели',
    SPONSORS: 'Партнеры',
    AREATROUT_INVITATION: 'Участвуйте в соревнованиях, делитесь впечатлениями, смотрите онлайн-трансляции',
    AREATROUT_LOGO: 'Логотип турнира по спортивной ловле форели',
    AREATROUT_IMAGE: 'Фотография с соревнований спортивной по ловле форели',
    ARTICLE: 'Статья',
    BAD_BEHAVIOR: 'Плохое поведение',
    BLOCK_USER: 'Заблокировать',
    CATCHES: 'Поимки',
    CANCEL_DRAWING: 'Отменить жеребьёвку',
    CLOSE_REGISTARTION: 'Закрыть регистрацию',
    CLOSEST_TOURNAMENTS: 'Ближайшие турниры',
    COUNTRY: 'Страна',
    COUNTRIES: 'Страны',
    CHOOSE_LANGUAGE: 'Выбрать язык',
    CREATE_TEAM: 'Создание команды',
    DATA: 'Данные',
    DATE: 'Дата проведения',
    DECLINE_INVITATION: 'Отклонить приглашение',
    DESCRIPTION: 'Описание',
    DEFINE_NUMBERS: 'Присвоить номера автоматически',
    DRAWING: 'Жеребьевка',
    DRAW_WITH_POINTS: 'Результативная ничья',
    EDIT_TEAM: 'Редактирование команды',
    ENTER_TEAM_NAME: 'Введите имя команды',
    FORMAT: 'Формат',
    FORMATS: 'Форматы',
    FILTERS: 'Фильтры',
    FINAL: 'Финал',
    FINISHED_TOURNAMENTS: 'Завершенные турниры',
    HEAT_MAP: 'Карта поимок',
    IMAGES: 'Фотографии турнира',
    INDIVIDUAL_TOURNAMENT: 'Личный',
    INCOMING_INVITATIONS: 'Входящие приглашения',
    INVITATION_FROM: 'Приглашение от',
    LOCATION: 'Водоём',
    LOCATIONS: 'Локации',
    LESS_2X_DEFEAT: ' Результативное поражение &#60; 2 раза ',
    LEFT: 'Л',
    MAIN_PAGE: 'Главная',
    MAIN_LIST: 'Основной список',
    MORE_2X_DEFEAT: ' Результативное поражение &#8805; 2  раза ',
    MY_POINTS: 'Свои',
    NUM: '№',
    NAME: 'Имя',
    NEWS_ARTICLES: 'Новости и статьи',
    NEWS: 'Новости',
    NOMINATION: 'Наименование',
    NO_CLOSEST_TOURNAMENTS: 'Нет запланированных турниров, попробуйте проверить позже',
    NO_DATA: 'Данных нет',
    NO_REGISTERED_USERS: 'Зарегистрированных участников пока нет',
    NO_AVAILABLE_TOURNAMENTS: 'Нет доступных турниров',
    NO_INVITATIONS: 'У вас пока нет входящих приглашений',
    OPEN_REGISTARTION: 'Открыть регистрацию',
    REGISTER_ANGLER_TO_TOURNAMENT: 'Зарегистрировать участника',
    ADD_PARTICIPANT: 'Добавление участника',
    OPPONENT: 'Соперник',
    PART_OF_FINAL: 'финала',
    PARTICIPANT_CARDS: 'Карточки участников',
    PARTICIPANTS_LIMIT: 'Максимальное количество участников',
    PARTICIPANTS_NUMBER: 'Количество участников',
    PIECE_OF_NEWS: 'Новость',
    PREVIEW: 'Предпросмотр изображения',
    PROJECT_MANAGEMENT: 'Управление проектом',
    POINTS: 'Баллов',
    PLAYOFF: 'Плейофф',
    PLAYOFF_RESULT: 'Результаты плейофф',
    PUBLICATION: 'Публикация',
    QUALIFIERS: 'Отборочные',
    QUALIFACATION_RESULTS: 'Результаты отборочных туров',
    QUARTER_FINAL: 'Четвертьфинал',
    RANKING: 'Зачет',
    RANKINGS: 'Зачеты',
    RATING: 'Рейтинг',
    RATINGS: 'Рейтинги',
    RIGHT: 'П',
    RULES: 'Правила',
    REGULATIONS: 'Регламент',
    REGULATIONS_LINK_WASNT_ADDED: 'Ссылка на регламент не добавлена',
    REGISTRATION: 'Регистрация',
    REVIEW: 'Отчет',
    RULES_LINK_WASNT_ADDED: 'Ссылка на правила не добавлена',
    ROUND: 'Тур',
    SELECT_COUNTRY: 'Выберите страну',
    SELECT_PARTNER_EMAIL: 'Введите E-mail партнера',
    SELECT_ANGLER_EMAIL: 'Введите E-mail участника',
    SEASON: 'Сезон',
    SEASONS: 'Сезоны',
    SEARCH_BY_NAME: 'Поиск по названию, месту',
    SEARCH_BY_ARTICLE_NAME: 'Поиск по названию публикации',
    SEARCH_BY_FULLNAME: 'Поиск по имени, фамилии',
    SECTOR: 'Cектор',
    SEMI_FINAL: 'Полуфинал',
    SPORTSMAN_RATINGS: 'Рейтинги',
    SIGN: 'Подпись',
    SIGN_INTO_ACCAUNT: 'Войти в аккаунт',
    STATUS: 'Статус',
    TEMPLATES: 'Шаблоны',
    TEAMS: 'Команды',
    TEAM_TOURNAMENT: 'Командный',
    THIRD_PLACE_FINAL: 'Финал за третье место',
    TOURNAMENT_STATUS: 'Статус турнира',
    TOURNAMENTS: 'Турниры',
    TOURNAMENT_IMAGES: 'Галерея',
    TOURNAMENT_RESULT: 'Итоговые результаты турнира',
    TOURNAMENT_DESCRIPTION: 'Описание турнира',
    TOURNAMENT_REGULATIONS: 'Регламент турнира',
    TEMPLATE: 'Шаблон',
    UNBLOCK_USER: 'Разблокировать',
    USERS_IMAGE: 'Изображение пользователя',
    WAITINGS_lIST: 'Список ожидания',
    WIN: 'Победа',
    ZERO_DRAW: 'Нулевая ничья',
    ZERO_DEFEAT: 'Нулевое поражение',
    LOADING_MESSAGE: 'Идет поиск...',
    NO_OPTIONS_MESSAGE: 'Не найдено',
    ALREADY_REGISTERED: 'Зарегистрирован',
    END_OF_REGISTRATIONE: 'До конца регистрации:',
    SCORE_COUNT_DESCRIPTION: `
    <h2 style='text-align: center'>Как проходит подсчёт баллов?</h2>
    <h3>Отборочные туры</h3>
    <p>
      В  отборочных турах соперники соревнуются в парах. В зачет идет каждая рыба пойманная и отпущенная по правилам.
      <br />
      По окончанию тура  спортсмены сравнивают свои поимки.  Выигрывает тот спортсмен, у которого поимок больше.
    </p>
    <p>
      Выйргавший спортсмен получает 3 балла.
      <br />
      Проигравший получает 0 баллов.
      <br />
      В случае нулевой (0:0) ничьи каждый спортсмен получает по 1 баллу.
      <br />
      В случае рыбной ничьи (1:1, 2:2 и т.п.) каждый спортсмен получает по 1.5 балла.
    </p>
    <p>
      По окончанию 8 туров все баллы спортсменасуммируются.
      8 сильнейших спортсменов, с наибольшим количеством баллов  проходят в финальную часть соревнований.
    </p>
    <h3>Плэйофф</h3>
    <p>
      В финальных раундах баллы не начисляются.  Участник выбывает из турнира после первого же проигрыша.
    </p>`
  }
};

export default ru;