import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    marginTop: 15,
    display: 'flex',
    gap: 20,
    borderBottom: '1px solid #e2e4f0',
    marginBottom: '21px',
    alignItems: 'center',
    flexWrap: 'nowrap',
    WebkitAlignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pictureWrapper: {
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      marginBottom: '0px'
    },
  },
  contentWrapper: {
    wordBreak: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  additionalInfo: {
    display: 'flex',
    gap: 30,
    alignItems: 'center',
  },
  control: {
    margin: 'auto',
    marginRight: 10,
  },
  previewImg: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 15,
      borderRadius: '2%',
    },
  },
  link: {
    color: colors.link,
  },
  flag: {
    width: 30,
    borderRadius: '50%',
  },
  locationWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  headerMobile: {
    marginLeft: 5,
  },
  tournamentInfo: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  readMore: {
    textAlign: 'right',
    float: 'right',
    marginRight: 30,
  },
  authorName: {
    marginLeft: '5px',
  },
}));

export default useStyles;