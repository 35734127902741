import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

export const useStyles = makeStyles(() => createStyles({
  root: {
    '& .MuiPaper-root': {
      '& > div > div > div': {
        overflowY: 'inherit !important'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: 16,
        border: '0.35rem solid #fff',
        '&:hover': {
          backgroundColor: '#a0a0a5',
          border: '0.3rem solid #f4f4f4',
        }
      },
    },
    '& .MuiPaper-elevation2': {
      boxShadow: 'none'
    },
    '& .MuiInput-root': {
      border: '1px solid #C1C1CA',
      '&:hover': { borderColor: '#858697' },
      '&:active': { borderColor: '#141429' },
      '&:focus': { borderColor: '#E2E4F0' }
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '135%',
      color: '#C1C1CA'
    },
    '& .MuiInputBase-input:focus ': {
      color: '#141429'
    },
    '& .MuiInputAdornment-positionStart svg': {
      marginLeft: 16,
      width: 24,
      height: 24,
    },
    '& .MuiInputAdornment-positionEnd svg': {
      display: 'none'
    },
    '& .MuiToolbar-regular': {
      paddingLeft: 0,
      width: '100%'
    },
    '& .MuiToolbar-regular .MuiFormControl-root': {
      maxWidth: 477,
      width: '100%'
    },
    '& .MuiTablePagination-toolbar .MuiButton-label': {
      borderRadius: 4,
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '22px',
      color: '#626770'
    },
    '& .MuiButton-root:hover, .MuiIconButton-root:hover': {
      background: 'transparent'
    },
    '& .MuiTablePagination-toolbar .MuiButton-root span, .MuiTablePagination-toolbar .MuiIconButton-root span': {
      background: 'transparent',
    },
    '& .MuiTablePagination-toolbar .MuiButton-contained.Mui-disabled .MuiButton-label': {
      color: '#FFFFFF',
    },
    '& .MuiTablePagination-toolbar .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#141429'
    },
    '& .MuiTableCell-root': {
      flexDirection: 'row',
      textAlign: 'center',
    },
    '& .MuiInput-underline:before': {
      content: 'none',
    },
    '& .MuiInput-underline:after': {
      content: 'none',
    },
    '& .MuiTableBody-root': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '135%',
      color: '#141429',
    },
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      backgroundColor: '#f1f2f8',
      '& th:first-child': {
        borderRadius: '8px 0 0 8px'
      },
      '& th:last-child': {
        borderRadius: '0 8px 8px 0'
      }
    },
    '& .MuiTablePagination-toolbar .MuiIconButton-root .MuiSvgIcon-root:hover': {
      color: '#474752'
    },
    '& .MuiTableCell-head': {
      backgroundColor: 'transparent',
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      color: '#141429',
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      padding: '8px 8px',
      textIndent: '16px',
    },
    '& .MuiTableSortLabel-root': {
      color: '#141429',
    },
    '& .MuiTableSortLabel-icon': {
      opacity: 1,
      color: '#858697',
      fontSize: 24,
    },
    '& .MuiSvgIcon-root': {
      color: '#858697',
      display: 'block',
    },
    '& .MuiTableSortLabel-root svg': { opacity: 0 },
    '& .MuiTableSortLabel-root:hover svg': {
      opacity: 1
    }
  },
  tollbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  search: {
    display: 'none'
  },
  stickyHeader: {
    '& .MuiTableCell-head': {
      position: 'sticky',
      top: 0,
      backgroundColor: '#f1f2f8',
      zIndex: 10
    }
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: `1px solid ${colors.primary}`,
    margin: '15px 15px 15px 0px',
    '& div:last-child': {
      borderBottom: 'none',
    }

  },
  mobileTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    boxSizing: 'border-box',
    padding: '0px 7px',
    borderBottom: `1px solid ${colors.primary}`,
    fontSize: 13,
    textAlign: 'right',
    minHeight: 35
  },
  columnName: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left',
    minWidth: 100,
  }
}));

export const search = {
  borderRadius: 8,
  height: 48,
  marginBottom: 32,
};