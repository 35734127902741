import Round from '../../../../../../../models/pages/tournament/Round';

const getTableRounds = (rounds: Round[], sectorSide: string) => (
  rounds.map((round) => [{
    text: `${round.roundNumber}`, style: 'subHeader', margin: [0, 0.5], alignment: 'center'
  },
  {
    text: `${round.sectorNumber} ${sectorSide}`, style: 'subHeader', margin: [0, 0.5], alignment: 'center'
  },
  {},
  {},
  {},
  {
    text: `${round.opponentNum}`, style: 'subHeader', margin: [0, 0.5], alignment: 'center'
  },
  {
    text: `${round.opponent.fullName}`, style: 'subHeader', margin: [0, 0.5], alignment: 'left'
  },
  {}])
);

export default getTableRounds;