import React from 'react';
import { Helmet } from 'react-helmet';
import { getTranslation } from '../localization/customHooks/Translation';

const l10n = getTranslation();

const defaultProps = {
  description: l10n.components.metaDescription.META_DESCRIPTION
};

function MetaDescription(props: {
  title: string;
  description?: string;
}) {
  const { title, description } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export default MetaDescription;

MetaDescription.defaultProps = defaultProps;