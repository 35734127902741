import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Button, TextField, Box, Typography, FormControl, Select, InputLabel, MenuItem
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPrettyLatitudeFromCoords, getPrettyLongitudeFromCoords } from '../../../../utils/Utils';
import Location from '../../../../models/components/dictionaries/location/Location';
import Map from '../../../../components/map/Map';
import schema from './helpers/ValidationSchema';
import useStyles from './EditLocation.style';
import LoaderFullScreen from '../../../../components/loader/fullScreen/FullScreen';
import Routes from '../../../../routing/Routes';
import ImageData from '../../../../models/components/image/ImageData';
import { useStores } from '../../../../stores/Main';
import Toasters from '../../../../components/popUp/PopUp';
import EditableTextField from '../../../../components/inputs/textField/Editable';
import useTranslation from '../../../../components/localization/customHooks/Translation';
import UploadImage from '../../../../components/uploadImages/UploadImage';
import UserPicture from '../../../../components/userPicture/UserPicture';
import SingleImageUpload from '../../../admin/tournaments/edit/tournamentData/poster/SingleImageUpload';

const EditLocation = observer(() => {
  const { LocationStore, CountriesStore } = useStores();
  const navigate = useNavigate();
  const classes = useStyles();
  const l10n = useTranslation();
  const editor = useRef(null);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<Location>({
    mode: 'onBlur',
    resolver: yupResolver(schema),

  });

  // TODO: delete after implementing ImagesState
  const [imageIds, setImageIds] = useState<number[]>([]);
  const [images, setImages] = useState<ImageData[] | [] | null>([]);
  const [logoLocation, setLogoLocation] = useState<ImageData | null>(null);

  const { id } = useParams<{ id: string }>();

  const setLocationDescription = (newComponent: string) => setValue('description', newComponent, { shouldDirty: true, shouldValidate: true });

  const getCurrentLocation = async () => {
    await LocationStore.getLocation(Number(id));
  };

  useEffect(() => () => { LocationStore.resetLocation(); }, []);

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (LocationStore.location?.images?.length) {
      setImages(LocationStore.location.images);
    }
    if (LocationStore.location?.logo) {
      setLogoLocation(LocationStore.location.logo);
    }
  }, [id, LocationStore.location]);

  useEffect(() => {
    if (images) setImageIds(images.map((photo) => photo.id));
  }, [images]);

  useEffect(() => {
    setValue('images', imageIds);
  }, [setValue, images, imageIds]);

  useEffect(() => {
    if (logoLocation) setValue('logoId', logoLocation.id);
  }, [logoLocation, imageIds]);

  const sendPutRequest = async (data: Location) => {
    const response: Location | null = await LocationStore.editLocation(data);
    if (response?.id) {
      Toasters.success(l10n.components.editLocation.LOCATION_EDIT_SUCCESS);
      LocationStore.refreshLocations();
      navigate(Routes.AdminDictionaries.path);
    }
  };

  if (LocationStore.isLoading && !LocationStore.location) {
    return (<LoaderFullScreen />);
  }

  return (
    LocationStore.location && (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(sendPutRequest)}>
        <Typography align="center" variant="h5">
          {l10n.components.editLocation.LOCATION_EDIT_TITLE}
        </Typography>
        <Box className={classes.contentWidth}>
          <Box m={1} p={1} className={classes.direction}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  id="filled-multiline-flexible"
                  label={l10n.components.editLocation.LOCATION_EDIT_NAME}
                  error={!!errors.name}
                  helperText={errors.name?.message?.toString() ?? ''}
                  rowsMax={4}
                  variant="outlined"
                  className={classes.contentInput}
                  autoFocus
                />
              )}
              name="name"
              control={control}
              defaultValue={LocationStore.location ? LocationStore.location.name : ''}
            />
            <FormControl variant="outlined">
              <InputLabel>{l10n.components.editLocation.LOCATION_EDIT_COUNTRY}</InputLabel>
              <Controller
                name="countryId"
                control={control}
                defaultValue={LocationStore.location?.country?.id}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    label={l10n.components.editLocation.LOCATION_EDIT_COUNTRY}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!errors.countryId}
                    className={classes.countrySelect}
                  >
                    {CountriesStore.countries?.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  id="filled-multiline-flexible"
                  label={l10n.components.editLocation.PHONE_NUMBER}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message?.toString() ?? ''}
                  rowsMax={1}
                  variant="outlined"
                  className={classes.contentInput}
                />
              )}
              name="phoneNumber"
              control={control}
              defaultValue={LocationStore.location ? LocationStore.location.phoneNumber : ''}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.contentInput}
                  label={l10n.components.editLocation.LINK_TO_WEBSITE}
                  name="facebookLink"
                  variant="outlined"
                  error={!!errors.websiteLink}
                  helperText={errors.websiteLink?.message?.toString() ?? ''}
                />
              )}
              name="websiteLink"
              control={control}
              defaultValue={LocationStore.location ? LocationStore.location.websiteLink : ''}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.contentInput}
                  label={l10n.components.editLocation.LINK_TO_FACEBOOK}
                  name="facebookLink"
                  variant="outlined"
                  error={!!errors.facebookLink}
                  helperText={errors.facebookLink?.message?.toString() ?? ''}
                />

              )}
              name="facebookLink"
              control={control}
              defaultValue={LocationStore.location ? LocationStore.location.facebookLink : ''}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.contentInput}
                  label={l10n.components.editLocation.LINK_TO_INSTAGRAM}
                  name="instagramLink"
                  variant="outlined"
                  error={!!errors.instagramLink}
                  helperText={errors.instagramLink?.message?.toString() ?? ''}
                />

              )}
              name="instagramLink"
              control={control}
              defaultValue={LocationStore.location ? LocationStore.location.instagramLink : ''}
            />
            <div className={classes.description}>
              <Controller
                render={({ field }) => (
                  <EditableTextField
                    {...field}
                    ref={editor}
                    errors={errors.description}
                    convertedContent={LocationStore.location?.description || ''}
                    onBlur={setLocationDescription}
                    label=""
                    placeholder={l10n.components.editLocation.PLACEHOLDER_DESCRIPTION}
                  />
                )}
                name="description"
                control={control}
                defaultValue={LocationStore.location ? LocationStore.location.description : ''}
              />
            </div>
          </Box>
          <Box m={1} p={1} className={classes.contentMap}>
            <Box className={classes.contentWidth}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register('longitude')}
                    label={l10n.components.editLocation.LONGITUDE}
                    variant="outlined"
                    className={classes.contentInput}
                    inputProps={{ readOnly: true }}
                    value={getPrettyLongitudeFromCoords(LocationStore.coordinates) || ''}
                    required
                  />
                )}
                name="longitude"
                control={control}
                defaultValue={LocationStore.location ? getPrettyLongitudeFromCoords(LocationStore.coordinates) : ''}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register('latitude')}
                    label={l10n.components.editLocation.LATTITUDE}
                    variant="outlined"
                    className={classes.contentInput}
                    inputProps={{ readOnly: true }}
                    value={getPrettyLatitudeFromCoords(LocationStore.coordinates) || ''}
                    required
                  />
                )}
                name="latitude"
                control={control}
                defaultValue={LocationStore.location ? getPrettyLatitudeFromCoords(LocationStore.coordinates) : ''}
              />
            </Box>
            <Map />
            <Box>
              <Box className={classes.logoContainer}>
                <SingleImageUpload
                  poster={logoLocation}
                  setPoster={setLogoLocation}
                  imageStyles={classes.logoPicture}
                  altButtonDescription={l10n.components.addImage.HELPER_LOGO_UPLOAD}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <UploadImage
            addImage={(img: ImageData) => { setImages((prev: ImageData[] | [] | null) => [...prev!, img]); }}
            altDescription={l10n.components.addImage.HELPER_PHOTO_UPLOAD}
          />
        </Box>
        <div>
          <div className={classes.imagesContainer}>
            {images?.map((img: ImageData) => (
              <div key={img.id}>
                <UserPicture pictureInfo={img} setImage={setImages} />
              </div>
            ))}
          </div>
        </div>
        <Box mb={1} p={1} display="flex" justifyContent="flex-end" gridGap={20} className={classes.buttonsContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {l10n.components.editLocation.LOCATION_EDIT_SAVE}
          </Button>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => navigate(Routes.AdminDictionaries.path)}
          >
            {l10n.components.editLocation.LOCATION_EDIT_CANCEL}
          </Button>
        </Box>
      </form>
    </div>
    )
  );
});

export default EditLocation;