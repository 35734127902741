import React, { Ref } from 'react';
import { Control, Controller } from 'react-hook-form';
import clsx from 'clsx';
import useStyles from '../../ReportControl.styles';
import EditableTextField from '../../../inputs/textField/Editable';
import useTranslation from '../../../localization/customHooks/Translation';
import Round from '../../../../models/pages/tournament/Round';

const RoundControl = React.forwardRef((props: {
    currentIdAngler: { id: string },
    autoSaveFunction: () => void,
    index: number,
    round: Partial<Round>,
    control: Control<any>,
    setValue: any,
    errors: any,
    }, roundsRef: Ref<HTMLDivElement[]>) => {
  const {
    round, control, setValue, errors, index, autoSaveFunction
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const limitChars = 3000;

  return (round && (
    <Controller
      name={round.roundDescription}
      defaultValue={round}
      control={control}
      render={({ field }) => (
        <>
          <div className={classes.resultWrapper}>
            <p className={classes.roundName}>
              {round.roundDescription}
            </p>
            <div className={clsx(classes.score, classes.nullBottomMargin)}>
              {`${l10n.components.tableColumns.SCORE}: ${round.anglerCatches!} - ${round.opponentCatches!}`}
            </div>
            <p className={clsx(classes.nullBottomMargin, classes.opponentName)}>
              {round.opponent!.fullName}
            </p>
          </div>
          <EditableTextField
            {...field}
            key={round.id}
            ref={(ref: HTMLHeadingElement) => {
              if (roundsRef !== null && typeof roundsRef !== 'function') roundsRef.current![index] = ref;
              return ref;
            }}
            maxLength={limitChars}
            errors={errors.description}
            convertedContent={round.description || ''}
            onBlur={(text: string) => {
              setValue(
              String(round.roundDescription)!,
              {
                description: text.trimEnd(),
                playOffGameId: round.stageDescription ? round.id : null,
                tournamentRoundId: !round.stageDescription ? round.id : null,
              }
              );
              autoSaveFunction();
            }}
            label=""
            placeholder={l10n.components.addLocation.PLACEHOLDER_DESCRIPTION}
          />
        </>
      )}
    />
  ));
});

export default RoundControl;