import React from 'react';
import clsx from 'clsx';
import useStyles from '../../TeamCards.styles';
import crown from '../../../../assets/icons/teamCards/crown.svg';
import PartnerFields from '../../../../models/pages/team/PartnerFields';
import MemberAvatar from '../../../member/avatar/Avatar';
import useTranslation from '../../../localization/customHooks/Translation';

function TeamParticipant(props: {
  userEmail: string
  teamParticipantData: PartnerFields
  className: string
}) {
  const classes = useStyles();
  const l10n = useTranslation();

  const {
    userEmail, teamParticipantData, className
  } = props;
  return (
    <div className={clsx(className, classes.textContainer)}>
      <MemberAvatar avatarStyle={classes.avatar} profilePicture={teamParticipantData.profilePicture} />
      <span className={classes.name}>{teamParticipantData.name}</span>
      {userEmail === teamParticipantData.email && (
      <span className={classes.smallMargin}>
        (
        {l10n.components.teamCard.YOU}
        )
      </span>
      )}
      {teamParticipantData.isCaptain && (<img alt="crown" src={crown} className={classes.largeMargin} />)}

    </div>
  );
}

export default TeamParticipant;