import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import AreatroutTable from '../../components/table';
import { columns, rows } from './mock/TableMock';
import Toasters from '../../components/popUp/PopUp';
import Tooltip from '../../components/tooltip/Tooltip';
import ATButton from '../../components/button/Button';
import useStyles from './Demo.styles';
import playWhite from '../../assets/icons/playWhite.svg';
import playBlack from '../../assets/icons/playBlack.svg';
import Footer from '../../components/footer/Footer';
import Tags from '../../components/tags/Tags';
import video from '../../assets/icons/video.svg';
import UserList from '../../components/userList/UserList';
import NewsComponent from '../home/components/newsComponent/NewsComponent';
import Menu from '../../components/menu/Menu';
import TournamentService from '../../services/main/Tournament.service';
import FinishedTournament from '../../models/pages/tournament/FinishedTournament';
import FinishedTournamentCard from '../../components/tournamentsCards/finishedTournamentCard/FinishedTournamentCard';
import ATCheckbox from '../../components/checkbox/Checkbox';
import ClosestTournamentCard from '../../components/tournamentsCards/closestTournamentCard/ClosestTournamentCard';
import CollapsibleSection from '../../components/collapsibleSection/CollapsibleSection';
import transformClosestTournamentData
  from '../../components/tournamentsCards/closestTournamentCard/ClosestTournamentCard.utils';
import ClosestTournamentCardData from '../../models/pages/tournament/ClosestTournamentCardData';

function Demo() {
  const classes = useStyles();
  const [latestFinishedTournaments, setLatestFinishedTournaments] = useState<FinishedTournament[]>([]);
  const [closestTournaments, setClosestTournaments] = useState<ClosestTournamentCardData[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [openSection, setOpenSection] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  interface Popover {
    JSX: JSX.Element,
    id: string
  }

  const opponents = [
    {
      JSX: (
        <div>
          <div>Соперник: Петров</div>
          <div>Тур: 5</div>
        </div>
      ),
      id: '1',
    },
    {
      JSX: (
        <div>
          <div>Соперник: Сидоров</div>
          <div>Тур: 7</div>
        </div>
      ),
      id: '2',
    }
  ];

  const getLatestFinishedTournaments = async () => {
    const res = await TournamentService.getLatestFinishedTournaments(1);

    if (res) {
      setLatestFinishedTournaments(res);
    }
  };

  const getClosestTournaments = async () => {
    const res = await TournamentService.getClosestTournaments();

    if (res) {
      setClosestTournaments(transformClosestTournamentData(res));
    }
  };

  useEffect(() => {
    getClosestTournaments();
    getLatestFinishedTournaments();
  }, []);

  return (
    <>
      <Menu />
      <h1>Components Demo Page</h1>
      <Tags variant="event-planned">Запланирован</Tags>
      <Tags variant="event-open">Открыта регистрация</Tags>
      <Tags variant="event-close">Регистрация закрыта</Tags>
      <Tags variant="event-canceled">Отменен</Tags>
      <Tags variant="event-now">Идет сейчас</Tags>
      <Tags variant="event-completed">Завершенный</Tags>
      <Tags variant="event-icon"><img src={video} alt="video" /></Tags>
      <Tags variant="news">Новость</Tags>
      <Tags variant="news-article">Статья</Tags>
      <Tags variant="news-announcement">Анонс</Tags>
      <Tags variant="news-violet">Новость</Tags>
      <Tags variant="news-review">Отзыв</Tags>
      <AreatroutTable columns={columns} rows={rows} />
      <Grid>
        {opponents.map((opponent: Popover) => <Tooltip key={opponent.id} container={<>Курочкин</>} content={opponent.JSX} index={opponent.id} />)}
        <Grid container direction="row" justify="space-between">
          <Button variant="contained" color="primary" onClick={() => Toasters.success('Ура')}>Success</Button>
          <Button variant="contained" color="primary" onClick={() => Toasters.error('ошибка')}>error</Button>
          <Button variant="contained" color="primary" onClick={() => Toasters.warning('внимание')}>warning</Button>
          <Button variant="contained" color="primary" onClick={() => Toasters.info('информация')}>info</Button>
        </Grid>
        <Grid container direction="column">
          <ATButton
            className={classes.button}
            variant="primary"
            fill="solid"
            size="md"
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="solid"
            size="md"
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="solid"
            size="md"
          >
            <img src={playWhite} alt="play-icon" className="icon" />
            <span>Зарегистрироваться</span>
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="solid"
            size="lg"
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            variant="primary"
            fill="solid"
            size="lg"
            className={classes.button}
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="primary"
            fill="outline"
            size="md"
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="outline"
            size="md"
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="outline"
            size="md"
          >
            <img src={playBlack} alt="play-icon" className="icon" />
            <span>Зарегистрироваться</span>
          </ATButton>
          <ATButton
            className={classes.button}
            variant="primary"
            fill="outline"
            size="lg"
            disabled
          >
            Зарегистрироваться
          </ATButton>
          <ATButton
            className={classes.button}
            variant="secondary"
            fill="outline"
            size="lg"
            disabled
          >
            Зарегистрироваться
          </ATButton>
        </Grid>
        <UserList users={closestTournaments[0]} />
        <FinishedTournamentCard tournamentData={latestFinishedTournaments[0]} />
        <ClosestTournamentCard
          className={classes.tournament}
          tournament={closestTournaments[0]}
          getTournaments={getClosestTournaments}
        />
        <ATCheckbox checked={isChecked} label="Чекбокс" onChange={handleChange} />
        <ATButton
          size="md"
          variant="primary"
          fill="outline"
          onClick={() => setOpenSection(((prevState) => !prevState))}
        >
          Фильтры
        </ATButton>
        <CollapsibleSection
          isSectionOpen={openSection}
          setIsSectionOpen={setOpenSection}
        >
          <div className={classes.section}>Фильтры</div>
        </CollapsibleSection>
        <Footer />
      </Grid>
      <NewsComponent />
    </>
  );
}

export default Demo;