import React from 'react';
import { FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../Teams.styles';
import PartnerFields from '../../../../models/pages/team/PartnerFields';
import closeIcon from '../../../../assets/icons/select/closeIcon.svg';
import { emptyPartner } from '../../helpers/Constants';
import ParticipantData from '../../../../components/participantData/ParticipantData';
import useTranslation from '../../../../components/localization/customHooks/Translation';

function PartnerData(props: {
  partnerData: PartnerFields
  setPartnerData: React.Dispatch<React.SetStateAction<PartnerFields>>
  disabled: boolean
}) {
  const { partnerData, setPartnerData, disabled } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const resetUserData = () => {
    setPartnerData(emptyPartner);
  };
  return (
    <>
      {
        partnerData && (
          <div className={clsx(classes.flexColumn, { [classes.disabledInput]: disabled })}>
            <FormHelperText className={clsx(classes.label, classes.smallText)}>{l10n.components.label.PARTNER}</FormHelperText>
            <div className={clsx(classes.flexRow, classes.partnerContainer)}>
              <ParticipantData
                url={partnerData?.profilePicture?.url}
                name={partnerData?.name}
              />
              {!disabled && (
                <button
                  className={classes.deleteItems}
                  onClick={resetUserData}
                  type="button"
                >
                  <img src={closeIcon} alt="closeIcon" />
                </button>
              )}
            </div>
          </div>
        )
      }
    </>

  );
}

export default PartnerData;