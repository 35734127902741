import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  tableCell: {
    '& .MuiTableCell-root': {
      padding: 7
    }
  },
  result: {
    borderRadius: 100,
    padding: '5px 10px',
    whiteSpace: 'nowrap',
    border: '1px solid #C1C1CA',
  },
  rating: {
    display: 'flex',
    gap: 12,
  },
  nameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
  },
}));

export default useStyles;