import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  button: {
    fontFamily: 'inherit',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    color: colors.link,
    textDecorationLine: 'underline',
    fontSize: 16,
    lineHeight: '24px',
    outline: 'none'
  },
  section: {
    boxShadow: 'none',
    borderRadius: 0
  }
}));

export default useStyles;