import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  title: {
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px 0px',
      boxSizing: 'border-box',
      margin: 0
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: '10px',
  },
  member: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  button: {
    minWidth: 300,
    minHeight: 50,
    [theme.breakpoints.down('sm')]: {
      minWidth: 192,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  contenPosition: {
    display: 'flex',
    marginBottom: 20,
    width: '100%',
    gap: '10px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      maxWidth: '300px',
    },
  },
  select: {
    width: '100%',
  },
}));

export default useStyles;

export const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 50,
  })
};