import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  editWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  description: {
    margin: '0 20px 10px 20px',
  },
  inputField: {
    boxSizing: 'border-box',
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  invalid: {
    color: 'red',
  },
  error: {
    maxWidth: 250,
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  checkboxLabel: {
    boxSizing: 'border-box',
    marginRight: '35px',
    width: '40%',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 'auto',
    },
  },
  button: {
    marginBottom: 20,
    marginLeft: 10
  },
  label: {
    boxSizing: 'border-box',
    width: '40%',
    marginRight: 'auto',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    alignItems: 'center',
    margin: '0 30px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 0,
      margin: '0 20px',
      '& > div > .MuiFormHelperText-contained': {
        margin: 0
      },
      '& > div': {
        width: '100%'
      }
    },
  },
  saveButton: {
    margin: '0 auto 15px auto',
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '65vh',
    minWidth: '65vh',
  },
  rating: {
    marginBottom: 4
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
      overflowY: 'hidden',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto',
      },
    }
  },
  picker: {
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    }
  }
}));

export default useStyles;