import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px 20px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    },
  },
  contentTitle: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 16,
  },
  contentButtons: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    },
  },
  cancelButton: {
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    },
  }
}));

export default useStyles;