import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    maxWidth: 1360,
    margin: '32px auto 0',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  search: {
    marginBottom: 20
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: 20,
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '30px',
      color: colors.secondary
    }
  },
  middleSizeText: {
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '30px'
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  addTeamModal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 660,
      width: '100%',
      maxHeight: 600,
      height: '100%',
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        maxWidth: '100%',
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('md')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      [theme.breakpoints.down('md')]: {
        maxHeight: '100%'
      },
    }
  },
  roundedButton: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: colors.primaryLight
    },
    '&:hover': {
      backgroundColor: colors.lightBlue
    },
  },
  link: {
    color: colors.link
  }
}));

export default useStyles;