import clsx from 'clsx';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '../../../../tooltip/Tooltip';
import useStyles from './Rate.styles';
import useTranslation from '../../../../localization/customHooks/Translation';

const defaultProps = {
  isWin: false,
  isDraw: false,
  isDefeat: false,
};

function Rate(props: {
  isWin?: boolean
  isDraw?: boolean
  isDefeat?: boolean
  rate: number | undefined
}) {
  const classes = useStyles();
  const l10n = useTranslation();

  const {
    isWin, isDraw, isDefeat, rate
  } = props;
  return (
    <Tooltip
      index={uuidv4()}
      className={classes.tooltip}
      content={(
        <div className={classes.wrapperContentTooltip}>
          {isWin && (
          <span>
            %
            {' '}
            {l10n.components.tableTemplates.WIN}
          </span>
          )}
          {isDraw && (
          <span>
            %
            {' '}
            {l10n.components.tableTemplates.DRAWS}
          </span>
          )}
          {isDefeat && (
          <span>
            %
            {' '}
            {l10n.components.tableTemplates.LOSSES}
          </span>
          )}
        </div>
      )}
      container={(
        <div
          className={clsx(
            classes.coloredCell,
            { [classes.defeat]: isDefeat },
            { [classes.draw]: isDraw },
            { [classes.win]: isWin },
          )}
        >
          {rate}
        </div>
      )}
    />
  );
}

export default Rate;

Rate.defaultProps = defaultProps;