import React from 'react';
import clsx from 'clsx';
import useStyles from '../Playoff.styles';
import icons from '../constants/Icons';

function TrophyPlace(props: {
  icon: number | null,
}) {
  const classes = useStyles();
  const { icon } = props;

  return (
    <>
      {typeof icon === 'number'
        && (
        <div className={clsx(classes.flexRow, classes.qualificationInfo)}>
          <img src={icons[icon]} alt="place" />
          <div>{icon + 1}</div>
        </div>
        )}
    </>

  );
}

export default TrophyPlace;