import React from 'react';
// eslint-disable-next-line
import { formatDuration } from 'date-fns';
// eslint-disable-next-line
import { ru } from 'date-fns/locale';
import Countdown from 'react-countdown';
import moment from 'moment';
import useTranslation from '../localization/customHooks/Translation';
import { toLocalDate } from '../../utils/Utils';
import { dateAndTimeFormat } from '../../utils/constants/Constants';
import useStyles from './Countdown.styles';
import { EmptyVoidFunction } from '../../utils/types/Types';

const defaultProps = {
  onComplete: () => {}
};

function renderer(props: {
  days: number, hours: number, minutes: number, seconds: number
}) {
  const {
    hours, days, minutes, seconds
  } = props;

  function getTwoDigitsNumber(number: Number) {
    return number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  }

  return (
    <span>
      {`${formatDuration({ days, hours }, { format: ['days'], locale: ru })} 
      ${getTwoDigitsNumber(hours)}:${getTwoDigitsNumber(minutes)}:${getTwoDigitsNumber(seconds)}`}
    </span>
  );
}

function CountDown(props: {
  date: string;
  onComplete?: EmptyVoidFunction;
}) {
  const { date, onComplete } = props;
  const l10n = useTranslation();
  const classes = useStyles();

  return (
    <>
      { moment(date).isAfter() && (
      <div className={classes.wrapper}>
        <span>{l10n.pages.END_OF_REGISTRATIONE}</span>
        {' '}
        <Countdown date={toLocalDate(date, dateAndTimeFormat)} renderer={renderer} onComplete={onComplete} />
      </div>
      ) }
    </>
  );
}

export default CountDown;

CountDown.defaultProps = defaultProps;