import React from 'react';
import MobileTabData from '../../models/components/tabs/MobileTabData';
import TabData from '../../models/components/tabs/TabData';
import TabsContainer from '../tabsContainer/TabsContainer';
import MobileTabsContainer from './mobileTabs/components/container/Container';

const defaultProps = {
  DesktopLayoutStyle: '',
  MobileLayoutStyle: '',
  isCentered: true,
  isMobileLayout: false
};

const Tabs = (props: {
  tabData: TabData[] | MobileTabData[],
  DesktopLayoutStyle?: string
  MobileLayoutStyle?: string
  isCentered?: boolean
  isMobileLayout?: boolean
}) => {
  const {
    tabData, MobileLayoutStyle, DesktopLayoutStyle, isCentered, isMobileLayout
  } = props;

  return (
    isMobileLayout ? (
      <MobileTabsContainer
        tabs={tabData as MobileTabData[]}
        MobileLayoutStyle={MobileLayoutStyle}
      />
    )
      : (
        <TabsContainer
          data={tabData as TabData[]}
          isCentered={isCentered}
          className={DesktopLayoutStyle}
        />
      )
  );
};

export default Tabs;

Tabs.defaultProps = defaultProps;