import React from 'react';
import { Chip } from '@material-ui/core';
import closeIcon from '../../../assets/icons/select/closeIcon.svg';
import useStyles from '../Tags.styles';
import { EmptyVoidFunction } from '../../../utils/types/Types';

const defaultProps = {
  onDelete: () => { },
};

function FilterTag(props: {
  label: string
  onDelete?: EmptyVoidFunction,
}) {
  const {
    label, onDelete
  } = props;
  const styles = useStyles();

  return (
    <Chip
      className={styles.filterTag}
      label={label}
      onDelete={onDelete}
      deleteIcon={<img src={closeIcon} alt="icon" />}
    />
  );
}

FilterTag.defaultProps = defaultProps;

export default FilterTag;