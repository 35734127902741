import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  participant: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  linkStyle: {
    '& > div:last-child': {
      color: colors.link
    }
  }
}));

export default useStyles;