import { getTranslation } from '../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const newsType: { [key: string]: string } = {
  article: l10n.pages.ARTICLE,
  announcement: l10n.pages.ANNOUNCEMENT,
  review: l10n.pages.REVIEW,
  news: l10n.pages.PIECE_OF_NEWS
};

export default newsType;