import React from 'react';
import clsx from 'clsx';
import useStyles from '../../../Playoff.styles';
import PlayoffParticipant from '../../../components/PlayoffParticipant';
import QualificationPlace from '../../../components/QualificationPlace';
import ModifiedPlayoffPlayer from '../../../../../../../../models/pages/tournament/playoff/ModifiedPlayoffPlayer';
import RoundResult from '../../../../../../../../models/pages/tournament/playoff/RoundResult';
import QualificationCatches from '../../../components/QualificationCatches';

function QuarterFinalCard(props: {
  player: ModifiedPlayoffPlayer | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean
}) {
  const classes = useStyles();
  const {
    player, setParticipant, winnerId, isEditMode
  } = props;
  return (
    <div className={clsx(classes.flexRow, classes.anglerContainer, classes.wideContainer, { [classes.subWinner]: (player?.id === winnerId) })}>
      <div>
        <QualificationPlace place={player?.qualifyingPlace} />
        <QualificationCatches catches={player?.qualifyingCatchesSum} />
      </div>
      <PlayoffParticipant
        winnerId={winnerId}
        player={player}
        setParticipant={setParticipant}
        isEditMode={isEditMode}
      />
    </div>
  );
}

export default QuarterFinalCard;