import {
  object, string, number, date as dateType,
} from 'yup';
import { getTranslation } from '../../../../../components/localization/customHooks/Translation';
import { toDateEndOfDay } from '../../../../../utils/Utils';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(l10n.components.error.EMPTY_FIELD_ERROR),
  description: string(),
  date: dateType()
    .required(l10n.components.error.EMPTY_FIELD_ERROR)
    .typeError(l10n.components.error.INVALID_DATE),
  endRegistrationDate: dateType()
    .required(l10n.components.error.EMPTY_FIELD_ERROR)
    .typeError(l10n.components.error.INVALID_DATE)
    .when(
      'date',
      (date, yup) => date && yup.max(toDateEndOfDay(date, 'YYYY-MM-DD HH:ss'), l10n.components.error.DATE_END_REGISTARTION_IS_LATER)
    ),
  locationId: string().required(l10n.components.error.LOCACTION_ERROR),
  participantsLimit: number()
    .required(l10n.components.error.MAX_PARTICIPANTS_ERROR)
    .typeError(l10n.components.error.EMPTY_FIELD_ERROR)
    .positive(l10n.components.error.MIN_PARTICIPANT_ERROR)
    .integer(l10n.components.error.INTEGER_ERROR),
  rankingId: string().required(l10n.components.error.RANKING_ERROR),
  templateId: string().required(l10n.components.error.TEMPLATE_ERROR),
  seasonId: string().required(l10n.components.error.SEASON_ERROR),
  formatId: string().required(l10n.components.error.FORMAT_ERROR),
  regulationsLink: string().url(l10n.components.error.LINK_ERROR),
  rulesLink: string().url(l10n.components.error.LINK_ERROR),
});

export default schema;