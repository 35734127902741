import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../../stores/Main';
import TournamentTemplate from '../../../../../../../models/components/dictionaries/tournamentTemplate/TournamentTemplate';
import schema from '../../helpers/ValidationSchema';
import useStyles from '../../Template.style';
import Toasters from '../../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import ConfigurationTable from '../../table/configuration/ConfigurationTable';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const EditTemplateModal = observer((props: {
  handleClose?: EmptyVoidFunction
}) => {
  const { handleClose } = props;
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<TournamentTemplate>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { TournamentTemplatesStore } = useStores();

  const currentTemplate = TournamentTemplatesStore!.templates!.find((template: TournamentTemplate) => template.id === TournamentTemplatesStore.id);

  const editTemplate = async (data: TournamentTemplate) => {
    const response = await TournamentTemplatesStore.editTemplate(data);
    if (response) {
      Toasters.success(l10n.components.tournamentsTemplate.TEMPLATE_EDIT_SUCCESS);
    }
    if (handleClose) {
      handleClose();
    }
  };

  if (TournamentTemplatesStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <div className={classes.modalWrapper}>
      <form onSubmit={handleSubmit(editTemplate)} className={classes.contentWrapper}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              label={l10n.components.tournamentsTemplate.TEMPLATE_NAME}
              variant="outlined"
              error={!!errors.name}
              className={classes.contentInput}
              required
              autoFocus
              inputProps={{ 'data-testid': 'name' }}
            />
          )}
          name="name"
          control={control}
          defaultValue={currentTemplate?.name || null}
        />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.contentInput}
              label={l10n.components.tournamentsTemplate.ROUNDS_COUNT}
              error={!!errors.roundsCount}
              required
              variant="outlined"
              inputProps={{ 'data-testid': 'roundsCount' }}
              disabled
            />
          )}
          name="roundsCount"
          control={control}
          defaultValue={currentTemplate?.roundsCount || null}
        />
        <ConfigurationTable control={control} errors={errors} currentTemplate={currentTemplate} isEditable />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              label={l10n.components.tournamentsTemplate.TEMPLATE_DESCRIPTION}
              error={!!errors.description}
              variant="outlined"
              className={classes.contentInput}
              required
              multiline
              rows={4}
              inputProps={{ 'data-testid': 'description' }}
            />
          )}
          name="description"
          control={control}
          defaultValue={currentTemplate?.description || null}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}
          className={clsx(classes.saveButton, classes.contentInput)}
          data-testid="saveBtn"
        >
          {l10n.components.tournamentsTemplate.SAVE_BUTTON}
        </Button>
      </form>
    </div>
  );
});

export default EditTemplateModal;