import AvailableTournamentsForReport from '../../models/pages/availiableTournaments/AvailableTournamentsForReport';
import Report from '../../models/components/report/Report';
import Round from '../../models/pages/tournament/Round';
import { sortReportRounds } from '../../utils/Utils';
import { getTranslation } from '../localization/customHooks/Translation';

const l10n = getTranslation();

export const getRoundDescription = (round: Round) => (round.stageDescription || `${l10n.components.tableColumns.ROUND} ${round.roundNumber}`);

export const mapRoundDescription = (currentRounds: Round[]) => currentRounds.map((round: Round) => ({
  ...round,
  roundDescription: getRoundDescription(round),
}));

export const mergeRounds = (report: AvailableTournamentsForReport) => (
  { ...report, roundReports: [...mapRoundDescription(report.rounds), ...mapRoundDescription(report.playOffGames)] });

export const mergeSingleRound = (round: Round) => ({
  ...round,
  ...(round.playOffGame || round.tournamentRound),
  roundDescription: getRoundDescription(round.playOffGame || round.tournamentRound)
});

export const mergeRoundsInEditModal = (report: Report) => ({
  ...report,
  roundReports: sortReportRounds(report.roundReports)
    .map((round: Round) => mergeSingleRound(round))
});