import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  IconButton, Collapse, Typography
} from '@material-ui/core';
import { EmptyVoidFunction } from '../../utils/types/Types';
import useStyles from './CollapsibleSection.styles';
import closeModal from '../../assets/icons/closeModal.svg';
import useTranslation from '../localization/customHooks/Translation';

const defaultProps = {
  onClose: () => { },
  className: '',
  isMobileViewDisabled: false,
  collapsedHeight: 0,
};

const CollapsibleSection = (props: {
  children: JSX.Element;
  isSectionOpen: boolean;
  setIsSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: EmptyVoidFunction
  className?: string
  isMobileViewDisabled?: boolean
  collapsedHeight?: string | number
}) => {
  const {
    onClose, isSectionOpen, setIsSectionOpen, children, className, isMobileViewDisabled, collapsedHeight
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const handleClose = () => {
    setIsSectionOpen(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (!isMobileViewDisabled) {
      document.body.classList.toggle(classes.sectionOpen, isSectionOpen);
    }
  }, [isSectionOpen]);

  return (
    <Collapse
      in={isSectionOpen}
      className={clsx(classes.collapseContainer, className)}
      collapsedHeight={collapsedHeight}
    >
      <div className={clsx(classes.root, { [classes.mobileViewDisabled]: isMobileViewDisabled })}>
        <div className={clsx(classes.header, { [classes.hideHeader]: isMobileViewDisabled })}>
          <IconButton
            disableRipple
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <img src={closeModal} alt="close" />
          </IconButton>
          <Typography component="h2" className={classes.title}>{l10n.components.collapsibleSection.FILTERS}</Typography>
        </div>
        {React.cloneElement(children, {
        })}
      </div>
    </Collapse>
  );
};

export default CollapsibleSection;

CollapsibleSection.defaultProps = defaultProps;