import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  heading: {
    margin: 0,
    marginLeft: 30,
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 0,
    },
  }
}));

export default useStyles;