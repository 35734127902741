import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import PlayoffSchedule from '../../../../admin/tournaments/edit/playoff/components/Schedule';
import TabsContainer from '../../../../../components/tabsContainer/TabsContainer';
import HeatCaptureTable from '../../../../../components/heatCaptureTable/HeatCaptureTable';
import FinalResults from './finalResults/FinalResults';
import Qualifiers from '../../../../admin/tournaments/edit/qualifiers/Qualifiers';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import TournamentReports from './finalResults/reports/Reports';

const Results = observer((props: {
  className?: string
  roundsQuantity: number | undefined
}) => {
  const url = useParams<{ id: string }>();
  const { className, roundsQuantity } = props;
  const l10n = useTranslation();

  const resultsContent = [
    {
      name: l10n.pages.TOURNAMENT_RESULT,
      component: <FinalResults id={Number(url.id)} />,
      hash: '#tournament-result'
    },
    {
      name: l10n.pages.QUALIFACATION_RESULTS,
      component: <Qualifiers />,
      hash: '#qualification-result'
    },
    {
      name: l10n.pages.PLAYOFF_RESULT,
      component: <PlayoffSchedule isEditMode={false} />,
      hash: '#playoff-result'
    },
    {
      name: l10n.pages.HEAT_MAP,
      component: <>{roundsQuantity && <HeatCaptureTable id={Number(url.id)} roundsQuantity={roundsQuantity} />}</>,
      hash: '#heat-map'
    },
    {
      name: l10n.components.tournamentReports.REPORTS,
      component: <TournamentReports tournamentId={url.id} />,
      hash: '#reports-list',
    }
  ];

  return (
    <TabsContainer
      data={resultsContent}
      isCentered={false}
      className={className}
    />
  );
});

export default Results;