import React from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { ParticipantInfo } from '../../../../models/pages/tournament/Participant';
import useStyles from './Winners.styles';
import { cups } from '../../../../utils/constants/Constants';
import Angler from '../../../angler/Angler';

const defaultProps = {
  className: '',
};

const Winners = (props: {
  winners: ParticipantInfo[] | undefined
  className?: string
}) => {
  const classes = useStyles();
  const { winners, className } = props;

  return (
    <ul className={clsx(className, classes.winners)}>
      {winners?.map((winner) => (
        <li
          key={uuidv4()}
          className={`${classes.winner} winner-${winner.participant.finalPlace}`}
        >
          <Angler
            anglerData={winner.participant.angler}
            isTeam={winner.isTeam}
            avatarStyle="medium"
            className={classes.userInfo}
          >
            <div className={classes.place}>
              <img src={cups[winner.participant.finalPlace]} alt="cup" />
              <span className={classes.winnerPlace}>{winner.participant.finalPlace}</span>
            </div>
          </Angler>
        </li>
      ))}
    </ul>
  );
};

export default Winners;

Winners.defaultProps = defaultProps;