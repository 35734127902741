import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    color: colors.white
  },
  AddBtnContainer: {
    marginBottom: 15
  },
  buttonSize: {
    width: 120
  },
  inputContainer: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  submit: {
    display: 'block',
  },
  modalWide: {
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      position: 'relative',
      width: '85%',
      maxWidth: 600,
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
      overflowY: 'hidden',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto',
      },
    }
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      gap: 40,
    },
  },
  controlButton: {
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  tacklesTable: {
    '& .MuiTableCell-root': {
      padding: 0,
      fontSize: 15,
      textAlign: 'left'
    },
    '& .MuiTableHead-root': {
      '& th': {
        padding: '10px 0px',
        textAlign: 'center'
      },
    },
    '& .MuiTableBody-root': {
      '&>tr>td': {
        paddingLeft: 15,
      },
      '&>tr>td:last-child': {
        paddingLeft: 0,
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
    margin: '20px 0px 30px'
  },
  input: {
    '&>div>.MuiFormHelperText-contained': {
      marginLeft: 0
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialog: {
    justifyContent: 'center',
    gap: 20,
    margin: '20px 0px',
    '& div:first-child': {
      fontSize: 18
    },
    '&>div>button': {
      width: 100
    }
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 300,
    width: '100%',
  },
}));

export default useStyles;