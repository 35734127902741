import { IconButton } from '@material-ui/core';
import React from 'react';
import useStyles from '../Button.styles';

const SocialMedia = (props: {
  link: string
  children: JSX.Element | JSX.Element[]
}) => {
  const classes = useStyles();

  const { link, children } = props;

  return (
    <IconButton
      disableFocusRipple
      disableRipple
      className={classes.socialMediaButton}
      size="medium"
      href={link}
      target="blank"
    >
      {children}
    </IconButton>
  );
};

export default SocialMedia;