import * as React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import RTEtext from '../../../../components/RTEtext/RTEtext';
import ImageData from '../../../../models/components/image/ImageData';
import { getImageUrl } from '../../../../utils/Utils';
import Routes from '../../../../routing/Routes';
import useStyles from '../../Location.styles';
import locationPlaceholder from '../../../../assets/location/placeholder.png';
import useTranslation from '../../../../components/localization/customHooks/Translation';

export default function MultiActionAreaCard(props: {
  locationId: number, description: string; name: string; image: ImageData | null;
}) {
  const {
    name, locationId, image, description
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const getImage = () => (
    image !== null ? getImageUrl(image.url) : locationPlaceholder
  );

  return (
    <Link to={Routes.Location.path.replace(':id', String(locationId))}>
      <div className={classes.newsCard}>
        <img
          className={classes.previewImg}
          src={getImage()}
          alt="previewImg"
        />
        <div className={classes.headerText}>
          <Typography variant="h5">{name}</Typography>
        </div>
        <p className={clsx(classes.basicTextStyle, classes.hideContent, classes.textContainer)}>
          <RTEtext
            text={description}
          />
          <Link
            to={Routes.Location.path.replace(':id', String(locationId))}
          >
            <span className={clsx(classes.continueRead, classes.link)}>
              {`...${l10n.shared.CONTINUE}`}
            </span>
          </Link>
        </p>
      </div>
    </Link>
  );
}