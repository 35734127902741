import React from 'react';
import clsx from 'clsx';
import useStyles from '../Playoff.styles';
import icons from '../constants/Icons';
import { playoffIcons } from '../../../../../../utils/enum/Enum';

function QualificationPlace(props: {
  place: number | undefined
}) {
  const classes = useStyles();
  const { place } = props;

  return (
    <div className={clsx(classes.flexRow, classes.qualificationInfo)}>
      <img src={icons[playoffIcons.star]} alt="qualification place" />
      <div>{place}</div>
    </div>
  );
}

export default QualificationPlace;