import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import clsx from 'clsx';
import ScrollableTableCell from '../scrollableTableCell/ScrollableTableCell';
import RatingResultsInterface from '../../models/pages/tournament/playoff/publication/RatingResults';
import PublishedTournament from '../../models/pages/tournament/playoff/publication/PublishedTournament';
import { sortingType } from '../../utils/enum/Enum';
import TournamentService from '../../services/main/Tournament.service';
import useStyles from './TournamentResult.styles';
import Table from '../table';
import LoaderFullScreen from '../loader/fullScreen/FullScreen';
import PlaceCell from '../tableTemplates/components/cell/place/Place';
import RatingValueCell from './components/cell/RatingValue';
import { cups } from '../../utils/constants/Constants';
import Angler from '../angler/Angler';
import useTranslation from '../localization/customHooks/Translation';
import Routes from '../../routing/Routes';

const defaultProps = {
  isSearchVisible: true,
};

const TournamentResult = (props: {
  id: number
  isSearchVisible?: boolean
}) => {
  const { id, isSearchVisible } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isLoading, setIsLoader] = useState(true);

  // TODO: move in above
  const [ratingResultsData, setRatingResultsData] = useState<RatingResultsInterface[]>([]);
  const [tournamentResult, setTournamentResult] = useState<PublishedTournament[]>([]);
  const [isTeamTournament, setIsTeamTournament] = useState<boolean>(false);
  const classes = useStyles();
  const l10n = useTranslation();

  const getTournamentsResult = async () => {
    const response = await TournamentService.getFinalTournamentResult(id);
    if (response) {
      const transformParticipants: PublishedTournament[] = response;
      transformParticipants.sort((prev, next) => prev.participant.finalPlace - next.participant.finalPlace);
      setTournamentResult(transformParticipants);
      setRatingResultsData(transformParticipants[0].participant.ratingResults);
      setIsTeamTournament(transformParticipants[0].isTeam);
    }
    setIsLoader(false);
  };

  const getRatingColumns = () => ratingResultsData.map((result: RatingResultsInterface) => ({
    displayName: result.rating.name,
    fieldName: `${result.points}`,
    allowSearch: false,
    allowSortring: false,
    render: ({ participant: { ratingResults } }: PublishedTournament) => {
      const ratingResult = ratingResults.find((rating) => result.ratingId === rating.ratingId);
      return <RatingValueCell points={ratingResult?.points} />;
    }
  }));

  const getColumns = () => {
    const columns = [
      {
        displayName: l10n.components.tournamentResult.PLACE,
        fieldName: 'participant.finalPlace',
        defaultSort: sortingType.asc,
        render: ({ participant: { finalPlace } }: PublishedTournament) => <PlaceCell place={finalPlace} imageName={cups[finalPlace]} isHaveImageAnyPlaces />
      },
      {
        displayName: `${isTeamTournament ? l10n.components.tournamentResult.TEAM : l10n.components.tournamentResult.ANGLER}`,
        fieldName: 'participant.angler.fullName',
        render: ({ participant: { angler }, reportsIds }: PublishedTournament) => (
          <div className={classes.nameWrapper}>
            <Angler anglerData={angler} isTeam={isTeamTournament} />
            {reportsIds && reportsIds.map((reportId) => (
              <Link to={Routes.TournamentReport.path.replace(':id', String(reportId))}>
                <ArticleIcon />
              </Link>
            ))}
          </div>
        )
      },
      {
        displayName: l10n.components.tournamentResult.CATCHES,
        fieldName: 'participant.catchesSum',
        render: ({ participant: { catchesSum } }: PublishedTournament) => catchesSum
      },
      {
        displayName: l10n.components.tournamentResult.POINTS,
        fieldName: 'participant.scoresSum',
        render: ({ participant: { scoresSum } }: PublishedTournament) => scoresSum
      },
      ...getRatingColumns()
    ];
    return columns;
  };

  useEffect(() => {
    getTournamentsResult();
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  if (tournamentResult) {
    if (isMobile) {
      return (
        <>
          {tournamentResult.map(({ participant, isTeam }: PublishedTournament) => {
            const participantResult = { icon: cups[participant.finalPlace], place: participant.finalPlace, angler: participant.angler };
            return (
              <ScrollableTableCell
                participant={participantResult}
                isTeam={isTeam}
                key={participant.angler.id}
                scrolledContent={
                  (
                    <>
                      <Grid className={classes.result}>
                        {`${l10n.pages.CATCHES}:  `}
                        {participant.catchesSum}
                      </Grid>
                      <Grid className={classes.result}>
                        {`${l10n.pages.POINTS}:  `}
                        {participant.scoresSum}
                      </Grid>
                      {ratingResultsData.length > 0
                        && (
                          <Grid className={clsx(classes.result, classes.rating)}>
                            {ratingResultsData.map((result) => {
                              const ratingResult = participant.ratingResults.find((rating) => result.ratingId === rating.ratingId);
                              return (
                                <div key={result.ratingId}>
                                  {`${result.rating.name}:  `}
                                  <RatingValueCell points={ratingResult?.points} />
                                </div>
                              );
                            })}
                          </Grid>
                        )}
                    </>
                  )
                }
              />
            );
          })}
        </>
      );
    }
    return (
      <Table
        columns={getColumns()}
        rows={tournamentResult}
        isSearchVisible={isSearchVisible}
        isPaging={false}
        isStickyHeader
        className={classes.tableCell}
      />
    );
  }
  return (
    <Grid container direction="row" justify="center">
      <h3>{l10n.components.tournamentResult.EMPTY_DATA}</h3>
    </Grid>
  );
};

export default TournamentResult;

TournamentResult.defaultProps = defaultProps;