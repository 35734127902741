import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import {
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import useStyles from '../../team/Team.styles';
import SelectItem from '../../../../models/components/selects/SelectItem';
import closeIcon from '../../../../assets/icons/select/closeIcon.svg';

const defaultProps = {
  disabled: false,
  isButtonResetAvailable: false,
  className: '',
  defaultValue: '',
  menuItemStyle: '',
  selectStyle: ''
};

const Desktop = (props: {
  menuItems: SelectItem[],
  // eslint-disable-next-line no-unused-vars
  onItemSelected: (value: string | number | null) => void,
  label: string
  disabled?: boolean,
  bindValue: keyof Omit<SelectItem, 'isDefault'>,
  isButtonResetAvailable?: boolean,
  className?: string
  defaultValue?: string | number
  menuItemStyle?: string
  selectStyle?: string
}) => {
  const {
    menuItems,
    disabled,
    onItemSelected,
    bindValue,
    isButtonResetAvailable,
    label,
    className,
    defaultValue,
    menuItemStyle,
    selectStyle
  } = props;

  const classes = useStyles();
  const getInitialValue = () => (menuItems.length ? (menuItems.find((item) => item.isDefault) || menuItems[0])[bindValue] : '');

  const [selectedItem, setSelectedItem] = useState<string | number>(getInitialValue());

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    event.preventDefault();
    setSelectedItem(event.target.value);
    onItemSelected(event.target.value);
  };

  const resetFilter = () => {
    setSelectedItem('');
    onItemSelected(null);
  };

  useEffect(() => {
    const initialValue = getInitialValue();
    setSelectedItem(defaultValue || initialValue);
    onItemSelected(defaultValue || initialValue);
  }, [menuItems]);

  return (
    <div className={clsx(classes.selectContainerWrapper, classes.basicText, className)}>
      <FormHelperText className={clsx(classes.label, classes.basicText)}>{label}</FormHelperText>
      <div className={classes.selectContainer}>
        {isButtonResetAvailable
          && (
            <button
              className={classes.deleteItems}
              onClick={() => resetFilter()}
              type="button"
            >
              <img src={closeIcon} alt="closeIcon" />
            </button>
          )}
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            classes: { list: classes.singlelist },
            autoFocus: false,
            getContentAnchorEl: null,
          }}
          disabled={disabled}
          variant="outlined"
          value={selectedItem}
          className={selectStyle || classes.select}
          defaultValue={selectedItem}
          onChange={handleSelectChange}
        >
          {menuItems.map((item: SelectItem) => (
            <MenuItem
              classes={{
                root: clsx(
                  classes.listItem,
                  classes.basicText,
                  classes.singleItem,
                  { [classes.active]: selectedItem === item[bindValue] }
                ),
                selected: classes.active
              }}
              disableGutters
              disableRipple
              key={item.id || uuidv4()}
              value={item[bindValue]}
              className={menuItemStyle || classes.menuItemContainer}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

Desktop.defaultProps = defaultProps;

export default Desktop;