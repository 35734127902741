import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import useStyles from '../../../Tabs.styles';
import MobileTabData from '../../../../../models/components/tabs/MobileTabData';

const defaultProps = {
  MobileLayoutStyle: '',
};

const Menu = (props: {
  tabs: MobileTabData[],
  MobileLayoutStyle?: string
  // eslint-disable-next-line
  onSelectTab: (hash: string) => void
}) => {
  const {
    tabs, MobileLayoutStyle, onSelectTab
  } = props;

  const handleClick = (tab: MobileTabData) => {
    if (tab.hash) {
      onSelectTab(tab.hash);
    }
  };
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, MobileLayoutStyle)}>
      {tabs.map((tab) => (
        <button
          className={classes.button}
          type="button"
          key={uuidv4()}
          onClick={() => handleClick(tab)}
        >
          <div>
            {tab.name}
          </div>
          <ChevronRightIcon />
        </button>
      ))}
    </div>
  );
};

export default Menu;

Menu.defaultProps = defaultProps;