import {
  number,
  object, string,
} from 'yup';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const validateString = () => string().trim().required(l10n.shared.REQUIRED_FIELD);

const schema = object().shape({
  name: validateString(),
  description: validateString(),
  status: number().required(l10n.shared.REQUIRED_FIELD),
});

export default schema;