import React from 'react';
import { Avatar } from '@material-ui/core';
import clsx from 'clsx';
import { getImageUrl } from '../../utils/Utils';
import useStyles from './ParticipantData.styles';
import noAvatar from '../../assets/icons/no-avatar.svg';

const defaultProps = {
  url: '',
};

function ParticipantData(props: {
  name: string
  url?: string
}) {
  const classes = useStyles();
  const { name, url } = props;
  return (
    <div className={clsx(classes.flexRow, classes.partnerData)}>
      <Avatar
        alt={name || 'avatar'}
        src={url ? getImageUrl(url) : noAvatar}
      />
      <span>{name}</span>
    </div>
  );
}

ParticipantData.defaultProps = defaultProps;

export default ParticipantData;