import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Member from '../../member/Member';
import useStyles from '../Angler.styles';
import { AnglerData } from '../../../models/pages/tournament/playoff/publication/PublishedTournament';
import Routes from '../../../routing/Routes';

const defaultProps = {
  avatarStyle: '',
  className: '',
  userNameStyle: '',
  children: null,
  isTeam: false,
};

const AnglerLink = (props: {
  anglerData: AnglerData;
  avatarStyle?: string
  className?: string
  userNameStyle?: string
  children?: JSX.Element | JSX.Element[] | null,
  isTeam?: boolean
}) => {
  const classes = useStyles();

  const {
    anglerData, avatarStyle, className, userNameStyle, children, isTeam
  } = props;

  return (
    <>
      { isTeam ? (
        <div className={classes.participant}>
          <Member
            countryFlag={anglerData.country && anglerData.country.flag}
            fullName={anglerData.fullName}
            profilePicture={anglerData?.profilePicture}
            avatarStyle={avatarStyle}
            className={clsx(className, { [classes.linkStyle]: !isTeam })}
            userNameStyle={userNameStyle}
          >
            {children}
          </Member>
        </div>
      ) : (
        <Link to={Routes.User.path.replace(':id', String(anglerData.id))}>
          <div className={classes.participant}>
            <Member
              countryFlag={anglerData.country && anglerData.country.flag}
              fullName={anglerData.fullName}
              profilePicture={anglerData?.profilePicture}
              avatarStyle={avatarStyle}
              className={clsx(className, { [classes.linkStyle]: !isTeam })}
              userNameStyle={userNameStyle}
            >
              {children}
            </Member>
          </div>
        </Link>
      )}

    </>
  );
};

AnglerLink.defaultProps = defaultProps;

export default AnglerLink;