import React, { } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Report from '../../../../../../../../../models/components/report/Report';
import useStyles from '../Card.styles';
import useTranslation from '../../../../../../../../../components/localization/customHooks/Translation';
import RTEtext from '../../../../../../../../../components/RTEtext/RTEtext';
import Routes from '../../../../../../../../../routing/Routes';
import getPreviewContent from '../../../../../../../../user/components/tournamentReports/components/ReportCard/ReportCard.utils';
import ReportPicture from '../picture/Picture';
import AnglerLink from '../../../../../../../../../components/angler/anglerLink/AnglerLink';

const MobileCard = (props: {
    report: Report,
}) => {
  const {
    report,
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.headerMobile, classes.locationWrapper)}>
        <AnglerLink anglerData={report.author} />
      </div>
      <ReportPicture report={report} />
      <div className={classes.contentWrapper}>
        <Typography variant="h2" className={classes.link} component={Link} to={Routes.TournamentReport.path.replace(':id', report.id)}>
          { report.name }
        </Typography>
        <div>
          <RTEtext text={getPreviewContent(report) || ''} />
        </div>
        <Link className={clsx(classes.readMore, classes.link)} to={Routes.TournamentReport.path.replace(':id', report.id)}>
          {l10n.shared.READ_MORE}
        </Link>
      </div>
    </div>
  );
};

export default MobileCard;