import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    boxSizing: 'border-box',
    color: colors.secondary,
    width: '100%',
    maxWidth: 430,
    border: '1px solid #E2E4F0',
    borderRadius: 8
  },
  name: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 'bold',
    color: colors.secondary,
    marginLeft: 20
  },
  date: {
    fontWeight: 600,
    lineHeight: '22px'
  },
  teamWrapper: {
    padding: '7px 12px',
    borderBottom: '1px solid #E2E4F0',
    background: 'rgba(226, 228, 240, 0.5)',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  person: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '16px 12px',
    borderBottom: '1px solid #E2E4F0',
  },
  personText: {
    lineHeight: '22px',
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 4
    },
  },
  personInfo: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  personName: {
    lineHeight: '22px',
    marginLeft: 12,
    color: colors.link
  },
  button: {
    padding: '10px 24px',
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'center',
    '&:first-child': {
      borderRight: '1px solid #E2E4F0',
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: 20,
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '30px',
      color: colors.secondary
    }
  },
  text: {
    marginTop: 10,
    lineHeight: '24px',
    color: colors.secondary,
    textAlign: 'left'
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100vh',
        margin: 0,
        height: '100vh',
        maxHeight: '100vh'
      },
    },
  }
}));

export default useStyles;