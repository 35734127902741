import React, { SetStateAction } from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useStores } from '../../../../../../../../stores/Main';
import useStyles from '../../ReportCard.styles';
import Report from '../../../../../../../../models/components/report/Report';

function EditDeleteCell(props: {
  report: Report,
  setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>,
  setIsDeleteModalOpened: React.Dispatch<SetStateAction<boolean>>,
}) {
  const {
    report, setIsEditModalOpened, setIsDeleteModalOpened
  } = props;
  const classes = useStyles();
  const { ReportsStore } = useStores();

  const openDelModal = (currReport: Report): void => {
    ReportsStore.setReport(currReport);
    setIsDeleteModalOpened(true);
  };

  const openEditModal = async () => {
    ReportsStore.setReport(report);
    await ReportsStore.getOneReportById(report.id);
    if (ReportsStore.report) {
      setIsEditModalOpened(true);
    }
  };

  return (
    <>
      {report && (
      <ListItem className={classes.buttonCell}>
        <IconButton
          edge="start"
          aria-label="edit"
          onClick={() => openEditModal()}
        >
          <Edit />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => openDelModal(report)}
        >
          <Delete />
        </IconButton>
      </ListItem>
      )}
    </>
  );
}

export default EditDeleteCell;