import React from 'react';
import ConfirmationEmail from '../confirmationEmail/ConfirmationEmail';
import ConfirmationResult from '../confirmationEmail/confirmationResult/ConfirmationResult';
import ChangePassword from '../changePassword/ChangePassword';
import ChangePasswordResult from '../changePassword/changePasswordResult/ChangePasswordResult';
import RecoveryPassword from '../recoveryPassword/RecoveryPassword';
import RecoveryLinkSend from '../recoveryPassword/RecoveryLinkSend/RecoveryLinkSend';
import SignInForm from '../signIn/SignIn';
import SignUpForm from '../signUp/SignUp';
import ActivationFailed from '../activationErrors/activationFailed/ActivationFailed';
import ActivationExpired from '../activationErrors/activationExpired/ActivationExpired';
import EditProfile from '../editProfile/EditProfile';

const AuthorizationComponents = {
  SignIn: <SignInForm />,
  SignUp: <SignUpForm />,
  ConfirmationEmail: <ConfirmationEmail />,
  ConfirmationResult: <ConfirmationResult />,
  ChangePassword: <ChangePassword />,
  ChangePasswordResult: <ChangePasswordResult />,
  RecoveryPassword: <RecoveryPassword />,
  RecoveryLinkSend: <RecoveryLinkSend />,
  ActivationFailed: <ActivationFailed />,
  ActivationExpired: (email: string) => <ActivationExpired email={email} />,
  EditProfile: <EditProfile />
};

export default AuthorizationComponents;