import React from 'react';
import { MenuItem, ListItemText } from '@material-ui/core';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useStyles from '../../UserProfile.styles';

const defaultProps = {
  clickHandler: () => { },
  handleClose: () => { }
};

function DropDownItem(props: {
  label: string
  clickHandler?: EmptyVoidFunction
  handleClose?: EmptyVoidFunction
}) {
  const classes = useStyles();
  const {
    label, clickHandler, handleClose
  } = props;
  const runClickHandler = () => {
    if (clickHandler) { clickHandler(); }
    if (handleClose) { handleClose(); }
  };
  return (
    <MenuItem disableRipple onClick={runClickHandler}>
      <ListItemText primary={label} className={classes.label} />
    </MenuItem>
  );
}

DropDownItem.defaultProps = defaultProps;

export default DropDownItem;