import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import useTranslation from '../../../../../components/localization/customHooks/Translation';
import Modal from '../../../../../components/modal/Modal';
import ActivationStateModal from './modal/Modal';
import useStyles from './ActivationState.style';
import { EmptyVoidFunction } from '../../../../../utils/types/Types';

export default function ActivationState(
  props: {
    userId: string
    getUsers: EmptyVoidFunction
    isEmailConfirmed: boolean
  }
) {
  const l10n = useTranslation();
  const classes = useStyles();
  const {
    userId, getUsers, isEmailConfirmed
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        className={isEmailConfirmed ? classes.activationButtonConfirmed : classes.activationButtonNotConfirmed}
        onClick={() => {
          setOpen(true);
        }}
      >
        {isEmailConfirmed ? l10n.components.button.ACTIVATED : l10n.components.button.ACTIVATE}
      </Button>
      <Modal
        header={l10n.components.confirmDialogsMessage.HEADER_USERACTIVATION}
        open={open}
        setOpen={setOpen}
        modalClassName={classes.ActivateFormModal}
        className={classes.modalWide}
        onClose={getUsers}
      >
        <ActivationStateModal
          userId={userId}
        />
      </Modal>
    </>
  );
}