import TeamData from '../../models/pages/team/TeamData';
import {
  remove, get, post, put, transformError
} from '../Http';

const url = '/api/Teams/';

const TeamsService = {
  async getAll() {
    return get(`${url}all`);
  },

  async getAllTeams() {
    return get(url);
  },

  async getAvailableTeams(tournamentId: number) {
    return get(`${url}registration/${tournamentId}/available`);
  },

  async post(team: TeamData) {
    return post(url, team, undefined, transformError);
  },

  async register(id: number, tournamentId: number) {
    return post(`${url}${id}/registration/${tournamentId}`);
  },

  async delete(id: number) {
    return remove(url + id);
  },

  async getAllActiveInvitations() {
    return get(`${url}invitations/active`);
  },

  async put(team: TeamData) {
    return put(url + team.id, team, undefined, transformError);
  },

  async acceptInvitation(id: number) {
    return put(`${url}invitations/${id}/accept`);
  },

  async rejectInvitation(id: number) {
    return put(`${url}invitations/${id}/reject`);
  },

  async getTeamByUserId(teamUserId: string) {
    return get(`${url}identity/${teamUserId}/members`);
  }
};

export default TeamsService;