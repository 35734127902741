import React from 'react';
import noAvatar from '../../../../../../assets/icons/no-avatar.svg';
import useStyles from '../../../../UserCard.styles';
import horizontalLine from '../../../../../../assets/icons/horizontal-line.svg';
import { getImageUrl } from '../../../../../../utils/Utils';
import AdminUser from '../../../../../../models/pages/user/AdminUser';

interface MeetingTableHeaderProps {
  user: AdminUser,
  opponent: AdminUser,
}

function MeetingTableHeader({ user, opponent }: MeetingTableHeaderProps) {
  const classes = useStyles();
  return (
    <div className={classes.meetingTableHeader}>
      <div className={classes.user}>
        <img
          src={user.profilePicture?.url ? getImageUrl(user.profilePicture.url) : noAvatar}
          alt={user.profilePicture?.filename || 'avatar'}
          className={classes.photo}
        />
        <p className={classes.name}>{user.fullName}</p>
      </div>
      <p className={classes.line}><img src={horizontalLine} alt="line" className={classes.line} /></p>
      <div className={classes.user}>
        <img
          src={opponent.profilePicture?.url ? getImageUrl(opponent.profilePicture.url) : noAvatar}
          alt={opponent.profilePicture?.filename || 'avatar'}
          className={classes.photo}
        />
        <p className={classes.name}>{opponent.fullName}</p>
      </div>
    </div>
  );
}

export default MeetingTableHeader;