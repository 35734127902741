import { ParticipantInfo } from '../../models/pages/tournament/Participant';
import Round from '../../models/pages/tournament/Round';
import SelectItem from '../../models/components/selects/SelectItem';
import HeatMapData from '../../models/pages/tournament/HeatMapData';
import { getTranslation } from '../localization/customHooks/Translation';

const l10n = getTranslation();

export const getParticipantsData = (participants: ParticipantInfo[]) => {
  const participantsData = participants;
  participantsData.map(({ participant }) => participant.rounds.sort((roundPrev, roundNext) => roundPrev.sectorNumber - roundNext.sectorNumber));
  return participantsData;
};

const sortAlphabet = (a: SelectItem, b: SelectItem) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const getFullNames = (arr: ParticipantInfo[], setter: any) => {
  const anglersNames: SelectItem[] = arr.map((info: ParticipantInfo) => ({ id: info.participant.id, name: info.participant.angler.fullName }))
    .sort(sortAlphabet);
  setter([{ id: 0, name: l10n.components.heatCaptureUtils.EMPTY_SELECT }, ...anglersNames]);
};

export const getRoundNumber = (participants: ParticipantInfo[]) => getParticipantsData(participants)[0].participant.rounds.length;

export const defineSectorByRounds = (heatMapData: { [key: number]: HeatMapData[] }) => Object.keys(heatMapData).map((value) => ({
  rounds: heatMapData[Number(value)],
  sector: value,
}
));

export const getSectorsData = (tableData: ParticipantInfo[]) => {
  const heatMapData: { [key: number]: HeatMapData[] } = {};

  tableData.forEach(({ participant }: ParticipantInfo) => {
    const { angler, rounds } = participant;

    rounds.forEach((round: Round) => {
      const {
        sectorNumber, roundNumber, anglerCatches, opponentCatches, opponent
      } = round;

      if (!heatMapData[sectorNumber]) {
        heatMapData[sectorNumber] = [];
      }

      if (!heatMapData[sectorNumber].some((currentRound: any) => currentRound.tour === roundNumber)) {
        heatMapData[sectorNumber]
          .push({
            tour: roundNumber,
            angler: { name: angler.fullName, catches: anglerCatches },
            opponent: { name: opponent.fullName, catches: opponentCatches }
          });
      }
    });
  });
  return defineSectorByRounds(heatMapData);
};

export const getTotalRow = (catchesData: { rounds: HeatMapData[] }[], roundsQuantity: number) => {
  const TotalCatches: number[] = Array(roundsQuantity).fill(0);
  catchesData
    .map((sector) => sector.rounds
      .forEach((round) => {
        const anglerCatches = round.angler.catches || 0;
        const opponentCatches = round.opponent.catches || 0;
        TotalCatches[round.tour - 1] += (anglerCatches + opponentCatches);
      }));
  return TotalCatches;
};

export const getSummaryData = (rounds: HeatMapData[]) => rounds.reduce((previousValue: number, currentValue: HeatMapData) => {
  const anglerCatches = currentValue.angler.catches || 0;
  const opponentCatches = currentValue.opponent.catches || 0;
  return previousValue + anglerCatches + opponentCatches;
}, 0);