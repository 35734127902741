import {
  object, string, number, array, mixed,
} from 'yup';
import { phoneRegex } from '../../../../../utils/constants/Constants';
import { instagramLinkDomens, facebookLinkDomens } from '../../../../../components/authorization/editProfile/helpers/Constants';
import { checkDomain } from '../../../../../components/authorization/editProfile/EditProfile.utils';
import { getTranslation } from '../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(),
  description: string().required(l10n.components.locations.REQUIRED_DESCRIPTION),
  longitude: number().required(),
  latitude: number().required(),
  images: array().of(mixed()),
  phoneNumber: string()
    .matches(phoneRegex,
      { message: l10n.components.editLocation.PHONE_NUMBER_VALIDATION }).required(),
  instagramLink: string().trim().test('checkUrl', l10n.components.editProfile.VALIDATION_INVALID, (value) => checkDomain(value, instagramLinkDomens)),
  facebookLink: string().trim().test('checkUrl', l10n.components.editProfile.VALIDATION_INVALID, (value) => checkDomain(value, facebookLinkDomens)),
  websiteLink: string().url(l10n.components.error.LINK_ERROR),
});

export default schema;