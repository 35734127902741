import Tackle from '../../models/components/tackles/Tackle';
import {
  get, post, patch, remove
} from '../Http';

const url = '/api/Tackles/';

const generateError = () => [
  { name: 'Green', id: 1 },
  { name: 'Yellow', id: 2 },
  { name: 'Orange', id: 3 },
];

class TackleService {
  static getTackles = async (userId: string) => get(`/api/users/${userId}/tackles`, generateError);

  static postTackle = async (tackle: Tackle) => post(url, tackle);

  static deleteTackle = async (id: number) => remove(`${url}${id}`);

  static editTackle = async (id: number, tackle: Tackle) => patch(`${url}${id}`, tackle);
}

export default TackleService;