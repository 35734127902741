import React from 'react';
import { Grid } from '@material-ui/core';
import Ranking from '../../../../../../models/components/dictionaries/ranking/Ranking';
import DeleteRanking from '../modals/deleteRanking/wrapper/Wrapper';
import EditRanking from '../modals/editRanking/wrapper/Wrapper';

type onFunction = () => void;

const Management = (props: {
  ranking: Ranking,
  getRankings: onFunction
}) => {
  const { ranking, getRankings } = props;

  return (
    <Grid container justify="center">
      <EditRanking getRankings={getRankings} ranking={ranking} />
      <DeleteRanking id={ranking.id} getRankings={getRankings} />
    </Grid>
  );
};

export default Management;