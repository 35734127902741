import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  socialButtons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  shareButton: {
    width: 50,
  },
}));

export default useStyles;