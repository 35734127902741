/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './ClosestTournamentCard.styles';
import Date from '../../date/Date';
import Tags from '../../tags/Tags';
import { tournamentStatus } from '../../../utils/enum/Enum';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import ClosestTournamentCardData from '../../../models/pages/tournament/ClosestTournamentCardData';
import ClosestTournamentInfo from './info/Info';
import Routes from '../../../routing/Routes';
import { statusIdx } from '../../../utils/constants/Constants';

const defaultProps = {
  className: '',
};

const ClosestTournamentCard = (props: {
  tournament: ClosestTournamentCardData,
  className?: string
  getTournaments: EmptyVoidFunction
}) => {
  const classes = useStyles();
  const { tournament, className, getTournaments } = props;

  const [closestTournament, setClosestTournament] = useState<ClosestTournamentCardData>(tournament);

  useEffect(() => {
    setClosestTournament(tournament);
  }, [tournament]);

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.content}>
        <div className={classes.title}>
          <Date date={closestTournament?.date} />
          <div className={classes.titleInfo}>
            <Link to={Routes.TournamentCard.path.replace(':id', String(closestTournament.id))}>
              <h3 className={classes.titleText}>{closestTournament?.name}</h3>
            </Link>
            <div className={classes.titleTags}>
              <Tags
                variant={`event-${closestTournament ? statusIdx[closestTournament.statusId] : statusIdx[tournamentStatus.Open]}`}
                className={classes.titleTag}
              >
                {closestTournament && closestTournament?.status}
              </Tags>
            </div>
          </div>
        </div>
        <ClosestTournamentInfo closestTournament={closestTournament} getTournaments={getTournaments} />
      </div>
    </div>
  );
};

export default ClosestTournamentCard;

ClosestTournamentCard.defaultProps = defaultProps;