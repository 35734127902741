import React, { useState, useEffect } from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import UserService from '../../../../../services/main/User.service';
import Toasters from '../../../../../components/popUp/PopUp';
import useStyles from './EditRole.style';
import Loader from '../../../../../components/loader/Loader';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  isDisabled: false,
};

export default function EditRole(props: {
  userId: string;
  userRoles: string[];
  role: string;
  isDisabled?: boolean;
  getUsers: Function;
}) {
  const {
    userId, userRoles, role, isDisabled, getUsers
  } = props;
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  const l10n = useTranslation();

  const data = {
    role,
    id: userId,
  };

  useEffect(() => {
    setChecked(userRoles.includes(role));
  }, [userRoles, role]);

  const sendEditRole = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoader(true);
    const response = event.target.checked
      ? await UserService.addToRole(data)
      : await UserService.removeFromRole(data);
    if (response) {
      Toasters.success(l10n.components.toasterMessage.ROLE_HAS_BEEN_DELETED);
      getUsers();
    }
    setLoader(false);
  };
  if (loader) {
    return <Loader />;
  }
  return (
    <Grid className={classes.content}>
      <Checkbox checked={checked} onChange={sendEditRole} disabled={isDisabled} data-testid="edit-role" />
    </Grid>
  );
}

EditRole.defaultProps = defaultProps;