import {
  post,
} from '../Http';
import LoggedError from '../../models/service/LoggedError';

const url = '/api/Errors/';

const ErrorService = {

  async post(error: LoggedError) {
    return post(url, error);
  },
};

export default ErrorService;