import React, { useEffect, useRef } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField, Grid, Button, Select, MenuItem, FormControl, FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import schema from '../../helpers/ValidationSchema';
import useStyles from './AddLocation.styles';
import Location from '../../../../../../../models/components/dictionaries/location/Location';
import AreatroutMap from '../../../../../../../components/map/Map';
import Loader from '../../../../../../../components/loader/Loader';
import { EmptyVoidFunction } from '../../../../../../../utils/types/Types';
import { useStores } from '../../../../../../../stores/Main';
import Toasters from '../../../../../../../components/popUp/PopUp';
import EditableTextField from '../../../../../../../components/inputs/textField/Editable';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const AddLocationForm = observer((props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { LocationStore, CountriesStore } = useStores();
  const { handleClose } = props;
  const editor = useRef(null);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Location>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      countryId: null,
    },
  });
  const classes = useStyles();
  const l10n = useTranslation();

  const setLocation = async (data: Location) => {
    const response = await LocationStore.addLocation(data);
    if (response.id) {
      Toasters.success(l10n.components.addLocation.LOCATION_ADD_SUCCESS);
      LocationStore.refreshLocations();
    }
    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => {
    if (LocationStore.coordinates) {
      setValue('latitude', LocationStore.coordinates[0]);
      setValue('longitude', LocationStore.coordinates[1]);
    }
  }, [LocationStore.coordinates]);

  const setLocationDescription = (newComponent: string) => setValue('description', newComponent, { shouldDirty: true, shouldValidate: true });

  if (LocationStore.isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(setLocation)}
      className={classes.inputContainer}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.addLocation.LOCATION_ADD_NAME}
            variant="outlined"
            error={!!errors.name}
            helperText={errors?.name?.message}
            autoFocus
          />
        )}
        name="name"
        control={control}
        defaultValue=""
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            id="filled-multiline-flexible"
            label={l10n.components.editLocation.PHONE_NUMBER}
            helperText={errors?.phoneNumber?.message}
            error={!!errors.phoneNumber}
            rowsMax={1}
            variant="outlined"
          />
        )}
        name="phoneNumber"
        control={control}
        defaultValue=""
      />
      <FormControl
        variant="outlined"
        fullWidth
        className="formControl"
      >
        <Controller
          name="countryId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              onChange={field.onChange}
              value={field.value}
              error={!!errors.countryId}
              className={clsx('',
                {
                  [classes.invalid]: errors?.countryId,
                  '': !errors?.countryId,
                })}
            >
              <MenuItem
                key={0}
                value={0}
              >
                {l10n.components.addLocation.LOCATION_CHOOSE_COUNTRY}
              </MenuItem>
              {CountriesStore.countries?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText className={classes.invalid}>
          {!!errors.countryId && l10n.components.addLocation.LOCATION_REQUIRED}
        </FormHelperText>
      </FormControl>
      <EditableTextField
        ref={editor}
        errors={errors.description}
        convertedContent=""
        onBlur={setLocationDescription}
        label=""
        placeholder={l10n.components.addLocation.PLACEHOLDER_DESCRIPTION}
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.addLocation.LATTITUDE}
            variant="outlined"
            multiline
            disabled
          />
        )}
        name="latitude"
        control={control}
        defaultValue={LocationStore.coordinates ? LocationStore.coordinates[0] : 0}
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            label={l10n.components.addLocation.LONGITUDE}
            variant="outlined"
            multiline
            disabled
          />
        )}
        name="longitude"
        control={control}
        defaultValue={LocationStore.coordinates ? LocationStore.coordinates[1] : 0}
      />
      <div className={classes.map}>
        <AreatroutMap />
      </div>
      <Button
        data-testid="save-location"
        type="submit"
        variant="contained"
        color="primary"
        className={classes.saveButton}
      >
        {l10n.components.addLocation.LOCATION_ADD_SAVE}
      </Button>
    </form>
  );
});

export default AddLocationForm;