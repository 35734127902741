/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import FilterField from '../../../models/pages/availiableTournaments/FilterField';
import SelectItem from '../../../models/components/selects/SelectItem';
import Loader from '../../loader/Loader';
import DesktopSingleSelect from './components/Desktop';
import MobileSingleSelect from './components/Mobile';

const defaultProps = {
  disabled: false,
  className: '',
  showCustomMobileSelect: false,
  defaultValue: '',
  menuItemStyle: '',
  selectStyle: '',
};

function SingleSelect(props: {
  filterProps: FilterField
  onValueSelected: (key: string, value: string | number | null) => void,
  className?: string
  disabled?: boolean,
  showCustomMobileSelect?: boolean,
  defaultValue?: string | number
  menuItemStyle?: string
  selectStyle?: string
}) {
  const {
    disabled, className, filterProps, onValueSelected, showCustomMobileSelect, defaultValue, menuItemStyle, selectStyle
  } = props;

  const [menuItems, setMenuItems] = useState<SelectItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onItemSelected = (value: string | number | null) => {
    onValueSelected(filterProps.filterName, value);
  };

  const fetchMenuItems = async () => {
    setIsLoading(true);
    let response: SelectItem[] = filterProps.items || [];
    if (filterProps.getAvailableItems) {
      response = await filterProps.getAvailableItems() || [];
    }

    if (response) {
      setMenuItems(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    setMenuItems(filterProps.items || []);
  }, [filterProps.items]);

  return (
    <div className={className}>
      {isLoading ? <Loader /> : (
        <>
          <DesktopSingleSelect
            defaultValue={defaultValue}
            menuItems={menuItems}
            disabled={disabled || !menuItems?.length}
            label={filterProps.label}
            onItemSelected={onItemSelected}
            bindValue="id"
            className={clsx({ mobileSelect: !showCustomMobileSelect })}
            menuItemStyle={menuItemStyle}
            selectStyle={selectStyle}
          />
          {showCustomMobileSelect && (
            <MobileSingleSelect
              menuItems={menuItems}
              disabled={disabled || !menuItems?.length}
              label={filterProps.label}
              onItemSelected={onItemSelected}
              bindValue="id"
              menuItemStyle={menuItemStyle}
            />
          )}
        </>
      )}
    </div>
  );
}

SingleSelect.defaultProps = defaultProps;

export default SingleSelect;