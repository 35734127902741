import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useStores } from '../../../../../../../stores/Main';
import useStyles from '../../Sponsors.style';
import Sponsor from '../../../../../../../models/components/dictionaries/sponsor/Sponsor';

function EditDelete(props: {
  sponsor: Sponsor,
  setIsEditModalOpen: any
  setIsDeleteModalOpen: any
}) {
  const {
    sponsor, setIsEditModalOpen, setIsDeleteModalOpen
  } = props;
  const classes = useStyles();
  const { SponsorsStore } = useStores();

  const openDelModal = (sponsorId: number): void => {
    SponsorsStore.setId(sponsorId);
    setIsDeleteModalOpen(true);
  };

  const openEditModal = async (sponsorId: number) => {
    const res = await SponsorsStore.getSponsorsById(sponsorId);
    if (res) setIsEditModalOpen(true);
  };

  return (
    <>
      {sponsor && (
      <ListItem className={classes.buttonCell}>
        <IconButton
          edge="start"
          aria-label="edit"
          onClick={() => openEditModal(sponsor.id)}
        >
          <Edit />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => openDelModal(sponsor.id)}
        >
          <Delete />
        </IconButton>
      </ListItem>
      )}
    </>
  );
}

export default EditDelete;