import React from 'react';
import useStyles from '../LocationsTable.styles';
import LocationData from '../../../../../../../models/components/dictionaries/location/LocationData';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';

const Country = (props: {
    data: LocationData | undefined,
}) => {
  const classes = useStyles();
  const l10n = useTranslation();
  const { data } = props;

  return (
    <p className={classes.tableCell}>
      {data?.country?.name || l10n.components.countryCell.NOT_AVAILABLE}
    </p>
  );
};

export default Country;