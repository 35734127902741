/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import AreatroutTable from '../../../../../../../components/table/Table';
import useStyles from '../../Sponsors.style';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';
import Sponsor, { SelectedSponsor } from '../../../../../../../models/components/dictionaries/sponsor/Sponsor';
import { getImageUrl } from '../../../../../../../utils/Utils';

export default function EditSponsorsTable(props: {
    sponsorsList: Sponsor[] | null,
    selectedMainSponsor: string,
    setSelectedMainSponsor: React.Dispatch<React.SetStateAction<string>>,
    selectedTournamentSponsors: SelectedSponsor[],
    setSelectedTournamentSponsors: React.Dispatch<React.SetStateAction<SelectedSponsor[]>>,
}) {
  const {
    sponsorsList, selectedMainSponsor, setSelectedMainSponsor, selectedTournamentSponsors, setSelectedTournamentSponsors
  } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  const handleChange = (event: any) => {
    setSelectedMainSponsor(event.target.value);
  };

  const findSponsorByName = (sponsor: SelectedSponsor) => selectedTournamentSponsors.find((el: Sponsor) => el.name === sponsor.name);

  const handleChangeCheckbox = (event: any, sponsor: SelectedSponsor) => {
    sponsor.isSelected = event.target.checked;
    if (selectedMainSponsor === sponsor.name) setSelectedMainSponsor('');
    const tempSec = selectedTournamentSponsors.filter((el: SelectedSponsor) => el.name !== sponsor.name);
    setSelectedTournamentSponsors(() => ([...tempSec, sponsor]));
  };

  const columns = [
    {
      displayName: l10n.components.sponsors.SPONSOR_NAME,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.sponsors.SPONSORS_LOGO,
      fieldName: 'logo',
      render: ({ logo }: Sponsor) => (
        <img src={getImageUrl(logo!.thumbnailImageUrl)} alt={l10n.components.sponsors.SPONSORS_LOGO} className={classes.logo} />
      )
    },
    {
      displayName: l10n.components.sponsors.SPONSOR_URL,
      fieldName: 'url',
      render: ({ url }: Sponsor) => (
        <a className={classes.link} href={url}>{url}</a>
      )
    },
    {
      displayName: l10n.components.sponsors.MAIN_SPONSOR,
      fieldName: 'generalSponsor',
      render: (sponsor: Sponsor) => (
        <Radio
          checked={!!selectedTournamentSponsors.length && !findSponsorByName(sponsor)?.isSelected && selectedMainSponsor === sponsor.name}
          disabled={!!selectedTournamentSponsors.length && findSponsorByName(sponsor)?.isSelected}
          color="primary"
          onChange={handleChange}
          value={sponsor.name}
          name="radio-buttons"
          inputProps={{ 'aria-label': sponsor.name }}
          className={classes.radioBtn}
        />
      )
    },
    {
      displayName: l10n.components.sponsors.TOURNAMENT_SPONSOR,
      fieldName: 'tournamentSponsor',
      render: (sponsor: Sponsor) => (
        <Checkbox
          checked={selectedTournamentSponsors ? findSponsorByName(sponsor)?.isSelected : false}
          onChange={(evt) => handleChangeCheckbox(evt, sponsor)}
        />
      )
    }
  ];
  return (
    <>
      {sponsorsList && sponsorsList.length
        ? <AreatroutTable columns={columns} rows={sponsorsList} className={classes.fullWidth} isPaging={false} />
        : <p>{l10n.components.sponsors.SPONSORS_LIST_IS_EMPTY}</p>}
    </>
  );
}