import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  seasonsDefaultText: {
    textAlign: 'center',
    fontSize: 18,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 720,
    border: '1px solid black',
    borderRadius: 5,
    padding: 15,
    '& .b-seasons__title': {
      textAlign: 'center',
      marginBottom: 20,
    },

    '& button': {
      alignSelf: 'flex-start',
      marginBottom: 10,
    },
    '& .MuiListItemText-secondary': {
      wordBreak: 'break-all',
      marginRight: 50,
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px 20px',
  },
  contentInput: {
    marginBottom: 20,
  },
  wrapperInput: {
    marginBottom: 20,
    marginTop: 20,
  },
  contentTitle: {
    marginBottom: 25,
    textAlign: 'center',
    fontSize: 16,
  },
  loadingWindow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30vh',
  },
  buttonCell: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableWrapper: {
    maxWidth: 800,
    margin: '0 10px'
  },
  button: {
    marginBottom: 20,
    marginLeft: 10
  },
  saveButton: {
    margin: '0 auto'
  },
  buttonsWrapper: {
    padding: 10
  },
  invalid: {
    color: 'red',
  },
}));

export default useStyles;