import {
  remove, get, post, patch, processError
} from '../../Http';
import Error from '../../../models/service/Error';
import Sponsor from '../../../models/components/dictionaries/sponsor/Sponsor';
import { getTranslation } from '../../../components/localization/customHooks/Translation';

const url = '/api/sponsors';
const l10n = getTranslation();

const generateError = (error: Error) => {
  if (error.response.status === 400) {
    return processError(error, l10n.components.error.REACHED_MAX_PARTICIPANTS_ERROR);
  }
  processError(error);
  return null;
};

const SponsorsService = {
  async getAll() {
    return get(url);
  },

  async get(id: number) {
    return get(`${url}/${id}`);
  },

  async delete(id: number) {
    return remove(`${url}/${id}`);
  },

  async post(sponsor: Sponsor) {
    return post(url, sponsor);
  },

  async update(sponsor: Sponsor) {
    const { id } = sponsor;
    return patch(`${url}/${id}`, sponsor, generateError);
  },
};

export default SponsorsService;