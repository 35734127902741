import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import RankingService from '../../../../../services/main/dictionaries/Ranking.service';
import Ranking from '../../../../../models/components/dictionaries/ranking/Ranking';
import useStyles from './Rankings.styles';
import AreatroutModal from '../../../../../components/modal/Modal';
import AddRankingModal from './modals/addRanking/AddRanking';
import ManagementCell from './cell/Management';
import AreatroutTable from '../../../../../components/table';
import LoaderFullScreen from '../../../../../components/loader/fullScreen/FullScreen';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Rankings = () => {
  const classes = useStyles();
  const l10n = useTranslation();
  const [openAdd, setOpenAdd] = useState(false);
  const [rankings, setRankings] = useState<Ranking[] | null>([]);
  const [isloading, setIsLoading] = useState(false);

  const getAllRankings = async () => {
    setIsLoading(true);
    const res = await RankingService.getAll();
    if (res) {
      setRankings(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllRankings();
  }, []);

  const columns = [
    {
      displayName: l10n.components.rangkings.NAME_RANKING,
      fieldName: 'name',
    },
    {
      displayName: l10n.components.rangkings.DESCRIPTION_RANKING,
      fieldName: 'description',
    },
    {
      displayName: l10n.components.rangkings.CONTROL_RANKING,
      fieldName: 'management',
      allowSortring: false,
      allowSearch: false,
      render: (ranking: Ranking) => <ManagementCell getRankings={getAllRankings} ranking={ranking} />,
    },
  ];

  const handleAdd = () => {
    setOpenAdd(true);
  };

  if (isloading) {
    return <LoaderFullScreen />;
  }
  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Button
        data-testid="add-button"
        className={classes.addButton}
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={handleAdd}
      >
        {l10n.components.rangkings.ADD_RANKING_BUTTON}
      </Button>
      <AreatroutModal
        header={l10n.components.rangkings.ADD_RANKING_MODAL}
        open={openAdd}
        setOpen={setOpenAdd}
        onClose={getAllRankings}
      >
        <AddRankingModal />
      </AreatroutModal>
      <AreatroutTable columns={columns} rows={rankings} />
    </Grid>
  );
};

export default Rankings;