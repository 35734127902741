import React, { useState } from 'react';
import {
  Button, DialogActions, DialogTitle, Grid,
} from '@material-ui/core';
import UserService from '../../../../../../services/main/User.service';
import Toasters from '../../../../../../components/popUp/PopUp';
import Loader from '../../../../../../components/loader/Loader';
import useStyles from '../DeleteUser.style';
import { EmptyVoidFunction } from '../../../../../../utils/types/Types';
import useTranslation from '../../../../../../components/localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

function DeleteUserConfirm(props: {
  handleClose?: EmptyVoidFunction;
  id: string;
  getUsers: EmptyVoidFunction;
}) {
  const { handleClose, id, getUsers } = props;
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  const l10n = useTranslation();

  const deleteUser = async () => {
    setLoader(true);
    const response = await UserService.delete(id);
    if (response) {
      Toasters.success(l10n.components.toasterMessage.USER_HAS_BEEN_DELETED);
    }
    if (handleClose) {
      handleClose();
    }
    getUsers();
    setLoader(false);
  };

  if (loader) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <DialogTitle>
        {l10n.components.confirmDialogsMessage.DELETE_USER}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {l10n.components.button.CANCEL}
        </Button>
        <Button onClick={deleteUser} color="primary" autoFocus>
          {l10n.components.button.DELETE}
        </Button>
      </DialogActions>
    </>
  );
}

export default DeleteUserConfirm;

DeleteUserConfirm.defaultProps = defaultProps;