import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import useStyles from './News.styles';
import useTranslation from '../../components/localization/customHooks/Translation';
import { useStores } from '../../stores/Main';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import NewsFilterBar from './components/filtersBar/FilterBar';
import MetaDescription from '../../components/metaDescription/MetaDescription';
import Loader from '../../components/loader/Loader';
import ArticlesLinks from '../../components/article/links/Links';

const News = observer(() => {
  const classes = useStyles();
  const l10n = useTranslation();

  const { NewsStore } = useStores();
  const urlName = [
    { displayName: l10n.components.locationCard.MAIN_PAGE, url: '/' },
    { displayName: l10n.pages.NEWS, url: '' }
  ];

  useEffect(() => {
    NewsStore.initialize();
  }, []);

  return (
    <div className={classes.mainWrapper}>
      <div>
        <MetaDescription title={l10n.pages.NEWS} />
        <Breadcrumbs urls={urlName} className={classes.breadCrumbs} />
        <h2 className={classes.heading}>{l10n.pages.NEWS}</h2>
        <div className={classes.filterBar}>
          <NewsFilterBar />
        </div>
      </div>
      <div>
        <InfiniteScroll
          initialLoad={false}
          loadMore={NewsStore.getNews}
          hasMore={NewsStore.news.items.length < NewsStore.news.total}
          loader={<Loader />}
          className={classes.infinityScroll}
        >
          <Grid className={classes.gridContainer} container spacing={2}>
            {NewsStore.filteredNews
              .map((article: any) => (
                <Grid className={classes.article} key={String(article.type) + article.id} item lg={4} md={5} sm={6} xs={11}>
                  <div className={classes.element}>
                    <ArticlesLinks article={article} />
                  </div>
                </Grid>
              ))}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>
  );
});

export default News;