import {
  makeAutoObservable,
  reaction,
  runInAction
} from 'mobx';
import Tackle from '../../models/components/tackles/Tackle';
import TackleService from '../../services/main/Tackle.service';
import { EmptyVoidFunction } from '../../utils/types/Types';

export default class TackleStore {
  emptyTackleData: Tackle = {
    spinning: '',
    reel: '',
    wire: '',
    id: 0,
    ownerId: '',
  };

  tackles: Tackle[] | null = null;

  userTackle: Tackle = { ...this.emptyTackleData };

  mainStore: any;

  constructor(mainStore: any) {
    this.mainStore = mainStore;

    makeAutoObservable(this);

    reaction(() => this.mainStore.UserStore.userId, () => {
      this.refresh();
    });
  }

  getTackles = async (id: string) => {
    const response = await TackleService.getTackles(id);
    if (response) {
      runInAction(() => {
        this.tackles = response;
      });
    }
    return this.tackles;
  };

  refresh: EmptyVoidFunction = async () => {
    this.getTackles(this.mainStore.UserStore.userId);
  };

  addTackle = async (data: Tackle) => {
    const response = await TackleService.postTackle(data);
    this.refresh();
    return response;
  };

  editTackle = async (data: Tackle) => {
    if (data.id && this.userTackle) {
      const response = await TackleService.editTackle(data.id, data);
      this.refresh();
      return response;
    }
    return null;
  };

  deleteTackle = async (tackle: Tackle) => {
    if (tackle.id) {
      const response = await TackleService.deleteTackle(tackle.id);
      if (response) {
        this.refresh();
      }
      return response;
    }
    return null;
  };

  setTackle = (tackle: Tackle) => {
    this.userTackle = tackle;
  }

  resetTackle = () => {
    this.userTackle = { ...this.emptyTackleData };
  }
}