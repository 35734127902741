import {
  object, string
} from 'yup';

import { getTranslation } from '../../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  email: string().required(l10n.components.recoveryPassword.REQUIRED_EMAIL).email(l10n.components.recoveryPassword.INVALID_EMAIL)
});

export default schema;