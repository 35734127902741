import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    boxSizing: 'border-box',
    maxWidth: 1360,
    width: '100%',
    margin: '0 auto',
  },
  breadCrumbs: {
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
  },
  imgCarousel: {
    objectFit: 'contain',
    maxWidth: '85vw',
    maxHeight: '85vh',
  },
  results: {
    marginTop: 48
  },
  photoGallery: {
    '& h2': {
      marginTop: 72,
      marginBottom: 32
    },
    [theme.breakpoints.down('md')]: {
      padding: 16
    }
  }
}));

export default useStyles;