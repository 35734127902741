import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../ChangePassword.styles';
import ATButton from '../../../button/Button';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import useTranslation from '../../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const ChangePasswordResult = (props: {
  handleClose?: EmptyVoidFunction;
}) => {
  const { handleClose } = props;
  const styles = useStyles();
  const l10n = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.checkmarkBox}>
          <b className={styles.checkmarkSuccess}>✓</b>
        </div>
        <Typography className={styles.title}>{l10n.components.authorization.TITLE_PASSWORD_CHANGED}</Typography>
        <Typography className={styles.text}>
          {l10n.components.authorization.DESCRIPTION_NEW_PASSWORD}
        </Typography>
        <ATButton
          variant="primary"
          fill="solid"
          size="lg"
          className={styles.button}
          onClick={handleClose && handleClose}
        >
          {l10n.components.authorization.BUTTON_DESCRIPTION_NEW_PASSWORD}
        </ATButton>
      </div>
    </div>
  );
};

ChangePasswordResult.defaultProps = defaultProps;

export default ChangePasswordResult;