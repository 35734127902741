import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import TournamentService from '../../../services/main/Tournament.service';
import Toasters from '../../popUp/PopUp';
import Loader from '../../loader/Loader';
import useStyles from '../RegistrationButton.styles';
import { statusUserRegistration } from '../../../utils/enum/Enum';
import { EmptyVoidFunction } from '../../../utils/types/Types';
import ATButton from '../../button/Button';
import TeamRegistrationModal from './TeamRegistration';
import useTranslation from '../../localization/customHooks/Translation';

const defaultProps = {
  handleClose: () => { },
};

const Registration = (props: {
  isTeamRanking: boolean | undefined;
  modalMessage: string;
  tournamentId: number | undefined;
  handleClose?: EmptyVoidFunction;
  isRegistered: boolean;
  isParticipantInWaitingList: boolean;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    tournamentId,
    isRegistered,
    isParticipantInWaitingList,
    handleClose,
    setIsRegistered,
    modalMessage,
    isTeamRanking
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const l10n = useTranslation();

  const showRegistrationStatus = (status: number) => {
    Toasters.success(
      status === statusUserRegistration.isInWaitingList
        ? l10n.components.registrationModal.ADDED_TO_THE_WAITING_LIST
        : l10n.components.registrationModal.REGISTERED_SUCCESSFULLY,
    );
  };

  const showCancelRegistrationStatus = () => {
    Toasters.success(
      isParticipantInWaitingList
        ? l10n.components.registrationModal.LEAVE_WAITING_LIST
        : l10n.components.registrationModal.CANCELLED_REGISTRATION,
    );
  };

  const sendRegistrationRequest = async () => {
    setIsLoading(true);
    if (tournamentId) {
      const response = await TournamentService.registerUserOnTournament(
        tournamentId,
      );
      if (response) {
        showRegistrationStatus(response.status);
        setIsRegistered(true);
      }
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(false);
  };

  const cancelRegistrationRequest = async () => {
    setIsLoading(true);
    if (tournamentId) {
      const response = await TournamentService.cancelRegistration(tournamentId);
      if (response) {
        showCancelRegistrationStatus();
        setIsRegistered(false);
      }
    }
    if (handleClose) {
      handleClose();
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWindow}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      {isTeamRanking && !isRegistered
        ? (
          <TeamRegistrationModal
            tournamentId={tournamentId}
            setIsRegistered={setIsRegistered}
            handleClose={handleClose}
          />
        )
        : (
          <>
            <Box className={classes.modalWindow}>
              {modalMessage}
            </Box>
            <Box m={1} p={1} display="flex" justifyContent="center">
              <ATButton
                variant="primary"
                fill="solid"
                size="md"
                onClick={
                  isRegistered ? cancelRegistrationRequest : sendRegistrationRequest
                }
              >
                {l10n.components.registrationModal.CONTINUE}
              </ATButton>
            </Box>
          </>
        )}
    </>
  );
};

export default Registration;

Registration.defaultProps = defaultProps;