export enum tournamentStatus {
  Planning = 0,
  Open = 1,
  Close = 2,
  Completed = 3,
  Canceled = 4,
}

export enum statusUserRegistration {
  Registered = 0,
  isInWaitingList = 1,
  Canceled = 2,
}

export enum sortingType {
  desc = 'desc',
  asc = 'asc',
}

export enum playoffIcons {
  goldCup = 0,
  silverCup = 1,
  bronzeCup = 2,
  star = 3
}

export enum stages {
  quarterFinals = 0,
  semiFinals = 3,
  firstPlace = 1,
  thirdPlace = 2,
}

export enum pairs {
  quarterFinals = 4,
  semiFinals = 2,
  firstPlace = 1,
  thirdPlace = 1
}

export enum selectType {
  multiple = 'multiple',
  single = 'single'
}

export enum emailType {
  password = 'password',
  email = 'email'
}

export enum teamRegistarationStatus {
  pending = 0,
  approved = 1,
  invitationRejected = 2,
  disbanded = 3
}

export enum articles {
  review = 2,
  announcement = 0,
  article = 1,
  all = 3,
}

export enum reportStatus {
  draft = 0,
  Published = 1,
  Hidden = 2,
}

export enum limitsForWeatherApi {
  limitForForecast = 7,
  limitForHistoricalWeather = -5,
}
