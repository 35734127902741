import {
  useMediaQuery, useTheme
} from '@material-ui/core';
import React, { SetStateAction } from 'react';
import Report from '../../../../../../models/components/report/Report';

import { StorageService } from '../../../../../../utils/Utils';

import DesktopCard from './components/desktop/Desktop';
import MobileCard from './components/mobile/Mobile';

const ReportCard = (props: {
  report: Report,
  anglerId: string,
  setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>,
  setIsDeleteModalOpened: React.Dispatch<SetStateAction<boolean>>,
  }) => {
  const {
    report, setIsEditModalOpened, setIsDeleteModalOpened, anglerId
  } = props;

  const currentUser = StorageService.getUserData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (!isMobile ? (
    <DesktopCard
      setIsDeleteModalOpened={setIsDeleteModalOpened}
      setIsEditModalOpened={setIsEditModalOpened}
      report={report}
      userInfo={currentUser}
      anglerId={anglerId}
    />
  ) : (
    <MobileCard
      setIsDeleteModalOpened={setIsDeleteModalOpened}
      setIsEditModalOpened={setIsEditModalOpened}
      report={report}
      userInfo={currentUser}
      anglerId={anglerId}
    />
  )
  );
};

export default ReportCard;