import React from 'react';
import { observer } from 'mobx-react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Routes from '../../../../../../../routing/Routes';
import useStyles from '../LocationsTable.styles';
import { useStores } from '../../../../../../../stores/Main';

const EditDelete = observer((props: {
    id: number
}) => {
  const { id } = props;
  const classes = useStyles();
  const { LocationStore } = useStores();

  const openDelModal = (locationId: number): void => {
    LocationStore.setId(locationId);
    LocationStore.toggleDeleteLocationModalState();
  };

  return (
    <ListItem className={classes.buttonCell}>
      <Link to={Routes.EditLocation.path.replace(':id', String(id))}>
        <IconButton
          edge="start"
          aria-label="edit"
        >
          <Edit />
        </IconButton>
      </Link>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => openDelModal(id)}
        data-testid="delete-button"
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
});

export default EditDelete;