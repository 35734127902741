import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Divider } from '@material-ui/core';
import Tooltip from '../Tooltip';
import useStyles from '../Tooltip.style';
import Member from '../../member/Member';
import { TeamMember } from '../../../models/pages/tournament/playoff/publication/PublishedTournament';
import PartnerFields from '../../../models/pages/team/PartnerFields';
import TeamsService from '../../../services/main/Team.service';
import Loader from '../../loader/Loader';

function Participants(props: {
  children: JSX.Element
  teamId: string
}) {
  const { children, teamId } = props;
  const classes = useStyles();

  const [teamMembers, setTeamMembers] = useState<(TeamMember | PartnerFields)[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTeamMembers = async () => {
    if (teamMembers.length === 0) {
      setLoading(true);
      const data = await TeamsService.getTeamByUserId(teamId);
      setTeamMembers(data);
      setLoading(false);
    }
  };

  const handleTooltipShow = () => {
    fetchTeamMembers();
  };

  return (
    <Tooltip
      index={uuidv4()}
      onShow={handleTooltipShow}
      content={(
        loading ? (
          <Loader />
        ) : (
          <div className={classes.wrapper}>
            {teamMembers?.map((member: TeamMember | PartnerFields, index: number) => (
              <div
                key={uuidv4()}
                className={classes.member}
              >
                <Member profilePicture={member.profilePicture} fullName={member.name} />
                {index !== Number(teamMembers?.length) - 1 && <Divider className={classes.divider} />}
              </div>
            ))}
          </div>
        )
      )}
      container={React.cloneElement(children)}
    />
  );
}

export default Participants;