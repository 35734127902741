import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/Variables';

const useStyles = makeStyles(() => createStyles({
  place: {
    '& span': {
      fontWeight: 600,
      lineHeight: '24px',
      color: colors.secondary
    }
  },
  icon: {
    flex: '0 0 34px'
  },
}));

export default useStyles;