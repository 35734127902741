import React from 'react';
import { Grid } from '@material-ui/core';
import useTranslation from '../../components/localization/customHooks/Translation';

export default function Admin() {
  const l10n = useTranslation();
  return (
    <Grid>
      <h2>{l10n.pages.PROJECT_MANAGEMENT}</h2>
    </Grid>
  );
}