import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 432,
    width: '100%',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    color: colors.secondary,
    boxSizing: 'border-box',
    border: '1px solid #E2E4F0',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      margin: 16
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 328,
    },
  },
  content: {
    padding: 24,
    width: '85%'
  },
  title: {
    display: 'flex',
    marginBottom: 20
  },
  titleInfo: {
    marginLeft: 15,
    width: '90%'
  },
  titleText: {
    margin: '0 0 8px 0',
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    inlineSize: '100%',
    overflowWrap: 'break-word',
  },
  titleTags: {
    fontSize: 0
  },
  titleTag: {
    marginRight: 12
  },
  weatherWrapper: {
    display: 'flex',
  },
}));

export default useStyles;