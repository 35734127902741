import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import useStyles from './AnglerRating.styles';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import AreatroutTable from '../../components/table';
import RatingResult from '../../models/pages/anglerRating/RatingResult';
import RatingService from '../../services/main/dictionaries/Rating.service';
import { getBestTournaments } from './AnglersRating.utils';
import AnglerRatingResult from '../../models/pages/anglerRating/AnglerRatingResult';
import TournamentRating from '../../models/pages/tournament/TournamentRating';
import TableHeader from './components/tableHeader/TableHeader';
import AvailbleRatingFilters from './helpers/Constants';
import SingleSelect from '../../components/selects/single/Single';
import SelectField from '../../models/pages/availiableTournaments/FilterField';
import AnglerRatingFiltersModel from '../../models/pages/tournament/AnglerRatingFiltersModel';
import RatingSelectItem from '../../models/components/selects/RatingSelectItem';
import CountryService from '../../services/main/dictionaries/Country.service';
import SelectItem from '../../models/components/selects/SelectItem';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Modal from '../../components/modal/Modal';
import Angler from '../../components/angler/Angler';
import useTranslation from '../../components/localization/customHooks/Translation';
import SearchInput from '../../components/searchInput/SearchInput';
import { limitCharsFullNameSearch } from '../../utils/constants/Constants';

const AnglersRating = () => {
  const classes = useStyles();
  const l10n = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const [filters, setFilters] = useState(AvailbleRatingFilters);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anglersRating, setAnglersRating] = useState<AnglerRatingResult[] | null>(null);
  const [appliedTournaments, setAppliedTournaments] = useState<TournamentRating[]>([]);
  const [ratings, setRatings] = useState<RatingSelectItem[]>([]);
  const [countries, setCountries] = useState<SelectItem[]>([]);
  const [selectedAnglersFilters, setSelectedAnglersFilters] = useState<AnglerRatingFiltersModel>({ seasonId: null, countryId: null, ratingId: null });
  const [bestTournamentsCount, setBestTournamentsCount] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const urlName = [
    { displayName: l10n.pages.MAIN_PAGE, url: '/' },
    { displayName: l10n.pages.SPORTSMAN_RATINGS, url: '/rating' },
  ];

  const createMarkup = (html: any) => ({ __html: DOMPurify.sanitize(html) });

  const getAnglersRatingResults = async () => {
    setIsLoading(true);
    if (selectedAnglersFilters.seasonId && selectedAnglersFilters.ratingId && ratings.length) {
      const searchResult: RatingResult = await RatingService.getAnglersRating(selectedAnglersFilters, searchText.trim());
      if (searchResult) {
        const anglerRatings = getBestTournaments(searchResult.anglerRatingResults, bestTournamentsCount);
        if (searchText) {
          anglerRatings.sort((a, b) => a.place - b.place);
        }
        setAnglersRating(anglerRatings);
        setAppliedTournaments(searchResult.tournaments);
      }
    }
    setIsLoading(false);
  };

  const getRatingColumn = (tournament: TournamentRating) => ({
    displayName: l10n.components.tableColumns.POINT,
    fieldName: 'tournamentRating',
    allowSearch: false,
    render: ({ tournamentResults }: AnglerRatingResult) => {
      const result = tournamentResults.find((item) => tournament.tournamentId === item.tournamentId);
      return (
        <div className={clsx(
          { [classes.filled]: result?.hasBestResult },
          classes.cell,
        )}
        >
          {Number.isFinite(result?.points) ? result?.points : '-'}
        </div>
      );
    }
  });
  const getPlaceColumn = (tournament: TournamentRating) => ({
    displayName: l10n.components.tableColumns.PLACE,
    fieldName: 'tournamentPlace',
    allowSearch: false,
    render: ({ tournamentResults }: AnglerRatingResult) => {
      const tournamentResult = tournamentResults.find((item) => tournament.tournamentId === item.tournamentId);
      let value: string = '-';
      if (tournamentResult) {
        value = String(tournamentResult.place);

        if (tournamentResult.nationalPlace && tournamentResult.place !== tournamentResult.nationalPlace) {
          value += `(${tournamentResult.nationalPlace})`;
        }
      }

      return (
        <div className={clsx(
          { [classes.filled]: tournamentResult?.hasBestResult },
          classes.tableBorder,
          classes.cell
        )}
        >
          {value}
        </div>
      );
    }
  });

  const columns = [
    {
      displayName: l10n.components.tableColumns.PLACE,
      fieldName: 'seasonPlace',
      allowSearch: false,
    },
    {
      displayName: l10n.components.tableColumns.SPORTSMAN,
      fieldName: 'anglerFullName',
      render: ({
        anglerProfilePicture, anglerFullName, anglerId, isTeam, anglerCountry
      }: AnglerRatingResult) => (
        <Angler
          anglerData={{
            id: anglerId,
            profilePicture: anglerProfilePicture,
            fullName: anglerFullName,
            country: anglerCountry
          }}
          className={classes.angler}
          isTeam={isTeam}
        />
      )
    },
    ...appliedTournaments.map((tournament) => [getPlaceColumn(tournament), getRatingColumn(tournament)]).flatMap((x) => x),
    {
      displayName: l10n.components.tableColumns.PLACES_SUM,
      fieldName: 'sumPlaces',
      allowSearch: false,
      render: ({ sumPlaces }: AnglerRatingResult) => (
        <div className={clsx(classes.tableBorder, classes.cell, classes.boldText)}>{sumPlaces}</div>
      )
    },
    {
      displayName: l10n.components.tableColumns.POINTS_SUM,
      fieldName: 'finalRatingPoints',
      render: ({ finalRatingPoints }: AnglerRatingResult) => (
        <div className={classes.boldText}>{finalRatingPoints}</div>
      )
    },
  ];

  const updateRatings = () => {
    const ratingsFilterIndex = 2;
    const items = ratings.filter((rating) => rating.countryId === selectedAnglersFilters.countryId);
    filters[ratingsFilterIndex].items = items;
    setFilters([...filters]);
  };

  const updateCountries = () => {
    const countriesFilterIndex = 0;
    const items = countries.filter((country) => ratings.some((rating) => rating.countryId === country.id));
    filters[countriesFilterIndex].items = items;
    setFilters([...filters]);
  };

  const getAllAvailableRatings = async () => {
    if (!ratings.length) {
      const response = await RatingService.getAsSelectItems();
      if (response) {
        setRatings(response);
      }
    }
  };

  const getAllAvailableCountries = async () => {
    if (!countries.length) {
      const response = await CountryService.getAsSelectItems();
      if (response) {
        setCountries(response);
      }
    }
  };

  const onFilterSelected = (filterName: string, value: string | number | null) => {
    setSelectedAnglersFilters({ ...selectedAnglersFilters, [filterName]: value });
  };

  useEffect(() => {
    if (selectedAnglersFilters.seasonId) {
      setIsLoading(true);
      Promise.all([getAllAvailableCountries(), getAllAvailableRatings(), getAnglersRatingResults()]).then(() => setIsLoading(false));
    }
  }, [selectedAnglersFilters.seasonId]);

  useEffect(() => {
    if (selectedAnglersFilters.countryId && ratings.length) {
      updateRatings();
    }
  }, [ratings, selectedAnglersFilters.countryId]);

  useEffect(() => {
    if (countries.length && ratings.length) {
      updateCountries();
    }
  }, [countries, ratings]);

  useEffect(() => {
    getAnglersRatingResults();
  }, [bestTournamentsCount]);

  useEffect(() => {
    if (selectedAnglersFilters.countryId) {
      updateRatings();
    }
  }, [selectedAnglersFilters.countryId]);

  useEffect(() => {
    if (ratings.length && selectedAnglersFilters.ratingId) {
      const countingTournamentsNumber = ratings.find((rating) => rating.id === selectedAnglersFilters.ratingId)?.countingTournamentsNumber;
      setBestTournamentsCount(countingTournamentsNumber || null);
      if (countingTournamentsNumber === bestTournamentsCount) {
        getAnglersRatingResults();
      }
    }
  }, [selectedAnglersFilters.ratingId, ratings]);

  useEffect(() => {
    if (anglersRating) {
      getAnglersRatingResults();
    }
  }, [searchText]);

  const getRatingDescription = () => ratings.find((rating) => rating.id === selectedAnglersFilters.ratingId)?.description;

  return (
    <Grid className={classes.container}>
      <Breadcrumbs urls={urlName} className={classes.breadcrumbs} />
      <h2 className={classes.title}>{l10n.pages.SPORTSMAN_RATINGS}</h2>
      <SearchInput
        placeholder={l10n.pages.SEARCH_BY_FULLNAME}
        onChange={setSearchText}
        className={classes.search}
        maxLength={limitCharsFullNameSearch}
      />
      <div className={classes.selectContainer}>
        {filters.map((filter: SelectField) => (
          <SingleSelect
            key={filter.filterName}
            filterProps={filter}
            onValueSelected={onFilterSelected}
            className={classes.select}
          />
        ))}
        <button
          type="button"
          onClick={() => setOpen(true)}
          className={classes.button}
        >
          {l10n.components.button.RATINGS_DESCRIPTION}
        </button>
      </div>
      {isLoading
        ? <LoaderFullScreen />
        : anglersRating && (
          <AreatroutTable
            className={classes.table}
            columns={columns}
            rows={anglersRating}
            isPaging={false}
            maxBodyHeight="calc(100vh - 340px)"
            header={<TableHeader tournaments={appliedTournaments} />}
          />
        )}
      <Modal
        header=""
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        modalClassName={classes.modal}
      >
        <div className={classes.wrapper}>
          <Typography
            component="h3"
            align="center"
            className={classes.descriptionTitle}
          >
            {l10n.components.button.RATINGS_DESCRIPTION}
          </Typography>
          <div className={classes.descriptionText}>
            {// eslint-disable-next-line
              < div dangerouslySetInnerHTML={createMarkup(getRatingDescription())} />
            }
          </div>
        </div>
      </Modal>
    </Grid>
  );
};

export default AnglersRating;