import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../../styles/Variables';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    padding: '16px 48px 48px',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  },
  checkMark: {
    fontSize: 0,
    marginBottom: 16
  },
  title: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: colors.secondary,
    textAlign: 'center',
    marginBottom: 20
  },
  description: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 16,
    lineHeight: '24px',
    color: colors.secondary,
    width: '100%',
    maxWidth: 564,
    letterSpacing: 0,
    marginBottom: 32,
    textAlign: 'justify'
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 75px)',
    },
  }
}));

export default useStyles;