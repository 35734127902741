/* eslint-disable max-len */
import React from 'react';
import AirIcon from '@mui/icons-material/Air';
import useStyles from '../closestTournamentCard/ClosestTournamentCard.styles';
import WeatherData from '../../../models/pages/tournament/WeatherData';
import useTranslation from '../../localization/customHooks/Translation';

const Weather = (props: {
  weatherData: WeatherData
}) => {
  const { weatherData } = props;
  const classes = useStyles();
  const l10n = useTranslation();

  return (
    <>
      <div className={classes.weatherWrapper}>
        <img className={classes.weatherImg} src={`http://openweathermap.org/img/wn/${weatherData.icon}@2x.png`} alt="weather" />
        <div className={classes.weatherDescription}>{weatherData.translatedDescription}</div>
        <div className={classes.temperatureWrapper}>
          <div className={classes.timeTemperature}>
            <div>{l10n.components.weather.MORNING}</div>
            <div className={classes.weatherTemperature}>
              {Math.round(weatherData.airTemperatureMorning)}
              &#176;
            </div>
          </div>
          <div className={classes.timeTemperature}>
            <div>{l10n.components.weather.DAY}</div>
            <div className={classes.weatherTemperature}>
              {Math.round(weatherData.airTemperatureDay)}
              &#176;
            </div>
          </div>
          <div className={classes.timeTemperature}>
            <div>{l10n.components.weather.EVENING}</div>
            <div className={classes.weatherTemperature}>
              {Math.round(weatherData.airTemperatureEvening)}
              &#176;
            </div>
          </div>
        </div>
        <div className={classes.weatherWind}>
          {Math.round(weatherData.windSpeed)}
          {l10n.components.weather.WIND_SPEED}
          <AirIcon color="disabled" />
        </div>
      </div>
      {' '}
    </>
  );
};

export default Weather;