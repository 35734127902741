import React from 'react';
import clsx from 'clsx';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from './RoundCell.styles';
import Tooltip from '../../../../../../../components/tooltip/Tooltip';
import Round from '../../../../../../../models/pages/tournament/Round';
import useTranslation from '../../../../../../../components/localization/customHooks/Translation';
import { calculateResult } from '../../../../../../../utils/Utils';

const RoundCell = (props: {
  round: Round | undefined
}) => {
  const theme = useTheme();
  const l10n = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({});
  const { round } = props;

  const setResultStyle = (anglerCatches: number, opponentCatches: number) => {
    if (anglerCatches > opponentCatches) { return classes.winColor; }
    if (anglerCatches < opponentCatches) { return classes.defeatColor; }
    return classes.drawColor;
  };

  return round && round.anglerCatches !== null && round.opponentCatches !== null
    ? (
      <Tooltip
        className={classes.tooltip}
        index={round.id.toString()}
        content={(
          <div className={classes.wrapperContentTooltip}>
            <div className={clsx(classes.resultStatus, setResultStyle(round.anglerCatches, round.opponentCatches))}>
              <span>{calculateResult(round.anglerCatches, round.opponentCatches)}</span>
            </div>
            <div className={classes.userContainer}>
              <span>
                {`${l10n.pages.OPPONENT}:  `}
                <b>{`${round.opponent.fullName}`}</b>
              </span>
              <div className={classes.roundInfo}>
                <span>
                  {`${l10n.pages.SECTOR}:  `}
                  <b>{`${round.sectorNumber}`}</b>
                </span>
                <span>
                  {`${l10n.pages.POINTS}:  `}
                  <b>{`${round.anglerScore}`}</b>
                </span>
              </div>
            </div>
          </div>
        )}
        container={(
          <div className={classes.wrapperRounds}>
            {isMobile && (
              <span className={classes.numberRound}>
                {`${l10n.pages.ROUND}:  `}
                {round.roundNumber}
              </span>
            )}
            <div
              className={clsx(classes.cell, {
                [classes.win]: round.anglerCatches! > round.opponentCatches!,
                [classes.loss]: round.anglerCatches! < round.opponentCatches!,
                [classes.draw]: round.anglerCatches === round.opponentCatches
              })}
            >
              {round.anglerCatches}
              :
              {round.opponentCatches}
            </div>
          </div>
        )}
      />
    )
    : null;
};

export default RoundCell;