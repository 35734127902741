import React from 'react';
import clsx from 'clsx';
import useStyles from '../../../Playoff.styles';
import ModifiedPlayoffRound from '../../../../../../../../models/pages/tournament/playoff/ModifiedPlayoffRound';
import EmptyCard from '../../../components/Empty';
import QuarterFinalCard from './QuarterFinalCard';
import RoundResult from '../../../../../../../../models/pages/tournament/playoff/RoundResult';

const defaultProps = {
  round: null
};

function QuarterFinalRounds(props: {
  round?: ModifiedPlayoffRound | null
  setParticipant: React.Dispatch<React.SetStateAction<RoundResult>>
  winnerId: number | null | undefined
  isEditMode: boolean
}) {
  const classes = useStyles();
  const {
    round, setParticipant, winnerId, isEditMode
  } = props;

  return (
    <>
      <div className={clsx(classes.coupleWrapper, classes.flexRow)}>
        <div className={clsx(classes.flexColumn, classes.innerWrapper)}>
          {round
            ? (
              <>
                <QuarterFinalCard
                  winnerId={winnerId}
                  player={round.firstParticipant}
                  setParticipant={setParticipant}
                  isEditMode={isEditMode}
                />
                <QuarterFinalCard
                  player={round.secondParticipant}
                  setParticipant={setParticipant}
                  winnerId={winnerId}
                  isEditMode={isEditMode}
                />
              </>
            )
            : (
              <>
                <EmptyCard />
                <EmptyCard />
              </>
            )}
        </div>
      </div>
    </>
  );
}

QuarterFinalRounds.defaultProps = defaultProps;

export default QuarterFinalRounds;