/* eslint-disable max-len */
import {
  object, string, ref,
} from 'yup';
import { getTranslation } from '../../../localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  email: string().required(l10n.components.signUpForm.REQUIRED_EMAIL).email(l10n.components.signUpForm.INVALID_EMAIL),
  firstName: string().required(l10n.components.signUpForm.REQUIRED_FIELD),
  lastName: string().required(l10n.components.signUpForm.REQUIRED_FIELD),
  countryId: string().required(l10n.components.signUpForm.REQUIRED_FIELD),
  city: string().required(l10n.components.signUpForm.REQUIRED_FIELD),
  password: string().required(l10n.components.signUpForm.VALIDATION_PASSWORD_REQUIRED).min(8, l10n.components.signUpForm.VALIDATION_PASSWORD_MIN),
  confirmPassword: string().required(l10n.components.signUpForm.VALIDATION_PASSWORD_REQUIRED).oneOf([ref('password'), null], l10n.components.signUpForm.VALIDATION_PASSWORD_MATCH),
});

export default schema;