import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ATButton from '../../components/button/Button';
import useStyles from './Teams.styles';
import AreatroutModal from '../../components/modal/Modal';
import AddOrEditTeamModal from './components/addOrEditTeamModal/AddOrEditTeamModal';
import TeamData from '../../models/pages/team/TeamData';
import { emptyTeamData } from './helpers/Constants';
import TeamsService from '../../services/main/Team.service';
import Team from '../../models/pages/team/Team';
import TeamCards from '../../components/teamCard/TeamCards';
import LoaderFullScreen from '../../components/loader/fullScreen/FullScreen';
import { StorageService } from '../../utils/Utils';
import transformTeams from './Teams.utils';
import IncomingInvitations from './components/incomingInvitations/IncomingInvitations';
import useTranslation from '../../components/localization/customHooks/Translation';

function Teams() {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [editTeamData, setEditTeamData] = useState<TeamData>(emptyTeamData);
  const [teams, setTeams] = useState<Team[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const l10n = useTranslation();

  const user = StorageService.getUserData();

  const getTeams = async () => {
    setIsLoading(true);
    const response = await TeamsService.getAll();
    if (response) {
      setTeams(response.map((team: Team) => transformTeams(team)));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTeams();
  }, []);

  const openCreateTeamModal = () => {
    setOpen(true);
    setEditTeamData(emptyTeamData);
  };

  const closeModalHandler = () => {
    setOpen(false);
    getTeams();
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }
  return teams && user ? (
    <div className={classes.teams}>
      <div className={classes.teamsContainer}>
        <Typography className={classes.boldText}>{l10n.pages.TEAMS}</Typography>
        <ATButton
          testId="add-button"
          onClick={openCreateTeamModal}
          className={classes.addButton}
          variant="primary"
          fill="solid"
          size="sm"
        >
          <AddIcon fontSize="large" className={classes.addIcon} />
        </ATButton>
      </div>
      <div className={classes.teamsList}>
        <TeamCards
          teams={teams}
          userEmail={user.email}
          setEditTeamData={setEditTeamData}
          setOpen={setOpen}
          refresh={getTeams}
        />
      </div>
      <div className={classes.teamFormContainer}>
        <Typography className={classes.boldText}>{l10n.pages.INCOMING_INVITATIONS}</Typography>
      </div>
      <IncomingInvitations refresh={getTeams} />
      <AreatroutModal
        header=""
        open={open}
        setOpen={setOpen}
        modalClassName={classes.addTeamModal}
        onClose={closeModalHandler}
      >
        <AddOrEditTeamModal editTeamData={editTeamData} refresh={getTeams} />
      </AreatroutModal>
    </div>
  ) : <div className={classes.emptyTeamList}><h2>{l10n.components.userProfile.EMPTY_TEAMS_LIST}</h2></div>;
}

export default Teams;