import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'block',
    maxWidth: 1700,
    margin: 'auto',
  },
  administration: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
  lastActive: {
    minWidth: 190,
    textAlign: 'left',
    paddingLeft: 10,
    boxSizing: 'border-box'
  },
  fullName: {
    minWidth: 190,
    margin: 0
  },
}));

export default useStyles;