import React from 'react';
import clsx from 'clsx';
import useStyles from '../Article.style';
import Tags from '../../tags/Tags';
import NewsItem from '../../../models/pages/news/NewsItem';
import newsType from '../../../pages/home/components/newsComponent/NewsComponent.utils';
import { articles } from '../../../utils/enum/Enum';
import { getImageUrl } from '../../../utils/Utils';
import RTEtext from '../../RTEtext/RTEtext';
import newsPlaceholder from '../../../assets/news/placeholder.png';

function Content(props: {
  article: NewsItem
}) {
  const classes = useStyles();
  const { article } = props;
  const getImage = () => (
    article.image !== null ? getImageUrl(article.image.thumbnailImageUrl) : newsPlaceholder
  );
  return (
    <div className={clsx(classes.articleWrapper, classes.articleSize, { [classes.headerPadding]: !article.image })}>
      <div className={article.image ? classes.imageComponentTag : classes.withoutImageComponentTag}>
        <Tags className={classes.typeOfPublication} variant={`news-${articles[article.type]}`}>{newsType[articles[article.type]]}</Tags>
      </div>
      <img src={getImage()} alt="newsImage" className={classes.image} />
      <div className={classes.subHeaderWrapper}>
        <div className={classes.subHeader}>{article.header}</div>
      </div>
      <RTEtext
        text={article.content}
        className={clsx(classes.basicTextStyle, classes.hideContent, article.image ? classes.textWrapperSmall : classes.textWrapperLarge)}
      />

    </div>
  );
}

export default Content;