import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../../../stores/Main';
import useStyles from './Publication.styles';
import TournamentService from '../../../../../services/main/Tournament.service';
import PublishTournament from '../../../../../models/pages/tournament/PublishTournament';
import TournamentResult from '../../../../../components/tournamentResult/TournamentResult';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

const Publication = observer(() => {
  const { TournamentStore } = useStores();
  const classes = useStyles();
  const l10n = useTranslation();
  const url = useParams<{ id: string }>();
  const id = Number(url.id);

  const setTournamentIsPublish = async (data: PublishTournament) => {
    TournamentStore.setIsPublished(await TournamentService.setIsPublish(data));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTournamentIsPublish({ tournamentId: id, tournamentIsPublished: event.target.checked });
  };

  return (
    <>
      <FormControlLabel
        className={classes.switchWrapper}
        control={(
          <Switch
            className={classes.switch}
            checked={TournamentStore.isPublished}
            onChange={handleChange}
          />
          )}
        label={l10n.components.headerLabel.TOURNAMENT_HAS_BEEN_PUBLISHED}
      />
      <div className={classes.root}>
        <TournamentResult id={id} />
      </div>
    </>
  );
});

export default Publication;