import React from 'react';
import { ListItem, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import TournamentFormat from '../../../../../../../models/pages/tournament/TournamentFormat';
import useStyles from '../../Formats.styles';

function EditDeleteCell(props: {
    data: TournamentFormat,
    setFormat: React.Dispatch<React.SetStateAction<TournamentFormat>>,
    setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  const classes = useStyles();
  const {
    data, setFormat, setOpenEdit, setOpenDelete,
  } = props;
  const editFormat = (format: TournamentFormat) => {
    setFormat(format);
    setOpenEdit(true);
  };

  const openDelModal = (format: TournamentFormat): void => {
    setFormat(format);
    setOpenDelete(true);
  };
  return (
    <ListItem className={classes.buttonCell}>
      <IconButton
        className={classes.button}
        onClick={() => editFormat(data)}
        edge="start"
        aria-label="edit"
      >
        <Edit />
      </IconButton>
      <IconButton
        className={classes.button}
        edge="end"
        aria-label="delete"
        onClick={() => openDelModal(data)}
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
}

export default EditDeleteCell;