import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import Modal from '../../../../../components/modal/Modal';
import BlockUserModal from './modal/Modal';
import useTranslation from '../../../../../components/localization/customHooks/Translation';

type onFunction = () => void;

export default function BlockUser(props: {
  userId: string;
  getUsers: onFunction;
  isBlocked: boolean;
  lockoutMessage: string;
  isDisable: boolean;
}) {
  const {
    userId, getUsers, isBlocked, lockoutMessage, isDisable
  } = props;
  const l10n = useTranslation();
  const [open, setOpen] = useState(false);
  const isBlockedText = isBlocked ? l10n.pages.UNBLOCK_USER : l10n.pages.BLOCK_USER;
  const isBlockedIcon = isBlocked ? <LockOpen /> : <Lock />;

  return (
    <>
      <IconButton
        edge="end"
        data-testid="block-user"
        aria-label="block"
        disabled={isDisable}
        onClick={() => setOpen(true)}
      >
        {isBlockedIcon}
      </IconButton>
      <Modal
        header={isBlockedText}
        open={open}
        setOpen={setOpen}
        onClose={getUsers}
      >
        <BlockUserModal
          isBlockedText={isBlockedText}
          isBlocked={isBlocked}
          userId={userId}
          lockoutMessage={lockoutMessage}
        />
      </Modal>
    </>
  );
}