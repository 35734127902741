import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import UserService from '../../../services/main/User.service';
import AdminUser from '../../../models/pages/user/AdminUser';
import AreatroutTable from '../../../components/table';
import DeleteUser from './components/deleteUser/DeleteUser';
import EditRole from './components/editRole/EditRole';
import { adminRole, organizerRole } from '../../../utils/constants/Constants';
import BlockUser from './components/blockUser/BlockUser';
import useStyles from './Users.style';
import LoaderFullScreen from '../../../components/loader/fullScreen/FullScreen';
import DateTimeCell from '../../../components/tableTemplates/components/cell/dateTime/DateTime';
import { StorageService } from '../../../utils/Utils';
import useTranslation from '../../../components/localization/customHooks/Translation';
import ActivationState from './components/activationState/ActivationState';

export default function AdminUsers() {
  const classes = useStyles();
  const l10n = useTranslation();
  const [allUsers, setUsers] = useState<Array<AdminUser> | null>([]);
  const [loader, setLoader] = useState(true);
  const [currentUserEmail, setCurrentUserEmail] = useState();

  const getUsers = async () => {
    const response = await UserService.get();

    if (response) {
      response.forEach((user: AdminUser) => {
        user.hasOrganizerRole = user.roles.some((role: string) => role === organizerRole);
        user.hasAdministatorRole = user.roles.some((role: string) => role === adminRole);
      });
      setUsers(response);
    }
    setLoader(false);
  };

  const columns = [
    {
      displayName: l10n.components.tableColumns.REGISTRATION_DATE,
      fieldName: 'registrationDate',
      allowSearch: false,
      render: ({ registrationDate }: AdminUser) => <DateTimeCell date={registrationDate} />,
    },
    {
      displayName: l10n.components.tableColumns.WAS_ACTIVE_AT,
      fieldName: 'wasActiveAt',
      allowSearch: false,
      render: ({ wasActiveAt }: AdminUser) => <DateTimeCell date={wasActiveAt} className={classes.lastActive} />,
    },
    {
      displayName: l10n.components.tableColumns.FULLNAME,
      fieldName: 'fullName',
      render: ({ fullName }: AdminUser) => <p className={classes.fullName}>{fullName}</p>,
    },
    {
      displayName: l10n.components.tableColumns.COUNTRY,
      fieldName: 'country.name',
      allowSearch: true,
      render: ({ country }: AdminUser) => country?.name
    },
    {
      displayName: l10n.components.tableColumns.EMAIL,
      fieldName: 'email',
      render: ({ email }: AdminUser) => (
        <a href={`mailto:${email}`}>{email}</a>
      ),
    },
    {
      displayName: l10n.components.tableColumns.ACTIVATION,
      fieldName: 'emailConfirmed',
      render: ({ id, emailConfirmed }: AdminUser) => (
        <ActivationState
          userId={id}
          getUsers={getUsers}
          isEmailConfirmed={emailConfirmed}
        />
      ),
    },
    {
      displayName: l10n.components.tableColumns.CREATOR,
      fieldName: 'hasOrganizerRole',
      allowSearch: false,
      render: ({ id, roles }: AdminUser) => (
        <EditRole userId={id} userRoles={roles} role={organizerRole} getUsers={getUsers} />
      ),
    },
    {
      displayName: l10n.components.tableColumns.ADMINISTRATOR,
      fieldName: 'hasAdministatorRole',
      allowSearch: false,
      render: ({ id, roles, email }: AdminUser) => (
        <EditRole userId={id} userRoles={roles} role={adminRole} isDisabled={currentUserEmail === email} getUsers={getUsers} />
      ),
    },
    {
      displayName: l10n.components.tableColumns.CONTROL,
      fieldName: 'control',
      allowSortring: false,
      allowSearch: false,
      render: ({
        id, isBlocked, lockoutMessage, email,
      }: AdminUser) => (
        <Grid className={classes.administration}>
          <BlockUser
            userId={id}
            isBlocked={isBlocked}
            getUsers={getUsers}
            lockoutMessage={lockoutMessage}
            isDisable={currentUserEmail === email}
          />
          <DeleteUser id={id} isDisabled={currentUserEmail === email} getUsers={getUsers} />
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    getUsers();
    setCurrentUserEmail(StorageService.getUserData()?.email);
  }, []);

  if (loader) {
    return <LoaderFullScreen />;
  }
  if (allUsers) {
    return (
      <div className={classes.root}>
        <AreatroutTable isSearchVisible columns={columns} rows={allUsers} />
      </div>
    );
  }
  return (
    <Grid container direction="row" justify="center">
      <h3>{l10n.pages.NO_DATA}</h3>
    </Grid>
  );
}