import React, { useState } from 'react';
import useStyles from '../../TeamCards.styles';
import bin from '../../../../assets/icons/teamCards/bin.svg';
import Team from '../../../../models/pages/team/Team';
import useConfirmationDialog from '../../../useConfirmationDialog/UseConfirmationDialog';
import checkMarkFailed from '../../../../assets/icons/checkMarkFailed.svg';
import TeamsService from '../../../../services/main/Team.service';
import { EmptyVoidFunction } from '../../../../utils/types/Types';
import Toasters from '../../../popUp/PopUp';
import useTranslation from '../../../localization/customHooks/Translation';

function DeleteTeamButton(props: {
  team: Team
  refresh: EmptyVoidFunction
}) {
  const classes = useStyles();
  const l10n = useTranslation();
  const {
    team, refresh
  } = props;

  const [id, setId] = useState<number | null>(null);

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    headerText: '',
    bodyText: (
      <div>
        <div className={classes.title}>
          <img src={checkMarkFailed} alt="check-mark-failed" />
          <span className={classes.text}>{l10n.components.teamCard.DELETE_TEAM}</span>
        </div>
        <span className={classes.middleSizeText}>{l10n.components.teamCard.DELETE_TEAM_CONFIRM}</span>
      </div>
    ),
    confirmationButtonText: l10n.components.teamCard.CONFIRM_BUTTON,
    cancelButtonText: l10n.components.teamCard.CANCEL_BUTTON,
    onCancelClick: () => {
      onClose();
    },
    onConfirmClick: async () => {
      if (id) {
        const res = await TeamsService.delete(id);
        if (res) {
          Toasters.success(l10n.components.teamCard.DELETE_TEAM_SUCCESS);
          refresh();
        }
      }
      onClose();
    },
  });

  return (
    <>
      <button
        type="button"
        className={classes.roundedButton}
        onClick={() => {
          onOpen();
          setId(team.id);
        }}
      >
        <img src={bin} alt="delete" />
      </button>
      <Dialog />
    </>
  );
}

export default DeleteTeamButton;