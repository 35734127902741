import { number, object, string } from 'yup';
import { phoneRegex } from '../../../../../../utils/constants/Constants';
import { getTranslation } from '../../../../../../components/localization/customHooks/Translation';

const l10n = getTranslation();

const schema = object().shape({
  name: string().required(l10n.components.locations.REQUIRED_NAME),
  description: string().required(l10n.components.locations.REQUIRED_DESCRIPTION),
  countryId: number().required(l10n.components.locations.REQUIRED_COUNTRY).moreThan(0),
  phoneNumber: string()
    .matches(phoneRegex,
      { message: l10n.components.editLocation.PHONE_NUMBER_VALIDATION }).required(),
});

export default schema;